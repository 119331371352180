import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Header from '../constants/Header';

import store from '../store/configureStore';

import * as HeaderActions from '../actions/Header';
import * as PasswordActions from '../actions/Password';
import ChangePassword from '../components/Organisms/ChangePassword';

type AllState = ReturnType<typeof store.getState>;

const ChangePasswordContainer: FC = () => {
  const dispatch = useDispatch();
  const password = useSelector((state: AllState) => state.password);

  useEffect(() => {
    (async () => {
      document.title = '管理';
      dispatch(HeaderActions.setPage(Header.SETTING_CHANGE_PASSWORD));
      dispatch(PasswordActions.resetStatus());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ChangePassword password={password} />;
};

export default ChangePasswordContainer;
