import { DisplayPayload } from '../constants/UserListField';
import { ResponseData } from '../constants/AppUser';
import { createLifeCheckAPIInstance } from '../api/LifeCheck';

const [isLifeCheckAPIError, lifeCheckAPIInstance, lifeCheckAPIErrorAction] = createLifeCheckAPIInstance();

/**
 * 生活機能チェック・指定の1件を取得.
 *
 * @param {string} userId
 * @param {string} uniqueId
 * @param {number} sequence
 * @returns
 */
export const getLifeCheckRecordBySequence = (userId: string, uniqueId: string, sequence: number) => {
  return isLifeCheckAPIError
    ? lifeCheckAPIErrorAction
    : lifeCheckAPIInstance.getRecordBySequenceAPI(userId, uniqueId, sequence);
};

/**
 * 生活機能チェック・指定月のデータを取得.
 *
 * @param {string} uniqueIds
 * @param {string} month
 * @returns
 */
export const getLifeCheckRecordByMonth = (uniqueIds: ResponseData[], month: string) =>
  isLifeCheckAPIError ? lifeCheckAPIErrorAction : lifeCheckAPIInstance.getRecordByMonthAPI(uniqueIds, month);

/**
 * 興味関心チェックの最終登録日を取得.
 * Componentから呼ばれる.
 * @param {string} userId
 * @param {(payload: DisplayPayload) => void} addDisplayDate
 * @returns
 */
export const getLifeCheckLastDate = (
  userId: string,
  addDisplayDate: (payload: DisplayPayload) => void,
  intervalTimeMs = 500,
) => {
  return isLifeCheckAPIError
    ? lifeCheckAPIErrorAction
    : lifeCheckAPIInstance.getLifeCheckLastDateAPI(userId, addDisplayDate, intervalTimeMs);
};

export type LifeCheckAction = ReturnType<
  typeof getLifeCheckRecordBySequence | typeof getLifeCheckRecordByMonth | typeof getLifeCheckLastDate
>;
