// 利用者設定.
import { MALE, FEMALE, CARE_LEVELS } from '../constants/Training';
import * as UserSettingConst from '../constants/UserSetting';

/**
 * バリデーション.
 *
 * @param newUser  UserSettingConst.ParamsFormat
 * @returns
 */
export const validate = (newUser: UserSettingConst.ParamsFormat) => {
  const NO_INPUT_MSG = 'が入力されていません。';

  // 名前.
  if (newUser.user_name === null || newUser.user_name === '') {
    alert(`名前${NO_INPUT_MSG}`);
    return false;
  }

  // なまえ(よみ).
  if (newUser.user_name_kana === null || newUser.user_name_kana === '') {
    alert(`なまえ(よみ)${NO_INPUT_MSG}`);
    return false;
  } else {
    const readName = newUser.user_name_kana;
    if (/^[、。ぁ-ゖゝゞゟー]+$/.test(readName)) {
      // ひらがなのみ.
    } else {
      alert('なまえ(よみ)にひらがな以外が入力されています。');
      return false;
    }
  }

  // 被保険者番号（介護）.
  if (newUser.user_care_id === null || newUser.user_care_id === '') {
    alert(`被保険者番号（介護）${NO_INPUT_MSG}`);
    return false;
  } else {
    const userCareId = newUser.user_care_id;
    if (userCareId.match(/[^0-9a-zA-Z]+/)) {
      alert('被保険者番号（介護）に半角英数字以外が入力されています。');
      return false;
    }
  }

  // 保険者番号
  if (newUser.user_insurer_no === null || newUser.user_insurer_no === '') {
    // 保険者番号は必須ではなく任意 https://moff-mobi.atlassian.net/browse/CSTRAINING-264
    // alert(`保険者番号 ${NO_INPUT_MSG}`);
    // return false;
  } else if (newUser.user_insurer_no === undefined) {
    console.log('user_insurer_no is undefined');
  } else {
    const userInsurerNo = newUser.user_insurer_no;
    if (userInsurerNo && userInsurerNo.match(/[^0-9]+/)) {
      alert('保険者番号に半角数字以外が入力されています。');
      return false;
    }
  }

  // 生年月日.
  if (newUser.user_birth_year === null) {
    alert(`生年月日の年${NO_INPUT_MSG}`);
    return false;
  } else {
    const birthYear = String(newUser.user_birth_year);
    if (birthYear.match('^[0-9]{4}$')) {
      // 正しい4桁.
    } else {
      alert('生年月日の年に不正な入力がされています。');
      return false;
    }
  }

  if (newUser.user_birth_month === null) {
    alert(`生年月日の月${NO_INPUT_MSG}`);
    return false;
  } else {
    const birthMonth = String(newUser.user_birth_month);
    if (birthMonth.match('^[0-9]{1,2}$')) {
      // 正しい1,2桁.
    } else {
      alert('生年月日の月に不正な入力がされています。');
      return false;
    }
  }

  if (newUser.user_birth_day === null) {
    alert(`生年月日の日${NO_INPUT_MSG}`);
    return false;
  } else {
    const birthDay = String(newUser.user_birth_day);
    if (birthDay.match('^[0-9]{1,2}$')) {
      // 正しい1,2桁.
    } else {
      alert('生年月日の日に不正な入力がされています。');
      return false;
    }
  }

  // 性別.
  // トグルなのでありえないけど一応チェック.
  if (newUser.user_gender !== Number(MALE) && newUser.user_gender !== Number(FEMALE)) {
    alert('性別に男女以外が入力されています。');
    return false;
  }

  // 介護度.
  // プルダウンなのでありえないけど一応チェック.
  if (newUser.user_care_level === null) {
    alert(`介護度${NO_INPUT_MSG}`);
    return false;
  } else {
    const isNan: boolean = isNaN(newUser.user_care_level);
    // trueは数字ではない.
    if (isNan) {
      alert('介護度に不正な入力がされています。');
      return false;
    } else {
      // 0開始.
      if (newUser.user_care_level >= 0 && newUser.user_care_level < Object.keys(CARE_LEVELS).length) {
        // 正しい選択.
      } else {
        alert('介護度に不正な入力がされています。');
        return false;
      }
    }
  }

  // 運動曜日.
  const dayList = newUser.user_visit_day;
  if (dayList === null || dayList.filter((day) => day === 1).length < 1) {
    alert('運動曜日が入力されていません。');
    return false;
  }

  // 正しい.
  return true;
};
