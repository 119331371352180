// ロコモかADL用定数
export const TRAINING_TYPE = {
  None: {
    id: 0,
    key: 'none',
    name: 'none',
  },
  Locomo: {
    id: 1,
    key: 'locomo',
    name: 'ロコモ予防',
  },
  Adl: {
    id: 2,
    key: 'adl',
    name: '日常生活動作',
  },
} as const;

export type TRAINING_TYPE = typeof TRAINING_TYPE[keyof typeof TRAINING_TYPE];

// ロコモ・ADL 通常・長期・比較
export const SCREEN_TYPE = {
  Detail: 'detail',
  LongTerm: 'longTerm',
  Compare: 'compare',
  CompareLeft: 'compareLeft',
  CompareRight: 'compareRight',
  LiterallyReportSelectMonth: 'literallyReportSelectMonth',
  MoffCheckSelectMonth: 'moffCheckSelectMonth',
  MoffCheckComment: 'moffCheckComment',
  KirokuShow: 'kirokuShow',
  KirokuEdit: 'kirokuEdit',
  KirokuDelete: 'kirokuDelete',
  PressSelectMonth: 'pressSelectMonth',
} as const;

export type SCREEN_TYPE = typeof SCREEN_TYPE[keyof typeof SCREEN_TYPE];

export enum DATE_DISPLAY_TYPE {
  Month,
  Day,
  ThePastYear,
}

// サーバ側のなのでキャメルケースは変えられない.
export interface Training {
  count: number;
  average_angle: number;
  average_duration: number;
}

export interface TrainingMenu {
  id: number;
  name: string;
}

export interface TypeVariables {
  // 比較用数.
  comp: number;
  // key用prefix.
  prefix: string;
}

export type TrainingGenre = Exclude<TRAINING_TYPE['name'], 'none'>;
export interface TrainingInfo {
  id: number;
  name: string;
  genre: TrainingGenre;
  position: string;
  posture: string;
}

export interface DailyTrainingNumber {
  [trainingNumber: string]: Training;
}

export interface MonthlyTrainingData {
  [date: string]: DailyTrainingNumber;
}

// 各ステート.
// 通常のロコモ or ADL 詳細.
export const DETAIL_STATE = 0;
// 長期変化.
export const LONG_TERM_STATE = 1;
// 比較.
export const COMPARE_STATE = 2;

// ロコモのデフォルト表示トレーニング
export const INITIAL_TRAINING_NAMES = [
  '腕上げ下げ',
  '腕左右',
  'ひざのばし',
  'ももあげ',
  '股開閉',
  'よこあげ',
  'おしり',
  '上部腹筋',
  'ローイング',
];

// TODO: モフトレ通信で使用している、各トレーニング情報も後ほどこの定数に集約する。
// ※ ロコモ・ADLとモフトレ通信でトレーニング名日本語が微妙に違っているため後ほど統一。
export const TRAINING_REPORT_INFO = {
  1: {
    key: 'udeJouge',
    name: '腕上げ下げ',
    maxAxis: 180,
    criteria: 100,
  },
  2: {
    key: 'udeSayu',
    name: '腕左右',
    maxAxis: 180,
    criteria: 50,
  },
  3: {
    key: 'hizaNobashi',
    name: 'ひざのばし',
    maxAxis: 100,
    criteria: 60,
  },
  4: {
    key: 'momoAge',
    name: 'ももあげ',
    maxAxis: 100,
    criteria: 20,
  },
  5: {
    key: 'mataKaihei',
    name: '股開閉',
    maxAxis: 100,
    criteria: 20,
  },
  6: {
    key: 'ashiYokoage',
    name: 'よこあげ',
    maxAxis: 100,
    criteria: 30,
  },
  7: {
    key: 'oshiri',
    name: 'おしり',
    maxAxis: 100,
    criteria: 20,
  },
  8: {
    key: 'trunkCurl',
    name: '上部腹筋',
    maxAxis: 90,
    criteria: 10,
  },
  9: {
    key: 'rowing',
    name: 'ローイング',
    maxAxis: 110,
    criteria: 40,
  },
  10: {
    key: 'udeNobashi',
    name: '腕伸ばし',
    maxAxis: 60,
    criteria: 45,
  },
  11: {
    key: 'kokansetsuSoto',
    name: '股関節外ひねり',
    maxAxis: 55,
    criteria: 40,
  },
  12: {
    key: 'armCurl',
    name: 'アームカール',
    maxAxis: 90,
    criteria: 45,
  },
  13: {
    key: 'karadaHineri',
    name: 'からだ捻り',
    maxAxis: 70,
    criteria: 40,
  },
  14: {
    key: 'legCurl',
    name: 'レッグカール',
    maxAxis: 130,
    criteria: 75,
  },
  15: {
    key: 'zennwannhineri',
    name: '前腕捻り',
    maxAxis: 180,
    criteria: 120,
  },
  16: {
    key: 'calfRays',
    name: 'カーフレイズ',
    maxAxis: 100,
    criteria: -10,
  },
  17: {
    key: 'hukusyakin',
    name: '腹斜筋',
    maxAxis: 70,
    criteria: 50,
  },
  18: {
    key: 'zaistep',
    name: '座位ステップ',
    maxAxis: 50,
    criteria: 35,
  },
  19: {
    key: 'akiresuken',
    name: 'アキレス腱',
    maxAxis: 25,
    criteria: 15,
  },
  20: {
    key: 'kataGaiten',
    name: '肩の外転',
    maxAxis: 100,
    criteria: 80,
  },
  21: {
    key: 'yokoFukkin',
    name: 'よこ腹筋',
    maxAxis: 60,
    criteria: 40,
  },
  // 日常生活動作(ADL)系,
  101: {
    key: 'ofuroMatagi',
    name: 'お風呂またぎ',
    maxAxis: 100,
    criteria: 20,
  },
  102: {
    key: 'ofuroSenpatsu',
    name: 'お風呂洗髪',
    maxAxis: 100,
    criteria: 40,
  },
  103: {
    key: 'toiletTachiagari',
    name: 'トイレ立ち上がり',
    maxAxis: 100,
    criteria: 30,
  },
  104: {
    key: 'toiletChakudatsui',
    name: 'トイレ着脱衣',
    maxAxis: 100,
    criteria: 30,
  },
  // 105: {
  //   // 欠番.
  //   key: null,
  //   name: '',
  //   maxAxis: null,
  //   criteria: null,
  // },
  // 106: {
  //   // 欠番.
  //   key: null,
  //   name: '',
  //   maxAxis: null,
  //   criteria: null,
  // },
  107: {
    key: 'idoHoko',
    name: '移動歩行',
    maxAxis: 100,
    criteria: 20,
  },
  108: {
    key: 'sentakumonoHoshi',
    name: '洗濯物干し',
    maxAxis: 100,
    criteria: 90,
  },
  109: {
    key: 'dansaSyoukou',
    name: '段差昇降',
    maxAxis: 40,
    criteria: 20,
  },
  110: {
    key: 'idouKashishiji',
    name: '移動下肢支持',
    maxAxis: 70,
    criteria: 50,
  },
  111: {
    key: 'kutiniHakobu',
    name: '食事口に運ぶ',
    maxAxis: 140,
    criteria: 90,
  },
  112: {
    key: 'seiyouSengan',
    name: '整容洗顔',
    maxAxis: 150,
    criteria: 100,
  },
  113: {
    key: 'tyouriKiru',
    name: '調理切る',
    maxAxis: 150,
    criteria: 100,
  },
  114: {
    key: 'rituiBaransu',
    name: '立位バランス',
    maxAxis: 150,
    criteria: 60,
  },
  115: {
    key: 'kouiUwagi',
    name: '更衣上衣',
    maxAxis: 90,
    criteria: 40,
  },
  116: {
    key: 'kouiShitagi',
    name: '更衣下衣',
    maxAxis: 25,
    criteria: 20,
  },
  117: {
    key: 'kouiKutushita1',
    name: '更衣靴下①',
    maxAxis: 60,
    criteria: 40,
  },
  118: {
    key: 'kouiKutushita2',
    name: '更衣靴下②',
    maxAxis: 90,
    criteria: 30,
  },
  // 119: {
  //   // 欠番.
  //   key: 'tableFuki',
  //   name: 'テーブル拭き',
  //   maxAxis: 90,
  //   criteria: 60,
  // },
  // 120: {
  //   // 欠番.
  //   key: 'soujikiKake',
  //   name: '掃除機かけ',
  //   maxAxis: 90,
  //   criteria: 40,
  // },
  // 121: {
  //   // 欠番.
  //   key: 'madofuki',
  //   name: '窓ふき',
  //   maxAxis: 90,
  //   criteria: 50,
  // },
} as const;

export type TRAINING_REPORT_INFO = typeof TRAINING_REPORT_INFO[keyof typeof TRAINING_REPORT_INFO];

// TODO: モフトレ通信のロジックをインフラに移行次第削除
export const SKIP_ADL_IDX: number[] = [4, 5];

// ADLで導入されなかったインデックス '更衣着脱衣(上半身)', '更衣着脱衣(靴下)' 'テーブル拭き', '掃除機かけ', '窓ふき'.
export const UN_INTRODUCED_ADL_IDX: string[] = ['105', '106', '119', '120', '121'];

// ロコモトレーニング数.
export const MAX_LOCOMO_CNT = 21;
// ロコモの最小値番号.
export const LOCOMO_MIN_NO = 1;
// ロコモの最大値番号.
export const LOCOMO_MAX_NO: number = MAX_LOCOMO_CNT;
// ロコモ用の番号
// トレーニングが追加されてもロコモには表示させない
export const FOR_LOCOMO_NO = 21;

export const MOFF_TRAINING_MAX_COUNT = 38;

// ADLトレーニング数.
// ※ADLは項目としては19だが、欠番が2つあるため19+2の21
export const MAX_ADL_CNT = 21;
// ADLの最小値番号.
export const ADL_MIN_NO = 101;
// ADLの最大値番号.
export const ADL_MAX_NO: number = 100 + MAX_ADL_CNT;
// トレーニングが追加されても日常生活動作には表示させない
export const FOR_ADL_CNT = 18;
export const FOR_ADL_NO = 100 + FOR_ADL_CNT;
// ADLのトレーニング履歴（月次）テーブルの列数（<TD>の数）
export const ADL_MONTHLY_TABLE_COLUMN = 9;
// ADLのトレーニング履歴（月次）比較テーブルの列数（<TD>の数）
export const ADL_MONTHLY_COMPARE_TABLE_COLUMN = 4;

// カレンダーの日付の数字1開始、ダミー0+最大31日の1〜31日の計32要素を示す1ヶ月分の数字.
export const MONTHLY_DAYS_COUNT = 32;

export const MALE = '0';
export const FEMALE = '1';

// 要介護レベルの対応.
export const CARE_LEVELS: { [key: string]: string } = {
  0: '要支援1',
  1: '要支援2',
  2: '要介護1',
  3: '要介護2',
  4: '要介護3',
  5: '要介護4',
  6: '要介護5',
  999: '要介護なし',
};

// ロコモ・ADLにおける
export const TRAINING_REPORT_NAME = {
  Detail: 'detail',
  CompareLeft: 'startReport',
  CompareRight: 'endReport',
  LongTerm: 'longTerm',
} as const;

export type TRAINING_REPORT_NAME = typeof TRAINING_REPORT_NAME[keyof typeof TRAINING_REPORT_NAME];

// トグル位置
export const TOGGLE_POSITION = {
  Center: 'center',
  Left: 'left',
  Right: 'right',
} as const;

export type TOGGLE_POSITION = typeof TOGGLE_POSITION[keyof typeof TOGGLE_POSITION];

// モフバンド装着位置
export const ATTACHMENT_POSITION = {
  UnSpecified: {
    name: 'unSpecified',
    option: '',
    togglePosition: TOGGLE_POSITION.Left,
  },
  Left: {
    name: 'left',
    option: 'Left',
    togglePosition: TOGGLE_POSITION.Center,
  },
  Right: {
    name: 'right',
    option: 'Right',
    togglePosition: TOGGLE_POSITION.Right,
  },
} as const;

export type ATTACHMENT_POSITION = typeof ATTACHMENT_POSITION[keyof typeof ATTACHMENT_POSITION];

// トレーニング結果テーブルのテーブル数・カラム数パターン
export const TRAINING_TABLE_PATTERN = {
  default: {
    tableCount: 4,
    colCount: 9,
    threshold: [
      { dayCount: 0, prefix: '1st' },
      { dayCount: 9, prefix: '2nd' },
      { dayCount: 18, prefix: '3rd' },
      { dayCount: 27, prefix: '4th' },
    ],
  },
  tight: {
    tableCount: 8,
    colCount: 4,
    threshold: [
      { dayCount: 0, prefix: '1st' },
      { dayCount: 4, prefix: '2nd' },
      { dayCount: 8, prefix: '3rd' },
      { dayCount: 12, prefix: '4th' },
      { dayCount: 16, prefix: '5th' },
      { dayCount: 20, prefix: '6th' },
      { dayCount: 24, prefix: '7th' },
      { dayCount: 28, prefix: '8th' },
    ],
  },
} as const;

export type TRAINING_TABLE_PATTERN = typeof TRAINING_TABLE_PATTERN[keyof typeof TRAINING_TABLE_PATTERN];
