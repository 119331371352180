// 興味関心チェックの定数.
export const CIRCLE = '○';
export const OTHER = 'その他';

// 各項目のタイトル.
export const TOILET = '自分でトイレへ行く';
export const LIFELONG_LEARNING = '生涯学習・歴史';
export const BATHING = '一人でお風呂に入る';
export const READING = '読書';
export const WEARING = '自分で服を着る';
export const HAIKU = '俳句';
export const EATING = '自分で食べる';
export const SHODO = '書道・習字';
export const BRUSHING = '歯磨きをする';
export const PAINTING = '絵を描く・絵手紙';
export const GROOMING = '身だしなみを整える';
export const TYPING = 'パソコン・ワープロ';
export const SLEEPING = '好きなときに眠る';
export const PHOTO = '写真';
export const CLEANING = '掃除・整理整頓';
export const THEATER = '映画・観劇・演奏会';
export const COOKING = '料理を作る';
export const SADO_KADO = 'お茶・お花';
export const SHOPPING = '買い物';
export const SINGING = '歌を歌う・カラオケ';
export const GARDENING = '家の庭の手入れ・世話';
export const MUSIC = '音楽を聴く・楽器演奏';
export const LAUNDERING = '洗濯・洗濯物たたみ';
export const GAMING = '将棋・囲碁・麻雀・ゲーム等';
export const DRIVING = '自転車・車の運転';
export const EXERCISING = '体操・運動';
export const OUTING = '電車・バスでの外出';
export const STROLLING = '散歩';
export const CHILDREN = '孫・子供の世話';
export const PLAYING_SPORTS1 = 'ゴルフ・グラウンドゴルフ・';
export const PLAYING_SPORTS2 = '水泳・テニスなどのスポーツ';
export const ANIMAL = '動物の世話';
export const DANCING = 'ダンス・踊り';
export const FRIENDS = '友達とおしゃべり・遊ぶ';
export const WATCHING_SPORTS = '野球・相撲等観戦';
export const FAMILY = '家族・親戚との団らん';
export const GAMBLING = '競馬・競輪・競艇・パチンコ';
export const DATING = 'デート・異性との交流';
export const KNITTING = '編み物';
export const DRINKING = '居酒屋に行く';
export const SEWING = '針仕事';
export const VOLUNTEER = 'ボランティア';
export const CROPPING = '畑仕事';
export const COMMUNITY_ACTIVITY = '地域活動(町内会・老人クラブ)';
export const EARNING = '賃金を伴う仕事';
export const RELIGION = 'お参り・宗教活動';
export const TRAVELING = '旅行・温泉';

// multiple(連想配列型).
export interface Multiple {
  other1: {
    column: string;
    check: Check;
  };
  other2: {
    column: string;
    check: Check;
  };
  other3: {
    column: string;
    check: Check;
  };
  other4: {
    column: string;
    check: Check;
  };
}

// チェック.
export interface Check {
  // している.
  do: boolean;
  // してみたい.
  interested: boolean;
  // 興味がある.
  want: boolean;
}

// 返り値の型.
export interface ResponseData {
  // Unique ID
  unique_id: string;
  // 自分でトイレに行く.
  toilet: Check;
  // 一人でお風呂に入る.
  bathing: Check;
  // 自分で服を着る.
  wearing: Check;
  // 自分で食べる.
  eating: Check;
  // 歯磨きをする.
  brushing: Check;
  // 身だしなみを整える.
  grooming: Check;
  // 好きな時に眠る.
  sleeping: Check;
  // 掃除・整理整頓.
  cleaning: Check;
  // 料理を作る.
  cooking: Check;
  // 買い物.
  shopping: Check;
  // 家の庭の手入れ・世話.
  gardening: Check;
  // 洗濯・洗濯物たたみ.
  laundering: Check;
  // 自転車・車の運転.
  driving: Check;
  // 電車・バスでの外出.
  outing: Check;
  // 孫・子供の世話.
  children: Check;
  // 動物の世話.
  animal: Check;
  // 友達とおしゃべり・遊ぶ.
  friends: Check;
  // 家族・親戚との団欒.
  family: Check;
  // デート・異性との交流.
  dating: Check;
  // 居酒屋に行く.
  drinking: Check;
  // ボランティア.
  volunteer: Check;
  // 地域活動（町内会・老人クラブ）.
  community_activity: Check;
  // お参り・宗教活動.
  religion: Check;
  // 生涯学習・歴史;
  lifelong_learning: Check;
  // 読書.
  reading: Check;
  // 俳句.
  haiku: Check;
  // 書道・習字.
  shodo: Check;
  // 絵を描く・絵手紙.
  painting: Check;
  // パソコン・ワープロ.
  typing: Check;
  // 写真.
  photo: Check;
  // 映画・観劇・演奏会.
  theater: Check;
  // お茶・お花.
  sado_kado: Check;
  // 歌を歌う・カラオケ.
  singing: Check;
  // 音楽を聴く・楽器演奏.
  music: Check;
  // 将棋・囲碁・麻雀・ゲーム等.
  gaming: Check;
  // 体操・運動.
  exercising: Check;
  // 散歩.
  strolling: Check;
  // ゴルフ・グランドゴルフ・水泳・テニスなどのスポーツ.
  playing_sports: Check;
  // ダンス・踊り.
  dancing: Check;
  // 野球・相撲観戦.
  watching_sports: Check;
  // 競馬・競輪・協定・パチンコ.
  gambling: Check;
  // 編み物.
  knitting: Check;
  // 針仕事.
  sewing: Check;
  // 畑仕事.
  cropping: Check;
  // 賃金を伴う仕事.
  earning: Check;
  // 旅行・温泉.
  traveling: Check;
  // その他.
  additional_item: Multiple;
  // 聞き取り日(yyy-mm-dd)
  visited_day: string;
  // データ作成時間.
  // yyyy-mm-dd hh:mm:ss
  created_at: string;
  // データ更新時間.
  updated_at: string;
}
export interface MonthlyUsersData {
  monthUsersRecords: {
    userId: string;
    data: ResponseData[];
  }[];
}

export const LOADING = 'INTEREST_CHECK_LOADING';
// エラーでもFINISH
export const FINISH = 'INTEREST_CHECK_FINISH';

export const SUCCESS = 'INTEREST_CHECK_SUCCESS';
export const ERROR = 'INTEREST_CHECK_ERROR';
