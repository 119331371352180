import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import * as PopupActions from '../actions/Popup';

import { SCREEN_TYPE } from '../constants/Training';
import { MOFF_CHECK_COMPARE_LOGIC } from '../constants/MoffCheck';

import TrainingHeader from '../components/Organisms/TrainingHeader';

import { dispatchIdentifierToGTM } from '../utils/commonUtil';

interface TrainingHeaderContainerProps {
  changeState?: (state: SCREEN_TYPE) => void;
  changeSelectBox?: (event: any) => void;
  screenType: SCREEN_TYPE;
  compareMonth?: MOFF_CHECK_COMPARE_LOGIC;
  setIsToggleOn?: () => void;
  selectItems?: string[];
  addOutputItems?: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const TrainingHeaderContainer: FC<TrainingHeaderContainerProps> = ({
  changeState = () => null,
  changeSelectBox = () => null,
  screenType,
  compareMonth = MOFF_CHECK_COMPARE_LOGIC.default,
  selectItems = [],
  addOutputItems = () => null,
}) => {
  const dispatch = useDispatch();
  let popupOpen: () => void;
  switch (screenType) {
    case SCREEN_TYPE.Detail:
    case SCREEN_TYPE.LongTerm:
    case SCREEN_TYPE.Compare: {
      popupOpen = () => {
        dispatch(PopupActions.OpenADLLocomo());
        dispatchIdentifierToGTM('detail_page_youtube_moff_training');
      };
      break;
    }
    case SCREEN_TYPE.LiterallyReportSelectMonth: {
      popupOpen = () => {
        dispatch(PopupActions.OpenLiterallyReport());
        dispatchIdentifierToGTM('detail_page_youtube_literally');
      };
      break;
    }
    case SCREEN_TYPE.MoffCheckSelectMonth: {
      popupOpen = () => {
        dispatch(PopupActions.OpenMoffCheck());
        dispatchIdentifierToGTM('detail_page_youtube_moffcheck');
      };
      break;
    }
    case SCREEN_TYPE.KirokuShow:
    case SCREEN_TYPE.KirokuEdit:
    case SCREEN_TYPE.KirokuDelete: {
      popupOpen = () => {
        dispatch(PopupActions.OpenKiroku());
        dispatchIdentifierToGTM('detail_page_youtube_kiroku');
      };
      break;
    }
    case SCREEN_TYPE.PressSelectMonth: {
      popupOpen = () => {
        dispatch(PopupActions.OpenPress());
        dispatchIdentifierToGTM('detail_page_youtube_press');
      };
      break;
    }
    default: {
      popupOpen = () => null;
    }
  }

  return (
    <>
      <TrainingHeader
        popupOpen={popupOpen}
        changeState={changeState}
        screenType={screenType}
        changeSelectBox={changeSelectBox}
        compareMonth={compareMonth}
        selectItems={selectItems}
        addOutputItems={addOutputItems}
      />
    </>
  );
};

export default TrainingHeaderContainer;
