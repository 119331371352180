// Internet Explorer
import React, { FC } from 'react';
import BaseBrowser from './BaseBrowser';
import { User } from '../../../constants/UserListField';
import { ResponseData } from '../../../constants/HomeVisit';

const RenderCSS = () => {
  return (
    <style type="text/css">
      {/* 縦印刷, 日付・title名は消す */}
      {'@media print{@page {size: A4; margin: 0; ' +
        'margin-top: 4mm; margin-left: 1cm; margin-right: 1cm; margin-bottom: 0mm; } ' +
        '#wrapper { margin-top: 0; } header { display: none; } ' +
        'body { padding: 8; } ' +
        '#homevisit { padding-left: 0; padding-right: 0; } ' +
        '#sec01 { display: none; }}'}
    </style>
  );
};

interface InternetExplorerProps {
  user: User;
  homeVisit: ResponseData;
}

// InternetExplorer.
const InternetExplorer: FC<InternetExplorerProps> = ({ user, homeVisit }) => {
  return (
    <>
      <BaseBrowser user={user} homeVisit={homeVisit} />
      <RenderCSS />
    </>
  );
};

export default InternetExplorer;
