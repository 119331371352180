import { RequestParams, CSV_OUTPUT_ITEMS } from '../constants/csvCreate';
import { createCsvCreateInstance } from '../api/csvCreate';

const [isCreateCsvCreateError, csvCreateInstance, createCsvCreateErrorAction] = createCsvCreateInstance();

export const createCSV = (requestParams: RequestParams, outputItems: CSV_OUTPUT_ITEMS[]) => {
  return isCreateCsvCreateError ? createCsvCreateErrorAction : csvCreateInstance?.createCsv(requestParams, outputItems);
};

export type csvCreateAction = ReturnType<typeof createCSV>;
