import * as Auth from '../../constants/Auth';

const initialState = {
  isAuthenticated: false,
  isSigningIn: false,
  isSigningOut: false,
  userName: '',
  err: '',
  lastSignedIn: null,
  Loading: false,
  Loaded: false,
  Result: false,
};

export default function auth(state: any = initialState, action: any) {
  switch (action.type) {
    case Auth.LOADING_SIGN_IN:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
        Result: null,
      });
    case Auth.SUCCESS_SIGN_IN:
      return Object.assign({}, state, {
        isAuthenticated: action.result.isAuthenticated,
        isSigningIn: action.result.isAuthenticated,
        isSignedOut: false,
        userName: action.result.username,
        err: action.result.error,
        Loading: false,
        Loaded: true,
        Result: action.result,
      });
    case Auth.ERROR_SIGN_IN:
      return Object.assign({}, state, {
        isAuthenticated: false,
        isSigningIn: false,
        isSignedOut: false,
        userName: '',
        err: action.result,
        Loading: false,
        Loaded: true,
        Result: null,
      });
    case Auth.LOADING_SESSION:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
        Result: action.result,
      });
    case Auth.SUCCESS_SESSION:
      return Object.assign({}, state, {
        isAuthenticated: action.result.isAuthenticated,
        userName: action.result.username,
        planType: action.result.planType,
        err: '',
        Loading: false,
        Loaded: true,
        Result: action.result,
      });
    case Auth.ERROR_SESSION:
      return Object.assign({}, state, {
        isAuthenticated: false,
        userName: '',
        planType: '',
        err: action.result,
        Loading: false,
        Loaded: true,
        Result: null,
      });
    case Auth.LOADING_SIGN_OUT:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
        Result: null,
      });
    case Auth.SUCCESS_SIGN_OUT:
      return Object.assign({}, state, {
        isAuthenticated: action.result.isAuthenticated,
        isSignedOut: action.result.isSignedOut,
        userName: action.result.username,
        err: '',
        Loading: false,
        Loaded: true,
        Result: action.result,
      });
    case Auth.ERROR_SIGN_OUT:
      return Object.assign({}, state, {
        isAuthenticated: true,
        isSignedOut: false,
        err: action.result,
        Loading: false,
        Loaded: true,
        Result: action.result,
      });
    case Auth.AUTH_INITIALIZE:
      return Object.assign({}, state, {
        isAuthenticated: false,
        isSigningIn: false,
        isSigningOut: false,
        userName: '',
        err: '',
        lastSignedIn: null,
        Loading: false,
        Loaded: false,
        Result: false,
      });
    default:
      return state;
  }
}
