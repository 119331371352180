/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';

interface GeneralPrimaryDateInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /** Date Pickerの名前 */
  name: string;
  /** Date Pickerの初期値 */
  defaultValue: string | number;
  /** 必須かどうか */
  isRequired: boolean;
  /** refで参照する関数 */
  refFunction: any;
}

const GeneralPrimaryDateInput: FC<GeneralPrimaryDateInputProps> = ({
  name = '',
  defaultValue = '',
  isRequired = false,
  refFunction,
  type,
  step,
}) => (
  <input
    name={name}
    ref={refFunction}
    type={type}
    step={step ?? undefined}
    required={isRequired}
    defaultValue={defaultValue}
  />
);

export default GeneralPrimaryDateInput;
