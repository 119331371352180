import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store/configureStore';

import * as HeaderActions from '../actions/Header';
import * as MoffAPIActions from '../actions/MoffAPI';
import * as Header from '../constants/Header';
import * as UserListField from '../constants/UserListField';
import * as MoffRaku from '../constants/MoffRaku';
import MoffRakuList from '../components/Organisms/MoffRakuList';
import _ from 'lodash';
import PageLoader from '../components/Atoms/PageLoader';

type AllState = ReturnType<typeof store.getState>;

const MoffRakuListContainer: FC<RouteComponentProps<{ userId: string }>> = ({ match }) => {
  const userId = match.params.userId;
  const dispatch = useDispatch();
  const moffAPIGlobalState = useSelector((state: AllState) => state.moffAPI);

  const [user, setUser] = useState<UserListField.User>({} as UserListField.User);
  const [plans, setPlans] = useState<MoffRaku.PlanFormat[]>([] as MoffRaku.PlanFormat[]);

  useEffect(() => {
    (async () => {
      document.title = '利用者一覧';
      dispatch(HeaderActions.setPage(Header.MOFF_RAKU_LIST));
      // モフらく計画書情報の取得
      await dispatch(MoffAPIActions.getMoffRakuUserPlansData(userId));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(moffAPIGlobalState.result.user)) {
      //const sortedUsers = duplicateRepeatSort(USERS_SORT_KEYS, moffAPIGlobalState.result, objectQuickSort);
      setUser(moffAPIGlobalState.result.user);
    }
    if (!_.isEmpty(moffAPIGlobalState.result.plans)) {
      //const sortedUsers = duplicateRepeatSort(USERS_SORT_KEYS, moffAPIGlobalState.result, objectQuickSort);
      setPlans(moffAPIGlobalState.result.plans);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffAPIGlobalState.Loaded, moffAPIGlobalState.result]);

  return (
    <>
      {moffAPIGlobalState.Loaded ? null : <PageLoader />}
      {moffAPIGlobalState.Loaded ? <MoffRakuList userId={userId} user={user} plans={plans} /> : null}
    </>
  );
};

export default MoffRakuListContainer;
