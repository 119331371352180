/* 利用者設定・子コンポーネントの生年月日の日コンポーネント */
import React from 'react';
import Enumerable from 'linq';
import { START_DATE } from '../../constants/UserSetting';
import GeneralPrimarySelectBox from '../Atoms/GeneralPrimarySelectBox';
import { getWareki } from '../../utils/dateUtil';
import { Box, MenuItem } from '@material-ui/core';

/**
 * 生年月日の日コンポーネントのprop.
 *
 * @interface DateOfBirthRowProps
 * @extends {React.Props<{}>}
 */
interface DateOfBirthRowProps {
  // その月の最終日.
  birthYear: number;
  setBirthYear: (birthYear: number) => void;
  birthMonth: number;
  setBirthMonth: (birthMonth: number) => void;
  lastDayOfMonth: number;
  birthDay: number;
  setBirthDay: (birthDay: number) => void;
}

const DateOfBirthRow: React.FC<DateOfBirthRowProps> = ({
  birthYear,
  setBirthYear,
  birthMonth,
  setBirthMonth,
  lastDayOfMonth,
  birthDay,
  setBirthDay,
}) => {
  return (
    <>
      <Box display="flex">
        <Box width={{ xs: '110px', sm: '120px', md: '200px' }} mr="10px">
          <GeneralPrimarySelectBox
            selectedValue={birthYear}
            selectBoxChanged={(event) => setBirthYear(Number(event.target.value))}
            dataTest="birth-year"
          >
            {
              // 1900年開始で、今年まで.
              Enumerable.range(START_DATE, new Date().getFullYear() - START_DATE + 1)
                .select<JSX.Element>((yearNo: number) => {
                  const keyYear = `kaigoYear${yearNo}`;

                  // 和暦
                  const JanWareki = getWareki(yearNo, 0, 1);
                  const DecWareki = getWareki(yearNo, 11, 31);
                  const wareki = JanWareki === DecWareki ? JanWareki : `${JanWareki}/${DecWareki}`;

                  return (
                    <MenuItem key={keyYear} value={yearNo}>
                      {yearNo}年（{wareki}）
                    </MenuItem>
                  );
                })
                .toArray()
            }
          </GeneralPrimarySelectBox>
        </Box>
        <Box width={{ xs: '110px', sm: '120px', md: '200px' }} mr="10px">
          <GeneralPrimarySelectBox
            selectedValue={birthMonth}
            selectBoxChanged={(event) => setBirthMonth(Number(event.target.value))}
            dataTest="birth-month"
          >
            {
              // 月(1-12).
              Enumerable.range(1, 12)
                .select<JSX.Element>((yearNo: number) => {
                  const keyMonth = `kaigoMonth${yearNo}`;
                  return (
                    <MenuItem key={keyMonth} value={yearNo}>
                      {yearNo}月
                    </MenuItem>
                  );
                })
                .toArray()
            }
          </GeneralPrimarySelectBox>
        </Box>
        <Box width={{ xs: '110px', sm: '120px', md: '200px' }}>
          <GeneralPrimarySelectBox
            selectedValue={birthDay}
            selectBoxChanged={(event) => setBirthDay(Number(event.target.value))}
            dataTest="birth-day"
          >
            {
              // うるう年を考慮した上での日付.
              Enumerable.range(1, lastDayOfMonth)
                .select<JSX.Element>((yearNo: number) => {
                  const keyDate = `kaigoDate${yearNo}`;
                  return (
                    <MenuItem key={keyDate} value={yearNo}>
                      {yearNo}日
                    </MenuItem>
                  );
                })
                .toArray()
            }
          </GeneralPrimarySelectBox>
        </Box>
      </Box>
    </>
  );
};

export default DateOfBirthRow;
