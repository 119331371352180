import { Reducer } from 'redux';
import { AxiosError } from 'axios';

import { pdfCreateAction } from '../../actions/pdfCreate';
import * as ActionType from '../../constants/pdfCreate';

export interface CreatePdfState {
  Loading: boolean;
  error?: AxiosError | null;
}

export const initialState: CreatePdfState = {
  Loading: false,
};

const pdfCreate: Reducer<CreatePdfState, pdfCreateAction> = (
  state: CreatePdfState = initialState,
  action: pdfCreateAction,
): CreatePdfState => {
  switch (action.type) {
    case ActionType.MOFF_CHECK_PDF_CREATE_START:
      return {
        ...state,
        Loading: true,
      };
    case ActionType.MOFF_CHECK_PDF_CREATE_SUCCEED:
      return {
        ...state,
        Loading: false,
      };
    case ActionType.MOFF_CHECK_PDF_CREATE_FAIL:
      return {
        ...state,
        Loading: false,
        error: action.error,
      };
    case ActionType.KIROKU_PDF_CREATE_START:
      return {
        ...state,
        Loading: true,
      };
    case ActionType.KIROKU_PDF_CREATE_SUCCEED:
      return {
        ...state,
        Loading: false,
      };
    case ActionType.KIROKU_PDF_CREATE_FAIL:
      return {
        ...state,
        Loading: false,
        error: action.error,
      };
    case ActionType.LITERALLY_PDF_CREATE_START:
      return {
        ...state,
        Loading: true,
      };
    case ActionType.LITERALLY_PDF_CREATE_SUCCEED:
      return {
        ...state,
        Loading: false,
      };
    case ActionType.LITERALLY_PDF_CREATE_FAIL:
      return {
        ...state,
        Loading: false,
        error: action.error,
      };
    case ActionType.PRESS_PDF_CREATE_START:
      return {
        ...state,
        Loading: true,
      };
    case ActionType.PRESS_PDF_CREATE_SUCCEED:
      return {
        ...state,
        Loading: false,
      };
    case ActionType.PRESS_PDF_CREATE_FAIL:
      return {
        ...state,
        Loading: false,
        error: action.error,
      };
    default: {
      return state;
    }
  }
};

export default pdfCreate;
