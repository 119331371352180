import { Reducer } from 'redux';
import { AxiosError } from 'axios';

import { reportAction } from '../../actions/Report';
import * as ActionType from '../../constants/Report';
import { ReportActionPayload } from '../../constants/Report';

export interface ReportState {
  Loading: boolean;
  result: ReportActionPayload;
  error?: AxiosError | null;
}

export const initialState: ReportState = {
  Loading: false,
  result: {} as ReportActionPayload,
};

const report: Reducer<ReportState, reportAction> = (
  state: ReportState = initialState,
  action: reportAction,
): ReportState => {
  switch (action.type) {
    case ActionType.MOFF_CHECK_V1_REPORT_START:
    case ActionType.MOFF_CHECK_V2_REPORT_START:
    case ActionType.PRESS_REPORT_START:
    case ActionType.REPORT_COMMENT_SAVE_START:
    case ActionType.REPORT_COMMENTS_GET_START:
      return {
        ...state,
        Loading: true,
      };
    case ActionType.MOFF_CHECK_V1_REPORT_SUCCEED:
      return {
        ...state,
        Loading: false,
        result: {
          ...state.result,
          moffCheckV1: action.result.moffCheckV1,
        },
      };
    case ActionType.MOFF_CHECK_V2_REPORT_SUCCEED:
      return {
        ...state,
        Loading: false,
        result: {
          ...state.result,
          moffCheckV2: action.result.moffCheckV2,
          comment: action.result.comment,
        },
      };
    case ActionType.PRESS_REPORT_SUCCEED:
      return {
        ...state,
        Loading: false,
        result: action.result,
      };
    case ActionType.REPORT_COMMENT_SAVE_SUCCEED:
      return {
        ...state,
        Loading: false,
      };
    case ActionType.REPORT_COMMENTS_GET_SUCCEED:
      return {
        ...state,
        Loading: false,
        result: {
          ...state.result,
          comments: action.result.comments,
        },
      };
    case ActionType.MOFF_CHECK_V1_REPORT_FAIL:
    case ActionType.MOFF_CHECK_V2_REPORT_FAIL:
    case ActionType.PRESS_REPORT_FAIL:
    case ActionType.REPORT_COMMENT_SAVE_FAIL:
    case ActionType.REPORT_COMMENTS_GET_FAIL:
      return {
        ...state,
        Loading: false,
        error: action.error,
      };
    default: {
      return state;
    }
  }
};

export default report;
