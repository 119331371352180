import * as redux from 'redux';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import app from './reducers/App';
import auth from './reducers/Auth';
import password from './reducers/Password';
import moffAPI from './reducers/MoffAPI';
import { moffCheck, moffCheckCount } from './reducers/MoffCheck';
import appUser from './reducers/AppUser';
import interestCheck from './reducers/InterestCheck';
import lifeCheck from './reducers/LifeCheck';
import functionalTrainingPlan from './reducers/FunctionalTrainingPlan';
import barthelIndex from './reducers/BarthelIndex';
import login from './reducers/Login';
import dialog from './reducers/Dialog';
import request_param from './reducers/RequestParam';
import header from './reducers/Header';
import popup from './reducers/Popup';
import footer from './reducers/Footer';
import pdfCreate from './reducers/pdfCreate';
import report from './reducers/Report';
import csvCreate from './reducers/csvCreate';

import { reducer as formReducer } from 'redux-form';

declare module 'redux' {
  export type GenericStoreEnhancer = any;
}

const history = createBrowserHistory();
const loggerMiddleware = createLogger;
let middleware: redux.GenericStoreEnhancer;

if (process.env.NODE_ENV !== 'production') {
  // 開発.
  middleware = redux.applyMiddleware(thunkMiddleware, loggerMiddleware);
} else {
  // 製品版はログを使わない.
  middleware = redux.applyMiddleware(thunkMiddleware);
}

const store = redux.createStore(
  redux.combineReducers({
    app: app,
    auth: auth,
    password: password,
    moffAPI: moffAPI,
    moffCheck: moffCheck,
    moffCheckCount: moffCheckCount,
    appUser: appUser,
    interestCheck: interestCheck,
    lifeCheck: lifeCheck,
    functionalTrainingPlan: functionalTrainingPlan,
    barthelIndex: barthelIndex,
    login: login,
    dialog: dialog,
    header: header,
    popup: popup,
    footer: footer,
    request_param: request_param,
    // signFormの formとの連携
    form: formReducer,
    router: connectRouter(history),
    pdfCreate: pdfCreate,
    report: report,
    csvCreate: csvCreate,
  }),
  middleware,
);

export default store;
