// 詳細・印刷のヘッダ部分.
import React, { FC, memo } from 'react';
import { Box, MenuItem } from '@material-ui/core';

import { Global } from '../../utils/global';
import { dispatchIdentifierToGTM } from '../../utils/commonUtil';

import * as TrainingConst from '../../constants/Training';
import { MOFF_CHECK_COMPARE_LOGIC, MOFF_CHECK_V1_VALUE_LIST } from '../../constants/MoffCheck';

import VideoButton from '../Atoms/VideoButton';
import GeneralPrimaryGhostButton from '../Atoms/GeneralPrimaryGhostButton';
import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import ToggleButton from '../Atoms/ToggleButton';
import GeneralPrimarySelectBox from '../Atoms/GeneralPrimarySelectBox';
import TableIcon from '../Atoms/TableIcon';
import ChartIcon from '../Atoms/ChartIcon';
import MultiSelectBox from '../Atoms/MultiSelectBox';
import { useTrainingUI } from '../../hooks/use-training-ui';
import { useMoffCheck } from '../../hooks/use-moff-check';

interface TrainingHeader {
  popupOpen?: () => void;
  screenType: TrainingConst.SCREEN_TYPE;
  changeState?: (state: TrainingConst.SCREEN_TYPE) => void;
  compareMonth?: MOFF_CHECK_COMPARE_LOGIC;
  changeSelectBox?: (event: any) => void;
  selectItems?: string[];
  addOutputItems?: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

// ローディング中のダミー・利用者の詳細.
const TrainingHeader: FC<TrainingHeader> = ({
  popupOpen = () => null,
  changeState = () => null,
  screenType,
  compareMonth = MOFF_CHECK_COMPARE_LOGIC.default,
  changeSelectBox = () => null,
  selectItems = [],
  addOutputItems = () => null,
}) => {
  const { isToggleOn: trainingToggle, setIsToggleOn: setTrainingToggle } = useTrainingUI();
  const { isToggleOn: moffCheckToggle, setIsToggleOn: setMoffCheckToggle } = useMoffCheck();

  const displayIdentifier = trainingToggle ? 'graph' : 'table';

  switch (screenType) {
    case TrainingConst.SCREEN_TYPE.Detail: {
      return (
        <>
          <Box component="section" p="30px 25px 0">
            <Box display="flex" justifyContent="flex-end" m="25px 0">
              <VideoButton onClick={popupOpen} text={'ロコモ予防と日常生活動作トレーニングの説明を動画で見る'} />
            </Box>
            <Box display="flex" justifyContent="space-between">
              <GeneralPrimaryButton
                txt="長期変化をみる"
                onClick={() => changeState(TrainingConst.SCREEN_TYPE.LongTerm)}
                setCss={'width: 158px; height: 50px;'}
              />
              <Box>
                <GeneralPrimaryGhostButton
                  onClick={() => {
                    window.print();
                    displayIdentifier && dispatchIdentifierToGTM(`detail_${displayIdentifier}_print_moff_training`);
                    return false;
                  }}
                  setCss={'width: 60px; height: 40px;'}
                  innerText="印刷"
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end" m="20px 25px 0">
            <ToggleButton
              isToggleOn={trainingToggle}
              toggleDisplayMode={setTrainingToggle}
              leftText="表"
              rightText="グラフ"
              leftIcon={<TableIcon />}
              rightIcon={<ChartIcon />}
              customCss="width: 110px; height: 50px;"
            />
          </Box>
        </>
      );
    }
    case TrainingConst.SCREEN_TYPE.Compare: {
      return (
        <>
          <Box
            component="section"
            mt="30px"
            lineHeight={0}
            display="flex"
            justifyContent="flex-end"
            displayPrint="none"
          >
            {Global.isApp ? null : (
              <GeneralPrimaryGhostButton
                onClick={() => {
                  window.print();
                  displayIdentifier && dispatchIdentifierToGTM(`compare_${displayIdentifier}_print_moff_training`);
                  return false;
                }}
                innerText={'印刷'}
                setCss={'width: 60px; height: 40px; margin-right: 25px;'}
              />
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" m="20px 25px 0">
            <ToggleButton
              isToggleOn={trainingToggle}
              toggleDisplayMode={setTrainingToggle}
              leftText="表"
              rightText="グラフ"
              leftIcon={<TableIcon />}
              rightIcon={<ChartIcon />}
              customCss="width: 110px; height: 50px;"
            />
          </Box>
        </>
      );
    }
    case TrainingConst.SCREEN_TYPE.LongTerm: {
      return (
        <Box component="section" p="30px 25px 0">
          <Box display="flex" justifyContent="space-between">
            <GeneralPrimaryGhostButton
              onClick={() => changeState(TrainingConst.SCREEN_TYPE.Detail)}
              innerText={'戻る'}
              setCss={'width: 60px; height: 40px;'}
            />
            <GeneralPrimaryGhostButton
              onClick={() => {
                window.print();
                dispatchIdentifierToGTM(`longterm_graph_print_moff_training`);
                return false;
              }}
              innerText={'印刷'}
              setCss={'width: 60px; height: 40px;'}
            />
          </Box>
        </Box>
      );
    }
    case TrainingConst.SCREEN_TYPE.KirokuShow: {
      return (
        <Box component="section" p="30px 25px 0" displayPrint="none">
          <Box display="flex" justifyContent={{ xs: 'center', sm: 'space-between' }} flexWrap="wrap" my="25px">
            <Box>
              <VideoButton onClick={popupOpen} text={'個別機能訓練に関する記録の説明を動画で見る'} />
            </Box>
            <Box mt={{ xs: 2, sm: 0 }}>
              <GeneralPrimaryButton
                txt="印刷する"
                onClick={() => {
                  window.print();
                  dispatchIdentifierToGTM('detail_page_print_kiroku');
                  return false;
                }}
                setCss={'width: 158px; height: 50px;'}
              />
            </Box>
          </Box>
        </Box>
      );
    }
    case TrainingConst.SCREEN_TYPE.PressSelectMonth: {
      return (
        <Box component="section" p="30px 25px 0">
          <Box display="flex" justifyContent="flex-end" m="25px 0">
            <VideoButton onClick={popupOpen} text={'モフトレ通信の説明を動画で見る'} />
          </Box>
        </Box>
      );
    }
    case TrainingConst.SCREEN_TYPE.MoffCheckSelectMonth: {
      return (
        <Box component="section" p="30px 25px 0">
          <Box display="flex" flexWrap="wrap" justifyContent={{ xs: 'flex-start', sm: 'space-between' }}>
            <Box>
              <Box mb={4}>
                <VideoButton onClick={popupOpen} text={'モフトレチェックの説明を動画で見る'} />
              </Box>
            </Box>
            <Box>
              <Box display="flex" flexWrap="wrap" justifyContent={{ xs: 'flex-start', sm: 'space-between' }}>
                <Box alignItems="center" lineHeight="40px" mx={1}>
                  <b>出力形式選択:</b>
                </Box>
                <Box>
                  <ToggleButton
                    isToggleOn={moffCheckToggle}
                    toggleDisplayMode={setMoffCheckToggle}
                    leftText="評価レポート"
                    rightText="一覧表"
                    customCss={'width: 140px; height: 40px;'}
                  />
                </Box>
              </Box>
              <Box
                mt={{ xs: 2, sm: 4 }}
                display="flex"
                flexWrap="wrap"
                justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
              >
                <Box alignItems="center" lineHeight="40px" mx={1}>
                  <b>比較方法選択:</b>
                </Box>
                <Box width="280px">
                  <GeneralPrimarySelectBox
                    selectedValue={compareMonth}
                    size="small"
                    style={{ height: 50 }}
                    selectBoxChanged={changeSelectBox}
                  >
                    <MenuItem value={MOFF_CHECK_COMPARE_LOGIC.default}>3ヶ月間比較</MenuItem>
                    <MenuItem value={MOFF_CHECK_COMPARE_LOGIC.secondOption}>トレーニング開始月＋2ヶ月間比較</MenuItem>
                    <MenuItem value={MOFF_CHECK_COMPARE_LOGIC.thirdOption}>比較月自由選択</MenuItem>
                  </GeneralPrimarySelectBox>
                </Box>
              </Box>
              {!moffCheckToggle ? (
                <Box
                  mt={{ xs: 2, sm: 4 }}
                  display="flex"
                  flexWrap="wrap"
                  justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                  displayPrint="none"
                >
                  <Box alignItems="center" lineHeight="50px" mx={1}>
                    <b>表への追加項目の選択:</b>
                  </Box>
                  <Box width="280px">
                    <MultiSelectBox
                      itemList={MOFF_CHECK_V1_VALUE_LIST}
                      selectedItems={selectItems}
                      checkBoxOnClick={addOutputItems}
                      style={{
                        width: 280,
                      }}
                      helperText="2項目まで選択可能です"
                    />
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      );
    }
    case TrainingConst.SCREEN_TYPE.MoffCheckComment: {
      return (
        <Box component="section" p="30px 25px 0">
          <Box display="flex" flexWrap="wrap" justifyContent={{ xs: 'flex-start', sm: 'space-between' }}>
            <Box>
              <Box mb={4}>
                <GeneralPrimaryGhostButton
                  onClick={() => changeState(TrainingConst.SCREEN_TYPE.MoffCheckSelectMonth)}
                  innerText={'月選択画面に戻る'}
                  setCss={'width: 150px; height: 40px;'}
                />
              </Box>
            </Box>
            <Box>
              <Box display="flex" flexWrap="wrap" justifyContent={{ xs: 'flex-start', sm: 'space-between' }}>
                <Box alignItems="center" lineHeight="40px" mx={1}>
                  <b>出力形式選択:</b>
                </Box>
                <Box>
                  <ToggleButton
                    isToggleOn={moffCheckToggle}
                    toggleDisplayMode={() => null}
                    leftText="評価レポート"
                    rightText="一覧表"
                    customCss={'width: 140px; height: 40px;'}
                    isDisable={true}
                  />
                </Box>
              </Box>
              <Box
                mt={{ xs: 2, sm: 4 }}
                display="flex"
                flexWrap="wrap"
                justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
              >
                <Box alignItems="center" lineHeight="40px" mx={1}>
                  <b>比較方法選択:</b>
                </Box>
                <Box width="280px">
                  <GeneralPrimarySelectBox
                    selectedValue={compareMonth}
                    selectBoxChanged={() => null}
                    size="small"
                    isDisable={true}
                  >
                    <MenuItem value={MOFF_CHECK_COMPARE_LOGIC.default}>3ヶ月間比較</MenuItem>
                    <MenuItem value={MOFF_CHECK_COMPARE_LOGIC.secondOption}>トレーニング開始月＋2ヶ月間比較</MenuItem>
                    <MenuItem value={MOFF_CHECK_COMPARE_LOGIC.thirdOption}>比較月自由選択</MenuItem>
                  </GeneralPrimarySelectBox>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
    case TrainingConst.SCREEN_TYPE.LiterallyReportSelectMonth: {
      return (
        <Box component="section" p="30px 25px 0">
          <Box display="flex" justifyContent="flex-end" m="25px 0">
            <VideoButton onClick={popupOpen} text={'機能訓練レポートの説明を動画で見る'} />
          </Box>
        </Box>
      );
    }
    default: {
      return null;
    }
  }
};

export default memo(TrainingHeader, (prevProps, nextProps) => {
  if (
    prevProps.screenType === nextProps.screenType &&
    prevProps.compareMonth === nextProps.compareMonth &&
    prevProps.selectItems === nextProps.selectItems
  ) {
    return true;
  } else {
    return false;
  }
});
