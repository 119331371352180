/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC, useLayoutEffect, useState, memo } from 'react';
import moment from 'moment';
import { makeCalendar, getDay, getDayFlag } from '../../utils/dateUtil';
import { MonthlyTrainingData } from '../../constants/Training';

export const WEEK_NAMES: string[] = ['日', '月', '火', '水', '木', '金', '土'];
// 月曜開始版.
export const MON_START_WEEK_NAMES: string[] = ['月', '火', '水', '木', '金', '土', '日'];

/* カレンダーのスタイル */
const calendar = css`
  float: right;
  width: 100%;
  height: 370px;
  font-weight: bold;
  color: #333;
  text-align: center;
  background-color: #fff;

  /* 縦幅 */
  table > tfoot > tr > td,
  table > tfoot > tr > th,
  table > thead > tr > td,
  table > thead > tr > th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
  }
  /* 曜日の下のボーダー */
  table > thead > tr > td,
  table > thead > tr > th {
    border-bottom-width: 2px;
  }
  table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
  }
  table > tbody > tr > td,
  table > tbody > tr > th,
  table > tfoot > tr > td,
  table > tfoot > tr > th,
  table > thead > tr > td,
  table > thead > tr > th {
    border: 1px solid #ddd;
  }
  table > tbody > tr > td,
  table > tbody > tr > th,
  table > tfoot > tr > td,
  table > tfoot > tr > th,
  table > thead > tr > td,
  table > thead > tr > th {
    padding-top: 7px;
    padding-bottom: 7px;
    line-height: -5.571429;
    vertical-align: top;
    border-top: 1px solid #ddd;
  }
  table {
    width: 100%;
    th {
      td {
        text-align: center;
      }
    }
    td {
      @media print {
        color: #ddd;
      }
    }
    .active {
      background-color: #edac34;
      @media print {
        color: #333;
        text-decoration: underline;
      }
    }
    th:first-of-type {
      background-color: #feeeff;
    }
    td:hover {
      opacity: 0.6;
    }
    td:first-of-type:not(.active) {
      background-color: #feeeff;
    }
    th:nth-of-type(7) {
      background-color: #dfffff;
    }
    td:nth-of-type(7):not(.active) {
      background-color: #dfffff;
    }
  }
`;

interface CalendarProps {
  /** トレーニング履歴 */
  history: MonthlyTrainingData;
  /** カレンダーの表示月 */
  date: moment.Moment;
}

// カレンダー部分.
const Calendar: FC<CalendarProps> = ({ history, date }) => {
  const [calender, setCalender] = useState<{ day: number | string }[][]>([]);
  const [historyDateFlag, sethistoryDateFlag] = useState<{ [day: string]: boolean }>({});

  useLayoutEffect(() => {
    const trainingDays = Object.keys(history).map((date) => getDay(date));
    const historyDateFlag = getDayFlag(trainingDays);
    const calender = makeCalendar(date.year(), date.month() + 1);

    setCalender(calender);
    sethistoryDateFlag(historyDateFlag);
    // eslint-disable-next-line
  }, [history]);

  return (
    <div css={calendar}>
      <table>
        <thead>
          <tr>
            {WEEK_NAMES.map((week, i) => (
              <th key={`week${i}`}>{week}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {calender.map((week, index) => (
            <tr key={`calender${index}`}>
              {week.map((day: { day: number | string }, weekIndex: number) => (
                <td key={`calennderWeek${index}-${weekIndex}`} className={historyDateFlag[day.day] ? 'active' : ''}>
                  {day.day}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(Calendar, (prevProps, nextProps) => {
  if (prevProps?.history === nextProps?.history) {
    return true;
  } else {
    return false;
  }
});
