// トレーニング.
export interface Training {
  day: string;
  duration: string;
  subject: string;
  instructor: string;
  remarks: string;
  datetime: string;

  print: number;
  datetimeT: string;
  user_id: string;
}

export enum type {
  None = 0,
  Show,
  Edit,
  Delete,
}

// チェックボックスの状況.
export interface CheckData {
  id: number | string;
  selected: boolean;
}

export interface DeleteRecord {
  id: number;
  selected: boolean;
  datetime: string;
}

// 実施記録フォームから受け取った「新規」データの型
export interface PostParamsFormat {
  datetime: string;
  duration: string;
  subject?: string;
  instructor?: string;
  remarks?: string;
  new: boolean;
}

// 実施記録フォームから受け取った「更新」データの型
export interface PutParamsFormat {
  datetime: string;
  duration: string;
  subject?: string;
  instructor?: string;
  remarks?: string;
}

// 記録リクエスト用フォーマット. newがあるかどうかで「新規」・「更新」を識別
export interface KirokuRequestParamsFormat {
  datetime: string;
  duration: string;
  subject?: string;
  instructor?: string;
  remarks?: string;
  new?: boolean;
}

// 記録フォームに入力時newはbooleanではなくstring、indexもnumberではなくstringになる
export type KirokuFormParamsFormat = KirokuRequestParamsFormat & {
  index: string;
};

export interface SuggestWord {
  title: string;
  text: string;
}

// 入力補完の定型文
export const MOFF_TRANING = [
  '腕上げ下ろし (モフトレ)',
  '腕左右 (モフトレ)',
  'ひざのばし (モフトレ)',
  'ももあげ (モフトレ)',
  '股開閉 (モフトレ)',
  'よこあげ (モフトレ)',
  'おしり (モフトレ)',
  '上部腹筋 (モフトレ)',
  'ローイング (モフトレ)',
  '腕伸ばし (モフトレ)',
  '股関節外ひねり (モフトレ)',
  'アームカール (モフトレ)',
  'からだ捻り (モフトレ)',
  'レッグカール (モフトレ)',
  '前腕捻り (モフトレ)',
  'カーフレイズ (モフトレ)',
  '腹斜筋 (モフトレ)',
  '座位ステップ (モフトレ)',
  'アキレス腱 (モフトレ)',
  '肩の外転 (モフトレ)',
  'よこ腹筋 (モフトレ)',
  'お風呂またぎ (モフトレ)',
  'お風呂洗髪 (モフトレ)',
  'トイレ立ち上がり (モフトレ)',
  'トイレ着脱衣 (モフトレ)',
  '移動歩行 (モフトレ)',
  '洗濯物干し (モフトレ)',
  '段差昇降 (モフトレ)',
  '移動下肢支持 (モフトレ)',
  '食事口に運ぶ (モフトレ)',
  '整容洗顔 (モフトレ)',
  '調理切る (モフトレ)',
  '立位バランス (モフトレ)',
  '更衣上衣 (モフトレ)',
  '更衣下衣 (モフトレ)',
  '更衣靴下① (モフトレ)',
  '更衣靴下② (モフトレ)',
  'テーブル拭き (モフトレ)',
  '掃除機かけ (モフトレ)',
  '窓ふき (モフトレ)',
  'ラインジャンプ (モフトレ)',
  '番号記憶 (モフトレ)',
  'カウント運動 (モフトレ)',
  'つなげてメロディー (モフトレ)',
];

export const ADL_YOGA = [
  '食事のヨガ (ADL別ヨガ)',
  '車椅子からベッドへの移動のヨガ (ADL別ヨガ)',
  'トイレ動作のヨガ (ADL別ヨガ)',
  '入浴のヨガ (ADL別ヨガ)',
  '階段昇降のヨガ (ADL別ヨガ)',
  '歩行のヨガ (ADL別ヨガ)',
];
