import { AppUserActionType } from '../../constants/AppUser';

const initialState = {
  Loading: false,
  Loaded: false,
};

export default function appUser(state: any = initialState, action: any) {
  switch (action.type) {
    case AppUserActionType.SUCCESS:
      return Object.assign({}, state, {
        ...state,
        Loading: false,
        Loaded: true,
        result: action.result,
      });
    case AppUserActionType.ERROR:
      return Object.assign({}, state, {
        ...state,
        Loading: false,
        Loaded: true,
        result: action.result,
      });
    case AppUserActionType.LOADING:
      return Object.assign({}, state, {
        ...state,
        Loading: true,
        Loaded: false,
      });
    default:
      return state;
  }
}
