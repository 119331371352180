/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core';
import React, { memo } from 'react';

const loadingAnimation = keyframes({
  from: {
    transform: 'rotate(0deg)',
    opacity: 0.2,
  },
  '50%': {
    transform: 'rotate(180deg)',
    opacity: 1.0,
  },
  to: {
    transform: 'rotate(360deg)',
    opacity: 0.2,
  },
});

const loadingStyle = css({
  zIndex: 2,
  width: '200px',
  height: '200px',
  borderRadius: '50%',
  border: '8px solid #fff',
  borderRightColor: 'transparent',
  animation: `${loadingAnimation} 1s linear infinite`,
});

const loadingSmallStyle = css(loadingStyle, {
  top: '35%',
  left: 'auto',
  width: '20px',
  height: '20px',
  border: '3px solid gray',
  borderRightColor: 'transparent',
  display: 'inline-block',
  'vertical-align': 'middle',
});

// TODO 大きいローディングもこのコンポーネントを使って共通化する
const SmallLoader: React.FC = () => {
  return <div css={loadingSmallStyle} />;
};

export default memo(SmallLoader);
