export class Global {
  // アプリ（Unity）か判定して使う.
  public static isApp = false;
  public static kanaSerach: Function = () => {
    return;
  };

  // ロコモの開始、終わりの年月は残るので保持しておく.
  public static locomoStartYearMonth = '';
  public static locomoEndYearMonth = '';

  // ADLの開始、終わりの年月は残るので保持しておく.
  public static adlStartYearMonth = '';
  public static adlEndYearMonth = '';
}
