import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store/configureStore';

import * as HeaderActions from '../actions/Header';
import * as MoffAPIActions from '../actions/MoffAPI';
import * as Header from '../constants/Header';
import UserSelectPress from '../components/Organisms/UserSelectPress';

type AllState = ReturnType<typeof store.getState>;

const UserSelectPressContainer: FC = () => {
  const dispatch = useDispatch();
  const moffAPI = useSelector((state: AllState) => state.moffAPI);

  useEffect(() => {
    (async () => {
      document.title = 'モフトレ通信';
      dispatch(HeaderActions.setPage(Header.USER_SELECT_PRESS));
      await dispatch(MoffAPIActions.getUsers());
      dispatch(MoffAPIActions.duplicateRepeatSort());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <UserSelectPress moffAPI={moffAPI} />;
};

export default UserSelectPressContainer;
