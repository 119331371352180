import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import GeneralPrimarySelectButton from '../Atoms/GeneralPrimarySelectButton';

const useStyles = makeStyles({
  genderList: {
    display: 'table',
    width: '100%',
    maxWidth: '224px',
    overflow: 'hidden',
    tableLayout: 'fixed',
  },
  listLi: {
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
});

interface SelectGenderProps {
  gender: number;
  setGender: (gender: number) => void;
}

const SelectGender: React.FC<SelectGenderProps> = ({ gender, setGender }) => {
  const classes = useStyles();

  return (
    <Box>
      <ul className={classes.genderList} data-test="genderList">
        <li className={classes.listLi}>
          <GeneralPrimarySelectButton txt="男性" onClick={() => setGender(0)} selected={gender === 0} />
        </li>
        <li className={classes.listLi}>
          <GeneralPrimarySelectButton txt="女性" onClick={() => setGender(1)} selected={gender === 1} />
        </li>
      </ul>
    </Box>
  );
};

export default SelectGender;
