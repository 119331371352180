/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC, Fragment, memo } from 'react';

import * as images from '../../../img/index';
import { PressReportResponse } from '../../../constants/Report';
import { getBadge } from '../../../utils/PressModule';

const pageBodyData = css`
  position: absolute;
  top: 0;
  padding: 18px 58px 0;
  width: 100%;
  height: 100%;
`;

const title = css`
  font-size: 25px;
  font-weight: bold;
  color: #263a5e;
`;

const restTraining = css`
  width: 38% !important;
  margin: 30px 170px 0;
`;

const pageBodyGraph = css`
  position: absolute;
  top: 60px;
  width: 48mm;
  height: 48mm;
  canvas {
    width: 100%;
    height: 100%;
  }
`;

const pageBodyList = css`
  position: absolute;
  top: 16mm;
  left: 270px;
  width: 60mm;
  ul {
    img {
      width: 4mm;
    }
  }
  li {
    font-family: '游ゴシック体', 'YuGothic', 'ヒラギノ角ゴ ProN W6', 'HiraKakuProN-W6', 'メイリオ', 'Meiryo', sans-serif;
    font-size: 10pt;
    font-weight: bold;
    color: #263a5e;
    margin-bottom: 2mm;
  }
`;

const pageBodyListText = css`
  font-size: 10px;
  font-weight: bold;
`;

const bodyResult = css`
  position: absolute;
  top: 0;
  right: 40px;
  width: 40mm;
`;

const bodyLabel = css`
  padding: 1mm 0;
  font-weight: bold;
  color: #263a5e;
  text-align: center;
`;

const bodyCount = css`
  padding: 1mm 0;
  text-align: center;
`;

const bodyCountInner = css`
  display: inline-block;
  height: 100%;
  margin: 0 auto;
`;

const bodyCountNumber = css`
  font-size: 26pt;
  font-weight: bold;
  color: #d85656;
`;

const bodyCountKai = css`
  font-size: 14pt;
  font-weight: bold;
  color: #d85656;
`;

interface PressResultReportProps {
  trainings: PressReportResponse['data'][0]['trainings'];
  refChart: any;
}

const PressResultReport: FC<PressResultReportProps> = memo(({ trainings, refChart }) => {
  return (
    <Fragment>
      <div css={pageBodyData}>
        <p css={title}>実施したトレーニング</p>
        {trainings.length === 0 ? (
          <img src={images.badge0} alt="おやすみ" css={restTraining} />
        ) : (
          <Fragment>
            <div css={pageBodyGraph}>
              {trainings[0]['count'] > 0 ? (
                <canvas
                  ref={(chart) => {
                    refChart.current = chart;
                  }}
                  width="236"
                  height="236"
                />
              ) : (
                <img src={images.dummy_pie_graph} alt="dummy pie graph" />
              )}
            </div>
            <div css={pageBodyList}>
              <ul data-test="bd_list_inner">
                {trainings.map((training, i) => {
                  return (
                    <li key={`icon_list_${i}`}>
                      <img src={images[`icon_list${i}`]} alt="icon list" />
                      {training.name}&hellip;<span>{training.count}</span>回
                    </li>
                  );
                })}
              </ul>
              <p css={pageBodyListText}>
                ※ 左右で実施可能なトレーニングは、
                <br />
                合計の結果を表示
              </p>
            </div>
            <div css={bodyResult}>
              {getBadge()}
              <div css={bodyLabel} data-test="best-training-name">
                <span>{trainings[0]['name']}</span>
              </div>
              <div css={bodyCount}>
                <div css={bodyCountInner} data-test="best-training-count">
                  <span css={bodyCountNumber}>{trainings[0]['count']}</span>
                  <span css={bodyCountKai}>回</span>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
});

PressResultReport.displayName = 'pressResultReport';
export default PressResultReport;
