/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { forwardRef, Ref } from 'react';

const textForm = (customCss: string) => css`
  max-width: 100%;
  -moz-box-sizing: border-box;
  /* Firefox */
  -webkit-box-sizing: border-box; /* Chrome, Safari */
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid #aaa;
  border-radius: 4px;
  :disabled {
    background-color: rgb(240, 240, 240);
  }
  ${customCss}
`;

interface GeneralPrimaryTextAreaProps {
  /** テキストエリアの名前 */
  name?: string;
  /** 行数 */
  rows?: number;
  /** 列数 */
  cols?: number;
  /** プレイスホルダー */
  placeholder?: string;
  /** 上限文字数 */
  maxLength?: number;
  /** disableかどうか */
  disabled?: boolean;
  /** 外部から渡すスタイル */
  customCss?: string;
  /** フォームの値を変更した時のイベントハンドラ */
  onChange?: (event: any) => void;
  /** フォームの値 */
  value?: any;
}

const GeneralPrimaryTextArea = forwardRef((props: GeneralPrimaryTextAreaProps, ref: Ref<HTMLTextAreaElement>) => {
  const {
    name = undefined,
    rows = undefined,
    cols = undefined,
    placeholder = '',
    maxLength = undefined,
    disabled = false,
    customCss = '',
    onChange = props.onChange,
    value = props.value,
  } = props;

  return (
    <textarea
      ref={ref}
      name={name}
      css={textForm(customCss)}
      rows={rows}
      cols={cols}
      placeholder={placeholder}
      maxLength={maxLength}
      disabled={disabled}
      data-test="general-primary-text-area"
      onChange={onChange}
      value={value}
    />
  );
});

GeneralPrimaryTextArea.displayName = 'GeneralPrimaryTextArea';
export default GeneralPrimaryTextArea;
