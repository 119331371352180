/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';

const baseBtnCss = (setCss: string, isDisable: boolean) => {
  return css`
    ${setCss}
    color: #fff;
    background: #f37640;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ddd9d4;
    background: ${isDisable
      ? 'linear-gradient(0deg, rgba(180, 180, 180, 1) 0%, rgb(189, 189, 189) 100%)'
      : 'linear-gradient(0deg, rgba(234, 109, 54, 1) 0%, rgba(239, 148, 107, 1) 100%)'};

    &:hover {
      opacity: 0.9;
    }

    @media print {
      display: none;
    }
  `;
};

interface GeneralPrimaryButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** ボタンのテキスト */
  txt: string | JSX.Element;
  /** 外部から渡すスタイル */
  setCss: string;
  /** disableにするかどうか */
  isDisable?: boolean;
  /** クリックした時のイベントハンドラ */
  onClick?: () => void;
}

const GeneralPrimaryButton: FC<GeneralPrimaryButtonProps> = ({ txt, setCss, isDisable = false, onClick, type }) => {
  return (
    <button css={baseBtnCss(setCss, isDisable)} onClick={onClick} type={type} data-test="general-primary-button">
      {txt}
    </button>
  );
};

export default GeneralPrimaryButton;
