import { Reducer } from 'redux';
import { AxiosError } from 'axios';

import { csvCreateAction } from '../../actions/csvCreate';
import * as ActionType from '../../constants/csvCreate';

export interface CreateCsvState {
  Loading: boolean;
  result?: {
    interestCheck?: { downloadPath: string | null };
    lifeCheck?: { downloadPath: string | null };
  } | null;
  error?: AxiosError | null;
}

export const initialState: CreateCsvState = {
  Loading: false,
  result: null,
};

const csvCreate: Reducer<CreateCsvState, csvCreateAction> = (
  state: CreateCsvState = initialState,
  action: csvCreateAction,
): CreateCsvState => {
  switch (action.type) {
    case ActionType.CSV_CREATE_START:
      return {
        ...state,
        Loading: true,
      };
    case ActionType.CSV_CREATE_SUCCEED:
      return {
        ...state,
        Loading: false,
        result: action.result,
      };
    case ActionType.CSV_CREATE_FAIL:
      return {
        ...state,
        Loading: false,
        error: action.error,
      };
    default: {
      return state;
    }
  }
};

export default csvCreate;
