// amplifyの一部ファンクションがIE11でエラーになるため、babel-polyfillで補完
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
// import App from './components/App';
import App from './containers/App';
import registerServiceWorker from './registerServiceWorker';
import './index.css';
import { Provider } from 'react-redux';
import store from './store/configureStore';
import TagManager from 'react-gtm-module';
import { amplifyConfig } from './constants/AWS';
import { theme } from './constants/MaterialUISettings';

amplifyConfig();

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID || '',
  dataLayerName: 'PageDataLayer',
  dataLayer: {},
  auth: process.env.REACT_APP_GTM_AUTH || '',
  preview: process.env.REACT_APP_GTM_PREVIEW || '',
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

registerServiceWorker();
