import React, { FC, useEffect, useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import MoffRakuForm from '../components/Organisms/MoffRakuForm';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../constants/UserListField';
import { PlanFormat, BundleMaster, Institution, ICF, Support, BasicInfoFormat } from '../constants/MoffRaku';
import store from '../store/configureStore';
import * as HeaderActions from '../actions/Header';
import * as Header from '../constants/Header';
import * as MoffAPIActions from '../actions/MoffAPI';
// import Enumerable from 'linq';
import _ from 'lodash';
import PageLoader from '../components/Atoms/PageLoader';

type AllState = ReturnType<typeof store.getState>;

const MoffRakuFormContainer: FC<RouteComponentProps<{ userId: string; planId: string }>> = ({ match }) => {
  const userId = match.params.userId;
  const planId = match.params.planId;
  const dispatch = useDispatch();
  const moffAPIGlobalState = useSelector((state: AllState) => state.moffAPI);

  const [user, setUser] = useState({} as User);
  const [institution, setInstitution] = useState({} as Institution);
  const [basicInfo, setBasicInfo] = useState({} as BasicInfoFormat);
  const [plan, setPlan] = useState({} as PlanFormat);
  const [bundle, setBundle] = useState([] as BundleMaster[]);
  const [icfactivity, setICFActivity] = useState([] as ICF[]);
  const [icffunction, setICFFunction] = useState([] as ICF[]);
  const [icfparticipation, setICFParticipation] = useState([] as ICF[]);
  const [support, setSupport] = useState([] as Support[]);

  const isCreated = useSelector((state: AllState) => state.moffAPI.result.is_created);
  const isFirstRenderCreated = useRef(true);
  const isUpdated = useSelector((state: AllState) => state.moffAPI.result.is_updated);
  const isFirstRenderUpdated = useRef(true);
  const isDeleted = useSelector((state: AllState) => state.moffAPI.result.is_deleted);
  const isFirstRenderDeleted = useRef(true);

  // 「保存」ボタン押下時の処理.
  const postPlan = async (data: PlanFormat) => {
    if (moffAPIGlobalState.Loading) {
      return;
    }
    await dispatch(MoffAPIActions.postMoffRakuPlan(userId, data));
  };
  const putPlan = async (data: PlanFormat) => {
    if (moffAPIGlobalState.Loading) {
      return;
    }
    await dispatch(MoffAPIActions.putMoffRakuPlan(userId, planId, data));
  };
  const getPlanFile = async (data: PlanFormat) => {
    if (moffAPIGlobalState.Loading) {
      return;
    }
    if (window.confirm('この内容を保存して出力します。よろしいですか？')) {
      await dispatch(MoffAPIActions.getMoffRakuPlanFile(userId, planId, data));
    }
  };
  // 「削除」ボタン押下時の処理.
  const deletePlan = async (userId: string, planId: string) => {
    if (moffAPIGlobalState.Loading) {
      return;
    }
    if (window.confirm('本当に削除してよろしいですか？')) {
      await dispatch(MoffAPIActions.deleteMoffRakuPlan(userId, planId));
    }
  };

  useEffect(() => {
    (async () => {
      document.title = 'モフらく計画書作成';
      dispatch(HeaderActions.setPage(Header.MOFF_RAKU_FORM));
      await dispatch(MoffAPIActions.getMoffRakuUserPlanData(userId, planId));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 登録完了後の処理
  useEffect(() => {
    if (isFirstRenderCreated.current) {
      isFirstRenderCreated.current = false;
    } else {
      if (isCreated) {
        const message = '登録されました。';
        alert(message);
        // 戻す.
        //TODO 開発中はコメントアウト
        window.location.href = '/moff_raku/' + userId;
      } else {
        //TODO 開発中はコメントアウト
        window.location.href = '/moff_raku_edit/' + userId + '/' + planId;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreated]);

  // 更新完了後の処理
  useEffect(() => {
    if (isFirstRenderUpdated.current) {
      isFirstRenderUpdated.current = false;
    } else {
      if (isUpdated) {
        const message = '更新されました。';
        alert(message);
        // 更新後のデータを再取得を下記に追加したので、ここは削除
        // window.location.href = '/moff_raku/' + userId;
      } else {
        // window.location.href = '/moff_raku_edit/' + userId + '/' + planId;
      }
      // 更新後のデータを再取得.
      (async () => {
        document.title = 'モフらく計画書作成';
        dispatch(HeaderActions.setPage(Header.MOFF_RAKU_FORM));
        await dispatch(MoffAPIActions.getMoffRakuUserPlanData(userId, planId));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  // 削除完了後の処理
  useEffect(() => {
    if (isFirstRenderDeleted.current) {
      isFirstRenderDeleted.current = false;
    } else {
      if (isDeleted) {
        const message = '削除されました。';
        alert(message);
        // 戻す.
        window.location.href = '/moff_raku/' + userId;
      } else {
        window.location.href = '/moff_raku_edit/' + userId + '/' + planId;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleted]);

  useEffect(() => {
    const usersBasicInfo: BasicInfoFormat[] = moffAPIGlobalState.result.usersBasicInfo;
    const user_basicinfo =
      usersBasicInfo !== undefined ? usersBasicInfo.find((item) => item.user_id === user.user_id) : undefined;
    if (user_basicinfo !== undefined) setBasicInfo(user_basicinfo);
  }, [user, moffAPIGlobalState.result.usersBasicInfo]);

  useEffect(() => {
    if (moffAPIGlobalState.result !== undefined) {
      if (!_.isEmpty(moffAPIGlobalState.result.user)) {
        setUser(moffAPIGlobalState.result.user);
      }
      if (!_.isEmpty(moffAPIGlobalState.result.institution)) {
        setInstitution(moffAPIGlobalState.result.institution);
      }
      if (!_.isEmpty(moffAPIGlobalState.result.plan)) {
        setPlan(moffAPIGlobalState.result.plan);
      }
      if (!_.isEmpty(moffAPIGlobalState.result.bundle)) {
        setBundle(moffAPIGlobalState.result.bundle);
      }
      if (!_.isEmpty(moffAPIGlobalState.result.icfactivity)) {
        setICFActivity(moffAPIGlobalState.result.icfactivity);
      }
      if (!_.isEmpty(moffAPIGlobalState.result.icffunction)) {
        setICFFunction(moffAPIGlobalState.result.icffunction);
      }
      if (!_.isEmpty(moffAPIGlobalState.result.icfparticipation)) {
        setICFParticipation(moffAPIGlobalState.result.icfparticipation);
      }
      if (!_.isEmpty(moffAPIGlobalState.result.support)) {
        setSupport(moffAPIGlobalState.result.support);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffAPIGlobalState.Loaded, moffAPIGlobalState.result]);

  return (
    <>
      {moffAPIGlobalState.Loaded ? null : <PageLoader />}
      {moffAPIGlobalState.Loaded ? (
        <MoffRakuForm
          userId={userId}
          planId={planId}
          user={user}
          institution={institution}
          basicInfo={basicInfo}
          // master
          bundle={bundle}
          icfactivity={icfactivity}
          icffunction={icffunction}
          icfparticipation={icfparticipation}
          support={support}
          // plan
          plan={plan}
          setPlan={setPlan}
          postPlan={postPlan}
          putPlan={putPlan}
          getPlanFile={getPlanFile}
          deletePlan={deletePlan}
        />
      ) : null}
    </>
  );
};

export default MoffRakuFormContainer;
