/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';

import { makeStyles, TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  resize: {
    fontSize: '1.1em',
    '&::placeholder': {
      color: 'black',
    },
  },
}));

interface GeneralPrimaryInput {
  /** フォームの値 */
  value: any;
  /** フォームの値を変更した時のイベントハンドラ */
  onChange: (event: any) => void;
  /** プレイスホルダ */
  placeholder?: string;
  /** テスト用 key_id */
  dataTest?: string;
}

const GeneralPrimaryInput: FC<GeneralPrimaryInput> = ({ value, onChange, placeholder, dataTest }) => {
  const classes = useStyles();
  return (
    <TextField
      fullWidth
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      variant="outlined"
      data-test={dataTest}
      InputProps={{
        classes: {
          input: classes.resize,
        },
      }}
    />
  );
};

export default GeneralPrimaryInput;
