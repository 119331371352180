// ユーザー詳細.
import React, { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

import store from '../store/configureStore';

import * as AppUserActions from '../actions/AppUser';
import * as MoffAPIActions from '../actions/MoffAPI';
import * as HeaderActions from '../actions/Header';
import * as InterestCheckActions from '../actions/InterestCheck';
import * as LifeCheckActions from '../actions/LifeCheck';
import * as BarthelIndexActions from '../actions/Adl';
import * as csvCreateActions from '../actions/csvCreate';
import * as FooterActions from '../actions/Footer';

import * as Header from '../constants/Header';
import * as UserListField from '../constants/UserListField';
import * as InterestCheck from '../constants/InterestCheck';
import * as FunctionalTrainingPlan from '../constants/MoffRaku';

import {
  objectQuickSort,
  duplicateRepeatSort,
  csvFileDownload,
  waitTimeMs,
  osJudgement,
  dispatchIdentifierToGTM,
} from '../utils/commonUtil';

import PageLoader from '../components/Atoms/PageLoader';
import LifeCSVOutput from '../components/Organisms/LifeCSVOutput';
import { CSV_FILE_DOWNLOAD_INTERVAL, CSV_OUTPUT_ITEMS, SERVICE_CODE, ADL_CODE } from '../constants/csvCreate';
import { ResponseData } from '../constants/AppUser';

type AllState = ReturnType<typeof store.getState>;

const LifeCSVOutputContainer: FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const moffAPIGlobalState = useSelector((state: AllState) => state.moffAPI);
  const csvCreateGlobalState = useSelector((state: AllState) => state.csvCreate);
  const appUserGlobalState = useSelector((state: AllState) => state.appUser);
  const interestCheckGlobalState = useSelector((state: AllState) => state.interestCheck);
  const lifeCheckGlobalState = useSelector((state: AllState) => state.lifeCheck);
  const functionalTrainingPlanGlobalState = useSelector((state: AllState) => state.functionalTrainingPlan);
  const barthelIndexGlobalState = useSelector((state: AllState) => state.barthelIndex);

  const [date, setDate] = useState(moment());
  const [, setDateYYYYMM] = useState(moment().format('YYYY-MM'));
  const [cachedUsers, setCachedUsers] = useState<UserListField.User[]>([] as UserListField.User[]);
  const [cachedAppUsers, setCachedAppUsers] = useState<ResponseData[]>([] as ResponseData[]);
  const refUsersList = useRef<any>();

  const [toggleLifeCheck, setToggleLifeCheck] = useState(true);
  const [toggleInterestCheck, setToggleInterestCheck] = useState(false);
  const [toggleFunctionalTrainingPlan, setToggleFunctionalTrainingPlan] = useState(false);
  const [toggleAdl, setToggleAdl] = useState(false);
  const [outputItemNames, setOutputItemNames] = useState<CSV_OUTPUT_ITEMS['name'][]>([CSV_OUTPUT_ITEMS.lifeCheck.name]);
  const [serviceCode, setServiceCode] = useState<SERVICE_CODE>(SERVICE_CODE.dayCare);
  const [adlCode, setAdlCode] = useState<ADL_CODE>(ADL_CODE.first);

  // 最初に一回ユーザー一覧取得のために通信.
  useEffect(() => {
    document.title = 'LIFE CSV出力';
    dispatch(HeaderActions.setPage(Header.LIFE_CSV_OUTPUT));
    dispatch(FooterActions.display('hidden'));
    dispatch(MoffAPIActions.getUsers());
    dispatch(AppUserActions.checkAppUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 利用者情報とuniqueId情報を取得したあとに、「生活機能チェック」のデータを取得
  useEffect(() => {
    if (
      _.isEmpty(cachedAppUsers) &&
      !_.isEmpty(moffAPIGlobalState.result) &&
      !_.isEmpty(appUserGlobalState.result?.uniqueIdsInfo)
    ) {
      const enabledUserIds = moffAPIGlobalState.result.map((user: UserListField.User) => user.user_id);
      const enabledUniqueIds = appUserGlobalState.result?.uniqueIdsInfo.filter((uniqueId: ResponseData) =>
        enabledUserIds.includes(uniqueId.app_user_id),
      );
      setCachedAppUsers(enabledUniqueIds);
      dispatch(LifeCheckActions.getLifeCheckRecordByMonth(enabledUniqueIds, date.format('YYYY-MM')));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffAPIGlobalState.result, appUserGlobalState.Loaded]);

  useEffect(() => {
    if (!_.isEmpty(moffAPIGlobalState.result) && !_.isEmpty(lifeCheckGlobalState.result)) {
      const userIds = lifeCheckGlobalState.result?.monthUsersRecords?.map((record) => record.userId);
      const filteredUsers = moffAPIGlobalState.result.filter((user: UserListField.User) =>
        userIds.includes(user.user_id),
      );
      setCachedUsers(
        !_.isEmpty(filteredUsers) && duplicateRepeatSort(UserListField.USERS_SORT_KEYS, filteredUsers, objectQuickSort),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifeCheckGlobalState.result]);

  useEffect(() => {
    if (!_.isEmpty(moffAPIGlobalState.result) && !_.isEmpty(interestCheckGlobalState.result)) {
      const monthUsersRecords: InterestCheck.MonthlyUsersData['monthUsersRecords'] =
        interestCheckGlobalState.result?.monthUsersRecords;
      const userIds = monthUsersRecords?.map((record) => record.userId);
      const filteredUsers = moffAPIGlobalState.result.filter((user: UserListField.User) =>
        userIds.includes(user.user_id),
      );
      setCachedUsers(
        !_.isEmpty(filteredUsers) && duplicateRepeatSort(UserListField.USERS_SORT_KEYS, filteredUsers, objectQuickSort),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interestCheckGlobalState.result]);

  useEffect(() => {
    if (!_.isEmpty(moffAPIGlobalState.result) && !_.isEmpty(barthelIndexGlobalState.result)) {
      const monthUsersRecords: InterestCheck.MonthlyUsersData['monthUsersRecords'] =
        barthelIndexGlobalState.result?.monthUsersRecords;
      const userIds = monthUsersRecords?.map((record) => record.userId);
      const filteredUsers = moffAPIGlobalState.result.filter((user: UserListField.User) =>
        userIds.includes(user.user_id),
      );
      setCachedUsers(
        !_.isEmpty(filteredUsers) && duplicateRepeatSort(UserListField.USERS_SORT_KEYS, filteredUsers, objectQuickSort),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barthelIndexGlobalState.result]);

  useEffect(() => {
    if (!_.isEmpty(moffAPIGlobalState.result) && !_.isEmpty(functionalTrainingPlanGlobalState.result)) {
      const monthUsersRecords: FunctionalTrainingPlan.MonthlyUsersData['monthUsersRecords'] =
        functionalTrainingPlanGlobalState.result?.monthUsersRecords;
      const userIds = monthUsersRecords?.map((record) => record.userId);
      const filteredUsers = moffAPIGlobalState.result.filter((user: UserListField.User) =>
        userIds.includes(user.user_id),
      );
      setCachedUsers(
        !_.isEmpty(filteredUsers) && duplicateRepeatSort(UserListField.USERS_SORT_KEYS, filteredUsers, objectQuickSort),
      );
    }
  }, [functionalTrainingPlanGlobalState.result]);

  useEffect(() => {
    if (!csvCreateGlobalState.Loading && csvCreateGlobalState?.result) {
      const result = csvCreateGlobalState?.result ?? {};
      (async () => {
        const messages = await Promise.all(
          Object.keys(result).map(async (key, i) => {
            await waitTimeMs((i + 1) * CSV_FILE_DOWNLOAD_INTERVAL);
            result[key].downloadPath && csvFileDownload(result[key].downloadPath, key);
            dispatchIdentifierToGTM(`life_csv_output_${CSV_OUTPUT_ITEMS[key].url}`);
            return result[key].downloadPath
              ? `【${CSV_OUTPUT_ITEMS[key].name}】の${date.format('YYYY年MM月')}分のデータを出力しました。`
              : `【${CSV_OUTPUT_ITEMS[key].name}】のデータが${date.format('YYYY年MM月')}に存在しませんでした。`;
          }),
        );
        alert(messages.join('\n'));
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvCreateGlobalState.Loading]);

  // 月選択画面 矢印のアクション
  const minusMonthly = () => {
    const lastMonth = date.subtract(1, 'months');
    setDate(lastMonth);
    setDateYYYYMM(lastMonth.format('YYYY-MM'));
    toggleLifeCheck &&
      dispatch(LifeCheckActions.getLifeCheckRecordByMonth(cachedAppUsers, lastMonth.format('YYYY-MM')));
    toggleInterestCheck &&
      dispatch(InterestCheckActions.getInterestCheckRecordByMonth(cachedAppUsers, lastMonth.format('YYYY-MM')));
    toggleFunctionalTrainingPlan &&
      dispatch(MoffAPIActions.getFunctionalTrainingPlansFromTo(cachedAppUsers, lastMonth.format('YYYY-MM')));
    toggleAdl &&
      dispatch(BarthelIndexActions.getBarthelIndexRecordByMonth(cachedAppUsers, lastMonth.format('YYYY-MM')));
    dispatchIdentifierToGTM('life_csv_change_month');
  };

  const plusMonthly = () => {
    const nextMonth = date.add(1, 'months');
    setDate(nextMonth);
    setDateYYYYMM(nextMonth.format('YYYY-MM'));
    toggleLifeCheck &&
      dispatch(LifeCheckActions.getLifeCheckRecordByMonth(cachedAppUsers, nextMonth.format('YYYY-MM')));
    toggleInterestCheck &&
      dispatch(InterestCheckActions.getInterestCheckRecordByMonth(cachedAppUsers, nextMonth.format('YYYY-MM')));
    toggleFunctionalTrainingPlan &&
      dispatch(MoffAPIActions.getFunctionalTrainingPlansFromTo(cachedAppUsers, nextMonth.format('YYYY-MM')));
    toggleAdl &&
      dispatch(BarthelIndexActions.getBarthelIndexRecordByMonth(cachedAppUsers, nextMonth.format('YYYY-MM')));
    dispatchIdentifierToGTM('life_csv_change_month');
  };

  // トレーニング記録取得のAPIを実行
  const runAPICsvOutput = async () => {
    // ローディングされている最中はボタンを押しても、APIが送信されない
    if (moffAPIGlobalState.Loading) return;
    if (outputItemNames.length === 0) {
      alert('出力項目を1つ以上選択してください。');
      return;
    }
    const requestParams = {
      year: date.year(),
      month: date.month() + 1,
      service_code: serviceCode.id,
      adl_code: adlCode.id,
    };
    const outputItems = _.filter(CSV_OUTPUT_ITEMS, (item) => outputItemNames.includes(item.name));
    dispatch(csvCreateActions.createCSV(requestParams, outputItems));
  };

  const handleCheckBoxClick = (event: React.ChangeEvent<{ value: unknown }>) => {
    const isPC = osJudgement() === 'PC';
    if (isPC || (!isPC && (event?.target?.value as CSV_OUTPUT_ITEMS['name'][]).length <= 1)) {
      setOutputItemNames(event?.target?.value as CSV_OUTPUT_ITEMS['name'][]);
    }
  };

  const selectBoxChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
    const service_id = event?.target?.value as SERVICE_CODE['id'];
    const service_code_info = _.find(SERVICE_CODE, (code) => code.id === service_id);
    setServiceCode(service_code_info ?? SERVICE_CODE.dayCare);
  };

  const selectADLBoxChanged = (event: React.ChangeEvent<{ value: unknown }>) => {
    const adl_id = event?.target?.value as ADL_CODE['id'];
    const adl_code_info = _.find(ADL_CODE, (code) => code.id === adl_id);
    setAdlCode(adl_code_info ?? ADL_CODE.first);
  };

  const switchToggleLifeCheck = () => {
    dispatch(LifeCheckActions.getLifeCheckRecordByMonth(cachedAppUsers, date.format('YYYY-MM')));
    setToggleInterestCheck(false);
    setToggleLifeCheck(!toggleLifeCheck);
    setToggleFunctionalTrainingPlan(false);
    setToggleAdl(false);
    const gtmKey = CSV_OUTPUT_ITEMS.lifeCheck.url;
    dispatchIdentifierToGTM(`life_csv_display_toggle_${gtmKey}`);
  };
  const switchToggleInterestCheck = () => {
    dispatch(InterestCheckActions.getInterestCheckRecordByMonth(cachedAppUsers, date.format('YYYY-MM')));
    setToggleInterestCheck(!toggleInterestCheck);
    setToggleLifeCheck(false);
    setToggleFunctionalTrainingPlan(false);
    setToggleAdl(false);
    const gtmKey = CSV_OUTPUT_ITEMS.interestCheck.url;
    dispatchIdentifierToGTM(`life_csv_display_toggle_${gtmKey}`);
  };
  const switchToggleFunctionalTrainingPlan = () => {
    dispatch(MoffAPIActions.getFunctionalTrainingPlansFromTo(cachedAppUsers, date.format('YYYY-MM')));
    setToggleInterestCheck(false);
    setToggleLifeCheck(false);
    setToggleFunctionalTrainingPlan(!toggleFunctionalTrainingPlan);
    setToggleAdl(false);
    const gtmKey = CSV_OUTPUT_ITEMS.functionalTrainingPlan.url;
    dispatchIdentifierToGTM(`life_csv_display_toggle_${gtmKey}`);
  };
  const switchToggleAdl = () => {
    dispatch(BarthelIndexActions.getBarthelIndexRecordByMonth(cachedAppUsers, date.format('YYYY-MM')));
    setToggleInterestCheck(false);
    setToggleLifeCheck(false);
    setToggleFunctionalTrainingPlan(false);
    setToggleAdl(!toggleAdl);
    const gtmKey = CSV_OUTPUT_ITEMS.barthelIndex.url;
    dispatchIdentifierToGTM(`life_csv_display_toggle_${gtmKey}`);
  };

  /**
   * CSSの描画.
   */
  const RenderCSS: FC = () => {
    return (
      <style type="text/css">
        {/* 縦印刷, 日付・title名ヘッダの余白 */}
        {'@media print{@page {size: portrait; margin-top: 43px;}}'}
        {'@media print{#wrapper { padding:0; margin: 0; } header { display: none; }}'}
        {'@media print{.contentIn { padding-top:0!important; padding-bottom:0!important; }}'}
        {'@media print{.report { padding-bottom:0!important; }}'}
        {/* Edge対策 */}
        {'@media print{tr { page-break-inside: avoid; }}'}
      </style>
    );
  };

  return (
    <>
      <RenderCSS />
      {!moffAPIGlobalState.Loaded ||
      lifeCheckGlobalState.Loading ||
      interestCheckGlobalState.Loading ||
      functionalTrainingPlanGlobalState.Loading ||
      barthelIndexGlobalState.Loading ||
      csvCreateGlobalState.Loading ? (
        <PageLoader />
      ) : null}
      <LifeCSVOutput
        location={location}
        refUsersList={refUsersList}
        date={date}
        cachedUsers={cachedUsers}
        loaded={moffAPIGlobalState.Loaded}
        serviceCode={serviceCode}
        adlCode={adlCode}
        outputItemNames={outputItemNames}
        toggleLifeCheck={toggleLifeCheck}
        toggleInterestCheck={toggleInterestCheck}
        toggleFunctionalTrainingPlan={toggleFunctionalTrainingPlan}
        toggleAdl={toggleAdl}
        minusMonthly={minusMonthly}
        plusMonthly={plusMonthly}
        allUsersPrintButtonClickHandler={runAPICsvOutput}
        switchToggleLifeCheck={switchToggleLifeCheck}
        switchToggleInterestCheck={switchToggleInterestCheck}
        switchToggleFunctionalTrainingPlan={switchToggleFunctionalTrainingPlan}
        switchToggleAdl={switchToggleAdl}
        handleCheckBoxClick={handleCheckBoxClick}
        selectBoxChanged={selectBoxChanged}
        selectADLBoxChanged={selectADLBoxChanged}
      />
    </>
  );
};

export default LifeCSVOutputContainer;
