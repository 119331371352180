import React, { FC } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { DATE_DISPLAY_TYPE } from '../../constants/Training';
import { MOFF_CHECK_COMPARE_LOGIC } from '../../constants/MoffCheck';
import DateSelector from '../Molecules/DateSelector';
import GeneralPrimarySquareButton from '../Atoms/GeneralPrimarySquareButton';
import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import GeneralPrimaryOutlineButton from '../Atoms/GeneralPrimaryOutlineButton';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  ttlStyle01: {
    padding: '0 45px 0 5px',
    margin: '0 0 20px 0',
    fontSize: '25px',
    borderBottom: 'solid 1px #dcdcdc',
  },
}));

const monthButtonStyle = (color: string, gradient = false, isMobile: boolean) => {
  return `
    color: ${gradient ? '#fff !important' : '#000'};
    background-color: ${color};
    background-image: ${gradient ? 'linear-gradient(180deg, #F0976F 0%, #F26B1E 60%)' : 'none'};
    width: ${isMobile ? '100%' : '150px'};
    height: ${isMobile ? '65px' : '80px'};
    margin: 0 10px 20px;
    font-size: 1.5em;
    border-radius: 20px;
  `;
};

interface ReportOutputMonthSelectProps {
  date: moment.Moment;
  dispYearMonth: any;
  selectAreaHeading: string;
  selectMonths?: string[];
  compareMonth?: string;
  oldestDate?: string;
  minusMonthly: () => void;
  plusMonthly: () => void;
  monthButtonOnClick: (month: string) => void;
  nextFetchMonth?: string;
  fetchThePastYearData?: () => Promise<void>;
  reportOutputButtonOnClick?: () => void;
}

const ReportOutputMonthSelect: FC<ReportOutputMonthSelectProps> = ({
  date,
  dispYearMonth,
  selectAreaHeading,
  selectMonths = [],
  compareMonth = '',
  oldestDate = '',
  minusMonthly,
  plusMonthly,
  monthButtonOnClick,
  nextFetchMonth = '',
  fetchThePastYearData = () => null,
  reportOutputButtonOnClick = () => null,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
  };

  const cnt: number = dispYearMonth.length;

  return (
    <>
      <Box component="section" m="30px 0 0 0" p="30px 25px 0">
        <h2 className={classes.ttlStyle01}>{`${selectAreaHeading}を表示する月を選んで下さい`}</h2>
        {compareMonth === MOFF_CHECK_COMPARE_LOGIC.thirdOption ? (
          <Box width="100%" m="40px 0 0 0" textAlign="center">
            <p>比較月を3ヶ月まで選択</p>
          </Box>
        ) : (
          <Box maxWidth="600px" m="0 auto">
            <DateSelector
              date={date}
              className={''}
              dateDisplayType={DATE_DISPLAY_TYPE.ThePastYear}
              minusDate={minusMonthly}
              plusDate={plusMonthly}
            />
          </Box>
        )}
        <Box mt={{ xs: 8, sm: 8, md: 0 }}>
          {cnt === 0 && (!oldestDate || oldestDate === 'empty_data') ? (
            <p>データがありません。</p>
          ) : (
            <Box
              width={{ xs: '100%', md: '680px' }}
              display="flex"
              justifyContent="flex-start"
              flexWrap="wrap"
              m="40px auto 0"
            >
              {
                // すでに数が0のは選定済み.
                _.map(dispYearMonth, (element, index: number) => (
                  <GeneralPrimarySquareButton
                    key={`selectButton${index}`}
                    text={element}
                    setCss={(() => {
                      if (_.some(selectMonths, (selectMonth: string) => selectMonth === element)) {
                        return monthButtonStyle('#ffd700', true, matches.xs);
                      } else if (index % 2 === 0) {
                        return monthButtonStyle('#e8e4df', false, matches.xs);
                      } else {
                        return monthButtonStyle('#fff', false, matches.xs);
                      }
                    })()}
                    onClick={monthButtonOnClick}
                  />
                ))
              }
            </Box>
          )}
        </Box>
      </Box>
      {compareMonth !== MOFF_CHECK_COMPARE_LOGIC.thirdOption ? null : (
        <Box height="auto" m="0 auto">
          {moment(oldestDate).isSameOrBefore(nextFetchMonth) ? (
            <Box display="flex" justifyContent="center" mt="15px">
              <GeneralPrimaryOutlineButton
                text="トレーニング履歴をさらに読込"
                customCss={`max-width: ${matches.xs ? '305px' : '680px'}; width: 100%; height: 40px;`}
                onClick={fetchThePastYearData}
              />
            </Box>
          ) : null}
          {oldestDate && oldestDate !== 'empty_data' ? (
            <Box m="15px 0 0 0" p="0 10px" textAlign="center">
              <p data-test="oldest-month">トレーニング開始月は {moment(oldestDate).format('YYYY年MM月')} です</p>
            </Box>
          ) : null}
          {selectMonths.length === 0 ? (
            <GeneralPrimaryButton
              txt="レポート出力"
              setCss="width: 200px; height: 50px; margin: 20px auto 0;"
              isDisable={true}
              onClick={() => null}
            />
          ) : (
            <GeneralPrimaryButton
              txt="レポート出力"
              setCss="width: 200px; height: 50px; margin: 20px auto 0;"
              onClick={reportOutputButtonOnClick}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default ReportOutputMonthSelect;
