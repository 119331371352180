import _ from 'lodash';

import {
  TOGGLE_POSITION,
  ATTACHMENT_POSITION,
  TrainingInfo,
  UN_INTRODUCED_ADL_IDX,
  MonthlyTrainingData,
  TRAINING_TYPE,
  TRAINING_REPORT_INFO,
  Training,
} from '../constants/Training';
import { omit, mapReturnObject } from './commonUtil';

export const convertToAttachmentPosition = (togglePosition: TOGGLE_POSITION) =>
  _.find(ATTACHMENT_POSITION, (position) => position.togglePosition === togglePosition);

export const sortByTrainingId = (trainingMenus: TrainingInfo[], beSortedTrainings: string[]) =>
  _(trainingMenus)
    .filter((trainingMenu) => beSortedTrainings.indexOf(trainingMenu.name) >= 0)
    .orderBy(['id', 'asc'])
    .map((training) => training.name)
    .value();

export const getDisplayTrainingMenus = (trainingMenus: TrainingInfo[]) =>
  _(trainingMenus)
    .filter((training) => [TRAINING_TYPE.Locomo.name, TRAINING_TYPE.Adl.name].includes(training.genre))
    .orderBy(['id', 'asc'])
    .value();

export const omitUnIntroducedFromTrainingMenu = (originalFetchData: TrainingInfo[]) =>
  originalFetchData.filter((trainingMenu) => !UN_INTRODUCED_ADL_IDX.includes(String(trainingMenu.id)));

export const omitUnIntroducedFromMonthlyTrainings = (originalFetchData: MonthlyTrainingData) =>
  mapReturnObject(Object.keys(originalFetchData), (date) => ({
    [date]: omit(originalFetchData[date], UN_INTRODUCED_ADL_IDX),
  }));

export const alignTrainingDict = (history: MonthlyTrainingData, trainingParamsKey: keyof Training) =>
  mapReturnObject(Object.keys(TRAINING_REPORT_INFO), (trainingId) => ({
    [trainingId]: mapReturnObject(Object.keys(history), (date) => ({
      [date]: history[date]?.[trainingId]?.[trainingParamsKey],
    })),
  }));

export const divideTraining = (
  dividedTrainingDays: string[][],
  alignedTraining: { [trainingId: string]: { [day: string]: number | undefined } },
  displayedTrainingIds: string[],
  colCount: number,
  tableCount: number,
): number[][][] =>
  [...Array(tableCount)].map((v, tableId) =>
    displayedTrainingIds.map((trainingId) =>
      [...Array(colCount)].map((v, colId) => alignedTraining[trainingId][dividedTrainingDays?.[tableId]?.[colId]] ?? 0),
    ),
  );
