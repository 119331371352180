// モフトレチェック.
export const LOADING = 'MOFF_CHECK_LOADING';
export const SUCCESS = 'MOFF_CHECK_SUCCESS';
export const ERROR = 'MOFF_CHECK_ERROR';
export const INITIALIZE = 'MOFF_CHECK_INITIALIZE';

// getMoffCheckLastDateで使用.
export const FINISH = 'MOFF_CHECK_FINISH';
export const START_LOAD = 'MOFF_CHECK_START_LOAD';

export const TUG_SECONDS = ['6〜8', '8〜10', '10〜11', '11〜13', '13〜15', '15〜20', '20〜30'];
export const COLOR_TILE_POSITION = [
  ['60〜50', 'blue', 'green', 'yellow', 'purple', 'pink', 'pink', 'pink'],
  ['50〜40', 'blue', 'green', 'yellow', 'purple', 'pink', 'pink', 'pink'],
  ['40〜30', 'blue', 'green', 'yellow', 'purple', 'pink', 'pink', 'pink'],
  ['30〜20', 'green', 'green', 'yellow', 'purple', 'pink', 'pink', 'pink'],
  ['20〜15', 'yellow', 'yellow', 'yellow', 'purple', 'pink', 'pink', 'pink'],
  ['15〜5', 'purple', 'purple', 'purple', 'purple', 'red', 'red', 'red'],
  ['5〜0', 'pink', 'pink', 'pink', 'red', 'red', 'red', 'red'],
];
export const MONTH_IMAGE_MAP = {
  '01': 'jan',
  '02': 'feb',
  '03': 'mar',
  '04': 'apr',
  '05': 'may',
  '06': 'jun',
  '07': 'jul',
  '08': 'aug',
  '09': 'sep',
  '10': 'oct',
  '11': 'nov',
  '12': 'dec',
};

// TUG測定データテーブル.
export interface TUGTable {
  // Unique ID
  unique_id: string;
  // data_id
  data_id: string;
  // 時間.
  time: number;
  // 起立.
  stand_up: number;
  // 往路.
  out: number;
  // 往路ターン.
  out_turn: number;
  // 復路.
  in: number;
  // 復路ターン.
  in_turn: number;
  // 着席.
  sit: number;
  // 測定開始時刻.
  started_at: string;
  // データ作成時刻.
  created_at: string;
  // データ更新時刻.
  updated_at: string;
}

// バランス測定データテーブル.
export interface BalanceTable {
  // Unique ID
  unique_id: string;
  // data_id
  data_id: string;
  // 時間.
  time: number;
  // 測定タイプ.
  /*
  左足 left_leg
  右足 right_leg
  両足 both_leg
  */
  measurement_type: string;
  // 測定開始時刻.
  started_at: string;
  // データ作成時刻.
  created_at: string;
  // データ更新時刻.
  updated_at: string;
}

// CS-30データテーブル(APIからの返却のItems内の型).
export interface CS30Table {
  // Unique ID
  unique_id: string;
  // data_id
  data_id: string;
  // 回数.
  count: number;
  // 測定開始時刻.
  started_at: string;
  // データ作成時刻.
  created_at: string;
  // データ更新時刻.
  updated_at: string;
}

// SS5データテーブル(APIからの返却のItems内の型).
export interface SS5Table {
  // Unique ID
  unique_id: string;
  // data_id
  data_id: string;
  // 回数
  count: number;
  // 計測時間
  time: number;
  // 測定開始時刻.
  started_at: string;
  // データ作成時刻.
  created_at: string;
  // データ更新時刻.
  updated_at: string;
}

// ROMデータテーブル(APIからの返却のItems内の型).
export interface ROMTable {
  // Unique ID
  unique_id: string;
  // data_id
  data_id: string;
  // item_id
  item_id: string;
  // item_id_started_at
  item_id_started_at: string;
  // item_position_id
  item_position_id: string;
  // ポジション。左右
  position: 'Left' | 'Right';
  // アングル
  angle: number;
  // 初期位置
  init_angle: number;
  // 測定開始時刻.
  started_at: string;
  // データ作成時刻.
  created_at: string;
  // データ更新時刻.
  updated_at: string;
}

// ROMデータテーブル(APIからの返却のItems内の型).
export interface PhysicalRecordTable {
  // Unique ID
  unique_id: string;
  // physical_id
  physical_id: number;
  // physical_id_recorded_at
  physical_id_recorded_at: string;
  // value
  value: number;
  // 測定日.
  recorded_at: string;
  // データ作成時刻.
  created_at: string;
  // データ更新時刻.
  updated_at: string;
}

export type UnionWalkingAbilityType = TUGTable | BalanceTable | CS30Table;

export type UnionMoffCheckTrainingType = UnionWalkingAbilityType | SS5Table | ROMTable | PhysicalRecordTable;

export interface MoffCheckAPIResponseType<T> {
  count: number;
  Items: T;
}

// TUG周期表の秒数.
export interface TUGTableSeconds {
  // 時間.
  time: number;

  // 起立.
  stand_up: number;
  // 往路.
  out: number;
  // 往路ターン.
  out_turn: number;
  // 復路.
  in: number;
  // 復路ターン.
  in_turn: number;
  // 着席.
  sit: number;
}

// 片足立ちの秒数(timeしか使わない).
export interface BalanceTableSeconds {
  // 時間.
  time: number;
}

// CS-30(countしか使わない).
export interface CS30TableCounts {
  // 回数.
  count: number;
}

// 施設.
export interface Institution {
  company_name: string;
  created_at: string;
  institution_email: string;
  institution_id: string;
  institution_name: string;
  institution_name_kana: string;
  institution_status: string;
  institution_sub: string;
  updated_at: string;
}

export interface MoffCheckReportMonth {
  first: string | null;
  second: string | null;
  third: string | null;
}

export interface MoffCheckReportComparison {
  diff: number;
  sign: '+' | '-' | '±';
  evaluation: 'up' | 'down' | 'keep';
}

export interface MoffCheckReportResult<T = number> {
  first: T | null;
  second: T | null;
  third: T | null;
  comparison: MoffCheckReportComparison | null;
  average: string | null;
}

type CheckV1MeasurementName =
  | 'tug'
  | 'balance'
  | 'cs30'
  | 'ss5'
  | 'rom_101_right'
  | 'rom_101_left'
  | 'rom_602_right'
  | 'rom_602_left'
  | 'grip_right'
  | 'grip_left'
  | 'walking_ability';

export type WalkingAbilityEvaluation = 'A' | 'B' | 'C' | 'D' | 'E';

export interface CheckV1User {
  user_id: string;
  user_name: string;
  user_name_kana: string;
  user_age: number;
  user_gender: number;
  user_care_id: string;
  user_care_level: number;
  user_visit_day: number[];
  user_birth_year: number;
  user_birth_month?: number;
  user_birth_day?: number;
  unique_id?: string;
}

export interface MoffCheckV1ReportActionPayload {
  institution: {
    institution_sub: string;
    institution_name: string;
  };
  title: {
    [key in CheckV1MeasurementName]?: string;
  };
  data: {
    user: CheckV1User;
    month: MoffCheckReportMonth;
    average_title: string;
    check: {
      [key in CheckV1MeasurementName]?: MoffCheckReportResult;
    };
    // 最大三つ
    tugData: {
      [key in keyof MoffCheckReportMonth]: {
        in_time: number;
        in_turn: number;
        out: number;
        out_turn: number;
        sit: number;
        stand_up: number;
      } | null;
    };
    // 最大三つ
    scoreGraph: {
      [key in keyof MoffCheckReportMonth]: {
        x: number;
        y: number;
      } | null;
    };
    walkingAbility:
      | {
          [key in keyof MoffCheckReportMonth]: WalkingAbilityEvaluation | null;
        }
      | null;
  }[];
}

export interface MoffCheckV2ReportActionPayload {
  title: { [key in MoffCheckAllMeasurementName]: string };
  data: {
    month: MoffCheckReportMonth;
    average_title: string;
    check: { [key in MoffCheckAllMeasurementName]: MoffCheckReportResult };
  };
}

type MoffCheckAllMeasurementName =
  | 'tug'
  | 'balance_right'
  | 'balance_left'
  | 'cs30'
  | 'ss5'
  | 'rom_101_right'
  | 'rom_101_left'
  | 'rom_602_right'
  | 'rom_602_left'
  | 'grip_right'
  | 'grip_left'
  | 'height'
  | 'weight'
  | 'bmi';

export const MOFF_CHECK_API_PREFIX_LIST = {
  tug: {
    name: 'tug',
    prefix: 'walking/tug',
    suffixDefault: '',
    suffixLatest: 'latest',
    paramsOption: null,
  },
  balance: {
    name: 'balance',
    prefix: 'balance',
    suffixDefault: '',
    suffixLatest: 'latest',
    paramsOption: null,
  },
  balanceRight: {
    name: 'balance/left_leg',
    prefix: 'balance',
    suffixDefault: '',
    suffixLatest: 'latest',
    paramsOption: 'right_leg',
  },
  balanceLeft: {
    name: 'balance/right_leg',
    prefix: 'balance',
    suffixDefault: '',
    suffixLatest: 'latest',
    paramsOption: 'left_leg',
  },
  cs30: {
    name: 'cs30',
    prefix: 'chairstand',
    suffixDefault: '',
    suffixLatest: 'latest',
    paramsOption: null,
  },
  ss5: {
    name: 'ss5',
    prefix: 'ss5',
    suffixDefault: '',
    suffixLatest: 'latest',
    paramsOption: null,
  },
  rom: {
    name: 'rom',
    prefix: 'rom',
    suffixDefault: '',
    suffixLatest: 'latest/list',
    paramsOption: null,
  },
  gripRight: {
    name: 'grip/right',
    prefix: 'physical_record',
    suffixDefault: '0/',
    suffixLatest: '0/latest',
    paramsOption: null,
  },
  gripLeft: {
    name: 'grip/left',
    prefix: 'physical_record',
    suffixDefault: '1/',
    suffixLatest: '1/latest',
    paramsOption: null,
  },
  height: {
    name: 'height',
    prefix: 'physical_record',
    suffixDefault: '2/',
    suffixLatest: '2/latest',
    paramsOption: null,
  },
  weight: {
    name: 'weight',
    prefix: 'physical_record',
    suffixDefault: '3/',
    suffixLatest: '3/latest',
    paramsOption: null,
  },
} as const;

export type MOFF_CHECK_API_PREFIX_LIST = typeof MOFF_CHECK_API_PREFIX_LIST[keyof typeof MOFF_CHECK_API_PREFIX_LIST];

export const MOFF_CHECK_COMPARE_LOGIC = {
  default: '3months',
  secondOption: '2months+oldestmonth',
  thirdOption: 'freechoice',
} as const;

export type MOFF_CHECK_COMPARE_LOGIC = typeof MOFF_CHECK_COMPARE_LOGIC[keyof typeof MOFF_CHECK_COMPARE_LOGIC];

export const MOFF_CHECK_V1_SELECT_LIST = {
  walking_ability: '歩行能力評価',
  cs30: 'CS-30',
  ss5: 'SS-5',
  rom_101_right: 'ROM：肩屈曲（右）',
  rom_101_left: 'ROM：肩屈曲（左）',
  rom_602_right: 'ROM：膝伸展（右）',
  rom_602_left: 'ROM：膝伸展（左）',
  grip_right: '握力（右）',
  grip_left: '握力（左）',
} as const;

export const MOFF_CHECK_V1_VALUE_LIST = Object.values(MOFF_CHECK_V1_SELECT_LIST);

export type MoffCheckV1SelectKeys = keyof typeof MOFF_CHECK_V1_SELECT_LIST;
export type MoffCheckV1SelectList = typeof MOFF_CHECK_V1_SELECT_LIST[keyof typeof MOFF_CHECK_V1_SELECT_LIST];

export const MOFF_CHECK_ALL_MEASUREMENT_LIST = {
  tug: 'tug',
  balanceRight: 'balance_right',
  balanceLeft: 'balance_left',
  cs30: 'cs30',
  ss5: 'ss5',
  romSholderRight: 'rom_101_right',
  romSholderLeft: 'rom_101_left',
  romKneeRight: 'rom_602_right',
  romKneeLeft: 'rom_602_left',
  gripRight: 'grip_right',
  gripLeft: 'grip_left',
  height: 'height',
  weight: 'weight',
  bmi: 'bmi',
} as const;

export type MOFF_CHECK_ALL_MEASUREMENT_LIST = typeof MOFF_CHECK_ALL_MEASUREMENT_LIST[keyof typeof MOFF_CHECK_ALL_MEASUREMENT_LIST];

export const REQUEST_RETRY_MAX_COUNT = 3;
