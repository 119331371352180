/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';

const questionButton = css`
  height: 34px;
  width: 34px;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(0deg, rgba(230, 230, 230, 1) 0%, rgba(255, 255, 255, 1) 100%);
  color: #4d4d4d;
  opacity: 0.85;
  padding: 0;
  border: 2px solid #ddd9d4;
  border-radius: 100%;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

interface QuestionButtonProps {
  /** 外部から渡すスタイル */
  setCss?: string;
  /** ボタンがクリックされた時のイベントハンドラ */
  popupSpecificFunction?: () => void;
}

const QuestionButton: React.FC<QuestionButtonProps> = ({ popupSpecificFunction, setCss = '' }) => {
  return (
    <button onClick={popupSpecificFunction} css={[questionButton, setCss]} data-test="popup-button-menu">
      ?
    </button>
  );
};

export default QuestionButton;
