// Barthel Index 用API.

import { AxiosInstance } from 'axios';
import { createAxiosInstance } from '../constants/AWS';
import { ResponseData } from '../constants/AppUser';
import moment from 'moment';
import { isEmpty, waitTimeMs } from '../utils/commonUtil';
import { DATA_CHECK_INTERVAL } from '../constants/csvCreate';
import { apiRequestFunc, errorFunc } from '../utils/apiUtil';
import * as BarthelIndex from '../constants/BarthelIndex';
import { MoffAPIActionType } from '../constants/MoffAPI';

/**
 * BarthelIndexAPIクラス.
 *
 * @export
 * @class BarthelIndexAPI
 */
export class BarthelIndexAPI {
  /** インスタンス */
  private static _instance: BarthelIndexAPI;
  private axiosInstance!: AxiosInstance;

  /** インスタンスの取得 */
  public static get instance(): BarthelIndexAPI | null {
    // _inctanceが存在しない場合に、new MoffAPI()を実行する。
    if (!this._instance) {
      this._instance = new BarthelIndexAPI();

      // .envの設定
      const API_BASE_URL = String(process.env.REACT_APP_BARTHEL_INDEX_API_URL);
      const API_KEY = String(process.env.REACT_APP_BARTHEL_INDEX_API_KEY);
      if (API_BASE_URL === 'undefined') {
        console.log('.envにREACT_APP_BARTHEL_INDEX_API_URLの設定がありません.');
        return null;
      }
      if (API_KEY === 'undefined') {
        console.log('.envにREACT_APP_BARTHEL_INDEX_API_KEYの設定がありません.');
        return null;
      }
      const axiosInstance = createAxiosInstance(API_BASE_URL, API_KEY);

      if (axiosInstance) {
        this._instance.axiosInstance = axiosInstance;
      } else {
        return null;
      }
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  // BI取得関数(1人) [APIリクエストから呼び出し]
  public async getBiFunc(uniqueId: string, startDate: string, endDate: string) {
    return this.axiosInstance.get(`/barthel/${uniqueId}/from/${startDate}/to/${endDate}`);
  }

  /**
   * 指定月に存在するデータを取得.
   *
   * @param {ResponseData[]} uniqueIds
   * @param {string} month
   * @returns {*}
   * @memberof InterestCheckAPI
   */
  public getRecordsByMonthAPI(uniqueIds: ResponseData[], month: string) {
    const uniqueLogicFunc = async () => {
      const fromDate = moment(month).startOf('month').format('YYYY-MM-DD');
      const toDate = moment(month).endOf('month').format('YYYY-MM-DD');
      const data = await Promise.all(
        uniqueIds.map(async (uniqueId: ResponseData, i) => {
          await waitTimeMs(i * DATA_CHECK_INTERVAL);
          const url = `/barthel/${uniqueId.unique_id}/from/${fromDate}/to/${toDate}`;
          //TODO データがないと、ここで404 NotFound Errorとなって通信エラーとなってしまうので、一旦空を返すように変更
          // https://github.com/moff-dev/moff-bi-api/issues/54 で対応予定
          try {
            const data = await this.axiosInstance.get(url).then((response) => response.data);
            return {
              userId: uniqueId.app_user_id,
              data,
            };
          } catch (error) {
            return {
              userId: uniqueId.app_user_id,
              data: [],
            };
          }
        }),
      );
      data.map((v) => console.log(!isEmpty(v.data)));
      const monthUsersRecords = data.filter((v) => !isEmpty(v.data));
      return {
        monthUsersRecords,
      };
    };

    return apiRequestFunc(BarthelIndex.LOADING, BarthelIndex.SUCCESS, BarthelIndex.ERROR, uniqueLogicFunc);
  }
}

export const createBarthelIndexAPIInstance: any = () => {
  // Moffインスタンスが存在しない場合、エラーアクションを返す
  return BarthelIndexAPI.instance === null
    ? [true, null, errorFunc(MoffAPIActionType.ERROR, '不明なエラーが発生しました.')]
    : [false, BarthelIndexAPI.instance, null];
};
