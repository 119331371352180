/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC, Fragment, MutableRefObject } from 'react';

import * as images from '../../img/index';
import { PressReportResponse } from '../../constants/Report';

import { getSeasonClassName, memoComplete } from '../../utils/PressModule';
import { dispatchIdentifierToGTM } from '../../utils/commonUtil';

import PressResultReport from '../Molecules/Reports/PressResultReport';
import PressCalendar from '../Atoms/PressCalendar';
import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';

const pressBackGround = (seasonClassName: string) => {
  return css`
    position: absolute;
    width: 665px;
    height: 970px;
    background-color: ${seasonClassName};
    @media print {
      -webkit-print-color-adjust: exact;
    }
    img {
      width: 100%;
    }
    .row {
      overflow: hidden;
    }
    .column {
      float: left;
    }
  `;
};

const pageInner = css`
  padding: 14px 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const institutionName = (smallFont: boolean) => {
  return css`
    margin-left: 26px;
    font-size: ${smallFont ? '18px' : '30px'};
    font-weight: bold;
    color: white;
    height: 30px;
    line-height: 30px;
  `;
};

const headerTitle = css`
  width: 250px !important;
`;

const headerMonth = css`
  width: 110px !important;
  margin-bottom: 12px;
`;

const headerUserName = css`
  position: relative;
  float: right;
  width: 74mm;
  overflow: hidden;
  font-family: 'ヒラギノ角ゴ ProN W6', 'HiraKakuProN-W6', 'メイリオ', 'Meiryo', sans-serif;
  color: #263a5e !important;
`;

const headerUserNameText = css`
  position: absolute;
  top: 0;
  right: 13mm;
  display: inline-block;
  height: 100%;
`;

const headerUserNameTextName = css`
  font-size: 16pt;
  font-weight: bold;
  line-height: 78px;
`;

const headerUserNameTextSama = css`
  padding-left: 2mm;
  font-size: 13pt;
`;

const trainingContent = css`
  position: relative;
`;

const contentBack = css`
  vertical-align: top;
`;

const monthlyImage = css`
  position: absolute;
  top: 6px;
  left: 30px;
  width: 90% !important;
`;

const pageFooter = css`
  position: relative;
  height: 76mm;
  overflow: hidden;
`;

const calenderArea = css`
  width: 88mm;
  float: left;
`;

const pressMemo = css`
  position: relative;
  width: 77mm;
  float: left;
  margin-left: 20px;
`;

const pressMemoText = css`
  position: absolute;
  top: 15.5mm;
  left: 10.4mm;
  z-index: 2;
  width: 55mm;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.7;
  text-align: left;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

const pressMemoIcon = css`
  position: absolute;
  right: 10mm;
  bottom: 12mm;
  width: 16mm !important;
`;

const pressMemoEdit = css`
  position: absolute;
  right: 60px;
  bottom: 225px;
  z-index: 1;
  width: 26mm;
  cursor: pointer;
  &:hover {
    filter: contrast(115%);
  }
  &:active {
    bottom: 224px;
  }
  @media print {
    display: none;
  }
`;

const pressMemoEditInput = css`
  position: absolute;
  right: 0;
  bottom: 250px;
  z-index: 2;
  textarea {
    padding: 7px;
    resize: none;
    background-color: rgba(240, 240, 240, 1);
    border: none;
    border-radius: 10px;
    outline: none;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    -ms-overflow-style: none;
  }
  &:before {
    position: absolute;
    top: 95%;
    left: 30%;
    margin-left: -15px;
    content: '';
    border: 15px solid transparent;
    border-top: 18px solid rgba(240, 240, 240, 1);
  }
  @media print {
    display: none;
  }
`;

const JunAnswer = css`
  transform: scale(-1, -1);
  font-size: 10px;
  margin: -10px 10px;
`;

interface PressReportProps {
  yearMonth: string;
  reportRes: PressReportResponse;
  refChart: MutableRefObject<any>;
  memoText: string;
  buttonClicked: boolean;
  refNode: MutableRefObject<any>;
  putMemo: () => void;
  deleteMemo: () => void;
  setButtonClicked: (isClicked: boolean) => void;
  setMemoText: (memoText: string) => void;
}

const PressReport: FC<PressReportProps> = ({
  yearMonth,
  reportRes,
  refChart,
  memoText,
  buttonClicked,
  refNode,
  putMemo,
  deleteMemo,
  setButtonClicked,
  setMemoText,
}) => {
  const yearMonthArr = yearMonth.split('-');
  const institution = reportRes.institution;
  const data = reportRes.data[0];
  const seasonClassName = getSeasonClassName(Number(yearMonthArr[1]));

  const handleOutsideClick = (event: any) => {
    // コンポーネント自身がクリックされた際は無視する
    if (refNode.current ? refNode.current.contains(event.target) : false) {
      return;
    } else {
      setButtonClicked(false);
      document.removeEventListener('click', handleOutsideClick, false);
    }
  };

  const showEditCallout = () => {
    if (buttonClicked === false) {
      // attach/remove event handler
      document.addEventListener('click', handleOutsideClick, false);
      setButtonClicked(true);
    }
    setButtonClicked(true);
    dispatchIdentifierToGTM('detail_page_edit_memo_press');
  };

  return (
    <Fragment>
      <div css={pressBackGround(seasonClassName)} data-test={seasonClassName}>
        <div css={pageInner}>
          {/* ヘッダ情報 */}
          <div>
            <p css={institutionName(institution.institution_name.length > 20)} data-test="institution-name">
              {institution.institution_name}
            </p>
            <div>
              <img src={images.cmn_title} alt="モフトレ通信" css={headerTitle} />
              <img
                src={images[`month_${yearMonthArr[1]}`]}
                alt={`${yearMonthArr[1]}月号`}
                css={headerMonth}
                data-test="header-month"
              />
              <div css={headerUserName}>
                <img src={images.hd_bg} alt="name background" />
                <div css={headerUserNameText} data-test="header-user-name">
                  <span css={headerUserNameTextName}>{data.user.user_name}</span>
                  <span css={headerUserNameTextSama}>様</span>
                </div>
              </div>
            </div>
          </div>
          {/* 実施したトレーニング */}
          <div css={trainingContent}>
            <img src={images.bd_bg} alt="content background" css={contentBack} />
            <PressResultReport trainings={data['trainings']} refChart={refChart} />
          </div>
          {/* 月別コンテンツ */}
          <div css={trainingContent}>
            <img src={images.bd_bg} alt="content background" />
            <img
              src={images[`press_content_${yearMonthArr[1]}`]}
              alt={`${yearMonthArr[1]}月コンテンツ`}
              css={monthlyImage}
              data-test="monthly-content"
            />
          </div>
          {/* カレンダー */}
          <div css={pageFooter}>
            <div css={calenderArea}>
              <img src={images.cal_title} alt="calendar title" />
              <PressCalendar calendarData={data.calendar} year={yearMonthArr[0]} month={yearMonthArr[1]} />
            </div>
            {/* メモ */}
            <div css={pressMemo} data-test="memo-body">
              <img src={images.ft_memo} alt="memo background" />
              <p css={pressMemoText} data-test="memo-text">
                {memoText}
              </p>
              <img
                src={images[`icon_${yearMonthArr[1]}`]}
                className="ft_memo_icon"
                alt="memo icon"
                css={pressMemoIcon}
              />
            </div>
          </div>
          {yearMonthArr[1] === '06' ? (
            <p css={JunAnswer} data-test="june-answer">
              答え：太陽、レインコートのボタンの形、てるてる坊主の目、長靴のフチ、犬の舌、ビルの高さ、紫陽花の花の数
            </p>
          ) : null}
        </div>
        {/* コメント編集機能 */}
        {buttonClicked ? null : (
          <div css={pressMemoEdit} onClick={showEditCallout} data-test="ft_memo_edit">
            <img src={images.memo_edit} alt="memo edit" />
          </div>
        )}
        {buttonClicked ? (
          <div
            css={pressMemoEditInput}
            ref={(node) => {
              refNode.current = node;
            }}
          >
            <textarea
              rows={6}
              cols={30}
              value={memoText}
              placeholder={'ここにメモを記入（50文字以内）'}
              maxLength={50}
              onChange={(event) => memoComplete(event, setMemoText)}
              onBlur={(event) => memoComplete(event, setMemoText)}
              onFocus={(event) => memoComplete(event, setMemoText)}
              data-test="memo-edit-input-textarea"
            />
            <GeneralPrimaryButton
              txt={'保存'}
              setCss={
                'position: absolute; right: 80px; bottom: 10px; width: 65px; height: 30px; white-space:nowrap; font-size: 0.9em !important;'
              }
              onClick={putMemo}
            />
            <GeneralPrimaryButton
              txt={'削除'}
              setCss={
                'position: absolute; right: 10px; bottom: 10px; width: 65px; height: 30px; white-space:nowrap; font-size: 0.9em !important;'
              }
              isDisable={true}
              onClick={deleteMemo}
            />
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

export default PressReport;
