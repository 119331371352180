// ロコモ長期変化.
import React, { FC } from 'react';

import { SCREEN_TYPE } from '../../constants/Training';
import { termType } from '../../constants/Locomo';

import TrainingGraphs from '../Molecules/TrainingGraphs';
import MonthSelectForm from '../Atoms/MonthSelectForm';
import ToggleButtonThreePosition from '../Atoms/ToggleButtonThreePosition';
import PageLoader from '../Atoms/PageLoader';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box } from '@material-ui/core';
import TrainingCustomize from '../Molecules/TrainingCustomize';
import { useTrainingUI } from '../../hooks/use-training-ui';
import { useTrainingFetchLongTerm } from '../../hooks/use-training-fetch-longterm';

const useStyles = makeStyles({
  ttlStyle01: {
    padding: '0 45px 0 5px',
    margin: '0 0 20px 0',
    fontSize: '25px',
    borderBottom: 'solid 1px #dcdcdc',
  },
  calendarHeader: {
    margin: '20px 0 5px',
    '@media print': {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'nowrap',
    },
  },
  dateArea: {
    margin: '0 auto',
    flexWrap: 'wrap',
    '& span': {
      display: 'block',
      width: '50px',
      height: '40px',
      textAlign: 'center',
    },
    '@media print': {
      width: '600px',
      margin: '0 20px 0 0',
    },
  },
  sectionGraph: {
    padding: '30px 25px 0',
    margin: '30px 0 0 0',
    '@media print': {
      marginTop: 0,
      paddingTop: '60px',
    },
  },
  toggleButtonArea: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0 0 0',
    '@media print': {
      display: 'block',
      margin: 0,
    },
  },
});

// エラーメッセージのCSS.
const getErrorMsgStyle = function (msg: string): React.CSSProperties {
  const style: React.CSSProperties = {
    color: 'red',
    textAlign: 'center',
  };
  // エラーがあれば変更.
  if (msg) {
    style.marginBottom = '10px';
  }
  return style;
};

// 利用者一覧の表のフィールド表示用コンポーネント
const TrainingLongTerm: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
  };
  const { longTermtogglePosition, setLongTermtogglePosition } = useTrainingUI();
  const {
    moffApiState,
    longTermData,
    refLongTermStartDate,
    refLongTermEndDate,
    date,
    longTermStartMonth,
    longTermEndMonth,
    longTermErrorMessage,
    longTermFormValidation,
  } = useTrainingFetchLongTerm();

  return (
    <>
      {!moffApiState.Loaded && <PageLoader />}
      <TrainingCustomize />
      <Box px="25px">
        <h2 className={classes.ttlStyle01}>長期変化</h2>
      </Box>
      <p style={getErrorMsgStyle(longTermErrorMessage)} data-test="longterm-error">
        {longTermErrorMessage}
      </p>
      {/* 年月選択  */}
      <Box className={classes.calendarHeader}>
        <Box
          display="flex"
          justifyContent={{ xs: 'center', sm: 'space-around' }}
          mt={{ xs: '20px', sm: '20px', md: '0' }}
          width={{ xs: '300px', sm: '600px' }}
          className={classes.dateArea}
        >
          <Box width={{ xs: '100%', sm: '200px' }} display="flex" justifyContent="center">
            <MonthSelectForm
              month={longTermStartMonth}
              refDate={refLongTermStartDate}
              termType={termType.Start}
              formValidation={longTermFormValidation}
            />
          </Box>
          <Box
            width={{ xs: '100%', sm: '200px' }}
            height="40px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            から
          </Box>
          <Box width={{ xs: '100%', sm: '200px' }} display="flex" justifyContent="center">
            <MonthSelectForm
              month={longTermEndMonth}
              refDate={refLongTermEndDate}
              termType={termType.End}
              formValidation={longTermFormValidation}
            />
          </Box>
        </Box>
        <Box className={classes.toggleButtonArea}>
          <ToggleButtonThreePosition
            togglePosition={longTermtogglePosition}
            changeTogglePosition={setLongTermtogglePosition}
            leftText="両側"
            rightText="右"
            centerText="左"
            customCss="width: 80px; height: 50px;"
          />
        </Box>
      </Box>
      <Box component="section" className={classes.sectionGraph}>
        <TrainingGraphs
          className="longTerm"
          historyArray={longTermData}
          date={date}
          screenType={SCREEN_TYPE.LongTerm}
          graphWidth={matches.xs ? 460 : 920}
          graphHeight={matches.xs ? 330 : 370}
        />
      </Box>
    </>
  );
};

export default TrainingLongTerm;
