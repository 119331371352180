import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuButton from '../Molecules/MenuButton';
import { MENU_TYPE, PLAN_TYPE } from '../../constants/Menu';

const useStyles = makeStyles(() => ({
  menuList: {
    overflow: 'hidden',
    listStyle: 'none',
    margin: '0 auto',
    padding: '0px',
    maxWidth: '750px',
    paddingBottom: '22px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
}));

interface MenuContentsProps {
  planType: keyof typeof PLAN_TYPE;
  openLiterallyReport: () => void;
  openMoffCheck: () => void;
  openADLLocomo: () => void;
  openKiroku: () => void;
  openPress: () => void;
}

const MenuContents: FC<MenuContentsProps> = ({
  planType,
  openLiterallyReport,
  openMoffCheck,
  openADLLocomo,
  openKiroku,
  openPress,
}) => {
  const classes = useStyles();
  const menus = PLAN_TYPE[`${planType}`]?.menus ?? PLAN_TYPE.Regular.menus;
  const popUpActions = {
    [MENU_TYPE.LiterallyReport.name]: openLiterallyReport,
    [MENU_TYPE.MoffCheck.name]: openMoffCheck,
    [MENU_TYPE.MoffTraining.name]: openADLLocomo,
    [MENU_TYPE.Kiroku.name]: openKiroku,
    [MENU_TYPE.MoffPress.name]: openPress,
  };

  return (
    <Box mb={{ xs: 5, sm: 0 }}>
      <ul className={classes.menuList}>
        {menus.map((menu: MENU_TYPE) => (
          <Box key={menu.name} component="li" mt="30px">
            <MenuButton
              isYoutubeButton={!!popUpActions[menu.name]}
              popupSpecificFunction={popUpActions[menu.name]}
              menuType={menu}
            />
          </Box>
        ))}
      </ul>
    </Box>
  );
};

export default MenuContents;
