import * as AuthConst from '../constants/Auth';
import _ from 'lodash';
import { Auth } from 'aws-amplify';
import { apiRequestFunc } from '../utils/apiUtil';

import { createMoffAPIInstance } from './MoffAPI';
import { createPdfCreateInstance } from './pdfCreate';
import { createReportInstance } from './Report';
import { createCsvCreateInstance } from './csvCreate';

const [isMoffAPIError, moffAPIInstance] = createMoffAPIInstance();
const [isPdfCreateError, pdfCreateInstance] = createPdfCreateInstance();
const [isReportError, reportInstance] = createReportInstance();
const [isCsvError, csvInstance] = createCsvCreateInstance();

export const signInAPI = (username: string, password: string) => {
  const uniqueLogicFunc: any = async () =>
    Auth.signIn(username, password)
      .then(() => {
        // Googleタグマネージャ用.
        window.category = username;
        return _.zipObject(['isAuthenticated', 'username'], [true, username]);
      })
      .catch((error) => {
        alert(error.message);
        return _.zipObject(['isAuthenticated', 'username', 'error'], [false, '', error.message]);
      });

  return apiRequestFunc(AuthConst.LOADING_SIGN_IN, AuthConst.SUCCESS_SIGN_IN, AuthConst.ERROR_SIGN_IN, uniqueLogicFunc);
};

export const getSessionAPI = () => {
  const uniqueLogicFunc: any = async () => {
    // セッション時にログインしている場合は、jwtTokenを取得しAxiosInstanceを生成
    if (!isMoffAPIError) {
      await moffAPIInstance.setAxiosInstanceWithJwtToken();
    }
    if (!isPdfCreateError) {
      await pdfCreateInstance.setAxiosInstanceWithJwtToken();
    }
    if (!isReportError) {
      await reportInstance.setAxiosInstanceWithJwtToken();
    }
    if (!isCsvError) {
      await csvInstance?.setAxiosInstanceWithJwtToken();
    }

    return Auth.currentAuthenticatedUser()
      .then(async (cognitoUser) => {
        // Googleタグマネージャ用.
        window.category = cognitoUser.username;
        // TODO: セッション前に毎回プランタイプを取得するのは、サーバ負荷的によくないので、cognitoの情報でプランタイプを管理できるようにしたい
        const institutionPlanType = await moffAPIInstance.getInstitutionsFunc().then((response: any) => {
          return response.data.plan_type ? response.data.plan_type : 'Regular';
        });
        return _.zipObject(
          ['isAuthenticated', 'username', 'planType'],
          [true, cognitoUser.username, institutionPlanType],
        );
      })
      .catch(() => {
        return _.zipObject(['isAuthenticated', 'username', 'plan_type'], [false, '', '']);
      });
  };

  return apiRequestFunc(AuthConst.LOADING_SESSION, AuthConst.SUCCESS_SESSION, AuthConst.ERROR_SESSION, uniqueLogicFunc);
};

export const signOutAPI = () => {
  const uniqueLogicFunc: any = async () => {
    return Auth.currentAuthenticatedUser()
      .then(async (cognitoUser) => {
        return Auth.signOut()
          .then(() => {
            localStorage.clear();
            return _.zipObject(['isAuthenticated', 'isSignedOut', 'username'], [false, true, '']);
          })
          .catch(() => {
            return _.zipObject(['isAuthenticated', 'isSignedOut', 'username'], [true, false, cognitoUser.username]);
          });
      })
      .catch(() => {
        return _.zipObject(['isAuthenticated', 'isSignedOut', 'username'], [false, true, '']);
      });
  };

  return apiRequestFunc(
    AuthConst.LOADING_SIGN_OUT,
    AuthConst.SUCCESS_SIGN_OUT,
    AuthConst.ERROR_SIGN_OUT,
    uniqueLogicFunc,
  );
};
