import React, { FC, useState, useEffect, memo } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import store from '../store/configureStore';

import * as MoffAPIActions from '../actions/MoffAPI';
import * as HeaderActions from '../actions/Header';

import { User } from '../constants/UserListField';

import UserDetail from '../components/Organisms/UserDetail';

type AllState = ReturnType<typeof store.getState>;

const mapStateToProps = (state: AllState) => ({
  user: state.moffAPI.result.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setPage: (page: string) => HeaderActions.setPage(page),
      getUser: (userId: string) => MoffAPIActions.getUser(userId),
    },
    dispatch,
  );

interface UserDetailContainerProps {
  user: User;
  setPage: (page: string) => void;
  getUser: (userId: string) => void;
  userId: string;
  headerType: string;
  pageTitle: string;
  isDisablePrint?: boolean;
}

const UserDetailContainer: FC<UserDetailContainerProps> = ({
  user,
  setPage,
  getUser,
  userId,
  headerType,
  pageTitle,
  isDisablePrint = false,
}) => {
  const [cachedUser, setCachedUser] = useState<User>({} as User);

  // 画面読み込み時の処理。ヘッダ情報の設定。利用者情報の取得。
  useEffect(() => {
    (async () => {
      document.title = pageTitle;
      setPage(headerType);
      await getUser(userId);
    })();
  }, [setPage, getUser, headerType, userId, pageTitle]);

  // 利用者取得が完了後、ローカルステートにキャッシュさせておく
  useEffect(() => {
    if (user) {
      // キャッシュさせておく.
      if (user) setCachedUser(user);
    }
  }, [user]);

  return <UserDetail user={user || cachedUser} isDisablePrint={isDisablePrint} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(UserDetailContainer));
