import React, { FC } from 'react';
import ForgotPasswordCodeForm from '../../containers/ForgotPasswordCodeForm';
import PageLoader from '../Atoms/PageLoader';

import { Box } from '@material-ui/core';
interface ForgotPasswordCodeProps {
  password: any;
}

const DoneForgotPasswordCode: FC = () => {
  return <h2>パスワード再設定が完了しました。</h2>;
};

const ForgotPasswordCode: FC<ForgotPasswordCodeProps> = ({ password }) => {
  return (
    <Box>
      {(() => {
        if (password.Loaded) {
          return;
        }

        // ローディング中
        return <PageLoader />;
      })()}
      {password.Loaded ? (
        password.result ? (
          <DoneForgotPasswordCode key="message" />
        ) : (
          <ForgotPasswordCodeForm key="form" />
        )
      ) : null}
    </Box>
  );
};

export default ForgotPasswordCode;
