import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';

const Mode: FC = ({ children }) => {
  return process.env.REACT_APP_MAINTAINANCE === 'maintainance' ? (
    <Redirect to="/maintainance.html" />
  ) : (
    <Route>{children}</Route>
  );
};

export default Mode;
