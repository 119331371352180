import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store/configureStore';

import * as HeaderActions from '../actions/Header';
import * as MoffAPIActions from '../actions/MoffAPI';
import * as Header from '../constants/Header';
import UserSelectMoffTraining from '../components/Organisms/UserSelectMoffTraining';

type AllState = ReturnType<typeof store.getState>;

const UserSelectMoffTrainingContainer: FC = () => {
  const dispatch = useDispatch();
  const moffAPI = useSelector((state: AllState) => state.moffAPI);

  useEffect(() => {
    (async () => {
      document.title = '利用者一覧';
      dispatch(HeaderActions.setPage(Header.USER_SELECT_MOFF_TRAINING));
      await dispatch(MoffAPIActions.getUsers());
      dispatch(MoffAPIActions.duplicateRepeatSort());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <UserSelectMoffTraining moffAPI={moffAPI} />;
};

export default UserSelectMoffTrainingContainer;
