import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import * as MoffAPIActions from '../actions/MoffAPI';
import * as UserListField from '../constants/UserListField';
import { UserModel } from '../constants/UserListField';
import ArrowButton from '../components/Atoms/ArrowButton';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

const useStyles = makeStyles(() => ({
  tableHeader: {
    padding: '16px 18px',
    fontSize: '14px',
    color: '#fff',
    backgroundColor: '#7c644e',
    textAlign: 'left',
    overflowWrap: 'break-word',
  },
}));

interface UserListHeaderState {
  isDescendingOrderMap: Omit<{ [P in keyof UserModel]: boolean }, 'user_name'> & { user_name_kana: boolean };
}

interface UserListHeaderProps {
  UserListFieldKey: 'LocomoModel' | 'KirokuModel';
}

const UserListHeaderContainer: FC<UserListHeaderProps> = ({ UserListFieldKey }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDescendingOrderMap, setIsDescendingOrderMap] = useState({
    user_care_id: false,
    user_name_kana: false,
    user_birth_year: false,
    user_gender: false,
    display_date: false,
  });

  /**
   * 並べ替えボタンのクリックイベントハンドラ
   * @param {String} key - userのどのプロパティを並べ換えるかのキー
   */
  const handleClickSortButton = (key: keyof UserListHeaderState['isDescendingOrderMap']) => {
    // 並べ替えのDispatcherを呼び出す
    dispatch(
      MoffAPIActions.sortByKey({
        key,
        isDescendingOrder: !isDescendingOrderMap[key],
      }),
    );
    const newIsDescendingOrderMap = {
      ...isDescendingOrderMap,
      [key]: !isDescendingOrderMap[key],
    };
    const data = Object.assign({}, newIsDescendingOrderMap);
    // 対象の降順かどうかのフラグを反転させる
    setIsDescendingOrderMap(data);
    dispatchIdentifierToGTM(`sort_${key}`);
  };

  return (
    <>
      {Object.keys(UserListField[UserListFieldKey]).map((key: any) => (
        <th className={classes.tableHeader} key={key} scope="col">
          {UserListField[UserListFieldKey][key]}
          {/* TODO: 最新の記録日は遅延処理がかかる設定になっているため、実装の難易度が上がる。一旦保留にする。 */}
          {key !== 'display_date' ? (
            <ArrowButton
              setCss={'top: 4px; left: 4px;'}
              handleClick={() => handleClickSortButton(key === 'user_name' ? 'user_name_kana' : key)}
              isDownward={isDescendingOrderMap[key === 'user_name' ? 'user_name_kana' : key]}
            />
          ) : null}
        </th>
      ))}
    </>
  );
};

export default UserListHeaderContainer;
