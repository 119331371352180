import Amplify, { Auth } from 'aws-amplify';
import axios, { AxiosInstance } from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';

export const amplifyConfig = () => {
  const config = {
    Auth: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
      // REQUIRED - Amazon Cognito Region
      region: process.env.REACT_APP_AWS_REGION,
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_CLIENT_ID,
    },
    Storage: {
      AWSS3: {
        bucket: process.env.REACT_APP_AWS_BUCKET, //REQUIRED -  Amazon S3 bucket name
        region: process.env.REACT_APP_AWS_REGION, //OPTIONAL -  Amazon service region
      },
      customPrefix: {
        public: '',
      },
    },
  };
  Amplify.configure(config);
  Auth.configure(config);
};

export const createAxiosInstance = (
  API_BASE_URL: string,
  API_KEY?: string | null,
  JWT_TOKEN?: string | null,
): AxiosInstance => {
  if (axios.defaults.adapter !== undefined) {
    // API_KEYが設定されている時はAPI_KEYで認証
    if (API_KEY) {
      return axios.create({
        baseURL: API_BASE_URL,
        headers: { 'x-api-key': API_KEY },
        adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' }),
      });
      // API_KEYが設定されていない時はawsから取得したcognitoJwtTokenで認証
    } else {
      return axios.create({
        baseURL: API_BASE_URL,
        headers: { Authorization: JWT_TOKEN },
        adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache' }),
      });
    }
  } else {
    return axios.create({
      baseURL: API_BASE_URL,
      headers: { Authorization: JWT_TOKEN },
    });
  }
};
