import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import store from '../store/configureStore';

type AllState = ReturnType<typeof store.getState>;

const Auth: FC = ({ children }) => {
  const authGlobalState = useSelector((state: AllState) => state.auth);
  return authGlobalState.isAuthenticated ? <Route>{children}</Route> : <Redirect to="/" />;
};

export default Auth;
