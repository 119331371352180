/* 機能訓練レポート・利用者選択 */
import React, { FC } from 'react';
import * as _ from 'lodash';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Select, MenuItem, FormControl, InputLabel, Box } from '@material-ui/core';

import * as UserListField from '../../constants/UserListField';
import GeneralPrimarySelectButton from '../Atoms/GeneralPrimarySelectButton';

const useStyles = makeStyles({
  inputField: {
    width: '100%',
    backgroundColor: '#fff',
  },
  menuItem: {
    height: '50px',
  },
  weekList: {
    display: 'table',
    width: '100%',
    maxWidth: '984px',
    marginBottom: '20px',
    overflow: 'hidden',
    tableLayout: 'fixed',
  },
  li: {
    display: 'table-cell',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
});

interface DateListProps {
  weekSelected: string;
  searchHeadWeek: (week: string) => void;
}

const DateList: FC<DateListProps> = ({ weekSelected, searchHeadWeek }) => {
  const theme = useTheme();
  const matches = {
    sm: useMediaQuery(theme.breakpoints.down('sm')),
  };
  const classes = useStyles();

  return matches.sm ? (
    <Box my={2}>
      <FormControl variant="outlined" className={classes.inputField}>
        <InputLabel id="demo-simple-select-outlined-label">曜日フィルター</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          defaultValue={weekSelected}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            searchHeadWeek(event.target.value as string);
          }}
          label="曜日フィルター"
        >
          {_.map(UserListField.WEEK_DAY, (weekDay, index) => (
            <MenuItem className={classes.menuItem} value={weekDay.key} key={index}>
              {weekDay.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  ) : (
    <Box>
      <ul className={classes.weekList}>
        <li className={classes.li} data-test={UserListField.MO}>
          <GeneralPrimarySelectButton
            txt="月"
            onClick={() => searchHeadWeek(UserListField.MO)}
            selected={weekSelected === UserListField.MO}
          />
        </li>
        <li className={classes.li} data-test={UserListField.TU}>
          <GeneralPrimarySelectButton
            txt="火"
            onClick={() => searchHeadWeek(UserListField.TU)}
            selected={weekSelected === UserListField.TU}
          />
        </li>
        <li className={classes.li} data-test={UserListField.WE}>
          <GeneralPrimarySelectButton
            txt="水"
            onClick={() => searchHeadWeek(UserListField.WE)}
            selected={weekSelected === UserListField.WE}
          />
        </li>
        <li className={classes.li} data-test={UserListField.TH}>
          <GeneralPrimarySelectButton
            txt="木"
            onClick={() => searchHeadWeek(UserListField.TH)}
            selected={weekSelected === UserListField.TH}
          />
        </li>
        <li className={classes.li} data-test={UserListField.FR}>
          <GeneralPrimarySelectButton
            txt="金"
            onClick={() => searchHeadWeek(UserListField.FR)}
            selected={weekSelected === UserListField.FR}
          />
        </li>
        <li className={classes.li} data-test={UserListField.SA}>
          <GeneralPrimarySelectButton
            txt="土"
            onClick={() => searchHeadWeek(UserListField.SA)}
            selected={weekSelected === UserListField.SA}
          />
        </li>
        <li className={classes.li} data-test={UserListField.SU}>
          <GeneralPrimarySelectButton
            txt="日"
            onClick={() => searchHeadWeek(UserListField.SU)}
            selected={weekSelected === UserListField.SU}
          />
        </li>
        <li className={classes.li} data-test={UserListField.ALL}>
          <GeneralPrimarySelectButton
            txt="全て"
            onClick={() => searchHeadWeek(UserListField.ALL)}
            selected={weekSelected === UserListField.ALL}
          />
        </li>
      </ul>
    </Box>
  );
};

export default DateList;
