import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store/configureStore';

import * as HeaderActions from '../actions/Header';
import * as MoffAPIActions from '../actions/MoffAPI';
import * as Header from '../constants/Header';
import MoffRakuUsers from '../components/Organisms/MoffRakuUsers';

type AllState = ReturnType<typeof store.getState>;

const MoffRakuUsersContainer: FC = () => {
  const dispatch = useDispatch();
  const moffAPI = useSelector((state: AllState) => state.moffAPI);

  useEffect(() => {
    (async () => {
      document.title = '利用者基本情報入力';
      dispatch(HeaderActions.setPage(Header.MOFF_RAKU_USERS));
      // await dispatch(MoffAPIActions.getUsers());
      dispatch(MoffAPIActions.duplicateRepeatSort());
      await dispatch(MoffAPIActions.getMoffRakuBasicInfoAndMasterData());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MoffRakuUsers moffAPI={moffAPI} />;
};

export default MoffRakuUsersContainer;
