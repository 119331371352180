/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';

const baseCss = (setCss?: string) => css`
  ${setCss}
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:hover {
    background: #e6e6e6;
    text-decoration: none;
  }

  @media print {
    display: none;
  }
`;
interface GeneralPrimaryGhostButtonProps {
  /** クリックした時のイベントハンドラ */
  onClick: () => void;
  /** ボタンのテキスト */
  innerText: string;
  /** 外部から渡すスタイル */
  setCss: string;
}

const GeneralPrimaryGhostButton: FC<GeneralPrimaryGhostButtonProps> = ({ onClick, innerText, setCss }) => {
  return (
    <button onClick={onClick} css={baseCss(setCss)} data-test="general-primary-ghost-button">
      {`${innerText}`}
    </button>
  );
};

export default GeneralPrimaryGhostButton;
