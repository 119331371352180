import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as PopupActions from '../actions/Popup';
import store from '../store/configureStore';

import Footer from '../components/Organisms/Footer';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

type AllState = ReturnType<typeof store.getState>;

const FooterContainer: FC = () => {
  const dispatch = useDispatch();
  const footerGlobalState = useSelector((state: AllState) => state.footer);
  const openPcPrint = async (): Promise<void> => {
    await dispatch(PopupActions.OpenPcPrint());
    dispatchIdentifierToGTM('menu_youtube_pc_print');
  };

  return <Footer isPcPrintDisplay={footerGlobalState.display} openPcPrint={openPcPrint} />;
};

export default FooterContainer;
