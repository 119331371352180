/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';

const link = css`
  position: relative;
  padding-left: 34px;
  color: #fff;
  font-size: 16px;
  &:before {
    position: absolute;
    top: 7px;
    left: 15px;
    width: 12px;
    height: 12px;
    content: '';
    border-bottom: 2px solid;
    border-left: 2px solid;
    transform: rotate(45deg);
  }
  @media print {
    display: none;
  }
`;

interface PrevLinkProps {
  /** ボタンテキスト */
  txt: string | JSX.Element;
  /** リンク先URL */
  href: string;
}

const PrevLink: FC<PrevLinkProps> = ({ txt, href }) => {
  return (
    <a css={link} href={href}>
      {txt}
    </a>
  );
};

export default PrevLink;
