// アプリユーザAPI.
// キーを変えるため通常のMoffAPIとはActionは分割.
import { createBarthelIndexAPIInstance } from '../api/BarthelIndexAPI';
import { ResponseData } from '../constants/AppUser';

const [isBarthelIndexAPIError, BarthelIndexAPIInstance, BarthelIndexAPIErrorAction] = createBarthelIndexAPIInstance();

/**
 * BIの詳細を取得【月指定】
 *
 * @param {string} userId
 * @returns
 */
export const getBarthelIndexRecordByMonth = (uniqueIds: ResponseData[], month: string) => {
  return isBarthelIndexAPIError
    ? BarthelIndexAPIErrorAction
    : BarthelIndexAPIInstance.getRecordsByMonthAPI(uniqueIds, month);
};
