import React, { FC } from 'react';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, Fab } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { POPUP_FUNCTION_NAMES } from '../../constants/Popup';

const useStyles = makeStyles(() =>
  createStyles({
    background: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
    },
    body: {
      backgroundColor: '#fff',
      '-webkit-transform': 'translateY(-50%) translateX(-50%)',
      transform: 'translateY(-50%) translateX(-50%)',
    },
    fab: {
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      border: '2px solid #fff',
      position: 'absolute',
      bottom: '-10px',
      left: '-18px',
      cursor: 'pointer',
    },
  }),
);

interface YouTubePCPrintProps {
  /** youtubeの機能名 */
  youtube: POPUP_FUNCTION_NAMES;
  /** 閉じるボタンがクリックされた時のイベントハンドラ */
  popupClose: () => void;
}

const YouTubePCPrint: FC<YouTubePCPrintProps> = ({ youtube, popupClose }) => {
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
  };
  const classes = useStyles();

  return (
    <Box
      width="100vw"
      height="100vh"
      position="fixed"
      top={0}
      left={0}
      zIndex={110}
      textAlign="center"
      className={classes.background}
      onClick={popupClose}
    >
      <Box p="10px" position="absolute" top="50%" left="50%" className={classes.body} data-test="pop-body">
        {youtube ? (
          <iframe
            title={youtube.title}
            width={matches.xs ? '300' : '560'}
            height={matches.xs ? '169' : '315'}
            src={youtube.src}
            allow="fullscreen"
          />
        ) : null}
        <Fab onClick={popupClose} aria-label="close" className={classes.fab} data-test="popup-close">
          <CloseIcon />
        </Fab>
      </Box>
    </Box>
  );
};

export default YouTubePCPrint;
