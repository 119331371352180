/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import store from '../store/configureStore';

import * as PasswordActions from '../actions/Password';
import GeneralPrimaryButton from '../components/Atoms/GeneralPrimaryButton';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

const input = css`
  width: 350px;
  margin: 25px 15px;
  padding: 12px 8px;
  border: 0;
  border: solid 1px #8c8c8c;
  border-radius: 4px;
  font-size: 20px;
  outline: 0;
  &:focus {
    border: solid 1px #ea6e37;
  }
`;

const th = css`
  font-size: 14px;
  text-align: right;
`;

type AllState = ReturnType<typeof store.getState>;
// componentのsignFormとの連係
const selector = formValueSelector('forgotPasswordForm');

const ForgotPasswordForm: FC = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: AllState) => state);

  const username = selector(state, 'username');

  const forgotPasswordForm = {
    username,
  };

  const handleSubmit = async () => {
    if (typeof forgotPasswordForm === 'undefined') {
      return;
    }
    // undefinedになってた時に最低から文字にしてcognite側で判断させる
    const username = forgotPasswordForm.username || '';

    await dispatch(
      PasswordActions.forgotPassword(username, (res: any, message: string) => {
        if (res === false) {
          alert(message);
        }
      }),
    );
    dispatchIdentifierToGTM('forget_password');
  };

  return (
    <div>
      <div>
        <h2>パスワードを忘れた方</h2>
        <form>
          <table>
            <tbody>
              <tr>
                <th css={th}>事業所ID</th>
                <td>
                  <Field
                    css={input}
                    name="username"
                    type="text"
                    component="input"
                    placeholder="事業所ID"
                    required={true}
                    autoFocus={true}
                  />
                </td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <td>
                  <GeneralPrimaryButton
                    txt="再発行手続きをする"
                    setCss="width: 240px; height: 60px; margin: 25px auto;"
                    onClick={() => handleSubmit()}
                    type="button"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div>
        <p>
          パスワードを紛失した場合は、今までのパスワードをお問い合わせいただくのではなく、再発行する手続きを取っていただく必要があります。
        </p>
        <p>再発行手続きは、事業所IDを入力して「再発行手続きする」ボタンをクリックして下さい。</p>
        <p>&nbsp;</p>
        <p>ボタンをクリックして、パスワードの再発行手続きが開始されると、</p>
        <ul>
          <li>1. ご契約時のメールアドレス宛てにメールが届きます。</li>
          <li>2. メールに「認証コード」が記載されているのを確認します。</li>
          <li>
            3.
            メールに記載されているURLをクリック(もしくはWebブラウザにコピー＆ペースト)してパスワード再発行ページを開きます。
          </li>
          <li>4. 開いたWebページに記載されている内容を読んで、手続きを行って下さい。</li>
          <li>以上の手続きで、新しいパスワードの再発行手続きが行なえます。</li>
        </ul>
        <p>&nbsp;</p>
        <p>※メールは、ご契約情報として登録がされているメールアドレスに送信されます。</p>
        <p>
          ご契約情報として登録がされているメールアドレスに送信されます。もしお届けのメールアドレスに届いていない場合は、担当営業にお問い合わせください。
        </p>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'forgotPasswordForm',
})(ForgotPasswordForm as any);
