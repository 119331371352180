/** @jsx jsx */
// graph toggle button
import { FC } from 'react';
import { jsx, css } from '@emotion/core';

const toggleBtnBox = css`
  display: flex;

  @media print {
    display: none;
  }
`;

const toggleBtn = (isSelected: boolean, side: string, customCss: string, isDisable: boolean) => {
  return css`
    font-weight: bold;
    font-size: 16px;
    width: 100px;
    text-align: center;
    box-shadow: ${isSelected ? '0 0 2px 1px rgba(34,36,38,.15)' : '0 0 2px 1px rgba(34,36,38,.15) inset'};
    cursor: ${isSelected ? 'default' : 'pointer'};
    background-color: ${!isDisable && isSelected
      ? '#ec7e4d'
      : !isDisable && !isSelected
      ? 'white'
      : isDisable && isSelected
      ? '#bdbdbd'
      : '#e0e0e0'};
    color: ${!isDisable && isSelected
      ? 'white'
      : !isDisable && !isSelected
      ? '#ffb492'
      : isDisable && isSelected
      ? '#757575'
      : '#9e9e9e'};
    border-radius: ${side === 'left' ? '20px 0 0 20px;' : ''};
    border-radius: ${side === 'right' ? '0 20px 20px 0;' : ''};
    ${customCss}
  `;
};

interface ToggleButton {
  /** トグルがOnかどうか */
  isToggleOn: boolean;
  /** 左のテキスト */
  leftText: string;
  /** 右のテキスト */
  rightText: string;
  /** 左のアイコン */
  leftIcon?: JSX.Element;
  /** 右のアイコン */
  rightIcon?: JSX.Element;
  /** disableかどうか */
  isDisable?: boolean;
  /** 外部から渡すスタイル */
  customCss: string;
  /** トグルOn/Off切り替え */
  toggleDisplayMode: (isToggleOn: boolean) => void;
}

const ToggleButton: FC<ToggleButton> = ({
  isToggleOn,
  toggleDisplayMode,
  leftText,
  rightText,
  leftIcon,
  rightIcon,
  customCss,
  isDisable = false,
}) => {
  return (
    <div css={toggleBtnBox}>
      <button
        onClick={!isToggleOn ? undefined : () => toggleDisplayMode(!isToggleOn)}
        css={
          !isToggleOn ? toggleBtn(true, 'left', customCss, isDisable) : toggleBtn(false, 'left', customCss, isDisable)
        }
        data-test="toggle-table"
      >
        {leftText}
        {leftIcon ?? leftIcon}
      </button>

      <button
        onClick={isToggleOn ? undefined : () => toggleDisplayMode(!isToggleOn)}
        css={
          isToggleOn ? toggleBtn(true, 'right', customCss, isDisable) : toggleBtn(false, 'right', customCss, isDisable)
        }
        data-test="toggle-graph"
      >
        {rightText}
        {rightIcon ?? rightIcon}
      </button>
    </div>
  );
};

export default ToggleButton;
