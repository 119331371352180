import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography } from '@material-ui/core';

import store from '../store/configureStore';

import * as AuthActions from '../actions/Auth';
import { waitResponseFromBrowser, dispatchIdentifierToGTM } from '../utils/commonUtil';
import GeneralPrimaryButton from '../components/Atoms/GeneralPrimaryButton';

const useStyles = makeStyles({
  bold: {
    fontWeight: 900,
  },
  inputForm: {
    width: '100%',
    margin: '0 0 10px',
    padding: '12px 8px',
    border: 'solid 1px #8c8c8c',
    borderRadius: '4px',
    fontSize: '20px',
    outline: 0,
    '&:focus': {
      border: 'solid 1px #ea6e37',
    },
  },
  description: {
    lineHeight: 1.8,
    textAlign: 'justify',
  },
});

type AllState = ReturnType<typeof store.getState>;
// componentのsignFormとの連係
const selector = formValueSelector('signForm');

const SignForm: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
  };
  const dispatch = useDispatch();
  const auth = useSelector((state: AllState) => state.auth);
  const state = useSelector((state: AllState) => state);

  const username = selector(state, 'username');
  const password = selector(state, 'password');

  const signForm = {
    username,
    password,
  };

  useEffect(() => {
    // 認証状態ならリダイレクトさせる
    if (auth.isAuthenticated === true) {
      window.location.href = '/admin.html';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const handleSubmit = async () => {
    // log in
    if (typeof AuthActions === 'undefined' || typeof signForm === 'undefined') {
      return;
    }

    // undefinedになってた時に最低から文字にしてcognite側で判断させる
    const username = signForm.username || '';
    const password = signForm.password || '';

    await dispatch(AuthActions.initialize());
    await waitResponseFromBrowser();

    await dispatch(AuthActions.signIn(username, password));
    dispatchIdentifierToGTM('login');
  };

  return (
    <Box m="100px auto 100px" p="0 10px">
      <form>
        <Grid container alignItems="center" spacing={1}>
          <Grid container item xs={'auto'} sm={1} lg={2} spacing={0} />
          <Grid container item xs={12} sm={3} lg={2} spacing={0}>
            <Box width="100%" m={{ xs: '0', sm: '0 0 10px' }}>
              <Typography variant="h6" component="h2" className={classes.bold}>
                施設アカウント
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs={12} sm={7} lg={6} spacing={0}>
            <Field
              data-test="accountid"
              className={classes.inputForm}
              name="username"
              component="input"
              type="text"
              placeholder="ID"
              required={true}
              autoFocus={true}
            />
          </Grid>
          {!matches.xs && (
            <>
              <Grid container item xs={'auto'} sm={1} lg={2} spacing={0} />
              <Grid container item xs={'auto'} sm={1} lg={2} spacing={0} />
            </>
          )}
          <Grid container item xs={12} sm={3} lg={2} spacing={0}>
            <Box width="100%" m={{ xs: '0', sm: '0 0 10px' }}>
              <Typography variant="h6" component="h2" className={classes.bold}>
                パスワード
              </Typography>
            </Box>
          </Grid>
          <Grid container item xs={12} sm={7} lg={6} spacing={0}>
            <Field
              data-test="password"
              className={classes.inputForm}
              name="password"
              component="input"
              type="password"
              placeholder="Password"
              required={true}
              autoFocus={true}
            />
          </Grid>
          <Grid container item xs={'auto'} sm={1} lg={2} spacing={0} />
          <Grid container item xs={12} spacing={0}>
            <GeneralPrimaryButton
              txt="ログイン"
              setCss="width: 240px; height: 60px; margin: 25px auto;"
              onClick={() => handleSubmit()}
              type="button"
            />
          </Grid>
          <Grid container item xs={'auto'} sm={1} lg={2} spacing={0} />
          <Grid container item xs={12} sm={10} lg={8} spacing={0}>
            <Typography variant="body1" component="p" className={classes.description}>
              モフトレ・レポートにログインできない時は…
              <br />
              ログインできない原因としては、IDやパスワードを入力にミスがあるのかもしれません。
              <br />
              <br />
              以下のような要因が考えられます。
              <br />
              *大文字と小文字が正しく入力されていない
              <br />
              *全角での入力になってしまっている
              <br />
              *数字を入力したつもりが、テンキー入力(数字のキーボード)が無効になっている
              <br />
              *IDやパスワードにスペースが入力されてしまっている
              <br />
              <br />
              ログインできない時は、焦らずに上記の可能性をしっかりと確認し、
              <br />
              それでもログインできない際はモフトレのカスタマーサポートに問い合わせるようにしましょう。
            </Typography>
          </Grid>
          <Grid container item xs={'auto'} sm={1} lg={2} spacing={0} />
        </Grid>
      </form>
    </Box>
  );
};

export default reduxForm({
  // configureStoreのformとの連携
  form: 'signForm',
})(SignForm as FC);
