// 居宅訪問API.
import { AxiosInstance } from 'axios';
import * as _ from 'lodash';
import { MoffAPIActionType } from '../constants/MoffAPI';
import { MoffAPI } from './MoffAPI';
import { createAxiosInstance } from '../constants/AWS';
import { errorFunc, apiRequestFunc } from '../utils/apiUtil';

export class HomeVisitAPI {
  /** インスタンス */
  private static _instance: HomeVisitAPI;
  private axiosInstance!: AxiosInstance;

  /** インスタンスの取得 */
  public static get instance(): HomeVisitAPI | null {
    // _inctanceが存在しない場合に、new User()を実行する。
    if (!this._instance) {
      this._instance = new HomeVisitAPI();

      // .envの設定
      const API_BASE_URL = String(process.env.REACT_APP_HOME_VISIT_API_URL);
      const API_KEY = String(process.env.REACT_APP_HOME_VISIT_API_KEY);
      if (typeof API_BASE_URL === 'undefined') {
        console.log('.envにREACT_APP_HOME_VISIT_API_URLの設定がありません.');
        return null;
      }
      if (typeof API_KEY === 'undefined') {
        console.log('.envにREACT_APP_HOME_VISIT_API_KEYの設定がありません.');
        return null;
      }
      const axiosInstance = createAxiosInstance(API_BASE_URL, API_KEY);

      if (axiosInstance) {
        this._instance.axiosInstance = axiosInstance;
      } else {
        return null;
      }
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  // 件数取得.
  public async getRecordCountAPI(uniqueId: string) {
    const url = `/homevisit/users/${uniqueId}/records`;
    return this.axiosInstance.get(url);
  }

  /**
   * 指定の1件を取得.
   *
   * @param {string} userId
   * @param {string} uniqueId
   * @param {number} sequence
   * @returns {*}
   * @memberof HomeVisit
   */
  public getRecordAPI(userId: string, uniqueId: string, sequence: number) {
    const moff = MoffAPI.instance;
    if (moff === null) {
      return errorFunc(MoffAPIActionType.ERROR, '不明なエラーが発生しました.');
    }

    const uniqueLogicFunc: any = async () => {
      const userDataMoff = await moff.getUserFunc(userId).then((response) => response.data);
      const url = `/homevisit/users/${uniqueId}/records/${sequence}`;
      const interestCheckData = await this.axiosInstance.get(url).then((response) => response.data);
      return _.zipObject(['user', 'homeVisit'], [userDataMoff, interestCheckData]);
    };

    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }
}

export const createHomeVisitAPIInstance: any = () => {
  // Moffインスタンスが存在しない場合、エラーアクションを返す
  return HomeVisitAPI.instance === null
    ? [true, null, errorFunc(MoffAPIActionType.ERROR, '不明なエラーが発生しました.')]
    : [false, HomeVisitAPI.instance, null];
};
