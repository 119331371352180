import React, { FC } from 'react';

import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface DialogAlertProps {
  /** ダイアログの開閉状態 */
  dialogOpen: boolean;
  /** ダイアログの開閉状態変更 */
  setDialogOpen: (isOpen: boolean) => void;
  /** ダイアログのタイトル */
  dialogTitle?: string | null;
  /** ダイアログの内容 */
  dialogContent?: JSX.Element | null;
  /** ダイアログの左ボタン */
  leftButton?: JSX.Element | null;
  /** ダイアログの右ボタン */
  rightButton?: JSX.Element | null;
}

export const DialogAlert: FC<DialogAlertProps> = ({
  dialogOpen,
  setDialogOpen,
  dialogTitle,
  dialogContent,
  leftButton,
  rightButton,
}) => {
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth={true}>
        {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
        {dialogContent && <DialogContent>{dialogContent}</DialogContent>}
        <DialogActions>
          <Box display="flex" flexWrap="wrap" justifyContent={matches.xs ? 'center' : 'flex-start'}>
            {leftButton && leftButton}
            {rightButton && rightButton}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogAlert;
