// 各ブラウザの親クラス.
import React, { FC } from 'react';
import * as Const from '../../../constants/InterestCheck';
import { detect } from 'detect-browser';
import { User, Male, Female } from '../../../constants/UserListField';
import { convertInterestCheckVisitDate } from '../../../utils/dateUtil';
import { ResponseData, Check, Multiple } from '../../../constants/InterestCheck';

// 興味関心チェックシート.
interface TableProps {
  user: User;
  interestCheck: ResponseData;
}

/**
 * 1行表示.
 * @method RenderRow
 * @param {String} leftItem 左の項目.
 * @param {String} rightItem 右の項目.
 * @param {Check} leftCheckList 左の項目の丸かどうかリスト.
 * @param {Check} rightCheckList 右の項目の丸かどうかリスト.
 */

interface RenderRowProps {
  leftItem: string;
  rightItem: string;
  leftCheckList: Check;
  rightCheckList: Check;
}

const RenderRow: React.FC<RenderRowProps> = ({ leftItem, rightItem, leftCheckList, rightCheckList }) => {
  return (
    <tr className="oneRow" data-test="interestCheckTableRow">
      <td className="leftTd">{leftItem}</td>
      <td className="middleTd">{leftCheckList.do ? Const.CIRCLE : ''}</td>
      <td className="middleTd">{leftCheckList.want ? Const.CIRCLE : ''}</td>
      <td className="middleTd">{leftCheckList.interested ? Const.CIRCLE : ''}</td>
      <td className="leftTd">{rightItem}</td>
      <td className="middleTd">{rightCheckList.do ? Const.CIRCLE : ''}</td>
      <td className="middleTd">{rightCheckList.want ? Const.CIRCLE : ''}</td>
      <td className="rightTd">{rightCheckList.interested ? Const.CIRCLE : ''}</td>
    </tr>
  );
};

/**
 * 1行表示(改行あり).
 * @method RenderRowWithBr
 * @param {String} leftItem 左の項目.
 * @param {String} rightItem1 右の項目改行まで.
 * @param {String} rightItem2 右の項目改行後.
 * @param {Check} leftCheckList 左の項目の丸かどうかリスト.
 * @param {Check} rightCheckList 右の項目の丸かどうかリスト.
 * @return {JSX.Element} JSX.
 */

interface RenderRowWithBrProps {
  leftItem: string;
  rightItem1: string;
  rightItem2: string;
  leftCheckList: Check;
  rightCheckList: Check;
}

const RenderRowWithBr: React.FC<RenderRowWithBrProps> = ({
  leftItem,
  rightItem1,
  rightItem2,
  leftCheckList,
  rightCheckList,
}) => {
  return (
    <tr className="oneRow" data-test="interestCheckTableRow">
      <td className="leftTd">{leftItem}</td>
      <td className="middleTd">{leftCheckList.do ? Const.CIRCLE : ''}</td>
      <td className="middleTd">{leftCheckList.want ? Const.CIRCLE : ''}</td>
      <td className="middleTd">{leftCheckList.interested ? Const.CIRCLE : ''}</td>
      {/* フォント小さく */}
      <td className="leftTd smallFont">
        {rightItem1}
        <br />
        {rightItem2}
      </td>
      <td className="middleTd">{rightCheckList.do ? Const.CIRCLE : ''}</td>
      <td className="middleTd">{rightCheckList.want ? Const.CIRCLE : ''}</td>
      <td className="rightTd">{rightCheckList.interested ? Const.CIRCLE : ''}</td>
    </tr>
  );
};

/**
 * 最後の1行表示.
 * @method RenderLastRow
 * @param {String} leftItem 左の項目.
 * @param {String} rightItem 右の項目.
 * @param {Check} leftCheckList 左の項目の丸かどうかリスト.
 * @param {Check} rightCheckList 右の項目の丸かどうかリスト.
 * @return {JSX.Element} JSX.
 */

interface RenderLastRowProps {
  leftItem: string;
  rightItem: string;
  leftCheckList: Check;
  rightCheckList: Check;
}

const RenderLastRow: React.FC<RenderLastRowProps> = ({ leftItem, rightItem, leftCheckList, rightCheckList }) => {
  return (
    <tr className="oneRow" data-test="interestCheckTableRow">
      <td className="bottomLeftTd">{leftItem}</td>
      <td className="bottomMiddleTd">{leftCheckList.do ? Const.CIRCLE : ''}</td>
      <td className="bottomMiddleTd">{leftCheckList.want ? Const.CIRCLE : ''}</td>
      <td className="bottomMiddleTd">{leftCheckList.interested ? Const.CIRCLE : ''}</td>
      <td className="bottomLeftTd">{rightItem}</td>
      <td className="bottomMiddleTd">{rightCheckList.do ? Const.CIRCLE : ''}</td>
      <td className="bottomMiddleTd">{rightCheckList.want ? Const.CIRCLE : ''}</td>
      <td className="bottomRightTd">{rightCheckList.interested ? Const.CIRCLE : ''}</td>
    </tr>
  );
};

/**
 * その他の文字列生成.
 * @method createOther
 * @param {Multiple} other その他のMultiple.
 * @param {string} key Multipleのキー.
 * @return {string} 結果的な文字.
 */
const createOther = (other: Multiple, key: keyof Multiple) => {
  if (other) {
    const value = other[key];
    if (value) {
      const column = value.column;
      return `${Const.OTHER}(${column})`;
    }
  }

  const browser = detect();
  if (browser) {
    const name: string = browser.name;
    // Edgeだけその他の空を短く.
    if (name === 'edge') {
      return `${Const.OTHER}(           )`;
    }
  }

  return `${Const.OTHER}(                 )`;
};

const BaseBrowser: FC<TableProps> = ({ user, interestCheck }) => {
  let hearingDate;
  if (interestCheck) {
    // 聞き取り日.
    hearingDate = interestCheck.visited_day ? convertInterestCheckVisitDate(interestCheck.visited_day) : '';
  }

  const defaultVal = { interested: false, do: false, want: false };
  let checkOther1: Check = defaultVal;
  let checkOther2: Check = defaultVal;
  let checkOther3: Check = defaultVal;
  let checkOther4: Check = defaultVal;

  // そもそもadditional_itemが存在しないことも有る.
  if (interestCheck.additional_item) {
    if (interestCheck.additional_item.other1) {
      checkOther1 = interestCheck.additional_item.other1.check;
    }
    if (interestCheck.additional_item.other2) {
      checkOther2 = interestCheck.additional_item.other2.check;
    }
    if (interestCheck.additional_item.other3) {
      checkOther3 = interestCheck.additional_item.other3.check;
    }
    if (interestCheck.additional_item.other4) {
      checkOther4 = interestCheck.additional_item.other4.check;
    }
  }

  return (
    <>
      <br id="blank" key="interest_check_print1" />
      <div key="interest_check_print2" className="tableTitle interest_check_table">
        興味関心チェックシート
      </div>
      <div key="interest_check_print3" className="hearing interest_check_table" data-test="hearing">
        聞き取り日：{hearingDate}
      </div>
      <table key="interest_check_print4" className="interest_check_table">
        <tbody>
          <tr className="oneRow">
            <td className="nameTd" data-test="nameTd">
              氏名：{user.user_name}
            </td>
            <td className="birthTd" data-test="birthTd">
              生年：{user.user_birth_year}年
            </td>
            {/* 微妙に右にずらすためにスペースが入っている */}
            {/* TODO: あとで歳を戻すためコメントアウト */}
            {/* <td className="ageTd"> 歳</td> */}
            <td className="genderTd" data-test="genderTd">
              性別：{user.user_gender === 0 ? Male : Female}
            </td>
          </tr>
        </tbody>
      </table>
      <br key="interest_check_print5" />
      <table key="interest_check_print6" className="interest_check_table">
        <tbody>
          <tr className="oneRow">
            <td className="topLeftTd">生活行為</td>
            <td className="topMiddleTd smallFont">している</td>
            <td className="topMiddleTd smallFont">してみたい</td>
            <td className="topMiddleTd smallFont">興味がある</td>
            <td className="topLeftTd">生活行為</td>
            <td className="topMiddleTd smallFont">している</td>
            <td className="topMiddleTd smallFont">してみたい</td>
            <td className="topRightTd smallFont">興味がある</td>
          </tr>
          <RenderRow
            leftItem={Const.TOILET}
            rightItem={Const.LIFELONG_LEARNING}
            leftCheckList={interestCheck.toilet}
            rightCheckList={interestCheck.lifelong_learning}
          />
          <RenderRow
            leftItem={Const.BATHING}
            rightItem={Const.READING}
            leftCheckList={interestCheck.bathing}
            rightCheckList={interestCheck.reading}
          />
          <RenderRow
            leftItem={Const.WEARING}
            rightItem={Const.HAIKU}
            leftCheckList={interestCheck.wearing}
            rightCheckList={interestCheck.haiku}
          />
          <RenderRow
            leftItem={Const.EATING}
            rightItem={Const.SHODO}
            leftCheckList={interestCheck.eating}
            rightCheckList={interestCheck.shodo}
          />
          <RenderRow
            leftItem={Const.BRUSHING}
            rightItem={Const.PAINTING}
            leftCheckList={interestCheck.brushing}
            rightCheckList={interestCheck.painting}
          />
          <RenderRow
            leftItem={Const.GROOMING}
            rightItem={Const.TYPING}
            leftCheckList={interestCheck.grooming}
            rightCheckList={interestCheck.typing}
          />
          <RenderRow
            leftItem={Const.SLEEPING}
            rightItem={Const.PHOTO}
            leftCheckList={interestCheck.sleeping}
            rightCheckList={interestCheck.photo}
          />
          <RenderRow
            leftItem={Const.CLEANING}
            rightItem={Const.THEATER}
            leftCheckList={interestCheck.cleaning}
            rightCheckList={interestCheck.theater}
          />
          <RenderRow
            leftItem={Const.COOKING}
            rightItem={Const.SADO_KADO}
            leftCheckList={interestCheck.cooking}
            rightCheckList={interestCheck.sado_kado}
          />
          <RenderRow
            leftItem={Const.SHOPPING}
            rightItem={Const.SINGING}
            leftCheckList={interestCheck.shopping}
            rightCheckList={interestCheck.singing}
          />
          <RenderRow
            leftItem={Const.GARDENING}
            rightItem={Const.MUSIC}
            leftCheckList={interestCheck.gardening}
            rightCheckList={interestCheck.music}
          />
          <RenderRow
            leftItem={Const.LAUNDERING}
            rightItem={Const.GAMING}
            leftCheckList={interestCheck.laundering}
            rightCheckList={interestCheck.gaming}
          />
          <RenderRow
            leftItem={Const.DRIVING}
            rightItem={Const.EXERCISING}
            leftCheckList={interestCheck.driving}
            rightCheckList={interestCheck.exercising}
          />
          <RenderRow
            leftItem={Const.OUTING}
            rightItem={Const.STROLLING}
            leftCheckList={interestCheck.outing}
            rightCheckList={interestCheck.strolling}
          />

          <RenderRowWithBr
            leftItem={Const.CHILDREN}
            rightItem1={Const.PLAYING_SPORTS1}
            rightItem2={Const.PLAYING_SPORTS2}
            leftCheckList={interestCheck.children}
            rightCheckList={interestCheck.playing_sports}
          />
          <RenderRow
            leftItem={Const.ANIMAL}
            rightItem={Const.DANCING}
            leftCheckList={interestCheck.animal}
            rightCheckList={interestCheck.dancing}
          />
          <RenderRow
            leftItem={Const.FRIENDS}
            rightItem={Const.WATCHING_SPORTS}
            leftCheckList={interestCheck.friends}
            rightCheckList={interestCheck.watching_sports}
          />
          <RenderRow
            leftItem={Const.FAMILY}
            rightItem={Const.GAMBLING}
            leftCheckList={interestCheck.family}
            rightCheckList={interestCheck.gambling}
          />
          <RenderRow
            leftItem={Const.DATING}
            rightItem={Const.KNITTING}
            leftCheckList={interestCheck.dating}
            rightCheckList={interestCheck.knitting}
          />
          <RenderRow
            leftItem={Const.DRINKING}
            rightItem={Const.SEWING}
            leftCheckList={interestCheck.drinking}
            rightCheckList={interestCheck.sewing}
          />
          <RenderRow
            leftItem={Const.VOLUNTEER}
            rightItem={Const.CROPPING}
            leftCheckList={interestCheck.volunteer}
            rightCheckList={interestCheck.cropping}
          />
          <RenderRow
            leftItem={Const.COMMUNITY_ACTIVITY}
            rightItem={Const.EARNING}
            leftCheckList={interestCheck.community_activity}
            rightCheckList={interestCheck.earning}
          />
          <RenderRow
            leftItem={Const.RELIGION}
            rightItem={Const.TRAVELING}
            leftCheckList={interestCheck.religion}
            rightCheckList={interestCheck.traveling}
          />
          <RenderRow
            leftItem={createOther(interestCheck.additional_item, 'other1')}
            rightItem={createOther(interestCheck.additional_item, 'other3')}
            leftCheckList={checkOther1}
            rightCheckList={checkOther3}
          />

          <RenderLastRow
            leftItem={createOther(interestCheck.additional_item, 'other2')}
            rightItem={createOther(interestCheck.additional_item, 'other4')}
            leftCheckList={checkOther2}
            rightCheckList={checkOther4}
          />
        </tbody>
      </table>
    </>
  );
};

export default BaseBrowser;
