import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as HeaderActions from '../actions/Header';
import * as Header from '../constants/Header';
import PopupContainer from './Popup';
import SignForm from './SignForm';

const Login: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      document.title = 'モフトレ・レポート';
      dispatch(HeaderActions.setPage(Header.LOGIN));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PopupContainer />
      <SignForm key="signForm" />
    </>
  );
};

export default Login;
