// アプリユーザ(actionのtype, reducerのstate)
// 他のMoffAPIとキーがかぶらないようにAPPUSER_をプレフィックス.
export enum AppUserActionType {
  LOADING = 'APPUSER_LOADING',
  SUCCESS = 'APPUSER_SUCCESS',
  ERROR = 'APPUSER_ERROR',
}

// アプリユーザの存在確認の返り値.
export interface ResponseData {
  app_id: number;
  updated_at: string;
  created_at: string;
  unique_id: string;
  app_user_id: string;
  institution_sub?: string;
}
