/** @jsx jsx */
// graph toggle button
import { FC, memo } from 'react';
import { jsx, css } from '@emotion/core';
import { TOGGLE_POSITION } from '../../constants/Training';

const toggleBtnBox = css`
  display: flex;
`;

const toggleBtn = (isSelected: boolean, side: TOGGLE_POSITION, customCss: string, isDisable: boolean) => {
  return css`
    ${customCss}
    font-weight: bold;
    font-size: 16px;
    width: 80px;
    text-align: center;
    box-shadow: ${isSelected ? '0 0 2px 1px rgba(34,36,38,.15)' : '0 0 2px 1px rgba(34,36,38,.15) inset'};
    cursor: ${isSelected ? 'default' : 'pointer'};
    background-color: ${!isDisable && isSelected
      ? '#ec7e4d'
      : !isDisable && !isSelected
      ? 'white'
      : isDisable && isSelected
      ? '#bdbdbd'
      : '#e0e0e0'};
    color: ${!isDisable && isSelected
      ? 'white'
      : !isDisable && !isSelected
      ? '#ffb492'
      : isDisable && isSelected
      ? '#757575'
      : '#9e9e9e'};
    border-radius: ${side === 'left' ? '20px 0 0 20px;' : ''};
    border-radius: ${side === 'right' ? '0 20px 20px 0;' : ''};
    border-radius: ${side === 'center' ? '0 0 0 0;' : ''};
    @media print {
      color: ${!isDisable && isSelected
        ? 'black'
        : !isDisable && !isSelected
        ? '#9e9e9e'
        : isDisable && isSelected
        ? '#e3e3e3'
        : '#e3e3e3'};
      display: ${!isDisable && isSelected ? 'block' : 'none'};
      box-shadow: none;
      background-color: white;
      font-size: 20px;
    }
  `;
};

interface ToggleButtonThreePositionProps {
  /** トグルの位置 */
  togglePosition?: TOGGLE_POSITION;
  /** 左のテキスト */
  leftText: string;
  /** 右のテキスト */
  rightText: string;
  /** 中央のテキスト */
  centerText: string;
  /** 左のアイコン */
  leftIcon?: JSX.Element;
  /** 右のアイコン */
  rightIcon?: JSX.Element;
  /** 中央のアイコン */
  centerIcon?: JSX.Element;
  /** disableかどうか */
  isDisable?: boolean;
  /** 外部から渡すスタイル */
  customCss: string;
  /** トグル位置切り替え */
  changeTogglePosition?: (togglePosition: TOGGLE_POSITION) => void;
}

const ToggleButtonThreePosition: FC<ToggleButtonThreePositionProps> = ({
  togglePosition = null,
  changeTogglePosition = () => null,
  leftText,
  rightText,
  centerText,
  leftIcon,
  rightIcon,
  centerIcon,
  customCss,
  isDisable = false,
}) => {
  return (
    <div css={toggleBtnBox}>
      <button
        onClick={togglePosition === TOGGLE_POSITION.Left ? undefined : () => changeTogglePosition(TOGGLE_POSITION.Left)}
        css={
          togglePosition === TOGGLE_POSITION.Left
            ? toggleBtn(true, TOGGLE_POSITION.Left, customCss, isDisable)
            : toggleBtn(false, TOGGLE_POSITION.Left, customCss, isDisable)
        }
        data-test="toggle-left"
      >
        {leftText}
        {leftIcon ?? leftIcon}
      </button>

      <button
        onClick={
          togglePosition === TOGGLE_POSITION.Center ? undefined : () => changeTogglePosition(TOGGLE_POSITION.Center)
        }
        css={
          togglePosition === TOGGLE_POSITION.Center
            ? toggleBtn(true, TOGGLE_POSITION.Center, customCss, isDisable)
            : toggleBtn(false, TOGGLE_POSITION.Center, customCss, isDisable)
        }
        data-test="toggle-center"
      >
        {centerText}
        {centerIcon ?? centerIcon}
      </button>

      <button
        onClick={
          togglePosition === TOGGLE_POSITION.Right ? undefined : () => changeTogglePosition(TOGGLE_POSITION.Right)
        }
        css={
          togglePosition === TOGGLE_POSITION.Right
            ? toggleBtn(true, TOGGLE_POSITION.Right, customCss, isDisable)
            : toggleBtn(false, TOGGLE_POSITION.Right, customCss, isDisable)
        }
        data-test="toggle-right"
      >
        {rightText}
        {rightIcon ?? rightIcon}
      </button>
    </div>
  );
};

export default memo(ToggleButtonThreePosition, (prevProps, nextProps) => {
  return prevProps.togglePosition === nextProps.togglePosition ? true : false;
});
