// アプリユーザAPI.
import { createAppUserAPIInstance } from '../api/AppUser';

const [isAppUserAPIError, appUserAPIInstance, appUserAPIErrorAction] = createAppUserAPIInstance();

/**
 * 機能訓練レポート・モフトレチェック の登録があるユーザか確認
 * 機能訓練レポート・モフトレチェック 登録確認後の処理が同一のためまとめる
 *
 * @param {string} userId
 * @param {Function} moveToDetailPagePath
 * @returns
 */
export const checkAppUser = (userId: string, moveToDetailPagePath: Function) => {
  return isAppUserAPIError ? appUserAPIErrorAction : appUserAPIInstance.checkAppUserAPI(userId, moveToDetailPagePath);
};

/**
 * 施設に存在する全ての利用者のuniqueIdを取得（削除利用者含む）
 * @returns
 */
export const checkAppUsers = () => (isAppUserAPIError ? appUserAPIErrorAction : appUserAPIInstance.checkAppUsersAPI());

/**
 * 興味関心チェック の登録があるユーザか確認.
 *
 * @param {string} userId
 * @returns
 */
export const checkAppUserForInterestCheck = (userId: string) => {
  return isAppUserAPIError ? appUserAPIErrorAction : appUserAPIInstance.checkAppUserForInterestCheckAPI(userId);
};

/**
 * 居宅訪問 の登録があるユーザか確認.
 *
 * @param {string} userId
 * @returns
 */
export const checkAppUserForHomeVisit = (userId: string) => {
  return isAppUserAPIError ? appUserAPIErrorAction : appUserAPIInstance.checkAppUserForHomeVisitAPI(userId);
};

/**
 * 生活機能チェック の登録があるユーザか確認.
 *
 * @param {string} userId
 * @returns
 */
export const checkAppUserForLifeCheck = (userId: string) => {
  return isAppUserAPIError ? appUserAPIErrorAction : appUserAPIInstance.checkAppUserForLifeCheckAPI(userId);
};
