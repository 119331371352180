import { createPdfCreateInstance } from '../api/pdfCreate';
import { MOFF_CHECK_COMPARE_LOGIC } from '../constants/MoffCheck';

const [isCreatePdfCreateError, pdfCreateInstance, createPdfCreateErrorAction] = createPdfCreateInstance();

export const createMoffCheckV1PDF = (
  compareMonth: MOFF_CHECK_COMPARE_LOGIC,
  selectMonths: string[],
  userIds: string[],
  items: string[],
  isWalkingAbility: boolean,
) => {
  return isCreatePdfCreateError
    ? createPdfCreateErrorAction
    : pdfCreateInstance.createMoffCheckV1PDFRequest(compareMonth, selectMonths, userIds, items, isWalkingAbility);
};

export const createMoffCheckV2PDF = (
  compareMonth: MOFF_CHECK_COMPARE_LOGIC,
  selectMonths: string[],
  userIds: string[],
  comments: { user_id: string; text: string }[],
  isAllUsersPrint: boolean,
) => {
  return isCreatePdfCreateError
    ? createPdfCreateErrorAction
    : pdfCreateInstance.createMoffCheckV2PDFRequest(compareMonth, selectMonths, userIds, comments, isAllUsersPrint);
};

export const createKirokuPDF = (selectMonths: string, userIds: string[]) => {
  return isCreatePdfCreateError
    ? createPdfCreateErrorAction
    : pdfCreateInstance.createKirokuPDFRequest(selectMonths, userIds);
};

export const createLiterallyPDF = (selectMonths: string, userIds: string[]) => {
  return isCreatePdfCreateError
    ? createPdfCreateErrorAction
    : pdfCreateInstance.createLiterallyPDFRequest(selectMonths, userIds);
};

export const createPressPDF = (
  selectMonth: string,
  userIds: string[],
  comments: { user_id: string; text: string }[],
) => {
  return isCreatePdfCreateError
    ? createPdfCreateErrorAction
    : pdfCreateInstance.createPressPDFRequest(selectMonth, userIds, comments);
};

export type pdfCreateAction = ReturnType<typeof createMoffCheckV2PDF>;
