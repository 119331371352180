import { MoffAPIActionType } from '../../constants/MoffAPI';
import { duplicateRepeatSort } from '../../utils/commonUtil';
import { USERS_SORT_KEYS } from '../../constants/UserListField';
import _ from 'lodash';
import { addLatestDisplayDate, usersSortByKey } from '../../utils/commonUtil';

const initialState = {
  Loading: false,
  Loaded: false,
  result: {},
};

export default function moffAPI(state: any = initialState, action: any) {
  switch (action.type) {
    case MoffAPIActionType.SUCCESS:
      return Object.assign({}, state, {
        Loading: false,
        Loaded: true,
        result: action.result,
      });
    case MoffAPIActionType.LOADING:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
      });
    case MoffAPIActionType.INITIALIZE:
      return Object.assign({}, state, {
        Loading: false,
        Loaded: false,
        result: action.result,
      });
    case MoffAPIActionType.DUPLICATE_REPEAT_SORT: {
      // 利用者が存在しないときはソートしない
      const sortedUsers = _.isEmpty(state.result) ? state.result : duplicateRepeatSort(USERS_SORT_KEYS, state.result);

      return {
        ...state,
        result: sortedUsers,
      };
    }
    case MoffAPIActionType.PRESS_DUPLICATE_REPEAT_SORT: {
      // 利用者が存在しないときはソートしない
      const sortedUsers = _.isEmpty(state.result.users)
        ? state.result.users
        : duplicateRepeatSort(USERS_SORT_KEYS, state.result.users);

      return {
        ...state,
        result: sortedUsers,
      };
    }
    case MoffAPIActionType.ADD_DISPLAY_DATE:
      return {
        ...state,
        result: addLatestDisplayDate(state.result, action.payload),
      };
    case MoffAPIActionType.SORT_BY_KEY: {
      // 利用者が存在しないときはソートしない
      const sortedByKey = _.isEmpty(state.result) ? state.result : usersSortByKey(state.result, action.payload);

      return {
        ...state,
        result: sortedByKey,
      };
    }
    default:
      return state;
  }
}
