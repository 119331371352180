/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { FC } from 'react';

const btnCss = css`
  background: linear-gradient(0deg, rgba(121, 200, 0, 1) 0%, rgba(128, 217, 59, 1) 100%);
  height: 40px;
  padding: 0 15px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3em;
  color: #fff;
  border: solid 2px #ddd9d4;
  border-radius: 40px;
  display: block;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
  @media print {
    display: none;
  }
`;

interface VideoButtonProps {
  /** ボタンテキスト */
  text: string;
  /** ボタンがクリックされた時のイベントハンドラ */
  onClick: () => void;
}

const VideoButton: FC<VideoButtonProps> = ({ text, onClick }) => (
  <button css={btnCss} onClick={() => onClick()} data-test="video-btn">
    {text}
  </button>
);

export default VideoButton;
