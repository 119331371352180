// 機能訓練レポートモジュール.
import _ from 'lodash';
import moment from 'moment';
import { HdsrData } from '../constants/HDSR';

export const hdsrMonthsInTimeRange = (hdsrData: HdsrData[], startDate: string, endDate: string) => {
  return _(hdsrData)
    .map((hdsrData) => {
      return moment(moment(hdsrData.timestamp).format('YYYY-MM-DD')).isBetween(startDate, endDate, undefined, '[]')
        ? moment(hdsrData.timestamp).format('YYYY-MM')
        : null;
    })
    .compact()
    .value();
};
