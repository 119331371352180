/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';

const select = (selected: boolean) => css`
  color: ${selected ? '#fff' : null};
  background: ${selected ? 'linear-gradient(0deg, rgb(234, 109, 54) 0%, rgb(239, 148, 107) 100%)' : 'transparent'};
  width: 94px;
  max-width: 100%;
  height: 44px;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.2;
  border-radius: 4px;
  padding: 0;
`;

interface GeneralPrimarySelectButtonProps {
  /** ボタンのテキスト */
  txt: string;
  /** 選択されているかどうか */
  selected: boolean;
  /** クリックされた時のイベントハンドラ */
  onClick: (event: any) => void;
}

const GeneralPrimarySelectButton: FC<GeneralPrimarySelectButtonProps> = ({ txt, onClick, selected }) => {
  return (
    <button
      css={select(selected)}
      onClick={onClick}
      data-test={`general-primary-select-button${selected ? '-selected' : ''}`}
    >
      {txt}
    </button>
  );
};

export default GeneralPrimarySelectButton;
