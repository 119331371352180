import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store/configureStore';

import * as AuthActions from '../actions/Auth';
import App from '../components/App';

import '../common/css/reset.css';
import '../common/css/cmn_layout.css';
import '../common/css/cmn_style.css';
import '../common/css/print.css';
// TODO: モフトレチェック歩行評価レポート用CSS。インフラPDF化したら消す。
import '../common/css/moff_check.css';

type AllState = ReturnType<typeof store.getState>;

const AppContainer: FC = () => {
  const dispatch = useDispatch();
  const authGlobalState = useSelector((state: AllState) => state.auth);

  useEffect(() => {
    dispatch(AuthActions.getSession());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authGlobalState.isSignedOut) {
      window.location.href = '/';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authGlobalState.isSignedOut]);

  return <App loaded={authGlobalState.Loaded} planType={authGlobalState.planType} />;
};

export default AppContainer;
