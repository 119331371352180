// Safari用.
import React, { FC } from 'react';
import { User } from '../../../constants/UserListField';
import { ResponseData } from '../../../constants/InterestCheck';
import BaseBrowser from './BaseBrowser';
import DummyBaseBrowser from './DummyBaseBrowser';

interface SafariProps {
  user: User;
  interestCheck: ResponseData;
  loaded: boolean;
}

// 興味関心チェックシート.
const Safari: FC<SafariProps> = ({ user, interestCheck, loaded }) => {
  return (
    <>
      {loaded ? <BaseBrowser user={user} interestCheck={interestCheck} /> : <DummyBaseBrowser />}
      <style key="interest_check_print_css" type="text/css">
        {'body,div,table,thead,tbody,tfoot,tr,th,td,p { font-size: small; padding:0 }'}
        {'body { -webkit-print-color-adjust: exact; }'}

        {/* 通常は小さめ */}
        {'.interest_check_table { width: 78%; table-layout: fixed; margin-left: 30mm; }'}

        {/* 印刷時 */}
        {'@media print{ body {margin: 0;} @page { size: 21.0cm 29.7cm;' +
          ' margin: 0; } ' +
          ' .interest_check_table { table-layout: fixed; margin-left: 0; }' +
          ' #interestcheck { width: 120%; padding: 0; }' +
          // 非表示系.
          ' .kiroku_edit_tpl { display:none; }' +
          ' #blank { display:none; }' +
          ' header { display:none; }' +
          ' }'}

        {'.smallFont { font-size: xx-small; }'}

        {'.tableTitle { text-align: left; vertical-align: middle; }'}

        {'.hearing { text-align: right; vertical-align: middle; }'}

        {/* 名前 */}
        {'.nameTd {' +
          ' border-top: 2px solid black; border-bottom: 2px solid black;' +
          ' border-left: 2px solid black; border-right: 0px solid black;' +
          ' text-align: left; vertical-align: middle;' +
          ' width: 34.0%; }'}

        {/* 生年 */}
        {'.birthTd {' +
          ' border-top: 2px solid black; border-bottom: 2px solid black; border-left:' +
          ' 1px solid black; border-right: 0px solid black;' +
          ' text-align: left; vertical-align: middle;' +
          ' width: 54.0%; }'}
        {/* TODO: あとで歳を戻すためコメントアウト */}
        {/* ' width: 42.0%; }'} */}

        {/* 年齢 */}
        {'.ageTd {' +
          ' border-top: 2px solid black; border-bottom: 2px solid black; border-left: 1px solid black;' +
          ' border-right: 0px solid black;' +
          ' text-align: center; vertical-align: middle;' +
          ' width: 12%; }'}

        {/* 性別 */}
        {'.genderTd {' +
          ' border-top: 2px solid black; border-bottom: 2px solid black; border-left: 1px solid black;' +
          ' border-right: 2px solid black;' +
          ' text-align: center; vertical-align: middle; }'}

        {/* 最初の行 */}
        {'.topLeftTd {' +
          ' border-top: 2px solid black; border-bottom: 1px solid black; border-left: 2px solid black;' +
          ' border-right: 1px solid black;' +
          ' text-align: left; vertical-align: middle;' +
          ' width: 26%; }'}

        {'.topMiddleTd {' +
          ' border-top: 2px solid black; border-bottom: 1px solid black; border-left: 1px solid black;' +
          ' border-right: 0px solid black;' +
          ' text-align: center; vertical-align: middle;' +
          ' width: 8%; }'}

        {'.topRightTd {' +
          ' border-top: 2px solid black; border-bottom: 1px solid black; border-left: 1px solid black;' +
          ' border-right: 2px solid black;' +
          ' text-align: center; vertical-align: middle;' +
          ' width: 8%; }'}

        {/* 間系 */}
        {'.leftTd {' +
          ' border-top: 1px solid black; border-bottom: 0px solid black; border-left: 2px solid black;' +
          ' border-right: 1px solid black;' +
          ' text-align: left; vertical-align: middle; }'}

        {'.middleTd {' +
          ' border-top: 1px solid black; border-bottom: 0px solid black; border-left: 1px solid black;' +
          ' text-align: center; vertical-align: middle; }'}

        {'.rightTd {' +
          ' border-top: 1px solid black; border-bottom: 0px solid black; border-left: 1px solid black;' +
          ' border-right: 2px solid black;' +
          ' text-align: center; vertical-align: middle; }'}

        {/* 最後系 */}
        {'.bottomLeftTd {' +
          ' border-top: 1px solid black; border-bottom: 2px solid black; border-left: 2px solid black;' +
          ' border-right: 1px solid black;' +
          ' text-align: left; vertical-align: middle; }'}

        {'.bottomMiddleTd {' +
          ' border-top: 1px solid black; border-bottom: 2px solid black; border-left: 1px solid black;' +
          ' text-align: center; vertical-align: middle; }'}

        {'.bottomRightTd {' +
          ' border-top: 1px solid black; border-bottom: 2px solid black; border-left: 1px solid black;' +
          ' border-right: 2px solid black;' +
          ' text-align: center; vertical-align: middle; }'}
      </style>
    </>
  );
};

export default Safari;
