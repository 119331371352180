import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store/configureStore';

import * as HeaderActions from '../actions/Header';
import * as MoffAPIActions from '../actions/MoffAPI';
import * as Header from '../constants/Header';
import HomeVisit from '../components/Organisms/HomeVisit';

type AllState = ReturnType<typeof store.getState>;

const HomeVisitContainer: FC = () => {
  const dispatch = useDispatch();
  const moffAPI = useSelector((state: AllState) => state.moffAPI);
  const appUser = useSelector((state: AllState) => state.appUser);

  useEffect(() => {
    (async () => {
      document.title = '居宅訪問';
      dispatch(HeaderActions.setPage(Header.HOME_VISIT));
      await dispatch(MoffAPIActions.getUsers());
      dispatch(MoffAPIActions.duplicateRepeatSort());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <HomeVisit moffAPI={moffAPI} appUser={appUser} />;
};

export default HomeVisitContainer;
