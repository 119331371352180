// グラフ.
import React, { FC, useMemo, memo } from 'react';
import moment from 'moment';

import { alignTrainingDict } from '../../utils/locomoAdlModule';
import { mapReturnObject, isNil, omitBy } from '../../utils/commonUtil';
import { formatDate, diffDate } from '../../utils/dateUtil';

import {
  SCREEN_TYPE,
  DATE_DISPLAY_TYPE,
  TOGGLE_POSITION,
  TRAINING_REPORT_NAME,
  TrainingInfo,
  TRAINING_REPORT_INFO,
  MonthlyTrainingData,
} from '../../constants/Training';

import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import ToggleButtonThreePosition from '../Atoms/ToggleButtonThreePosition';
import TrainingGraph from '../Atoms/TrainingGraph';
import DateSelector from './DateSelector';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useTrainingUI } from '../../hooks/use-training-ui';

const useStyles = makeStyles({
  graphWrapper: {
    '@media print': {
      margin: 0,
    },
  },
  toggleButton: {
    '@media print': {
      width: '50%',
    },
  },
  dateSelector: {
    '@media print': {
      width: '400px',
    },
  },
  graphAreaDetail: {
    display: 'flex',
    marginBottom: '20px',
    flexWrap: 'wrap',
    '@media (max-width: 800px)': {
      flexWrap: 'wrap',
    },
    '@media (min-width: 970px)': {
      maxWidth: '100%',
    },
    '@media (max-width: 970px)': {
      maxWidth: '100%',
    },
    '@media print': {
      maxWidth: '100%',
      minWidth: '425px',
      flexWrap: 'nowrap',
    },
  },
  graphAreaCompareAndLongTerm: {
    display: 'flex',
    marginBottom: '20px',
    flexWrap: 'wrap',
    '@media (max-width: 800px)': {
      flexWrap: 'wrap',
    },
    '@media (min-width: 970px)': {
      maxWidth: '467px',
    },
    '@media (max-width: 970px)': {
      maxWidth: '100%',
    },
    '@media print': {
      maxWidth: '46%',
      minWidth: '425px',
      flexWrap: 'nowrap',
    },
  },
  compareGraphArea: {
    flexGrow: 1,
    flexShrink: 0,
    minHeight: '400px',
    '@media (min-width: 970px)': {
      maxWidth: '467px',
    },
    '@media (max-width: 970px)': {
      maxWidth: '100%',
    },
    '@media print': {
      maxWidth: '46%',
      minWidth: '425px',
      margin: 0,
    },
  },
  trainingNameHead: {
    marginTop: 0,
    fontSize: '25px',
    '@media print': {
      marginTop: 0,
      marginBottom: '-2cm',
    },
  },
  trainingName: {
    marginTop: '80px',
    fontSize: '25px',
    '@media print': {
      marginTop: '5cm',
      marginBottom: '-2cm',
    },
  },
  eachGraph: {
    '@media print': {
      'page-break-after': 'always',
    },
  },
});
interface TrainingGraphsProps {
  className: TRAINING_REPORT_NAME;
  historyArray: MonthlyTrainingData[];
  date: moment.Moment;
  screenType: SCREEN_TYPE;
  graphWidth: number;
  graphHeight: number;
  togglePosition?: TOGGLE_POSITION;
  minusMonthly?: (className: string) => void;
  plusMonthly?: (className: string) => void;
  changeState?: (state: SCREEN_TYPE) => void;
  changeTogglePosition?: (state: TOGGLE_POSITION) => void;
}

/**
 * Chart.jsの折れ線グラフ（詳細画面, 長期）.
 */
const TrainingGraphs: FC<TrainingGraphsProps> = ({
  className,
  historyArray,
  date,
  screenType,
  graphWidth,
  graphHeight,
  togglePosition,
  minusMonthly = () => null,
  plusMonthly = () => null,
  changeState = () => null,
  changeTogglePosition = () => null,
}) => {
  const classes = useStyles();
  const { displayedTrainings, allTrainings } = useTrainingUI();
  const mergedHistory = useMemo(() => mapReturnObject(historyArray, (history) => history), [historyArray]);
  const alignedTrainingCountDict = useMemo(() => alignTrainingDict(mergedHistory, 'count'), [mergedHistory]);
  const alignedTrainingAngleDict = useMemo(() => alignTrainingDict(mergedHistory, 'average_angle'), [mergedHistory]);

  return (
    <Box
      mt={{ xs: className === TRAINING_REPORT_NAME.CompareRight ? '100px' : '0', md: '0' }}
      className={
        className === TRAINING_REPORT_NAME.CompareLeft || className === TRAINING_REPORT_NAME.CompareRight
          ? classes.compareGraphArea
          : classes.graphWrapper
      }
      data-test={`graph-${className}`}
    >
      {screenType === SCREEN_TYPE.LongTerm ? null : (
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent={{
            xs: 'center',
            sm:
              className === TRAINING_REPORT_NAME.CompareLeft || className === TRAINING_REPORT_NAME.CompareRight
                ? 'center'
                : 'space-between',
          }}
          className={
            className === TRAINING_REPORT_NAME.Detail ? classes.graphAreaDetail : classes.graphAreaCompareAndLongTerm
          }
        >
          <Box display="flex" justifyContent="center" flexWrap="wrap" width="100%" className={classes.toggleButton}>
            <Box mb="20px">
              <ToggleButtonThreePosition
                togglePosition={togglePosition}
                changeTogglePosition={changeTogglePosition}
                leftText="両側"
                rightText="右"
                centerText="左"
                customCss="width: 80px; height: 50px;"
              />
            </Box>
          </Box>
          {screenType === SCREEN_TYPE.CompareLeft ? (
            <Box mb={{ xs: '30px', sm: '0' }} width={{ xs: '240px', sm: '65px' }}>
              <GeneralPrimaryButton
                txt="終了"
                setCss={'width: 100%; height: 50px; white-space:nowrap;'}
                onClick={() => changeState(SCREEN_TYPE.Detail)}
              />
            </Box>
          ) : null}
          {screenType === SCREEN_TYPE.Detail ? (
            <Box mb={{ xs: '30px', sm: '0' }} width={{ xs: '240px', sm: '158px' }}>
              <GeneralPrimaryButton
                txt="比較する"
                setCss={`width: 100%; height: 50px;`}
                onClick={() => changeState(SCREEN_TYPE.Compare)}
              />
            </Box>
          ) : null}
          <Box width={{ xs: '315px', sm: '400px' }} className={classes.dateSelector}>
            <DateSelector
              date={date}
              className={className}
              dateDisplayType={DATE_DISPLAY_TYPE.Month}
              minusDate={minusMonthly}
              plusDate={plusMonthly}
            />
          </Box>
        </Box>
      )}
      {allTrainings?.map((trainingInfo: TrainingInfo, index: number) => {
        const isDisplay = displayedTrainings.indexOf(trainingInfo.name) >= 0;
        // 回数の記録が存在する日をグラフに表示する。
        const trainingDays = Object.keys(omitBy(alignedTrainingCountDict[trainingInfo.id], isNil))
          .map((date) => date)
          .sort((a, b) => diffDate(a, b));
        // 長期変化はMM-DD形式、詳細・比較はDD日形式
        const trainingDaysToDisplay =
          screenType === SCREEN_TYPE.LongTerm
            ? trainingDays.map((date) => formatDate(date, 'MM-DD'))
            : trainingDays.map((date) => formatDate(date, 'D日'));
        return (
          <Box display={!isDisplay && 'none'} component="section" key={`section${index}`} className={classes.eachGraph}>
            <Box mb={{ xs: '20px', sm: '0' }}>
              <h3
                className={
                  displayedTrainings?.[0] === trainingInfo.name ? classes.trainingNameHead : classes.trainingName
                }
              >
                {trainingInfo.name}
              </h3>
            </Box>
            <TrainingGraph
              width={graphWidth}
              height={graphHeight}
              yAxis1Max={30}
              yAxis2Max={TRAINING_REPORT_INFO[trainingInfo.id].maxAxis}
              criteriaValue={TRAINING_REPORT_INFO[trainingInfo.id].criteria}
              trainingDays={trainingDaysToDisplay}
              counts={trainingDays.map((date) => alignedTrainingCountDict[trainingInfo.id][date])}
              angles={trainingDays.map((date) => alignedTrainingAngleDict[trainingInfo.id][date])}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(TrainingGraphs);
