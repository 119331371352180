import React, { FC } from 'react';
import ForgotPasswordForm from '../../containers/ForgotPasswordForm';
import PageLoader from '../Atoms/PageLoader';

import { Box } from '@material-ui/core';
interface ForgotPasswordProps {
  password: any;
}

const DoneForgotPassword: FC = () => {
  return (
    <Box>
      <h2>パスワード再設定手続きを開始しました。</h2>
      <p>&nbsp;</p>
      <p>ご契約時のメールアドレスにメールを送信しました。</p>
      <p>メールを確認して手続きを行って下さい。</p>
      <p>手続きは、１時間以内に行って下さい。1時間を超えると期限切れになるので、手続きをやり直して下さい。</p>
    </Box>
  );
};

const ForgotPassword: FC<ForgotPasswordProps> = ({ password }) => {
  return (
    <Box>
      {(() => {
        if (password.Loaded) {
          return;
        }

        // ローディング中
        return <PageLoader />;
      })()}
      {password.Loaded ? (
        password.result ? (
          <DoneForgotPassword key="message" />
        ) : (
          <ForgotPasswordForm key="form" />
        )
      ) : null}
    </Box>
  );
};

export default ForgotPassword;
