/* モフトレ通信・利用者選択 */
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Popup from '../../containers/Popup';
import UserListHeader from '../../containers/UserListHeader';
import KanaList from '../../containers/KanaList';
import UserSelectBody from '../../containers/UserSelectBody';
import { MENU_TYPE } from '../../constants/Menu';
import PageLoader from '../Atoms/PageLoader';

const useStyles = makeStyles(() => ({
  table: {
    display: 'table',
    width: '100%',
    margin: '0 0 80px 0',
    overflow: 'hidden',
    borderSpacing: 0,
    borderCollapse: 'separate',
    borderRadius: '10px',
    boxShadow: '0 2px 3px 1px #ccc',
  },
}));

interface UserSelectPressProps {
  moffAPI: any;
}

const UserSelectPress: FC<UserSelectPressProps> = ({ moffAPI }) => {
  const classes = useStyles();

  return (
    <Box>
      <Popup />
      <KanaList />
      {moffAPI.Loaded ? null : <PageLoader />}

      <Box>
        <table className={classes.table}>
          <thead>
            <tr>
              <UserListHeader UserListFieldKey="LocomoModel" />
            </tr>
          </thead>
          <tbody>{moffAPI.Loaded ? <UserSelectBody type={MENU_TYPE.MoffPress} users={moffAPI.result} /> : null}</tbody>
        </table>
      </Box>
    </Box>
  );
};

export default UserSelectPress;
