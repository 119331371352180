// テーブルのprops
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import * as TrainingConst from '../../constants/Training';

const useStyles = makeStyles((theme) => ({
  tblStyle02: {
    display: 'table',
    width: '100%',
    margin: '0 0 50px 0',
    fontWeight: 'normal',
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    fontSize: '1em',
    '& th': {
      width: '170px',
      padding: '8px',
      fontWeight: 'normal',
      textAlign: 'left',
    },
    '& td': {
      padding: '8px 0',
      textAlign: 'left',
    },
    '& thead th:first-of-type': {
      width: '170px',
    },
    '& tr:nth-of-type(2n + 1) th': {
      borderRadius: '4px 0 0 4px',
    },
    '& thead': {
      color: '#969696',
    },
    '& tr:nth-of-type(2n + 1)': {
      backgroundColor: '#e8e4df',
    },
    '& thead tr:nth-of-type(2n + 1)': {
      backgroundColor: '#fff',
    },
    '& tr:nth-of-type(2n + 1) td:last-child': {
      borderRadius: '0 4px 4px 0',
    },
    '@media print': {
      'page-break-after': 'always',
      margin: (props: { margin: string }) => props.margin,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9em',
      '& th': {
        width: '110px',
      },
    },
  },
  tightCss: {
    '& col + col': {
      width: '20%',
    },
    maxWidth: '97%',
    margin: '0 auto 50px',
  },
}));

interface TableProps {
  /** テーブルのインデックス */
  myNo: number;
  /** テーブルのkeyのprefix */
  prefix: string;
  /** 日数 */
  historyMonthlyDateTable: number[];
  /** 月間テーブル履歴 */
  historyMonthlyTable: number[][];
  /** テーブル*列「4*9」「8*4」のどちらか */
  tablePattern: TrainingConst.TRAINING_TABLE_PATTERN;
  /** ロコモまたはADLの全てのトレーニングの配列 */
  allTrainings: TrainingConst.TrainingInfo[];
}

// 各テーブル生成用.
const Table: FC<TableProps> = ({
  myNo,
  prefix,
  historyMonthlyDateTable,
  historyMonthlyTable,
  tablePattern,
  allTrainings,
}) => {
  const classes = useStyles({
    margin: `${myNo === 0 ? 0 : '3cm'} 0 0 0`,
  });
  return (
    <table
      className={`${classes.tblStyle02} ${
        tablePattern === TrainingConst.TRAINING_TABLE_PATTERN.tight ? classes.tightCss : undefined
      }`}
    >
      <colgroup>
        <col />
        <col span={4} />
      </colgroup>
      <tbody>
        {historyMonthlyTable?.map((history: number[], index: number) => {
          return (
            <tr key={index}>
              <th key={`${prefix}TableHeader${index}`} scope="row">
                {allTrainings[index].name}
              </th>
              {history.map((data: number, i: number) => {
                const key = `${prefix}TableBody${i}`;
                return (
                  <td key={key}>
                    <p>{data > 0 ? `${data}回` : '- '}</p>
                  </td>
                );
              })}
            </tr>
          );
        })}
        <tr>
          <th>&nbsp;</th>
          {(historyMonthlyDateTable as (number | string)[])
            ?.concat(
              Array.from(Array(TrainingConst.ADL_MONTHLY_TABLE_COLUMN - historyMonthlyDateTable.length), () => ''),
            )
            .map((date: number | string, i) => (
              <td key={`${prefix}TableDateList${i}`}>{date && `${date}日`}</td>
            ))}
        </tr>
      </tbody>
    </table>
  );
};

export default Table;
