import * as images from '../img/index';
import { LOGIN_URL } from '../utils/commonUtil';

import Menu from '../containers/Menu';

// パスワード
import ChangePassword from '../containers/ChangePassword';

// モフトレチェック系.
import UserSelectMoffCheck from '../containers/UserSelectMoffCheck';
import MoffCheckAllUsersPrint from '../containers/MoffCheckAllUsersPrint';
import MoffCheckSelectMonth from '../containers/MoffCheckSelectMonth';
import MoffCheck from '../containers/MoffCheck';

// 機能訓練レポート.
import UserSelectLiterallyReport from '../containers/UserSelectLiterallyReport';
import LiterallyReportSelectMonth from '../containers/LiterallyReportSelectMonth';
import LiterallyReportAllUsersPrint from '../containers/LiterallyReportAllUsersPrint';

// 各Menuページ
import UserSelectMoffTraining from '../containers/UserSelectMoffTraining';
import MoffTraining from '../containers/MoffTraining';

// 記録系.
import UserSelectKiroku from '../containers/UserSelectKiroku';
import KirokuAllUsersPrint from '../containers/KirokuAllUsersPrint';
import KirokuAllUsersSave from '../containers/KirokuAllUsersSave';
import Kiroku from '../containers/Kiroku';

// モフトレ通信系.
import UserSelectPress from '../containers/UserSelectPress';
import PressAllUsersPrint from '../containers/PressAllUsersPrint';
import PressSelectMonth from '../containers/PressSelectMonth';
import Press from '../containers/Press';

// 利用者設定系.
import UserSetting from '../containers/UserSetting';
import UserSettingRegister from '../containers/UserSettingRegister';
import UserSettingEdit from '../containers/UserSettingEdit';

// 興味関心チェック系.
import UserInterestCheck from '../containers/UserInterestCheck';
import InterestCheckPrint from '../containers/InterestCheckPrint';

// 居宅訪問.
import HomeVisit from '../containers/HomeVisit';
import HomeVisitPrint from '../containers/HomeVisitPrint';

// 生活機能チェック.
import UserSelectLifeCheck from '../containers/UserSelectLifeCheck';
import LifeCheckPrint from '../containers/LifeCheckPrint';

// Life CSV出力
import LifeCSVOutput from '../containers/LifeCSVOutput';

// モフらく
import MoffRakuUsers from '../containers/MoffRakuUsers';
import UserSelectMoffRaku from '../containers/UserSelectMoffRaku';
import MoffRakuList from '../containers/MoffRakuList';
import MoffRakuForm from '../containers/MoffRakuForm';

import Login from '../containers/Login';
import Maintainance from '../components/Organisms/Maintainance';
import Setting from '../containers/Setting';
import ForgotPassword from '../containers/ForgotPassword';
import ForgotPasswordCode from '../containers/ForgotPasswordCode';

/** ルートパス & ページリスト */
export const ROUTE_PAGES = {
  /** メンテナンス */
  Maintainance: {
    path: '/maintainance.html',
    component: Maintainance,
  },
  /** モフトレ設定画面 */
  Setting: {
    path: '/settings.html',
    component: Setting,
  },
  /** パスワード変更 */
  ChangePassword: {
    path: '/change_password.html',
    component: ChangePassword,
  },
  /** モフトレパスワード忘れた画面 */
  ForgotPassword: {
    path: '/forgot_password.html',
    component: ForgotPassword,
  },
  /** モフトレパスワードリセット再登録画面 */
  ForgotPasswordCode: {
    path: '/forgot_password_code.html',
    component: ForgotPasswordCode,
  },
  /** ログイン画面 */
  Login: {
    path: '/',
    component: Login,
  },
  /** メニュー */
  Menu: {
    path: LOGIN_URL,
    component: Menu,
  },
  /** 利用者設定/一覧 */
  UserSetting: {
    path: '/user_setting',
    component: UserSetting,
  },
  /** 利用者設定/登録 */
  UserSettingRegister: {
    path: '/user_setting_register',
    component: UserSettingRegister,
  },
  /** 利用者設定/編集 */
  UserSettingEdit: {
    path: '/user_setting_edit/:userId',
    component: UserSettingEdit,
  },
  /** LIFE CSV出力 */
  LifeCSVOutput: {
    path: '/life_csv_output',
    component: LifeCSVOutput,
  },
  /** モフトレ通信/一覧 */
  UserSelectPress: {
    path: '/user_select_press',
    component: UserSelectPress,
  },
  /** モフトレ通信/全ユーザー印刷画面 */
  PressAllUsersPrint: {
    path: '/all_users_print_press',
    component: PressAllUsersPrint,
  },
  /** モフトレ通信/月選択画面 */
  PressSelectMonth: {
    path: '/press_select_month/:userId',
    component: PressSelectMonth,
  },
  /** モフトレ通信/詳細 */
  Press: {
    path: '/press/:userId/:yearMonth',
    component: Press,
  },
  /** モフトレチェック/一覧 */
  UserSelectMoffCheck: {
    path: '/user_select_moff_check',
    component: UserSelectMoffCheck,
  },
  /** モフトレチェック/全ユーザー印刷画面 */
  MoffCheckAllUsersPrint: {
    path: '/all_users_print_moff_check',
    component: MoffCheckAllUsersPrint,
  },
  /** モフトレチェック/月選択画面 */
  MoffCheckSelectMonth: {
    path: '/moff_check_select_month/:userId',
    component: MoffCheckSelectMonth,
  },
  /** モフトレチェック/詳細 */
  MoffCheck: {
    path: '/moff_check/:userId/:yearMonth/:compareSelectMonth/:items',
    component: MoffCheck,
  },
  /** トレーニング実施記録（表・グラフ）/利用者選択 */
  UserSelectMoffTraining: {
    path: '/user_select_moff_training',
    component: UserSelectMoffTraining,
  },
  /** トレーニング実施記録（表・グラフ）/詳細 */
  MoffTraining: {
    path: '/moff_training/:userId',
    component: MoffTraining,
  },
  /** 機能訓練レポート/一覧 */
  UserSelectLiterallyReport: {
    path: '/user_select_literally_report',
    component: UserSelectLiterallyReport,
  },
  /** 機能訓練レポート/詳細 */
  LiterallyReportSelectMonth: {
    path: '/literally_report_select_month/:userId',
    component: LiterallyReportSelectMonth,
  },
  /** 機能訓練レポート/全ユーザー印刷画面 */
  LiterallyReportAllUsersPrint: {
    path: '/all_users_print_literally_report',
    component: LiterallyReportAllUsersPrint,
  },
  /** 個別機能訓練に関する記録/一覧 */
  UserSelectKiroku: {
    path: '/user_select_kiroku',
    component: UserSelectKiroku,
  },
  /** 個別機能訓練に関する記録/全ユーザー印刷画面 */
  KirokuAllUsersPrint: {
    path: '/all_users_print_kiroku',
    component: KirokuAllUsersPrint,
  },
  /** 個別機能訓練に関する記録/全ユーザー入力画面 */
  KirokuAllUsersSave: {
    path: '/all_users_save_kiroku',
    component: KirokuAllUsersSave,
  },
  /** 個別機能訓練に関する記録/詳細 */
  Kiroku: {
    path: '/kiroku/:userId',
    component: Kiroku,
  },
  /** 生活機能チェック/一覧 */
  UserSelectLifeCheck: {
    path: '/user_select_life_check',
    component: UserSelectLifeCheck,
  },
  /** 生活機能チェック/詳細 */
  LifeCheckPrint: {
    path: '/life_check_print/:total/:uniqueId/:userId',
    component: LifeCheckPrint,
  },
  /** 興味関心チェック/一覧 */
  UserInterestCheck: {
    path: '/user_interest_check',
    component: UserInterestCheck,
  },
  /** 興味関心チェック/詳細 */
  InterestCheckPrint: {
    path: '/interest_check_print/:total/:uniqueId/:userId',
    component: InterestCheckPrint,
  },
  /** 居宅訪問/一覧 */
  HomeVisit: {
    path: '/home_visit',
    component: HomeVisit,
  },
  /** 居宅訪問/詳細 */
  HomeVisitPrint: {
    path: '/home_visit_print/:total/:uniqueId/:userId',
    component: HomeVisitPrint,
  },
  /** モフらく/基本情報入力 */
  MoffRakuUsers: {
    path: '/moff_raku_users',
    component: MoffRakuUsers,
  },
  /** モフらく/計画書-利用者の選択画面 */
  UserSelectMoffRaku: {
    path: '/user_select_moff_raku',
    component: UserSelectMoffRaku,
  },
  /** モフらく/計画書-利用者の一覧画面 */
  MoffRakuPlanList: {
    path: '/moff_raku/:userId',
    component: MoffRakuList,
  },
  /** モフらく/計画書-入力画面 */
  MoffRakuForm: {
    path: '/moff_raku_form/:userId',
    component: MoffRakuForm,
  },
  /** モフらく/計画書-編集画面 */
  MoffRakuEdit: {
    path: '/moff_raku_edit/:userId/:planId',
    component: MoffRakuForm,
  },
};

export type ROUTE_PAGES = typeof ROUTE_PAGES[keyof typeof ROUTE_PAGES];

export const APP_TYPE = {
  None: {
    key: '',
    name: '',
    color: '',
    image: '',
  },
  MoffTraining: {
    key: 'MoffTraining',
    name: 'モフトレ',
    color: '#ED9B34',
    image: images.icon_moff_training,
  },
  MoffCheck: {
    key: 'MoffCheck',
    name: 'モフトレチェック',
    color: '#ED9B34',
    image: images.icon_moff_check,
  },
  BarthelIndex: {
    key: 'BarthelIndex',
    name: 'BI',
    color: '#ED9B34',
    image: '',
  },
  Hdsr: {
    key: 'Hdsr',
    name: 'HDS-R',
    color: '#ED9B34',
    image: '',
  },
  LifeCheck: {
    key: 'LifeCheck',
    name: '生活機能',
    color: '#ED9B34',
    image: images.icon_life_check,
  },
  InterestCheck: {
    key: 'InterestCheck',
    name: '興味関心',
    color: '#ED9B34',
    image: images.icon_interest_check,
  },
  HomeVisit: {
    key: 'HomeVisit',
    name: '居宅訪問',
    color: '#ED9B34',
    image: images.icon_home_visit,
  },
  MoffRaku: {
    key: 'MoffRaku',
    name: 'モフらく',
    color: '#ED9B34',
    image: images.icon_moff_raku,
  },
};

export type APP_TYPE = typeof APP_TYPE[keyof typeof APP_TYPE];

export const MENU_TYPE = {
  None: {
    name: 'none',
    urlBase: 'none',
    buttonText: '',
    buttonHref: '',
    chip: [APP_TYPE.None],
  },
  // トレーニング実施記録（表・グラフ）
  MoffTraining: {
    name: 'moff_training',
    urlBase: 'moff_training',
    buttonText: `トレーニング\n実施記録（表・グラフ）`,
    buttonHref: 'user_select_moff_training',
    chip: [APP_TYPE.MoffTraining],
  },
  // 個別機能訓練に関する記録.
  Kiroku: {
    name: 'kiroku',
    urlBase: 'kiroku',
    buttonText: `個別機能訓練に\n関する記録`,
    buttonHref: 'user_select_kiroku',
    chip: [APP_TYPE.MoffTraining],
  },
  // モフトレ通信.
  MoffPress: {
    name: 'press',
    urlBase: 'press_select_month',
    buttonText: 'モフトレ通信',
    buttonHref: 'user_select_press',
    chip: [APP_TYPE.MoffTraining],
  },
  // 利用者設定.
  UserSetting: {
    name: 'UserSetting',
    urlBase: 'user_setting_edit',
    buttonText: '利用者設定',
    buttonHref: 'user_setting',
    chip: [APP_TYPE.None],
  },
  // モフトレチェック.
  MoffCheck: {
    name: 'MoffCheck',
    urlBase: 'moff_check_select_month',
    buttonText: `モフトレチェック\nレポート`,
    buttonHref: 'user_select_moff_check',
    chip: [APP_TYPE.MoffCheck],
  },
  // 居宅訪問.
  HomeVisit: {
    name: 'HomeVisit',
    urlBase: 'home_visit_print',
    buttonText: '居宅訪問チェック',
    buttonHref: 'home_visit',
    chip: [APP_TYPE.HomeVisit],
  },
  // 興味関心チェック.
  InterestCheck: {
    name: 'InterestCheck',
    urlBase: 'interest_check_print',
    buttonText: '興味関心チェック',
    buttonHref: 'user_interest_check',
    chip: [APP_TYPE.InterestCheck],
  },
  // 機能訓練レポート.
  LiterallyReport: {
    name: 'LiterallyReport',
    urlBase: 'literally_report_select_month',
    buttonText: '機能訓練レポート',
    buttonHref: 'user_select_literally_report',
    chip: [APP_TYPE.MoffTraining, APP_TYPE.BarthelIndex, APP_TYPE.Hdsr],
  },
  // 生活機能チェック.
  LifeCheck: {
    name: 'LifeCheck',
    urlBase: 'life_check_print',
    buttonText: '生活機能チェック',
    buttonHref: 'user_select_life_check',
    chip: [APP_TYPE.LifeCheck],
  },
  // モフらく
  // MoffRaku: {
  //   name: 'MoffRaku',
  //   urlBase: 'moff_raku',
  //   buttonText: 'モフらく',
  //   buttonHref: 'moff_raku',
  //   chip: [APP_TYPE.MoffRaku],
  // },
  // モフらく利用者基本情報入力
  MoffRakuUsers: {
    name: 'MoffRakuUsers',
    urlBase: 'moff_raku',
    buttonText: '利用者\n基本情報入力',
    buttonHref: 'moff_raku_users',
    chip: [APP_TYPE.MoffRaku],
  },
  // // モフらく計画書利用者選択
  UserSelectMoffRaku: {
    name: 'UserSelectMoffRaku',
    urlBase: 'moff_raku',
    buttonText: '計画書作成',
    buttonHref: 'user_select_moff_raku',
    chip: [APP_TYPE.MoffRaku],
  },
};

export type MENU_TYPE = typeof MENU_TYPE[keyof typeof MENU_TYPE];

export const PLAN_TYPE = {
  Moffraku: {
    name: 'モフらくプラン',
    routePages: [
      ROUTE_PAGES.UserSetting,
      ROUTE_PAGES.UserSettingRegister,
      ROUTE_PAGES.UserSettingEdit,
      ROUTE_PAGES.LifeCSVOutput,
      ROUTE_PAGES.UserSelectPress,
      ROUTE_PAGES.PressAllUsersPrint,
      ROUTE_PAGES.PressSelectMonth,
      ROUTE_PAGES.Press,
      ROUTE_PAGES.UserSelectMoffCheck,
      ROUTE_PAGES.MoffCheckAllUsersPrint,
      ROUTE_PAGES.MoffCheckSelectMonth,
      ROUTE_PAGES.MoffCheck,
      ROUTE_PAGES.UserSelectMoffTraining,
      ROUTE_PAGES.MoffTraining,
      ROUTE_PAGES.UserSelectLiterallyReport,
      ROUTE_PAGES.LiterallyReportSelectMonth,
      ROUTE_PAGES.LiterallyReportAllUsersPrint,
      ROUTE_PAGES.UserSelectKiroku,
      ROUTE_PAGES.KirokuAllUsersPrint,
      ROUTE_PAGES.KirokuAllUsersSave,
      ROUTE_PAGES.Kiroku,
      ROUTE_PAGES.UserSelectLifeCheck,
      ROUTE_PAGES.LifeCheckPrint,
      ROUTE_PAGES.UserInterestCheck,
      ROUTE_PAGES.InterestCheckPrint,
      ROUTE_PAGES.HomeVisit,
      ROUTE_PAGES.HomeVisitPrint,
      ROUTE_PAGES.MoffRakuUsers,
      ROUTE_PAGES.UserSelectMoffRaku,
      ROUTE_PAGES.MoffRakuPlanList,
      ROUTE_PAGES.MoffRakuForm,
      ROUTE_PAGES.MoffRakuEdit,
    ],
    menus: [
      MENU_TYPE.MoffPress,
      MENU_TYPE.MoffCheck,
      MENU_TYPE.MoffTraining,
      MENU_TYPE.LiterallyReport,
      MENU_TYPE.Kiroku,
      MENU_TYPE.LifeCheck,
      MENU_TYPE.InterestCheck,
      MENU_TYPE.HomeVisit,
      MENU_TYPE.MoffRakuUsers,
      MENU_TYPE.UserSelectMoffRaku,
    ],
  },
  Regular: {
    name: '通常プラン',
    routePages: [
      ROUTE_PAGES.UserSetting,
      ROUTE_PAGES.UserSettingRegister,
      ROUTE_PAGES.UserSettingEdit,
      ROUTE_PAGES.LifeCSVOutput,
      ROUTE_PAGES.UserSelectPress,
      ROUTE_PAGES.PressAllUsersPrint,
      ROUTE_PAGES.PressSelectMonth,
      ROUTE_PAGES.Press,
      ROUTE_PAGES.UserSelectMoffCheck,
      ROUTE_PAGES.MoffCheckAllUsersPrint,
      ROUTE_PAGES.MoffCheckSelectMonth,
      ROUTE_PAGES.MoffCheck,
      ROUTE_PAGES.UserSelectMoffTraining,
      ROUTE_PAGES.MoffTraining,
      ROUTE_PAGES.UserSelectLiterallyReport,
      ROUTE_PAGES.LiterallyReportSelectMonth,
      ROUTE_PAGES.LiterallyReportAllUsersPrint,
      ROUTE_PAGES.UserSelectKiroku,
      ROUTE_PAGES.KirokuAllUsersPrint,
      ROUTE_PAGES.KirokuAllUsersSave,
      ROUTE_PAGES.Kiroku,
      ROUTE_PAGES.UserSelectLifeCheck,
      ROUTE_PAGES.LifeCheckPrint,
      ROUTE_PAGES.UserInterestCheck,
      ROUTE_PAGES.InterestCheckPrint,
      ROUTE_PAGES.HomeVisit,
      ROUTE_PAGES.HomeVisitPrint,
      ROUTE_PAGES.MoffRakuUsers,
      ROUTE_PAGES.UserSelectMoffRaku,
      ROUTE_PAGES.MoffRakuPlanList,
      ROUTE_PAGES.MoffRakuForm,
      ROUTE_PAGES.MoffRakuEdit,
    ],
    menus: [
      MENU_TYPE.MoffPress,
      MENU_TYPE.MoffCheck,
      MENU_TYPE.MoffTraining,
      MENU_TYPE.LiterallyReport,
      MENU_TYPE.Kiroku,
      MENU_TYPE.LifeCheck,
      MENU_TYPE.InterestCheck,
      MENU_TYPE.HomeVisit,
      MENU_TYPE.MoffRakuUsers,
      MENU_TYPE.UserSelectMoffRaku,
    ],
  },
  Light: {
    name: 'ライトプラン',
    routePages: [
      ROUTE_PAGES.UserSetting,
      ROUTE_PAGES.UserSettingRegister,
      ROUTE_PAGES.UserSettingEdit,
      ROUTE_PAGES.LifeCSVOutput,
      ROUTE_PAGES.UserSelectMoffCheck,
      ROUTE_PAGES.MoffCheckAllUsersPrint,
      ROUTE_PAGES.MoffCheckSelectMonth,
      ROUTE_PAGES.MoffCheck,
      ROUTE_PAGES.UserSelectLifeCheck,
      ROUTE_PAGES.LifeCheckPrint,
      ROUTE_PAGES.UserInterestCheck,
      ROUTE_PAGES.InterestCheckPrint,
      ROUTE_PAGES.HomeVisit,
      ROUTE_PAGES.HomeVisitPrint,
    ],
    menus: [MENU_TYPE.MoffCheck, MENU_TYPE.LifeCheck, MENU_TYPE.InterestCheck, MENU_TYPE.HomeVisit],
  },
  MoffCheck: {
    name: 'モフトレチェックプラン',
    routePages: [
      ROUTE_PAGES.UserSetting,
      ROUTE_PAGES.UserSettingRegister,
      ROUTE_PAGES.UserSettingEdit,
      ROUTE_PAGES.UserSelectMoffCheck,
      ROUTE_PAGES.MoffCheckAllUsersPrint,
      ROUTE_PAGES.MoffCheckSelectMonth,
      ROUTE_PAGES.MoffCheck,
    ],
    menus: [MENU_TYPE.MoffCheck],
  },
} as const;

export type PLAN_TYPE = typeof PLAN_TYPE[keyof typeof PLAN_TYPE];
