/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';

const linkButtonCss = (setCss: string) => {
  return css`
    box-align: center;
    color: #fff;
    background: #f37640;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid #ddd9d4;
    background: linear-gradient(0deg, rgba(234, 109, 54, 1) 0%, rgba(239, 148, 107, 1) 100%);
    ${setCss}

    &:hover {
      opacity: 0.9;
    }

    @media print {
      display: none;
    }
  `;
};

interface LinkButtonProps {
  /** ボタンのテキスト */
  txt: string | JSX.Element;
  /** 外部から渡すスタイル */
  setCss: string;
  /** 遷移先のURL */
  href: string;
}

const LinkButton: FC<LinkButtonProps> = ({ txt, setCss, href }) => {
  return (
    <a css={linkButtonCss(setCss)} href={href} data-test="link-button">
      {txt}
    </a>
  );
};

export default LinkButton;
