import { LOGIN_URL } from '../utils/commonUtil';

// ログイン.
export const LOGIN = 'LOGIN';

// メニュー
export const MENU = 'LOCOMO_DETAIL';

// 管理メニュー
export const SETTING = 'SETTING';
export const SETTING_CHANGE_PASSWORD = 'SETTING_CHANGE_PASSWORD';
export const SETTING_FORGOT_PASSWORD = 'SETTING_FORGOT_PASSWORD';
export const SETTING_FORGOT_PASSWORD_CODE = 'SETTING_FORGOT_PASSWORD_CODE';

// モフトレ
export const USER_SELECT_MOFF_TRAINING = 'USER_SELECT_MOFF_TRAINING';
export const MOFF_TRAINING = 'MOFF_TRAINING';

// 個別機能訓練に関する記録
export const USER_SELECT_KIROKU = 'USER_SELECT_KIROKU';
export const KIROKU_ALL_USER_PRINT = 'KIROKU_ALL_USER_PRINT';
export const KIROKU_ALL_USER_SAVE = 'KIROKU_ALL_USER_SAVE';
export const KIROKU = 'KIROKU';

// モフトレ通信
export const USER_SELECT_PRESS = 'USER_SELECT_PRESS';
export const PRESS_ALL_USER_PRINT = 'PRESS_ALL_USER_PRINT';
export const PRESS_SELECT_MONTH = 'PRESS_SELECT_MONTH';
export const PRESS = 'PRESS';

// 利用者設定.
export const USER_SETTING = 'USER_SETTING';
export const REGISTER_USER_SETTING = 'REGISTER_USER_SETTING';
export const EDIT_USER_SETTING = 'EDIT_USER_SETTING';

// モフトレチェック.
export const USER_SELECT_MOFF_CHECK = 'USER_SELECT_MOFF_CHECK';
export const MOFF_CHECK_ALL_USER_PRINT = 'MOFF_CHECK_ALL_USER_PRINT';
export const MOFF_CHECK_SELECT_MONTH = 'MOFF_CHECK_SELECT_MONTH';

// 居宅訪問.
export const HOME_VISIT = 'HOME_VISIT';
export const HOME_VISIT_PRINT = 'HOME_VISIT_PRINT';

// 興味関心チェック.
export const USER_INTEREST_CHECK = 'USER_INTEREST_CHECK';
export const INTEREST_CHECK_PRINT = 'INTEREST_CHECK_PRINT';

// 機能訓練レポート.
export const USER_SELECT_LITERALLY_REPORT = 'USER_SELECT_LITERALLY_REPORT';
export const LITERALLY_REPORT_SELECT_MONTH = 'LITERALLY_REPORT_SELECT_MONTH';
export const LITERALLY_REPORT_ALL_USER_PRINT = 'LITERALLY_REPORT_ALL_USER_PRINT';

// 生活チェック.
export const USER_SELECT_LIFE_CHECK = 'USER_SELECT_LIFE_CHECK';
export const LIFE_CHECK_PRINT = 'LIFE_CHECK_PRINT';

// LIFE CSV 出力
export const LIFE_CSV_OUTPUT = 'LIFE_CSV_OUTPUT';

// モフらく計画書
export const MOFF_RAKU_USERS = 'MOFF_RAKU_USERS';
export const USER_SELECT_MOFF_RAKU = 'USER_SELECT_MOFF_RAKU';
export const MOFF_RAKU_LIST = 'MOFF_RAKU_LIST';
export const MOFF_RAKU_FORM = 'MOFF_RAKU_FORM';

export const HEADER_BACK_PAGE_TYPE = {
  none: {
    text: '',
    url: '#',
  },
  backMenu: {
    text: 'メニューに戻る',
    url: LOGIN_URL,
  },
  backUserList: {
    text: '利用者一覧に戻る',
    url: '#',
  },
  setting: {
    text: '管理に戻る',
    url: '#',
  },
};

export type HEADER_BACK_PAGE_TYPE = typeof HEADER_BACK_PAGE_TYPE[keyof typeof HEADER_BACK_PAGE_TYPE];

export const HEADER_ALL_USERS_BUTTON_TYPE = {
  literally: {
    path: '/user_select_literally_report',
    text: `一括PDF出力`,
    css: 'padding: 4px 8px;',
    url: '/all_users_print_literally_report',
  },
  check: {
    path: '/user_select_moff_check',
    text: '一括PDF出力',
    css: 'padding: 4px 8px;',
    url: '/all_users_print_moff_check',
  },
  press: {
    path: '/user_select_press',
    text: `一括コメント編集\n&PDF出力`,
    css: 'padding: 2px 8px; white-space: pre; font-weight: normal; font-size: 13.5px; line-height: 1.25;',
    url: '/all_users_print_press',
  },
  kiroku: {
    path: '/user_select_kiroku',
    text: `一括PDF出力`,
    css: 'padding: 4px 8px;',
    url: '/all_users_print_kiroku',
  },
  kirokuSave: {
    path: '/user_select_kiroku',
    text: `一括入力`,
    css: 'padding: 4px 8px;',
    url: '/all_users_save_kiroku',
  },
};

export type HEADER_ALL_USERS_BUTTON_TYPE = typeof HEADER_ALL_USERS_BUTTON_TYPE[keyof typeof HEADER_ALL_USERS_BUTTON_TYPE];
