// TODO: エラーalert
import * as Dialog from '../../constants/Dialog';

const initialState = {
  displayError: false,
  alertTitle: '',
  alertMessage: '',
  onClickEvent: null,
};

export default function alert(state: any = initialState, action: any) {
  switch (action.type) {
    case Dialog.DISPLAY_ALERT:
      return Object.assign({}, state, {
        displayError: true,
        alertTitle: action.title,
        alertMessage: action.message,
        onClickEvent: null,
      });
    case Dialog.CLOSE_ALERT:
      return Object.assign({}, state, {
        displayError: false,
        alertTitle: '',
        alertMessage: '',
        onClickEvent: null,
      });
    default:
      return state;
  }
}
