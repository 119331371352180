import React, { FC } from 'react';
import ArrowRoundButton from '../Atoms/ArrowRoundButton';
import { DATE_DISPLAY_TYPE } from '../../constants/Training';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
  dateArea: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    lineHeight: '1.3em',
  },
});
interface DateSelectorProps {
  date: moment.Moment;
  className: string;
  dateDisplayType: DATE_DISPLAY_TYPE;
  minusDate: (className: string) => void;
  plusDate: (className: string) => void;
}

// レポート(endReport, startReport共通).
export const DateSelector: FC<DateSelectorProps> = ({ date, className, dateDisplayType, minusDate, plusDate }) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.dateArea}>
        <ArrowRoundButton arrowDirection="left" onClick={() => minusDate(className)} />
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          width={dateDisplayType === DATE_DISPLAY_TYPE.ThePastYear ? '350px' : '250px'}
          height="50px"
          fontSize={{ xs: '27px', sm: '30px' }}
        >
          <Box
            display="flex"
            width={{ xs: '100%', sm: '100%', md: 'auto' }}
            justifyContent="center"
            flexWrap="wrap"
            alignItems="center"
          >
            <Box data-test={dateDisplayType === DATE_DISPLAY_TYPE.Day ? 'daily-date-text' : 'monthly-date-text'}>
              {date.format('YYYY')}
              <Box component="span" display="inline-block" m="0 0.4em 0 0.3em" height="16px" fontSize="16px">
                年
              </Box>
              {date.format('MM')}
              <Box component="span" display="inline-block" m="0 0.2em 0 0.3em" height="16px" fontSize="16px">
                月
              </Box>
              {dateDisplayType === DATE_DISPLAY_TYPE.Day ? (
                <>
                  {date.format('DD')}
                  <Box component="span" display="inline-block" m="0 0 0 0" height="16px" fontSize="16px">
                    日
                  </Box>
                </>
              ) : null}
            </Box>
          </Box>
          {dateDisplayType === DATE_DISPLAY_TYPE.ThePastYear ? (
            <Box
              display="flex"
              alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'center' }}
              mt={{ xs: '15px', sm: '15px', md: '0' }}
            >
              <Box component="span" display="inline-block" fontSize="16px" height="16px">
                までの1年分を表示
              </Box>
            </Box>
          ) : null}
        </Box>
        <ArrowRoundButton arrowDirection="right" onClick={() => plusDate(className)} />
      </Box>
    </>
  );
};

export default DateSelector;
