// ユーザー一覧の表示(Show).
import React, { FC, MutableRefObject } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { RouteProps } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { User } from '../../constants/UserListField';
import { KirokuRequestParamsFormat, SuggestWord } from '../../constants/Kiroku';

import { getCurrentTime } from '../../utils/dateUtil';

import UserList, { RefFunction } from '../Molecules/UserListShow';
import KirokuAllUsersForm from './KirokuAllUsersForm';

const useStyles = makeStyles(() => ({
  report: {
    background: '#fff',
    borderRadius: '10px',
    boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
    /* 印刷時に見えるシャドウを消す */
    '@media print': {
      boxShadow: 'none',
    },
  },
  cardHeader: {
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#7c644e',
    borderRadius: '10px 10px 0 0',
    '&:after': {
      display: 'block',
      clear: 'both',
      content: '',
    },
    '@media print': {
      color: '#000',
    },
    '& p': {
      float: 'left',
      '&:first-child': {
        marginRight: '100px',
      },
    },
  },
  ttlStyle01: {
    padding: '0 45px 0 5px',
    margin: '0 25px 20px 25px',
    fontSize: '25px',
    borderBottom: 'solid 1px #dcdcdc',
  },
}));

export interface KirokuAllUsersSaveProps {
  location: RouteProps['location'];
  isLoaded: boolean;
  cachedUsers: User[];
  refUsersList: MutableRefObject<RefFunction | null>;
  submitKiroku: (data: KirokuRequestParamsFormat[], suggestWordsGroupSubject: SuggestWord[], reset: () => void) => void;
}

const KirokuAllUsersSave: FC<KirokuAllUsersSaveProps> = ({
  location,
  isLoaded,
  cachedUsers,
  refUsersList,
  submitKiroku,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box component="article" pb="75px" className={classes.report}>
        <Box className={classes.cardHeader} p="25px 30px 65px 20px" displayPrint="none">
          <p>一括入力</p>
        </Box>

        <Box component="section" mt="50px">
          <h2 className={classes.ttlStyle01}>個別機能訓練の一括入力</h2>
          <KirokuAllUsersForm
            submitKiroku={submitKiroku}
            defaultValues={[
              {
                datetime: getCurrentTime().datetimeT,
                duration: '00:00:00',
                subject: '',
                instructor: '',
                remarks: '',
              },
            ]}
          />
          {isLoaded && !_.isEmpty(cachedUsers) ? (
            <Box m="80px 25px 0">
              <UserList
                users={cachedUsers}
                invokedPath={location?.pathname}
                currentMonth={moment().format('YYYY-MM')}
                ref={(instance) => {
                  refUsersList.current = instance;
                }}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default KirokuAllUsersSave;
