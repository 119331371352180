import React, { FC, useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';

import store from '../store/configureStore';

import * as moffCheckActions from '../actions/MoffCheck';
import * as pdfCreateActions from '../actions/pdfCreate';
import * as reportActions from '../actions/Report';

import UserDetail from './UserDetail';
import TrainingHeader from './TrainingHeader';
import PopupContainer from './Popup';

import ReportOutputMonthSelect from '../components/Organisms/ReportOutputMonthSelect';
import MoffCheckComment from '../components/Organisms/MoffCheckComment';
import DialogAlert from '../components/Molecules/DialogAlert';
import PageLoader from '../components/Atoms/PageLoader';
import GeneralPrimaryButton from '../components/Atoms/GeneralPrimaryButton';

import { getFirstEndDay } from '../utils/dateUtil';
import * as MoffCheckModule from '../utils/MoffCheckModule';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

import * as Header from '../constants/Header';
import { SCREEN_TYPE } from '../constants/Training';
import { MOFF_CHECK_COMPARE_LOGIC, MOFF_CHECK_V1_SELECT_LIST } from '../constants/MoffCheck';
import { MOFF_REPORT_MASTER, ReportActionPayload } from '../constants/Report';
import { MoffCheckProvider } from '../hooks/use-moff-check';

type AllState = ReturnType<typeof store.getState>;

const MoffCheckSelectMonthContainer: FC<RouteComponentProps<{ userId: string }>> = ({ match }) => {
  const dispatch = useDispatch();
  const moffCheckGlobalState = useSelector((state: AllState) => state.moffCheck);
  const pdfCreateGlobalState = useSelector((state: AllState) => state.pdfCreate);
  const reportGlobalState = useSelector((state: AllState) => state.report);

  const userId = match.params.userId;
  const articleClassName = 'report';
  const [isToggleOn, setIsToggleOn] = useState<boolean>(false);

  // 矢印ボタンによる月選択で選択されている月（日付）
  const [date, setDate] = useState(moment());

  // 月選択画面 もしくは コメント入力画面
  const [screenType, setScreenType] = useState<SCREEN_TYPE>(SCREEN_TYPE.MoffCheckSelectMonth);

  // 比較方法
  const [compareMonth, setCompareMonth] = useState<MOFF_CHECK_COMPARE_LOGIC>(MOFF_CHECK_COMPARE_LOGIC.default);
  const compareMonthRef = useRef<MOFF_CHECK_COMPARE_LOGIC>();
  const prevCompareMonth = compareMonthRef.current;

  // レポート出力する項目を格納
  const [multiSelectItems, setMultiSelectItems] = useState<string[]>(['CS-30']);

  // レポート出力時どの月を出力するかを保持
  const [singleSelectMonth, setSingleSelectMonth] = useState<string>(moment().format('YYYY-MM'));
  const [multiSelectMonths, setMultiSelectMonths] = useState<string[]>([]);

  // 月ボタンの表示月の配列を格納
  const [dispYearMonth, setDispYearMonth] = useState<string[]>([]);

  // 「比較月自由選択」で表示する最も過去の測定結果
  const [oldestDate, setOldestDate] = useState('');
  // 「トレーニング履歴をさらに読込」を押した時に取得する、昨年の月
  const [nextFetchMonth, setNextFetchMonth] = useState('');
  const [cachedTrainingData, setCachedTrainingData] = useState({});

  // コメント
  const [comment, setComment] = useState('');

  // コメントを入力するかどうかを選択するためのモーダルの表示・非表示
  const [dialogOpen, setDialogOpen] = useState(false);

  // 月ボタンを表示するために、指定月から遡って1年間の測定結果を取得
  const fetchAllMeasurementData = async (selectedDate: moment.Moment) => {
    let isGetOldestDate;
    // 最初の日と最後の日を返す.（1年分）
    const [startDate, endDate] = getFirstEndDay(selectedDate.format('YYYY'), selectedDate.format('MM'));
    // 比較方法が「比較月自由選択」のときトレーニング開始月を取得する
    if (compareMonth === MOFF_CHECK_COMPARE_LOGIC.thirdOption) {
      isGetOldestDate = true;
      setNextFetchMonth(moment(startDate).subtract(1, 'months').format('YYYY-MM'));
    } else {
      isGetOldestDate = false;
    }
    // API呼び出し.
    await dispatch(moffCheckActions.getMoffCheckAllData(userId, startDate, endDate, isGetOldestDate));
  };

  // 初期読み込み時に、今月基点の測定データを取得
  useEffect(() => {
    (async () => {
      await fetchAllMeasurementData(date);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // モフトレチェック測定データを取得した後の処理
  useEffect(() => {
    if (moffCheckGlobalState.result) {
      if (compareMonth === MOFF_CHECK_COMPARE_LOGIC.thirdOption) {
        // 「トレーニング履歴をさらに読込」ボタンを押した場合の処理
        if (!_.isEmpty(cachedTrainingData) && moffCheckGlobalState.Loaded) {
          _.map(
            ['tug', 'balance', 'cs30', 'ss5', 'rom', 'grip_right', 'grip_left', 'height', 'weight'],
            (trainingName) => {
              moffCheckGlobalState.result[trainingName].count += cachedTrainingData[trainingName].count;
              moffCheckGlobalState.result[trainingName].Items = [
                ...moffCheckGlobalState.result[trainingName].Items,
                ...cachedTrainingData[trainingName].Items,
              ];
            },
          );
        }
        // トレーニング開始月のデータをローカルステートに保持
        if (moffCheckGlobalState.result.oldestdate && !oldestDate) {
          const oldestDate = MoffCheckModule.convertResponseToOldestMonth(moffCheckGlobalState.result.oldestdate);
          setOldestDate(oldestDate);
        }
      }

      // yyyy-mm配列(TUG).
      const tugArr = MoffCheckModule.convertResponseToMonthArray(moffCheckGlobalState.result.tug.Items);
      // yyyy-mm配列(開眼片足立ち).
      const balanceArr = MoffCheckModule.convertResponseToMonthArray(moffCheckGlobalState.result.balance.Items);
      // yyyy-mm配列.（CS30）
      const cs30Arr = MoffCheckModule.convertResponseToMonthArray(moffCheckGlobalState.result.cs30.Items);
      // yyyy-mm配列.（SS5）
      const ss5Arr = MoffCheckModule.convertResponseToMonthArray(moffCheckGlobalState.result.ss5.Items);
      // yyyy-mm配列.（ROM）
      const romArr = MoffCheckModule.convertResponseToMonthArray(moffCheckGlobalState.result.rom.Items);
      // yyyy-mm配列.（ROM）
      const gripArr = MoffCheckModule.convertResponseToMonthArray([
        ...moffCheckGlobalState.result.grip_right.Items,
        ...moffCheckGlobalState.result.grip_left.Items,
        ...moffCheckGlobalState.result.height.Items,
        ...moffCheckGlobalState.result.weight.Items,
      ]);

      // 複数の配列の和集合（重複を除いた全ての値）を取得する.
      // TUGと開眼片足立ちの片方でもあれば出す.
      setDispYearMonth(
        [..._.union(tugArr, balanceArr, cs30Arr, ss5Arr, romArr, gripArr)].sort((a, b) => moment(b).diff(a)),
      );
      setCachedTrainingData(moffCheckGlobalState.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffCheckGlobalState.result]);

  // モフトレチェック測定データを取得した後の処理
  useEffect(() => {
    if (reportGlobalState.result && (reportGlobalState.result as ReportActionPayload).comment?.comment) {
      setComment((reportGlobalState.result as ReportActionPayload).comment.comment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportGlobalState.result]);

  // 比較方法を変更したあとの処理
  useEffect(() => {
    compareMonthRef.current = compareMonth;
    if (
      compareMonth === MOFF_CHECK_COMPARE_LOGIC.thirdOption ||
      prevCompareMonth === MOFF_CHECK_COMPARE_LOGIC.thirdOption
    ) {
      setMultiSelectMonths([]);
      setCachedTrainingData({});
      setOldestDate('');
      setNextFetchMonth('');
      (async () => {
        await fetchAllMeasurementData(moment());
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareMonth]);

  // 比較方法を変更する
  const changeSelectBox = async (event: any) => {
    setCompareMonth(event.target.value);
  };

  // 月選択画面 矢印のアクション
  const minusMonthly = async () => {
    const lastMonth = date.subtract(1, 'months');
    setDate(lastMonth);
    await fetchAllMeasurementData(lastMonth);
    dispatchIdentifierToGTM('detail_page_change_month_moffcheck');
  };

  // 月選択画面 矢印のアクション
  const plusMonthly = async () => {
    const nextMonth = date.add(1, 'months');
    setDate(nextMonth);
    await fetchAllMeasurementData(nextMonth);
    dispatchIdentifierToGTM('detail_page_change_month_moffcheck');
  };

  // 選択項目を変更する
  const addOutputItems = (event: React.ChangeEvent<{ value: unknown }>) => {
    const items = event.target.value as string[];
    if (items.length <= 2) {
      setMultiSelectItems(items);
    }
  };

  // 歩行評価レポートURLへ遷移
  // TODO: PDFに切り替え予定
  const moveToReportURL = (compareMonth: MOFF_CHECK_COMPARE_LOGIC, items: string, key = '') => {
    if (compareMonth === MOFF_CHECK_COMPARE_LOGIC.thirdOption) {
      window.open(
        `/moff_check/${userId}/${multiSelectMonths
          .sort((a, b) => moment(b).diff(a))
          .join('_')}/${compareMonth}/${items}`,
        '_blank',
        'noreferrer noopener',
      );
    } else {
      window.open(`/moff_check/${userId}/${key}/${compareMonth}/${items}`, '_blank', 'noreferrer noopener');
    }
    dispatchIdentifierToGTM(`output_user_report_moffcheck_walk_${compareMonth}`);
  };

  // 「比較月自由選択」のとき選択月をローカルステートに保持する
  const pickReportOutputMonth = (month: string) => {
    if (_.some(multiSelectMonths, (selectMonth: string) => selectMonth === month)) {
      setMultiSelectMonths(_.filter(multiSelectMonths, (selectMonth: string) => selectMonth !== month));
    } else {
      if (multiSelectMonths.length < 3) {
        setMultiSelectMonths([...multiSelectMonths, month]);
      }
    }
  };

  // 月ボタンをクリックした時の処理
  const monthButtonOnClick = (month: string) => {
    if (compareMonth === MOFF_CHECK_COMPARE_LOGIC.thirdOption) {
      pickReportOutputMonth(month);
    } else {
      if (isToggleOn) {
        setSingleSelectMonth(month);
        setDialogOpen(true);
      } else {
        const select_items = Object.keys(MOFF_CHECK_V1_SELECT_LIST).filter((key) =>
          multiSelectItems.includes(MOFF_CHECK_V1_SELECT_LIST[key]),
        );
        const items = select_items.length ? select_items.join('-') : 'null';
        moveToReportURL(compareMonth, items, month);
      }
    }
  };

  // 「比較月自由月選択」で、レポート出力ボタンを押した時の処理
  const reportOutputButtonOnClick = () => {
    if (isToggleOn) {
      setDialogOpen(true);
    } else {
      const select_items = Object.keys(MOFF_CHECK_V1_SELECT_LIST).filter((key) =>
        multiSelectItems.includes(MOFF_CHECK_V1_SELECT_LIST[key]),
      );
      const items = select_items.length ? select_items.join('-') : 'null';
      moveToReportURL(MOFF_CHECK_COMPARE_LOGIC.thirdOption, items);
    }
  };

  // 「比較月自由月選択」で、「トレーニング履歴をさらに読込」をクリックした時の処理
  const fetchThePastYearData = async () => {
    const [startDate, endDate] = getFirstEndDay(nextFetchMonth.split('-')[0], nextFetchMonth.split('-')[1], 12);
    setNextFetchMonth(moment(startDate).subtract(1, 'months').format('YYYY-MM'));
    await dispatch(moffCheckActions.initialize());
    await dispatch(moffCheckActions.getMoffCheckAllData(userId, startDate, endDate, true));
    dispatchIdentifierToGTM('detail_page_history_moffcheck');
  };

  // 月選択画面 への切り替え
  const switchSelectMonthScreen = async (screenType: SCREEN_TYPE) => {
    setScreenType(screenType);
    setComment('');
  };

  // コメント画面 への切り替え
  const switchCommentScreen = async () => {
    setScreenType(SCREEN_TYPE.MoffCheckComment);
    setDialogOpen(false);
    switch (compareMonth) {
      case MOFF_CHECK_COMPARE_LOGIC.default:
        dispatch(reportActions.getMoffCheckAllMeasurementThreeMonth(userId, singleSelectMonth));
        break;
      case MOFF_CHECK_COMPARE_LOGIC.secondOption:
        dispatch(reportActions.getMoffCheckAllMeasurementOldest(userId, singleSelectMonth));
        break;
      case MOFF_CHECK_COMPARE_LOGIC.thirdOption:
        dispatch(reportActions.getMoffCheckAllMeasurementFreeChoice(userId, multiSelectMonths));
        break;
      default:
        alert('比較方法が選択されていません。');
        setScreenType(SCREEN_TYPE.MoffCheckSelectMonth);
    }
  };

  // 全体レポート出力ボタンを押した時の処理
  const allMeasurementReportOutput = async (commentText = '') => {
    setDialogOpen(false);
    const selectMonths = MoffCheckModule.generateMoffCheckMonthsPayload(
      compareMonth,
      singleSelectMonth,
      multiSelectMonths,
    );
    const comments = [
      {
        user_id: userId,
        text: commentText,
      },
    ];
    dispatchIdentifierToGTM(`output_user_report_moffcheck_all_${compareMonth}`);
    await dispatch(pdfCreateActions.createMoffCheckV2PDF(compareMonth, selectMonths, [userId], comments, false));
  };

  // コメント保存ボタンを押した時の処理
  const commentSave = async (commentText: string) => {
    const selectMonths = MoffCheckModule.generateMoffCheckMonthsPayload(
      compareMonth,
      singleSelectMonth,
      multiSelectMonths,
    );
    if (window.confirm('メモを保存してよろしいですか？')) {
      await dispatch(
        reportActions.saveComment(
          userId,
          MOFF_REPORT_MASTER.MoffCheckAllMeasurementReport,
          selectMonths[0],
          commentText,
        ),
      );
    }
    dispatchIdentifierToGTM('detail_page_save_memo_moffcheck');
  };

  return (
    <MoffCheckProvider isToggleOn={isToggleOn} setIsToggleOn={setIsToggleOn}>
      {!moffCheckGlobalState.Loaded || pdfCreateGlobalState.Loading || reportGlobalState.Loading ? (
        <PageLoader />
      ) : null}
      <PopupContainer />
      <article className={articleClassName}>
        <UserDetail userId={userId} headerType={Header.MOFF_CHECK_SELECT_MONTH} pageTitle="モフトレチェック" />
        <TrainingHeader
          screenType={screenType}
          changeState={switchSelectMonthScreen}
          changeSelectBox={changeSelectBox}
          compareMonth={compareMonth}
          selectItems={multiSelectItems}
          addOutputItems={addOutputItems}
        />
        {screenType === SCREEN_TYPE.MoffCheckSelectMonth ? (
          <Fragment>
            <DialogAlert
              dialogOpen={dialogOpen}
              setDialogOpen={setDialogOpen}
              dialogTitle="コメントを入力しますか？"
              leftButton={
                <GeneralPrimaryButton
                  onClick={() => allMeasurementReportOutput()}
                  txt="コメント入力なしで出力"
                  setCss="width: 200px; font-size: 0.9em !important; padding: 5px 15px; margin: 5px;"
                  isDisable={true}
                />
              }
              rightButton={
                <GeneralPrimaryButton
                  onClick={switchCommentScreen}
                  txt="コメント入力画面へ"
                  setCss="width: 200px; font-size: 0.9em !important; padding: 5px 15px; margin: 5px;"
                />
              }
            />
            <ReportOutputMonthSelect
              dispYearMonth={dispYearMonth}
              date={date}
              selectAreaHeading="モフトレチェック"
              selectMonths={multiSelectMonths}
              minusMonthly={minusMonthly}
              plusMonthly={plusMonthly}
              monthButtonOnClick={monthButtonOnClick}
              compareMonth={compareMonth}
              oldestDate={oldestDate}
              nextFetchMonth={nextFetchMonth}
              fetchThePastYearData={fetchThePastYearData}
              reportOutputButtonOnClick={reportOutputButtonOnClick}
            />
          </Fragment>
        ) : (
          <MoffCheckComment
            comment={comment}
            allMeasurementReportOutput={allMeasurementReportOutput}
            allMeasurementResult={reportGlobalState.result?.moffCheckV2}
            commentSave={commentSave}
          />
        )}
      </article>
    </MoffCheckProvider>
  );
};

export default MoffCheckSelectMonthContainer;
