import React, { FC } from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import GeneralPrimarySquareButton from '../Atoms/GeneralPrimarySquareButton';
import { useTrainingUI } from '../../hooks/use-training-ui';

const useStyles = makeStyles(() => ({
  ttlStyle01: {
    padding: '0 45px 0 5px',
    margin: '0 0 20px 0',
    fontSize: '25px',
    borderBottom: 'solid 1px #dcdcdc',
  },
  sectionCustomize: {
    padding: '30px 25px 0',
    margin: '30px 0 0 0',
    '@media print': {
      display: 'none',
    },
  },
  annotation: {
    color: '#ED9B34',
    fontSize: '16px',
  },
}));

const selectButtonStyle = (isSelected = false, isOdd = false) => `
  color: ${isSelected ? '#fff !important' : '#000'};
  background: linear-gradient(0deg, rgba(234, 109, 54, 1) 0%, rgba(239, 148, 107, 1) 100%);
  background: ${isSelected ? undefined : isOdd ? '#e8e4df' : '#fff'};
  font-size: 1em;
  border-radius: 10px;
  padding: 0 6px;
`;

const TrainingCustomize: FC = () => {
  const classes = useStyles();
  const { allTrainings, displayedTrainings, addDisplayTraining, removeDisplayTraining } = useTrainingUI();
  return (
    <Box className={classes.sectionCustomize}>
      <h2 className={classes.ttlStyle01}>
        {/* eslint-disable-next-line no-irregular-whitespace */}
        レポートカスタマイズ　<span className={classes.annotation}>※ トレーニングは9つまで選択可能です</span>
      </h2>
      <Box m="30px auto" display="flex" justifyContent="center" flexWrap="wrap">
        {allTrainings.map((trainingInfo, index) => {
          const isSelected = displayedTrainings.includes(trainingInfo.name);
          return (
            <Box
              key={index}
              width={{ xs: '240px', sm: '170px' }}
              height="44px"
              margin="0 8px 20px"
              data-test={isSelected ? 'is-selected' : 'not-selected'}
            >
              <GeneralPrimarySquareButton
                text={trainingInfo?.name}
                setCss={selectButtonStyle(isSelected, index % 2 === 0)}
                onClick={() => (isSelected ? removeDisplayTraining(trainingInfo) : addDisplayTraining(trainingInfo))}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default TrainingCustomize;
