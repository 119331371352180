// ユーザー一覧の表示(Show).
import React, { FC } from 'react';
import _ from 'lodash';

import * as UserListField from '../../constants/UserListField';

import { osJudgement } from '../../utils/commonUtil';

import { DATE_DISPLAY_TYPE } from '../../constants/Training';

import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import DateSelector from '../Molecules/DateSelector';
import UserList from '../Molecules/UserListShow';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  mainBody: {
    background: '#fff',
    borderRadius: '10px',
    '-moz-box-shadow': '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
    '-webkit-box-shadow': '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
    boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
    '@media print': {
      boxShadow: 'none',
    },
  },
  headerTitle: {
    padding: '25px 30px 25px 20px',
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#7c644e',
    borderRadius: '10px 10px 0 0',
    '@media print': {
      display: 'none',
    },
  },
  ttlStyle01: {
    padding: '0 45px 0 5px',
    margin: '0 0 20px 0',
    fontSize: '25px',
    borderBottom: 'solid 1px #dcdcdc',
  },
  dateSelecterArea: {
    width: '100%',
    maxWidth: '350px',
    margin: '0 auto 25px',
    '@media print': {
      margin: '0 auto 25px',
      width: '100%',
    },
  },
}));
export interface LiterallyReportAllUsersPrintProps {
  location: any;
  refUsersList: any;
  minusMonthly: () => void;
  plusMonthly: () => void;
  allUsersPrintButtonClickHandler: () => void;
  date: moment.Moment;
  loaded: boolean;
  cachedUsers: UserListField.User[];
  refLiterallyReport: any;
}

const LiterallyReportAllUsersPrint: FC<LiterallyReportAllUsersPrintProps> = ({
  location,
  refUsersList,
  minusMonthly,
  plusMonthly,
  allUsersPrintButtonClickHandler,
  date,
  loaded,
  cachedUsers,
}) => {
  const classes = useStyles();
  const isApp = false;

  return (
    <>
      <Box component="article" position="relative" pb="75px" className={classes.mainBody}>
        <Box className={classes.headerTitle}>
          <p>一括出力</p>
        </Box>
        <Box component="section" m="30px 0 0 0" p="30px 25px 0">
          <Box mb={3} display="flex" justifyContent={{ xs: 'center', sm: 'flex-end' }}>
            {isApp ? null : osJudgement() === 'PC' ||
              osJudgement() === 'ipad' ||
              osJudgement() === 'iphone' ||
              osJudgement() === 'android' ||
              osJudgement() === 'androidTablet' ? (
              <GeneralPrimaryButton
                txt="一括PDF出力"
                setCss={'width: 250px; height: 40px;'}
                onClick={allUsersPrintButtonClickHandler}
              />
            ) : (
              <GeneralPrimaryButton
                txt="一括PDF出力"
                setCss={'width: 250px; height: 40px;'}
                onClick={() => null}
                isDisable={true}
              />
            )}
          </Box>
          <h2 className={classes.ttlStyle01}>機能訓練レポートの一括PDF出力</h2>
          <Box className={classes.dateSelecterArea}>
            <DateSelector
              date={date}
              className={''}
              dateDisplayType={DATE_DISPLAY_TYPE.Month}
              minusDate={minusMonthly}
              plusDate={plusMonthly}
            />
          </Box>
          {loaded && !_.isEmpty(cachedUsers) ? (
            <UserList
              users={cachedUsers}
              invokedPath={location.pathname}
              currentMonth={date.format('YYYY-MM')}
              isMemoColumn={false}
              isReportCheckColumn={false}
              ref={(instance) => {
                refUsersList.current = instance;
              }}
            />
          ) : (
            // ダミー.
            <Box>
              <p>読み込み中</p>
            </Box>
          )}
        </Box>
        <Box component="section" m="30px 0 0 0" p="30px 25px 0">
          <Box mb={3} display="flex" justifyContent={{ xs: 'center', sm: 'flex-end' }}>
            {isApp ? null : osJudgement() === 'PC' ||
              osJudgement() === 'ipad' ||
              osJudgement() === 'iphone' ||
              osJudgement() === 'android' ||
              osJudgement() === 'androidTablet' ? (
              <GeneralPrimaryButton
                txt="一括PDF出力"
                setCss={'width: 250px; height: 40px;'}
                onClick={allUsersPrintButtonClickHandler}
              />
            ) : (
              <GeneralPrimaryButton
                txt="一括PDF出力"
                setCss={'width: 250px; height: 40px;'}
                onClick={() => null}
                isDisable={true}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default LiterallyReportAllUsersPrint;
