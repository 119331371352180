// モフトレチェック.
import { createMoffCheckAPIInstance } from '../api/MoffCheck';
import * as MoffCheckConst from '../constants/MoffCheck';

const [isMoffCheckAPIError, moffCheckAPIInstance, moffCheckAPIErrorAction] = createMoffCheckAPIInstance();

/**
 * モフトレチェックの全ての計測詳細データを取得するアクション
 *
 * @param {string} userId
 * @param {string} startDate
 * @param {string} endDate
 * @returns
 */
export const getMoffCheckAllData = (
  userId: string,
  startDate: string,
  endDate: string,
  isGetOldestDate: boolean,
): any => {
  return isMoffCheckAPIError
    ? moffCheckAPIErrorAction
    : moffCheckAPIInstance.getMoffCheckAllDataAPI(userId, startDate, endDate, isGetOldestDate);
};

export const getFreeChoiceMonthMoffCheckAllData = (userId: string, startAndEndDateList: string[][]): any => {
  return isMoffCheckAPIError
    ? moffCheckAPIErrorAction
    : moffCheckAPIInstance.getFreeChoiceMonthMoffCheckAllDataAPI(userId, startAndEndDateList);
};

/**
 * モフトレチェックの詳細データを取得するアクション
 *
 * @param {string} userId
 * @param {string} startDate
 * @param {string} endDate
 * @returns
 */
export const getMoffCheckData = (userId: string, startDate: string, endDate: string, isGetOldestDate: boolean): any => {
  return isMoffCheckAPIError
    ? moffCheckAPIErrorAction
    : moffCheckAPIInstance.getMoffCheckDataAPI(userId, startDate, endDate, isGetOldestDate);
};

/**
 * モフトレチェックのトレーニング開始月と指定月数分のデータを取得するアクション
 *
 * @param {string} userId
 * @param {string} startDate
 * @param {string} endDate
 * @returns
 */
export const getMoffCheckOldestMonthData = (userId: string, startDate: string, endDate: string): any => {
  return isMoffCheckAPIError
    ? moffCheckAPIErrorAction
    : moffCheckAPIInstance.getMoffCheckOldestMonthDataAPI(userId, startDate, endDate);
};

/**
 * モフトレチェックの自由選択を行なったデータを取得するアクション
 *
 * @param {string} userId
 * @param {string[][]} startAndEndDateList
 * @returns
 */
export const getFreeChoiceMonthData = (userId: string, startAndEndDateList: string[][]): any => {
  return isMoffCheckAPIError
    ? moffCheckAPIErrorAction
    : moffCheckAPIInstance.getFreeChoiceMonthData(userId, startAndEndDateList);
};

/**
 * モフトレチェックの最新データの日付を取得するアクション
 * @param {string} userId
 * @param {Function} addDisplayDate
 * @returns
 */
export const getMoffCheckLastDate = (userId: string, addDisplayDate: Function, intervalTimeMs = 500) => {
  return isMoffCheckAPIError
    ? moffCheckAPIErrorAction
    : moffCheckAPIInstance.getMoffCheckLastDateAPI(userId, addDisplayDate, intervalTimeMs);
};

/**
 * モフトレチェックグローバルステートの初期化
 *
 * @returns
 */
export const initialize = () => {
  return {
    type: MoffCheckConst.INITIALIZE,
    result: null,
  };
};
