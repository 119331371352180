import React, { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import Header from '../containers/Header';
import Footer from '../containers/Footer';
import Auth from '../containers/Auth';
import Mode from '../containers/Mode';
import Routes from './Routes';
import { PLAN_TYPE, ROUTE_PAGES } from 'constants/Menu';

declare global {
  interface Window {
    // window.dataLayerを動作させるためのインターフェイス.
    dataLayer: any;
    // タグマネージャのカスタムJavaScriptの変数格納用.
    category: string;
  }
}

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundSize: '100%',
  },
}));

interface AppProps {
  loaded: boolean;
  planType: keyof typeof PLAN_TYPE;
}

const App: FC<AppProps> = ({ loaded, planType }) => {
  const classes = useStyles();
  return (
    <BrowserRouter>
      <Box height="100%">
        <Header />
        <Box id="wrapper" minHeight="100%" mt="50px" className={classes.wrapper}>
          <Box className="contentIn" width="1004px" maxWidth="100%" p="20px 10px 24px" m="0 auto">
            {/* モフトレ設定画面 */}
            <Switch>
              <Route path={ROUTE_PAGES.Maintainance.path} component={ROUTE_PAGES.Maintainance.component} />
              <Mode>
                <Switch>
                  <Route path={ROUTE_PAGES.Setting.path} component={ROUTE_PAGES.Setting.component} />
                  {/* モフトレパスワード変更画面 */}
                  <Route path={ROUTE_PAGES.ForgotPassword.path} component={ROUTE_PAGES.ForgotPassword.component} />
                  {/* モフトレパスワードリセット再登録画面 */}
                  <Route
                    path={ROUTE_PAGES.ForgotPasswordCode.path}
                    component={ROUTE_PAGES.ForgotPasswordCode.component}
                  />
                  <Route exact={true} path={ROUTE_PAGES.Login.path} component={ROUTE_PAGES.Login.component} />
                  {loaded ? (
                    <Auth>
                      <Route path="/" component={() => <Routes planType={planType} />} />
                    </Auth>
                  ) : null}
                </Switch>
              </Mode>
            </Switch>
            <Footer />
          </Box>
        </Box>
      </Box>
    </BrowserRouter>
  );
};

export default App;
