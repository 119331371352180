// アプリユーザAPI.
// キーを変えるため通常のMoffAPIとはActionは分割.
import { createInterestCheckAPIInstance } from '../api/InterestCheckAPI';
import { ResponseData } from '../constants/AppUser';

const [
  isInterestCheckAPIError,
  interestCheckAPIInstance,
  interestCheckAPIErrorAction,
] = createInterestCheckAPIInstance();

/**
 * 興味関心チェックの最終登録日を取得.
 * @param {string} userId
 * @param {Function} addDisplayDate
 * @returns
 */
export const getInterestCheckLastDate = (userId: string, addDisplayDate: Function, intervalTimeMs = 500) => {
  return isInterestCheckAPIError
    ? interestCheckAPIErrorAction
    : interestCheckAPIInstance.getInterestCheckLastDateAPI(userId, addDisplayDate, intervalTimeMs);
};

/**
 * 興味関心チェックの詳細を取得【何件目か指定】
 *
 * @param {string} userId
 * @param {HTMLElement[]} elements
 * @returns
 */
export const getInterestCheckRecordBySequence = (userId: string, uniqueId: string, sequence: number) => {
  return isInterestCheckAPIError
    ? interestCheckAPIErrorAction
    : interestCheckAPIInstance.getRecordBySequenceAPI(userId, uniqueId, sequence);
};

/**
 * 興味関心チェックの詳細を取得【月指定】
 *
 * @param {string} userId
 * @returns
 */
export const getInterestCheckRecordByMonth = (uniqueIds: ResponseData[], month: string) => {
  return isInterestCheckAPIError
    ? interestCheckAPIErrorAction
    : interestCheckAPIInstance.getRecordsByMonthAPI(uniqueIds, month);
};
