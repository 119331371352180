// multiple(連想配列型).
export interface Multiple {
  level: number[];
  is_problem: boolean;
  // ADL, IADLのみ.
  env?: string;
}

// 返り値の型.
export interface ResponseData {
  // Unique ID
  unique_id: string;
  // ADL-食事
  adl_feeding: Multiple;
  // ADL-椅子とベッド間の移乗
  adl_transfers: Multiple;
  // ADL-整容
  adl_grooming: Multiple;
  // ADL-トイレ動作.
  adl_toilet_use: Multiple;
  // ADL-入浴
  adl_bathing: Multiple;
  // ADL-平地歩行
  adl_mobility: Multiple;
  // ADL-階段昇降
  adl_stairs: Multiple;
  // ADL-更衣
  adl_dressing: Multiple;
  // ADL-排便コントロール
  adl_bowels: Multiple;
  // ADL-排尿コントロール
  adl_bladder: Multiple;
  // ADL-状況・生活課題
  adl_detail: string;
  // IADL-調理
  iadl_cooking: Multiple;
  // IADL-洗濯
  iadl_washing: Multiple;
  // IADL-掃除
  iadl_cleaning: Multiple;
  // IADL-状況・生活課題
  iadl_detail: string;
  // 起居動作-寝返り
  behavior_turn_over: Multiple;
  // 起居動作-起き上がり
  behavior_raise_up: Multiple;
  // 起居動作-座位
  behavior_sitting: Multiple;
  // 起居動作-立ち上がり
  behavior_stand_up: Multiple;
  // 起居動作-立位
  behavior_upright: Multiple;
  // 起居動作-状況・生活課題
  behavior_detail: string;
  // 訪問開始時間
  visited_time_start: string;
  // 訪問終了時間
  visited_time_end: string;
  // 訪問スタッフ名
  staff: string;
  // 被聞き取り者
  interviewee: string;
  // 訪問スタッフの職種
  profession: string;
  // データ作成時間
  created_at: string;
  // データ更新時間
  updated_at: string;
  // 記録番号
  sequence: number;
}

export interface MonthlyUsersData {
  monthUsersRecords: {
    userId: string;
    data: ResponseData[];
  }[];
}

export const LIFE_CHECK_LIST_PATTERN = {
  adl: {
    key: 'adl',
    independence: '自立',
    partialAssistance: '一部介助',
    totalAssistance: '全介助',
    rowLength: 2,
  },
  transfers: {
    key: 'transfers',
    independence: '自立',
    supervised: '監視下',
    canSitNotTransfer: '座れるが移れない',
    totalAssistance: '全介助',
    rowLength: 3,
  },
  mobility: {
    key: 'mobility',
    independence: '自立',
    walker: '歩行器等',
    wheelchair: '車椅子操作が可能',
    totalAssistance: '全介助',
    rowLength: 3,
  },
  iadl: {
    key: 'iadl',
    independence: '自立',
    watchOver: '見守り',
    partialAssistance: '一部介助',
    totalAssistance: '全介助',
    rowLength: 2,
  },
  behavior: {
    key: 'behavior',
    independence: '自立',
    watchOver: '見守り',
    partialAssistance: '一部介助',
    totalAssistance: '全介助',
    rowLength: 2,
  },
} as const;

export type LIFE_CHECK_LIST_PATTERN = typeof LIFE_CHECK_LIST_PATTERN[keyof typeof LIFE_CHECK_LIST_PATTERN];

export const LIFE_CHECK_SCORE_PATTERN = {
  feeding: {
    independence: 10,
    partialAssistance: 5,
    totalAssistance: 0,
  },
  transfers: {
    independence: 15,
    supervised: 10,
    canSitNotTransfer: 5,
    totalAssistance: 0,
  },
  grooming: {
    independence: 5,
    partialAssistance: 0,
    totalAssistance: 0,
  },
  toilet_use: {
    independence: 10,
    partialAssistance: 5,
    totalAssistance: 0,
  },
  bathing: {
    independence: 5,
    partialAssistance: 0,
    totalAssistance: 0,
  },
  mobility: {
    independence: 15,
    walker: 10,
    wheelchair: 5,
    totalAssistance: 0,
  },
  stairs: {
    independence: 10,
    partialAssistance: 5,
    totalAssistance: 0,
  },
  dressing: {
    independence: 10,
    partialAssistance: 5,
    totalAssistance: 0,
  },
  bowels: {
    independence: 10,
    partialAssistance: 5,
    totalAssistance: 0,
  },
  bladder: {
    independence: 10,
    partialAssistance: 5,
    totalAssistance: 0,
  },
} as const;

export type LIFE_CHECK_SCORE_PATTERN = typeof LIFE_CHECK_SCORE_PATTERN[keyof typeof LIFE_CHECK_SCORE_PATTERN];

export const DUMMY_LIFE_CHECK_RESULT = {
  adl_bath: {
    env: '',
    is_problem: false,
    level: [],
  },
  adl_cosme: {
    env: '',
    is_problem: false,
    level: [],
  },
  adl_dress: {
    env: '',
    is_problem: false,
    level: [],
  },
  adl_feed: {
    env: '',
    is_problem: false,
    level: [],
  },
  adl_transfer: {
    env: '',
    is_problem: false,
    level: [],
  },
  adl_ur: {
    env: '',
    is_problem: false,
    level: [],
  },
  behaivior_arise: {
    is_problem: false,
    level: [],
  },
  behaivior_sitting: {
    is_problem: false,
    level: [],
  },
  behaivior_standup: {
    is_problem: false,
    level: [],
  },
  behaivior_upright: {
    is_problem: false,
    level: [],
  },
  iadl_clean: {
    is_problem: false,
    level: [],
  },
  iadl_cook: {
    is_problem: false,
    level: [],
  },
  iadl_indoor: {
    env: '',
    is_problem: false,
    level: [],
  },
  iadl_lift: {
    env: '',
    is_problem: false,
    level: [],
  },
  iadl_outdoor: {
    env: '',
    is_problem: false,
    level: [],
  },
  iadl_wash: {
    env: '',
    is_problem: false,
    level: [],
  },
  iadl_detail: '',
  behaivior_detail: '',
  adl_detail: '',
  interviewee: '',
  profession: '',
  sequence: 1,
  staff: '',
  unique_id: '',
  created_at: '',
  updated_at: '',
  visited_day: '',
};

export const LOADING = 'LIFE_CHECK_LOADING';
// エラーでもFINISH
export const FINISH = 'LIFE_CHECK_FINISH';

export const SUCCESS = 'LIFE_CHECK_SUCCESS';
export const ERROR = 'LIFE_CHECK_ERROR';
