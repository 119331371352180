import React, { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import store from '../store/configureStore';

import * as MoffAPIActions from '../actions/MoffAPI';
import * as HeaderActions from '../actions/Header';
import * as FooterActions from '../actions/Footer';
import * as ReportActions from '../actions/Report';

import * as Header from '../constants/Header';
import { PressReportResponse } from '../constants/Report';

import { Graph } from '../utils/Graph';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

// 画像
import PageLoader from '../components/Atoms/PageLoader';
import PressReport from '../components/Organisms/PressReport';

type AllState = ReturnType<typeof store.getState>;

interface ParamType {
  userId: string;
  yearMonth: string;
}

const PressContainer: FC = () => {
  const { userId, yearMonth } = useParams<ParamType>();
  const dispatch = useDispatch();
  const loading = useSelector((state: AllState) => state.moffAPI.Loading);
  const memo = useSelector((state: AllState) => state.moffAPI.result.memo);
  const reportGlobalState = useSelector((state: AllState) => state.report);

  const refChart = useRef<HTMLCanvasElement>();
  const refNode = useRef<any>();

  const [buttonClicked, setButtonClicked] = useState(false);
  const [memoText, setMemoText] = useState('');

  // 円グラフの作成.
  const insertPieChart = (refChart: HTMLCanvasElement, trainingCounts: PressReportResponse['data'][0]['trainings']) => {
    const width = 236;
    const height = 236;

    const graph = new Graph();
    graph.insertDataCanvasPressPieChart(refChart, trainingCounts, width, height, false);
  };

  // レポート読み込み時の処理。ヘッダ情報の設定。モフトレ通信のデータを取得。
  useEffect(() => {
    document.title = 'モフトレ通信';
    dispatch(HeaderActions.setPage(Header.PRESS));
    dispatch(FooterActions.display('hidden'));
    dispatch(ReportActions.getPress(userId, yearMonth));
    dispatch(MoffAPIActions.getPressMemo(userId, yearMonth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // モフトレ通信のデータ取得が完了後、メモが存在すればローカルステートに設定する
  useEffect(() => {
    setMemoText(memo ? memo.comment : '');
  }, [memo]);

  useEffect(() => {
    if (reportGlobalState.result.press && refChart.current) {
      insertPieChart(refChart.current, reportGlobalState.result.press.data[0]['trainings']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportGlobalState.result.press]);

  const putMemo = async () => {
    const memo = {
      comment: memoText,
    };
    await dispatch(MoffAPIActions.putPressMemo(userId, memo, yearMonth))
      .then(async () => {
        alert('データを保存しました');
        // // API実行.
        await dispatch(MoffAPIActions.getPressMemo(userId, yearMonth));
      })
      .catch(() => alert('データの保存に失敗しました'));
    dispatchIdentifierToGTM('detail_page_save_memo_press');
  };

  const deleteMemo = async () => {
    if (!memo) {
      alert('データが存在しません');
      return;
    }
    if (window.confirm('削除してよろしいですか？')) {
      await dispatch(MoffAPIActions.deletePressMemo(userId, yearMonth))
        .then(async () => {
          alert('データを削除しました');
          // API実行.
          await dispatch(MoffAPIActions.getPressMemo(userId, yearMonth));
        })
        .catch(() => alert('データの削除に失敗しました'));
    }
    dispatchIdentifierToGTM('detail_page_delete_memo_press');
  };

  /**
   * CSSの描画.
   * 画面を白紙にするための設定
   */
  const RenderCSS: FC = () => {
    return (
      <style type="text/css">
        {/* 回数,トレーニング項目,カレンダーの行間 */}
        {'body { line-height: 1; background: none; }'}
        {/* 余白消す. */}
        {'#wrapper { padding: 0; border: 0; margin: 0; min-height: 0; }'}
        {'#wrapper .contentIn { padding: 0; border: 0; margin: 0; }'}
        {/* 縦印刷, 日付・title名ヘッダの余白 */}
        {'@media print{@page {size: 210mm 297mm; margin-top: 40px;}}'}
        {'@media print{#wrapper { margin-top: 0; } header { display: none; }}'}
        {/* /* chart-jsハック: Chrome108系: 印刷数が膨大になるのを防ぐ */}
        {
          '@media print {.chartjs-size-monitor-expand > div {display: none !important;} .chartjs-size-monitor-shrink > div { display: none !important;}}'
        }
      </style>
    );
  };

  return (
    <>
      <RenderCSS />
      {reportGlobalState.Loading ? (
        <PageLoader />
      ) : (
        <>
          {reportGlobalState.result.press == null ? (
            <p>この月はトレーニングがありません。</p>
          ) : (
            <>
              {loading ? <PageLoader /> : null}
              <PressReport
                yearMonth={yearMonth}
                reportRes={reportGlobalState.result.press}
                refChart={refChart}
                memoText={memoText}
                buttonClicked={buttonClicked}
                refNode={refNode}
                putMemo={putMemo}
                deleteMemo={deleteMemo}
                setButtonClicked={setButtonClicked}
                setMemoText={setMemoText}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default PressContainer;
