/* 利用者設定・更新 */
import React, { FC, useState, useEffect, useRef } from 'react';
import Enumerable from 'linq';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import store from '../store/configureStore';

import * as MoffAPIActions from '../actions/MoffAPI';
import * as HeaderActions from '../actions/Header';
import * as PopupActions from '../actions/Popup';

import * as Header from '../constants/Header';
import * as UserSettingConst from '../constants/UserSetting';
import { User } from '../constants/UserListField';
import * as UserSettingModule from '../utils/UserSettingModule';

import PopupContainer from './Popup';

import PageLoader from '../components/Atoms/PageLoader';
import UserSettingForm from '../components/Organisms/UserSettingForm';
import { START_DATE, CheckerUser, DEFAULT_MONTH } from '../constants/UserSetting';

import { dispatchIdentifierToGTM } from '../utils/commonUtil';

type AllState = ReturnType<typeof store.getState>;

const UserSettingRegisterContainer: FC<RouteComponentProps<{ userId: string }>> = ({ match }) => {
  const userId = match.params.userId;
  const dispatch = useDispatch();
  const loaded = useSelector((state: AllState) => state.moffAPI.Loaded);
  const users: User[] = useSelector((state: AllState) => state.moffAPI.result);
  const isUpdated = useSelector((state: AllState) => state.moffAPI.result.is_updated);
  const isDeleted = useSelector((state: AllState) => state.moffAPI.result.is_deleted);
  const isFirstRender = useRef(true);

  const [lastDayOfMonth, setLastDayOfMonth] = useState(new Date(START_DATE, 1, 0).getDate());
  const [userName, setUserName] = useState('');
  const [readName, setReadName] = useState('');
  const [userCareId, setUserCareId] = useState('');
  const [birthYear, setBirthYear] = useState(1900);
  const [birthMonth, setBirthMonth] = useState(1);
  const [birthDay, setBirthDay] = useState(1);
  const [gender, setGender] = useState(0);
  const [careLevel, setCareLevel] = useState(0);
  const [dateList, setDateList] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [checkerUsers, setCheckerUsers] = useState<CheckerUser[]>();
  const [selectedUser, setSelectedUser] = useState(false);
  const [insurerNo, setInsurerNo] = useState('');

  useEffect(() => {
    (async () => {
      document.title = '利用者設定';
      dispatch(HeaderActions.setPage(Header.EDIT_USER_SETTING));
      await dispatch(MoffAPIActions.getUsers());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users && !selectedUser) {
      const CheckerUsers: CheckerUser[] = [];
      Enumerable.from(users)
        .select((user: User) => {
          // 今回のユーザ.
          if (userId === user.user_id) {
            setUserName(user.user_name);
            setReadName(user.user_name_kana);
            setUserCareId(user.user_care_id);
            setBirthYear(Number(user.user_birth_year));
            setBirthMonth(Number(user.user_birth_month));
            setBirthDay(Number(user.user_birth_day));
            setGender(user.user_gender);
            setInsurerNo(user.user_insurer_no);
            setCareLevel(Number(user.user_care_level));
            setDateList(user.user_visit_day);
            // 0日を指定することで最後の日を取得.
            const date = new Date(Number(user.user_birth_year), Number(user.user_birth_month || DEFAULT_MONTH), 0);
            const lastMonthDate = date.getDate();
            setLastDayOfMonth(lastMonthDate);
            setSelectedUser(true);
          } else {
            CheckerUsers.push({
              careId: user.user_care_id,
              userName: user.user_name,
            } as CheckerUser);
          }
        })
        .count();
      setCheckerUsers(CheckerUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  // 年月によって日を変更
  useEffect(() => {
    const date = new Date(birthYear, birthMonth, 0);
    const lastDay = date.getDate();

    setLastDayOfMonth(lastDay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthYear, birthMonth]);

  // 登録完了後の処理
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      const message = isUpdated ? '更新されました。' : '削除しました。';
      alert(message);
      // 戻す.
      window.location.href = '/user_setting';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated, isDeleted]);

  const funcDateList = (index: number, value: number) => {
    dateList.splice(index, 1, value === 0 ? 1 : 0);
    const newDateList = [...dateList];
    setDateList(newDateList);
  };

  /**
   * 入力内容の確認.
   *
   * @returns {boolean}
   * @memberof UserSettingRegister
   */
  const validate = (updateUser: UserSettingConst.ParamsFormat): boolean => {
    return UserSettingModule.validate(updateUser);
  };

  /**
   * 重複ダイアログ.
   *
   * @private
   * @returns {boolean}
   * @memberof UserSettingRegister
   */
  const showConfirm = (): boolean => {
    const message = '同じ名前もしくは被保険者番号（介護）の\nユーザーが存在します。\n\n登録しますか？';
    const isYes: boolean = window.confirm(message);
    return isYes;
  };

  /**
   * 登録ボタン押下の処理.
   *
   * @private
   * @returns
   * @memberof UserSettingRegister
   */
  const registerUser = async (): Promise<void> => {
    const updateUser: UserSettingConst.ParamsFormat = {
      user_care_level: careLevel,
      user_birth_year: birthYear,
      user_birth_month: birthMonth,
      user_birth_day: birthDay,
      user_name_kana: readName,
      user_name: userName,
      // 数字だけだが、0開始の数字もあり得るので文字.
      user_care_id: userCareId,
      user_gender: gender,
      user_insurer_no: insurerNo,
      user_visit_day: dateList,
    } as UserSettingConst.ParamsFormat;

    // 各入力項目のバリデーション.
    const res = validate(updateUser);
    if (res === false) {
      return;
    }

    // 重複確認.
    if (checkerUsers) {
      for (let i = 0; i < checkerUsers.length; i++) {
        if (checkerUsers[i].careId === userCareId || checkerUsers[i].userName === userName) {
          const isYes: boolean = showConfirm();
          if (isYes) {
            break;
          } else {
            return;
          }
        }
      }
    }

    // 次回用.
    const CheckerUsers = checkerUsers ? checkerUsers : [];
    CheckerUsers.push({
      careId: userCareId,
      userName: userName,
    } as CheckerUser);
    setCheckerUsers(CheckerUsers);

    // Action
    await dispatch(MoffAPIActions.updateUser(userId, updateUser));
    dispatchIdentifierToGTM('user_edit');
  };

  const deleteUser = async (): Promise<void> => {
    if (window.confirm('本当に削除してよろしいですか？')) {
      await dispatch(MoffAPIActions.deleteUser(userId));
    }
  };

  return (
    <>
      {loaded ? null : <PageLoader />}
      <PopupContainer />
      <UserSettingForm
        tableTitle="利用者更新"
        submitButtonText="更新"
        deleteButtonText="削除"
        lastDayOfMonth={lastDayOfMonth}
        userName={userName}
        readName={readName}
        userCareId={userCareId}
        birthYear={birthYear}
        birthMonth={birthMonth}
        birthDay={birthDay}
        careLevel={careLevel}
        gender={gender}
        insurerNo={insurerNo}
        dateList={dateList}
        setUserName={setUserName}
        setReadName={setReadName}
        setUserCareId={setUserCareId}
        setBirthYear={setBirthYear}
        setBirthMonth={setBirthMonth}
        setBirthDay={setBirthDay}
        setCareLevel={setCareLevel}
        setGender={setGender}
        setInsurerNo={setInsurerNo}
        funcDateList={funcDateList}
        submit={registerUser}
        deleteFunc={deleteUser}
        openPopupUserSetting={() => {
          dispatch(PopupActions.OpenUserSetting());
          dispatchIdentifierToGTM('user_register_youtube');
        }}
      />
    </>
  );
};

export default UserSettingRegisterContainer;
