// デフォルト.
import React, { FC } from 'react';
import BaseBrowser from './BaseBrowser';

import { User } from '../../../constants/UserListField';
import { ResponseData } from '../../../constants/HomeVisit';

interface DefaultProps {
  user: User;
  homeVisit: ResponseData;
}

// Chrome.
const Default: FC<DefaultProps> = ({ user, homeVisit }) => {
  return <BaseBrowser user={user} homeVisit={homeVisit} />;
};

export default Default;
