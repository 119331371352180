import { createHomeVisitAPIInstance } from '../api/HomeVisit';

const [isHomeVisitAPIError, homeVisitAPIInstance, homeVisitAPIErrorAction] = createHomeVisitAPIInstance();

/**
 * 居宅訪問・指定の1件を取得.
 *
 * @param {string} userId
 * @param {string} uniqueId
 * @param {number} sequence
 * @returns
 */
export const getHomeVisitRecord = (userId: string, uniqueId: string, sequence: number) => {
  return isHomeVisitAPIError ? homeVisitAPIErrorAction : homeVisitAPIInstance.getRecordAPI(userId, uniqueId, sequence);
};
