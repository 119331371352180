import { createReportInstance } from '../api/Report';
import { MoffCheckV1SelectKeys } from '../constants/MoffCheck';
import * as ReportConst from '../constants/Report';

const [isCreateReportError, reportInstance, createReportErrorAction] = createReportInstance();

/**
 * モフトレチェック評価レポートの計測詳細データを取得するアクション【3ヶ月比較】
 *
 * @param {string} userId
 * @param {string} selectMonth
 * @returns
 */
export const getMoffCheckV1MeasurementThreeMonth = (
  userId: string,
  selectMonth: string,
  items: MoffCheckV1SelectKeys[],
  isWalkingAbility: boolean,
) => {
  return isCreateReportError
    ? createReportErrorAction
    : reportInstance.getMoffCheckV1MeasurementThreeMonthAPI(userId, selectMonth, items, isWalkingAbility);
};

/**
 * モフトレチェック評価レポートの計算済み計測詳細データを取得するアクション【開始月比較】
 *
 * @param {string} userId
 * @param {string} selectMonth
 * @returns
 */
export const getMoffCheckV1MeasurementOldest = (
  userId: string,
  selectMonth: string,
  items: MoffCheckV1SelectKeys[],
  isWalkingAbility: boolean,
) => {
  return isCreateReportError
    ? createReportErrorAction
    : reportInstance.getMoffCheckV1MeasurementOldestOldestAPI(userId, selectMonth, items, isWalkingAbility);
};

/**
 * モフトレチェック評価レポートの計算済み計測詳細データを取得するアクション【比較月自由選択】
 *
 * @param {string} userId
 * @param {string[]} selectMonths
 * @returns
 */
export const getMoffCheckV1MeasurementFreeChoice = (
  userId: string,
  selectMonths: string[],
  items: MoffCheckV1SelectKeys[],
  isWalkingAbility: boolean,
) => {
  return isCreateReportError
    ? createReportErrorAction
    : reportInstance.getMoffCheckV1MeasurementFreeChoiceAPI(userId, selectMonths, items, isWalkingAbility);
};

/**
 * モフトレチェックの全ての計算済み計測詳細データを取得するアクション【3ヶ月比較】
 *
 * @param {string} userId
 * @param {string} selectMonth
 * @returns
 */
export const getMoffCheckAllMeasurementThreeMonth = (userId: string, selectMonth: string) => {
  return isCreateReportError
    ? createReportErrorAction
    : reportInstance.getMoffCheckAllMeasurementThreeMonthAPI(userId, selectMonth);
};

/**
 * モフトレチェックの全ての計算済み計測詳細データを取得するアクション【開始月比較】
 *
 * @param {string} userId
 * @param {string} selectMonth
 * @returns
 */
export const getMoffCheckAllMeasurementOldest = (userId: string, selectMonth: string) => {
  return isCreateReportError
    ? createReportErrorAction
    : reportInstance.getMoffCheckAllMeasurementOldestOldestAPI(userId, selectMonth);
};

/**
 * モフトレチェックの全ての計算済み計測詳細データを取得するアクション【比較月自由選択】
 *
 * @param {string} userId
 * @param {string[]} selectMonths
 * @returns
 */
export const getMoffCheckAllMeasurementFreeChoice = (userId: string, selectMonths: string[]) => {
  return isCreateReportError
    ? createReportErrorAction
    : reportInstance.getMoffCheckAllMeasurementFreeChoiceAPI(userId, selectMonths);
};

export const getPress = (userId: string, selectMonth: string) => {
  return isCreateReportError ? createReportErrorAction : reportInstance.getPressAPI(userId, selectMonth);
};

/**
 * レポートのコメントを保存する
 *
 * @param {string} userId
 * @param {number} reportId
 * @param {string} yearMonth
 * @param {string} comment
 * @returns
 */
export const saveComment = (userId: string, reportId: number, yearMonth: string, comment: string) => {
  return isCreateReportError
    ? createReportErrorAction
    : reportInstance.saveCommentAPI(userId, reportId, yearMonth, comment);
};

/**
 * 複数人のレポートのコメントを保存する
 *
 * @param {number} reportId
 * @param {string} yearMonth
 * @param {ReportConst.MemoForAPI[]} memoArr
 * @returns
 */
export const saveAllUsersComment = (reportId: number, yearMonth: string, memoArr: ReportConst.MemoForAPI[]) => {
  return isCreateReportError
    ? createReportErrorAction
    : reportInstance.saveAllUsersCommentAPI(reportId, yearMonth, memoArr);
};

/**
 * ログイン施設のレポートのコメントを全て取得する
 *
 * @param {number} reportId
 * @param {string} yearMonth
 * @returns
 */
export const getComments = (reportId: number, yearMonth: string) => {
  return isCreateReportError ? createReportErrorAction : reportInstance.getCommentsAPI(reportId, yearMonth);
};

export type reportAction = ReturnType<
  | typeof getMoffCheckV1MeasurementThreeMonth
  | typeof getMoffCheckV1MeasurementOldest
  | typeof getMoffCheckV1MeasurementFreeChoice
  | typeof getMoffCheckAllMeasurementThreeMonth
  | typeof getMoffCheckAllMeasurementOldest
  | typeof getMoffCheckAllMeasurementFreeChoice
  | typeof getPress
  | typeof saveComment
  | typeof saveAllUsersComment
  | typeof getComments
>;
