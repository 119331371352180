import React from 'react';

import QuestionButton from '../Atoms/QuestionButton';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import { MENU_TYPE } from '../../constants/Menu';

import {
  menu_icon_homevisit_normal,
  menu_icon_interestcheck_normal,
  menu_icon_literally_report_normal,
  menu_icon_locomo_normal,
  menu_icon_moff_check_normal,
  menu_icon_mofftre_normal,
  menu_icon_userselectkiroku_normal,
  menu_icon_lifecheck_normal,
  menu_icon_moffraku_normal,
  menu_icon_homevisit_hover,
  menu_icon_interestcheck_hover,
  menu_icon_literally_report_hover,
  menu_icon_locomo_hover,
  menu_icon_moff_check_hover,
  menu_icon_mofftre_hover,
  menu_icon_userselectkiroku_hover,
  menu_icon_lifecheck_hover,
  menu_icon_moffraku_hover,
} from '../../common/img/index';

const urlToImageNameDict = {
  user_select_literally_report: {
    normal: menu_icon_literally_report_normal,
    hover: menu_icon_literally_report_hover,
  },
  user_select_moff_check: {
    normal: menu_icon_moff_check_normal,
    hover: menu_icon_moff_check_hover,
  },
  user_select_moff_training: {
    normal: menu_icon_locomo_normal,
    hover: menu_icon_locomo_hover,
  },
  user_select_kiroku: {
    normal: menu_icon_userselectkiroku_normal,
    hover: menu_icon_userselectkiroku_hover,
  },
  user_select_press: {
    normal: menu_icon_mofftre_normal,
    hover: menu_icon_mofftre_hover,
  },
  user_interest_check: {
    normal: menu_icon_interestcheck_normal,
    hover: menu_icon_interestcheck_hover,
  },
  home_visit: {
    normal: menu_icon_homevisit_normal,
    hover: menu_icon_homevisit_hover,
  },
  user_select_life_check: {
    normal: menu_icon_lifecheck_normal,
    hover: menu_icon_lifecheck_hover,
  },
  moff_raku_users: {
    normal: menu_icon_moffraku_normal,
    hover: menu_icon_moffraku_hover,
  },
  user_select_moff_raku: {
    normal: menu_icon_moffraku_normal,
    hover: menu_icon_moffraku_hover,
  },
};

const useStyles = makeStyles(() => ({
  menuButton: {
    position: 'relative',
    borderRadius: '6px',
    background: 'linear-gradient(0deg, #ebc33f 0%, #F0C235 100%)',
    boxShadow: '3px 3px 10px rgba(0,0,0,0.1)',
    width: '340px',
    height: '118px',
    padding: '3px',
    color: 'white',
    fontWeight: 'bold',
    '&:link, &:hover, &:active, &:focus': {
      background: 'linear-gradient(0deg, #ec9f35 0%, #edac36 100%)',
      boxShadow: '5px 5px 15px rgba(0,0,0,0.15)',
      '& $menuIcon': {
        backgroundImage: (props: { urlNormal: string; urlHover: string }) => `url(${props.urlHover})`,
      },
    },
  },
  menuIcon: {
    width: '112px',
    height: '112px',
    borderRadius: '6px',
    backgroundColor: '#fff',
    backgroundSize: '90%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: (props: { urlNormal: string; urlHover: string }) => `url(${props.urlNormal})`,
  },
  text: {
    fontWeight: 'bold',
    fontSize: '1.15em',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    textAlign: 'left',
  },
  chip: {
    backgroundColor: '#fff',
    borderWidth: '1.5px',
    marginRight: '3px',
  },
}));

interface MenuButtonProps {
  /** ボタンテキスト */
  menuType: MENU_TYPE;
  /** youtubeボタン（?ボタン）が存在するかどうか */
  isYoutubeButton?: boolean;
  /** youtubeボタン（?ボタン）が押されたときのイベントハンドラ */
  popupSpecificFunction?: () => void;
}

const MenuButton: React.FC<MenuButtonProps> = ({ menuType, popupSpecificFunction, isYoutubeButton = true }) => {
  const imagePath = urlToImageNameDict[menuType.buttonHref];
  const classes = useStyles({ urlNormal: imagePath.normal, urlHover: imagePath.hover });

  return (
    <Box className={classes.menuButton}>
      <a href={menuType.buttonHref} data-test="menu-button">
        <Box display="flex">
          <Box className={classes.menuIcon} />
          <Box mb={3} ml={2} width="calc(100% - 112px)" display="flex" alignItems="center" justifyContent="left">
            <Typography className={classes.text} component="h1">
              {menuType.buttonText}
            </Typography>
          </Box>
        </Box>
      </a>
      <Box position="absolute" bottom="11px" left="122px">
        {menuType.chip.map((chip, i) => (
          <Chip
            key={i}
            avatar={chip.image ? <Avatar alt={chip.key} src={chip.image} /> : undefined}
            label={
              <Typography
                style={{
                  whiteSpace: 'normal',
                  lineHeight: '1em',
                  fontSize: '1em',
                  textAlign: 'center',
                  padding: '1px 0 0 0',
                }}
              >
                {chip.name}
              </Typography>
            }
            variant="outlined"
            size="small"
            className={classes.chip}
            style={{ borderColor: chip.color, color: chip.color }}
          />
        ))}
      </Box>
      {isYoutubeButton && (
        <Box position="absolute" top="10px" right="10px">
          <QuestionButton popupSpecificFunction={popupSpecificFunction} />
        </Box>
      )}
    </Box>
  );
};

export default MenuButton;
