// ユーザー一覧の表示(Show).
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  useLayoutEffect,
  memo,
  useRef,
} from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as UserListField from '../../constants/UserListField';
import * as UserListFieldConst from '../../constants/UserListField';
import { Memo } from '../../constants/Report';

import { dispatchIdentifierToGTM } from '../../utils/commonUtil';

import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import GeneralPrimaryCheckBox from '../Atoms/GeneralPrimaryCheckBox';
import GeneralPrimaryTextArea from '../Atoms/GeneralPrimaryTextArea';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
  tblStyle00: {
    width: '100%',
    maxWidth: '100%',
    border: 'solid 1px #999',
    '& thead, & tbody': {
      width: '100%',
      maxWidth: '100%',
      '@media print': {
        '@supports (-ms-ime-align: auto)': {
          border: 'solid 2px #999',
        },
      },
      '& th, & td': {
        padding: '0 5px',
        textAlign: 'left',
        border: 'solid 1px #999',
      },
      '& th': {
        position: 'relative',
        // firefoxで枠線が消える対策
        backgroundClip: 'padding-box',
        backgroundColor: '#e8e4df',
        lineHeight: '1.1em',
        verticalAlign: 'middle',
        padding: '3px 0',
        fontWeight: 'normal',
        textAlign: 'center',
        '&:nth-of-type(1)': {
          maxWidth: '40px',
          '@media print': {
            display: 'none',
          },
        },
        '&:nth-of-type(2)': {
          maxWidth: '50px',
        },
        '&:nth-of-type(3)': {
          maxWidth: '150px',
        },
        '&:nth-of-type(4)': {
          maxWidth: '115px',
        },
        '&:nth-of-type(5)': {
          maxWidth: '70px',
        },
        '&:nth-of-type(6)': {
          maxWidth: '175px',
        },
        '&:nth-of-type(7)': {
          width: '230px',
        },
        '&:nth-of-type(8)': {
          width: '100px',
          '@media print': {
            display: 'none',
          },
        },
      },
      '& td': {
        height: '50px',
        lineHeight: '1.1em',
        verticalAlign: 'middle',
        wordBreak: 'break-word',
        '&:nth-of-type(1)': {
          textAlign: 'center',
          padding: '6px 5px 0px 5px',
          '@media print': {
            display: 'none',
          },
        },
        '&:nth-of-type(2)': {
          textAlign: 'center',
          padding: '3px 5px',
        },
        '&:nth-of-type(3)': {
          textAlign: 'center',
          padding: '3px 5px',
        },
        '&:nth-of-type(4)': {
          fontSize: '90%',
          textAlign: 'center',
          padding: '3px 5px',
        },
        '&:nth-of-type(5)': {
          fontSize: '90%',
          textAlign: 'center',
          padding: '3px 5px',
        },
        '&:nth-of-type(6)': {
          fontSize: '90%',
          textAlign: 'center',
          padding: '3px 5px',
        },
        '&:nth-of-type(7)': {
          textAlign: 'center',
          padding: '6px 5px 0px 5px',
        },
        '&:nth-of-type(8)': {
          textAlign: 'center',
          '@media print': {
            display: 'none',
          },
        },
      },
    },
  },
  mobileTableFontSize: {
    fontSize: '0.8em',
    lineHeight: '1.3em',
    overflowX: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    '@media print': {
      display: 'table',
    },
  },
  isPrintRow: {
    '@media print': {
      display: 'table-row',
    },
  },
  isNotPrintRow: {
    '@media print': {
      display: 'none',
    },
  },
});

export interface UserListProps {
  users: UserListField.User[];
  memos?: Memo[];
  reload?: any;
  invokedPath?: string;
  currentMonth?: string | undefined;
  isCheckBox?: boolean;
  isMemoColumn?: boolean;
  isReportCheckColumn?: boolean;
  textFormPlaceholder?: string;
  textFormMaxLength?: number;
  getLastMonthMemos?: (checkDataArr: UserListField.CheckData[]) => void;
  saveAllMemoValue?: (checkDataArr: UserListField.CheckData[]) => void;
  reportOutputButtonOnClick?: (user: UserListField.User, currentMonth?: string) => void;
}

export interface RefFunction {
  getCheckedUsers: () => UserListField.User[];
}

// 詳細本体.
const UserList: ForwardRefRenderFunction<RefFunction, UserListProps> = (props, ref) => {
  const {
    users,
    memos,
    invokedPath = '',
    currentMonth,
    isCheckBox = true,
    isMemoColumn = false,
    isReportCheckColumn = false,
    textFormPlaceholder = '',
    textFormMaxLength = undefined,
    /*eslint no-empty-pattern: "off"*/
    getLastMonthMemos = ([]) => null,
    saveAllMemoValue = ([]) => null,
    reportOutputButtonOnClick = ({}) => null,
  } = props;

  // stateの準備.
  const initialAllCheckBoxState: UserListFieldConst.AllCheckData = {
    indeterminate: false,
    selected: true,
  };
  const initialChackDataArr = users.map((user: UserListFieldConst.User, index: number) => {
    return {
      id: index,
      selected: true,
      userId: user.user_id,
    } as UserListFieldConst.CheckData;
  });
  const [checkDataArr, setCheckDataArr] = useState(initialChackDataArr);
  const [allCheckData, setAllcheckData] = useState(initialAllCheckBoxState);
  const memoRefs = useRef<HTMLTextAreaElement[]>([]);

  const classes = useStyles();
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
  };

  const dataNullFlg = users ? users.length : 0;

  useLayoutEffect(() => {
    if (isMemoColumn) {
      users.forEach((user: UserListFieldConst.User, index: number) => {
        const userComment = memos?.find((memo) => {
          return user.user_id === memo.user_id;
        });
        memoRefs.current[index].value = userComment ? userComment.comment : '';
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memos]);

  /**
   * チェックボックスがonになっているユーザーの取得
   * 親要素から呼び出す
   *
   * @memberof UserListshow
   */
  useImperativeHandle(ref, () => ({
    getCheckedUsers: () => {
      const checkedUsersList: any[] = [];

      checkDataArr.forEach((checkbox, idx: number) => {
        if (checkbox.selected === true) {
          if (isMemoColumn) {
            const usersWithMemo = { ...users[idx], memo: memoRefs.current[idx].value };
            checkedUsersList.push(usersWithMemo);
          } else {
            checkedUsersList.push(users[idx]);
          }
        }
      });
      return checkedUsersList;
    },
  }));

  // チェックボックスの状況変更.
  const changeSelection = (id: number) => {
    const nextState = checkDataArr.map((checkData: UserListFieldConst.CheckData) => {
      return {
        id: checkData.id,
        selected: checkData.id === id ? !checkData.selected : checkData.selected,
        userId: checkData.userId,
      };
    });
    const nextAllState = {
      indeterminate: true,
      selected: false,
    };
    setCheckDataArr(nextState);
    setAllcheckData(nextAllState);
    dispatchIdentifierToGTM('all_users_checkbox');
  };

  // 全てのチェックボックスの状況変更.
  const changeAllSelection = () => {
    const nextState = checkDataArr.map((checkData: UserListFieldConst.CheckData) => {
      return {
        id: checkData.id,
        selected: !allCheckData.selected,
        userId: checkData.userId,
      };
    });
    const nextAllState = {
      indeterminate: false,
      selected: !allCheckData.selected,
    };
    setCheckDataArr(nextState);
    setAllcheckData(nextAllState);
    dispatchIdentifierToGTM('all_users_checkbox');
  };

  const getMemoCheckData = () => {
    const checkData = checkDataArr.map((checkData, index) => {
      const memo = memoRefs.current[index].value;
      checkData.memo = memo;
      return checkData;
    });
    return checkData;
  };

  const saveAllMemoFunc = () => {
    const checkData = getMemoCheckData();
    saveAllMemoValue(checkData);
  };

  const getLastMonthMemosFunc = () => {
    const checkData = getMemoCheckData();
    getLastMonthMemos(checkData);
  };

  if (dataNullFlg === 0) {
    return (
      <>
        <p>この月はデータがありません。</p>
      </>
    );
  } else {
    return (
      <>
        <Box
          component="table"
          className={`${classes.tblStyle00} ${matches.xs ? classes.mobileTableFontSize : undefined}`}
          display={matches.xs && isCheckBox ? 'block' : undefined}
          whiteSpace={matches.xs && isCheckBox ? 'nowrap' : undefined}
        >
          <thead>
            <tr>
              {isCheckBox && (
                <th scope="col">
                  {invokedPath === '/all_users_save_kiroku' ? '入力' : '印刷'}
                  <br />
                  対象
                  <br />
                  <br />
                  <GeneralPrimaryCheckBox
                    checkBoxId={-1}
                    checked={allCheckData.selected}
                    indeterminate={allCheckData.indeterminate}
                    onChange={changeAllSelection}
                  />
                </th>
              )}
              <th scope="col">被保険者番号（介護）</th>
              <th scope="col">名前</th>
              <th scope="col">生年</th>
              <th scope="col">性別</th>
              <th scope="col">登録日</th>
              {isMemoColumn ? (
                <>
                  <th scope="col">
                    メモ編集
                    <Box display="flex">
                      {invokedPath === '/all_users_print_press' || invokedPath === '/all_users_print_moff_check' ? (
                        <GeneralPrimaryButton
                          txt="メモを保存"
                          setCss={'margin: 5px auto 0px; width: 105px; height: 40px; font-size: 14px !important;'}
                          onClick={saveAllMemoFunc}
                        />
                      ) : null}
                      {invokedPath === '/all_users_print_press' ? (
                        <GeneralPrimaryButton
                          txt={'前月のメモ\n一括反映'}
                          setCss={
                            'margin: 5px auto 0px; width: 105px; height: 40px; font-size: 14px !important; white-space: pre; font-weight: normal;  font-size: 13.5px; line-height: 1.25;'
                          }
                          onClick={getLastMonthMemosFunc}
                        />
                      ) : null}
                    </Box>
                  </th>
                </>
              ) : null}
              {isReportCheckColumn ? <th scope="col">レポート確認</th> : null}
            </tr>
          </thead>

          <tbody>
            {users.map((user: UserListFieldConst.User, index: number) => {
              return (
                <tr
                  key={index}
                  className={isCheckBox && checkDataArr[index].selected ? classes.isPrintRow : classes.isNotPrintRow}
                >
                  {isCheckBox && (
                    <td>
                      <GeneralPrimaryCheckBox
                        checkBoxId={index}
                        checked={checkDataArr[index].selected}
                        onChange={() => changeSelection(index)}
                      />
                    </td>
                  )}
                  <td>{user.user_care_id}</td>
                  <td>{user.user_name}</td>
                  <td>{user.user_birth_year}</td>
                  <td>{user.user_gender === 0 ? UserListField.Male : UserListField.Female}</td>
                  <td>{user.created_at}</td>
                  {isMemoColumn ? (
                    <>
                      <td>
                        <Box display="block" displayPrint="none">
                          <GeneralPrimaryTextArea
                            name={`text_area_${index}`}
                            rows={5}
                            cols={30}
                            placeholder={checkDataArr[index].selected ? textFormPlaceholder : ''}
                            maxLength={textFormMaxLength}
                            disabled={!checkDataArr[index].selected}
                            ref={(el: HTMLTextAreaElement) => (memoRefs.current[index] = el)}
                          />
                        </Box>
                      </td>
                    </>
                  ) : null}
                  {isReportCheckColumn ? (
                    <>
                      <td>
                        <GeneralPrimaryButton
                          txt={
                            <>
                              {currentMonth}
                              <br />
                              レポート確認
                            </>
                          }
                          setCss={'width: 110px; height: 60px; font-size: 13px !important; display: inline;'}
                          onClick={() => reportOutputButtonOnClick(user, currentMonth)}
                        />
                      </td>
                    </>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </Box>
        {(() => {
          return null;
        })()}
      </>
    );
  }
};

export default memo(forwardRef(UserList), (prevProps, nextProps) => {
  if (prevProps.users === nextProps.users) {
    return true;
  } else {
    return false;
  }
});
