import React, { FC, useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';

import { getCurrentTime, convertTime, convertDay } from '../../utils/dateUtil';
import { Global } from '../../utils/global';
import { returnFalse, dispatchIdentifierToGTM } from '../../utils/commonUtil';
import * as KirokuAutoSuggestUtil from '../../utils/KirokuAutoSuggestModule';

import { CheckData, KirokuFormParamsFormat, KirokuRequestParamsFormat, SuggestWord } from '../../constants/Kiroku';

import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import GeneralPrimaryCheckBox from '../Atoms/GeneralPrimaryCheckBox';
import AutoCompleteTextArea from '../Atoms/AutoCompleteTextArea';
import GeneralPrimaryDateInput from '../Atoms/GeneralPrimaryDateInput';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tblStyle00: {
    [theme.breakpoints.down('xs')]: {
      width: '300px',
      margin: '0 auto',
      lineHeight: '1.3em',
      display: 'block',
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      '-webkit-overflow-scrolling': 'touch',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 50px)',
      margin: '0 25px',
      fontSize: '1em',
      display: 'block',
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      '-webkit-overflow-scrolling': 'touch',
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 50px)',
      margin: '0 25px',
      fontSize: '1em',
    },
    '@media print': {
      margin: '0 auto',
      display: 'table',
      fontSize: '1em',
    },
    /* Edgeの印刷時に線が出る対策 */
    '@supports (-ms-ime-align: auto)': {
      border: 'solid 2px #999',
    },
    maxWidth: '939px',
    border: 'none',
    '& thead': {
      '& th': {
        padding: '3px 10px',
        fontWeight: 'normal',
        textAlign: 'center',
        background: '#e8e4df',
        lineHeight: '1.1em',
        verticalAlign: 'middle',
        border: 'solid 1px #999',
      },
      '& th:nth-of-type(1)': {
        width: '40px',
        '@media print': {
          display: 'none',
        },
        textAlign: 'center',
        padding: '6px 5px 0px 5px',
      },
      '& th:nth-of-type(2)': {
        width: '200px',
      },
      '& th:nth-of-type(3)': {
        width: '100px',
      },
      '& th:nth-of-type(4)': {
        width: '185px',
      },
      '& th:nth-of-type(5)': {
        width: '120px',
      },
      '& th:nth-of-type(6)': {
        width: '195px',
      },
      '& th:nth-of-type(7)': {
        '@media print': {
          display: 'none',
        },
      },
    },
    '& tbody': {
      '& td': {
        padding: '3px 5px',
        verticalAlign: 'middle',
        textAlign: 'left',
        border: 'solid 1px #999',
      },
      '& td:nth-of-type(1)': {
        textAlign: 'center',
        '@media print': {
          display: 'none',
        },
      },
      '& td:nth-of-type(2)': {
        textAlign: 'center',
      },
      '& td:nth-of-type(3)': {
        textAlign: 'center',
      },
      '& td:nth-of-type(4)': {
        textAlign: 'center',
        minWidth: '185px',
      },
      '& td:nth-of-type(5)': {
        textAlign: 'center',
        minWidth: '120px',
      },
      '& td:nth-of-type(6)': {
        textAlign: 'center',
        minWidth: '195px',
      },
      '& td:nth-of-type(7)': {
        display: 'none',
      },
      '& td:nth-of-type(8)': {
        display: 'none',
      },
      '& td:nth-of-type(9)': {
        '@media print': {
          display: 'none',
        },
      },
    },
    /* form parts */
    "input[type='datetime']": {
      width: '180px',
      fontSize: '90%',
    },
    "input[type='time']": {
      width: '95px',
      fontSize: '78%',
    },
    "input[type='datetime-local']": {
      width: '200px',
      fontSize: '85%',
    },
  },
  isPrintRow: {
    '@media print': {
      display: 'table-row',
    },
  },
  isNotPrintRow: {
    '@media print': {
      display: 'none',
    },
  },
  newRowDeleteCell: {
    position: 'absolute',
    right: '-40px',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
}));

const KirokuForm: FC<{
  toKirokuCancel: () => void;
  defaultValues: KirokuRequestParamsFormat[];
  submitKiroku: (
    data: KirokuFormParamsFormat[],
    defaultValues: KirokuRequestParamsFormat[],
    isDirty: boolean,
    suggestWordsGroupSubject: SuggestWord[],
  ) => void;
}> = (props) => {
  const classes = useStyles();
  const { toKirokuCancel, defaultValues, submitKiroku } = props;
  const [checkDataArr, setCheckDataArr] = useState<CheckData[]>([] as CheckData[]);
  const [
    suggestWordsGroupSubject,
    suggestWordsGroupInstructor,
  ] = KirokuAutoSuggestUtil.setLocalStorageValueForSuggestWordsMUI();

  const defalutForm: { ['kiroku']: KirokuRequestParamsFormat[] } = {
    kiroku: defaultValues,
  };

  const { register, control, handleSubmit, formState, watch } = useForm({
    defaultValues: defalutForm,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'kiroku',
  });

  const { isSubmitting, isDirty } = formState;
  const watchAllFields = watch();
  const formLength = watchAllFields?.kiroku?.length;

  useEffect(() => {
    // stateの準備.
    const checkDataArr: CheckData[] = fields.map((record) => ({
      id: record.id as string,
      selected: true,
    }));
    setCheckDataArr(checkDataArr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(checkDataArr)) {
      const up = _.differenceBy(fields, checkDataArr, 'id');
      const down = _.differenceBy(checkDataArr, fields, 'id');
      if (!_.isEmpty(up)) {
        setCheckDataArr([...checkDataArr, { id: up[0].id as string, selected: true }]);
      }
      if (!_.isEmpty(down)) {
        setCheckDataArr(checkDataArr.filter((checkData) => checkData.id !== down[0].id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  // チェックボックスの状況変更.
  const changeSelection = (id: string) => {
    const nextState = checkDataArr.map((checkData: CheckData) => {
      return {
        id: checkData.id,
        selected: checkData.id === id ? !checkData.selected : checkData.selected,
      };
    });
    setCheckDataArr(nextState);

    dispatchIdentifierToGTM('select_print_data');
  };

  const addNewFormRow = () => {
    append({
      datetime: getCurrentTime().datetimeT,
      duration: '00:00:00',
      // レコードが新規か更新かを判断するためにnewのフラグで判断する
      new: true,
      subject: '',
      instructor: '',
      remarks: '',
    });
    dispatchIdentifierToGTM('detail_page_add_record_kiroku');
  };

  return (
    <form
      onSubmit={handleSubmit((data: { ['kiroku']: KirokuFormParamsFormat[] }) =>
        submitKiroku(data.kiroku, defaultValues, isDirty, suggestWordsGroupSubject),
      )}
    >
      <table className={classes.tblStyle00}>
        <thead>
          <tr>
            {!Global.isApp ? (
              <th scope="col">
                印刷
                <br />
                対象
              </th>
            ) : (
              <th scope="col" className="isApp" />
            )}
            <th key={'date'} scope="col">
              実施日時
            </th>
            <th key={'time'} scope="col">
              実施時間
            </th>
            <th key={'trainingcontent'} scope="col">
              訓練内容
            </th>
            <th key={'featuretraining'} scope="col">
              機能訓練
              <br />
              指導員
            </th>
            <th key={'remark'} scope="col">
              特記事項
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.map((record, index: number) => {
            const checkState = checkDataArr.find((checkData) => checkData.id === record.id);
            return (
              <tr key={record.id} className={checkState?.selected ? classes.isPrintRow : classes.isNotPrintRow}>
                <td>
                  <GeneralPrimaryCheckBox
                    onChange={() => changeSelection(record.id as string)}
                    checked={checkState?.selected ?? true}
                  />
                </td>
                <td>
                  {record.datetime === undefined || record.datetime.length < 20 ? (
                    <GeneralPrimaryDateInput
                      name={`kiroku[${index}].datetime`}
                      defaultValue={record.datetime}
                      isRequired={true}
                      refFunction={register()}
                      type="datetime-local"
                    />
                  ) : (
                    <>
                      <Box>{convertDay(convertTime(record.datetime).day)}</Box>
                      <GeneralPrimaryDateInput
                        name={`kiroku[${index}].datetime`}
                        defaultValue={record.datetime}
                        isRequired={true}
                        refFunction={register()}
                        type="hidden"
                      />
                    </>
                  )}
                </td>
                <td>
                  <GeneralPrimaryDateInput
                    name={`kiroku[${index}].duration`}
                    type="time"
                    step="1"
                    defaultValue={record.duration}
                    isRequired={false}
                    refFunction={register()}
                  />
                </td>
                <td>
                  <Box my={1}>
                    <AutoCompleteTextArea
                      formName={`kiroku[${index}].subject`}
                      defaultValue={record.subject}
                      suggestWordsGroup={suggestWordsGroupSubject.filter((el) => !_.isNil(el.text))}
                      register={register}
                      control={control}
                      formLength={formLength}
                    />
                  </Box>
                </td>
                <td>
                  <Box my={1}>
                    <AutoCompleteTextArea
                      formName={`kiroku[${index}].instructor`}
                      defaultValue={record.instructor}
                      suggestWordsGroup={suggestWordsGroupInstructor.filter((el) => !_.isNil(el.text))}
                      register={register}
                      control={control}
                      formLength={formLength}
                    />
                  </Box>
                </td>
                <td>
                  <Box my={1} position="relative">
                    <AutoCompleteTextArea
                      formName={`kiroku[${index}].remarks`}
                      defaultValue={record.remarks}
                      suggestWordsGroup={[]}
                      register={register}
                      control={control}
                      formLength={formLength}
                    />
                    {record.new && (
                      <IconButton
                        size="medium"
                        onClick={() => remove(index)}
                        className={classes.newRowDeleteCell}
                        data-test="remove-button"
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Box>
                </td>
                <td>
                  <GeneralPrimaryDateInput
                    name={`kiroku[${index}].index`}
                    defaultValue={index + 1}
                    isRequired={false}
                    refFunction={register()}
                    type="hidden"
                  />
                </td>
                <td>
                  <Checkbox checked={record.new} name={`kiroku[${index}].new`} inputRef={register()} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Box display="flex" justifyContent="center" mt="50px">
        <GeneralPrimaryButton
          type="button"
          txt="項目追加"
          setCss={'width: 158px; height: 50px;'}
          onClick={addNewFormRow}
        />
      </Box>
      <Box display="flex" justifyContent="center" mt="50px">
        {!isSubmitting ? (
          <Box width="158px" mx={{ xs: '15px', sm: '30px' }}>
            <GeneralPrimaryButton
              type="button"
              txt="キャンセル"
              setCss={'width: 100%; height: 50px;'}
              onClick={toKirokuCancel}
            />
          </Box>
        ) : null}

        {!isSubmitting ? (
          <Box width="158px" mx={{ xs: '15px', sm: '30px' }}>
            <GeneralPrimaryButton
              type="submit"
              txt="保存する"
              setCss={'width: 100%; height: 50px;'}
              onClick={returnFalse}
            />
          </Box>
        ) : null}
      </Box>
    </form>
  );
};

export default KirokuForm;
