/* 上部の曜日リスト */
import React, { FC, useEffect, useState, useLayoutEffect } from 'react';

import * as UserListField from '../constants/UserListField';
import DateList from '../components/Molecules/DateList';
import SearchInput from '../components/Molecules/SearchInput';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

const DateListContainer: FC = () => {
  const [weekSelected, setWeekSelected] = useState<string>(UserListField.WEEK_CHARS[new Date().getDay()]);
  const [searchText, setSearchText] = useState('');

  // フィルター処理.
  const filter = () => {
    // userのオブジェクトの入ったhiddenのClass.
    let usersObject: HTMLCollectionOf<Element> | any = document.getElementsByClassName('user');

    // search枠.
    if (searchText) {
      // フィルターする(HTMLCollectionは配列に一回変換する必要あり).
      usersObject = [].slice.call(usersObject).filter(function (element: HTMLElement) {
        const parent: HTMLElement | null = element.parentElement;
        if (parent) {
          // 一旦全員消す(次の頭文字フィルターに委ねるため).
          parent.hidden = true;
        }

        const html: string = element.innerHTML;
        const row = JSON.parse(html);
        return Object.keys(row).some(function (key: string) {
          return String(row[key]).indexOf(searchText) > -1;
        });
      });
    }

    // ここから曜日フィルター.
    let index = 0;
    for (const key in usersObject) {
      if (Object.prototype.hasOwnProperty.call(usersObject, key)) {
        // 1つのエレメント.
        const element: Element = usersObject[key];

        // stringfyで入ってるのでいったん変換.
        // 運動曜日: 月曜開始で順に入った配列（1:該当, 0:非該当）.
        const userVisitDay: number[] = (JSON.parse(element.innerHTML) as UserListField.User).user_visit_day;

        const parent: HTMLElement | null = element.parentElement;
        // 選択されたWeekSelectedがWeekChars[]の中では何番目の値なのか？
        const weekIndex = UserListField.START_MO_WEEK_CHARS.indexOf(weekSelected);

        // 選択したのがAll or 1なら入ってる.
        if (weekSelected === UserListField.ALL || userVisitDay[weekIndex] === 1) {
          if (parent) {
            // 居る人は表示する.
            parent.hidden = false;

            // 偶数は白/奇数グレー(cssがhidden要素をスキップしてくれないので上書き).
            const isEven: boolean = index % 2 === 0;
            [].forEach.call(
              parent.childNodes,
              (child: Node) => ((child as HTMLElement).style.background = isEven ? '#fff' : '#e8e4df'),
            );

            index++;
          }
        } else {
          if (parent) {
            // 該当しない人は消す.
            parent.hidden = true;
          }
        }
      }
    }
  };

  const searchHeadWeek = (week: string) => {
    // 背景変更.
    setWeekSelected(week);
    dispatchIdentifierToGTM('week_day_filter');
  };

  const onFocusSearchInput = () => {
    dispatchIdentifierToGTM('user_search');
  };

  useEffect(() => {
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // フィルター処理.
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekSelected, searchText]);

  return (
    <React.Fragment>
      <SearchInput onChange={setSearchText} onFocus={onFocusSearchInput} placeholder="利用者検索" />
      <DateList weekSelected={weekSelected} searchHeadWeek={searchHeadWeek} />
    </React.Fragment>
  );
};

export default DateListContainer;
