// Userのプロパティ.
export interface User {
  memo: any;
  created_at: string;
  functional_latest_date: string | undefined;
  institution_sub: string;
  updated_at: string;
  user_birth_year: string;
  user_birth_month: string;
  user_birth_day: string;
  user_care_id: string;
  user_care_level: string;
  user_gender: number;
  user_id: string;
  user_name: string;
  user_name_kana: string;
  user_visit_day: number[];
  user_insurer_no: string;
  /** 表に表示する日付 */
  display_date?: string;
}

export interface UserModel {
  user_care_id: string;
  user_name: string;
  user_birth_year: string;
  user_gender: string;
  display_date: string;
}

export interface DisplayPayload {
  user_id: User['user_id'];
  displayDate: User['display_date'];
}

const commonModel = {
  user_care_id: '被保険者番号（介護）',
  user_name: '名前',
  user_birth_year: '生年',
  user_gender: '性別',
};

// 利用者一覧用モデル.
export const LocomoModel: UserModel = {
  ...commonModel,
  display_date: '登録日',
};

// 記録用・利用者一覧用モデル.
export const KirokuModel: UserModel = {
  ...commonModel,
  display_date: '最新の記録日',
};

export const Male = '男';
export const Female = '女';

export const TypeTraining = 1;
// 日常生活動作: 2?
export const TypeKiroku = 3;

export const MO = 'Mo';
export const TU = 'Tu';
export const WE = 'We';
export const TH = 'Th';
export const FR = 'Fr';
export const SA = 'Sa';
export const SU = 'Su';
export const ALL = 'All';

// 日曜日開始の曜日達.
export const WEEK_CHARS: string[] = [SU, MO, TU, WE, TH, FR, SA];

// 月曜日開始日曜日終わりの曜日達.
export const START_MO_WEEK_CHARS: string[] = [MO, TU, WE, TH, FR, SA, SU];

export const WEEK_DAY = {
  all: {
    key: ALL,
    name: '全て',
  },
  monday: {
    key: MO,
    name: '月',
  },
  tuesday: {
    key: TU,
    name: '火',
  },
  wednesday: {
    key: WE,
    name: '水',
  },
  thursday: {
    key: TH,
    name: '木',
  },
  friday: {
    key: FR,
    name: '金',
  },
  saturday: {
    key: SA,
    name: '土',
  },
  sunday: {
    key: SU,
    name: '日',
  },
};

// 並び替えるユーザー属性値の優先順位
export const USERS_SORT_KEYS = [
  'user_name_kana',
  'user_care_id',
  'user_birth_year',
  'user_birth_month',
  'user_birth_day',
  'user_gender',
  'user_name',
];

export enum type {
  None = 0,
  Show,
}

// チェックボックスの状況.
export interface CheckData {
  id: number;
  selected: boolean;
  memo?: string;
  userId: string;
}

// チェックボックスの状況.（全てチェック）
export interface AllCheckData {
  indeterminate: boolean;
  selected: boolean;
}
