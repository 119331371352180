import React, { FC, useState, useLayoutEffect } from 'react';
import * as TrainingConst from '../../../constants/Training';
import { TrainingMenu, MonthlyTrainingData, DailyTrainingNumber } from '../../../constants/Training';
import GeneralPrimaryButton from '../../Atoms/GeneralPrimaryButton';
import Calendar from '../../Atoms/Calendar';
import DateSelector from '../DateSelector';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useTrainingUI } from '../../../hooks/use-training-ui';
import { isEmpty, pick } from '../../../utils/commonUtil';

const useStyles = makeStyles({
  dailyWrapper: {
    '@media print': {
      margin: '0',
    },
  },
  dailyArea: {
    '@media print': {
      justifyContent: 'space-between',
    },
  },
  dailyResult: {
    '@media print': {
      width: '467px',
    },
  },
  tblStyle02: {
    display: 'table',
    fontWeight: 'normal',
    tableLayout: 'fixed',
    borderCollapse: 'separate',
    width: '100%',
    margin: '0 0 50px 0',
    '& thead': {
      color: '#969696',
    },
    '& thead th:first-of-type': {
      padding: '8px',
    },
    '& thead tr:nth-of-type(2n + 1)': {
      backgroundColor: '#fff',
    },
    '& tr:nth-of-type(2n + 1)': {
      backgroundColor: '#e8e4df',
    },
    '& tr:nth-of-type(2n + 1) th': {
      borderRadius: '4px 0 0 4px',
    },
    '& tr:nth-of-type(2n + 1) td:last-child': {
      borderRadius: '0 4px 4px 0',
    },
    '& tr:first-of-type th:not(::first-of-type)': {
      paddingLeft: 0,
    },
    '& th': {
      width: '100%',
      fontWeight: 'normal',
      textAlign: 'left',
    },
    '& th:first-of-type': {
      padding: '0 8px',
    },
    '& td': {
      padding: '8px 0',
      textAlign: 'left',
    },
  },
  compareCss: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '0 7px',
  },
});

interface DailyReportProps {
  history: MonthlyTrainingData;
  className: string;
  isDetailComponent: boolean;
  isCompareStartTerm: boolean;
  date: moment.Moment;
  minusDaily: (className: string) => void;
  plusDaily: (className: string) => void;
  changeState: (state: TrainingConst.SCREEN_TYPE) => void;
}

const Report: FC<DailyReportProps> = ({
  history,
  className,
  isDetailComponent,
  isCompareStartTerm,
  date,
  minusDaily,
  plusDaily,
  changeState,
}) => {
  const classes = useStyles();
  const { cachedTrainingMenus, displayedTrainings, allTrainings } = useTrainingUI();
  const [dailyTrainings, setDailyTrainings] = useState<DailyTrainingNumber>({});

  useLayoutEffect(() => {
    const dailyTrainings = history?.[date.format('YYYY-MM-DD')];
    const displayedTrainingInfoArray = allTrainings.filter((trainingInfo) =>
      displayedTrainings.includes(trainingInfo.name),
    );
    const displayedTrainingIds = displayedTrainingInfoArray.map((trainingInfo) => String(trainingInfo.id));
    const pickedDailyTrainings = pick(dailyTrainings, displayedTrainingIds);
    setDailyTrainings(pickedDailyTrainings);
    // eslint-disable-next-line
  }, [history, displayedTrainings, date.format('YYYY-MM-DD')]);

  return (
    <Box
      mt={{ xs: className === TrainingConst.TRAINING_REPORT_NAME.CompareRight ? '50px' : '0', md: '0' }}
      minHeight="400px"
      className={classes.dailyWrapper}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={{
          xs: 'center',
          sm:
            className === TrainingConst.TRAINING_REPORT_NAME.CompareLeft ||
            className === TrainingConst.TRAINING_REPORT_NAME.CompareRight
              ? 'center'
              : 'space-between',
        }}
        mb="20px"
        data-test="daily-header"
      >
        <Box>
          {isCompareStartTerm ? (
            <Box mb={{ xs: '30px', sm: '0' }} width={{ xs: '240px', sm: '65px' }}>
              <GeneralPrimaryButton
                txt="終了"
                setCss={'width: 100%; height: 50px; white-space:nowrap;'}
                onClick={() => changeState(TrainingConst.SCREEN_TYPE.Detail)}
              />
            </Box>
          ) : null}
          {isDetailComponent ? (
            <Box mb={{ xs: '30px', lg: '0' }} width={{ xs: '240px', sm: '158px' }}>
              <GeneralPrimaryButton
                txt="比較する"
                onClick={() => changeState(TrainingConst.SCREEN_TYPE.Compare)}
                setCss={`width: 100%; height: 50px;`}
              />
            </Box>
          ) : null}
        </Box>
        <Box display="flex" width={{ xs: '315px', sm: '400px' }}>
          <DateSelector
            date={date}
            className={className}
            dateDisplayType={TrainingConst.DATE_DISPLAY_TYPE.Day}
            minusDate={minusDaily}
            plusDate={plusDaily}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent={{ xs: 'center', md: 'space-between' }}
        flexWrap="wrap"
        className={classes.dailyArea}
        data-test="daily-body"
      >
        <Box width={{ xs: '100%', md: '467px' }} fontSize={{ xs: '0.9em', sm: '1em' }} className={classes.dailyResult}>
          <table className={`${classes.tblStyle02} ${isDetailComponent || classes.compareCss}`}>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">項目</th>
                <th scope="col">回数</th>
                <th scope="col">平均角度</th>
                <th scope="col">動作時間</th>
              </tr>
            </thead>
            <tbody>
              {
                // ある時だけ表示される.
                !isEmpty(dailyTrainings) &&
                  Object.keys(dailyTrainings).map((trainingId: string) => (
                    <tr key={`DailyTable${trainingId}`}>
                      {cachedTrainingMenus.map((trainingMenu: TrainingMenu) => {
                        if (trainingMenu.id === Number(trainingId)) {
                          const headKey = `DailyTableHead${trainingId}`;
                          return <th key={headKey}>{trainingMenu.name}</th>;
                        }

                        // 該当しないときは空
                        return null;
                      })}
                      <td>{dailyTrainings[trainingId].count}回</td>
                      <td>{dailyTrainings[trainingId].average_angle}度</td>
                      <td>{dailyTrainings[trainingId].average_duration}秒</td>
                    </tr>
                  ))
              }
            </tbody>
          </table>
        </Box>
        {isDetailComponent && (
          <Box width="400px" mt="20px">
            <Calendar history={history} date={date} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Report;
