import React, { FC, KeyboardEvent, ChangeEvent, memo } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller, UseFormMethods } from 'react-hook-form';

import { SuggestWord } from '../../constants/Kiroku';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
  textField: {
    fontSize: '0.8rem',
    minWidth: '150px',
    minHeight: '50px',
  },
  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#ED9B34 !important',
    },
  },
  cssFocused: {},
  notchedOutline: {},
});

interface AutoCompleteTextAreaProps {
  /** フォーム名 */
  formName: string;
  /** フォーム初期値 */
  defaultValue: string;
  /** 入力予測グループ */
  suggestWordsGroup: SuggestWord[];
  /** react-hook-formのパラメータ */
  register: UseFormMethods['register'];
  /** react-hook-formのパラメータ */
  control: UseFormMethods['control'];
  /** memo化の際に、フォームが増減していたらレンダリングする */
  formLength?: number;
}

const AutoCompleteTextArea: FC<AutoCompleteTextAreaProps> = ({
  formName,
  defaultValue,
  suggestWordsGroup,
  register,
  control,
}) => {
  const classes = useStyles();
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          options={suggestWordsGroup.filter((el) => !_.isNil(el.text))}
          groupBy={(option) => option.title}
          renderOption={(option) => {
            return (
              <Box fontSize="0.8em" data-test="option-text">
                {option.text}
              </Box>
            );
          }}
          getOptionLabel={(option) => {
            return option.text ?? '';
          }}
          freeSolo
          autoComplete
          openOnFocus={false}
          blurOnSelect
          clearOnBlur={false}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                multiline={true}
                size="small"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    input: classes.textField,
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  name: props.name,
                  inputRef: (ref) => {
                    if (!ref) return;
                    register(ref);
                  },
                }}
              />
            );
          }}
          onChange={(e: ChangeEvent<{}> | KeyboardEvent<{}>, data) => {
            if ((e as KeyboardEvent<{}>).keyCode === 13) {
              onChange({ title: 'default', text: data?.text ?? data });
            } else {
              onChange(data);
            }
          }}
          {...props}
        />
      )}
      name={formName}
      control={control}
      defaultValue={{ title: 'default', text: defaultValue }}
    />
  );
};

export default memo(AutoCompleteTextArea, (prevProps, nextProps) => {
  if (prevProps?.formLength !== nextProps?.formLength) {
    // フォーム行を追加・削除した時に再レンダリングしないと値がクリアされてしまう。
    return false;
  } else if (prevProps?.defaultValue === nextProps?.defaultValue) {
    // IEで defaultValueが存在するフォームで、あ行以外の文字を入力すると「kあ」の様になってしまう対策。
    return true;
  } else {
    return false;
  }
});
