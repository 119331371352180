/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';

const Icon = css`
  width: 18px;
  max-height: 19px; //IE11対策
  margin-left: 5px;
  transform: translateY(3px);
`;

/*
 * TODO react-scripts@2.0.0以上で、react@16.3.0以上だとsvgをcomponentとして扱える
 * https://create-react-app.dev/docs/adding-images-fonts-and-files/#adding-svgs
 * (現状svg-loaderでエラー出るので、とりあえず直接記入)
 */
const ChartIcon: FC = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="chart-area"
      className="svg-inline--fa fa-chart-area fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      css={Icon}
    >
      <path
        fill="currentColor"
        d="M500 384c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12
          12-12h40c6.6 0 12 5.4 12 12v308h436zM372.7 159.5L288 216l-85.3-113.7c-5.1-6.8-15.5-6.3-19.9
          1L96 248v104h384l-89.9-187.8c-3.2-6.5-11.4-8.7-17.4-4.7z"
      />
    </svg>
  );
};

export default ChartIcon;
