/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC, MutableRefObject } from 'react';

import { termType } from '../../constants/Locomo';

const form = css`
  width: 200px;
  input {
    min-width: 200px;
    height: 40px;
    font-size: 1.2em;
  }
`;

interface MonthSelectFormProps {
  month: string;
  refDate: MutableRefObject<HTMLInputElement | null | undefined>;
  formValidation: () => void;
  termType: termType;
}

const MonthSelectForm: FC<MonthSelectFormProps> = ({ month, refDate, termType, formValidation }) => (
  <div css={form}>
    <input
      key={`${termType}_${month}`}
      ref={(Date) => {
        refDate.current = Date;
      }}
      type="month"
      name={`${termType}_date`}
      onChange={() => formValidation()}
      value={month}
    />
  </div>
);

export default MonthSelectForm;
