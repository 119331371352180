import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as HeaderActions from '../actions/Header';
import * as Header from '../constants/Header';
import LinkButton from '../components/Atoms/LinkButton';

const Setting: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      document.title = '管理';
      dispatch(HeaderActions.setPage(Header.SETTING));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <LinkButton
        txt="パスワードを変更する"
        setCss="width: 300px; height: 44px; margin: 25px auto; line-height: 40px;"
        href="change_password.html"
      />
      <LinkButton
        txt="パスワードを忘れた方はこちら"
        setCss="width: 300px; height: 44px; margin: 0 auto; line-height: 40px;"
        href="forgot_password.html"
      />
    </div>
  );
};

export default Setting;
