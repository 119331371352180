// BI reducer.
import * as BarthelIndex from '../../constants/BarthelIndex';

const initialState = {
  Loading: false,
  LoadedCount: 0,
};

export default function barthelIndex(state: any = initialState, action: any) {
  switch (action.type) {
    case BarthelIndex.LOADING:
      return Object.assign({}, state, {
        type: BarthelIndex.LOADING,
        Loading: true,
        LoadedCount: 0,
      });
    case BarthelIndex.FINISH:
      return Object.assign({}, state, {
        type: BarthelIndex.FINISH,
        Loading: false,
        LoadedCount: state.LoadedCount + 1,
      });
    case BarthelIndex.SUCCESS:
      return Object.assign({}, state, {
        type: BarthelIndex.SUCCESS,
        Loading: false,
        result: action.result,
      });
    case BarthelIndex.ERROR:
      return Object.assign({}, state, {
        type: BarthelIndex.SUCCESS,
        Loading: false,
        error: action?.error,
      });
    default:
      return state;
  }
}
