/** @jsx jsx */
// モフトレチェック・詳細.
import * as React from 'react';
import { jsx, css } from '@emotion/core';
import {
  MoffCheckV1ReportActionPayload,
  MoffCheckReportResult,
  WalkingAbilityEvaluation,
} from '../../constants/MoffCheck';
import * as TrainingConst from '../../constants/Training';
import * as MoffCheckConst from '../../constants/MoffCheck';
import * as MoffCheckModule from '../../utils/MoffCheckModule';
import * as images from '../../img/index';

const pin = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 13px;
  line-height: 14px;
  font-size: 9px;
  font-weight: 900;
  color: #e58e38;
  background-color: white;
  padding: 3px;
  white-space: pre-wrap;
  border-radius: 1px;
`;

const pinBox = css`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
`;

interface ScoreTableProps {
  data: MoffCheckV1ReportActionPayload['data'][0];
  title: MoffCheckV1ReportActionPayload['title'];
}

const ScoreTable: React.FC<ScoreTableProps> = ({ data, title }) => {
  const user = data.user;
  const gender = MoffCheckModule.getGender(user.user_gender);
  const second = data.month.second ? data.month.second : '-';
  const third = data.month.third ? data.month.third : '-';
  const months = [data.month.first, second, third];

  const checkArr = Object.entries<MoffCheckReportResult<number | WalkingAbilityEvaluation> | undefined>(data.check);
  // 歩行能力評価が存在する場合、TUG、BALANCEの次の行（3行目）に追加する
  if (data?.walkingAbility)
    checkArr.splice(2, 0, ['walking_ability', { ...data.walkingAbility, comparison: null, average: '' }]);
  if (checkArr.length < 4) {
    for (let i = checkArr.length; i < 4; i++) {
      checkArr.push([
        '',
        {
          first: null,
          second: null,
          third: null,
          comparison: null,
          average: '',
        },
      ]);
    }
  }

  const tugRecords = ['first', 'second', 'third'].map((key) => {
    const month: string = data.month[key] ?? '-';
    const rowElement =
      data.tugData[key] === null
        ? [month, '-', '-', '-', '-', '-', '-']
        : [
            month,
            String(data.tugData[key].stand_up),
            String(data.tugData[key].out),
            String(data.tugData[key].out_turn),
            String(data.tugData[key].in_time),
            String(data.tugData[key].in_turn),
            String(data.tugData[key].sit),
          ];
    return rowElement;
  });

  return (
    <React.Fragment>
      <table key="moffcheck3_2" className="moffcheck_score">
        <tbody>
          <tr className="moffcheck_score__inner" data-test="score-table-th">
            {/* タイトル */}
            <td className="table_left moffcheck_score__col">&nbsp;</td>
            {months.map((month, i) => {
              return <td key={`best_${i}`}>{month}</td>;
            })}
            <td>{data.average_title}</td>
          </tr>
          {checkArr.map(([key, value], i) => {
            const resultComparison = value?.comparison;
            const unit = MoffCheckModule.measurementUnit(key);
            const comparison = resultComparison ? `(${resultComparison.sign}${resultComparison.diff})` : '';
            const evaluation = resultComparison?.evaluation ?? null;

            return (
              <tr key={key} className="moffcheck_score__inner" data-test={`score-table-tr-${i}`}>
                <td className="table_left moffcheck_score__col">{title[key]}</td>
                <td>
                  {value?.first != null ? `${value.first}${unit}${comparison}` : key === '' ? '' : '-'}
                  {evaluation != null ? (
                    <img
                      src={images[`icon_${evaluation}`]}
                      className="arrow_icon"
                      alt="hoge"
                      data-test={`score-table-img-${i}`}
                    ></img>
                  ) : null}
                </td>
                <td>{value?.second != null ? `${value.second}${unit}` : key === '' ? '' : '-'}</td>
                <td>{value?.third != null ? `${value.third}${unit}` : key === '' ? '' : '-'}</td>
                <td>{key !== '' && value?.average ? `${gender} ${value.average}${unit}` : ''}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <img key="moffcheck4_1" src={images.head_tug} alt="hoge" />
      {/* TUGレコード */}
      <table key="moffcheck4_2" className="moffcheck_tug">
        <tbody>
          {tugRecords.map((row, num) => {
            return (
              <tr key={`tr_${num}`} className="moffcheck_tug__inner" data-test={`tug-record-tr-${num}`}>
                {row.map((item, i) => {
                  const className = i === 0 ? 'table_left' : 'table_right';
                  return (
                    <td key={`item_${num}_${i}`} className={className}>
                      {item}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
};

interface MoffCheckProps {
  reportRes: MoffCheckV1ReportActionPayload;
}
const MoffCheck: React.FC<MoffCheckProps> = ({ reportRes }) => {
  const institution = reportRes.institution;
  const title = reportRes.title;
  const data = reportRes.data[0];
  const user = data.user;
  const gender = MoffCheckModule.getGender(data.user.user_gender);

  const scoreGraphMonths = ['first', 'second', 'third']
    .map((key) => {
      if (data.scoreGraph[key]) {
        return {
          x: data.scoreGraph[key].x,
          y: data.scoreGraph[key].y - 1,
          month: MoffCheckModule.jpNotationMonth(data.month[key]),
        };
      }
      return null;
    })
    .filter((v) => v);

  return (
    <div key="moffcheckTop" className="moffcheck">
      <img key="moffcheck1_1" src={images.logo_moffcheck} className="moffcheck_logo" alt="モフトレ" />
      <p key="moffcheck1_2" className="moffcheck_name" data-test="institution-name">
        {institution.institution_name}
      </p>
      <img key="moffcheck2_1" src={images.head_user} alt="hoge" />
      <table key="moffcheck2_2" className="moffcheck_user">
        <tbody>
          <tr className="moffcheck_user__inner" data-test="user-table-tr">
            <td className="table_left user_name">{user.user_name}様</td>
            <td className="user_sex">{gender}</td>
            <td className="user_birthday">{MoffCheckModule.makeBirthday(user)}</td>
            <td className="user_old">{user.user_age}歳</td>
            <td>{TrainingConst.CARE_LEVELS[user.user_care_level]}</td>
          </tr>
        </tbody>
      </table>
      <img key="moffcheck3_1" src={images.head_score} alt="hoge" />
      <ScoreTable data={data} title={title} />
      <img key="moffcheck5_1" src={images.graph_head} alt="hoge" />
      <div key="moffcheck5_2" className="moffcheck_graph">
        <div className="moffcheck_graph__l">
          <img src={images.graph_second} alt="hoge" />
        </div>
        <div className="moffcheck_graph__r">
          <img src={images.graph_space} alt="hoge" />
          <table className="moffcheck_graph__table">
            <tbody>
              <tr className="moffcheck_graph__table_head">
                <td>&nbsp;</td>
                {MoffCheckConst.TUG_SECONDS.map((second, i) => {
                  return <td key={i}>{second}</td>;
                })}
              </tr>
              {MoffCheckConst.COLOR_TILE_POSITION.map((row, y) => {
                return (
                  <tr className="moffcheck_graph__table_body" key={y}>
                    {row.map((color, x) => {
                      if (x === 0) {
                        return <td key={`${y}_${x}`}>{color}</td>;
                      } else {
                        return (
                          <td key={`${y}_${x}`}>
                            <img src={images[`graph_body_${color}`]} alt="hoge" />
                            <div className="moffcheck_graph__pin_wrap" css={pinBox}>
                              {scoreGraphMonths.map((ele, i) => {
                                if (ele?.x === x && ele.y === y) {
                                  return (
                                    <div data-test={`score-graph-${i}-${y}-${x}`} css={pin}>
                                      {ele.month}
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <img key="moffcheck5_3" src={images.graph_foot} alt="hoge" />
    </div>
  );
};

export default MoffCheck;
