// ユーザー詳細.
import React, { FC, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import store from '../store/configureStore';

import * as reportActions from '../actions/Report';
import * as HeaderActions from '../actions/Header';
import * as FooterActions from '../actions/Footer';

import { MOFF_CHECK_COMPARE_LOGIC, MoffCheckV1SelectKeys } from '../constants/MoffCheck';
import * as Header from '../constants/Header';

import PageLoader from '../components/Atoms/PageLoader';
import MoffCheck from '../components/Organisms/MoffCheck';

type AllState = ReturnType<typeof store.getState>;

interface ParamType {
  userId: string;
  yearMonth: string;
  compareSelectMonth: MOFF_CHECK_COMPARE_LOGIC;
  items: string;
}

const MoffCheckContainer: FC = () => {
  const dispatch = useDispatch();
  const { userId, yearMonth, compareSelectMonth, items } = useParams<ParamType>();
  const reportGlobalState = useSelector((state: AllState) => state.report);
  const Items: MoffCheckV1SelectKeys[] = items === 'null' ? [] : (items.split('-') as MoffCheckV1SelectKeys[]);

  // 最初に一回ユーザー一覧取得のために通信.
  useEffect(() => {
    (async () => {
      document.title = 'モフトレチェック';
      dispatch(HeaderActions.setPage(Header.PRESS));
      dispatch(FooterActions.display('hidden'));
      const walkingAbilityIndex = Items.indexOf('walking_ability');
      const isWalkingAbility = walkingAbilityIndex !== -1 ? true : false;
      isWalkingAbility && Items.splice(walkingAbilityIndex, 1);
      if (compareSelectMonth === MOFF_CHECK_COMPARE_LOGIC.default) {
        // API呼び出し.
        dispatch(reportActions.getMoffCheckV1MeasurementThreeMonth(userId, yearMonth, Items, isWalkingAbility));
      } else if (compareSelectMonth === MOFF_CHECK_COMPARE_LOGIC.secondOption) {
        // API呼び出し.
        dispatch(reportActions.getMoffCheckV1MeasurementOldest(userId, yearMonth, Items, isWalkingAbility));
      } else {
        const selectMonths = yearMonth.split('_');
        // API呼び出し.
        dispatch(reportActions.getMoffCheckV1MeasurementFreeChoice(userId, selectMonths, Items, isWalkingAbility));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * CSSの描画.
   */
  const RenderCSS: React.FC = () => {
    return (
      <style key="moffcheckCSS" type="text/css">
        {/* 回数,トレーニング項目,カレンダーの行間 */}
        {'body { line-height: 1;background: none;}'}
        {/* 余白消す. */}
        {'#wrapper { padding: 0;border: 0;margin: 0;min-height: 0;}'}
        {'#wrapper .contentIn { padding: 0;border: 0;margin: 0;}'}
        {/* 縦印刷, 日付・title名ヘッダの余白 */}
        {'@media print{@page {size: 210mm 297mm;margin-top: 20px;}}'}
        {'@media print{#wrapper { margin-top: 0;} header { display: none;} '}
        {'.blue { background-color: blue;}'}

        {` body { -webkit-print-color-adjust: exact; ` +
          `font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', sans-serif; }`}
      </style>
    );
  };

  return (
    <Fragment>
      <RenderCSS />
      {reportGlobalState.Loading ? (
        <PageLoader />
      ) : reportGlobalState.result.moffCheckV1 == null ? (
        <p className="moffcheck">データが見つかりませんでした。チェック項目を選択し直してください。</p>
      ) : (
        <MoffCheck reportRes={reportGlobalState.result?.moffCheckV1} />
      )}
    </Fragment>
  );
};

export default MoffCheckContainer;
