import _ from 'lodash';

import { MOFF_TRANING, ADL_YOGA } from '../constants/Kiroku';

// 補足文言 ----------------------------------------
export const suggestWordsGroupSubject: any = {
  history: {
    title: '履歴',
    texts: [],
  },
  moffTre: {
    title: 'モフトレ',
    texts: _.map(MOFF_TRANING, (el: string) => ({ text: el })),
  },
  adlYoga: {
    title: 'ADL別ヨガ',
    texts: _.map(ADL_YOGA, (el: string) => ({ text: el })),
  },
};

export const suggestWordsGroupInstructor: any = {
  history: {
    title: '履歴',
    texts: [],
  },
};

export const setLocalStorageValueForSuggestWords = () => {
  suggestWordsGroupSubject.history.texts = [];
  suggestWordsGroupInstructor.history.texts = [];
  suggestWordsGroupSubject.history.texts = [
    ...suggestWordsGroupSubject.history.texts,
    ...JSON.parse(localStorage.getItem('subject') || '{"texts":[]}').texts,
  ];
  suggestWordsGroupInstructor.history.texts = [
    ...suggestWordsGroupInstructor.history.texts,
    ...JSON.parse(localStorage.getItem('instructor') || '{"texts":[]}').texts,
  ];

  return [suggestWordsGroupSubject, suggestWordsGroupInstructor];
};

// 補足文言 Material UI AutoComplete用 ----------------------------------------
export const suggestWordsGroupSubjectMUI: any = [
  ..._.map(MOFF_TRANING, (el: string) => ({ title: 'モフトレ', text: el })),
  ..._.map(ADL_YOGA, (el: string) => ({ title: 'ADL別ヨガ', text: el })),
];

export const setLocalStorageValueForSuggestWordsMUI = () => {
  const subjectHistoryData = _.map(
    JSON.parse(localStorage.getItem('subject') || '{"texts":[]}').texts,
    (el: { text: string }) => ({ title: '履歴', ...el }),
  );
  const instructorHistoryData = _.map(
    JSON.parse(localStorage.getItem('instructor') || '{"texts":[]}').texts,
    (el: { text: string }) => ({ title: '履歴', ...el }),
  );

  return [[...subjectHistoryData, ...suggestWordsGroupSubjectMUI], instructorHistoryData];
};
