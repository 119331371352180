import { Dispatch } from 'redux';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import { apiRequestFunc } from '../utils/apiUtil';
import * as Password from '../constants/Password';

export const changePasswordAPI = (oldPassword: string, newPassword: string) => {
  const uniqueLogicFunc: any = async () => {
    return Auth.currentAuthenticatedUser()
      .then(async (cognitoUser) => {
        return Auth.changePassword(cognitoUser, oldPassword, newPassword)
          .then(() => {
            return _.zipObject(['isChanged', 'error'], [true, '']);
          })
          .catch((error) => {
            alert(error.message);
            return _.zipObject(['isChanged', 'error'], [false, error.message]);
          });
      })
      .catch((error) => {
        alert(error.message);
        return _.zipObject(['isChanged', 'error'], [false, error.message]);
      });
  };
  return apiRequestFunc(
    Password.LOADING_CHANGE_PASSWORD,
    Password.SUCCESS_CHANGE_PASSWORD,
    Password.ERROR_CHANGE_PASSWORD,
    uniqueLogicFunc,
  );
};

export const forgotPasswordAPI = (username: string) => {
  const uniqueLogicFunc: any = async () => {
    return Auth.forgotPassword(username)
      .then(() => _.zipObject(['isChanged', 'error'], [true, '']))
      .catch((error) => {
        alert(error.message);
        return _.zipObject(['isChanged', 'error'], [false, error.message]);
      });
  };
  return apiRequestFunc(
    Password.LOADING_FORGOT_PASSWORD,
    Password.SUCCESS_FORGOT_PASSWORD,
    Password.ERROR_FORGOT_PASSWORD,
    uniqueLogicFunc,
  );
};

export const confirmPasswordAPI = (username: string, confirmationCode: string, newPassword: string) => {
  const uniqueLogicFunc: any = async () => {
    return Auth.forgotPasswordSubmit(username, confirmationCode, newPassword)
      .then(() => _.zipObject(['isChanged', 'error'], [true, '']))
      .catch((error) => {
        alert(error.message);
        return _.zipObject(['isChanged', 'error'], [false, error.message]);
      });
  };
  return apiRequestFunc(
    Password.LOADING_CONFIRM_PASSWORD,
    Password.SUCCESS_CONFIRM_PASSWORD,
    Password.ERROR_CONFIRM_PASSWORD,
    uniqueLogicFunc,
  );
};

export const confirmPassword = (
  username: string,
  confirmationCode: string,
  newPassword: string,
  requestFunc: Function,
  fetchedFunc: Function,
) => {
  return (dispatch: Dispatch) => {
    dispatch(requestFunc());

    Auth.forgotPasswordSubmit(username, confirmationCode, newPassword)
      .then(dispatch(fetchedFunc(true)))
      .catch((err) => {
        dispatch(fetchedFunc(false, err.message));
      });
  };
};
