/* 上部のあ行リスト */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { FC, useLayoutEffect, useEffect, useState } from 'react';

import * as UserListField from '../constants/UserListField';
import * as DateKanaList from '../constants/DateKanaList';
import KanaList from '../components/Molecules/KanaList';
import SearchInput from '../components/Molecules/SearchInput';

import { dispatchIdentifierToGTM } from '../utils/commonUtil';

const KanaListContainer: FC = () => {
  const [kanaSelected, setKanaSelected] = useState<DateKanaList.KANA>(DateKanaList.KANA.KANA_ALL);
  const [searchText, setSearchText] = useState('');

  // フィルター処理.
  const filter = () => {
    // userのオブジェクトの入ったhiddenのClass.
    let usersObject: HTMLCollectionOf<Element> | any = document.getElementsByClassName('user');

    // search枠.
    if (searchText) {
      // フィルターする(HTMLCollectionは配列に一回変換する必要あり).
      usersObject = [].slice.call(usersObject).filter(function (element: HTMLElement) {
        const parent: HTMLElement | null = element.parentElement;
        if (parent) {
          // 一旦全員消す(次の頭文字フィルターに委ねるため).
          parent.hidden = true;
        }

        const html: string = element.innerHTML;
        const row = JSON.parse(html);
        return Object.keys(row).some(function (key: string) {
          return String(row[key]).indexOf(searchText) > -1;
        });
      });
    }

    // ここから頭文字フィルター.
    let index = 0;
    for (const key in usersObject) {
      if (Object.prototype.hasOwnProperty.call(usersObject, key)) {
        // 1つのエレメント.
        const element: Element = usersObject[key];

        // stringfyで入ってるのでいったん変換.
        const kanaInitial: string = (JSON.parse(element.innerHTML) as UserListField.User).user_name_kana;

        // カナ名頭文字.
        const firstKana: string = kanaInitial.substring(0, 1);
        const parent: HTMLElement | null = element.parentElement;
        if (kanaSelected.word === '' || kanaSelected.word.search(firstKana) > -1) {
          if (parent) {
            // 居る人は表示する.
            parent.hidden = false;

            // 偶数は白/奇数グレー(cssがhidden要素をスキップしてくれないので上書き).
            const isEven: boolean = index % 2 === 0;
            [].forEach.call(
              parent.childNodes,
              (child: Node) => ((child as HTMLElement).style.background = isEven ? '#fff' : '#e8e4df'),
            );

            index++;
          }
        } else {
          if (parent) {
            // 該当しない人は消す.
            parent.hidden = true;
          }
        }
      }
    }
  };

  useEffect(() => {
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // フィルター処理.
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanaSelected, searchText]);

  const searchHeadChar = (kana: DateKanaList.KANA) => {
    setKanaSelected(kana);
    dispatchIdentifierToGTM('kana_filter');
  };

  const onFocusSearchInput = () => {
    dispatchIdentifierToGTM('user_search');
  };

  return (
    <React.Fragment>
      <SearchInput onChange={setSearchText} onFocus={onFocusSearchInput} placeholder="利用者検索" />
      <KanaList kanaSelected={kanaSelected} searchHeadChar={searchHeadChar} />
    </React.Fragment>
  );
};

export default KanaListContainer;
