// デフォルト.
import React, { FC } from 'react';
import BaseBrowser from './BaseBrowser';

import { User } from '../../../constants/UserListField';
import { ResponseData } from '../../../constants/LifeCheck';

interface DefaultProps {
  user: User;
  lifeCheck: ResponseData;
}

// Chrome.
const Default: FC<DefaultProps> = ({ user, lifeCheck }) => {
  return <BaseBrowser user={user} lifeCheck={lifeCheck} />;
};

export default Default;
