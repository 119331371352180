import React, { FC } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-ignore
// @ts-ignore
import { Route, Switch, Redirect } from 'react-router-dom';
import { PLAN_TYPE, ROUTE_PAGES } from 'constants/Menu';

interface RoutesProps {
  planType: keyof typeof PLAN_TYPE;
}

const Routes: FC<RoutesProps> = ({ planType }) => {
  const routePages = PLAN_TYPE[`${planType}`]?.routePages ?? PLAN_TYPE.Regular.routePages;
  return (
    <Switch>
      {/* プラン問わず必要なパス */}
      {/* メニュー画面 */}
      <Route exact path={ROUTE_PAGES.Menu.path} component={ROUTE_PAGES.Menu.component} />
      {/* モフトレパスワード変更画面 */}
      <Route exact path={ROUTE_PAGES.ChangePassword.path} component={ROUTE_PAGES.ChangePassword.component} />
      <Switch>
        {routePages.map((page: ROUTE_PAGES) => (
          <Route exact key={page.path} path={page.path} component={page.component} />
        ))}
        <Redirect to="/" />
      </Switch>
    </Switch>
  );
};

export default Routes;
