import Chart from 'chart.js';
import { GraphBackgroundColor } from '../constants/Press';
import { PressReportResponse } from '../constants/Report';

export class Graph {
  public lineChart1!: Chart;

  // コンストラクタ.
  constructor() {
    if (!(this instanceof Graph)) {
      return new Graph();
    }
  }

  /**
   * モフトレ通信用ロコモ予防トレーニングの円グラフの作成.
   *
   * @memberof Graph
   */
  insertDataCanvasPressPieChart = (
    canvasElement: HTMLCanvasElement,
    trainings: PressReportResponse['data'][0]['trainings'],
    width: number,
    height: number,
    animation = false,
  ) => {
    // Get canvas context
    const context1 = canvasElement.getContext('2d');
    if (context1 === null) {
      return;
    }

    context1.canvas.width = width;
    context1.canvas.height = height;

    const trainingCounts = trainings.map((training): number => {
      return training.count;
    });
    const labelName = trainings.map((training): string => {
      return training.name;
    });

    const data: Chart.ChartData = {
      labels: labelName,
      datasets: [
        {
          data: trainingCounts,
          // 円グラフの各色.
          backgroundColor: GraphBackgroundColor,
        },
      ],
    } as Chart.ChartData;

    const options: any = {
      responsive: true,
      legend: false,
    } as Chart.ChartOptions;

    if (animation === true) {
      options.animation = false;
    }

    this.lineChart1 = new Chart(context1, {
      type: 'pie',
      data: data,
      options: options,
    } as Chart.ChartConfiguration);
  };
}
