// パスワード変更時のアクションタイプ
export const LOADING_CHANGE_PASSWORD = 'LOADING_CHANGE_PASSWORD';
export const SUCCESS_CHANGE_PASSWORD = 'SUCCESS_CHANGE_PASSWORD';
export const ERROR_CHANGE_PASSWORD = 'ERROR_CHANGE_PASSWORD';

// パスワード忘れ時のアクションタイプ
export const LOADING_FORGOT_PASSWORD = 'LOADING_FORGOT_PASSWORD';
export const SUCCESS_FORGOT_PASSWORD = 'SUCCESS_FORGOT_PASSWORD';
export const ERROR_FORGOT_PASSWORD = 'ERROR_FORGOT_PASSWORD';

// パスワード確認時のアクションタイプ
export const LOADING_CONFIRM_PASSWORD = 'LOADING_CONFIRM_PASSWORD';
export const SUCCESS_CONFIRM_PASSWORD = 'SUCCESS_CONFIRM_PASSWORD';
export const ERROR_CONFIRM_PASSWORD = 'ERROR_CONFIRM_PASSWORD';

// password初期化時のアクションタイプ
export const PASSWORD_INITIALIZE = 'PASSWORD_INITIALIZE';
