import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import store from '../store/configureStore';

import * as literallyReportActions from '../actions/LiterallyReport';
import * as pdfCreateActions from '../actions/pdfCreate';

import UserDetail from './UserDetail';
import TrainingHeader from './TrainingHeader';
import PopupContainer from './Popup';

import ReportOutputMonthSelect from '../components/Organisms/ReportOutputMonthSelect';
import PageLoader from '../components/Atoms/PageLoader';

import { dispatchIdentifierToGTM } from '../utils/commonUtil';

import * as Header from '../constants/Header';
import { SCREEN_TYPE } from '../constants/Training';

type AllState = ReturnType<typeof store.getState>;

const LiterallyReportSelectMonthContainer: FC<RouteComponentProps<{ userId: string }>> = ({ match }) => {
  const dispatch = useDispatch();
  const moffAPIGlobalState = useSelector((state: AllState) => state.moffAPI);
  const pdfCreateGlobalState = useSelector((state: AllState) => state.pdfCreate);

  const userId = match.params.userId;
  const articleClassName = 'report';

  // 矢印ボタンによる月選択で選択されている月（日付）
  const [date, setDate] = useState(moment());

  // 月ボタンの表示月の配列を格納
  const [dispYearMonth, setDispYearMonth] = useState<string[]>([]);

  // 月ボタンを表示するために、指定月から遡って1年間の測定結果を取得
  const fetchTrainingAndEvaluationData = async (selectedDate: moment.Moment) => {
    dispatch(literallyReportActions.getLiterallyReportData(userId, selectedDate));
  };

  // 初期読み込み時に、今月基点の測定データを取得
  useEffect(() => {
    (async () => {
      await fetchTrainingAndEvaluationData(date);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 月ボタンの表示月を取得したのちキャッシュする
  useEffect(() => {
    if (moffAPIGlobalState.result.dispYearMonth) {
      setDispYearMonth(
        moffAPIGlobalState.result.dispYearMonth ? Object.keys(moffAPIGlobalState.result.dispYearMonth) : [],
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffAPIGlobalState.result.dispYearMonth]);

  // 月選択画面 矢印のアクション
  const minusMonthly = async () => {
    const lastMonth = date.subtract(1, 'months');
    setDate(lastMonth);
    await fetchTrainingAndEvaluationData(lastMonth);
    dispatchIdentifierToGTM('detail_page_change_month_literally');
  };

  // 月選択画面 矢印のアクション
  const plusMonthly = async () => {
    const nextMonth = date.add(1, 'months');
    setDate(nextMonth);
    await fetchTrainingAndEvaluationData(nextMonth);
    dispatchIdentifierToGTM('detail_page_change_month_literally');
  };

  const runAPILiterallyReport = async (yearMonth: string): Promise<void> => {
    if (moffAPIGlobalState.Loading) return;

    await dispatch(pdfCreateActions.createLiterallyPDF(yearMonth, [userId]));
    dispatchIdentifierToGTM('output_user_report_literally');
  };

  return (
    <>
      {moffAPIGlobalState.Loaded && moffAPIGlobalState.result.dispYearMonth ? null : <PageLoader />}
      {pdfCreateGlobalState.Loading && <PageLoader />}
      <PopupContainer />
      <article className={articleClassName}>
        <UserDetail userId={userId} headerType={Header.LITERALLY_REPORT_SELECT_MONTH} pageTitle="機能訓練レポート" />
        <TrainingHeader screenType={SCREEN_TYPE.LiterallyReportSelectMonth} />
        <>
          <ReportOutputMonthSelect
            dispYearMonth={dispYearMonth}
            date={date}
            selectAreaHeading="機能訓練レポート"
            minusMonthly={minusMonthly}
            plusMonthly={plusMonthly}
            monthButtonOnClick={runAPILiterallyReport}
          />
        </>
      </article>
    </>
  );
};

export default LiterallyReportSelectMonthContainer;
