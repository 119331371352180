// 機能訓練レポートのAction.
import { createMoffAPIInstance } from '../api/MoffAPI';

const [isMoffAPIError, moffAPIInstance, moffAPIErrorAction] = createMoffAPIInstance();

// 機能訓練レポート・月選択.
// 以下を複数同時.
// getUser(userId);
// x12
// getMonthlyData(1, userId, '2017-08'); LocomoとADL.
// getMonthlyData(2, userId, '2017-08');
export const getLiterallyReportData = (userId: string, selectedDate: moment.Moment, intervalTimeMs = 500) => {
  return isMoffAPIError
    ? moffAPIErrorAction
    : moffAPIInstance.getLiterallyReportDataAPI(userId, selectedDate, intervalTimeMs);
};
