// 興味関心チェックAPI.
import { AxiosInstance } from 'axios';
import * as _ from 'lodash';
import moment from 'moment';

import { MoffAPIActionType } from '../constants/MoffAPI';
import * as InterestCheck from '../constants/InterestCheck';
import { createAxiosInstance } from '../constants/AWS';
import { ResponseData } from '../constants/AppUser';

import { MoffAPI } from './MoffAPI';
import { getAppUserData } from './AppUser';
import { errorFunc, apiRequestFunc } from '../utils/apiUtil';
import { convertFormatTimeToDate } from '../utils/dateUtil';
import { waitTimeMs, isEmpty } from '../utils/commonUtil';
import { DATA_CHECK_INTERVAL } from '../constants/csvCreate';

export class InterestCheckAPI {
  /** インスタンス */
  private static _instance: InterestCheckAPI;
  private axiosInstance!: AxiosInstance;

  /** インスタンスの取得 */
  public static get instance(): InterestCheckAPI | null {
    if (!this._instance) {
      this._instance = new InterestCheckAPI();

      // .envの設定
      const API_BASE_URL = String(process.env.REACT_APP_INTEREST_CHECK_API_URL);
      const API_KEY = String(process.env.REACT_APP_INTEREST_CHECK_API_KEY);
      if (API_BASE_URL === 'undefined') {
        console.log('.envにREACT_APP_INTEREST_CHECK_API_URLの設定がありません.');
        return null;
      }
      if (API_KEY === 'undefined') {
        console.log('.envにREACT_APP_INTEREST_CHECK_API_KEYの設定がありません.');
        return null;
      }
      const axiosInstance = createAxiosInstance(API_BASE_URL, API_KEY);

      if (axiosInstance) {
        this._instance.axiosInstance = axiosInstance;
      } else {
        return null;
      }
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  // 件数取得.
  public async getRecordCountAPI(uniqueId: string) {
    const url = `/interests/users/${uniqueId}/records`;
    return this.axiosInstance.get(url);
  }

  /**
   * 興味関心チェックまたは居宅訪問の登録がユーザがあるか確認.
   * あれば件数を取り、最後の件数のシーケンスで日付取得.
   *
   * @param {string} userId
   * @param {Function} addDisplayDate
   * @returns {*}
   * @memberof AppUserAPI
   */
  public getInterestCheckLastDateAPI(userId: string, addDisplayDate: Function, intervalTimeMs = 500) {
    const uniqueLogicFunc = async () => {
      await waitTimeMs(intervalTimeMs);
      const appUser = await getAppUserData(userId, InterestCheck.FINISH);

      if (!appUser) {
        addDisplayDate({ user_id: userId, displayDate: '' });
        return null;
      } else {
        // 件数取得.
        const getRecordCountURL = `/interests/users/${appUser.data.unique_id}/records`;
        return this.axiosInstance
          .get(getRecordCountURL)
          .then((recordCountResponse: any) => {
            if (recordCountResponse.data.total === 0) {
              // elements[userId].innerText = 'ない';
              addDisplayDate({ user_id: userId, displayDate: '' });
              return recordCountResponse.data;
            } else {
              const sequence = recordCountResponse.data.total;
              // 指定されたシーケンスの情報から.
              const getRecordURL = `/interests/users/${appUser.data.unique_id}/records/${sequence}`;
              this.axiosInstance
                .get(getRecordURL)
                .then((getRecordResponse: any) => {
                  // 最新日
                  const updateDate = convertFormatTimeToDate(getRecordResponse.data.visited_day);
                  // 年月日の隙間の記号は"/"ではなく"-".
                  addDisplayDate({ user_id: userId, displayDate: updateDate });
                  return recordCountResponse.data;
                })
                .catch((error: any) => {
                  addDisplayDate({ user_id: userId, displayDate: '' });
                  return error;
                });
            }
          })
          .catch((error: any) => {
            addDisplayDate({ user_id: userId, displayDate: '' });
            return error;
          });
      }
    };
    return apiRequestFunc(InterestCheck.LOADING, InterestCheck.FINISH, InterestCheck.FINISH, uniqueLogicFunc);
  }

  /**
   * 指定の1件を取得.
   *
   * @param {string} userId
   * @param {string} uniqueId
   * @param {number} sequence
   * @returns {*}
   * @memberof InterestCheckAPI
   */
  public getRecordBySequenceAPI(userId: string, uniqueId: string, sequence: number) {
    const moff = MoffAPI.instance;
    if (moff === null) {
      return errorFunc(MoffAPIActionType.ERROR, '不明なエラーが発生しました.');
    }

    const uniqueLogicFunc: any = async () => {
      const userDataMoff = await moff.getUserFunc(userId).then((response) => response.data);
      const url = `/interests/users/${uniqueId}/records/${sequence}`;
      const interestCheckData = await this.axiosInstance.get(url).then((response) => response.data);
      return _.zipObject(['user', 'interestCheck'], [userDataMoff, interestCheckData]);
    };

    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  /**
   * 指定月に存在するデータを取得.
   *
   * @param {ResponseData[]} uniqueIds
   * @param {string} month
   * @returns {*}
   * @memberof InterestCheckAPI
   */
  public getRecordsByMonthAPI(uniqueIds: ResponseData[], month: string) {
    const uniqueLogicFunc = async () => {
      const fromDate = moment(month).startOf('month').format('YYYY-MM-DD');
      const toDate = moment(month).endOf('month').format('YYYY-MM-DD');
      const data = await Promise.all(
        uniqueIds.map(async (uniqueId: ResponseData, i) => {
          await waitTimeMs(i * DATA_CHECK_INTERVAL);
          const url = `/interests/users/${uniqueId.unique_id}/records/from/${fromDate}/to/${toDate}`;
          const data = await this.axiosInstance.get(url).then((response) => response.data);
          return {
            userId: uniqueId.app_user_id,
            data,
          };
        }),
      );
      const monthUsersRecords = data.filter((v) => !isEmpty(v.data));
      return {
        monthUsersRecords,
      };
    };

    return apiRequestFunc(InterestCheck.LOADING, InterestCheck.SUCCESS, InterestCheck.ERROR, uniqueLogicFunc);
  }
}

export const createInterestCheckAPIInstance: any = () => {
  // Moffインスタンスが存在しない場合、エラーアクションを返す
  return InterestCheckAPI.instance === null
    ? [true, null, errorFunc(MoffAPIActionType.ERROR, '不明なエラーが発生しました.')]
    : [false, InterestCheckAPI.instance, null];
};
