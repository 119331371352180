import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store/configureStore';

import * as PopupActions from '../actions/Popup';

import { POPUP_FUNCTION_NAMES } from '../constants/Popup';

import YouTube from '../components/Atoms/YouTube';

type AllState = ReturnType<typeof store.getState>;

export const PopupContainer: FC = () => {
  const dispatch = useDispatch();
  const popup = useSelector((state: AllState) => state.popup);

  const closePopup = () => dispatch(PopupActions.ClosePopup());
  return (
    <>
      {popup.pcPrint ? <YouTube youtube={POPUP_FUNCTION_NAMES.pcPrint} popupClose={() => closePopup()} /> : null}
      {popup.ADLLocomo ? <YouTube youtube={POPUP_FUNCTION_NAMES.adlLocomo} popupClose={() => closePopup()} /> : null}
      {popup.kiroku ? <YouTube youtube={POPUP_FUNCTION_NAMES.kiroku} popupClose={() => closePopup()} /> : null}
      {popup.press ? <YouTube youtube={POPUP_FUNCTION_NAMES.press} popupClose={() => closePopup()} /> : null}
      {popup.moffCheck ? <YouTube youtube={POPUP_FUNCTION_NAMES.moffCheck} popupClose={() => closePopup()} /> : null}
      {popup.literallyReport ? (
        <YouTube youtube={POPUP_FUNCTION_NAMES.literallyReport} popupClose={() => closePopup()} />
      ) : null}
      {popup.userSetting ? (
        <YouTube youtube={POPUP_FUNCTION_NAMES.userSetting} popupClose={() => closePopup()} />
      ) : null}
    </>
  );
};

export default PopupContainer;
