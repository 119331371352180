import * as Password from '../../constants/Password';

const initialState = {
  Loading: false,
  Loaded: true,
  err: '',
  result: false,
};

export default function password(state: any = initialState, action: any) {
  switch (action.type) {
    case Password.LOADING_CHANGE_PASSWORD:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
      });
    case Password.SUCCESS_CHANGE_PASSWORD:
      return Object.assign({}, state, {
        err: action.result.error,
        Loading: false,
        Loaded: true,
        result: action.result.isChanged,
      });
    case Password.ERROR_CHANGE_PASSWORD:
      return Object.assign({}, state, {
        err: action.error,
        Loading: false,
        Loaded: true,
        result: action.result,
      });
    case Password.LOADING_FORGOT_PASSWORD:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
      });
    case Password.SUCCESS_FORGOT_PASSWORD:
      return Object.assign({}, state, {
        err: action.result.error,
        Loading: false,
        Loaded: true,
        result: action.result.isChanged,
      });
    case Password.ERROR_FORGOT_PASSWORD:
      return Object.assign({}, state, {
        err: action.result.error,
        Loading: false,
        Loaded: true,
        result: action.result.isChanged,
      });
    case Password.LOADING_CONFIRM_PASSWORD:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
      });
    case Password.SUCCESS_CONFIRM_PASSWORD:
      return Object.assign({}, state, {
        err: action.result.error,
        Loading: false,
        Loaded: true,
        result: action.result.isChanged,
      });
    case Password.ERROR_CONFIRM_PASSWORD:
      return Object.assign({}, state, {
        err: action.result.error,
        Loading: false,
        Loaded: true,
        result: action.result.isChanged,
      });
    case Password.PASSWORD_INITIALIZE:
      return Object.assign({}, state, {
        err: '',
        Loading: false,
        Loaded: true,
        result: false,
      });
    default:
      return state;
  }
}
