import * as Auth from '../constants/Auth';
import * as AuthAPI from '../api/Auth';

/**
 * サインインアクション、Amplifyを使用したcognito認証
 *
 * @param {string} username
 * @param {string} password
 * @returns
 */
export const signIn = (username: string, password: string) => {
  return AuthAPI.signInAPI(username, password);
};

/**
 * セッションアクション、Amplifyを使用したcognito認証
 *
 * @returns
 */
export const getSession = () => {
  return AuthAPI.getSessionAPI();
};

/**
 * ログアウトアクション、Amplifyを使用したcognito認証
 *
 * @returns
 */
export const signOut = () => {
  return AuthAPI.signOutAPI();
};

/**
 * 初期化する.
 *
 * @returns
 */
export const initialize = () => {
  return {
    type: Auth.AUTH_INITIALIZE,
    result: null,
  };
};
