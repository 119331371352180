import React, { FC, memo, useState, useLayoutEffect, useMemo } from 'react';

import {
  MonthlyTrainingData,
  TrainingInfo,
  TOGGLE_POSITION,
  TRAINING_REPORT_NAME,
  SCREEN_TYPE,
  DATE_DISPLAY_TYPE,
  TRAINING_TABLE_PATTERN,
} from '../../../constants/Training';
import { isEmpty, divideArray, mapReturnObject, pick } from '../../../utils/commonUtil';
import { alignTrainingDict, divideTraining } from '../../../utils/locomoAdlModule';
import { getDay, diffDate } from '../../../utils/dateUtil';

import Table from '../Table';
import DateSelector from '../DateSelector';
import GeneralPrimaryButton from '../../Atoms/GeneralPrimaryButton';
import ToggleButtonThreePosition from '../../Atoms/ToggleButtonThreePosition';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useTrainingUI } from '../../../hooks/use-training-ui';

const useStyles = makeStyles({
  settingArea: {
    '@media print': {
      flexWrap: 'nowrap',
    },
  },
  toggleButton: {
    '@media print': {
      width: '50%',
    },
  },
  dateSelector: {
    '@media print': {
      width: '400px',
    },
  },
});

// レポート props.
interface MonthlyReportProps {
  date: moment.Moment;
  className: TRAINING_REPORT_NAME;
  history: MonthlyTrainingData;
  tablePattern: TRAINING_TABLE_PATTERN;
  togglePosition: TOGGLE_POSITION;
  changeState: (state: SCREEN_TYPE) => void;
  minusMonthly: (className: string) => void;
  plusMonthly: (className: string) => void;
  changeTogglePosition: (state: TOGGLE_POSITION) => void;
}

// レポート(endReport, startReport共通).
export const MonthlyReport: FC<MonthlyReportProps> = ({
  date,
  className,
  history,
  tablePattern,
  togglePosition = TOGGLE_POSITION.Left,
  changeState,
  minusMonthly,
  plusMonthly,
  changeTogglePosition,
}) => {
  const classes = useStyles();
  const { displayedTrainings, allTrainings } = useTrainingUI();

  const [trainingTitle, setTrainingTitle] = useState<TrainingInfo[]>([]);
  const [displayedTrainingIds, setDisplayedTrainingIds] = useState<string[]>([]);
  const [pickedHistory, setPickedHistory] = useState({});
  const [daysCount, setDaysCount] = useState<number>(0);

  useLayoutEffect(() => {
    const trainingTitle = allTrainings.filter((trainingInfo) => displayedTrainings.includes(trainingInfo.name));
    const displayedTrainingIds = trainingTitle.map((trainingInfo) => String(trainingInfo.id));
    const pickedHistory = mapReturnObject(Object.keys(history), (date) => ({
      [date]: pick(history[date], displayedTrainingIds),
    }));
    const daysCount = Object.keys(pickedHistory).map((date) => getDay(date))?.length;
    setTrainingTitle(trainingTitle);
    setDisplayedTrainingIds(displayedTrainingIds);
    setPickedHistory(pickedHistory);
    setDaysCount(daysCount);
    // eslint-disable-next-line
  }, [history, displayedTrainings]);

  const historyMonthlyDateTables = useMemo(
    () =>
      divideArray(
        Object.keys(pickedHistory)
          .map((date) => getDay(date))
          .sort((a, b) => diffDate(a, b)),
        tablePattern.colCount,
      ),
    // eslint-disable-next-line
    [pickedHistory, tablePattern],
  );
  const historyMonthlyTables = useMemo(() => {
    const alignedTrainingDict = alignTrainingDict(pickedHistory, 'count');
    const trainingDate = Object.keys(pickedHistory)
      .map((date) => date)
      .sort((a, b) => diffDate(a, b));
    const dividedTrainingDate = divideArray(trainingDate, tablePattern.colCount);
    return divideTraining(
      dividedTrainingDate,
      alignedTrainingDict,
      displayedTrainingIds,
      tablePattern.colCount,
      tablePattern.tableCount,
    );
    // eslint-disable-next-line
  }, [pickedHistory, tablePattern]);

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent={{
          xs: 'center',
          sm: tablePattern === TRAINING_TABLE_PATTERN.tight ? 'center' : 'space-between',
        }}
        data-test="monthly-header"
        mb="20px"
        className={classes.settingArea}
      >
        <Box
          display="flex"
          justifyContent={{
            xs: 'center',
            sm: tablePattern === TRAINING_TABLE_PATTERN.tight ? 'center' : 'space-between',
          }}
          flexWrap="wrap"
          width="100%"
          className={classes.toggleButton}
        >
          <Box display="flex" justifyContent="center" mb="20px" width="100%">
            <ToggleButtonThreePosition
              togglePosition={togglePosition}
              changeTogglePosition={changeTogglePosition}
              leftText="両側"
              rightText="右"
              centerText="左"
              customCss="width: 80px; height: 50px;"
            />
          </Box>
        </Box>
        {className === TRAINING_REPORT_NAME.CompareLeft ? (
          <Box mb={{ xs: '30px', sm: '0' }} width={{ xs: '240px', sm: '65px' }}>
            <GeneralPrimaryButton
              txt="終了"
              setCss={'width: 100%; height: 50px; white-space:nowrap;'}
              onClick={() => changeState(SCREEN_TYPE.Detail)}
            />
          </Box>
        ) : null}
        {className === TRAINING_REPORT_NAME.Detail ? (
          <Box mb={{ xs: '30px', sm: '0' }} width={{ xs: '240px', sm: '158px' }}>
            <GeneralPrimaryButton
              txt="比較する"
              setCss={`width: 100%; height: 50px;`}
              onClick={() => changeState(SCREEN_TYPE.Compare)}
            />
          </Box>
        ) : null}
        <Box width={{ xs: '315px', sm: '400px' }} className={classes.dateSelector}>
          <DateSelector
            date={date}
            className={className}
            dateDisplayType={DATE_DISPLAY_TYPE.Month}
            minusDate={minusMonthly}
            plusDate={plusMonthly}
          />
        </Box>
      </Box>
      <Box data-test="monthly-body">
        {isEmpty(history) ? (
          <Box>
            <p>この月はデータがありません。</p>
          </Box>
        ) : (
          <Box>
            {
              // 1st to 8th.
              [...Array(tablePattern.tableCount)].map((v, i: number) => {
                const isDisplayed = daysCount > tablePattern.threshold[i].dayCount;
                return (
                  isDisplayed && (
                    <Table
                      key={`MonthlyHistoryCompareTable${i}`}
                      prefix={tablePattern.threshold[i].prefix}
                      myNo={i}
                      historyMonthlyDateTable={historyMonthlyDateTables[i]}
                      historyMonthlyTable={historyMonthlyTables[i]}
                      tablePattern={tablePattern}
                      allTrainings={trainingTitle}
                    />
                  )
                );
              })
            }
          </Box>
        )}
      </Box>
    </>
  );
};

export default memo(MonthlyReport, (prevProps, nextProps) => {
  if (prevProps.date.format('YYYY-MM') !== nextProps.date.format('YYYY-MM')) {
    return true;
  } else {
    return false;
  }
});
