// import React, { FC, useEffect, useState, useCallback } from 'react';
import React, { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, MenuItem, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import GeneralPrimaryInput from '../Atoms/GeneralPrimaryInput';
import GeneralPrimarySelectBox from '../Atoms/GeneralPrimarySelectBox';
import Enumerable from 'linq';
import GeneralPrimaryGhostButton from '../Atoms/GeneralPrimaryGhostButton';
import { User } from '../../constants/UserListField';
import {
  BasicInfoFormat,
  PlanFormat,
  Institution,
  BundleMaster,
  ICF,
  Support,
  CARE_LEVELS,
} from '../../constants/MoffRaku';
// import AutocompleteComboBoxICF from '../Atoms/AutoCompleteComboBoxICF';
// import AutocompleteComboBoxProgram from '../Atoms/AutoCompleteComboBoxProgram';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import GeneralPrimaryTextArea from '../Atoms/GeneralPrimaryTextArea';

const useStyles = makeStyles(() => ({
  report: {
    paddingBottom: '50px',
    background: '#fff',
    borderRadius: '10px',
    boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
  },
  hideable: {
    padding: '25px 30px 25px 20px',
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#7c644e',
    borderRadius: '10px 10px 0 0',
  },
  select: {
    width: '70px',
    height: '30px',
    fontSize: '16px',
  },
}));

const backToMoffRakuList = (userId: string) => {
  window.location.href = `/moff_raku/${userId}`;
};

interface UserSettingFormProps {
  userId: string;
  planId: string;
  user: User;
  institution: Institution;
  basicInfo: BasicInfoFormat;
  bundle: BundleMaster[];
  icfactivity: ICF[];
  icffunction: ICF[];
  icfparticipation: ICF[];
  support: Support[];
  plan: PlanFormat;
  setPlan: (plan: PlanFormat) => void;
  postPlan: (plan: PlanFormat) => void;
  putPlan: (plan: PlanFormat) => void;
  getPlanFile: (plan: PlanFormat) => void;
  deletePlan: (userId: string, planId: string) => void;
}

const MoffRakuForm: FC<UserSettingFormProps> = ({
  userId,
  planId,
  user,
  institution,
  basicInfo,
  // master
  bundle,
  icfactivity,
  icffunction,
  icfparticipation,
  support,
  plan,
  setPlan,
  postPlan,
  putPlan,
  getPlanFile,
  deletePlan,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
  };

  const thisYearStr = new Date().getFullYear().toString();
  const thisMonthStr = (new Date().getMonth() + 1).toString();
  const thisDayStr = new Date().getDate().toString();

  const [userName, setUserName] = useState('');
  const [trainingList, setTrainingList] = useState({});
  const [itemList, setItemList] = useState({});
  const [patternList, setPatternList] = useState({});

  //1介護度
  const [careLevel, setCareLevel] = useState('');
  //2項目リスト
  const [training, setTraining] = useState('');
  //3訓練種類
  const [item, setItem] = useState('');
  //4パターン
  const [pattern, setPattern] = useState('');

  const [note, setNote] = useState('');

  const [shortGoalStartYear, setShortGoalStartYear] = useState(thisYearStr);
  const [shortGoalStartMonth, setShortGoalStartMonth] = useState(thisMonthStr);
  const [shortGoalFinishYear, setShortGoalFinishYear] = useState(thisYearStr);
  const [shortGoalFinishMonth, setShortGoalFinishMonth] = useState(thisMonthStr);
  const [shortGoalAchievement, setShortGoalAchievement] = useState('未達成');
  const [shortGoalFunction, setShortGoalFunction] = useState<string | null>('');
  const [inputShortGoalFunction, setInputShortGoalFunction] = useState('');
  const [shortGoalFunctionForPlan, setShortGoalFunctionForPlan] = useState('');
  const [shortGoalActivity, setShortGoalActivity] = useState<string | null>('');
  const [inputShortGoalActivity, setInputShortGoalActivity] = useState('');
  const [shortGoalActivityForPlan, setShortGoalActivityForPlan] = useState('');
  const [shortGoalParticipation, setShortGoalParticipation] = useState<string | null>('');
  const [inputShortGoalParticipation, setInputShortGoalParticipation] = useState('');
  const [shortGoalParticipationForPlan, setShortGoalParticipationForPlan] = useState('');
  const [shortGoalFunctionICF, setShortGoalFunctionICF] = useState('');
  const [shortGoalActivityICF, setShortGoalActivityICF] = useState('');
  const [shortGoalParticipationICF, setShortGoalParticipationICF] = useState('');

  const [longGoalStartYear, setLongGoalStartYear] = useState(thisYearStr);
  const [longGoalStartMonth, setLongGoalStartMonth] = useState(thisMonthStr);
  const [longGoalFinishYear, setLongGoalFinishYear] = useState(thisYearStr);
  const [longGoalFinishMonth, setLongGoalFinishMonth] = useState(thisMonthStr);
  const [longGoalAchievement, setLongGoalAchievement] = useState('未達成');
  const [longGoalFunction, setLongGoalFunction] = useState<string | null>('');
  const [inputLongGoalFunction, setInputLongGoalFunction] = useState('');
  const [longGoalFunctionForPlan, setLongGoalFunctionForPlan] = useState('');
  const [longGoalActivity, setLongGoalActivity] = useState<string | null>('');
  const [inputLongGoalActivity, setInputLongGoalActivity] = useState('');
  const [longGoalActivityForPlan, setLongGoalActivityForPlan] = useState('');
  const [longGoalParticipation, setLongGoalParticipation] = useState<string | null>('');
  const [inputLongGoalParticipation, setInputLongGoalParticipation] = useState('');
  const [longGoalParticipationForPlan, setLongGoalParticipationForPlan] = useState('');
  const [longGoalFunctionICF, setLongGoalFunctionICF] = useState('');
  const [longGoalActivityICF, setLongGoalActivityICF] = useState('');
  const [longGoalParticipationICF, setLongGoalParticipationICF] = useState('');

  //プログラム内容
  const [program1, setProgram1] = useState<string | null>('');
  const [inputProgram1, setInputProgram1] = useState('');
  const [program1ForPlan, setProgram1ForPlan] = useState('');
  const [program2, setProgram2] = useState<string | null>('');
  const [inputProgram2, setInputProgram2] = useState('');
  const [program2ForPlan, setProgram2ForPlan] = useState('');
  const [program3, setProgram3] = useState<string | null>('');
  const [inputProgram3, setInputProgram3] = useState('');
  const [program3ForPlan, setProgram3ForPlan] = useState('');
  const [program4, setProgram4] = useState<string | null>('');
  const [inputProgram4, setInputProgram4] = useState('');
  const [program4ForPlan, setProgram4ForPlan] = useState('');

  const [programSupport1, setProgramSupport1] = useState('');
  const [programSupport2, setProgramSupport2] = useState('');
  const [programSupport3, setProgramSupport3] = useState('');
  const [programSupport4, setProgramSupport4] = useState('');

  const [programNote1, setProgramNote1] = useState('');
  const [programNote2, setProgramNote2] = useState('');
  const [programNote3, setProgramNote3] = useState('');
  const [programNote4, setProgramNote4] = useState('');

  const [programFrequency1, setProgramFrequency1] = useState('週1回');
  const [programFrequency2, setProgramFrequency2] = useState('週1回');
  const [programFrequency3, setProgramFrequency3] = useState('週1回');
  const [programFrequency4, setProgramFrequency4] = useState('週1回');

  const [programTime1, setProgramTime1] = useState('');
  const [programTime2, setProgramTime2] = useState('');
  const [programTime3, setProgramTime3] = useState('');
  const [programTime4, setProgramTime4] = useState('');
  const [programPractitioner1, setProgramPractitioner1] = useState('');
  const [programPractitioner2, setProgramPractitioner2] = useState('');
  const [programPractitioner3, setProgramPractitioner3] = useState('');
  const [programPractitioner4, setProgramPractitioner4] = useState('');

  // サービス時間以外に実施すること: outside_of_service
  const [outsideOfService, setOutsideOfService] = useState('');
  // 特記事項: special_remarks
  const [specialRemarks, setSpecialRemarks] = useState('');
  // 変化: change
  const [change, setChange] = useState('');
  // 課題とその要因: issue_factor
  const [issueFactor, setIssueFactor] = useState('');

  const [creationYear, setCreationYear] = useState(thisYearStr);
  const [creationMonth, setCreationMonth] = useState(thisMonthStr);
  const [creationDay, setCreationDay] = useState(thisDayStr);
  const [lastCreationYear, setLastCreationYear] = useState(thisYearStr);
  const [lastCreationMonth, setLastCreationMonth] = useState(thisMonthStr);
  const [lastCreationDay, setLastCreationDay] = useState(thisDayStr);
  const [firstCreationYear, setFirstCreationYear] = useState(thisYearStr);
  const [firstCreationMonth, setFirstCreationMonth] = useState(thisMonthStr);
  const [firstCreationDay, setFirstCreationDay] = useState(thisDayStr);

  // 社会参加の状況: social_participation
  const [socialParticipation, setSocialParticipation] = useState('');
  // 居宅の環境: residence_environment
  const [residenceEnvironment, setResidenceEnvironment] = useState('');

  //------------------------------------------------

  const convertDot = (str: any) => {
    return str ? str.replace(/･/g, '・') : '';
  };

  const convertToHalfWidth = (str: string): string => {
    const fullWidthChars =
      '０１２３４５６７８９ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚ';
    const halfWidthChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

    const charMap: Record<string, string> = {};
    for (let i = 0; i < fullWidthChars.length; i++) {
      charMap[fullWidthChars[i]] = halfWidthChars[i];
    }
    return str
      .split('')
      .map((char) => charMap[char] || char)
      .join('');
  };

  const dispCreatedAt = (createdAt: string) => {
    if (createdAt === undefined) return '';
    const dispStr = createdAt.substr(0, 10);
    return dispStr;
  };

  const dispAchievement = (
    achievement: string,
    id: string,
    setFunction: (value: ((prevState: string) => string) | string) => void,
  ) => {
    if (achievement === undefined || achievement === '') achievement = '未達成';
    return (
      <select
        id={id}
        name={id}
        value={achievement}
        onChange={(event) => {
          setFunction(event.target.value);
        }}
        className={classes.select}
      >
        <option value="未達成">未達成</option>
        <option value="一部">一部</option>
        <option value="達成">達成</option>
      </select>
    );
  };

  const dispFrequency = (
    frequency: string,
    id: string,
    setFunction: (value: ((prevState: string) => string) | string) => void,
  ) => {
    if (frequency === undefined || frequency === '') frequency = '週1回';
    return (
      <select
        id={id}
        name={id}
        value={frequency}
        className={classes.select}
        onChange={(event) => {
          setFunction(event.target.value);
        }}
      >
        <option value="週1回">週1回</option>
        <option value="週2回">週2回</option>
        <option value="週3回">週3回</option>
        <option value="週4回">週4回</option>
        <option value="週5回">週5回</option>
        <option value="週6回">週6回</option>
        <option value="週7回">週7回</option>
        <option value="月1回">月1回</option>
        <option value="月2回">月2回</option>
        <option value="月3回">月3回</option>
      </select>
    );
  };

  // 令和のselect-option選択肢
  const dispReiwaSelect = (
    argYear: string,
    id: string,
    setFunction: (value: ((prevState: string) => string) | string) => void,
  ) => {
    const thisYear: number = new Date().getFullYear();
    let defaultYear = thisYear;
    const data = [];
    // 令和 今年と前後5年
    for (let year = thisYear - 2; year <= thisYear + 2; year++) {
      // 表示用に、西暦から令和に変換 (西暦-2018)
      data.push({ value: year, label: year - 2018 });
      if (Number(argYear) === year) {
        defaultYear = year;
      }
    }
    const options = data.map((d) => {
      return (
        <option value={d.value} key={d.value}>
          {d.label}
        </option>
      );
    });
    return (
      <select
        id={id}
        name={id}
        value={defaultYear}
        onChange={(event) => {
          setFunction(event.target.value);
        }}
        className={classes.select}
      >
        {options}
      </select>
    );
  };

  // 月のselect-option選択肢
  const dispMonthSelect = (
    argMonth: string,
    id: string,
    setFunction: (value: ((prevState: string) => string) | string) => void,
  ) => {
    const thisMonth: number = new Date().getMonth() + 1;
    let defaultMonth = thisMonth;
    const data = [];
    for (let month = 1; month <= 12; month++) {
      data.push({ value: month, label: month });
      if (Number(argMonth) === month) {
        defaultMonth = month;
      }
    }
    const options = data.map((d) => {
      return (
        <option value={d.value} key={d.value}>
          {d.label}
        </option>
      );
    });
    return (
      <select
        id={id}
        name={id}
        value={defaultMonth}
        onChange={(event) => {
          setFunction(event.target.value);
        }}
        className={classes.select}
      >
        {options}
      </select>
    );
  };

  // 日のselect-option選択肢
  const dispDayOptions = (
    argDay: string,
    id: string,
    setFunction: (value: ((prevState: string) => string) | string) => void,
  ) => {
    const thisDay: number = new Date().getDate();
    let defaultDay = thisDay;
    const data = [];
    for (let day = 1; day <= 31; day++) {
      data.push({ value: day, label: day });
      if (Number(argDay) === day) {
        defaultDay = day;
      }
    }
    const options = data.map((d) => {
      return (
        <option value={d.value} key={d.value}>
          {d.label}
        </option>
      );
    });
    return (
      <select
        id={id}
        name={id}
        value={defaultDay}
        onChange={(event) => {
          setFunction(event.target.value);
        }}
        className={classes.select}
      >
        {options}
      </select>
    );
  };

  const changedBySelectedPattern = (patternVal: string) => {
    // 選択1~4に基づきplanにセットする
    const key = careLevel + training + item + patternVal;
    for (const itemKey in bundle) {
      for (const itemKey2 in bundle[itemKey]) {
        const item = bundle[itemKey][itemKey2];
        if (key === item['id']) {
          setShortGoalFunction(
            item['short_goal_function'] === undefined ? shortGoalFunction : item['short_goal_function'],
          );
          setShortGoalActivity(
            item['short_goal_activity'] === undefined ? shortGoalActivity : item['short_goal_activity'],
          );
          setShortGoalParticipation(
            item['short_goal_participation'] === undefined ? shortGoalParticipation : item['short_goal_participation'],
          );
          setLongGoalFunction(item['long_goal_function'] === undefined ? longGoalFunction : item['long_goal_function']);
          setLongGoalActivity(item['long_goal_activity'] === undefined ? longGoalActivity : item['long_goal_activity']);
          setLongGoalParticipation(
            item['long_goal_participation'] === undefined ? longGoalParticipation : item['long_goal_participation'],
          );
          setShortGoalFunctionICF(
            item['short_goal_function_icf'] === undefined ? shortGoalFunctionICF : item['short_goal_function_icf'],
          );
          setShortGoalActivityICF(
            item['short_goal_activity_icf'] === undefined ? shortGoalActivityICF : item['short_goal_activity_icf'],
          );
          setShortGoalParticipationICF(
            item['short_goal_participation_icf'] === undefined
              ? shortGoalParticipationICF
              : item['short_goal_participation_icf'],
          );
          setLongGoalFunctionICF(
            item['long_goal_function_icf'] === undefined ? longGoalFunctionICF : item['long_goal_function_icf'],
          );
          setLongGoalActivityICF(
            item['long_goal_activity_icf'] === undefined ? longGoalActivityICF : item['long_goal_activity_icf'],
          );
          setLongGoalParticipationICF(
            item['long_goal_participation_icf'] === undefined
              ? longGoalParticipationICF
              : item['long_goal_participation_icf'],
          );
          setProgram1(item['program_1'] === undefined ? '' : item['program_1']);
          setProgram2(item['program_2'] === undefined ? '' : item['program_2']);
          setProgram3(item['program_3'] === undefined ? '' : item['program_3']);
          setProgram4(item['program_4'] === undefined ? '' : item['program_4']);
          setProgramSupport1(item['program_support_1'] === undefined ? '' : item['program_support_1']);
          setProgramSupport2(item['program_support_2'] === undefined ? '' : item['program_support_2']);
          setProgramSupport3(item['program_support_3'] === undefined ? '' : item['program_support_3']);
          setProgramSupport4(item['program_support_4'] === undefined ? '' : item['program_support_4']);
          setProgramPractitioner1(item['program_practitioner_1'] === undefined ? '' : item['program_practitioner_1']);
          setProgramPractitioner2(item['program_practitioner_2'] === undefined ? '' : item['program_practitioner_2']);
          setProgramPractitioner3(item['program_practitioner_3'] === undefined ? '' : item['program_practitioner_3']);
          setProgramPractitioner4(item['program_practitioner_4'] === undefined ? '' : item['program_practitioner_4']);
          setProgramNote1(item['program_note_1'] === undefined ? '' : item['program_note_1']);
          setProgramNote2(item['program_note_2'] === undefined ? '' : item['program_note_2']);
          setProgramNote3(item['program_note_3'] === undefined ? '' : item['program_note_3']);
          setProgramNote4(item['program_note_4'] === undefined ? '' : item['program_note_4']);
          setProgramTime1(item['program_time_1'] === undefined ? '' : item['program_time_1']);
          setProgramTime2(item['program_time_2'] === undefined ? '' : item['program_time_2']);
          setProgramTime3(item['program_time_3'] === undefined ? '' : item['program_time_3']);
          setProgramTime4(item['program_time_4'] === undefined ? '' : item['program_time_4']);

          // 2024/01 特記事項にセットしてたものは機能訓練実施上の留意事項に変更
          setNote(item['special_remarks'] === undefined ? '' : item['special_remarks']);
          setOutsideOfService(item['outside_of_service'] === undefined ? '' : item['outside_of_service']);
        }
      }
    }
  };

  const setToPlan = () => {
    // console.log('savePlan:', plan);
    plan['care_level'] = careLevel;
    plan['training'] = training;
    plan['item'] = item;
    plan['pattern'] = pattern;

    plan['note'] = note;

    plan['short_goal_start_year'] = shortGoalStartYear;
    plan['short_goal_start_month'] = shortGoalStartMonth;
    plan['short_goal_finish_year'] = shortGoalFinishYear;
    plan['short_goal_finish_month'] = shortGoalFinishMonth;

    plan['short_goal_achievement'] = shortGoalAchievement;

    plan['short_goal_function'] = shortGoalFunctionForPlan;
    plan['short_goal_function_icf'] = shortGoalFunctionICF;
    plan['short_goal_activity'] = shortGoalActivityForPlan;
    plan['short_goal_activity_icf'] = shortGoalActivityICF;
    plan['short_goal_participation'] = shortGoalParticipationForPlan;
    plan['short_goal_participation_icf'] = shortGoalParticipationICF;

    plan['long_goal_start_year'] = longGoalStartYear;
    plan['long_goal_start_month'] = longGoalStartMonth;
    plan['long_goal_finish_year'] = longGoalFinishYear;
    plan['long_goal_finish_month'] = longGoalFinishMonth;

    plan['long_goal_achievement'] = longGoalAchievement;

    plan['long_goal_function'] = longGoalFunctionForPlan;
    plan['long_goal_function_icf'] = longGoalFunctionICF;
    plan['long_goal_activity'] = longGoalActivityForPlan;
    plan['long_goal_activity_icf'] = longGoalActivityICF;
    plan['long_goal_participation'] = longGoalParticipationForPlan;
    plan['long_goal_participation_icf'] = longGoalParticipationICF;

    plan['program_1'] = program1ForPlan;
    plan['program_support_1'] = programSupport1;
    plan['program_note_1'] = programNote1;
    plan['program_frequency_1'] = programFrequency1;
    plan['program_time_1'] = programTime1;
    plan['program_practitioner_1'] = programPractitioner1;

    plan['program_2'] = program2ForPlan;
    plan['program_support_2'] = programSupport2;
    plan['program_note_2'] = programNote2;
    plan['program_frequency_2'] = programFrequency2;
    plan['program_time_2'] = programTime2;
    plan['program_practitioner_2'] = programPractitioner2;

    plan['program_3'] = program3ForPlan;
    plan['program_support_3'] = programSupport3;
    plan['program_note_3'] = programNote3;
    plan['program_frequency_3'] = programFrequency3;
    plan['program_time_3'] = programTime3;
    plan['program_practitioner_3'] = programPractitioner3;

    plan['program_4'] = program4ForPlan;
    plan['program_support_4'] = programSupport4;
    plan['program_note_4'] = programNote4;
    plan['program_frequency_4'] = programFrequency4;
    plan['program_time_4'] = programTime4;
    plan['program_practitioner_4'] = programPractitioner4;

    plan['outside_of_service'] = outsideOfService;
    plan['special_remarks'] = specialRemarks;

    plan['change'] = change;
    plan['issue_factor'] = issueFactor;

    plan['creation_year'] = creationYear;
    plan['creation_month'] = creationMonth;
    plan['creation_day'] = creationDay;

    plan['last_creation_year'] = lastCreationYear;
    plan['last_creation_month'] = lastCreationMonth;
    plan['last_creation_day'] = lastCreationDay;

    plan['first_creation_year'] = firstCreationYear;
    plan['first_creation_month'] = firstCreationMonth;
    plan['first_creation_day'] = firstCreationDay;

    plan['social_participation'] = socialParticipation;
    plan['residence_environment'] = residenceEnvironment;
  };

  const savePlan = () => {
    setToPlan();
    // console.log('savePlan:', plan);

    // TODO ここを開発中はコメントアウト
    if (planId) {
      putPlan(plan);
    } else {
      postPlan(plan);
    }
  };

  const duplicatePlan = () => {
    setToPlan();
    if (planId) {
      postPlan(plan);
    }
  };

  const delPlan = () => {
    deletePlan(userId, planId);
  };

  const exportPlanToExcel = () => {
    setToPlan();
    getPlanFile(plan);
  };

  //----------------------------------------------------------------------
  // 初回読み込み
  useEffect(() => {
    if (plan.pattern === undefined) {
      // console.log('planが空');
      return;
    }
    // console.log('useEffect[plan]', plan);
    // planの値をstateにセット
    // 画面の操作変更が反映されならないように stateの値が空の条件を付け加える
    // 1~4選択肢
    if (plan.care_level !== undefined && careLevel === '') {
      setCareLevel(plan.care_level);
    }
    if (plan.training !== undefined && training === '') {
      setTraining(plan.training);
    }
    if (plan.item !== undefined && item === '') {
      setItem(plan.item);
    }
    if (plan.pattern !== undefined && pattern === '') {
      setPattern(plan.pattern);
    }
    // 特記事項
    if (plan.note !== undefined && note === '') {
      setNote(plan.note);
    }
    // 短期目標
    if (plan.short_goal_start_year !== undefined) {
      setShortGoalStartYear(plan.short_goal_start_year);
    }
    if (plan.short_goal_start_month !== undefined) {
      setShortGoalStartMonth(plan.short_goal_start_month);
    }
    if (plan.short_goal_finish_year !== undefined) {
      setShortGoalFinishYear(plan.short_goal_finish_year);
    }
    if (plan.short_goal_finish_month !== undefined) {
      setShortGoalFinishMonth(plan.short_goal_finish_month);
    }
    if (plan.short_goal_achievement !== undefined) {
      setShortGoalAchievement(plan.short_goal_achievement);
    }
    if (plan.short_goal_function !== undefined && shortGoalFunction === '') {
      setShortGoalFunction(plan.short_goal_function);
    }
    if (plan.short_goal_activity !== undefined && shortGoalActivity === '') {
      setShortGoalActivity(plan.short_goal_activity);
    }
    if (plan.short_goal_participation !== undefined && shortGoalParticipation === '') {
      setShortGoalParticipation(plan.short_goal_participation);
    }
    if (plan.short_goal_function_icf !== undefined && shortGoalFunctionICF === '') {
      setShortGoalFunctionICF(plan.short_goal_function_icf);
    }
    if (plan.short_goal_activity_icf !== undefined && shortGoalActivityICF === '') {
      setShortGoalActivityICF(plan.short_goal_activity_icf);
    }
    if (plan.short_goal_participation_icf !== undefined && shortGoalParticipationICF === '') {
      setShortGoalParticipationICF(plan.short_goal_participation_icf);
    }

    // 長期目標
    if (plan.long_goal_start_year !== undefined) {
      setLongGoalStartYear(plan.long_goal_start_year);
    }
    if (plan.long_goal_start_month !== undefined) {
      setLongGoalStartMonth(plan.long_goal_start_month);
    }
    if (plan.long_goal_finish_year !== undefined) {
      setLongGoalFinishYear(plan.long_goal_finish_year);
    }
    if (plan.long_goal_finish_month !== undefined) {
      setLongGoalFinishMonth(plan.long_goal_finish_month);
    }
    if (plan.long_goal_achievement !== undefined) {
      setLongGoalAchievement(plan.long_goal_achievement);
    }
    if (plan.long_goal_function !== undefined && longGoalFunction === '') {
      setLongGoalFunction(plan.long_goal_function);
    }
    if (plan.long_goal_activity !== undefined && longGoalActivity === '') {
      setLongGoalActivity(plan.long_goal_activity);
    }
    if (plan.long_goal_participation !== undefined && longGoalParticipation === '') {
      setLongGoalParticipation(plan.long_goal_participation);
    }
    if (plan.long_goal_function_icf !== undefined && longGoalFunctionICF === '') {
      setLongGoalFunctionICF(plan.long_goal_function_icf);
    }
    if (plan.long_goal_activity_icf !== undefined && longGoalActivityICF === '') {
      setLongGoalActivityICF(plan.long_goal_activity_icf);
    }
    if (plan.long_goal_participation_icf !== undefined && longGoalActivityICF === '') {
      setLongGoalParticipationICF(plan.long_goal_participation_icf);
    }

    // プログラム内容
    if (plan.program_1 !== undefined && program1 === '') {
      setProgram1(plan.program_1);
    }
    if (plan.program_2 !== undefined && program2 === '') {
      setProgram2(plan.program_2);
    }
    if (plan.program_3 !== undefined && program3 === '') {
      setProgram3(plan.program_3);
    }
    if (plan.program_4 !== undefined && program4 === '') {
      setProgram4(plan.program_4);
    }
    if (plan.program_support_1 !== undefined && programSupport1 === '') {
      setProgramSupport1(plan.program_support_1);
    }
    if (plan.program_support_2 !== undefined && programSupport2 === '') {
      setProgramSupport2(plan.program_support_2);
    }
    if (plan.program_support_3 !== undefined && programSupport3 === '') {
      setProgramSupport3(plan.program_support_3);
    }
    if (plan.program_support_4 !== undefined && programSupport4 === '') {
      setProgramSupport4(plan.program_support_4);
    }
    if (plan.program_note_1 !== undefined && programNote1 === '') {
      setProgramNote1(plan.program_note_1);
    }
    if (plan.program_note_2 !== undefined && programNote2 === '') {
      setProgramNote2(plan.program_note_2);
    }
    if (plan.program_note_3 !== undefined && programNote3 === '') {
      setProgramNote3(plan.program_note_3);
    }
    if (plan.program_note_4 !== undefined && programNote4 === '') {
      setProgramNote4(plan.program_note_4);
    }
    if (plan.program_frequency_1 !== undefined) {
      setProgramFrequency1(plan.program_frequency_1);
    }
    if (plan.program_frequency_2 !== undefined) {
      setProgramFrequency2(plan.program_frequency_2);
    }
    if (plan.program_frequency_3 !== undefined) {
      setProgramFrequency3(plan.program_frequency_3);
    }
    if (plan.program_frequency_4 !== undefined) {
      setProgramFrequency4(plan.program_frequency_4);
    }
    if (plan.program_time_1 !== undefined && programTime1 === '') {
      setProgramTime1(plan.program_time_1);
    }
    if (plan.program_time_2 !== undefined && programTime2 === '') {
      setProgramTime2(plan.program_time_2);
    }
    if (plan.program_time_3 !== undefined && programTime3 === '') {
      setProgramTime3(plan.program_time_3);
    }
    if (plan.program_time_4 !== undefined && programTime4 === '') {
      setProgramTime4(plan.program_time_4);
    }
    if (plan.program_practitioner_1 !== undefined && programPractitioner1 === '') {
      setProgramPractitioner1(plan.program_practitioner_1);
    }
    if (plan.program_practitioner_2 !== undefined && programPractitioner2 === '') {
      setProgramPractitioner2(plan.program_practitioner_2);
    }
    if (plan.program_practitioner_3 !== undefined && programPractitioner3 === '') {
      setProgramPractitioner3(plan.program_practitioner_3);
    }
    if (plan.program_practitioner_4 !== undefined && programPractitioner4 === '') {
      setProgramPractitioner4(plan.program_practitioner_4);
    }

    // サービス時間以外に実施すること: outside_of_service
    if (plan.outside_of_service !== undefined && outsideOfService === '') {
      setOutsideOfService(plan.outside_of_service);
    }
    // 特記事項: special_remarks
    if (plan.special_remarks !== undefined && specialRemarks === '') {
      setSpecialRemarks(plan.special_remarks);
    }

    // 変化: change
    if (plan.change !== undefined && change === '') {
      setChange(plan.change);
    }
    // 課題とその要因: issue_factor
    if (plan.issue_factor !== undefined && issueFactor === '') {
      setIssueFactor(plan.issue_factor);
    }

    if (plan.creation_year !== undefined) {
      setCreationYear(plan.creation_year);
    }
    if (plan.creation_month !== undefined) {
      setCreationMonth(plan.creation_month);
    }
    if (plan.creation_day !== undefined) {
      setCreationDay(plan.creation_day);
    }
    if (plan.last_creation_year !== undefined) {
      setLastCreationYear(plan.last_creation_year);
    }
    if (plan.last_creation_month !== undefined) {
      setLastCreationMonth(plan.last_creation_month);
    }
    if (plan.last_creation_day !== undefined) {
      setLastCreationDay(plan.last_creation_day);
    }
    if (plan.first_creation_year !== undefined) {
      setFirstCreationYear(plan.first_creation_year);
    }
    if (plan.first_creation_month !== undefined) {
      setFirstCreationMonth(plan.first_creation_month);
    }
    // first_creation_day
    if (plan.first_creation_day !== undefined) {
      setFirstCreationDay(plan.first_creation_day);
    }

    // 社会参加の状況: social_participation
    if (plan.social_participation !== undefined && socialParticipation === '') {
      setSocialParticipation(plan.social_participation);
    }
    // 居宅の環境: residence_environment
    if (plan.residence_environment !== undefined && residenceEnvironment === '') {
      setResidenceEnvironment(plan.residence_environment);
    }
  }, [plan]);

  //----------------------------------------------------------------------
  // ①〜④の介護度が変更
  useEffect(() => {
    // console.log('useEffect[plan,setPlan]', plan);
    // planのロード
    setPlan(plan);
  }, [plan, setPlan]);

  useEffect(() => {
    // console.log('useEffect[user]', user);
    setUserName(user.user_name);
  }, [user]);

  useEffect(() => {
    // console.log('useEffect[careLevel]', careLevel);
    setCareLevel(careLevel);
    // リストをクリア
    setTrainingList([]);
    setTraining('');
    setItemList([]);
    setItem('');
    setPatternList([]);
    setPattern('');
    // 1介護度が選ばれたら、2項目リストをセット
    const key = careLevel;
    const patternArray = [];
    for (const itemKey in bundle['data']) {
      const item = bundle['data'][itemKey];
      if (key == item['care_level']) {
        patternArray.push(item['training']);
      }
      const uniqueList = Array.from(new Set(patternArray));
      setTrainingList(Object.assign({}, uniqueList));
    }
  }, [careLevel]);

  useEffect(() => {
    // console.log('useEffect[careLevel,training]', careLevel, training);
    setTraining(training);
    // リストをクリア
    setItemList([]);
    setItem('');
    setPatternList([]);
    setPattern('');
    // 1介護度と2項目が選ばれたら、3訓練種類リストをセット
    const key = careLevel + training;
    const patternArray = [];
    let mofftreIndex = -1;
    for (const itemKey in bundle['data']) {
      const item = bundle['data'][itemKey];
      //③訓練種類は、モフトレが最上位表示、モフテレビを除外
      if (convertToHalfWidth(key) == convertToHalfWidth(item['care_level'] + item['training'])) {
        if (item['item'] !== 'モフテレビ') {
          patternArray.push(item['item']);
        }
        // 'モフトレ' のインデックスを保存
        if (item['item'] === 'モフトレ') {
          mofftreIndex = patternArray.length - 1;
        }
      }
    }
    // 'モフトレ' が含まれていれば、その要素を削除して配列の先頭に'モフトレ'を挿入
    if (mofftreIndex !== -1) {
      patternArray.splice(mofftreIndex, 1);
      patternArray.unshift('モフトレ');
    }
    const uniqueList = Array.from(new Set(patternArray));
    setItemList(Object.assign({}, uniqueList));
  }, [training]);

  useEffect(() => {
    // console.log('useEffect[careLevel,training,item]', careLevel, training, item);
    setItem(item);
    // リストをクリア
    setPatternList([]);
    setPattern('');
    // 1介護度と2項目と3訓練種類が選ばれたら、4パターンリストをセット
    const key = careLevel + training + item;
    // bundleでのpattern_descriptionをセットするように変更した
    const patternArray = [];
    for (const itemKey2 in bundle['data']) {
      const item = bundle['data'][itemKey2];
      if (key == item['care_level'] + item['training'] + item['item']) {
        patternArray.push({ name: item['pattern'] + item['pattern_description'], number: item['pattern'] });
      }
    }
    patternArray.sort((a, b) => {
      if (a.number > b.number) {
        return 1;
      } else {
        return -1;
      }
    });
    const newList = Object.assign({}, patternArray);
    setPatternList(newList);
  }, [item]);

  useEffect(() => {
    // console.log('useEffect[pattern]', pattern);
    setPattern(pattern);
  }, [pattern]);

  //----------------------------------------------------------------------
  //各種フォーム内容の変更

  useEffect(() => {
    // console.log('useEffect[shortGoalStartYear]', shortGoalStartYear);
    // console.log('plan[short_goal_start_year]', plan.short_goal_start_year);
  }, [shortGoalStartYear]);

  useEffect(() => {
    // console.log('useEffect[shortGoalStartMonth]', shortGoalStartMonth);
    // console.log('plan[short_goal_start_month]', plan.short_goal_start_month);
  }, [shortGoalStartMonth]);

  useEffect(() => {
    // console.log('useEffect[shortGoalFinishYear]', shortGoalFinishYear);
    // console.log('plan[short_goal_finish_year]', plan.short_goal_finish_year);
  }, [shortGoalFinishYear]);

  useEffect(() => {
    // console.log('useEffect[shortGoalFinishMonth]', shortGoalFinishMonth);
    // console.log('plan[short_goal_finish_month]', plan.short_goal_finish_month);
  }, [shortGoalFinishMonth]);

  useEffect(() => {
    // console.log('useEffect[shortGoalAchievement]', shortGoalAchievement);
    // console.log('plan[short_goal_achievement]', plan.short_goal_achievement);
  }, [shortGoalAchievement]);

  useEffect(() => {
    // console.log('useEffect[shortGoalFunction]', shortGoalFunction);
    // ICFの値をセット. 記入内容を少し変更しても消えないようにしている
    const icf_selected = icffunction.find((item) => convertDot(item.goal) === convertDot(shortGoalFunction));
    if (icf_selected) {
      setShortGoalFunctionICF(icf_selected.icf);
    } else if (shortGoalFunction === '') {
      setShortGoalFunctionICF('');
    }
    setShortGoalFunctionForPlan(shortGoalFunction ? shortGoalFunction : '');
  }, [shortGoalFunction]);

  useEffect(() => {
    // console.log('useEffect[inputShortGoalFunction]', inputShortGoalFunction);
    if (inputShortGoalFunction === '') {
      setShortGoalFunctionICF('');
    }
    setShortGoalFunctionForPlan(inputShortGoalFunction);
  }, [inputShortGoalFunction]);

  useEffect(() => {
    // console.log('useEffect[shortGoalActivity]', shortGoalActivity);
    // ICFの値をセット
    const icf_selected = icfactivity.find((item) => convertDot(item.goal) === convertDot(shortGoalActivity));
    if (icf_selected) {
      setShortGoalActivityICF(icf_selected.icf);
    } else if (shortGoalActivity === '') {
      setShortGoalActivityICF('');
    }
    setShortGoalActivityForPlan(shortGoalActivity ? shortGoalActivity : '');
  }, [shortGoalActivity]);

  useEffect(() => {
    // console.log('useEffect[inputShortGoalActivity]', inputShortGoalActivity);
    if (inputShortGoalActivity === '') {
      setShortGoalActivityICF('');
    }
    setShortGoalActivityForPlan(inputShortGoalActivity);
  }, [inputShortGoalActivity]);

  useEffect(() => {
    // console.log('useEffect[shortGoalParticipation]', shortGoalParticipation);
    // ICFの値をセット
    const icf_selected = icfparticipation.find((item) => convertDot(item.goal) === convertDot(shortGoalParticipation));
    if (icf_selected) {
      setShortGoalParticipationICF(icf_selected.icf);
    } else if (shortGoalParticipation === '') {
      setShortGoalParticipationICF('');
    }
    setShortGoalParticipationForPlan(shortGoalParticipation ? shortGoalParticipation : '');
  }, [shortGoalParticipation]);

  useEffect(() => {
    // console.log('useEffect[inputShortGoalParticipation]', inputShortGoalParticipation);
    if (inputShortGoalParticipation === '') {
      setShortGoalParticipationICF('');
    }
    setShortGoalParticipationForPlan(inputShortGoalParticipation);
  }, [inputShortGoalParticipation]);

  useEffect(() => {
    // console.log('useEffect[longGoalFunction]', longGoalFunction);
    // ICFの値をセット
    const icf_selected = icffunction.find((item) => convertDot(item.goal) === convertDot(longGoalFunction));
    if (icf_selected) {
      setLongGoalFunctionICF(icf_selected.icf);
    } else if (longGoalFunction === '') {
      setLongGoalFunctionICF('');
    }
    setLongGoalFunctionForPlan(longGoalFunction ? longGoalFunction : '');
  }, [longGoalFunction]);

  useEffect(() => {
    // console.log('useEffect[inputLongGoalFunction]', inputLongGoalFunction);
    if (inputLongGoalFunction === '') {
      setLongGoalFunctionICF('');
    }
    setLongGoalFunctionForPlan(inputLongGoalFunction);
  }, [inputLongGoalFunction]);

  useEffect(() => {
    // console.log('useEffect[longGoalActivity]', longGoalActivity);
    // ICFの値をセット
    const icf_selected = icfactivity.find((item) => convertDot(item.goal) === convertDot(longGoalActivity));
    if (icf_selected) {
      // plan[key + '_icf'] = icf_selected.icf;
      setLongGoalActivityICF(icf_selected.icf);
    } else if (longGoalActivity === '') {
      setLongGoalActivityICF('');
    }
    setLongGoalActivityForPlan(longGoalActivity ? longGoalActivity : '');
  }, [longGoalActivity]);

  useEffect(() => {
    // console.log('useEffect[inputLongGoalActivity]', inputLongGoalActivity);
    if (inputLongGoalActivity === '') {
      setLongGoalActivityICF('');
    }
    setLongGoalActivityForPlan(inputLongGoalActivity);
  }, [inputLongGoalActivity]);

  useEffect(() => {
    // console.log('useEffect[longGoalParticipation]', longGoalParticipation);
    // ICFの値をセット
    const icf_selected = icfparticipation.find((item) => convertDot(item.goal) === convertDot(longGoalParticipation));
    if (icf_selected) {
      // plan[key + '_icf'] = icf_selected.icf;
      setLongGoalParticipationICF(icf_selected.icf);
    } else if (longGoalParticipation === '') {
      setLongGoalParticipationICF('');
    }
    setLongGoalParticipationForPlan(longGoalParticipation ? longGoalParticipation : '');
  }, [longGoalParticipation]);

  useEffect(() => {
    // console.log('useEffect[inputLongGoalParticipation]', inputLongGoalParticipation);
    if (inputLongGoalParticipation === '') {
      setLongGoalParticipationICF('');
    }
    setLongGoalParticipationForPlan(inputLongGoalParticipation);
  }, [inputLongGoalParticipation]);

  useEffect(() => {
    // console.log('useEffect[shortGoalFunctionICF]', shortGoalFunctionICF);
  }, [shortGoalFunctionICF]);

  useEffect(() => {
    // console.log('useEffect[shortGoalActivityICF]', shortGoalActivityICF);
  }, [shortGoalActivityICF]);

  useEffect(() => {
    // console.log('useEffect[shortGoalParticipationICF]', shortGoalParticipationICF);
  }, [shortGoalParticipationICF]);

  useEffect(() => {
    // console.log('useEffect[longGoalFunctionICF]', longGoalFunctionICF);
  }, [longGoalFunctionICF]);

  useEffect(() => {
    // console.log('useEffect[longGoalActivityICF]', longGoalActivityICF);
  }, [longGoalActivityICF]);

  useEffect(() => {
    // console.log('useEffect[longGoalParticipationICF]', longGoalParticipationICF);
  }, [longGoalParticipationICF]);

  useEffect(() => {
    setProgram1(program1);
    // 支援コードをセット
    const support_selected = support.find((item) => {
      return item.program === program1;
    });
    if (support_selected) {
      setProgramSupport1(support_selected.code);
    } else if (program1 === '') {
      setProgramSupport1('');
    }
    setProgram1ForPlan(program1 ? program1 : '');
  }, [program1]);

  useEffect(() => {
    // console.log('useEffect[inputProgram1]', inputProgram1);
    if (inputProgram1 === '') {
      setProgramSupport1('');
    }
    setProgram1ForPlan(inputProgram1);
  }, [inputProgram1]);

  useEffect(() => {
    setProgram2(program2);
    // 支援コードをセット
    const support_selected = support.find((item) => {
      return item.program === program2;
    });
    if (support_selected) {
      setProgramSupport2(support_selected.code);
    } else if (program2 === '') {
      setProgramSupport2('');
    }
    setProgram2ForPlan(program2 ? program2 : '');
  }, [program2]);

  useEffect(() => {
    // console.log('useEffect[inputProgram2]', inputProgram2);
    if (inputProgram2 === '') {
      setProgramSupport2('');
    }
    setProgram2ForPlan(inputProgram2);
  }, [inputProgram2]);

  useEffect(() => {
    setProgram3(program3);
    // 支援コードをセット
    const support_selected = support.find((item) => {
      return item.program === program3;
    });
    if (support_selected) {
      setProgramSupport3(support_selected.code);
    } else if (program3 === '') {
      setProgramSupport3('');
    }
    setProgram3ForPlan(program3 ? program3 : '');
  }, [program3]);

  useEffect(() => {
    // console.log('useEffect[inputProgram3]', inputProgram3);
    if (inputProgram3 === '') {
      setProgramSupport3('');
    }
    setProgram3ForPlan(inputProgram3);
  }, [inputProgram3]);

  useEffect(() => {
    setProgram4(program4);
    // 支援コードをセット
    const support_selected = support.find((item) => {
      return item.program === program4;
    });
    if (support_selected) {
      setProgramSupport4(support_selected.code);
    } else if (program4 === '') {
      setProgramSupport4('');
    }
    setProgram4ForPlan(program4 ? program4 : '');
  }, [program4]);

  useEffect(() => {
    // console.log('useEffect[inputProgram4]', inputProgram4);
    if (inputProgram4 === '') {
      setProgramSupport4('');
    }
    setProgram4ForPlan(inputProgram4);
  }, [inputProgram4]);

  useEffect(() => {
    // console.log('useEffect[programSupport1]', programSupport1);
  }, [programSupport1]);

  useEffect(() => {
    // console.log('useEffect[programSupport2]', programSupport2);
  }, [programSupport2]);

  useEffect(() => {
    // console.log('useEffect[programSupport3]', programSupport3);
  }, [programSupport3]);

  useEffect(() => {
    // console.log('useEffect[programSupport4]', programSupport4);
  }, [programSupport4]);

  useEffect(() => {
    // console.log('useEffect[programNote1]', programNote1);
  }, [programNote1]);

  useEffect(() => {
    // console.log('useEffect[programNote2]', programNote2);
  }, [programNote2]);

  useEffect(() => {
    // console.log('useEffect[programNote3]', programNote3);
  }, [programNote3]);

  useEffect(() => {
    // console.log('useEffect[programNote4]', programNote4);
  }, [programNote4]);

  useEffect(() => {
    // console.log('useEffect[programFrequency1]', programFrequency1);
  }, [programFrequency1]);

  useEffect(() => {
    // console.log('useEffect[programFrequency2]', programFrequency2);
  }, [programFrequency2]);

  useEffect(() => {
    // console.log('useEffect[programFrequency3]', programFrequency3);
  }, [programFrequency3]);

  useEffect(() => {
    // console.log('useEffect[programFrequency4]', programFrequency4);
  }, [programFrequency4]);

  useEffect(() => {
    // console.log('useEffect[programTime1]', programTime1);
  }, [programTime1]);

  useEffect(() => {
    // console.log('useEffect[programTime2]', programTime2);
  }, [programTime2]);

  useEffect(() => {
    // console.log('useEffect[programTime3]', programTime3);
  }, [programTime3]);

  useEffect(() => {
    // console.log('useEffect[programTime4]', programTime4);
  }, [programTime4]);

  useEffect(() => {
    // console.log('useEffect[programPractitioner1]', programPractitioner1);
  }, [programPractitioner1]);

  useEffect(() => {
    // console.log('useEffect[programPractitioner2]', programPractitioner2);
  }, [programPractitioner2]);

  useEffect(() => {
    // console.log('useEffect[programPractitioner3]', programPractitioner3);
  }, [programPractitioner3]);

  useEffect(() => {
    // console.log('useEffect[programPractitioner4]', programPractitioner4);
  }, [programPractitioner4]);

  useEffect(() => {
    // console.log('useEffect[note]', note);
  }, [note]);

  useEffect(() => {
    // console.log('useEffect[specialRemarks]', specialRemarks);
  }, [specialRemarks]);

  useEffect(() => {
    // console.log('useEffect[outsideOfService]', outsideOfService);
  }, [outsideOfService]);

  useEffect(() => {
    // console.log('useEffect[creationYear]', creationYear);
  }, [creationYear]);

  useEffect(() => {
    // console.log('useEffect[creationMonth]', creationMonth);
  }, [creationMonth]);

  useEffect(() => {
    // console.log('useEffect[creationDay]', creationDay);
  }, [creationDay]);

  useEffect(() => {
    // console.log('useEffect[lastCreationYear]', lastCreationYear);
  }, [lastCreationYear]);

  useEffect(() => {
    // console.log('useEffect[lastCreationMonth]', lastCreationMonth);
  }, [lastCreationMonth]);

  useEffect(() => {
    // console.log('useEffect[lastCreationDay]', lastCreationDay);
  }, [lastCreationDay]);

  useEffect(() => {
    // console.log('useEffect[change]', change);
  }, [change]);

  useEffect(() => {
    // console.log('useEffect[issueFactor]', issueFactor);
  }, [issueFactor]);

  return (
    <Box component="article" pb="50px" className={classes.report}>
      <Box className={classes.hideable}>【個別機能訓練計画書】</Box>
      <Box component="section">
        <Box mx={{ xs: 2, sm: 5 }} my={5}>
          <Grid item container spacing={matches.xs ? 2 : 3}>
            <Box style={{ display: 'none' }} data-test="profile"></Box>

            <Grid item container xs={12}>
              <Grid item xs={12} sm={2}>
                <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                  <GeneralPrimaryGhostButton
                    onClick={() => backToMoffRakuList(userId)}
                    innerText={'保存せず戻る'}
                    setCss={'width: 120px; height: 40px;'}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box width={{ xs: '100%', sm: '200px' }}>
                  <GeneralPrimaryGhostButton
                    onClick={() => savePlan()}
                    innerText={'保存する'}
                    setCss={'width: 100px; height: 40px;'}
                  />
                </Box>
              </Grid>
              {/* planIDがある場合のみ表示する */}
              {planId && (
                <Grid item xs={12} sm={2}>
                  <Box width={{ xs: '100%', sm: '200px' }}>
                    <GeneralPrimaryGhostButton
                      onClick={() => duplicatePlan()}
                      innerText={'複製する'}
                      setCss={'width: 100px; height: 40px;'}
                    />
                  </Box>
                </Grid>
              )}
              {planId && (
                <Grid item xs={12} sm={3}>
                  <Box width={{ xs: '100%', sm: '200px' }}>
                    <GeneralPrimaryGhostButton
                      onClick={() => exportPlanToExcel()}
                      innerText={'Excelで出力する'}
                      setCss={'width: 150px; height: 40px;'}
                    />
                  </Box>
                </Grid>
              )}
              {planId && (
                <Grid item xs={12} sm={2}>
                  <Box width={{ xs: '100%', sm: '200px' }}>
                    <GeneralPrimaryGhostButton
                      onClick={() => delPlan()}
                      innerText={'削除する'}
                      setCss={'width: 100px; height: 40px;'}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                  <p>利用者: {userName}</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                  <p>作成日: {dispCreatedAt(plan.created_at)}</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                  <p>更新日: {plan.updated_at}</p>
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid container item xs={12}>
              ①から④までを順番に選んでください
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  ①介護度(目安)
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '250px' }}>
                  <GeneralPrimarySelectBox
                    selectedValue={careLevel}
                    selectBoxChanged={(event) => {
                      setCareLevel(event.target.value);
                    }}
                    dataTest="care-level"
                  >
                    {Enumerable.from(CARE_LEVELS)
                      .select<JSX.Element>((careLevelStr: { key: string; value: any }, index: number) => {
                        // valueは0開始の数字.
                        return (
                          <MenuItem key={`careLevel${index}`} value={careLevelStr.value}>
                            {careLevelStr.value}
                          </MenuItem>
                        );
                      })
                      .toArray()}
                  </GeneralPrimarySelectBox>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  ②項目リスト
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '250px' }}>
                  <GeneralPrimarySelectBox
                    selectedValue={training}
                    selectBoxChanged={(event) => {
                      setTraining(event.target.value);
                    }}
                    dataTest="training"
                  >
                    {Enumerable.from(trainingList)
                      .select<JSX.Element>((trainingStr: { key: string; value: any }, index: number) => {
                        // valueは0開始の数字.
                        return (
                          <MenuItem key={`training${index}`} value={trainingStr.value}>
                            {trainingStr.value}
                          </MenuItem>
                        );
                      })
                      .toArray()}
                  </GeneralPrimarySelectBox>
                </Box>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  ③訓練種類
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '250px' }}>
                  <GeneralPrimarySelectBox
                    selectedValue={item}
                    selectBoxChanged={(event) => {
                      setItem(event.target.value);
                    }}
                    dataTest="item"
                  >
                    {Enumerable.from(itemList)
                      .select<JSX.Element>((itemStr: { key: string; value: any }, index: number) => {
                        return (
                          <MenuItem key={`item${index}`} value={itemStr.value}>
                            {itemStr.value}
                          </MenuItem>
                        );
                      })
                      .toArray()}
                  </GeneralPrimarySelectBox>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  ④パターン
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '250px' }}>
                  <GeneralPrimarySelectBox
                    selectedValue={pattern}
                    selectBoxChanged={(event) => {
                      setPattern(event.target.value);
                      changedBySelectedPattern(event.target.value.toString());
                    }}
                    dataTest="pattern"
                  >
                    {Enumerable.from(patternList)
                      .select<JSX.Element>((patternStr: { key: string; value: any }, index: number) => {
                        return (
                          <MenuItem key={`pattern${index}`} value={patternStr.value.number}>
                            {patternStr.value.name}
                          </MenuItem>
                        );
                      })
                      .toArray()}
                  </GeneralPrimarySelectBox>
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" width="90%" display="flex" alignItems="center" fontWeight="bold">
                  <p>機能訓練実施上の留意事項(開始前・訓練中の留意事項、運動強度・負荷量等)</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <GeneralPrimaryTextArea
                  /** テキストエリアの名前 */
                  name={'note'}
                  rows={6}
                  cols={400}
                  placeholder={'ここに入力してください。'}
                  onChange={(event) => {
                    setNote(event.target.value);
                  }}
                  value={note}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                <p>Ⅱ 個別機能訓練の目標・個別機能訓練項目の設定</p>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                <p>個別機能訓練の目標</p>
              </Box>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>短期目標(今後3ヶ月) 令和</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispReiwaSelect(shortGoalStartYear, 'short_goal_start_year', setShortGoalStartYear)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>年</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispMonthSelect(shortGoalStartMonth, 'short_goal_start_month', setShortGoalStartMonth)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>月</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  <p>〜</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispReiwaSelect(shortGoalFinishYear, 'short_goal_finish_year', setShortGoalFinishYear)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>年</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '80%', sm: '50px' }}>
                  {dispMonthSelect(shortGoalFinishMonth, 'short_goal_finish_month', setShortGoalFinishMonth)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>月</Box>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>目標達成度</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                {dispAchievement(shortGoalAchievement, 'short_goal_achievement', setShortGoalAchievement)}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>機能</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  id={'short_goal_function'}
                  freeSolo
                  autoComplete
                  options={icffunction?.map((obj) => obj.goal) ?? []}
                  value={shortGoalFunction === undefined ? '' : shortGoalFunction}
                  onChange={(event: any, newValue: string | null) => {
                    setShortGoalFunction(newValue);
                  }}
                  inputValue={inputShortGoalFunction === undefined ? '' : inputShortGoalFunction}
                  onInputChange={(event: any, newValue: string) => {
                    setInputShortGoalFunction(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={shortGoalFunction === undefined ? '' : shortGoalFunction}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>ICF</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {shortGoalFunctionICF}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>活動</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  id={'short_goal_activity'}
                  freeSolo
                  options={icfactivity.map((obj) => obj.goal)}
                  // style={{ width: 450 }}
                  value={shortGoalActivity === undefined ? '' : shortGoalActivity}
                  onChange={(event: any, newValue: string | null) => {
                    setShortGoalActivity(newValue);
                  }}
                  inputValue={inputShortGoalActivity === undefined ? '' : inputShortGoalActivity}
                  onInputChange={(event: any, newValue: string) => {
                    setInputShortGoalActivity(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label={shortGoalActivity} variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>ICF</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {shortGoalActivityICF}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>参加</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  id={'short_goal_participation'}
                  freeSolo
                  options={icfparticipation.map((obj) => obj.goal)}
                  // style={{ width: 450 }}
                  value={shortGoalParticipation === undefined ? '' : shortGoalParticipation}
                  onChange={(event: any, newValue: string | null) => {
                    setShortGoalParticipation(newValue);
                  }}
                  inputValue={inputShortGoalParticipation === undefined ? '' : inputShortGoalParticipation}
                  onInputChange={(event: any, newValue: string) => {
                    setInputShortGoalParticipation(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label={shortGoalParticipation} variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>ICF</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {shortGoalParticipationICF}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>長期目標 令和</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispReiwaSelect(longGoalStartYear, 'long_goal_start_year', setLongGoalStartYear)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>年</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispMonthSelect(longGoalStartMonth, 'long_goal_start_month', setLongGoalStartMonth)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>月</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  <p>〜</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispReiwaSelect(longGoalFinishYear, 'long_goal_finish_year', setLongGoalFinishYear)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>年</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '80%', sm: '50px' }}>
                  {dispMonthSelect(longGoalFinishMonth, 'long_goal_finish_month', setLongGoalFinishMonth)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>月</Box>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>目標達成度</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                {dispAchievement(longGoalAchievement, 'long_goal_achievement', setLongGoalAchievement)}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>機能</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  id={'long_goal_function'}
                  freeSolo
                  options={icffunction.map((obj) => obj.goal)}
                  // style={{ width: 450 }}
                  value={longGoalFunction === undefined ? '' : longGoalFunction}
                  onChange={(event: any, newValue: string | null) => {
                    setLongGoalFunction(newValue);
                  }}
                  inputValue={inputLongGoalFunction === undefined ? '' : inputLongGoalFunction}
                  onInputChange={(event: any, newValue: string) => {
                    setInputLongGoalFunction(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label={longGoalFunction} variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>ICF</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {longGoalFunctionICF}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>活動</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  id={'long_goal_activity'}
                  freeSolo
                  options={icfactivity.map((obj) => obj.goal)}
                  // style={{ width: 450 }}
                  value={longGoalActivity === undefined ? '' : longGoalActivity}
                  onChange={(event: any, newValue: string | null) => {
                    setLongGoalActivity(newValue);
                  }}
                  inputValue={inputLongGoalActivity === undefined ? '' : inputLongGoalActivity}
                  onInputChange={(event: any, newValue: string) => {
                    setInputLongGoalActivity(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label={longGoalActivity} variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>ICF</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {longGoalActivityICF}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>参加</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  id={'long_goal_participation'}
                  freeSolo
                  options={icfparticipation.map((obj) => obj.goal)}
                  // style={{ width: 450 }}
                  value={longGoalParticipation === undefined ? '' : longGoalParticipation}
                  onChange={(event: any, newValue: string | null) => {
                    setLongGoalParticipation(newValue);
                  }}
                  inputValue={inputLongGoalParticipation === undefined ? '' : inputLongGoalParticipation}
                  onInputChange={(event: any, newValue: string) => {
                    setInputLongGoalParticipation(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label={longGoalParticipation} variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>ICF</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {longGoalParticipationICF}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                <p>個別機能訓練項目</p>
              </Box>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>プログラム内容①</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  id={'program_1'}
                  freeSolo
                  options={support.map((obj) => obj.program)}
                  // style={{ width: 400 }}
                  value={program1 === undefined ? '' : program1}
                  onChange={(event: any, newValue: string | null) => {
                    setProgram1(newValue);
                  }}
                  inputValue={inputProgram1 === undefined ? '' : inputProgram1}
                  onInputChange={(event: any, newValue: string) => {
                    setInputProgram1(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label={program1} variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>支援</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {programSupport1}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>留意点</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <GeneralPrimaryInput
                  value={programNote1}
                  onChange={(event) => {
                    setProgramNote1(event.target.value);
                  }}
                  placeholder={'ここに入力'}
                  data-test=""
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>頻度</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {dispFrequency(programFrequency1, 'program_frequency_1', setProgramFrequency1)}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>時間</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}>
                <GeneralPrimaryInput
                  value={programTime1}
                  onChange={(event) => {
                    setProgramTime1(event.target.value);
                  }}
                  placeholder={'ここに入力'}
                  data-test=""
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>主な実施者</p>
              </Grid>
              <Grid item xs={12} sm={5}>
                <GeneralPrimaryInput
                  value={programPractitioner1}
                  onChange={(event) => {
                    setProgramPractitioner1(event.target.value);
                  }}
                  placeholder={'ここに入力'}
                  data-test=""
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>プログラム内容②</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  id={'program_2'}
                  freeSolo
                  options={support.map((obj) => obj.program)}
                  // style={{ width: 400 }}
                  value={program2 === undefined ? '' : program2}
                  onChange={(event: any, newValue: string | null) => {
                    setProgram2(newValue);
                  }}
                  inputValue={inputProgram2 === undefined ? '' : inputProgram2}
                  onInputChange={(event: any, newValue: string) => {
                    setInputProgram2(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label={program2} variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>支援</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {programSupport2}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>留意点</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <GeneralPrimaryInput
                  value={programNote2}
                  onChange={(event) => {
                    setProgramNote2(event.target.value);
                  }}
                  placeholder={'ここに入力'}
                  data-test=""
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>頻度</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {dispFrequency(programFrequency2, 'program_frequency_2', setProgramFrequency2)}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>時間</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}>
                <GeneralPrimaryInput
                  value={programTime2}
                  onChange={(event) => {
                    setProgramTime2(event.target.value);
                  }}
                  placeholder={'ここに入力'}
                  data-test=""
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>主な実施者</p>
              </Grid>
              <Grid item xs={12} sm={5}>
                <GeneralPrimaryInput
                  value={programPractitioner2}
                  onChange={(event) => {
                    setProgramPractitioner2(event.target.value);
                  }}
                  placeholder={'ここに入力'}
                  data-test=""
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>プログラム内容③</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  id={'program_3'}
                  freeSolo
                  options={support.map((obj) => obj.program)}
                  // style={{ width: 400 }}
                  value={program3 === undefined ? '' : program3}
                  onChange={(event: any, newValue: string | null) => {
                    setProgram3(newValue);
                  }}
                  inputValue={inputProgram3 === undefined ? '' : inputProgram3}
                  onInputChange={(event: any, newValue: string) => {
                    setInputProgram3(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label={program3} variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>支援</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {programSupport3}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>留意点</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <GeneralPrimaryInput
                  value={programNote3}
                  onChange={(event) => {
                    setProgramNote3(event.target.value);
                  }}
                  placeholder={'ここに入力'}
                  data-test=""
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>頻度</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {dispFrequency(programFrequency3, 'program_frequency_3', setProgramFrequency3)}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>時間</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}>
                <GeneralPrimaryInput
                  value={programTime3}
                  onChange={(event) => {
                    setProgramTime3(event.target.value);
                  }}
                  placeholder={'ここに入力'}
                  data-test=""
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>主な実施者</p>
              </Grid>
              <Grid item xs={12} sm={5}>
                <GeneralPrimaryInput
                  value={programPractitioner3}
                  onChange={(event) => {
                    setProgramPractitioner3(event.target.value);
                  }}
                  placeholder={'ここに入力'}
                  data-test=""
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>プログラム内容④</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Autocomplete
                  id={'program_4'}
                  freeSolo
                  options={support.map((obj) => obj.program)}
                  // style={{ width: 400 }}
                  value={program4 === undefined ? '' : program4}
                  onChange={(event: any, newValue: string | null) => {
                    setProgram4(newValue);
                  }}
                  inputValue={inputProgram4 === undefined ? '' : inputProgram4}
                  onInputChange={(event: any, newValue: string) => {
                    setInputProgram4(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label={program4} variant="outlined" />}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>支援</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {programSupport4}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>留意点</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <GeneralPrimaryInput
                  value={programNote4}
                  onChange={(event) => {
                    setProgramNote4(event.target.value);
                  }}
                  placeholder={'ここに入力'}
                  data-test=""
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>頻度</p>
              </Grid>
              <Grid item xs={12} sm={1}>
                {dispFrequency(programFrequency4, 'program_frequency_4', setProgramFrequency4)}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>時間</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={5}>
                <GeneralPrimaryInput
                  value={programTime4}
                  onChange={(event) => {
                    setProgramTime4(event.target.value);
                  }}
                  placeholder={'ここに入力'}
                  data-test=""
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <p>主な実施者</p>
              </Grid>
              <Grid item xs={12} sm={5}>
                <GeneralPrimaryInput
                  value={programPractitioner4}
                  onChange={(event) => {
                    setProgramPractitioner4(event.target.value);
                  }}
                  placeholder={'ここに入力'}
                  data-test=""
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>プログラム立案者</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Box width={{ xs: '100%', sm: '200px' }}>{basicInfo.program_planner}</Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" width="90%" display="flex" alignItems="center" fontWeight="bold">
                  <p>利用者本人・家族などがサービス利用時間以外に実施すること</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10}>
                <GeneralPrimaryInput
                  value={outsideOfService}
                  onChange={(event) => {
                    setOutsideOfService(event.target.value);
                  }}
                  placeholder={''}
                  data-test=""
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" width="90%" display="flex" alignItems="center" fontWeight="bold">
                  <p>特記事項</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={10}>
                <GeneralPrimaryInput
                  value={specialRemarks}
                  onChange={(event) => {
                    setSpecialRemarks(event.target.value);
                  }}
                  placeholder={''}
                  data-test=""
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                <p>Ⅲ 個別機能訓練実施後の対応</p>
              </Box>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>個別機能訓練の実施による変化</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <GeneralPrimaryInput
                  value={change}
                  onChange={(event) => {
                    setChange(event.target.value);
                  }}
                  placeholder={''}
                  data-test=""
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>個別機能訓練実施における課題とその要因</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <GeneralPrimaryInput
                  value={issueFactor}
                  onChange={(event) => {
                    setIssueFactor(event.target.value);
                  }}
                  placeholder={''}
                  data-test=""
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                Ⅰ 利用者の基本情報
              </Box>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>作成日：令和</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispReiwaSelect(creationYear, 'creation_year', setCreationYear)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>年</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispMonthSelect(creationMonth, 'creation_month', setCreationMonth)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>月</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispDayOptions(creationDay, 'creation_day', setCreationDay)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  <p>日</p>
                </Box>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>前回作成日:令和</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispReiwaSelect(lastCreationYear, 'last_creation_year', setLastCreationYear)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>年</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispMonthSelect(lastCreationMonth, 'last_creation_month', setLastCreationMonth)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>月</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispDayOptions(lastCreationDay, 'last_creation_day', setLastCreationDay)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  <p>日</p>
                </Box>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>初回作成日:令和</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispReiwaSelect(firstCreationYear, 'first_creation_year', setFirstCreationYear)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>年</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispMonthSelect(firstCreationMonth, 'first_creation_month', setFirstCreationMonth)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>月</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  {dispDayOptions(firstCreationDay, 'first_creation_day', setFirstCreationDay)}
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  <p>日</p>
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>氏名</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                {user.user_name}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>ふりがな</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                {user.user_name_kana}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  生年月日
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>{user.user_birth_year}</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>年</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>{user.user_birth_month}</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>月</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>{user.user_birth_day}</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  <p>日</p>
                </Box>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>要介護度</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box width={{ xs: '100%', sm: '200px' }}>
                  {String(user.user_care_level) === '0'
                    ? '要支援1'
                    : String(user.user_care_level) === '1'
                    ? '要支援2'
                    : String(user.user_care_level) === '2'
                    ? '要介護1'
                    : String(user.user_care_level) === '3'
                    ? '要介護2'
                    : String(user.user_care_level) === '4'
                    ? '要介護3'
                    : String(user.user_care_level) === '5'
                    ? '要介護4'
                    : String(user.user_care_level) === '6'
                    ? '要介護5'
                    : ''}
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>計画書作成者</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '200px' }}>{basicInfo.planner}</Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>職種</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '200px' }}>{basicInfo.planner_job}</Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>障害高齢者の日常生活自立度</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '200px' }}>{basicInfo.independence_level_disabled_elderly}</Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>認知症高齢者の日常生活自立度</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '200px' }}>{basicInfo.independence_level_dementia_elderly}</Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" width="90%" display="flex" alignItems="center" fontWeight="bold">
                  <p>利用者本人の希望</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '200px' }}>{basicInfo.user_wish}</Box>
              </Grid>

              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" width="90%" display="flex" alignItems="center" fontWeight="bold">
                  <p>家族の希望</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '200px' }}>{basicInfo.family_wish}</Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>利用者本人の社会参加の状況</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '300px' }}>
                  <GeneralPrimaryInput
                    value={socialParticipation}
                    onChange={(event) => {
                      setSocialParticipation(event.target.value);
                    }}
                    placeholder={'例:週1回買物、週2回デイに通所'}
                    data-test=""
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>利用者の居宅の環境(環境因子)</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '300px' }}>
                  <GeneralPrimaryInput
                    value={residenceEnvironment}
                    onChange={(event) => {
                      setResidenceEnvironment(event.target.value);
                    }}
                    placeholder={'例:独居で自宅内は手摺で移動'}
                    data-test=""
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                <p>健康状態・経過</p>
              </Box>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>病名</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                {basicInfo.disease_name}
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>ICD10</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                {basicInfo.idc10_disease_name}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>発症日・受傷日：{basicInfo.illness_era}</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>{basicInfo.illness_year}</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>年</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>{basicInfo.illness_month}</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>月</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>{basicInfo.illness_day}</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  <p>日</p>
                </Box>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>直近の入院日:{basicInfo.hospitalization_era}</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>{basicInfo.hospitalization_year}</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>年</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>{basicInfo.hospitalization_month}</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>月</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>{basicInfo.hospitalization_day}</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  <p>日</p>
                </Box>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>最近の退院日:{basicInfo.discharge_era}</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>{basicInfo.discharge_year}</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>年</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>{basicInfo.discharge_month}</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>月</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>{basicInfo.discharge_day}</Box>
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box width={{ xs: '100%', sm: '50px' }}>
                  <p>日</p>
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>治療経過(手術ばある場合は手術日・術式等)</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                {basicInfo.course_of_treatment}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider variant="inset" />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>合併疾患・コントロール状態（高血圧、心疾患、呼吸器疾患、糖尿病等) 1</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                {basicInfo.complicated_diseases_1}
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>ICD10</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                {basicInfo.complicated_diseases_icd10_1}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>合併疾患・コントロール状態（高血圧、心疾患、呼吸器疾患、糖尿病等) 2</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                {basicInfo.complicated_diseases_2}
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>ICD10</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                {basicInfo.complicated_diseases_icd10_2}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>合併疾患・コントロール状態（高血圧、心疾患、呼吸器疾患、糖尿病等) 3</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                {basicInfo.complicated_diseases_3}
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>ICD10</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                {basicInfo.complicated_diseases_icd10_3}
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>合併疾患・コントロール状態（高血圧、心疾患、呼吸器疾患、糖尿病等) 4</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                {basicInfo.complicated_diseases_4}
              </Grid>
              <Grid item xs={12} sm={1}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>ICD10</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                {basicInfo.complicated_diseases_icd10_4}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                <p>施設情報</p>
              </Box>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>通所介護</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '200px' }}>{institution.institution_name}</Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>事業所No</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '200px' }}>{institution.institution_care_id}</Box>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>住所</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '200px' }}>{institution.address}</Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  <p>tel</p>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box width={{ xs: '100%', sm: '200px' }}>{institution.institution_phone}</Box>
              </Grid>
            </Grid>
            {/* この上に各項目 */}

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container xs={12}>
              <Grid item xs={12} sm={2}>
                <Box width={{ xs: '100%', sm: '200px' }}>
                  <GeneralPrimaryGhostButton
                    onClick={() => backToMoffRakuList(userId)}
                    innerText={'保存せず戻る'}
                    setCss={'width: 120px; height: 40px;'}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Box width={{ xs: '100%', sm: '200px' }}>
                  <GeneralPrimaryGhostButton
                    onClick={() => savePlan()}
                    innerText={'保存する'}
                    setCss={'width: 100px; height: 40px;'}
                  />
                </Box>
              </Grid>
              {/* planIDがある場合のみ表示する */}
              {planId && (
                <Grid item xs={12} sm={2}>
                  <Box width={{ xs: '100%', sm: '200px' }}>
                    <GeneralPrimaryGhostButton
                      onClick={() => duplicatePlan()}
                      innerText={'複製する'}
                      setCss={'width: 100px; height: 40px;'}
                    />
                  </Box>
                </Grid>
              )}
              {planId && (
                <Grid item xs={12} sm={3}>
                  <Box width={{ xs: '100%', sm: '200px' }}>
                    <GeneralPrimaryGhostButton
                      onClick={() => exportPlanToExcel()}
                      innerText={'Excelで出力する'}
                      setCss={'width: 150px; height: 40px;'}
                    />
                  </Box>
                </Grid>
              )}
              {planId && (
                <Grid item xs={12} sm={2}>
                  <Box width={{ xs: '100%', sm: '200px' }}>
                    <GeneralPrimaryGhostButton
                      onClick={() => delPlan()}
                      innerText={'削除する'}
                      setCss={'width: 100px; height: 40px;'}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default MoffRakuForm;
