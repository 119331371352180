import { Dispatch } from 'redux';

export const startFunc = (actionType: string) => {
  return {
    type: actionType,
  };
};

export const successFunc = (actionType: string, result: any) => {
  return {
    type: actionType,
    result: result,
  };
};

export const errorFunc = (actionType: string, error: any) => {
  return {
    type: actionType,
    result: error,
    error: true,
  };
};

/**
 * エラーポップアップ.
 * apiNameはどのAPIによるものか.
 *
 * @private
 * @param {string} apiName
 * @param {*} error
 * @memberof MoffCheck
 */
export const showAxiosError = (error: any) => {
  if (error.toString().indexOf('Network Error') !== -1) {
    const errorMessage =
      '通信エラーが発生しました。インターネット接続が出来ることを確認してから再度ページを読み込み直して下さい。';
    alert(errorMessage);
  } else if (error.toString().indexOf('timeout') !== -1) {
    const errorMessage =
      '通信エラーが発生しました。インターネット接続が良好な環境で、再度ページを読み込み直して下さい。';
    alert(errorMessage);
  } else {
    const errorMessage = '通信エラーが発生しました。' + error.toString();
    alert(errorMessage);
  }

  // TODO 通信エラーでログイン画面に戻る場合はこのコメントをはずす
  // ログインへ.
  window.location.href = '/';
};

/**
 * apiリクエストアクション発行の雛形
 *
 * @param {string} startActionType
 * @param {string} successActionType
 * @param {string} errorActionType
 * @param {*} uniqueLogicFunc
 * @memberof apiUtil
 */
export const apiRequestFunc = (
  startActionType: string,
  successActionType: string,
  errorActionType: string,
  uniqueLogicFunc: any,
) => {
  return async (dispatch: Dispatch<any>) => {
    // APIリクエストアクション開始
    dispatch(startFunc(startActionType));
    // axiosによるAPIリクエストはtryの中に記載する。複数のapiリクエスト内のどこかでエラーを検出した場合エラーを吐く。
    try {
      // 全てのAPIがうまくいった場合、最終的な結果を返す
      dispatch(successFunc(successActionType, await uniqueLogicFunc()));
    } catch (error) {
      // TODO: firefoxのみAPIリクエスト中にキャンセルされると、エラーになるためメイン画面にリダイレクトされてしまう。
      // 一時対処としてaxiosキャンセルエラーをエラーとみなさないように設定するが、もっと良い解決策を探りたい
      const firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      const isFirefoxAxiosCancelError =
        firefox && (error.message === 'error.response is undefined' || error.message === 'e.response is undefined');
      if (!isFirefoxAxiosCancelError) {
        // エラーを検知したら、エラーを返す
        showAxiosError(error);
      }
      dispatch(errorFunc(errorActionType, error.message));
    }
  };
};
