// 興味関心チェックreducer.
import * as InterestCheck from '../../constants/InterestCheck';

const initialState = {
  Loading: false,
  LoadedCount: 0,
};

export default function interestCheck(state: any = initialState, action: any) {
  switch (action.type) {
    case InterestCheck.LOADING:
      return Object.assign({}, state, {
        type: InterestCheck.LOADING,
        Loading: true,
        LoadedCount: 0,
      });
    case InterestCheck.FINISH:
      return Object.assign({}, state, {
        type: InterestCheck.FINISH,
        Loading: false,
        LoadedCount: state.LoadedCount + 1,
      });
    case InterestCheck.SUCCESS:
      return Object.assign({}, state, {
        type: InterestCheck.SUCCESS,
        Loading: false,
        result: action.result,
      });
    case InterestCheck.ERROR:
      return Object.assign({}, state, {
        type: InterestCheck.SUCCESS,
        Loading: false,
        error: action?.error,
      });
    default:
      return state;
  }
}
