import React, { FC, useState, useCallback } from 'react';
import { detect } from 'detect-browser';
import { useParams } from 'react-router-dom';

import { SCREEN_TYPE } from '../constants/Training';
import * as Header from '../constants/Header';

import UserDetail from './UserDetail';
import TrainingHeader from './TrainingHeader';
import TrainingLongTerm from '../components/Organisms/TrainingLongTerm';
import TrainingCompare from '../components/Organisms/TrainingCompare';
import TrainingDetail from '../components/Organisms/TrainingDetail';
import PopupContainer from './Popup';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';
import { TrainingUIProvider } from '../hooks/use-training-ui';

/**
 * CSSの描画.
 * A4横向き固定、ページはみ出る対策.
 */
const RenderCSS: FC = () => {
  let margin = 'margin:0mm !important;';
  // Firefoxの印刷調整.
  const browser = detect();
  if (browser) {
    const name: string = browser.name;
    if (name === 'firefox') {
      margin =
        'margin-left:10mm !important;' +
        'margin-right:10mm !important;' +
        'margin-top:0mm !important;' +
        'margin-bottom:0mm !important;';
    }
  }
  return (
    <>
      <style key="locomoCSS" type="text/css">
        {'@media print { html, body { height: 100% !important; width: 100% !important; } ' +
          '@page { size: A4 landscape !important; ' +
          margin +
          ' padding:0mm !important; } ' +
          '#wrapper .contentIn { min-height: 100% !important; margin:0mm !important; padding:0mm !important; ' +
          'height: 100% !important; width: 100% !important; } ' +
          '.report { padding-bottom: 0 !important; } ' +
          '}'}
      </style>
    </>
  );
};

const MoffTrainingContainer: FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [screenState, setScreenState] = useState<SCREEN_TYPE>(SCREEN_TYPE.Detail);

  // 詳細・比較・長期変化の切り替え.
  const changeState = useCallback((state: SCREEN_TYPE) => {
    setScreenState(state);
    if (state === SCREEN_TYPE.LongTerm || state === SCREEN_TYPE.Compare) {
      dispatchIdentifierToGTM(`${state?.toLowerCase()}_moff_training`);
    }
  }, []);

  return (
    <TrainingUIProvider initialUserId={userId}>
      <RenderCSS />
      <PopupContainer />
      <article className={screenState === SCREEN_TYPE.Compare ? 'report compare' : 'report'}>
        <UserDetail userId={userId} headerType={Header.MOFF_TRAINING} pageTitle="モフトレレポート" />
        <TrainingHeader screenType={screenState} changeState={changeState} />
        {(() => {
          switch (screenState) {
            case SCREEN_TYPE.LongTerm:
              return <TrainingLongTerm />;
            case SCREEN_TYPE.Compare:
              return <TrainingCompare changeState={changeState} />;
            default:
              return <TrainingDetail changeState={changeState} />;
          }
        })()}
      </article>
    </TrainingUIProvider>
  );
};

export default MoffTrainingContainer;
