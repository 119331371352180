import React from 'react';
import { MON_START_WEEK_NAMES } from '../../constants/Calendar';
import GeneralPrimarySelectButton from '../Atoms/GeneralPrimarySelectButton';
import Enumerable from 'linq';
import { Box } from '@material-ui/core';

interface DateListProps {
  dateList: number[];
  funcDateList: (index: number, value: number) => void;
}

const DateList: React.FC<DateListProps> = ({ dateList, funcDateList }) => {
  return (
    <Box display="flex" justifyContent="space-around" flexWrap="wrap" data-test="weekList">
      {Enumerable.from(dateList)
        .select<JSX.Element>((selectDate, index: number) => {
          return (
            <Box mb={{ xs: 2, md: 0 }} key={`week${index}`}>
              <GeneralPrimarySelectButton
                txt={MON_START_WEEK_NAMES[index]}
                onClick={() => funcDateList(index, selectDate)}
                selected={selectDate === 1}
              />
            </Box>
          );
        })
        .toArray()}
    </Box>
  );
};

export default DateList;
