// ユーザー詳細.
import React, { FC, useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

import store from '../store/configureStore';

import * as MoffAPIActions from '../actions/MoffAPI';
import * as HeaderActions from '../actions/Header';
import * as pdfCreateActions from '../actions/pdfCreate';

import * as Header from '../constants/Header';
import * as UserListField from '../constants/UserListField';

import { objectQuickSort, duplicateRepeatSort, dispatchIdentifierToGTM } from '../utils/commonUtil';

import PopupContainer from './Popup';

import PageLoader from '../components/Atoms/PageLoader';
import LiterallyReportAllUsersPrint from '../components/Organisms/LiterallyReportAllUsersPrint';

type AllState = ReturnType<typeof store.getState>;

const MoffCheckAllUsersPrintContainer: FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const moffAPIGlobalState = useSelector((state: AllState) => state.moffAPI);
  const pdfCreateGlobalState = useSelector((state: AllState) => state.pdfCreate);

  const [date, setDate] = useState(moment());
  const [, setDateYYYYMM] = useState(moment().format('YYYY-MM'));
  const [cachedUsers, setCachedUsers] = useState<UserListField.User[]>([] as UserListField.User[]);

  const refUsersList = useRef<any>();
  const refLiterallyReport = useRef<any>();

  // 最初に一回ユーザー一覧取得のために通信.
  useEffect(() => {
    (async () => {
      document.title = '機能訓練レポート一括PDF出力';
      dispatch(HeaderActions.setPage(Header.LITERALLY_REPORT_ALL_USER_PRINT));
      await dispatch(MoffAPIActions.getUsers());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 機能訓練レポート一括出力利用者リスト用データ取得後、利用者のデータをローカルステートにキャッシュさせておく
  useEffect(() => {
    // キャッシュさせておく.
    if (_.isEmpty(cachedUsers) && !_.isEmpty(moffAPIGlobalState.result ?? [])) {
      setCachedUsers(
        duplicateRepeatSort(UserListField.USERS_SORT_KEYS, moffAPIGlobalState.result ?? [], objectQuickSort),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffAPIGlobalState.Loaded]);

  // 月選択画面 矢印のアクション
  const minusMonthly = () => {
    const lastMonth = date.subtract(1, 'months');
    setDate(lastMonth);
    setDateYYYYMM(lastMonth.format('YYYY-MM'));
    dispatchIdentifierToGTM('all_users_change_month_literally');
  };

  const plusMonthly = () => {
    const nextMonth = date.add(1, 'months');
    setDate(nextMonth);
    setDateYYYYMM(nextMonth.format('YYYY-MM'));
    dispatchIdentifierToGTM('all_users_change_month_literally');
  };

  // トレーニング記録取得のAPIを実行
  const runAPILiterallyReport = async () => {
    // ローディングされている最中はボタンを押しても、APIが送信されない
    if (moffAPIGlobalState.Loading) {
      return;
    }
    // チェックボックスがonのユーザーのみを取得
    const checkedUsers: UserListField.User[] = refUsersList.current.getCheckedUsers();
    if (checkedUsers.length === 0) {
      alert('ユーザーが選択されていません');
      return;
    }
    const userIds = _.map(checkedUsers, (user) => user.user_id);
    const selectMonth = date.format('YYYY-MM');

    await dispatch(pdfCreateActions.createLiterallyPDF(selectMonth, userIds));
    dispatchIdentifierToGTM('output_all_users_report_literally');
  };

  /**
   * CSSの描画.
   */
  const RenderCSS: FC = () => {
    return (
      <style type="text/css">
        {/* 縦印刷, 日付・title名ヘッダの余白 */}
        {'@media print{@page {size: portrait; margin-top: 43px;}}'}
        {'@media print{#wrapper { padding:0; margin: 0; } header { display: none; }}'}
        {'@media print{.contentIn { padding-top:0!important; padding-bottom:0!important; }}'}
        {'@media print{.report { padding-bottom:0!important; }}'}
        {/* Edge対策 */}
        {'@media print{tr { page-break-inside: avoid; }}'}
      </style>
    );
  };

  return (
    <Fragment>
      <RenderCSS />
      <PopupContainer />
      {(!moffAPIGlobalState.Loaded && !cachedUsers) || pdfCreateGlobalState.Loading ? <PageLoader /> : null}
      <LiterallyReportAllUsersPrint
        location={location}
        refUsersList={refUsersList}
        minusMonthly={minusMonthly}
        plusMonthly={plusMonthly}
        allUsersPrintButtonClickHandler={runAPILiterallyReport}
        date={date}
        loaded={moffAPIGlobalState.Loaded}
        cachedUsers={cachedUsers}
        refLiterallyReport={refLiterallyReport}
      />
    </Fragment>
  );
};

export default MoffCheckAllUsersPrintContainer;
