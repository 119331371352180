import React, { FC, useRef, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import moment from 'moment';

import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import GeneralPrimaryTextArea from '../Atoms/GeneralPrimaryTextArea';
import MoffCheckMeasurementList from '../Molecules/MoffCheckMeasurementList';
import { MoffCheckV2ReportActionPayload } from '../../constants/MoffCheck';

const useStyles = makeStyles(() => ({
  ttlStyle01: {
    padding: '0 45px 0 5px',
    margin: '0 0 20px 0',
    fontSize: '25px',
    borderBottom: 'solid 1px #dcdcdc',
  },
}));
export interface MoffCheckCommentProps {
  comment: string;
  allMeasurementReportOutput: (comment?: string) => Promise<void>;
  allMeasurementResult: MoffCheckV2ReportActionPayload;
  commentSave: (comment: string) => Promise<void>;
}

const MoffCheckComment: FC<MoffCheckCommentProps> = ({
  comment,
  allMeasurementReportOutput,
  allMeasurementResult,
  commentSave,
}) => {
  const classes = useStyles();
  const commentRef = useRef<HTMLTextAreaElement>(null);
  const commentTargetMonth = allMeasurementResult?.data?.month?.first?.replace('.', '-');

  useLayoutEffect(() => {
    if (commentRef.current) {
      commentRef.current.value = comment;
    }
  }, [comment]);

  const getComment = () => {
    return commentRef.current ? commentRef.current.value : '';
  };

  return (
    <>
      <Box component="section" m="30px 0 0 0" p="30px 25px 0">
        <h2 className={classes.ttlStyle01}>
          モフトレチェックのコメントを入力してください
          {commentTargetMonth ? `（${moment(commentTargetMonth).format('YYYY年MM月')}）` : null}
        </h2>
        <GeneralPrimaryTextArea
          ref={commentRef}
          placeholder="ここにコメントを入力してください"
          customCss={'width: 100%; max-width: 500px; height: 200px; margin: 0 auto; display: block;'}
        />
        <Box width="100%" maxWidth="500px" m="0 auto" display="flex" justifyContent="space-around">
          <GeneralPrimaryButton
            txt="レポート出力"
            setCss="width: 180px; height: 50px; margin: 20px 10px 50px;"
            onClick={() => allMeasurementReportOutput(getComment())}
          />
          <GeneralPrimaryButton
            txt="コメント保存"
            setCss="width: 180px; height: 50px; margin: 20px 10px 50px;"
            onClick={() => commentSave(getComment())}
          />
        </Box>
        <Box width="100%" m="20px 0" fontSize="20px">
          <h2 className={classes.ttlStyle01}>モフトレチェック測定結果一覧</h2>
        </Box>
        <MoffCheckMeasurementList allMeasurementResult={allMeasurementResult} />
      </Box>
    </>
  );
};

export default MoffCheckComment;
