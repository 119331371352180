import moment, { Moment } from 'moment';
import { WEEK_NAMES } from '../constants/Calendar';
import { mapReturnObject } from './commonUtil';

// 日付に関わる共通処理のユーティリティ.
export class MDate {
  public yearmonth!: string;

  private _year!: string;
  private _month!: string;
  private _day!: string;

  private _date!: Date;

  get year(): string {
    return this._year;
  }

  set year(theYear: string) {
    this._year = theYear;
  }

  get month(): string {
    return this._month;
  }

  set month(theMonth: string) {
    this._month = theMonth;
  }

  get day(): string {
    return this._day;
  }

  set day(theDay: string) {
    this._day = theDay;
  }

  // コンストラクタ
  constructor(date: Date = new Date()) {
    if (!(this instanceof MDate)) {
      return new MDate();
    }

    this._date = date;
    this.refresh();
  }

  // yyyy-mm-ddを取得.
  getYearMonthDay = (): string => {
    return `${this.year}-${this.month}-${this.day}`;
  };

  // yyyy-mmを取得.
  getYearMonth = (): string => {
    return `${this.year}-${this.month}`;
  };

  // 初期時や更新時.
  refresh = () => {
    this.year = this._date.getFullYear().toString();
    this.month = (this._date.getMonth() + 1).toString();
    if (Number(this.month) < 10) {
      this.month = `0${this.month}`;
    }

    const myDay = this._date.getDate();
    if (myDay < 10) {
      this.day = `0${myDay}`;
    } else {
      this.day = myDay.toString();
    }

    this.yearmonth = `${this.year}-${this.month}`;
  };

  toStringDate = (val: any): string => {
    if (Number(val) < 10) {
      return `0${val}`;
    } else {
      return val.toString();
    }
  };

  // 次の日にする.
  setNextDate = (day = 1) => {
    this._date.setDate(this._date.getDate() + day);
    this.refresh();
  };

  // 前日にする.
  setPreDate = (day = 1) => {
    this._date.setDate(this._date.getDate() - day);
    this.refresh();
  };

  // 先月の末日を取得する.
  getEndOfLastMonth = (): Date => {
    const dt = new Date(this._date.getFullYear(), this._date.getMonth(), 0);
    return dt;
  };

  /**
   * 末日を取得する.
   *
   * @memberof MDate
   */
  getEndOfMonth = (month = 0): Date => {
    const dt: Date = new Date(this._date.getFullYear(), this._date.getMonth() + 1 + month, 0);
    return dt;
  };

  // 翌月の末日を取得する.
  getEndOfNextMonth = (): Date => {
    const dt: Date = new Date(this._date.getFullYear(), this._date.getMonth() + 2, 0);
    return dt;
  };

  // 次の月にする.
  setNextMonth = (month = 1) => {
    const nextMonth: Date = this.getEndOfNextMonth();
    if (this._date.getDate() > nextMonth.getDate()) {
      // 次の月の月末の方が小さい時(1/31 => 2/28)はそのまま次の月末でトリミング.
      this._date = nextMonth;
    } else {
      // 単純に次の月.
      this._date.setMonth(this._date.getMonth() + month);
    }

    this.refresh();
  };

  // 前月にする.
  setPreMonth = (month = 1) => {
    const targetMonth = this.getEndOfMonth(-month);
    if (this._date.getDate() > targetMonth.getDate()) {
      // 先月の月末の方が小さい時(3/31 => 2/28)はそのまま次の月末でトリミング.
      this._date = targetMonth;
    } else {
      // 単純に先月.
      this._date.setMonth(this._date.getMonth() - month);
    }

    this.refresh();
  };

  // カレンダー
  makeCalendar = (year: number, month: number): { day: number | string }[][] => {
    // 1日の曜日
    const firstDay = new Date(year, month - 1, 1).getDay();
    // 晦日の日にち
    const lastDate = new Date(year, month, 0).getDate();
    // 日にちのカウント
    let dayIdx = 1;

    const calendar: { day: number | string }[][] = [];
    for (let w = 0; w < 6; w++) {
      const week: { day: number | string }[] = [];
      // 空白行をなくすため
      if (lastDate < dayIdx) {
        break;
      }
      for (let d = 0; d < 7; d++) {
        if (w === 0 && d < firstDay) {
          week[d] = {
            day: '',
          };
        } else if (w === 6 && lastDate < dayIdx) {
          week[d] = {
            day: '',
          };
          dayIdx++;
        } else if (lastDate < dayIdx) {
          week[d] = {
            day: '',
          };
          dayIdx++;
        } else {
          week[d] = {
            day: dayIdx,
          };
          dayIdx++;
        }
      }

      calendar.push(week);
    }

    return calendar;
  };
}

// 1桁の数字を0埋めで2桁にする
export function toDoubleDigits(num: string): string {
  num += '';
  if (num.length === 1) {
    num = `0${num}`;
  }

  return num.toString();
}

interface Time {
  full: string;
  day: string;
  time: string;
  datetimeT: string;
}

// 現在日付をYYYY-MM-DD HH:MI:SS形式で取得(2017-06-08 09:43:00みたいな)
export function getCurrentTime(dateNumber: number | undefined = undefined): Time {
  let date: Date = new Date();
  if (dateNumber !== undefined) {
    if (date && date.getTime && dateNumber && String(dateNumber).match(/^-?[0-9]+$/)) {
      date = new Date(date.getTime() + Number(dateNumber) * 24 * 60 * 60 * 1000);
    }
  }

  const yyyy: number = date.getFullYear();
  const mm: string = toDoubleDigits((date.getMonth() + 1).toString());
  const dd: string = toDoubleDigits(date.getDate().toString());
  const hh: string = toDoubleDigits(date.getHours().toString());
  const mi: string = toDoubleDigits(date.getMinutes().toString());
  const ss: string = toDoubleDigits(date.getSeconds().toString());
  const cuurrentTime: Time = { full: '', day: '', time: '', datetimeT: '' };
  cuurrentTime.full = `${yyyy}-${mm}-${dd} ${hh}:${mi}:${ss}`;
  cuurrentTime.day = `${yyyy}-${mm}-${dd}`;
  cuurrentTime.time = `${hh}:{mi}`;
  cuurrentTime.datetimeT = `${yyyy}-${mm}-${dd}T${hh}:${mi}`; // input datetime用フォーマット

  return cuurrentTime;
}

// DBに格納された形式から、日時とかUnixTimeとかURL用の形式とかに変換した型.
interface ConvertedTime {
  day: string;
  ux: number;
  uuid: string;
  'ux-qa': string;
  0: any;
}

export function convertDay(day: string): string {
  let theDay = day;
  if (theDay.length > 16) {
    // 秒は不要.
    theDay = theDay.slice(0, -3);
  }

  // 一覧表示は「/」ではなく「.」区切り
  return theDay.replace(/\//g, '.');
}

// DBに格納された形式から、日時とかUnixTimeとかURL用の形式とかに変換した配列を返す
// 入力：2017/01/01 09:00:00 00f8c99e-7ff3-42e9-b5b9-a1eecc9f000d
// 出力：
// {
//   day: "2018/02/02 13:14", ux: 1517577240, uuid: "14213103-7919-4242-aefd-c4908b2d3b0f",
//   ux-qa: "1517577240_14213103-7919-4242-aefd-c4908b2d3b0f"
// }
export function convertTime(timeTex: string): ConvertedTime {
  const sprit: string[] = timeTex.split(' ');
  sprit[0] = sprit[0].replace(/-/g, '/');
  const date = new Date(`${sprit[0]} ${sprit[1]}`);

  const res: ConvertedTime = {
    day: '',
    ux: 0,
    uuid: '',
    'ux-qa': '',
  } as ConvertedTime;

  // FIXME: 0で入れるのは気に入らないが元のコードもこうなっているので検討したい.
  res[0] = sprit[0];
  res.day = `${sprit[0]} ${sprit[1]}`; // ex. 2017-06-14 13:14:12
  res.ux = (date.getTime() - date.getTimezoneOffset() * 60 * 1000) / 1000; // ex.1497446052 ⇒ unix_timestamp
  res.uuid = sprit[2];
  res['ux-qa'] = `${res.ux}_${res.uuid}`;
  return res;
}

export const convertFormatTimeToDate = (time: string) => moment(time).format('YYYY-MM-DD');

// 0: 正しい.
// 1: フォーマットは正しいが妥当ではない.
// 2: 全く正しくない.
export const VALID_FORMAT = 0;
export const INVALID_FORMAT = 1;
export const BAD_FORMAT = 2;

// エラーメッセージ.
export const INVALID_ERR_MSG = '期間は１ヶ月以内を選択してください。';

// 期間のバリデーションチェック.
export function validateTerm(startDateStr: string, endDateStr: string): number {
  if (!startDateStr || !endDateStr) {
    return BAD_FORMAT;
  }

  // 値が"YYYY-MM-DD"かをチェック.
  const regex = /\d{4}-\d{2}-\d{2}/;
  if (startDateStr.match(regex) === null || endDateStr.match(regex) === null) {
    return BAD_FORMAT;
  }

  let splitedDate: string[] = startDateStr.split('-');
  const startDate = new Date(Number(splitedDate[0]), Number(splitedDate[1]) - 1, Number(splitedDate[2]));
  splitedDate = endDateStr.split('-');
  const endDate = new Date(Number(splitedDate[0]), Number(splitedDate[1]) - 1, Number(splitedDate[2]));
  const endDateOneMonthAgo = new Date(Number(splitedDate[0]), Number(splitedDate[1]) - 2, Number(splitedDate[2]));
  if (endDateOneMonthAgo.getTime() > startDate.getTime()) {
    return INVALID_FORMAT;
  } else if (startDate.getTime() > endDate.getTime()) {
    return INVALID_FORMAT;
  } else {
    return VALID_FORMAT;
  }
}

// date_controller.jsから移植.
export function validateLongterm(startDateStr: string, endDateStr: string): boolean {
  if (!startDateStr || !endDateStr) {
    return false;
  }

  let splitedDate: string[] = startDateStr.split('-');
  const startDate: Date = new Date(Number(splitedDate[0]), Number(splitedDate[1]) - 1);
  splitedDate = endDateStr.split('-');
  const endDate: Date = new Date(Number(splitedDate[0]), Number(splitedDate[1]) - 1);

  // 2016年2月1日(サービス開始: 0開始なので1月ではない).
  const startThisServiceDate = new Date(2016, 1, 1);

  // 12ヶ月以内.
  const endDateTwelveMontsAgo = new Date(Number(splitedDate[0]), Number(splitedDate[1]) - 12);
  if (startDate.getTime() < startThisServiceDate.getTime() || endDate.getTime() < startThisServiceDate.getTime()) {
    return false;
  } else if (startDate.getTime() > endDate.getTime()) {
    return false;
  } else if (endDateTwelveMontsAgo.getTime() > startDate.getTime()) {
    return false;
  } else {
    return true;
  }
}

// 0埋め, digitは埋めたあとの全体の桁数.
export const zeroPadding = function (num: number | string, digit = 2): string {
  const numberLength = String(num).length;
  if (digit > numberLength) {
    return new Array(digit - numberLength + 1).join('0') + num;
  } else {
    return num.toString();
  }
};

// 長期期間履歴.
// 2017-11, 2018-02より期間内の配列を返す.
// ["2017-11", "2017-12", "2018-01", "2018-02"]
export function getLongtermHistory(startDate: string, endDate: string): string[] {
  // start_dateからend_dateまでのデータをself.history_monthlyとself.history_monthly_angleに格納する.
  const yearmonthStart: string = startDate;
  const yearmonthEnd: string = endDate;

  const ys: string[] = yearmonthStart.split('-');
  const startYear: string = ys[0];
  const startMonth: string = ys[1];
  const ye = yearmonthEnd.split('-');
  const endYear: string = ye[0];
  const endMonth: string = ye[1];
  const diffYear: number = Number(endYear) - Number(startYear);
  const diffMonth: number = Number(endMonth) - Number(startMonth);

  const yearmonthArray: string[] = [yearmonthStart];

  if (diffYear > 0) {
    // 年度が変化してる場合、年度変化時の計算 (diff_year -1 ) * 12) の月数と、(12 - start_month)の月数と、end_monthの月数をstart_yearとstart_monthから追加する.
    const additionalMonth = (diffYear - 1) * 12 + (12 - Number(startMonth)) + Number(endMonth);
    let tmpYear = Number(startYear);
    let tmpMonth = Number(startMonth);
    for (let i = 1; i <= additionalMonth; i++) {
      if (tmpMonth + i > 12) {
        tmpMonth = 1 - i;
        tmpYear += 1;
      }
      const tmpYearmonth = `${tmpYear}-${zeroPadding(Number(tmpMonth) + i, 2)}`;
      yearmonthArray.push(tmpYearmonth);
    }
  } else {
    // 年度の変化はなし
    if (diffMonth >= 1) {
      // 月の変化あり
      for (let i = 1; i <= diffMonth; i++) {
        const tmpYearmonth = `${startYear}-${zeroPadding(Number(startMonth) + i, 2)}`;
        yearmonthArray.push(tmpYearmonth);
      }
    } else {
      const tmpYearmonth = `${startYear}-${zeroPadding(Number(startMonth), 2)}`;
      yearmonthArray.push(tmpYearmonth);
    }
  }

  return yearmonthArray;
}

/**
 * 指定ヶ月(デフォルトは1年分)のyyyy-mmの配列を取得.
 *
 * @export
 * @param {string} yearSelected
 * @param {string} monthSelected
 * @param {number} [deltaMonths=12]
 * @returns {string[]}
 */
export function getYearMonthArray(yearSelected: string, monthSelected: string, deltaMonths = 12): string[] {
  const yearmonthStart = `${yearSelected}-${monthSelected}`;
  const yearmonthArray: string[] = [yearmonthStart];
  for (let i = 1; i < deltaMonths; i++) {
    const tmpDate = new Date(Number(yearSelected), Number(monthSelected) - i - 1);
    const yyyy = tmpDate.getFullYear();
    const mm = toDoubleDigits((tmpDate.getMonth() + 1).toString());
    const tmpYearmonth = yyyy + '-' + zeroPadding(Number(mm), 2);
    yearmonthArray.push(tmpYearmonth);
  }

  return yearmonthArray;
}

/**
 * 指定ヶ月(デフォルトは1年分)での最初の日と指定月の最後の日.
 *
 * @export
 * @param {string} yearSelected
 * @param {string} monthSelected
 * @param {number} [deltaMonths=12]
 * @returns {string[]}
 */
export function getFirstEndDay(yearSelected: string, monthSelected: string, deltaMonths = 12): string[] {
  // 1年分の配列
  const yearMonthArr: string[] = getYearMonthArray(yearSelected, monthSelected, deltaMonths);
  const startDate = moment(yearMonthArr.slice(-1)[0]).format('YYYY-MM-01'); // 最初の日.
  // その月の月末(31とは限らないため).
  const endDate = moment(yearMonthArr[0]).endOf('month').format('YYYY-MM-DD');
  return [startDate, endDate];
}

/**
 * 生年月日を日本語フォーマット.
 * @export
 * @param {string} year
 * @param {(string|undefined)} month
 * @param {(string|undefined)} date
 * @returns {string}
 */
export function formatBirthday(year: string, month: string | undefined, date: string | undefined): string {
  return (
    `${year}年${month ? ('00' + month).slice(-2).toString() + '月' : ''}` +
    `${date ? ('00' + date).slice(-2).toString() + '日' : ''}生`
  );
}

/**
 * 年齢計算.
 *
 * @export
 * @param {number} year
 * @param {number} month
 * @param {number} date
 * @returns {number}
 */
export function calcAge(year: number, month: number, date: number): number {
  const today = new Date();

  // Dateインスタンスに変換
  const birthDate = new Date(year, month - 1, date);
  // 月日がないときは単純に現在の年から引くだけ.
  if (Number.isNaN(month) || Number.isNaN(date) || birthDate.getMonth() !== month - 1 || birthDate.getDate() !== date) {
    return today.getFullYear() - year;
  }

  // 文字列に分解
  const y2 = ('0000' + birthDate.getFullYear().toString()).slice(-4);
  const m2 = ('00' + (birthDate.getMonth() + 1).toString()).slice(-2);
  const d2 = ('00' + birthDate.getDate().toString()).slice(-2);

  // 今日の日付
  const y1 = ('0000' + today.getFullYear().toString()).slice(-4);
  const m1 = ('00' + (today.getMonth() + 1).toString()).slice(-2);
  const d1 = ('00' + today.getDate().toString()).slice(-2);

  // 引き算
  const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
  return age;
}

/*
 * 居宅訪問用変換メソッド.
 * (例) 2018年5月15日(金) 11:06 〜 11:06
 * @method convertHomeVisitDate
 * @param {String} dateString 変換元: yyyy-mm-dd hh:mm-hh:mm
 * @return {String} 変換の日付.
 */
export function convertHomeVisitDate(dateString: string): string {
  // 曜日生成のためまず日付のみ取り出す.
  const day: string = dateString.replace(/^(\d{4})-(\d{1,2})-(\d{1,2}).*/, '$1/$2/$3');
  // 曜日.
  const dayOfWeek: number = new Date(day).getDay();

  const formatted: string = dateString.replace(
    /^(\d{4})-(\d{1,2})-(\d{1,2}) (\d{1,2}):(\d{1,2})-(\d{1,2}):(\d{1,2})/,
    `$1年$2月$3日(${WEEK_NAMES[dayOfWeek]}) $4:$5 〜 $6:$7`,
  );
  return formatted;
}

/*
 * 生活チェック用変換メソッド.
 * (例) 2018年5月15日(金) 11:06 〜 11:06
 */
export function convertLifeCheckDate(visitedTimeStart: string, visitedTimeEnd: string): string {
  const startDateTime: Moment = moment(visitedTimeStart);
  const endDateTime: Moment = moment(visitedTimeEnd);
  if (!(startDateTime.isValid() && endDateTime.isValid())) {
    return '';
  }
  const date = startDateTime.format('YYYY年MM月DD日');
  const weekDay = WEEK_NAMES[startDateTime.day()];
  const startTime = startDateTime.format('H:mm');
  const endTime = endDateTime.format('H:mm');

  return `${date}(${weekDay}) ${startTime} 〜 ${endTime}`;
}

/**
 * 興味関心チェックシート・聞き取り日用日付変換メソッド.
 * (例) 2018年5月15日 火曜日
 * @method convertInterestCheckVisitDate
 * @param {String} dateString 変換元: yyyy-mm-dd hh:mm
 * @return {String} 変換の日付.
 */
export function convertInterestCheckVisitDate(dateString: string): string {
  // 曜日生成のためまず日付のみ取り出す.
  const day: string = dateString.replace(/^(\d{4})-(\d{1,2})-(\d{1,2}).*/, '$1/$2/$3');
  // 曜日.
  const dayOfWeek: number = new Date(day).getDay();
  // 置換(時間を消すので.*)
  const formatted: string = dateString.replace(
    /^(\d{4})-(\d{1,2})-(\d{1,2}).*/,
    `$1年$2月$3日 ${WEEK_NAMES[dayOfWeek]}曜日`,
  );
  return formatted;
}

/**
 * モフトレチェック/興味関心チェック・利用者一覧用日付変換メソッド.
 * (例) 2018/5/15
 * @method convertInterestCheckUpdateDate
 * @param {String} dateString 変換元: yyyy-mm-dd hh:mm
 * @return {String} 変換の日付.
 */
export function convertInterestCheckUpdateDate(dateString: string): string {
  // 置換(時間を消すので.*)
  const formatted: string = dateString.replace(/^(\d{4})-(\d{1,2})-(\d{1,2}).*/, `$1/$2/$3`);
  return formatted;
}

/**
 * 年月日が正しいかを判定.
 *
 * @export
 * @param {*} year 年.
 * @param {*} month 月.
 * @param {*} day 日.
 * @returns {boolean} 正しいときはtrue.
 */
export function isValidDate(year: any, month: any, day: any): boolean {
  const date = new Date(year, month - 1, day);
  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}

// カレンダー
export const makeCalendar = (year: number, month: number): { day: number | string }[][] => {
  // 1日の曜日
  const firstDay = new Date(year, month - 1, 1).getDay();
  // 晦日の日にち
  const lastDate = new Date(year, month, 0).getDate();
  // 日にちのカウント
  let dayIdx = 1;

  const calendar: { day: number | string }[][] = [];
  for (let w = 0; w < 6; w++) {
    const week: { day: number | string }[] = [];
    // 空白行をなくすため
    if (lastDate < dayIdx) {
      break;
    }
    for (let d = 0; d < 7; d++) {
      if (w === 0 && d < firstDay) {
        week[d] = {
          day: '',
        };
      } else if (w === 6 && lastDate < dayIdx) {
        week[d] = {
          day: '',
        };
        dayIdx++;
      } else if (lastDate < dayIdx) {
        week[d] = {
          day: '',
        };
        dayIdx++;
      } else {
        week[d] = {
          day: dayIdx,
        };
        dayIdx++;
      }
    }

    calendar.push(week);
  }

  return calendar;
};

/**
 * 【momentラッパー】日付 YYYY-MM-DDから日の数値を返す
 * @param {string} date 日付
 * @return {number} 日
 */
export const getDay = (date: string): number => moment(date).date();

/**
 * 【momentラッパー】日付の差分を計算
 * @return {number} 日
 */
export const diffDate = <T, U>(
  baseDate: T,
  diffDate: U,
  unitOfTime?: moment.unitOfTime.Diff,
  precise?: boolean,
): number => moment(baseDate).diff(moment(diffDate), unitOfTime, precise);

/**
 * 【momentラッパー】日付 YYYY-MM-DDから日の数値を返す
 * @param {string} date 日付
 * @param {string} formatStyle 変換形式。例： YYYY-MM-DD
 * @return {string} 指定形式の日付文字列
 */
export const formatDate = (date: string, formatStyle: string): string => moment(date).format(formatStyle);

/**
 * 31日の中で、引数の配列に存在する日をtrue
 * @param {number[]} dayArray 日の配列
 * @return {{ [day: number]: boolean }}
 */
export const getDayFlag = (dayArray: number[]): { [day: number]: boolean } =>
  mapReturnObject([...Array(31)], (v, i) => ({
    [i + 1]: dayArray.includes(i + 1) ? true : false,
  }));

export const getWareki = (year: number, month: number, date: number) => {
  const dt = new Date(year, month, date);
  const opt: Intl.DateTimeFormatOptions = { year: 'numeric' };
  return dt.toLocaleDateString('ja-JP-u-ca-japanese', opt);
};
