/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Box } from '@material-ui/core';
import { FC } from 'react';

const baseBtnCss = (setCss: string) => {
  return css`
    width: 100%;
    height: 100%;
    padding: 1px 6px;
    box-shadow: 0 0 4px 1px #c6c5c5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1em;
    font-family: Arial, Helvetica, sans-serif;
    &:hover {
      opacity: 0.7;
    }
    ${setCss}
  `;
};

interface GeneralPrimarySquareButtonProps {
  /** ボタンのテキスト */
  text: string;
  /** 外部から渡すスタイル */
  setCss: string;
  /** アイコンのコンポーネント */
  icon?: JSX.Element;
  /** ボタンがクリックされた時のイベントハンドラ */
  onClick?: (key: string) => void;
  /** アイコンがクリックされた時のイベントハンドラ */
  onIconClick?: (key: string) => void;
}

const GeneralPrimarySquareButton: FC<GeneralPrimarySquareButtonProps> = ({
  text,
  setCss,
  icon,
  onClick,
  onIconClick,
}) => {
  return (
    <Box
      onClick={() => (onClick ? onClick(text) : null)}
      css={baseBtnCss(setCss)}
      data-test="general-primary-square-button"
    >
      <Box width="100%" px="1px" display="flex" justifyContent="space-between" alignItems="center">
        <Box width="100%" textAlign="center">
          {text}
        </Box>
        {!!icon && (
          <Box onClick={() => !!onIconClick && onIconClick(text)} data-test="general-primary-square-button-icon">
            {icon}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default GeneralPrimarySquareButton;
