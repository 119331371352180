// 個別機能訓練に関する記録・詳細(Show).
import React, { FC } from 'react';
import KirokuCommon from '../Molecules/KirokuCommon';
import KirokuForm from './KirokuForm';

import { User } from '../../constants/UserListField';
import { KirokuRequestParamsFormat, KirokuFormParamsFormat, SuggestWord } from '../../constants/Kiroku';
import { Box } from '@material-ui/core';

interface KirokuEditProps {
  isLoaded: boolean;
  isLoadingOrNotImplementTraining: boolean;
  cachedTraining: KirokuRequestParamsFormat[];
  cachedUser: User;
  date: moment.Moment;
  minusMonthly: () => void;
  plusMonthly: () => void;
  toKirokuCancel: () => void;
  submitKiroku: (
    data: KirokuFormParamsFormat[],
    defaultValues: KirokuRequestParamsFormat[],
    isDirty: boolean,
    suggestWordsGroupSubject: SuggestWord[],
  ) => void;
}

// 詳細本体.
const KirokuEdit: FC<KirokuEditProps> = ({
  isLoaded,
  isLoadingOrNotImplementTraining,
  cachedTraining,
  cachedUser,
  date,
  minusMonthly,
  plusMonthly,
  toKirokuCancel,
  submitKiroku,
}) => {
  return (
    <Box component="section" mt="0">
      <KirokuCommon date={date} cachedUser={cachedUser} minusMonthly={minusMonthly} plusMonthly={plusMonthly} />
      {isLoadingOrNotImplementTraining ? (
        <KirokuForm toKirokuCancel={toKirokuCancel} defaultValues={[]} submitKiroku={submitKiroku} />
      ) : null}
      {isLoaded && cachedTraining.length !== 0 ? (
        <KirokuForm toKirokuCancel={toKirokuCancel} defaultValues={cachedTraining} submitKiroku={submitKiroku} />
      ) : null}
    </Box>
  );
};

export default KirokuEdit;
