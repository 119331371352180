import React, { FC } from 'react';
import { Box } from '@material-ui/core';

import Popup from '../../containers/Popup';
// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-ignore
// @ts-ignore
// import UserListHeader from '../../containers/UserListHeader';
// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-ignore
// @ts-ignore
// import KanaList from '../../containers/KanaList';
import UserInputMoffRaku from '../../containers/UserInputMoffRaku';
import { MENU_TYPE } from '../../constants/Menu';
import PageLoader from '../Atoms/PageLoader';

interface UserSelectMoffRakuProps {
  moffAPI: any;
}

const MoffRakuUsers: FC<UserSelectMoffRakuProps> = ({ moffAPI }) => {
  return (
    <Box>
      <Popup />
      {moffAPI.Loaded ? null : <PageLoader />}
      {moffAPI.Loaded ? (
        <UserInputMoffRaku
          type={MENU_TYPE.MoffRakuUsers}
          users={moffAPI.result.users}
          // users_basicinfo={moffAPI.result.usersBasicInfo}
          icd10={moffAPI.result.icd10}
          disease={moffAPI.result.disease}
        />
      ) : null}
    </Box>
  );
};

export default MoffRakuUsers;
