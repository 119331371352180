import React, { FC } from 'react';
import moment from 'moment';

import DateSelector from './DateSelector';

import { formatBirthday } from '../../utils/dateUtil';
import { Male, Female, User } from '../../constants/UserListField';
import { DATE_DISPLAY_TYPE } from '../../constants/Training';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  ttlStyle01: {
    padding: '0 45px 0 5px',
    margin: '0 25px 20px 25px',
    fontSize: '25px',
    borderBottom: 'solid 1px #dcdcdc',
  },
  userInfo: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8em',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1em',
    },
    '@media print': {
      fontSize: '0.9em',
    },
  },
  userName: {
    paddingLeft: '1em',
    fontSize: '150%',
    '@media print': {
      fontSize: '140%',
    },
  },
  userBirthday: {
    fontSize: '80%',
    '@media print': {
      fontSize: '70%',
    },
  },
}));

interface KirokuCommonProps {
  date: moment.Moment;
  cachedUser: User;
  minusMonthly: () => void;
  plusMonthly: () => void;
}

const KirokuCommon: FC<KirokuCommonProps> = ({ minusMonthly, plusMonthly, cachedUser, date }) => {
  const classes = useStyles();

  return (
    <>
      <h2 className={classes.ttlStyle01}>個別機能訓練に関する記録</h2>
      <Box>
        <Box>
          <Box
            mx="25px"
            mb={{ xs: '0', sm: '30px' }}
            display="flex"
            flexWrap="wrap"
            justifyContent={{ xs: 'center', sm: 'space-between' }}
            alignItems="center"
          >
            <Box className={classes.userInfo}>
              <p>氏名</p>
              <p className={classes.userName}>
                {cachedUser.user_name} 様{'　'}
                <span className={classes.userBirthday}>
                  {formatBirthday(cachedUser.user_birth_year, cachedUser.user_birth_month, cachedUser.user_birth_day)}
                  {'　'}
                  {`${cachedUser.user_gender === 0 ? Male : Female}性`}
                </span>
              </p>
            </Box>
            <Box my={{ xs: '30px', sm: '0' }} width={{ xs: '300px', md: '400px' }}>
              {/* <>矢印と日付 */}
              <DateSelector
                date={date}
                className={''}
                dateDisplayType={DATE_DISPLAY_TYPE.Month}
                minusDate={minusMonthly}
                plusDate={plusMonthly}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default KirokuCommon;
