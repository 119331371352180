import React, { FC } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box } from '@material-ui/core';

import { SCREEN_TYPE, TRAINING_REPORT_NAME, TRAINING_TABLE_PATTERN } from '../../constants/Training';

import MonthlyReport from '../Molecules/Reports/MonthlyReport';
import DailyReport from '../Molecules/Reports/DailyReport';
import TrainingGraphs from '../Molecules/TrainingGraphs';
import TrainingCustomize from '../Molecules/TrainingCustomize';
import PageLoader from '../Atoms/PageLoader';
import { useTrainingUI } from '../../hooks/use-training-ui';
import { useTrainingFetchDetail } from '../../hooks/use-training-fetch-detail';

const useStyles = makeStyles(() => ({
  ttlStyle01: {
    padding: '0 45px 0 5px',
    margin: '0 0 20px 0',
    fontSize: '25px',
    borderBottom: 'solid 1px #dcdcdc',
  },
  sectionGraph: {
    '@media print': {
      margin: '-3cm 0 0 0',
      paddingTop: 0,
    },
  },
  sectionCustomize: {
    padding: '30px 25px 0',
    margin: '30px 0 0 0',
    '@media print': {
      display: 'none',
    },
  },
  sectionMonthly: {
    padding: '30px 25px 0',
    margin: '30px 0 0 0',
    '@media print': {
      'page-break-after': 'always',
      margin: '-2cm 0 0 0',
    },
  },
  sectionDaily: {
    padding: '30px 25px 0',
    margin: '60px 0 0 0',
    '@media print': {
      'page-break-after': 'always',
      margin: '2cm 0 0 0',
    },
  },
}));

interface TrainingDetailProps {
  changeState: (state: SCREEN_TYPE) => void;
}

const TrainingDetail: FC<TrainingDetailProps> = ({ changeState }) => {
  const classes = useStyles();
  const { isToggleOn, togglePosition, setTogglePosition } = useTrainingUI();
  const {
    date,
    monthlyData,
    moffApiState,
    minusMonthly,
    plusMonthly,
    minusDaily,
    plusDaily,
  } = useTrainingFetchDetail();
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
  };
  return (
    <>
      {moffApiState.Loaded && moffApiState?.result?.training ? null : <PageLoader />}
      {!isToggleOn ? (
        <Box>
          <TrainingCustomize />
          <Box component="section" className={classes.sectionMonthly}>
            <h2 className={classes.ttlStyle01}>トレーニング履歴（月次）</h2>
            <MonthlyReport
              key={TRAINING_REPORT_NAME.Detail}
              className={TRAINING_REPORT_NAME.Detail}
              tablePattern={matches.xs ? TRAINING_TABLE_PATTERN.tight : TRAINING_TABLE_PATTERN.default}
              history={monthlyData}
              date={date}
              togglePosition={togglePosition}
              changeState={changeState}
              minusMonthly={minusMonthly}
              plusMonthly={plusMonthly}
              changeTogglePosition={setTogglePosition}
            />
          </Box>
          <Box component="section" className={classes.sectionDaily}>
            <h2 className={classes.ttlStyle01}>トレーニング履歴（日次）</h2>
            <Box width="100%">
              <DailyReport
                key={TRAINING_REPORT_NAME.Detail}
                className={TRAINING_REPORT_NAME.Detail}
                history={monthlyData}
                isDetailComponent={true}
                isCompareStartTerm={false}
                date={date}
                minusDaily={minusDaily}
                plusDaily={plusDaily}
                changeState={changeState}
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <TrainingCustomize />
          <Box component="section" m="30px 0 0 0" p="30px 25px 0" className={classes.sectionGraph}>
            <h2 className={classes.ttlStyle01}>1ヶ月の変化</h2>
            <TrainingGraphs
              className={TRAINING_REPORT_NAME.Detail}
              historyArray={[monthlyData]}
              date={date}
              screenType={SCREEN_TYPE.Detail}
              graphWidth={matches.xs ? 460 : 920}
              graphHeight={matches.xs ? 330 : 370}
              togglePosition={togglePosition}
              minusMonthly={minusMonthly}
              plusMonthly={plusMonthly}
              changeState={changeState}
              changeTogglePosition={setTogglePosition}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default TrainingDetail;
