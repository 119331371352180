/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import store from '../store/configureStore';

import * as PasswordActions from '../actions/Password';
import GeneralPrimaryButton from '../components/Atoms/GeneralPrimaryButton';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

const input = css`
  width: 350px;
  margin: 25px 15px;
  padding: 12px 8px;
  border: 0;
  border: solid 1px #8c8c8c;
  border-radius: 4px;
  font-size: 20px;
  outline: 0;
  &:focus {
    border: solid 1px #ea6e37;
  }
`;

const th = css`
  font-size: 14px;
  text-align: right;
`;

const selector = formValueSelector('forgotPasswordCodeForm');
type AllState = ReturnType<typeof store.getState>;

const ForgotPasswordCodeForm: FC = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: AllState) => state);

  const username = selector(state, 'username');
  const confirmationCode = selector(state, 'confirmationCode');
  const newPassword = selector(state, 'newPassword');
  const newPasswordCheck = selector(state, 'newPasswordCheck');

  const forgotPasswordCodeForm = {
    username,
    confirmationCode,
    newPassword,
    newPasswordCheck,
  };

  const handleSubmit = async () => {
    if (typeof forgotPasswordCodeForm === 'undefined') {
      return;
    }
    // undefinedになってた時に最低から文字にしてcognite側で判断させる
    const username = forgotPasswordCodeForm.username || '';
    const confirmationCode = forgotPasswordCodeForm.confirmationCode || '';
    const newPassword = forgotPasswordCodeForm.newPassword || '';
    const newPasswordCheck = forgotPasswordCodeForm.newPasswordCheck || '';

    await dispatch(
      PasswordActions.confirmPasswordCode(
        username,
        confirmationCode,
        newPassword,
        newPasswordCheck,
        (res: any, message: string) => {
          if (res === false) {
            alert(message);
          }
        },
      ),
    );
    dispatchIdentifierToGTM('update_password');
  };

  return (
    <div>
      <div>
        <h2>メールに記載の認証コードと新しく設定するパスワードなどを入力してください</h2>
        <form>
          <table>
            <tbody>
              <tr>
                <th css={th}>認証コード</th>
                <td>
                  <Field
                    css={input}
                    name="confirmationCode"
                    type="text"
                    placeholder="認証コード"
                    component="input"
                    required={true}
                    autoFocus={true}
                  />
                </td>
              </tr>
              <tr>
                <th css={th}>新しいパスワード</th>
                <td>
                  <Field
                    css={input}
                    name="newPassword"
                    type="password"
                    placeholder="新しいパスワード"
                    component="input"
                    required={true}
                    autoFocus={false}
                  />
                </td>
              </tr>
              <tr>
                <th css={th}>(確認用)新しいパスワード</th>
                <td>
                  <Field
                    css={input}
                    name="newPasswordCheck"
                    type="password"
                    component="input"
                    placeholder="(確認用)新しいパスワード"
                    required={true}
                  />
                </td>
              </tr>
              <tr>
                <th css={th}>事業所ID</th>
                <td>
                  <Field
                    css={input}
                    name="username"
                    type="text"
                    component="input"
                    placeholder="事業所ID"
                    required={true}
                  />
                </td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <td>
                  <GeneralPrimaryButton
                    txt="パスワード更新"
                    setCss="width: 240px; height: 60px; margin: 25px auto;"
                    onClick={() => handleSubmit()}
                    type="button"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
      <div>
        <p>パスワード再発行手続きについて</p>
        <p>&nbsp;</p>
        <ul>
          <li>1. ご契約時のメールアドレス宛てにメールが届いている事を確認してください。*1 *2</li>
          <li>2. メールに「認証コード」が記載されているのを確認します。</li>
          <li>3. 認証コードを入力します。</li>
          <li>4. 新しいパスワードを入力します。</li>
          <li>5. (確認用)再度、新しいパスワードを入力します。</li>
          <li>6. 事業所IDを入力します。</li>
          <li>以上の手続きで、新しいパスワードの再発行手続きが完了です。</li>
          <li>
            (*1)1時間以内に届いているものだけ有効です。もし期限が過ぎていたら、パスワード再発行手続きを最初からやり直して下さい。
          </li>
          <li>
            (※2)メールは、ご契約情報として登録がされているメールアドレスに送信されます。もしお届けのメールアドレスに届いていない場合は、担当営業にお問い合わせください。
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'forgotPasswordCodeForm',
})(ForgotPasswordCodeForm as any);
