import React, { FC } from 'react';
import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import ArrowRoundButton from '../Atoms/ArrowRoundButton';
import { dispatchIdentifierToGTM } from '../../utils/commonUtil';
import { CHECK_SHEET_TYPES } from '../../constants/Report';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
  buttonArea: {
    position: 'relative',
    width: '158px',
    height: '42px',
    margin: '45px auto 0',
    fontSize: '20px',
    lineHeight: '1em',
    cursor: 'pointer',
    '@media print': {
      display: 'none',
    },
  },
});

interface PageTransitionProps {
  currnetSequence: number;
  total: number;
  pushLeft: any;
  pushRight: any;
  sheetType: CHECK_SHEET_TYPES;
}

const PageTransition: FC<PageTransitionProps> = ({ currnetSequence, total, pushLeft, pushRight, sheetType }) => {
  const classes = useStyles();

  return (
    <section>
      <Box className={classes.buttonArea}>
        <GeneralPrimaryButton
          txt="印刷"
          setCss={'width: 158px; height: 42px;'}
          onClick={() => {
            window.print();
            if (sheetType.name === CHECK_SHEET_TYPES.homeVisit.name) {
              dispatchIdentifierToGTM('homevisit_print');
            } else {
              dispatchIdentifierToGTM(`detail_print_${sheetType.gtmKey}`);
            }
            return false;
          }}
        />
      </Box>

      <Box display="flex" displayPrint="none" width={{ xs: '80%', sm: '80%', md: '50%' }} m="0 auto 15px">
        <Box width="50px">
          {currnetSequence <= 1 ? null : <ArrowRoundButton arrowDirection="left" onClick={pushLeft} />}
        </Box>
        <Box displayPrint="none" m="0 auto" fontSize="30px" textAlign="center">
          {currnetSequence} / {total}
        </Box>
        <Box width="50px">
          {currnetSequence >= total ? null : <ArrowRoundButton arrowDirection="right" onClick={pushRight} />}
        </Box>
      </Box>
      <br />
    </section>
  );
};

export default PageTransition;
