import React, { FC } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import _ from 'lodash';

import { getCurrentTime, convertTime, convertDay } from '../../utils/dateUtil';
import { returnFalse, dispatchIdentifierToGTM } from '../../utils/commonUtil';
import * as KirokuAutoSuggestUtil from '../../utils/KirokuAutoSuggestModule';

import { KirokuRequestParamsFormat, SuggestWord } from '../../constants/Kiroku';

import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import AutoCompleteTextArea from '../Atoms/AutoCompleteTextArea';
import GeneralPrimaryDateInput from '../Atoms/GeneralPrimaryDateInput';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  tblStyle00: {
    [theme.breakpoints.down('xs')]: {
      width: '300px',
      margin: '0 auto',
      lineHeight: '1.3em',
      display: 'block',
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      '-webkit-overflow-scrolling': 'touch',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 50px)',
      margin: '0 25px',
      fontSize: '1em',
      display: 'block',
      overflowX: 'scroll',
      whiteSpace: 'nowrap',
      '-webkit-overflow-scrolling': 'touch',
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 50px)',
      margin: '0 25px',
      fontSize: '1em',
    },
    '@media print': {
      margin: '0 auto',
      display: 'table',
      fontSize: '1em',
    },
    /* Edgeの印刷時に線が出る対策 */
    '@supports (-ms-ime-align: auto)': {
      border: 'solid 2px #999',
    },
    maxWidth: '939px',
    border: 'solid 1px #999',
    '& thead': {
      '& th': {
        padding: '3px 10px',
        fontWeight: 'normal',
        textAlign: 'center',
        background: '#e8e4df',
        lineHeight: '1.1em',
        verticalAlign: 'middle',
        border: 'solid 1px #999',
      },
      '& th:nth-of-type(1)': {
        width: '200px',
      },
      '& th:nth-of-type(2)': {
        width: '100px',
      },
      '& th:nth-of-type(3)': {
        width: '185px',
      },
      '& th:nth-of-type(4)': {
        width: '120px',
      },
      '& th:nth-of-type(5)': {
        width: '195px',
      },
      '& th:nth-of-type(6)': {
        display: 'none',
      },
      '& th:nth-of-type(7)': {
        display: 'none',
      },
    },
    '& tbody': {
      '& td': {
        padding: '5px',
        verticalAlign: 'middle',
        textAlign: 'left',
        border: 'solid 1px #999',
      },
      '& td:nth-of-type(1)': {
        textAlign: 'center',
      },
      '& td:nth-of-type(2)': {
        textAlign: 'center',
      },
      '& td:nth-of-type(3)': {
        textAlign: 'center',
        minWidth: '185px',
      },
      '& td:nth-of-type(4)': {
        textAlign: 'center',
        minWidth: '120px',
      },
      '& td:nth-of-type(5)': {
        textAlign: 'center',
        minWidth: '195px',
      },
      '& td:nth-of-type(7)': {
        display: 'none',
      },
      '& td:nth-of-type(8)': {
        display: 'none',
      },
    },
    /* form parts */
    "input[type='datetime']": {
      width: '180px',
      fontSize: '90%',
    },
    "input[type='time']": {
      width: '95px',
      fontSize: '78%',
    },
    "input[type='datetime-local']": {
      width: '200px',
      fontSize: '85%',
    },
  },
  newRowDeleteCell: {
    position: 'absolute',
    right: '-40px',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
}));

const KirokuAllUsersForm: FC<{
  defaultValues: KirokuRequestParamsFormat[];
  submitKiroku: (data: KirokuRequestParamsFormat[], suggestWordsGroupSubject: SuggestWord[], reset: () => void) => void;
}> = (props) => {
  const classes = useStyles();
  const { defaultValues, submitKiroku } = props;
  const [
    suggestWordsGroupSubject,
    suggestWordsGroupInstructor,
  ] = KirokuAutoSuggestUtil.setLocalStorageValueForSuggestWordsMUI();

  const defalutForm: { ['AllKiroku']: KirokuRequestParamsFormat[] } = {
    AllKiroku: defaultValues,
  };

  const { register, control, handleSubmit, formState, reset, watch } = useForm({
    defaultValues: defalutForm,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'AllKiroku',
  });

  const { isSubmitting } = formState;
  const watchAllFields = watch();
  const formLength = watchAllFields?.AllKiroku?.length;

  const addNewFormRow = () => {
    append({
      datetime: getCurrentTime().datetimeT,
      duration: '00:00:00',
      subject: '',
      instructor: '',
      remarks: '',
    });
    dispatchIdentifierToGTM('all_users_save_add_record_kiroku');
  };

  return (
    <form
      onSubmit={handleSubmit((data: { ['AllKiroku']: KirokuRequestParamsFormat[] }) =>
        submitKiroku(data.AllKiroku, suggestWordsGroupSubject, reset),
      )}
    >
      <table className={classes.tblStyle00}>
        <thead>
          <tr>
            <th key={'date'} scope="col">
              実施日時
            </th>
            <th key={'time'} scope="col">
              実施時間
            </th>
            <th key={'trainingcontent'} scope="col">
              訓練内容
            </th>
            <th key={'featuretraining'} scope="col">
              機能訓練
              <br />
              指導員
            </th>
            <th key={'remark'} scope="col">
              特記事項
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.map((record, index: number) => {
            return (
              <tr key={record.id}>
                <td>
                  {record.datetime === undefined || record.datetime.length < 20 ? (
                    <GeneralPrimaryDateInput
                      name={`AllKiroku[${index}].datetime`}
                      defaultValue={record.datetime}
                      isRequired={true}
                      refFunction={register()}
                      type="datetime-local"
                    />
                  ) : (
                    <>
                      <div>{convertDay(convertTime(record.datetime).day)}</div>
                      <GeneralPrimaryDateInput
                        name={`AllKiroku[${index}].datetime`}
                        defaultValue={record.datetime}
                        isRequired={false}
                        refFunction={register()}
                        type="hidden"
                      />
                    </>
                  )}
                </td>
                <td>
                  <GeneralPrimaryDateInput
                    name={`AllKiroku[${index}].duration`}
                    defaultValue={record.duration}
                    isRequired={false}
                    refFunction={register()}
                    type="time"
                    step="1"
                  />
                </td>
                <td>
                  <AutoCompleteTextArea
                    formName={`AllKiroku[${index}].subject`}
                    defaultValue={record.subject}
                    suggestWordsGroup={suggestWordsGroupSubject.filter((el) => !_.isNil(el.text))}
                    register={register}
                    control={control}
                    formLength={formLength}
                  />
                </td>
                <td>
                  <AutoCompleteTextArea
                    formName={`AllKiroku[${index}].instructor`}
                    defaultValue={record.instructor}
                    suggestWordsGroup={suggestWordsGroupInstructor.filter((el) => !_.isNil(el.text))}
                    register={register}
                    control={control}
                    formLength={formLength}
                  />
                </td>
                <td>
                  <Box position="relative">
                    <AutoCompleteTextArea
                      formName={`AllKiroku[${index}].remarks`}
                      defaultValue={record.remarks}
                      suggestWordsGroup={[]}
                      register={register}
                      control={control}
                      formLength={formLength}
                    />
                    {index !== 0 && (
                      <IconButton
                        size="medium"
                        onClick={() => remove(index)}
                        className={classes.newRowDeleteCell}
                        data-test="remove-button"
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </Box>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Box display="flex" justifyContent="center" mt="50px">
        <Box width={{ xs: '130px', sm: '158px' }} mx="15px">
          <GeneralPrimaryButton
            type="button"
            txt="項目追加"
            setCss={'width: 100%; height: 50px;'}
            onClick={addNewFormRow}
          />
        </Box>
        {!isSubmitting ? (
          <Box width={{ xs: '130px', sm: '158px' }} mx="15px">
            <GeneralPrimaryButton
              type="submit"
              txt="保存する"
              setCss={'width: 100%; height: 50px;'}
              onClick={returnFalse}
            />
          </Box>
        ) : null}
      </Box>
    </form>
  );
};

export default KirokuAllUsersForm;
