import React, { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

import { User } from '../constants/UserListField';

import store from '../store/configureStore';

import * as headerActions from '../actions/Header';
import * as moffAPIActions from '../actions/MoffAPI';

import { objectQuickSort, duplicateRepeatSort, dispatchIdentifierToGTM } from '../utils/commonUtil';

import PopupContainer from './Popup';

import * as Header from '../constants/Header';
import { KirokuRequestParamsFormat, SuggestWord } from '../constants/Kiroku';
import { USERS_SORT_KEYS } from '../constants/UserListField';
import * as KirokuModule from '../utils/KirokuModule';
import PageLoader from '../components/Atoms/PageLoader';
import { RefFunction } from '../components/Molecules/UserListShow';
import KirokuAllUsersSave from '../components/Organisms/KirokuAllUsersSave';

type AllState = ReturnType<typeof store.getState>;

const KirokuAllUsersSaveContainer: FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const moffAPIGlobalState = useSelector((state: AllState) => state.moffAPI);
  const [cachedUsers, setcachedUsers] = useState<User[]>([] as User[]);
  const refUsersList = useRef<RefFunction | null>(null);

  useEffect(() => {
    (async () => {
      document.title = '個別機能訓練一括入力';
      dispatch(headerActions.setPage(Header.KIROKU_ALL_USER_SAVE));
      // 最初に一回ユーザー一覧取得のために通信.
      await dispatch(moffAPIActions.getUsers());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_.isEmpty(cachedUsers) && !_.isEmpty(moffAPIGlobalState.result)) {
      const sortedUsers = duplicateRepeatSort(USERS_SORT_KEYS, moffAPIGlobalState.result, objectQuickSort);
      setcachedUsers(sortedUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffAPIGlobalState.Loaded, moffAPIGlobalState.result]);

  const saveKiroku = (
    records: KirokuRequestParamsFormat[],
    checkedUsers: User[],
    suggestWordsGroupSubject: SuggestWord[],
    reset: () => void,
  ) => {
    // ユーザーのインデックス.
    let firstIndex = 0;
    // 同時実行回数.
    const MULTIPLE_COUNT = 5;
    const loopUsers = () => {
      // .maxCountに相当
      let existCount = 0;
      for (let i = 0; i < MULTIPLE_COUNT; i++) {
        if (checkedUsers[firstIndex + i]) {
          dispatch(moffAPIActions.postFunctionalTrainingAllUser(checkedUsers[firstIndex + i], records));
        } else {
          return existCount;
        }
        existCount++;
      }
      // maxCountの返却.
      return existCount;
    };

    // Promise内部用のため.
    Promise.resolve().then(function promise(): any {
      return new Promise(function (resolve: any) {
        const loop = setInterval(() => {
          const maxCount = loopUsers();
          firstIndex += MULTIPLE_COUNT;
          // なくなったらやめる.
          if (maxCount === 0) {
            clearInterval(loop);
            resolve();
          }
        }, 100);
      })
        .then(() => {
          alert('データを保存しました');
          KirokuModule.saveToLocalStorage(KirokuModule.createLocalStoragePostData(records), suggestWordsGroupSubject);
          reset();
        })
        .catch(() => alert('データ保存に失敗しました'));
    });
    dispatchIdentifierToGTM('all_users_save_save_kiroku');
  };

  const submitKiroku = (
    data: KirokuRequestParamsFormat[],
    suggestWordsGroupSubject: SuggestWord[],
    reset: () => void,
  ) => {
    // ローディングされている最中はボタンを押しても、APIが送信されない
    if (moffAPIGlobalState.Loading) {
      return;
    }
    const resultValidate = KirokuModule.allKirokuFormValidate(data);

    if (resultValidate) {
      // チェックボックスがonのユーザーのみを取得
      const checkedUsers: User[] = refUsersList.current?.getCheckedUsers() ?? [];
      const resultCheckUser = KirokuModule.checkUsers(checkedUsers);
      if (resultCheckUser) {
        if (window.confirm('本当に保存しますか？')) {
          const records = KirokuModule.checkDatetime(data);
          saveKiroku(records, checkedUsers, suggestWordsGroupSubject, reset);
        }
      }
    }
  };

  /**
   * CSSの描画.
   */
  const RenderCSS: FC = () => {
    return (
      <style type="text/css">
        {/* 縦印刷, 日付・title名ヘッダの余白 */}
        {'@media print{@page {size: portrait; margin-top: 43px;}}'}
        {'@media print{#wrapper { padding:0; margin: 0; } header { display: none; }}'}
        {'@media print{.contentIn { padding-top:0!important; padding-bottom:0!important; }}'}
        {'@media print{.report { padding-bottom:0!important; }}'}
        {/* Edge対策 */}
        {'@media print{tr { page-break-inside: avoid; }}'}
      </style>
    );
  };
  return (
    <>
      <div>
        <RenderCSS />
        <PopupContainer />
        {moffAPIGlobalState.Loaded ? null : <PageLoader />}
        <KirokuAllUsersSave
          location={location}
          isLoaded={moffAPIGlobalState.Loaded}
          cachedUsers={cachedUsers}
          refUsersList={refUsersList}
          submitKiroku={submitKiroku}
        />
      </div>
    </>
  );
};

export default KirokuAllUsersSaveContainer;
