import React, { FC, useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';

import store from '../store/configureStore';

import * as MoffAPIActions from '../actions/MoffAPI';
import * as MoffCheckActions from '../actions/MoffCheck';
import * as InterestCheckActions from '../actions/InterestCheck';
import * as LifeCheckActions from '../actions/LifeCheck';
import * as AppUserActions from '../actions/AppUser';
import * as UserListField from '../constants/UserListField';
import * as MoffCheckConst from '../constants/MoffCheck';
import * as InterestCheckConst from '../constants/InterestCheck';
import * as LifeCheckConst from '../constants/LifeCheck';
import { USERS_SORT_KEYS } from '../constants/UserListField';
import { MENU_TYPE } from '../constants/Menu';

import { objectQuickSort, duplicateRepeatSort } from '../utils/commonUtil';
import { getCurrentTime, convertTime } from '../utils/dateUtil';
import SmallLoader from '../components/Atoms/SmallLoader';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    [theme.breakpoints.up('sm')]: {
      '& th, & td': {
        maxWidth: '150px',
        padding: '20px 20px',
        textAlign: 'left',
        fontWeight: 'normal',
        lineHeight: '1.3em',
        overflowWrap: 'break-word',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& th, & td': {
        padding: '20px 7px',
        textAlign: 'left',
        fontWeight: 'normal',
        fontSize: '0.8em',
        overflowWrap: 'break-word',
        verticalAlign: 'middle',
        lineHeight: '1.3em',
      },
      '& th': {
        maxWidth: '105px',
      },
      '& td': {
        maxWidth: '15px',
        '&:first-of-type': {
          maxWidth: '70px',
        },
        '&:nth-of-type(2)': {
          maxWidth: '20px',
          textAlign: 'center',
        },
        '&:nth-of-type(3)': {
          maxWidth: '10px',
          textAlign: 'center',
        },
      },
    },
  },
  today: {
    paddingLeft: '40px',
    fontSize: '110%',
    fontWeight: 'bold!important' as 'bold',
    color: '#ee9c36',
  },
}));

type AllState = ReturnType<typeof store.getState>;

interface UserSelectBodyProps {
  // UserSelectLocomoから渡されたパラメータ
  users: UserListField.User[];
  type: MENU_TYPE;
}

// 利用者一覧の表のフィールド表示用コンポーネント
const UserSelectBodyContainer: FC<UserSelectBodyProps> = ({ users, type }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const appUser = useSelector((state: AllState) => state.appUser);
  const store = useStore();
  const [urlBase, setUrlBase] = useState('');
  const [userIdForUrl, setUserIdForUrl] = useState('');

  const addDisplayDate = (payload: UserListField.DisplayPayload) => {
    dispatch(MoffAPIActions.addDisplayDate(payload));
  };
  /**
   * モフトレチェック用API呼び出しメソッド
   * @param {MENU_TYPE} type 各タイプ切り替え.
   * @memberof UserSelectBody
   */
  const runAPI = (type: MENU_TYPE) => {
    const sortedUsers = duplicateRepeatSort(USERS_SORT_KEYS, users, objectQuickSort);
    // ユーザーのインデックス.
    let firstIndex = 0;
    // 同時実行回数.
    const MULTIPLE_COUNT = 5;
    // 決められた数API実行.
    const runActionAPI = () => {
      // maxCountに相当.
      let existCount = 0;
      for (let i = 0; i < MULTIPLE_COUNT; i++) {
        if (sortedUsers[firstIndex + i]) {
          // ACTION.
          if (type === MENU_TYPE.MoffCheck) {
            // モフトレチェック.
            dispatch(MoffCheckActions.getMoffCheckLastDate(sortedUsers[firstIndex + i].user_id, addDisplayDate, 500));
          } else if (type === MENU_TYPE.InterestCheck) {
            // 興味関心チェック.
            dispatch(
              InterestCheckActions.getInterestCheckLastDate(sortedUsers[firstIndex + i].user_id, addDisplayDate, 500),
            );
          } else if (type === MENU_TYPE.LifeCheck) {
            // 生活機能チェック.
            dispatch(LifeCheckActions.getLifeCheckLastDate(sortedUsers[firstIndex + i].user_id, addDisplayDate, 500));
          }

          existCount++;
        }
      }

      // maxCountの返却.
      return existCount;
    };
    // 実行した回数.
    let maxCount = runActionAPI();

    Promise.resolve().then(function loop(): Promise<void> {
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          let isOK = false;
          // 各項目でpropsを切り替え.
          if (type === MENU_TYPE.MoffCheck) {
            const moffCheckCountStoreState = store.getState().moffCheckCount;
            // モフトレチェック.
            isOK =
              moffCheckCountStoreState.type === MoffCheckConst.FINISH &&
              moffCheckCountStoreState.LoadedCount === maxCount;
          } else if (type === MENU_TYPE.InterestCheck) {
            const interestCheckStoreState = store.getState().interestCheck;
            // 興味関心チェック.
            isOK =
              interestCheckStoreState.type === InterestCheckConst.FINISH &&
              interestCheckStoreState.LoadedCount === maxCount;
          } else if (type === MENU_TYPE.LifeCheck) {
            const lifeCheckStoreState = store.getState().lifeCheck;
            // 生活機能チェック.
            isOK = lifeCheckStoreState.type === LifeCheckConst.FINISH && lifeCheckStoreState.LoadedCount === maxCount;
          }

          if (isOK) {
            firstIndex += MULTIPLE_COUNT;
            maxCount = runActionAPI();
            // なくなったらやめる.
            if (maxCount === 0) {
              return;
            }
          }
          // 0.1秒間隔チェック.
          resolve();
        }, 100);
      }).then(loop);
    });
  };

  useEffect(() => {
    setUrlBase(type.urlBase);
    if ((type === MENU_TYPE.MoffCheck || MENU_TYPE.InterestCheck) && !_.isEmpty(users)) {
      runAPI(type);
    }

    // 利用者登録ページは利用者が存在しなくても遷移できるようにする
    if (_.isEmpty(users) && type !== MENU_TYPE.UserSetting) {
      alert('利用者を登録してください');
      window.location.href = '/admin.html';
    }
    // 「個別機能訓練に関する記録」と「利用者設定」のときは曜日で行うがそれ以外は何もしない.
    if (type !== MENU_TYPE.Kiroku && type !== MENU_TYPE.UserSetting) {
      return;
    }

    // 個別機能訓練に関する記録のときの本日の曜日の処理.
    const todayDay: string = UserListField.WEEK_CHARS[new Date().getDay()];

    // デフォルトはその曜日なのでそれでinexを取得する.
    const weekSelected: string = todayDay;

    // userのオブジェクトの入ったhiddenのClass.
    const usersObject: HTMLCollectionOf<Element> = document.getElementsByClassName('user');
    // ここから曜日フィルター.
    let index = 0;
    for (const key in usersObject) {
      if (Object.prototype.hasOwnProperty.call(usersObject, key)) {
        // 1つのエレメント.
        const element: Element = usersObject[key];

        // stringfyで入ってるのでいったん変換.
        const userVisitDay: number[] = (JSON.parse(element.innerHTML) as UserListField.User).user_visit_day;

        const parent: HTMLElement | null = element.parentElement;
        // 選択されたWeekSelectedがWeekChars[]の中では何番目の値なのか？
        const weekIndex = UserListField.START_MO_WEEK_CHARS.indexOf(weekSelected);

        // 選択したのがAll or 1なら入ってる.
        if (weekSelected === UserListField.ALL || (userVisitDay && userVisitDay[weekIndex] === 1)) {
          if (parent) {
            // 居る人は表示する.
            parent.hidden = false;

            // 偶数は白/奇数グレー(cssがhidden要素をスキップしてくれないので上書き).
            const isEven: boolean = index % 2 === 0;
            [].forEach.call(
              parent.childNodes,
              (child: Node) => ((child as HTMLElement).style.background = isEven ? '#fff' : '#e8e4df'),
            );

            index++;
          }
        } else {
          if (parent) {
            // 該当しない人は消す.
            parent.hidden = true;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // userのオブジェクトの入ったhiddenのClass.
    const usersObject: HTMLCollectionOf<Element> = document.getElementsByClassName('user');
    let index = 0;
    for (const el in usersObject) {
      if (Object.prototype.hasOwnProperty.call(usersObject, el)) {
        // 1つのエレメント.
        const parent: HTMLElement | null = usersObject[el].parentElement;

        if (parent) {
          if (parent.hidden === false) {
            const isEven: boolean = index % 2 === 0;
            [].forEach.call(
              parent.childNodes,
              (child: Node) => ((child as HTMLElement).style.background = isEven ? '#fff' : '#e8e4df'),
            );
            index++;
          }
        }
      }
    }
  }, [users]);

  useEffect(() => {
    switch (type) {
      case MENU_TYPE.InterestCheck:
        if (appUser.Loaded && appUser.result) {
          // 興味関心チェック.
          window.location.href = `/interest_check_print/${appUser.result.total}/${appUser.result.uniqueId}/${userIdForUrl}`;
        }
        break;
      case MENU_TYPE.HomeVisit:
        if (appUser.Loaded && appUser.result) {
          // 居宅訪問の詳細.
          window.location.href = `/home_visit_print/${appUser.result.total}/${appUser.result.uniqueId}/${userIdForUrl}`;
        }
        break;
      case MENU_TYPE.LifeCheck:
        if (appUser.Loaded && appUser.result) {
          // 生活チェックの詳細.
          // totalになってるが、sequenceのlastにしないと、データ削除した時におかしくなるので、関連コードを変更した 2024/03/08
          window.location.href = `/life_check_print/${appUser.result.total}/${appUser.result.uniqueId}/${userIdForUrl}`;
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser.Loaded, appUser.result]);

  const transitionForLiterallyReportOrMoffCheck: Function = (userId: string) => {
    // 登録の確認Action.
    const moveToDetailPagePath = () => {
      switch (type) {
        case MENU_TYPE.LiterallyReport:
          window.location.href = `literally_report_select_month/${userId}`;
          break;
        case MENU_TYPE.MoffCheck:
          window.location.href = `moff_check_select_month/${userId}`;
          break;
      }
    };
    dispatch(AppUserActions.checkAppUser(userId, moveToDetailPagePath));
  };

  const transitionForHomevisit: Function = (userId: string) => {
    // 登録の確認Action.
    setUserIdForUrl(userId);
    dispatch(AppUserActions.checkAppUserForHomeVisit(userId));
  };

  const transitionForInterestCheck: Function = (userId: string) => {
    // 登録の確認Action.
    setUserIdForUrl(userId);
    dispatch(AppUserActions.checkAppUserForInterestCheck(userId));
  };

  const transitionForLifeCheck: Function = (userId: string) => {
    // 登録の確認Action.
    setUserIdForUrl(userId);
    dispatch(AppUserActions.checkAppUserForLifeCheck(userId));
  };

  /**
   * 一覧をクリックしたときの処理.
   *
   * @memberof UserSelectBody
   */
  const handleFieldClicked = (userId: string) => {
    // 居宅訪問 or 興味関心チェック or 機能訓練レポート or モフトレチェック.
    if (type === MENU_TYPE.LiterallyReport || type === MENU_TYPE.MoffCheck) {
      transitionForLiterallyReportOrMoffCheck(userId);
      dispatchIdentifierToGTM('user_record');
      return;
    } else if (type === MENU_TYPE.HomeVisit) {
      transitionForHomevisit(userId);
      dispatchIdentifierToGTM('user_record');
      return;
    } else if (type === MENU_TYPE.InterestCheck) {
      transitionForInterestCheck(userId);
      dispatchIdentifierToGTM('user_record');
      return;
    } else if (type === MENU_TYPE.LifeCheck) {
      transitionForLifeCheck(userId);
      dispatchIdentifierToGTM('user_record');
      return;
    }

    // 詳細画面への導線.
    window.location.href = `${urlBase}/${userId}`;
    dispatchIdentifierToGTM('user_record');
  };

  /**
   * 対象の日付が今日か判定する
   * @param {string} date - 対象の日付
   * @returns {boolean}
   */
  const isTodayFunc = (date: string): boolean => {
    // 今日の日付
    const today: string = getCurrentTime().day;
    return today === date;
  };

  return (
    <>
      {typeof users === 'undefined' ? (
        <tr>
          <th scope="row">Error.</th>
          <td />
          <td />
          <td />
          <td />
        </tr>
      ) : null}
      {users.map((user) => {
        const displayDate: string = (() => {
          if ([MENU_TYPE.MoffCheck, MENU_TYPE.InterestCheck, MENU_TYPE.LifeCheck].includes(type)) {
            // モフトレチェック・興味関心チェック・生活機能チェックの場合のみローディングを表示
            return (() => {
              if (_.isNil(user.display_date)) {
                return (
                  <Box display="flex" justifyContent="center">
                    <SmallLoader />
                  </Box>
                );
              } else if (user.display_date) {
                return user.display_date;
              } else {
                return '';
              }
            })();
          } else if (type === MENU_TYPE.Kiroku) {
            // undefinedの可能性があるのでチェックする
            return user.functional_latest_date ? convertTime(user.functional_latest_date)[0].replace(/\//g, '-') : '';
          }
          return user.created_at;
        })();

        return (
          <tr
            style={{ cursor: 'pointer' }}
            key={user.user_id}
            onClick={() => handleFieldClicked(user.user_id)}
            className={classes.tableRow}
          >
            {/* hiddenで隠しておくuserの内容. */}
            <th className="user" hidden={true}>
              {JSON.stringify(user)}
            </th>
            <th scope="row">{user.user_care_id}</th>
            <td>{user.user_name}</td>
            <td>{user.user_birth_year}</td>
            <td>{user.user_gender === 0 ? UserListField.Male : UserListField.Female}</td>
            <td
              className={isTodayFunc(displayDate) ? classes.today : undefined}
              data-test={isTodayFunc(displayDate) ? 'today' : ''}
            >
              {isTodayFunc(displayDate) ? '本日' : displayDate}
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default UserSelectBodyContainer;
