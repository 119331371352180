import { useEffect, useState } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import * as MoffAPIActions from '../actions/MoffAPI';
import { ATTACHMENT_POSITION, MonthlyTrainingData } from '../constants/Training';
import { dispatchIdentifierToGTM, mergeObject, cloneDeep } from '../utils/commonUtil';
import { convertToAttachmentPosition, omitUnIntroducedFromMonthlyTrainings } from '../utils/locomoAdlModule';
import { useTrainingUI } from './use-training-ui';

export const useTrainingFetchDetail = () => {
  const store = useStore();
  const dispatch = useDispatch();
  type AllState = ReturnType<typeof store.getState>;
  const moffApiState = useSelector((state: AllState) => state.moffAPI);
  const { userId, isToggleOn, togglePosition, setCachedTrainingMenus } = useTrainingUI();

  const [date, setDate] = useState(moment());
  const [, setdateYYYYMMDD] = useState(moment().format('YYYY-MM-DD'));
  const [monthlyData, setMonthlyData] = useState<MonthlyTrainingData>({});
  const displayIdentifier = isToggleOn ? 'graph' : 'table';

  // 画面読み込み時の処理。選択月のトレーニングデータを取得
  useEffect(() => {
    // 通常のモフトレレポート詳細.
    // 長期変化から詳細へ戻ったときの処理.
    // 再度現在のYearMonthで通信.
    if (userId) dispatch(MoffAPIActions.getDetailData(userId, date.format('YYYY-MM'), togglePosition));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, userId]);

  // 「両側」「左」「右」をトグルで切り替えた時の処理
  useEffect(() => {
    (async () => {
      if (userId) {
        const attachmentPosition = convertToAttachmentPosition(togglePosition);
        await dispatch(MoffAPIActions.getDetailData(userId, date.format('YYYY-MM'), togglePosition));
        if (attachmentPosition?.name && attachmentPosition?.name !== ATTACHMENT_POSITION.UnSpecified.name) {
          dispatchIdentifierToGTM(`detail_${displayIdentifier}_${attachmentPosition.name}_moff_training`);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [togglePosition]);

  // トレーニングデータ読み込み完了後、トレーニングデータをローカルステートにキャッシュする
  useEffect(() => {
    const training = moffApiState.result.training;
    if (training) {
      // キャッシュさせておく.
      if (training) setCachedTrainingMenus(training);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffApiState.result.training]);

  // トレーニングデータ読み込み完了後、ロコモ・ADLのデータを合成しローカルステートに格納する
  useEffect(() => {
    const copyLocomoMonthlyData = cloneDeep(moffApiState.result.locomoMonthlyData);
    const copyAdlMonthlyData = cloneDeep(moffApiState.result.adlMonthlyData);
    const fetchedAllMonthlyData = mergeObject<MonthlyTrainingData>(copyLocomoMonthlyData, copyAdlMonthlyData);
    const monthlyData = omitUnIntroducedFromMonthlyTrainings(fetchedAllMonthlyData);
    setMonthlyData(monthlyData);
  }, [moffApiState.result.locomoMonthlyData, moffApiState.result.adlMonthlyData]);

  // 通常画面月矢印のアクション
  const minusMonthly = async () => {
    const lastMonth = date.subtract(1, 'months');
    setDate(lastMonth);
    await dispatch(MoffAPIActions.getDetailData(userId, lastMonth.format('YYYY-MM'), togglePosition));
    dispatchIdentifierToGTM(`detail_page_change_month_moff_training`);
  };

  const plusMonthly = async () => {
    const nextMonth = date.add(1, 'months');
    setDate(nextMonth);
    await dispatch(MoffAPIActions.getDetailData(userId, nextMonth.format('YYYY-MM'), togglePosition));
    dispatchIdentifierToGTM(`detail_page_change_month_moff_training`);
  };

  const minusDaily = async () => {
    const prevMonth = date.format('MM');
    const lastDate = date.subtract(1, 'days');
    setDate(lastDate);
    setdateYYYYMMDD(lastDate.format('YYYY-MM-DD'));
    // 月に変化があったとき.
    if (prevMonth !== lastDate.format('MM')) {
      await dispatch(MoffAPIActions.getDetailData(userId, lastDate.format('YYYY-MM'), togglePosition));
    }
    dispatchIdentifierToGTM(`detail_page_change_day_moff_training`);
  };

  const plusDaily = async () => {
    const prevMonth = date.format('MM');
    const nextDate = date.add(1, 'days');
    setDate(nextDate);
    setdateYYYYMMDD(nextDate.format('YYYY-MM-DD'));
    // 月に変化があったとき.
    if (prevMonth !== nextDate.format('MM')) {
      await dispatch(MoffAPIActions.getDetailData(userId, nextDate.format('YYYY-MM'), togglePosition));
    }
    dispatchIdentifierToGTM(`detail_page_change_day_moff_training`);
  };

  return {
    moffApiState,
    monthlyData,
    date,
    minusMonthly,
    plusMonthly,
    minusDaily,
    plusDaily,
  };
};

type useTrainingFetchDetail = ReturnType<typeof useTrainingFetchDetail>;
