/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';

import { Box } from '@material-ui/core';

const btnCss = (isDownward: boolean, setCss?: string) => {
  return css`
    ${setCss}
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    color: black;
    text-decoration: none;
    background-color: white;
    border-radius: 5px;
    ${isDownward ? 'transform: rotate(180deg);' : null}

    &:before {
      position: absolute;
      top: 4px;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: 8px;
      height: 8px;
      margin: auto;
      content: '';
      border-bottom: 2px solid;
      border-left: 2px solid;
      transform: rotate(-225deg);
    }
  `;
};

interface ArrowButtonProps {
  /** 下向きかどうかのフラグ */
  isDownward: boolean;
  /** クリックした時のイベントハンドラ */
  handleClick?: () => void;
  /** 外部から渡すスタイル */
  setCss?: string;
}

const ArrowButton: FC<ArrowButtonProps> = ({ setCss, isDownward, handleClick }) => {
  return <Box css={btnCss(isDownward, setCss)} onClick={handleClick} />;
};

export default ArrowButton;
