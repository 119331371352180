import React, { FC, useState } from 'react';
import _ from 'lodash';

import LinkButton from '../Atoms/LinkButton';
import PrevLink from '../Atoms/PrevLink';
import QuestionButton from '../Atoms/QuestionButton';
import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { HEADER_BACK_PAGE_TYPE, HEADER_ALL_USERS_BUTTON_TYPE } from '../../constants/Header';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Menu, MenuItem } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { HeaderState } from '../../store/reducers/Header';
import { LoadParamState } from '../../store/reducers/RequestParam';

const useStyles = makeStyles(() => ({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 100,
    width: '100%',
    height: '50px',
    backgroundImage: 'linear-gradient(0deg, #ec9f35 0%, #edac36 100%)',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },
  title: {
    minWidth: '100px',
    lineHeight: '1.3em',
  },
}));
interface HeaderProps {
  header: HeaderState;
  request_param: LoadParamState;
  handleSignOut: () => void;
  openPopupUserSetting: () => void;
  planType: string;
}

const Header: FC<HeaderProps> = ({ header, request_param, handleSignOut, openPopupUserSetting, planType }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = {
    sm: useMediaQuery(theme.breakpoints.down('sm')),
  };
  const isOptionButton =
    header.is_register_btn || header.is_user_setting_btn || header.is_logout || header.is_all_print_btn;
  const allUsersButtonParams = _.filter(
    HEADER_ALL_USERS_BUTTON_TYPE,
    (type: HEADER_ALL_USERS_BUTTON_TYPE) => type.path === request_param?.pathname,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <header className={classes.header}>
      <Box height={50} display="flex" justifyContent="space-between" alignItems="center">
        {!matches.sm && (
          <>
            <Box>
              {header && header.back_type === HEADER_BACK_PAGE_TYPE.none ? null : (
                <Box minWidth="100px">
                  <PrevLink
                    txt={header.back_type.text}
                    href={
                      header.back_type === HEADER_BACK_PAGE_TYPE.backMenu ? header?.back_type?.url : header?.back_url
                    }
                  />
                </Box>
              )}
            </Box>
            <Box>
              <h1 className={classes.title} data-test="header-title">
                {header ? header.title : 'モフトレ・レポート'}
              </h1>
            </Box>
            <Box display="flex" mx={5} justifyContent="space-around">
              {header && header.is_all_print_btn && (
                <>
                  {allUsersButtonParams.map((param, index) => {
                    return (
                      <Box key={index} mx={1} minWidth="120px">
                        <LinkButton
                          txt={param?.text ?? ''}
                          setCss={param?.css ?? 'padding: 4px 8px;'}
                          href={param?.url ?? '#'}
                        />
                      </Box>
                    );
                  })}
                </>
              )}
              {header && header.is_register_btn && (
                <Box>
                  <LinkButton txt="新規登録" setCss="padding: 4px 24px;" href="/user_setting_register" />
                </Box>
              )}
              {header && header.is_search && <Box width={200}></Box>}
              {header && header.is_life_btn && planType !== 'MoffCheck' && (
                <Box>
                  <LinkButton
                    txt="LIFE CSV出力"
                    setCss="margin: 0 10px; padding: 4px 20px 4px 20px;"
                    href="life_csv_output"
                  />
                </Box>
              )}
              {header && header.is_user_setting_btn && (
                <Box>
                  <LinkButton
                    txt="利用者設定"
                    setCss="margin: 0 10px; padding: 4px 55px 4px 20px;"
                    href="user_setting"
                  />
                  <Box position="absolute" bottom="10px" right="165px">
                    <QuestionButton
                      popupSpecificFunction={openPopupUserSetting}
                      setCss="width: 29px; height: 29px; font-size: 16px; line-height: 1.2;"
                    />
                  </Box>
                </Box>
              )}
              {header && header.is_logout && (
                <Box width={100}>
                  <GeneralPrimaryButton
                    txt="ログアウト"
                    setCss="margin: 0 10px; width: 120px; height: 40px;"
                    onClick={handleSignOut}
                  />
                </Box>
              )}
            </Box>
          </>
        )}
        {matches.sm && (
          <>
            <Box>
              {header && header.back_type === HEADER_BACK_PAGE_TYPE.none ? null : (
                <IconButton
                  onClick={() => {
                    window.location.href =
                      header.back_type === HEADER_BACK_PAGE_TYPE.backMenu ? header?.back_type?.url : header?.back_url;
                  }}
                >
                  <ArrowBackIosIcon style={{ color: 'white' }} />
                </IconButton>
              )}
            </Box>
            <Box>
              <h1 data-test="header-title">{header ? header.title : 'モフトレ・レポート'}</h1>
            </Box>
            <Box>
              {isOptionButton && (
                <Box mx={1}>
                  <IconButton onClick={handleClick}>
                    <MenuIcon style={{ color: 'white' }} fontSize="large" />
                  </IconButton>
                  <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                    {header && header.is_all_print_btn && (
                      <>
                        {allUsersButtonParams.map((param, index) => {
                          return (
                            <MenuItem key={index} onClick={handleClose}>
                              <Box mx={2} my={1}>
                                <a href={param.url}>{param.text}</a>
                              </Box>
                            </MenuItem>
                          );
                        })}
                      </>
                    )}
                    {header && header.is_register_btn && (
                      <MenuItem onClick={handleClose}>
                        <Box mx={2} my={1}>
                          <a href="/user_setting_register">新規登録</a>
                        </Box>
                      </MenuItem>
                    )}
                    {header && header.is_life_btn && planType !== 'MoffCheck' && (
                      <MenuItem onClick={handleClose}>
                        <Box mx={2} my={1}>
                          <a href="life_csv_output">LIFE CSV出力</a>
                        </Box>
                      </MenuItem>
                    )}
                    {header && header.is_user_setting_btn && (
                      <MenuItem onClick={handleClose}>
                        <Box mx={2} my={1}>
                          <a href="user_setting">利用者設定</a>
                        </Box>
                      </MenuItem>
                    )}
                    {header && header.is_logout && (
                      <MenuItem
                        onClick={() => {
                          handleClose();
                          handleSignOut();
                        }}
                      >
                        <Box mx={2} my={1}>
                          ログアウト
                        </Box>
                      </MenuItem>
                    )}
                  </Menu>
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>
    </header>
  );
};

export default Header;
