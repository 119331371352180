import React, { FC, useState, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import store from '../store/configureStore';

import * as MoffAPIActions from '../actions/MoffAPI';

import UserDetail from './UserDetail';
import TrainingHeader from './TrainingHeader';
import PopupContainer from './Popup';

import ReportOutputMonthSelect from '../components/Organisms/ReportOutputMonthSelect';
import PageLoader from '../components/Atoms/PageLoader';

import { getYearMonthArray } from '../utils/dateUtil';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

import * as Header from '../constants/Header';
import { SCREEN_TYPE } from '../constants/Training';

type AllState = ReturnType<typeof store.getState>;

const mapStateToProps = (state: AllState) => ({
  loaded: state.moffAPI.Loaded,
  dispYearMonth: state.moffAPI.result.dispYearMonth,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMoffTrainingData: (userId: string, yearMonthArr: string[], waitTimeMs: number) =>
        MoffAPIActions.getMoffTrainingData(userId, yearMonthArr, waitTimeMs),
    },
    dispatch,
  );

interface PressSelectMonthContainerProps extends RouteComponentProps<{ userId: string }> {
  loaded: boolean;
  dispYearMonth: string[];
  getMoffTrainingData: (userId: string, yearMonthArr: string[], waitTimeMs: number) => void;
  userId: string;
}

const PressSelectMonthContainer: FC<PressSelectMonthContainerProps> = ({
  loaded,
  dispYearMonth,
  getMoffTrainingData,
  match,
}) => {
  const userId = match.params.userId;
  const articleClasName = 'report';
  const [date, setDate] = useState(moment());

  // 画面読み込み時の処理。過去1年のロコモ・ADLトレーニングデータを取得
  useEffect(() => {
    const yearMonthArr: string[] = getYearMonthArray(date.format('YYYY'), date.format('MM'));
    getMoffTrainingData(userId, yearMonthArr, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, userId]);

  // 月選択画面 矢印のアクション
  const minusMonthly = () => {
    const lastMonth = date.subtract(1, 'months');
    setDate(lastMonth);
    const yearMonthArr: string[] = getYearMonthArray(lastMonth.format('YYYY'), lastMonth.format('MM'));
    getMoffTrainingData(userId, yearMonthArr, 500);
    dispatchIdentifierToGTM('detail_page_change_month_press');
  };

  const plusMonthly = () => {
    const nextMonth = date.add(1, 'months');
    setDate(nextMonth);
    const yearMonthArr: string[] = getYearMonthArray(nextMonth.format('YYYY'), nextMonth.format('MM'));
    getMoffTrainingData(userId, yearMonthArr, 500);
    dispatchIdentifierToGTM('detail_page_change_month_press');
  };

  const monthButtonOnClick = (month: string) => {
    window.open(`/press/${userId}/${month}`, '_blank', 'noreferrer noopener');
    dispatchIdentifierToGTM('output_user_report_press');
  };

  return (
    <>
      {loaded && dispYearMonth ? null : <PageLoader />}
      <PopupContainer />
      <article className={articleClasName}>
        <UserDetail userId={userId} headerType={Header.PRESS_SELECT_MONTH} pageTitle="モフトレ通信" />
        <TrainingHeader screenType={SCREEN_TYPE.PressSelectMonth} />
        <ReportOutputMonthSelect
          dispYearMonth={dispYearMonth ?? []}
          date={date}
          selectAreaHeading="モフトレ通信"
          minusMonthly={minusMonthly}
          plusMonthly={plusMonthly}
          monthButtonOnClick={monthButtonOnClick}
        />
      </article>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PressSelectMonthContainer);
