/* 居宅訪問 */
import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import store from '../store/configureStore';

import * as HomeVisitActions from '../actions/HomeVisit';
import * as HeaderActions from '../actions/Header';
import * as FooterActions from '../actions/Footer';
import * as Header from '../constants/Header';

import PageLoader from '../components/Atoms/PageLoader';
import BrowserHandler from '../components/Molecules/HomeVisitPrintBrowsers/BrowserHandler';
import PageTransition from '../components/Molecules/PageTransition';

import { dispatchIdentifierToGTM } from '../utils/commonUtil';
import { CHECK_SHEET_TYPES } from '../constants/Report';
import { DUMMY_HOME_VISIT_RESULT } from '../constants/HomeVisit';
import { DUMMY_USER } from '../constants/UserSetting';

import { Box } from '@material-ui/core';

type AllState = ReturnType<typeof store.getState>;

const RenderCSS: FC = () => {
  return (
    <style type="text/css">
      {/* 表の調整 */}
      {'body,div,table,thead,tbody,tfoot,tr,th,td,p { font-size:small }'}

      {/* 上記の影響を吸収 */}
      {'h1, .prev { font-size:120%; line-height: 1.75;}'}

      {/* Chromeでのtrの背景表示のため */}
      {'body { -webkit-print-color-adjust: exact; }'}
      {'a.comment-indicator:hover + comment { background:#ffd; ' +
        'position:absolute; display:block; border:1px solid black; padding:0.5em; }'}
      {'a.comment-indicator { background:red; display:inline-block; ' +
        'border:1px solid black; width:0.5em; height:0.5em; }'}
      {'comment { display:none; } '}
    </style>
  );
};

const HomeVisitPrintContainer: React.FC<RouteComponentProps<{ total: string; userId: string; uniqueId: string }>> = ({
  match,
}) => {
  // URLのパラメータ.
  const total = Number(match.params.total);
  const userId = match.params.userId;
  const uniqueId = match.params.uniqueId;

  const dispatch = useDispatch();
  const loaded = useSelector((state: AllState) => state.moffAPI.Loaded);
  const user = useSelector((state: AllState) => state.moffAPI.result.user);
  const homeVisit = useSelector((state: AllState) => state.moffAPI.result.homeVisit);

  // 記録番号.
  const [currnetPage, setCurrentPage] = useState(total);

  // ヘッダ情報の設定。居宅訪問のデータを取得。
  useEffect(() => {
    (async () => {
      document.title = '居宅訪問チェックシート';
      dispatch(HeaderActions.setPage(Header.HOME_VISIT_PRINT));
      dispatch(FooterActions.display('hidden'));
      await dispatch(HomeVisitActions.getHomeVisitRecord(userId, uniqueId, currnetPage));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushLeft = async () => {
    const currnetSequence = currnetPage - 1;
    setCurrentPage(currnetSequence);
    await dispatch(HomeVisitActions.getHomeVisitRecord(userId, uniqueId, currnetSequence));
    dispatchIdentifierToGTM('detail_page_change_history_home_visit');
  };

  const pushRight = async () => {
    const currnetSequence = currnetPage + 1;
    setCurrentPage(currnetSequence);
    await dispatch(HomeVisitActions.getHomeVisitRecord(userId, uniqueId, currnetSequence));
    dispatchIdentifierToGTM('detail_page_change_history_home_visit');
  };

  return (
    <>
      {loaded ? null : <PageLoader />}
      <div style={{ backgroundColor: 'white' }}>
        <BrowserHandler user={loaded ? user : DUMMY_USER} homeVisit={loaded ? homeVisit : DUMMY_HOME_VISIT_RESULT} />
        <Box pb="30px">
          <PageTransition
            currnetSequence={currnetPage}
            total={total}
            pushLeft={pushLeft}
            pushRight={pushRight}
            sheetType={CHECK_SHEET_TYPES.homeVisit}
          />
        </Box>
        <RenderCSS />
      </div>
    </>
  );
};

export default HomeVisitPrintContainer;
