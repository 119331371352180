export const KANA = {
  KANA_ALL: {
    name: 'kanaAll',
    word: '',
    initialWord: '全て',
  },
  KANA_A: {
    name: 'kanaA',
    word: 'あいうえおぁぃぅぇぉ',
    initialWord: 'あ行',
  },
  KANA_KA: {
    name: 'kanaKa',
    word: 'かきくけこがぎぐげご',
    initialWord: 'か行',
  },
  KANA_SA: {
    name: 'kanaSa',
    word: 'さしすせそざじずぜぞ',
    initialWord: 'さ行',
  },
  KANA_TA: {
    name: 'kanaTa',
    word: 'たちつてとだぢづでどっ',
    initialWord: 'た行',
  },
  KANA_NA: {
    name: 'kanaNa',
    word: 'なにぬねの',
    initialWord: 'な行',
  },
  KANA_HA: {
    name: 'kanaHa',
    word: 'はひふへほばびぶべぼぱぴぷぺぽ',
    initialWord: 'は行',
  },
  KANA_MA: {
    name: 'kanaMa',
    word: 'まみむめも',
    initialWord: 'ま行',
  },
  KANA_YA: {
    name: 'kanaYa',
    word: 'やゆよゃゅょ',
    initialWord: 'や行',
  },
  KANA_RA: {
    name: 'kanaRa',
    word: 'らりるれろ',
    initialWord: 'ら行',
  },
  KANA_WA: {
    name: 'kanaWa',
    word: 'わゐゑをんゎ',
    initialWord: 'わ行',
  },
} as const;

export type KANA = typeof KANA[keyof typeof KANA];
