import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store/configureStore';

import * as HeaderActions from '../actions/Header';
import * as MoffAPIActions from '../actions/MoffAPI';
import * as Header from '../constants/Header';
import UserSelectKiroku from '../components/Organisms/UserSelectKiroku';

type AllState = ReturnType<typeof store.getState>;

const UserSelectKirokuContainer: FC = () => {
  const dispatch = useDispatch();
  const moffAPI = useSelector((state: AllState) => state.moffAPI);

  useEffect(() => {
    (async () => {
      document.title = '記録一覧(曜日別)';
      dispatch(HeaderActions.setPage(Header.USER_SELECT_KIROKU));
      await dispatch(MoffAPIActions.getUsers());
      dispatch(MoffAPIActions.duplicateRepeatSort());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <UserSelectKiroku moffAPI={moffAPI} />;
};

export default UserSelectKirokuContainer;
