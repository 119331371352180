import * as Popup from '../constants/Popup';

export const OpenPcPrint = () => ({
  type: Popup.OPEN_PCPRINT,
});
export const OpenLiterallyReport = () => ({
  type: Popup.OPEN_LITERALLYREPORT,
});
export const OpenMoffCheck = () => ({
  type: Popup.OPEN_MOFFCHECK,
});
export const OpenADLLocomo = (): any => ({
  type: Popup.OPEN_ADLLOCOMO,
});
export const OpenKiroku = () => ({
  type: Popup.OPEN_KIROKU,
});
export const OpenPress = () => ({
  type: Popup.OPEN_PRESS,
});
export const OpenUserSetting = () => ({
  type: Popup.OPEN_USER_SETTING,
});
export const ClosePopup = (): any => ({
  type: Popup.CLOSE_POPUP,
});
