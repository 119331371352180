import { AxiosInstance, AxiosResponse } from 'axios';
import { Auth, Storage } from 'aws-amplify';
import * as PdfCreateConstants from '../constants/pdfCreate';
import { createAxiosInstance } from '../constants/AWS';

import { apiRequestFunc, errorFunc } from '../utils/apiUtil';
import { waitTimeMs, pdfFileDownload } from '../utils/commonUtil';
import { MOFF_CHECK_COMPARE_LOGIC } from '../constants/MoffCheck';

export class PdfCreate {
  /** インスタンス */
  private static _instance: PdfCreate;
  private axiosInstance!: AxiosInstance;
  // .envの設定
  private API_BASE_URL = String(process.env.REACT_APP_CREATE_PDF_API_URL);

  /** インスタンスの取得 */
  public static get instance(): PdfCreate | null {
    // _inctanceが存在しない場合に、new PdfCreate()を実行する。
    if (!this._instance) {
      this._instance = new PdfCreate();
      // moffAPIはjwtTokenで認証を行うため、初回instance生成時はapiKey・jwtToken共にnullで設定
      // ユーザーセッション取得の際（ページロード時）に、jwtTokenを含むAxiosInstanceで書き換える
      // （jwtToken取得を非同期で行うためreduxのフローにのせる）
      const axiosInstance = createAxiosInstance(this._instance.API_BASE_URL, null, null);

      if (axiosInstance) {
        this._instance.axiosInstance = axiosInstance;
      } else {
        return null;
      }
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  // jwtTokenを含むAxiosInstanceに書き換える関数
  public async setAxiosInstanceWithJwtToken(): Promise<void> {
    const jwtToken: string = await Auth.currentAuthenticatedUser()
      .then((user: any) => user.signInUserSession.idToken.jwtToken)
      .catch(() => '');
    this.axiosInstance = await createAxiosInstance(this.API_BASE_URL, null, jwtToken);
  }

  public createMoffCheckV1PDFRequest(
    compareMonth: MOFF_CHECK_COMPARE_LOGIC,
    selectMonths: string[],
    userIds: string[],
    items: string[],
    isWalkingAbility: boolean,
  ) {
    const uniqueLogicFunc = async () => {
      const requestParams = {
        compare_month: compareMonth,
        select_months: selectMonths,
        user_ids: userIds,
        items: items,
        is_walking_ability: isWalkingAbility,
      };
      const statusId = await this.generateCheckV1PdfStartFunc(requestParams)
        .then((response: AxiosResponse) => {
          return response.data.statusId;
        })
        .catch((error) => {
          throw error;
        });
      // pdf生成リクエストを行ったすぐに、statusを確認すると500エラーが返るため2秒の間隔を開ける
      await waitTimeMs(PdfCreateConstants.STATUS_CHECK_FIRST_INTERVAL);
      // ここをPDFの処理が終わるまで3秒おきにループさせる
      await this.checkStatusAndDownloadPdf(
        statusId,
        PdfCreateConstants.REPORT.MoffCheckV1_AllMeasurement.retryLimit,
        PdfCreateConstants.REPORT.MoffCheckV1_AllMeasurement.retryInterval,
        PdfCreateConstants.REPORT.MoffCheckV1_AllMeasurement.fileName,
      );
      return null;
    };

    return apiRequestFunc(
      PdfCreateConstants.MOFF_CHECK_PDF_CREATE_START,
      PdfCreateConstants.MOFF_CHECK_PDF_CREATE_SUCCEED,
      PdfCreateConstants.MOFF_CHECK_PDF_CREATE_FAIL,
      uniqueLogicFunc,
    );
  }

  public createMoffCheckV2PDFRequest(
    compareMonth: MOFF_CHECK_COMPARE_LOGIC,
    selectMonths: string[],
    userIds: string[],
    comments: { user_id: string; text: string }[],
    isAllUsersPrint: boolean,
  ) {
    const uniqueLogicFunc = async () => {
      const requestParams = {
        compare_month: compareMonth,
        select_months: selectMonths,
        user_ids: userIds,
        comments,
      };
      const statusId = await this.generateCheckV2PdfStartFunc(requestParams)
        .then((response: AxiosResponse) => {
          return response.data.statusId;
        })
        .catch((error) => {
          throw error;
        });
      // pdf生成リクエストを行ったすぐに、statusを確認すると500エラーが返るため2秒の間隔を開ける
      await waitTimeMs(PdfCreateConstants.STATUS_CHECK_FIRST_INTERVAL);
      // ここをPDFの処理が終わるまで3秒おきにループさせる
      const statusCheckRetryLimit = isAllUsersPrint
        ? PdfCreateConstants.REPORT.MoffCheckV2_AllMeasurement.retryLimit.all
        : PdfCreateConstants.REPORT.MoffCheckV2_AllMeasurement.retryLimit.individual;
      await this.checkStatusAndDownloadPdf(
        statusId,
        statusCheckRetryLimit,
        PdfCreateConstants.REPORT.MoffCheckV2_AllMeasurement.retryInterval,
        PdfCreateConstants.REPORT.MoffCheckV2_AllMeasurement.fileName,
      );
      return null;
    };

    return apiRequestFunc(
      PdfCreateConstants.MOFF_CHECK_PDF_CREATE_START,
      PdfCreateConstants.MOFF_CHECK_PDF_CREATE_SUCCEED,
      PdfCreateConstants.MOFF_CHECK_PDF_CREATE_FAIL,
      uniqueLogicFunc,
    );
  }

  public createKirokuPDFRequest(selectMonths: string, userIds: string[]) {
    const uniqueLogicFunc = async () => {
      const requestParams = {
        year_month: selectMonths,
        user_ids: userIds,
      };
      const statusId = await this.generateKirokuPdfStartFunc(requestParams)
        .then((response: AxiosResponse) => {
          return response.data.statusId;
        })
        .catch((error) => {
          throw error;
        });
      // pdf生成リクエストを行ったすぐに、statusを確認すると500エラーが返るため2秒の間隔を開ける
      await waitTimeMs(PdfCreateConstants.STATUS_CHECK_FIRST_INTERVAL);
      // ここをPDFの処理が終わるまで3秒おきにループさせる
      await this.checkStatusAndDownloadPdf(
        statusId,
        PdfCreateConstants.REPORT.Kiroku_All.retryLimit,
        PdfCreateConstants.REPORT.Kiroku_All.retryInterval,
        PdfCreateConstants.REPORT.Kiroku_All.fileName,
      );
      return null;
    };

    return apiRequestFunc(
      PdfCreateConstants.KIROKU_PDF_CREATE_START,
      PdfCreateConstants.KIROKU_PDF_CREATE_SUCCEED,
      PdfCreateConstants.KIROKU_PDF_CREATE_FAIL,
      uniqueLogicFunc,
    );
  }

  public createLiterallyPDFRequest(selectMonths: string, userIds: string[]) {
    const uniqueLogicFunc = async () => {
      const requestParams = {
        year_month: selectMonths,
        user_ids: userIds,
      };
      const statusId = await this.generateLiterallyPdfStartFunc(requestParams)
        .then((response: AxiosResponse) => {
          return response.data.statusId;
        })
        .catch((error) => {
          throw error;
        });
      // pdf生成リクエストを行ったすぐに、statusを確認すると500エラーが返るため2秒の間隔を開ける
      await waitTimeMs(PdfCreateConstants.STATUS_CHECK_FIRST_INTERVAL);
      // ここをPDFの処理が終わるまで3秒おきにループさせる
      await this.checkStatusAndDownloadPdf(
        statusId,
        PdfCreateConstants.REPORT.Literally_All.retryLimit,
        PdfCreateConstants.REPORT.Literally_All.retryInterval,
        PdfCreateConstants.REPORT.Literally_All.fileName,
      );
      return null;
    };

    return apiRequestFunc(
      PdfCreateConstants.LITERALLY_PDF_CREATE_START,
      PdfCreateConstants.LITERALLY_PDF_CREATE_SUCCEED,
      PdfCreateConstants.LITERALLY_PDF_CREATE_FAIL,
      uniqueLogicFunc,
    );
  }

  public createPressPDFRequest(selectMonth: string, userIds: string[], comments: { user_id: string; text: string }[]) {
    const uniqueLogicFunc = async () => {
      const requestParams = {
        year_month: selectMonth,
        user_ids: userIds,
        comments,
      };
      const statusId = await this.generatePressPdfStartFunc(requestParams)
        .then((response: AxiosResponse) => {
          return response.data.statusId;
        })
        .catch((error) => {
          throw error;
        });
      // pdf生成リクエストを行ったすぐに、statusを確認すると500エラーが返るため2秒の間隔を開ける
      await waitTimeMs(PdfCreateConstants.STATUS_CHECK_FIRST_INTERVAL);
      // ここをPDFの処理が終わるまで3秒おきにループさせる
      await this.checkStatusAndDownloadPdf(
        statusId,
        PdfCreateConstants.REPORT.Press_ALL.retryLimit,
        PdfCreateConstants.REPORT.Press_ALL.retryInterval,
        PdfCreateConstants.REPORT.Press_ALL.fileName,
      );
      return null;
    };

    return apiRequestFunc(
      PdfCreateConstants.PRESS_PDF_CREATE_START,
      PdfCreateConstants.PRESS_PDF_CREATE_SUCCEED,
      PdfCreateConstants.PRESS_PDF_CREATE_FAIL,
      uniqueLogicFunc,
    );
  }

  // PDFの生成状況確認＆PDFファイルをダウンロード
  private async checkStatusAndDownloadPdf(
    statusId: string,
    retryLimit: number,
    retryInterval: number,
    fileName: string,
  ) {
    let pdfGenerateResponse;
    let timeOutCount = 0;
    while (timeOutCount < retryLimit) {
      pdfGenerateResponse = await this.fetchGeneratingPpfStatusFunc(statusId)
        .then((response: AxiosResponse) => response.data)
        .catch((error) => {
          throw error;
        });
      if (pdfGenerateResponse?.status === 'SUCCEEDED') {
        await Storage.get(pdfGenerateResponse.key, { download: true })
          .then((result: any) => {
            pdfFileDownload(result.Body, fileName);
          })
          .catch((error) => {
            throw error;
          });
        break;
      } else if (pdfGenerateResponse?.status === 'NOT_FOUND') {
        alert('指定月に測定を行った利用者が存在しません');
        break;
      } else if (pdfGenerateResponse?.status === 'UNAUTHORIZED') {
        alert('セッション切れです。ページをリロードしてお試しください。');
        break;
      } else if (pdfGenerateResponse?.status === 'FAILED') {
        alert('PDF生成に失敗しました。時間をおいてお試しください。');
        break;
      } else {
        await waitTimeMs(retryInterval);
        timeOutCount += 1;
      }
      if (timeOutCount >= retryLimit - 1) alert('タイムアウトしました。時間をおいてお試しください。');
    }
  }

  // モフトレチェック評価レポートPDFの生成を開始する
  public async generateCheckV1PdfStartFunc(requestParams: any) {
    return this.axiosInstance.post(`/check_v1/all`, requestParams);
  }

  // モフトレチェックレポートPDFの生成を開始する
  public async generateCheckV2PdfStartFunc(requestParams: any) {
    return this.axiosInstance.post(`/check_v2/all`, requestParams);
  }

  // 個別機能訓練PDFの生成を開始する
  public async generateKirokuPdfStartFunc(requestParams: any) {
    return this.axiosInstance.post(`/kiroku/all`, requestParams);
  }

  // 機能訓練レポートPDFの生成を開始する
  public async generateLiterallyPdfStartFunc(requestParams: any) {
    return this.axiosInstance.post(`/literally/all`, requestParams);
  }

  // モフトレ通信レポートPDFの生成を開始する
  public async generatePressPdfStartFunc(requestParams: any) {
    return this.axiosInstance.post(`/press/all`, requestParams);
  }

  // PDFの生成状況を確認
  public async fetchGeneratingPpfStatusFunc(statusId: string) {
    return this.axiosInstance.get(`/status/${statusId}`);
  }
}

export const createPdfCreateInstance: any = () => {
  // Moffインスタンスが存在しない場合、エラーアクションを返す
  return PdfCreate.instance === null
    ? [true, null, errorFunc(PdfCreateConstants.UNKNOWN_ERROR, '不明なエラーが発生しました.')]
    : [false, PdfCreate.instance, null];
};
