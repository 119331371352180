// ロコモ比較する.
import React, { FC } from 'react';
import { SCREEN_TYPE, TRAINING_TABLE_PATTERN } from '../../constants/Training';
import { TRAINING_REPORT_NAME } from '../../constants/Training';

import MonthlyReport from '../Molecules/Reports/MonthlyReport';
import DailyReport from '../Molecules/Reports/DailyReport';
import TrainingGraphs from '../Molecules/TrainingGraphs';
import TrainingCustomize from '../Molecules/TrainingCustomize';
import PageLoader from '../Atoms/PageLoader';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { useTrainingUI } from '../../hooks/use-training-ui';
import { useTrainingFetchCompare } from '../../hooks/use-training-fetch-compare';

const useStyles = makeStyles({
  sectionMonthly: {
    '@media print': {
      'page-break-after': 'always',
      margin: '-1cm 0 0 0',
    },
  },
  sectionDaily: {
    '@media print': {
      'page-break-after': 'always',
      margin: '2cm 0 0 0',
    },
  },
  ttlStyle01: {
    padding: '0 45px 0 5px',
    margin: '0 0 20px 0',
    fontSize: '25px',
    borderBottom: 'solid 1px #dcdcdc',
  },
  compareDailyArea: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    '@media print': {
      'page-break-after': 'always',
    },
  },
  compareMonthlyArea: {
    '@media print': {
      flexWrap: 'nowrap',
    },
  },
  sectionCompareGraph: {
    padding: '0 25px',
    margin: '50px 0 0 0',
    '@media print': {
      'page-break-after': 'always',
      margin: '-1cm 0 0 0',
    },
  },
});

interface LocomoCompareProps {
  changeState: (state: SCREEN_TYPE) => void;
}

// 利用者一覧の表のフィールド表示用コンポーネント
const TrainingCompare: FC<LocomoCompareProps> = ({ changeState }) => {
  const classes = useStyles();
  const { isToggleOn, startTogglePosition, endTogglePosition, changeTogglePosition } = useTrainingUI();
  const {
    moffApiState,
    compareStartDate,
    compareEndDate,
    compareStartData,
    compareEndData,
    compareMinusMonthly,
    comparePlusMonthly,
    compareMinusDaily,
    comparePlusDaily,
  } = useTrainingFetchCompare();
  return (
    <>
      {!moffApiState.Loaded && <PageLoader />}
      <Box>
        <Box>
          {!isToggleOn ? (
            <Box>
              <TrainingCustomize />
              {/* トレーニング履歴 （月次）. */}
              <Box component="section" m="20px 0 0 0" p="20px 25px 0" className={classes.sectionMonthly}>
                <h2 className={classes.ttlStyle01}>トレーニング履歴（月次）</h2>
                <Box
                  display="flex"
                  justifyContent="space-around"
                  flexWrap="wrap"
                  className={classes.compareMonthlyArea}
                >
                  <Box flexGrow={1} flexShrink={0} flexBasis="300px" width="100%" data-test="monthly-startReport">
                    <MonthlyReport
                      key={TRAINING_REPORT_NAME.CompareLeft}
                      date={compareStartDate}
                      className={TRAINING_REPORT_NAME.CompareLeft}
                      history={compareStartData}
                      tablePattern={TRAINING_TABLE_PATTERN.tight}
                      togglePosition={startTogglePosition}
                      changeState={changeState}
                      minusMonthly={compareMinusMonthly}
                      plusMonthly={comparePlusMonthly}
                      changeTogglePosition={changeTogglePosition(TRAINING_REPORT_NAME.CompareLeft)}
                    />
                  </Box>
                  <Box flexGrow={1} flexShrink={0} flexBasis="300px" width="100%" data-test="monthly-endReport">
                    <MonthlyReport
                      key={TRAINING_REPORT_NAME.CompareRight}
                      date={compareEndDate}
                      className={TRAINING_REPORT_NAME.CompareRight}
                      history={compareEndData}
                      tablePattern={TRAINING_TABLE_PATTERN.tight}
                      togglePosition={endTogglePosition}
                      changeState={changeState}
                      minusMonthly={compareMinusMonthly}
                      plusMonthly={comparePlusMonthly}
                      changeTogglePosition={changeTogglePosition(TRAINING_REPORT_NAME.CompareRight)}
                    />
                  </Box>
                </Box>
              </Box>
              {/* トレーニング履歴（日次）. */}
              <Box component="section" m="60px 0 0 0" p="30px 25px 0" className={classes.sectionDaily}>
                <h2 className={classes.ttlStyle01}>トレーニング履歴（日次）</h2>
                <Box className={classes.compareDailyArea}>
                  <Box flexGrow={1} flexShrink={0} flexBasis="300px" width="100%" data-test="daily-startReport">
                    <DailyReport
                      key={TRAINING_REPORT_NAME.CompareLeft}
                      date={compareStartDate}
                      className={TRAINING_REPORT_NAME.CompareLeft}
                      history={compareStartData}
                      isDetailComponent={false}
                      isCompareStartTerm={true}
                      minusDaily={compareMinusDaily}
                      plusDaily={comparePlusDaily}
                      changeState={changeState}
                    />
                  </Box>
                  <Box flexGrow={1} flexShrink={0} flexBasis="300px" width="100%" data-test="daily-endReport">
                    <DailyReport
                      key={TRAINING_REPORT_NAME.CompareRight}
                      date={compareEndDate}
                      className={TRAINING_REPORT_NAME.CompareRight}
                      history={compareEndData}
                      isDetailComponent={false}
                      isCompareStartTerm={false}
                      minusDaily={compareMinusDaily}
                      plusDaily={comparePlusDaily}
                      changeState={changeState}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              <TrainingCustomize />
              <Box component="section" className={classes.sectionCompareGraph}>
                <h2 className={classes.ttlStyle01}>1ヶ月の変化</h2>
                <Box display="flex" justifyContent="space-around" flexWrap="wrap" key="report">
                  <TrainingGraphs
                    className={TRAINING_REPORT_NAME.CompareLeft}
                    historyArray={[compareStartData]}
                    date={compareStartDate}
                    screenType={SCREEN_TYPE.CompareLeft}
                    graphWidth={460}
                    graphHeight={330}
                    togglePosition={startTogglePosition}
                    changeState={changeState}
                    minusMonthly={compareMinusMonthly}
                    plusMonthly={comparePlusMonthly}
                    changeTogglePosition={changeTogglePosition(TRAINING_REPORT_NAME.CompareLeft)}
                  />
                  <TrainingGraphs
                    className={TRAINING_REPORT_NAME.CompareRight}
                    historyArray={[compareEndData]}
                    date={compareEndDate}
                    screenType={SCREEN_TYPE.CompareRight}
                    graphWidth={460}
                    graphHeight={330}
                    togglePosition={endTogglePosition}
                    changeState={changeState}
                    minusMonthly={compareMinusMonthly}
                    plusMonthly={comparePlusMonthly}
                    changeTogglePosition={changeTogglePosition(TRAINING_REPORT_NAME.CompareRight)}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TrainingCompare;
