import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store/configureStore';

import * as HeaderActions from '../actions/Header';
import * as PopupActions from '../actions/Popup';
import * as Header from '../constants/Header';
import MenuContents from '../components/Organisms/Menu';
import PopupContainer from './Popup';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

type AllState = ReturnType<typeof store.getState>;

const MenuContainer: FC = () => {
  const dispatch = useDispatch();
  const planType = useSelector((state: AllState) => state.auth.planType);

  useEffect(() => {
    (async () => {
      document.title = 'レポートメニュー';
      dispatch(HeaderActions.setPage(Header.MENU));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openMoffCheck = (): void => {
    dispatch(PopupActions.OpenMoffCheck());
    dispatchIdentifierToGTM('menu_youtube_moff_check');
  };
  const openLiterallyReport = (): void => {
    dispatch(PopupActions.OpenLiterallyReport());
    dispatchIdentifierToGTM('menu_youtube_literally');
  };
  const openADLLocomo = (): void => {
    dispatch(PopupActions.OpenADLLocomo());
    dispatchIdentifierToGTM('menu_youtube_locomo_adl');
  };
  const openKiroku = (): void => {
    dispatch(PopupActions.OpenKiroku());
    dispatchIdentifierToGTM('menu_youtube_kiroku');
  };
  const openPress = (): void => {
    dispatch(PopupActions.OpenPress());
    dispatchIdentifierToGTM('menu_youtube_press');
  };

  return (
    <>
      <PopupContainer />
      <MenuContents
        planType={planType}
        openLiterallyReport={openLiterallyReport}
        openMoffCheck={openMoffCheck}
        openADLLocomo={openADLLocomo}
        openKiroku={openKiroku}
        openPress={openPress}
      />
    </>
  );
};

export default MenuContainer;
