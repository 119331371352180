import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import { User } from '../constants/UserListField';

import store from '../store/configureStore';

import * as MoffAPIActions from '../actions/MoffAPI';

import { SCREEN_TYPE } from '../constants/Training';
import { Training, KirokuRequestParamsFormat, KirokuFormParamsFormat, SuggestWord } from '../constants/Kiroku';

import * as KirokuModule from '../utils/KirokuModule';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

import PageLoader from '../components/Atoms/PageLoader';
import KirokuEdit from '../components/Organisms/KirokuEdit';

type AllState = ReturnType<typeof store.getState>;

interface KirokuEditContainerProps {
  userId: string;
  date: moment.Moment;
  setDate: (date: moment.Moment) => void;
  setScreenState: (screenState: SCREEN_TYPE) => void;
}

const KirokuEditContainer: FC<KirokuEditContainerProps> = ({ userId, date, setDate, setScreenState }) => {
  const dispatch = useDispatch();
  const moffAPIGlobalState = useSelector((state: AllState) => state.moffAPI);

  const [cachedUser, setcachedUser] = useState<User>({
    user_care_id: '',
    user_name: '',
    user_birth_year: '',
    user_gender: 1,
  } as User);
  const [cachedTraining, setCachedTraining] = useState<KirokuRequestParamsFormat[]>([] as KirokuRequestParamsFormat[]);

  useEffect(() => {
    (async () => {
      await dispatch(
        MoffAPIActions.getTrainingDetailData([
          null,
          userId,
          [userId, date.startOf('month').format('YYYY-MM-DD'), date.endOf('month').format('YYYY-MM-DD')],
        ]),
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (moffAPIGlobalState.result.user) {
      setcachedUser(moffAPIGlobalState.result.user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffAPIGlobalState.Loaded, moffAPIGlobalState.result.user]);

  useEffect(() => {
    if (moffAPIGlobalState.Loaded && moffAPIGlobalState.result.filteredTraining) {
      const defalutFormValue = _.map(moffAPIGlobalState.result.filteredTraining, (record: Training) => {
        const { datetime, duration, subject, instructor, remarks } = record;
        return {
          datetime,
          duration,
          subject: subject || '',
          instructor: instructor || '',
          remarks: remarks || '',
          new: false,
        };
      });
      setCachedTraining(defalutFormValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffAPIGlobalState.Loaded, moffAPIGlobalState.result.filteredTraining]);

  // 通常画面月矢印のアクション
  const minusMonthly = async () => {
    setCachedTraining([]);
    const lastMonth = date.subtract(1, 'months');
    setDate(lastMonth);
    await dispatch(
      MoffAPIActions.getTrainingDetailData([
        null,
        userId,
        [userId, lastMonth.startOf('month').format('YYYY-MM-DD'), lastMonth.endOf('month').format('YYYY-MM-DD')],
      ]),
    );
    dispatchIdentifierToGTM('detail_page_change_month_kiroku');
  };

  const plusMonthly = async () => {
    setCachedTraining([]);
    const nextMonth = date.add(1, 'months');
    setDate(nextMonth);
    await dispatch(
      MoffAPIActions.getTrainingDetailData([
        null,
        userId,
        [userId, nextMonth.startOf('month').format('YYYY-MM-DD'), nextMonth.endOf('month').format('YYYY-MM-DD')],
      ]),
    );
    dispatchIdentifierToGTM('detail_page_change_month_kiroku');
  };

  // キャンセルボタンの押下.
  const toKirokuCancel = () => {
    setScreenState(SCREEN_TYPE.KirokuShow);
    dispatchIdentifierToGTM('detail_page_cancel_kiroku');
  };

  // 「保存」ボタン押下時の処理.
  const submitKiroku = async (
    data: KirokuFormParamsFormat[],
    defaultValues: KirokuRequestParamsFormat[],
    isDirty: boolean,
    suggestWordsGroupSubject: SuggestWord[],
  ) => {
    if (moffAPIGlobalState.Loading) {
      return;
    }

    // 「実施日時」の入力が不整合かどうかを確かめる
    const isInvalidDateTimeBool = (data ?? []).every((checkRecord) => {
      return checkRecord.new ? KirokuModule.kirokuFormValidate(checkRecord) : true;
    });
    // 送信レコードに1つでも不整合な値が含まれている場合送信をキャンセル。
    if (isInvalidDateTimeBool) {
      // 新規を含む変更のあったレコードだけを抽出
      const updateRecords = KirokuModule.pickChangedRecords(
        // 不整合アラート用に用意した「index」paramsは取り除きdefaultValuesと比較する
        _.map(data, (data) => _.omit(data, ['index'])),
        defaultValues,
      );
      if (!isDirty || _.isEmpty(updateRecords)) {
        alert('新規または更新する項目がありません');
        return;
      }
      // 「新規」・「更新」データをAPIリクエストできる形に整形する。
      const convertedUpdateRecords = KirokuModule.convertUpdateParams(updateRecords, defaultValues);
      await dispatch(MoffAPIActions.postAndPutFunctionalTrainingHistory(userId, [...convertedUpdateRecords]));
      KirokuModule.saveToLocalStorage(
        KirokuModule.createLocalStoragePostData(convertedUpdateRecords),
        suggestWordsGroupSubject,
      );
      // 新規追加・修正の処理を待った後、typeを切り替え。新規追加・修正後のトレーニング結果をリロードする
      setScreenState(SCREEN_TYPE.KirokuShow);
    } else {
      return;
    }
    dispatchIdentifierToGTM('detail_page_save_kiroku');
  };

  const trainingCount: number = moffAPIGlobalState.result.filteredTraining
    ? moffAPIGlobalState.result.filteredTraining.length
    : 0;
  const isLoadingOrNotImplementTraining: boolean = trainingCount === 0 || !moffAPIGlobalState.Loaded;

  return (
    <>
      {moffAPIGlobalState.Loaded ? null : <PageLoader />}
      <KirokuEdit
        isLoaded={moffAPIGlobalState.Loaded}
        isLoadingOrNotImplementTraining={isLoadingOrNotImplementTraining}
        cachedTraining={cachedTraining}
        cachedUser={cachedUser}
        date={date}
        minusMonthly={minusMonthly}
        plusMonthly={plusMonthly}
        toKirokuCancel={toKirokuCancel}
        submitKiroku={submitKiroku}
      />
    </>
  );
};

export default KirokuEditContainer;
