import { useEffect, useState } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import * as MoffAPIActions from '../actions/MoffAPI';
import { MonthlyTrainingData } from '../constants/Training';
import { dispatchIdentifierToGTM, mergeObject, cloneDeep } from '../utils/commonUtil';
import { convertToAttachmentPosition, omitUnIntroducedFromMonthlyTrainings } from '../utils/locomoAdlModule';
import { useTrainingUI } from './use-training-ui';

export const useTrainingFetchCompare = () => {
  const store = useStore();
  const dispatch = useDispatch();
  type AllState = ReturnType<typeof store.getState>;
  const moffApiState = useSelector((state: AllState) => state.moffAPI);
  const { userId, isToggleOn, startTogglePosition, endTogglePosition } = useTrainingUI();

  const [compareStartDate, setCompareStartDate] = useState(moment().subtract(1, 'months'));
  const [, setCompareStartDateYYYYMMDD] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'));
  const [compareEndDate, setCompareEndDate] = useState(moment());
  const [, setCompareEndDateYYYYMMDD] = useState(moment().format('YYYY-MM-DD'));
  const [compareStartData, setCompareStartData] = useState<MonthlyTrainingData>({});
  const [compareEndData, setCompareEndData] = useState<MonthlyTrainingData>({});
  const displayIdentifier = isToggleOn ? 'graph' : 'table';

  const getCompareData = (startDate: moment.Moment, endDate: moment.Moment) =>
    dispatch(
      MoffAPIActions.getCompareData(
        userId,
        {
          date: startDate.format('YYYY-MM'),
          togglePosition: startTogglePosition,
        },
        {
          date: endDate.format('YYYY-MM'),
          togglePosition: endTogglePosition,
        },
      ),
    );

  // 画面読み込み時の処理。トレーニングの比較データ取得(選択月2月分)
  useEffect(() => {
    getCompareData(compareStartDate, compareEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareEndDate, compareStartDate]);

  // 「両側」「左」「右」をトグルで切り替えた時の処理
  useEffect(() => {
    getCompareData(compareStartDate, compareEndDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTogglePosition, endTogglePosition]);

  // 比較画面左側の「両側」「左」「右」をトグルで切り替えた時の処理
  useEffect(() => {
    const attachmentPosition = convertToAttachmentPosition(startTogglePosition);
    if (attachmentPosition?.name) {
      dispatchIdentifierToGTM(`compare_${displayIdentifier}_${attachmentPosition.name}_moff_training`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTogglePosition]);

  // 比較画面右側の「両側」「左」「右」をトグルで切り替えた時の処理
  useEffect(() => {
    const attachmentPosition = convertToAttachmentPosition(endTogglePosition);
    if (attachmentPosition?.name) {
      dispatchIdentifierToGTM(`compare_${displayIdentifier}_${attachmentPosition.name}_moff_training`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTogglePosition]);

  // トレーニングデータ読み込み完了後、ロコモ・ADLのデータを合成しローカルステートに格納する
  useEffect(() => {
    const copyLocomoStartData = cloneDeep(moffApiState.result.locomoStartData);
    const copyAdlStartData = cloneDeep(moffApiState.result.adlStartData);
    const fetchedAllStartMonthlyData = mergeObject<MonthlyTrainingData>(copyLocomoStartData, copyAdlStartData);
    const compareStartData = omitUnIntroducedFromMonthlyTrainings(fetchedAllStartMonthlyData);
    setCompareStartData(compareStartData);
  }, [moffApiState.result.locomoStartData, moffApiState.result.adlStartData]);

  // トレーニングデータ読み込み完了後、ロコモ・ADLのデータを合成しローカルステートに格納する
  useEffect(() => {
    const copyLocomoStartData = cloneDeep(moffApiState.result.locomoEndData);
    const copyAdlEndData = cloneDeep(moffApiState.result.adlEndData);
    const fetchedAllEndMonthlyData = mergeObject<MonthlyTrainingData>(copyLocomoStartData, copyAdlEndData);
    const compareEndData = omitUnIntroducedFromMonthlyTrainings(fetchedAllEndMonthlyData);
    setCompareEndData(compareEndData);
  }, [moffApiState.result.locomoEndData, moffApiState.result.adlEndData]);

  // 比較画面月矢印のアクション
  const compareMinusMonthly = async (className: string) => {
    switch (className) {
      case 'startReport': {
        const lastMonth = compareStartDate.subtract(1, 'months');
        setCompareStartDate(lastMonth);
        await getCompareData(lastMonth, compareEndDate);
        break;
      }
      case 'endReport': {
        const lastMonth = compareEndDate.subtract(1, 'months');
        setCompareEndDate(lastMonth);
        await getCompareData(compareStartDate, lastMonth);
        break;
      }
      default: {
        break;
      }
    }
    dispatchIdentifierToGTM(`detail_page_change_month_moff_training`);
  };

  const comparePlusMonthly = async (className: string) => {
    switch (className) {
      case 'startReport': {
        const nextMonth = compareStartDate.add(1, 'months');
        setCompareStartDate(nextMonth);
        await getCompareData(nextMonth, compareEndDate);
        break;
      }
      case 'endReport': {
        const nextMonth = compareEndDate.add(1, 'months');
        setCompareEndDate(nextMonth);
        await getCompareData(compareStartDate, nextMonth);
        break;
      }
      default: {
        break;
      }
    }
    dispatchIdentifierToGTM(`detail_page_change_month_moff_training`);
  };

  const compareMinusDaily = async (className: string) => {
    switch (className) {
      case 'startReport': {
        const prevMonth = compareStartDate.format('MM');
        // 前日.
        const lastDate = compareStartDate.subtract(1, 'days');
        setCompareStartDate(lastDate);
        setCompareStartDateYYYYMMDD(lastDate.format('YYYY-MM-DD'));
        // 月に変化があったとき.
        if (prevMonth !== lastDate.format('MM')) {
          await getCompareData(lastDate, compareEndDate);
        }
        break;
      }
      case 'endReport': {
        const prevMonth = compareEndDate.format('MM');
        // 前日.
        const lastDate = compareEndDate.subtract(1, 'days');
        setCompareEndDate(lastDate);
        setCompareEndDateYYYYMMDD(lastDate.format('YYYY-MM-DD'));
        // 月に変化があったとき.
        if (prevMonth !== lastDate.format('MM')) {
          await getCompareData(compareStartDate, lastDate);
        }
        break;
      }
      default: {
        break;
      }
    }
    dispatchIdentifierToGTM(`detail_page_change_day_moff_training`);
  };

  const comparePlusDaily = async (className: string) => {
    switch (className) {
      case 'startReport': {
        const prevMonth = compareStartDate.format('MM');
        // 前日.
        const lastDate = compareStartDate.add(1, 'days');
        setCompareStartDate(lastDate);
        setCompareStartDateYYYYMMDD(lastDate.format('YYYY-MM-DD'));
        // 月に変化があったとき.
        if (prevMonth !== lastDate.format('MM')) {
          await getCompareData(lastDate, compareEndDate);
        }
        break;
      }
      case 'endReport': {
        const prevMonth = compareEndDate.format('MM');
        // 前日.
        const lastDate = compareEndDate.add(1, 'days');
        setCompareEndDate(lastDate);
        setCompareEndDateYYYYMMDD(lastDate.format('YYYY-MM-DD'));
        // 月に変化があったとき.
        if (prevMonth !== lastDate.format('MM')) {
          await getCompareData(compareStartDate, lastDate);
        }
        break;
      }
      default: {
        break;
      }
    }
    dispatchIdentifierToGTM(`detail_page_change_day_moff_training`);
  };

  return {
    moffApiState,
    compareStartDate,
    compareEndDate,
    compareStartData,
    compareEndData,
    compareMinusMonthly,
    comparePlusMonthly,
    compareMinusDaily,
    comparePlusDaily,
  };
};

type useTrainingFetchCompare = ReturnType<typeof useTrainingFetchCompare>;
