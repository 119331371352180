import { createMuiTheme } from '@material-ui/core/styles';
import pink from '@material-ui/core/colors/pink';

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#E8DFE4',
      main: '#ED9B34',
      dark: '#7C644E',
      contrastText: '#fff',
    },
    secondary: {
      light: 'FFB492',
      main: '#EA6D36',
      contrastText: '#fff',
    },
    error: {
      light: pink.A100,
      main: pink.A200,
      contrastText: pink.A400,
    },
  },
});
