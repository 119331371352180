// 個別機能訓練計画reducer.
import * as FunctionalTrainingPlan from '../../constants/MoffRaku';

const initialState = {
  Loading: false,
  LoadedCount: 0,
};

export default function functionalTrainingPlan(state: any = initialState, action: any) {
  switch (action.type) {
    case FunctionalTrainingPlan.LOADING:
      return Object.assign({}, state, {
        type: FunctionalTrainingPlan.LOADING,
        Loading: true,
        LoadedCount: 0,
      });
    case FunctionalTrainingPlan.FINISH:
      return Object.assign({}, state, {
        type: FunctionalTrainingPlan.FINISH,
        Loading: false,
        LoadedCount: state.LoadedCount + 1,
      });
    case FunctionalTrainingPlan.SUCCESS:
      return Object.assign({}, state, {
        type: FunctionalTrainingPlan.SUCCESS,
        Loading: false,
        result: action.result,
      });
    case FunctionalTrainingPlan.ERROR:
      return Object.assign({}, state, {
        type: FunctionalTrainingPlan.SUCCESS,
        Loading: false,
        error: action?.error,
      });
    default:
      return state;
  }
}
