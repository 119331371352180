// 個別機能訓練に関する記録用モジュール.
import * as KirokuConst from '../constants/Kiroku';
import * as _ from 'lodash';
import TagManager from 'react-gtm-module';

import { convertTime } from './dateUtil';

import { User } from '../constants/UserListField';
import { KirokuRequestParamsFormat } from '../constants/Kiroku';

// safari・firefox・IEで不整合な日時が入力されないためのバリデーション
export function isDateTimeCorrect(dayValue: any) {
  return dayValue &&
    !dayValue.match(
      // tslint:disable-next-line: max-line-length
      /^(\d{4}|\d{4}-(0[1-9]|1[0-2])|(?!([02468][1-35-79]|[13579][013-57-9])00-02-29)((\d{4}-(01|03|05|07|08|10|12)-(0[1-9]|[12]\d|3[01]))|(\d{4}-(04|06|09|11)-(0[1-9]|[12]\d|30))|(\d{4}-02-(0[1-9]|1\d|2[0-8]))|(\d{2}([02468][048]|[13579][26])-02-29)))T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[0-5][0-9])$/,
    )
    ? false
    : true;
}

export const kirokuFormValidate = (newValue: any, recordIndex?: number) => {
  // 日時のバリデーション
  const isDateTimeCorrectBool = isDateTimeCorrect(newValue.datetime);
  if (!isDateTimeCorrectBool) {
    alert(
      `${recordIndex ? recordIndex.toString() : newValue.index}行目の「実施日時」の入力「${
        newValue.datetime
      }」が不正です。\n` +
        '実施日時をテキスト入力する場合は初期入力値と同じ形式で入力してください。\n' +
        '※ 全角（数字・記号）、スペースは使用できません。\n' +
        '※ 一般的な日時範囲を超える入力はできません。\n\n' +
        '例： 「2019年1月23日 12時34分」の場合、「2019-01-23T12:34」\n\n' +
        'どうしても入力できない場合は「google chrome」から選択形式で入力することをおすすめ致します。',
    );
  }
  return isDateTimeCorrectBool;
};

export const allKirokuFormValidate = (values: any) => {
  const validateResult = values.map((record: KirokuConst.PostParamsFormat, index: number) => {
    return kirokuFormValidate(record, index + 1);
  });
  return validateResult.every((result: boolean) => result === true);
};

// 「編集」画面で「キャンセル」ボタンが押された時の処理
export function toKiroku(self: any): void {
  self.context.callbackParent(KirokuConst.type.Show);
}

/**
 * 「新規」・「更新」において、フォームに入力された値を、APIリクエスト可能な形に変換する
 *  変換された値は今後変換されるとデータ不整合に繋がるため「ReadonlyArray」にして編集できないようにする
 */
export const convertUpdateParams = (
  updateRecords: KirokuConst.KirokuRequestParamsFormat[],
  initialRecord: KirokuConst.KirokuRequestParamsFormat[],
): ReadonlyArray<KirokuConst.KirokuRequestParamsFormat> => {
  return _.map(updateRecords, (updateRecord) => {
    const copyUpdateRecord = { ...updateRecord };
    // 「新規」の時
    if (copyUpdateRecord.new) {
      copyUpdateRecord.datetime = `${copyUpdateRecord.datetime.replace(/T/g, ' ')}:00`;
      // 「更新」の時
    } else {
      // 更新時すでに値がある項目を空欄にすると、API側で「通信エラー」になるため、空欄を入力した場合は初期値を再代入する。
      if (_.some(copyUpdateRecord, (el) => el === '')) {
        _.map(['subject', 'instructor', 'remarks'], (coloumName: string) => {
          if (copyUpdateRecord[coloumName] === '') {
            copyUpdateRecord[coloumName] = _.filter(
              [...initialRecord],
              (record) => record.datetime === copyUpdateRecord.datetime,
            )[0][coloumName];
          }
        });
      }
      copyUpdateRecord.datetime = convertTime(copyUpdateRecord.datetime)['ux-qa'];
    }

    // リクエストにおいて必ず存在する（必要になる）項目を先に定義
    const convertedParams = {
      datetime: copyUpdateRecord.datetime,
      duration: copyUpdateRecord.duration || '00:00:00',
      new: copyUpdateRecord.new || false,
    };
    // 「更新」の際空白の要素をリクエストすると、サーバ側でエラーになってしまうため、データ内容がある場合のみ格納する
    _.map(['subject', 'instructor', 'remarks'], (columnName: string) => {
      if (copyUpdateRecord[columnName]) {
        convertedParams[columnName] = copyUpdateRecord[columnName];
      }
    });

    return convertedParams;
  });
};

// ローカルストレージへ入力するデータを整形
export const createLocalStoragePostData = (
  convertedUpdateRecords: ReadonlyArray<KirokuConst.KirokuRequestParamsFormat>,
) => {
  return {
    subject: {
      texts: _.map(convertedUpdateRecords, (el: KirokuConst.KirokuRequestParamsFormat) => ({ text: el.subject })),
    },
    instructor: {
      texts: _.map(convertedUpdateRecords, (el: KirokuConst.KirokuRequestParamsFormat) => ({ text: el.instructor })),
    },
  };
};

export const removeMoffDefaulValueFunc = (postListForEachColumns: string[], suggestWordsGroupSubject: any) => {
  return _.filter(postListForEachColumns, (postWord: string) => {
    return _.every(
      suggestWordsGroupSubject.map((supportWordObj: any) => supportWordObj.text),
      (supportWord: string) => {
        return postWord !== supportWord;
      },
    );
  });
};

export const pickChangedRecords = (
  newValues: KirokuConst.KirokuRequestParamsFormat[],
  defaultValues: KirokuConst.KirokuRequestParamsFormat[],
) => {
  return _.differenceWith(newValues, defaultValues, _.isEqual);
};

// ローカルストレージへ入力履歴を保存
export const saveToLocalStorage = (postData: any, suggestWordsGroupSubject: any) => {
  _.map(['subject', 'instructor'], (formColumnName: string) => {
    const postListForEachColumns: string[] = _.map(postData[formColumnName].texts, (el) => el.text);

    // subjectのみ、「投稿されたデータ」が「モフトレ定型文」と一致しているとき重複して履歴に保存しないようにする
    const listRemoveMoffDefaulValue: string[] =
      formColumnName === 'subject'
        ? removeMoffDefaulValueFunc(postListForEachColumns, suggestWordsGroupSubject)
        : postListForEachColumns;

    // 「投稿されたデータ」が「すでに履歴として登録されているワード」と一致しているとき、重複して履歴に保存しないようにする
    if (localStorage.getItem(formColumnName)) {
      const currentStorageList: string[] = _.map(
        JSON.parse(localStorage.getItem(formColumnName) || '').texts,
        (localStorageWord: any) => localStorageWord.text,
      );
      const uniqPostList: any[] = _.uniq([..._.without(listRemoveMoffDefaulValue, ''), ...currentStorageList]);
      localStorage.setItem(
        formColumnName,
        JSON.stringify({ texts: _.map(uniqPostList, (uniqPost: any) => ({ text: uniqPost })) }),
      );
    } else {
      localStorage.setItem(
        formColumnName,
        JSON.stringify({
          texts: _.map(_.without(listRemoveMoffDefaulValue, ''), (removeMoffDefaulValue: any) => ({
            text: removeMoffDefaulValue,
          })),
        }),
      );
    }
  });
};

// Google Analytics GTMのため.
export const saveTagManager = () => {
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV_NAME === 'prod') {
    const tagManagerArgs = {
      dataLayerName: 'PageDataLayer',
      dataLayer: {
        event: 'save_kiroku',
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }
};

export const checkUsers = (checkedUsers: User[]) => {
  if (checkedUsers.length === 0) {
    alert('ユーザーが選択されていません');
    return false;
  }
  return true;
};

export const checkDatetime = (values: KirokuRequestParamsFormat[]) => {
  const records = values.map((record) => {
    return {
      ...record,
      datetime: `${record.datetime.replace(/T/g, ' ')}:00`,
      duration: record.duration || '00:00:00',
    };
  });
  return records;
};
