import moment from 'moment';
// NOTE: lodashは容量が大きいため、いつでも依存を解消できるように関数でラップする
import _ from 'lodash';
import TagManager from 'react-gtm-module';
import { USERS_SORT_KEYS } from '../constants/UserListField';
import { User } from '../constants/UserListField';

// イベントさせない
export function returnFalse(): boolean {
  return false;
}

// ログイン済みならメニュー表示、未ログインならログイン画面に遷移するページのURL.
export const LOGIN_URL = '/admin.html';

// 指定ms待つ
export const waitTimeMs = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
// 指定msに0を指定した場合、ブラウザのレスポンスを待ってから次の処理を実行できる
export const waitResponseFromBrowser = () => {
  return new Promise((resolve) => setTimeout(resolve, 0));
};

/**
 * Base64に変換.
 *
 * @export
 * @param {*} origin 元の値.
 * @returns {string} Base64の文字列.
 */
export function encodeBase64(origin: any): string {
  const objJsonStr = JSON.stringify(origin);
  const objJsonB64 = Buffer.from(objJsonStr).toString('base64');
  return objJsonB64;
}

/**
 * Base64から戻す.
 *
 * @export
 * @param {string} encodedData Base64の文字列.
 * @returns {string} 元の値.
 */
export function decodeBase64(encodedData: string): string {
  const moji = Buffer.from(encodedData, 'base64').toString('utf-8', 0, encodedData.length);
  const objJsonStr = JSON.parse(moji);
  return objJsonStr;
}

/**
 * クエリ文字列を変換する.
 *
 * @export
 * @param {string} query
 * @returns
 */
export function parseQueryString(query: string) {
  const obj = Object.create(null);
  query
    .slice(query.indexOf('?') + 1, query.length)
    .split('&')
    .forEach((entry) => {
      Object.assign(obj, {
        [entry.split('=')[0]]: entry.split('=')[1],
      });
    });
  return obj;
}

/**
 * 配列の要素にオブジェクトが含まれているケースのクイックソート
 * @export
 * @param {string} sortKey ソートを行うためにキーとなる属性値
 * @param {{key: string | number}[]} objectList ソートを行う配列（要素はオブジェクト）
 * @param {number} startID ソートを行う配列の開始インデックス（範囲指定）
 * @param {number} endID ソートを行う配列の終了インデックス（範囲指定）
 * @param {boolean} isDescendingOrder 降順にするかのフラグ
 * @returns {any[]} キーとなる属性値で並び替えた後の配列を返す
 */
export function objectQuickSort(
  sortKey = '',
  objectList: { key: string | number }[] = [],
  startID = 0,
  endID = 0,
  isDescendingOrder = false,
): any[] {
  const pivot: any = objectList[Math.floor((startID + endID) / 2)];
  let left: number = startID;
  let right: number = endID;
  for (;;) {
    // クイックソート開始
    while (
      isDescendingOrder ? objectList[left][sortKey] > pivot[sortKey] : objectList[left][sortKey] < pivot[sortKey]
    ) {
      left++;
    }
    while (
      isDescendingOrder ? pivot[sortKey] > objectList[right][sortKey] : pivot[sortKey] < objectList[right][sortKey]
    ) {
      right--;
    }
    if (right <= left) {
      break;
    }
    [objectList[left], objectList[right]] = [objectList[right], objectList[left]];
    left++;
    right--;
  }
  // 再帰的にソートを行う
  if (startID < left - 1) {
    objectQuickSort(sortKey, objectList, startID, left - 1, isDescendingOrder);
  }
  if (right + 1 < endID) {
    objectQuickSort(sortKey, objectList, right + 1, endID, isDescendingOrder);
  }
  return objectList;
}

/**
 * オブジェクトのキーを変更しながら、重複している箇所のみ再帰的にソートを行う
 * @export
 * @param {object} sortKeys ソートを行うためにキーとなる属性値の連想配列（優先順位順）
 * @param {ObjectList} objectList ソートを行う配列（要素はオブジェクト）
 * @param {any} sortMethod ソート方法（関数）
 * @param {boolean} isDescendingOrder 降順にするかのフラグ
 * @returns {any[]} キーとなる属性値で並び替えた後の配列を返す
 */

export function duplicateRepeatSort<ObjectList extends any[]>(
  sortKeys: string[],
  objectList: ObjectList,
  sortMethod: Function = objectQuickSort,
  isDescendingOrder = false,
): ObjectList {
  // ユーザー配列をコピーしてソートを行う
  const sortList = Object.assign([], objectList);
  const sortStartIndex = 0;
  const sortEndIndex: number = sortList.length - 1;
  let duplicateStartAndEndIndex: any[] = [[sortStartIndex, sortEndIndex]];
  let tmpStartAndEndIndex: any[] = [];
  // 重複値がなくなるまで、オブジェクト属性を切り替えながらソートを繰り返す
  for (const key of sortKeys) {
    for (const indexVal of duplicateStartAndEndIndex) {
      sortMethod(key, sortList, indexVal[0], indexVal[1], isDescendingOrder);
      // 配列をソート後、重複している値の開始位置、終了位置を検索 & 取得
      duplicateStartAndEndIndex = sortList
        .map((val) => val[key])
        .map((val, idx, arr) => {
          // 重複範囲外はnullを返し、最終的にnullの要素を消すことにより重複範囲を抽出
          if (idx < indexVal[0] || idx > indexVal[1]) {
            return null;
          }
          if (arr.indexOf(val, indexVal[0]) === idx && idx !== arr.lastIndexOf(val, indexVal[1])) {
            return [arr.indexOf(val, indexVal[0]), arr.lastIndexOf(val, indexVal[1])];
          }
          return null;
        })
        .filter(Boolean);
      // 重複している値の範囲が複数あったとき場合mapで上書きされてしまうため、一時的に範囲を退避し、全て結合する
      tmpStartAndEndIndex = tmpStartAndEndIndex.concat(duplicateStartAndEndIndex);
    }
    // 重複している値が存在しない時終了、存在しているときソート範囲を重複している範囲に変更
    if (tmpStartAndEndIndex.length <= 0) {
      break;
    } else {
      duplicateStartAndEndIndex = tmpStartAndEndIndex;
      tmpStartAndEndIndex = [];
    }
  }
  return sortList;
}

export const osJudgement = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  // IOS13からuserAgentだけでIpadとMacを判定できなくなったため、以下処理でIpadの判定を行う。
  const ipad = userAgent.indexOf('ipad') > -1 || (userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document);
  if (userAgent.indexOf('iphone') !== -1) {
    return 'iphone';
  } else if (ipad) {
    return 'ipad';
  } else if (userAgent.indexOf('android') !== -1) {
    if (userAgent.indexOf('mobile') !== -1) {
      return 'android';
    } else {
      return 'androidTablet';
    }
  } else {
    return 'PC';
  }
};

/**
 * 取得した最新記録日を利用者リストの対象利用者データに加える.
 *
 * @currentUserList {User[]}
 * @payload {{ user_id: string, displayDate: string }}
 */
export const addLatestDisplayDate = (currentUserList: User[], payload: { user_id: string; displayDate: string }) => {
  // payloadのuser_idと同じuser_idのuserのインデックスを代入する
  const foundIndex = currentUserList.findIndex((user: User) => user.user_id === payload.user_id);
  // usersをコピーする
  const addedLatestDateUsers = currentUserList.slice();
  // TODO コメント
  addedLatestDateUsers.splice(foundIndex, 1, {
    ...currentUserList[foundIndex],
    display_date: payload.displayDate,
  });

  return addedLatestDateUsers;
};

/**
 * 利用者リストをソートキーで並び替える
 *
 * @currentUserList {User[]}
 * @payload {{ key: string, isDescendingOrder: boolean }}
 */
export const usersSortByKey = (currentUserList: User[], payload: { key: string; isDescendingOrder: boolean }) => {
  // USERS_SORT_KEYSをコピーする
  const NEW_USERS_SORT_KEYS = USERS_SORT_KEYS.slice();
  // NEW_USERS_SORT_KEYSからpayloadのkeyのindexを特定する
  const targetIndex = NEW_USERS_SORT_KEYS.findIndex((key: string) => key === payload.key);
  // 特定したキーを削除する
  if (targetIndex !== -1) {
    NEW_USERS_SORT_KEYS.splice(targetIndex, 1);
  }
  // payloadのkeyを最優先にするためにNEW_USERS_SORT_KEYSの先頭に追加する
  NEW_USERS_SORT_KEYS.unshift(payload.key);
  // 再起的にソートする
  const sortedUsers = duplicateRepeatSort(
    NEW_USERS_SORT_KEYS,
    currentUserList,
    objectQuickSort,
    payload.isDescendingOrder,
  );

  return sortedUsers;
};

export const pdfFileDownload = async (file: any, fileName: string) => {
  const blob = new Blob([file], { type: 'application/pdf' });
  const RESOURCE_RELESE_WAIT_TIME = 200;
  if ((window.navigator as any).msSaveOrOpenBlob) {
    // IE11・旧Edge
    (window.navigator as any).msSaveOrOpenBlob(blob, `${fileName}_${moment().format('YYYY-MM-DD')}.pdf`);
  } else {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}_${moment().format('YYYY-MM-DD')}`;
    link.click();
    await waitTimeMs(RESOURCE_RELESE_WAIT_TIME);
    URL.revokeObjectURL(url);
    // Ipadのみ「WebkitBlobRessource error 1」が発生するため、linkを開放する
    if (osJudgement() !== 'PC') {
      document.body.contains(link) && document.body.removeChild(link);
    }
  }
};

export const csvFileDownload = (downLoadUrl: string, fnName: string) => {
  const link = document.createElement('a', { is: fnName });
  link.setAttribute('id', fnName);
  link.download = fnName;
  link.href = downLoadUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const xlsxFileDownload = async (file: any, fileName: string) => {
  // APIのレスポンスはbase64エンコードされてるので、デコード
  const bin = window.atob(file);
  // Blob変換する際に、バイナリデータで格納しないと、さらにエンコードされてしまう
  // ref: https://qiita.com/kke1229/items/5fb05b3a5381cfbcd9be
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  const blob = new Blob([buffer.buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  // const blob = new Blob([buffer.buffer], { type: "application/octet-binary" });
  const RESOURCE_RELESE_WAIT_TIME = 200;
  if ((window.navigator as any).msSaveOrOpenBlob) {
    // IE11・旧Edge https://qiita.com/wadahiro/items/eb50ac6bbe2e18cf8813
    (window.navigator as any).msSaveOrOpenBlob(blob, `${fileName}.xlsx`);
  } else {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}.xlsx`;
    link.click();
    await waitTimeMs(RESOURCE_RELESE_WAIT_TIME);
    URL.revokeObjectURL(url);
    // Ipadのみ「WebkitBlobRessource error 1」が発生するため、linkを開放する
    if (osJudgement() !== 'PC') {
      document.body.contains(link) && document.body.removeChild(link);
    }
  }
};

export const dispatchIdentifierToGTM = (identifier: string): void => {
  const tagManagerArgs = {
    dataLayerName: 'PageDataLayer',
    dataLayer: {
      event: identifier,
    },
  };
  TagManager.dataLayer(tagManagerArgs);
};

/**
 * 【lodashラッパー】配列またはオブジェクトが空か判定
 */
export const isEmpty = <T>(value: T): boolean => _.isEmpty(value);

/**
 * 【lodashラッパー】値がnullもしくはundefinedか判定
 */
export const isNil = <T>(value: T): boolean => _.isNil(value);

/**
 * 【lodashラッパー】オブジェクトの中から、指定のkey（複数指定可）のみ取り出す
 */
export const pick = <T extends Record<string, unknown>>(object: T, keys: (keyof T)[]): Pick<T, keyof T> =>
  _.pick(object, keys);

/**
 * 【lodashラッパー】オブジェクトの中から、指定のkey（複数指定可）を取り除く
 */
export const omit = <T extends Record<string, unknown>>(object: T, keys: (keyof T)[]): Omit<T, keyof T> =>
  _.omit(object, keys);

/**
 * 【lodashラッパー】オブジェクトの中から、valueが指定関数に一致するものを取り除く
 */
export const omitBy = <T>(
  object: _.Dictionary<T> | null | undefined,
  predicate?: _.ValueKeyIteratee<T>,
): _.Dictionary<T> => _.omitBy(object, predicate);

/**
 * 【lodashラッパー】オブジェクトをマージする
 */
export const mergeObject = <T>(originalObjectArray: T, additionalObjectArray: T): T =>
  _.merge(originalObjectArray, additionalObjectArray);

/**
 * 【lodashラッパー】ディープコピーする
 */
export const cloneDeep = <T>(object: T): T => _.cloneDeep(object);

/**
 * 配列の中から最大値を返す
 * @param {number[]} array 最大値を求める配列
 * @return {number} 最大値
 */
export const calculateMaxCountInArray = (array: number[]): number =>
  !_.isEmpty(array) ? Math.max(...(array as number[])) : 0;

/**
 * 任意の桁で切り上げする
 * @param {number} value 切り上げする数値
 * @param {number} digit どの桁で切り上げするか（10→10の位、0.1→小数第１位）
 * @return {number} 切り上げした値
 */
export const numberRoundUp = (value: number, digit: number) => Math.ceil(value / digit) * digit;

/**
 * 配列から任意の1つのオブジェクトに変換するイテレータ
 * @param {T[]} array 元配列
 * @param {(v: T, i?: number) => U} callbackfn 生成するオブジェクトの形式を整形する関数
 * @return {U} 生成されたオブジェクト
 */
export const mapReturnObject = <T, U>(array: T[], callbackfn: (v: T, i: number) => U): U =>
  array.reduce((objects, v, i) => ({ ...objects, ...callbackfn(v, i) }), {} as U);

/**
 * 配列を分割する関数
 * @param {T[]} array 元配列
 * @param {number} eachArraySize 分割する配列のサイズ
 * @return {T[]} 分割された配列（行列）
 */
export const divideArray = <T>(array: T[], eachArraySize: number): T[][] =>
  new Array(Math.ceil(array.length / eachArraySize))
    .fill(null)
    .map((_, i) => array.slice(i * eachArraySize, (i + 1) * eachArraySize));
