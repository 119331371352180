// ユーザー一覧の表示(Show).
import React, { FC } from 'react';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { MOFF_CHECK_ALL_MEASUREMENT_LIST, MoffCheckV2ReportActionPayload } from '../../constants/MoffCheck';
import { measurementUnitJp, judgeDisplayDiff, displayMeasurementResult } from '../../utils/MoffCheckModule';

import ArrowIcon from '../Atoms/ArrowIcon';

const useStyles = makeStyles((theme) => ({
  resultTable: {
    width: '100%',
    tableLayout: 'fixed',
    overflowWrap: 'break-word',
    borderCollapse: 'collapse',
    borderColor: '#999',
    borderSpacing: 0,
    '& td': {
      backgroundColor: '#fff',
      borderColor: '#999',
      borderStyle: 'solid',
      borderWidth: '1px',
      color: '#333',
      overflow: 'hidden',
      padding: '10px 5px',
      wordBreak: 'normal',
      textAlign: 'center',
    },
    '& th': {
      backgroundColor: '#e8e4df',
      borderColor: '#999',
      borderStyle: 'solid',
      borderWidth: '1px',
      color: '#333',
      fontWeight: 'normal',
      overflow: 'hidden',
      padding: '10px 5px',
      wordBreak: 'normal',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8em',
      lineHeight: '1.3em',
    },
  },
}));

const TableRow: FC<{
  testid: string;
  allMeasurementResult: MoffCheckV2ReportActionPayload;
  measurement: MOFF_CHECK_ALL_MEASUREMENT_LIST;
}> = ({ testid, allMeasurementResult, measurement }) => {
  const resultEachMonth = allMeasurementResult.data.check[measurement];
  const resultComparison = allMeasurementResult.data.check[measurement].comparison;
  const measurementUnit = measurementUnitJp(measurement);
  const isDisplayDiff = judgeDisplayDiff(resultEachMonth?.first, resultEachMonth?.second, resultComparison);
  const [firstCell, secondCell, thirdCell, average] = _.map(
    ['first', 'second', 'third', 'average'],
    (prefix: string) => {
      return displayMeasurementResult(resultEachMonth?.[prefix], measurementUnit, prefix === 'average' ? '' : '-');
    },
  );

  return (
    <tr data-test={testid}>
      <td data-test="title">{allMeasurementResult.title[measurement]}</td>
      <td data-test="first">
        <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
          <Box width="calc(100% - 24px)" display="table-cell" textAlign="center">
            {firstCell}
            {isDisplayDiff && resultComparison ? `（${resultComparison.sign + resultComparison.diff}）` : ''}
          </Box>
          {isDisplayDiff && resultComparison ? (
            <Box display="flex">
              <ArrowIcon direction={resultComparison.evaluation} />
            </Box>
          ) : null}
        </Box>
      </td>
      <td data-test="second">{secondCell}</td>
      <td data-test="third">{thirdCell}</td>
      <td data-test="average">{average}</td>
    </tr>
  );
};

export interface MoffCheckMeasurementListProps {
  allMeasurementResult: MoffCheckV2ReportActionPayload;
}

// 詳細本体.
const MoffCheckMeasurementList: FC<MoffCheckMeasurementListProps> = ({ allMeasurementResult }) => {
  const classes = useStyles();

  return _.isEmpty(allMeasurementResult) ? null : (
    <>
      <table className={classes.resultTable} data-test="moff-check-measurement-result-table">
        <thead>
          <tr>
            <th></th>
            {_.map(Object.values(allMeasurementResult.data.month), (month: string, index: number) => (
              <th key={`month_${index}`} data-test={`month-${index}`}>
                {month ?? '-'}
              </th>
            ))}
            <th data-test="average-title">{allMeasurementResult.data.average_title}</th>
          </tr>
        </thead>
        <tbody>
          {_.map(MOFF_CHECK_ALL_MEASUREMENT_LIST, (measurement: MOFF_CHECK_ALL_MEASUREMENT_LIST, index: number) => (
            <TableRow
              testid={`measurement-${index}`}
              key={`measurement_${index}`}
              allMeasurementResult={allMeasurementResult}
              measurement={measurement}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default MoffCheckMeasurementList;
