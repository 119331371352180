import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { User } from '../constants/UserListField';

import store from '../store/configureStore';

import * as MoffAPIActions from '../actions/MoffAPI';

import { SCREEN_TYPE } from '../constants/Training';
import { Training } from '../constants/Kiroku';

import PageLoader from '../components/Atoms/PageLoader';
import KirokuShow from '../components/Organisms/KirokuShow';

import { dispatchIdentifierToGTM } from '../utils/commonUtil';

type AllState = ReturnType<typeof store.getState>;

interface KirokuShowContainerProps {
  userId: string;
  date: moment.Moment;
  setDate: (date: moment.Moment) => void;
  setScreenState: (screenState: SCREEN_TYPE) => void;
}

const KirokuShowContainer: FC<KirokuShowContainerProps> = ({ userId, date, setDate, setScreenState }) => {
  const dispatch = useDispatch();
  const moffAPIGlobalState = useSelector((state: AllState) => state.moffAPI);

  const [cachedUser, setcachedUser] = useState<User>({
    user_care_id: '',
    user_name: '',
    user_birth_year: '',
    user_gender: 1,
  } as User);
  const [cachedTraining, setCachedTraining] = useState<Training[]>([] as Training[]);

  useEffect(() => {
    (async () => {
      await dispatch(
        MoffAPIActions.getTrainingDetailData([
          null,
          userId,
          [userId, date.startOf('month').format('YYYY-MM-DD'), date.endOf('month').format('YYYY-MM-DD')],
        ]),
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, date]);

  useEffect(() => {
    if (moffAPIGlobalState.result.user) {
      setcachedUser(moffAPIGlobalState.result.user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffAPIGlobalState.Loaded, moffAPIGlobalState.result.user]);

  useEffect(() => {
    if (moffAPIGlobalState.result.filteredTraining) {
      setCachedTraining(moffAPIGlobalState.result.filteredTraining);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffAPIGlobalState.Loaded, moffAPIGlobalState.result.filteredTraining]);

  // 通常画面月矢印のアクション
  const minusMonthly = () => {
    const lastMonth = date.subtract(1, 'months');
    setDate(lastMonth);
    (async () => {
      await dispatch(
        MoffAPIActions.getTrainingDetailData([
          null,
          userId,
          [userId, lastMonth.startOf('month').format('YYYY-MM-DD'), lastMonth.endOf('month').format('YYYY-MM-DD')],
        ]),
      );
    })();
    dispatchIdentifierToGTM('detail_page_change_month_kiroku');
  };

  const plusMonthly = () => {
    const nextMonth = date.add(1, 'months');
    setDate(nextMonth);
    (async () => {
      await dispatch(
        MoffAPIActions.getTrainingDetailData([
          null,
          userId,
          [userId, nextMonth.startOf('month').format('YYYY-MM-DD'), nextMonth.endOf('month').format('YYYY-MM-DD')],
        ]),
      );
    })();
    dispatchIdentifierToGTM('detail_page_change_month_kiroku');
  };

  const deleteRecord = async (user_id: string, datetimeArr: string[]) => {
    await dispatch(MoffAPIActions.deleteFunctionalTrainingHistory(user_id, datetimeArr));
    // 削除の処理を待った後、typeを切り替え。削除後のトレーニング結果をリロードする
    await dispatch(
      MoffAPIActions.getTrainingDetailData([
        null,
        userId,
        [userId, date.startOf('month').format('YYYY-MM-DD'), date.endOf('month').format('YYYY-MM-DD')],
      ]),
    );
    dispatchIdentifierToGTM('detail_page_delete_kiroku');
  };

  return (
    <>
      {moffAPIGlobalState.Loaded && cachedUser.user_care_id !== '' && cachedUser.user_name !== '' ? null : (
        <PageLoader />
      )}
      <KirokuShow
        filteredTraining={cachedTraining}
        deleteRecord={deleteRecord}
        setScreenState={setScreenState}
        date={date}
        cachedUser={cachedUser}
        minusMonthly={minusMonthly}
        plusMonthly={plusMonthly}
      />
    </>
  );
};

export default KirokuShowContainer;
