// ユーザー詳細.
import React, { FC, useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

import store from '../store/configureStore';

import * as MoffAPIActions from '../actions/MoffAPI';
import * as HeaderActions from '../actions/Header';
import * as pdfCreateActions from '../actions/pdfCreate';
import * as reportActions from '../actions/Report';

import * as Header from '../constants/Header';
import * as UserListField from '../constants/UserListField';
import { MOFF_CHECK_COMPARE_LOGIC, MOFF_CHECK_V1_SELECT_LIST } from '../constants/MoffCheck';
import { MOFF_REPORT_MASTER } from '../constants/Report';

import { objectQuickSort, duplicateRepeatSort, dispatchIdentifierToGTM } from '../utils/commonUtil';
import { extractionChangedMemo } from '../utils/PressAllUsersPrintModule';

import PopupContainer from './Popup';

import PageLoader from '../components/Atoms/PageLoader';
import MoffCheckAllUsersPrint from '../components/Organisms/MoffCheckAllUsersPrint';

type AllState = ReturnType<typeof store.getState>;

const MoffCheckAllUsersPrintContainer: FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const moffCheckGlobalState = useSelector((state: AllState) => state.moffCheck);
  const moffAPIGlobalState = useSelector((state: AllState) => state.moffAPI);
  const pdfCreateGlobalState = useSelector((state: AllState) => state.pdfCreate);
  const reportGlobalState = useSelector((state: AllState) => state.report);

  const [date, setDate] = useState(moment());
  const [, setDateYYYYMM] = useState(moment().format('YYYY-MM'));
  const [cachedUsers, setCachedUsers] = useState<UserListField.User[]>([] as UserListField.User[]);
  const [toggle, setToggle] = useState(false);

  const [compareMonth, setCompareMonth] = useState<MOFF_CHECK_COMPARE_LOGIC>(MOFF_CHECK_COMPARE_LOGIC.default);
  const [multiSelectItems, setMultiSelectItems] = useState<string[]>(['CS-30']);
  const [multiSelectMonths, setMultiSelectMonths] = useState<string[]>([]);

  const refUsersList = useRef<any>();

  // 最初に一回ユーザー一覧取得のために通信.
  useEffect(() => {
    (async () => {
      document.title = 'モフトレチェック一括PDF出力';
      dispatch(HeaderActions.setPage(Header.MOFF_CHECK_ALL_USER_PRINT));
      await dispatch(MoffAPIActions.getUsers());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // モフトレチェック一括出力利用者リスト用データ取得後、利用者のデータをローカルステートにキャッシュさせておく
  useEffect(() => {
    // キャッシュさせておく.
    if (_.isEmpty(cachedUsers) && !_.isEmpty(moffAPIGlobalState.result ?? [])) {
      setCachedUsers(
        duplicateRepeatSort(UserListField.USERS_SORT_KEYS, moffAPIGlobalState.result ?? [], objectQuickSort),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moffAPIGlobalState.Loaded]);

  // 月選択画面 矢印のアクション
  const minusMonthly = () => {
    const lastMonth = date.subtract(1, 'months');
    setDate(lastMonth);
    setDateYYYYMM(lastMonth.format('YYYY-MM'));
    if (toggle) {
      dispatch(
        reportActions.getComments(MOFF_REPORT_MASTER.MoffCheckAllMeasurementReport, lastMonth.format('YYYY-MM')),
      );
    }
    dispatchIdentifierToGTM(`all_users_change_month_moffcheck`);
  };

  const plusMonthly = () => {
    const nextMonth = date.add(1, 'months');
    setDate(nextMonth);
    setDateYYYYMM(nextMonth.format('YYYY-MM'));
    if (toggle) {
      dispatch(
        reportActions.getComments(MOFF_REPORT_MASTER.MoffCheckAllMeasurementReport, nextMonth.format('YYYY-MM')),
      );
    }
    dispatchIdentifierToGTM(`all_users_change_month_moffcheck`);
  };

  // nextに切り替える際、この中のコードをAPIリクエストに変更
  const allUsersPrintButtonClickHandler = async () => {
    // チェックボックスがonのユーザーのみを取得
    const checkedUsers: UserListField.User[] = refUsersList.current.getCheckedUsers();
    if (checkedUsers.length === 0) {
      alert('ユーザーが選択されていません');
      return;
    }
    if (toggle === true) {
      const selectMonths = ([
        MOFF_CHECK_COMPARE_LOGIC.default,
        MOFF_CHECK_COMPARE_LOGIC.secondOption,
      ] as MOFF_CHECK_COMPARE_LOGIC[]).includes(compareMonth)
        ? [date.format('YYYY-MM')]
        : multiSelectMonths;
      const userIds = _.map(checkedUsers, (user) => user.user_id);
      const comments = _.map(checkedUsers, (user) => ({ user_id: user.user_id, text: user.memo }));
      await dispatch(pdfCreateActions.createMoffCheckV2PDF(compareMonth, selectMonths, userIds, comments, true));
      dispatchIdentifierToGTM(`output_all_users_report_moffcheck_all_${compareMonth}`);
    } else {
      const selectMonths = ([
        MOFF_CHECK_COMPARE_LOGIC.default,
        MOFF_CHECK_COMPARE_LOGIC.secondOption,
      ] as MOFF_CHECK_COMPARE_LOGIC[]).includes(compareMonth)
        ? [date.format('YYYY-MM')]
        : multiSelectMonths;
      const userIds = _.map(checkedUsers, (user) => user.user_id);
      const items = Object.keys(MOFF_CHECK_V1_SELECT_LIST).filter((key) =>
        multiSelectItems.includes(MOFF_CHECK_V1_SELECT_LIST[key]),
      );
      const walkingAbilityIndex = items.indexOf('walking_ability');
      const isWalkingAbility = walkingAbilityIndex !== -1 ? true : false;
      isWalkingAbility && items.splice(walkingAbilityIndex, 1);
      await dispatch(
        pdfCreateActions.createMoffCheckV1PDF(compareMonth, selectMonths, userIds, items, isWalkingAbility),
      );

      dispatchIdentifierToGTM(`output_all_users_report_moffcheck_walk_${compareMonth}`);
    }
  };

  const selectBoxChanged = (event: any) => {
    setCompareMonth(event.target.value);
  };

  const switchToggle = (switchedValue: boolean) => {
    setToggle(switchedValue);
    if (switchedValue) {
      dispatch(reportActions.getComments(MOFF_REPORT_MASTER.MoffCheckAllMeasurementReport, date.format('YYYY-MM')));
    }
  };

  const addOutputItems = (event: React.ChangeEvent<{ value: unknown }>) => {
    const items = event.target.value as string[];
    if (items.length <= 2) {
      setMultiSelectItems(items);
    }
  };

  const addOutputMonths = (event: React.ChangeEvent<{ value: unknown }>) => {
    const months = event.target.value as string[];
    if (months.length <= 3) {
      setMultiSelectMonths(months.sort((a, b) => moment(b).diff(a)));
    }
  };

  const deleteMonthSelect = (month: string) => {
    if (_.some(multiSelectMonths, (selectMonth: string) => selectMonth === month)) {
      setMultiSelectMonths(_.filter(multiSelectMonths, (selectMonth: string) => selectMonth !== month));
    }
  };

  // メモの内容を保存
  const saveAllUsersComment = async (checkDataArr: UserListField.CheckData[]) => {
    // 変更があったメモのみ取得
    const memoArr = extractionChangedMemo(checkDataArr, reportGlobalState.result?.comments?.data);
    if (memoArr) {
      if (window.confirm('メモを保存してよろしいですか？')) {
        await dispatch(
          reportActions.saveAllUsersComment(
            MOFF_REPORT_MASTER.MoffCheckAllMeasurementReport,
            date.format('YYYY-MM'),
            memoArr,
          ),
        );
      }
    } else {
      return;
    }
    dispatchIdentifierToGTM(`all_users_save_memo_moffcheck`);
  };

  /**
   * CSSの描画.
   */
  const RenderCSS: FC = () => {
    return (
      <style type="text/css">
        {/* 縦印刷, 日付・title名ヘッダの余白 */}
        {'@media print{@page {size: portrait; margin-top: 43px;}}'}
        {'@media print{#wrapper { padding:0; margin: 0; } header { display: none; }}'}
        {'@media print{.contentIn { padding-top:0!important; padding-bottom:0!important; }}'}
        {'@media print{.report { padding-bottom:0!important; }}'}
        {/* Edge対策 */}
        {'@media print{tr { page-break-inside: avoid; }}'}
      </style>
    );
  };

  return (
    <Fragment>
      <RenderCSS />
      <PopupContainer />
      {(!moffAPIGlobalState.Loaded && !cachedUsers) || pdfCreateGlobalState.Loading || reportGlobalState.Loading ? (
        <PageLoader />
      ) : null}
      <MoffCheckAllUsersPrint
        location={location}
        refUsersList={refUsersList}
        minusMonthly={minusMonthly}
        plusMonthly={plusMonthly}
        allUsersPrintButtonClickHandler={allUsersPrintButtonClickHandler}
        date={date}
        loaded={moffAPIGlobalState.Loaded || moffCheckGlobalState.Loaded}
        cachedUsers={cachedUsers}
        compareMonth={compareMonth}
        selectBoxChanged={selectBoxChanged}
        toggle={toggle}
        switchToggle={switchToggle}
        selectItems={multiSelectItems}
        addOutputItems={addOutputItems}
        selectMonths={multiSelectMonths}
        addOutputMonths={addOutputMonths}
        deleteMonthSelect={deleteMonthSelect}
        memos={reportGlobalState.result?.comments?.data ?? []}
        saveAllMemoValue={saveAllUsersComment}
      />
    </Fragment>
  );
};

export default MoffCheckAllUsersPrintContainer;
