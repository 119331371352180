// 各ブラウザの親クラス.
import React from 'react';
import * as Const from '../../../constants/InterestCheck';

// ロード時に使用
// 興味関心チェックシート.

interface RenderRowProps {
  leftItem: string;
  rightItem: string;
}

const RenderRow: React.FC<RenderRowProps> = ({ leftItem, rightItem }) => {
  return (
    <tr className="oneRow">
      <td className="leftTd">{leftItem}</td>
      <td className="middleTd"></td>
      <td className="middleTd"></td>
      <td className="middleTd"></td>
      <td className="leftTd">{rightItem}</td>
      <td className="middleTd"></td>
      <td className="middleTd"></td>
      <td className="rightTd"></td>
    </tr>
  );
};

/**
 * 1行表示(改行あり).
 * @method RenderRowWithBr
 * @param {String} leftItem 左の項目.
 * @param {String} rightItem1 右の項目改行まで.
 * @param {String} rightItem2 右の項目改行後.
 * @param {Check} leftCheckList 左の項目の丸かどうかリスト.
 * @param {Check} rightCheckList 右の項目の丸かどうかリスト.
 * @return {JSX.Element} JSX.
 */

interface RenderRowWithBrProps {
  leftItem: string;
  rightItem1: string;
  rightItem2: string;
}

const RenderRowWithBr: React.FC<RenderRowWithBrProps> = ({ leftItem, rightItem1, rightItem2 }) => {
  return (
    <tr className="oneRow">
      <td className="leftTd">{leftItem}</td>
      <td className="middleTd"></td>
      <td className="middleTd"></td>
      <td className="middleTd"></td>
      {/* フォント小さく */}
      <td className="leftTd smallFont">
        {rightItem1}
        <br />
        {rightItem2}
      </td>
      <td className="middleTd"></td>
      <td className="middleTd"></td>
      <td className="rightTd"></td>
    </tr>
  );
};

/**
 * 最後の1行表示.
 * @method RenderLastRow
 * @param {String} leftItem 左の項目.
 * @param {String} rightItem 右の項目.
 * @param {Check} leftCheckList 左の項目の丸かどうかリスト.
 * @param {Check} rightCheckList 右の項目の丸かどうかリスト.
 * @return {JSX.Element} JSX.
 */

interface RenderLastRowProps {
  leftItem: string;
  rightItem: string;
}

const RenderLastRow: React.FC<RenderLastRowProps> = ({ leftItem, rightItem }) => {
  return (
    <tr className="oneRow">
      <td className="bottomLeftTd">{leftItem}</td>
      <td className="bottomMiddleTd"></td>
      <td className="bottomMiddleTd"></td>
      <td className="bottomMiddleTd"></td>
      <td className="bottomLeftTd">{rightItem}</td>
      <td className="bottomMiddleTd"></td>
      <td className="bottomMiddleTd"></td>
      <td className="bottomRightTd"></td>
    </tr>
  );
};

const BaseBrowser = () => {
  return (
    <>
      <br id="blank" key="interest_check_print1" />
      <div key="interest_check_print2" className="tableTitle interest_check_table">
        興味関心チェックシート
      </div>
      <div key="interest_check_print3" className="hearing interest_check_table">
        聞き取り日：
      </div>
      <table key="interest_check_print4" className="interest_check_table">
        <tbody>
          <tr className="oneRow">
            <td className="nameTd">氏名：</td>
            <td className="birthTd">生年：年</td>
            {/* 微妙に右にずらすためにスペースが入っている */}
            {/* TODO: あとで歳を戻すためコメントアウト */}
            {/* <td className="ageTd"> 歳</td> */}
            <td className="genderTd">性別：</td>
          </tr>
        </tbody>
      </table>
      <br key="interest_check_print5" />
      <table key="interest_check_print6" className="interest_check_table">
        <tbody>
          <tr className="oneRow">
            <td className="topLeftTd">生活行為</td>
            <td className="topMiddleTd smallFont">している</td>
            <td className="topMiddleTd smallFont">してみたい</td>
            <td className="topMiddleTd smallFont">興味がある</td>
            <td className="topLeftTd">生活行為</td>
            <td className="topMiddleTd smallFont">している</td>
            <td className="topMiddleTd smallFont">してみたい</td>
            <td className="topRightTd smallFont">興味がある</td>
          </tr>
          <RenderRow leftItem={Const.TOILET} rightItem={Const.LIFELONG_LEARNING} />
          <RenderRow leftItem={Const.BATHING} rightItem={Const.READING} />
          <RenderRow leftItem={Const.WEARING} rightItem={Const.HAIKU} />
          <RenderRow leftItem={Const.EATING} rightItem={Const.SHODO} />
          <RenderRow leftItem={Const.BRUSHING} rightItem={Const.PAINTING} />
          <RenderRow leftItem={Const.GROOMING} rightItem={Const.TYPING} />
          <RenderRow leftItem={Const.SLEEPING} rightItem={Const.PHOTO} />
          <RenderRow leftItem={Const.CLEANING} rightItem={Const.THEATER} />
          <RenderRow leftItem={Const.COOKING} rightItem={Const.SADO_KADO} />
          <RenderRow leftItem={Const.SHOPPING} rightItem={Const.SINGING} />
          <RenderRow leftItem={Const.GARDENING} rightItem={Const.MUSIC} />
          <RenderRow leftItem={Const.LAUNDERING} rightItem={Const.GAMING} />
          <RenderRow leftItem={Const.DRIVING} rightItem={Const.EXERCISING} />
          <RenderRow leftItem={Const.OUTING} rightItem={Const.STROLLING} />

          <RenderRowWithBr
            leftItem={Const.CHILDREN}
            rightItem1={Const.PLAYING_SPORTS1}
            rightItem2={Const.PLAYING_SPORTS2}
          />
          <RenderRow leftItem={Const.ANIMAL} rightItem={Const.DANCING} />
          <RenderRow leftItem={Const.FRIENDS} rightItem={Const.WATCHING_SPORTS} />
          <RenderRow leftItem={Const.FAMILY} rightItem={Const.GAMBLING} />
          <RenderRow leftItem={Const.DATING} rightItem={Const.KNITTING} />
          <RenderRow leftItem={Const.DRINKING} rightItem={Const.SEWING} />
          <RenderRow leftItem={Const.VOLUNTEER} rightItem={Const.CROPPING} />
          <RenderRow leftItem={Const.COMMUNITY_ACTIVITY} rightItem={Const.EARNING} />
          <RenderRow leftItem={Const.RELIGION} rightItem={Const.TRAVELING} />
          <RenderRow leftItem="その他( )" rightItem="その他( )" />

          <RenderLastRow leftItem="その他( )" rightItem="その他( )" />
        </tbody>
      </table>
    </>
  );
};

export default BaseBrowser;
