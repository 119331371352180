// ブラウザのJSXの親クラス.
import React, { FC } from 'react';
import { User, Male, Female } from '../../../constants/UserListField';
import { ResponseData } from '../../../constants/HomeVisit';
import * as HomeVisit from '../../../constants/HomeVisit';
import { convertHomeVisitDate } from '../../../utils/dateUtil';
import { CARE_LEVELS } from '../../../constants/Training';
import Enumerable from 'linq';

const RenderCSS = () => {
  return (
    <style type="text/css">
      {/* 縦印刷, 日付・title名は消す */}
      {'@media print{@page {size: A4; margin: 0; ' +
        'margin-top: 4mm; margin-left: 1cm; margin-right: 1cm; margin-bottom: 0mm; } ' +
        '#wrapper { margin-top: 0; } header { display: none; } ' +
        'body { padding: 8; } ' +
        '#homevisit { padding-left: 0; padding-right: 0; } ' +
        '#sec01 { display: none; }}'}
      {'table, th, td { border: 1px solid #111; vertical-align: middle; text-align: center }'}
      {'table { width: 774px; }'}
      {'th { background-color: #F2F2F2; font-weight: normal; }'}
      {'.thRow { height: 26px; }'}
      {'.tdRow { height: 54px; }'}
      {'.tableContainer { width: 774px; margin: 0 auto; line-height: 1; }'}
      {'.tableTitle { padding: 10px 0; font-weight: bold; text-align: center; }'}
      {'.lastTable { margin-bottom: 19px; }'}
      {'.env { text-align: left; }'}
      {'.cell1 { width: 160px; }'}
      {'.cell2 { width: 91px; }'}
      {'.cell3 { width: 136px; }'}
      {'.cell4 { width: 68px; }'}
      {'.cell5 { width: 430px; }'}
      {'.cell6 { width: 91px; }'}
      {'.sectionCell1 { width: 91px; }'}
      {'.sectionCell2 { width: 182px; }'}
      {'.sectionCell3 { width: 45px; }'}
      {'.sectionCell4 { width: 364px; }'}
      {'.topBorderNone { border-top: none; }'}
      {'.detailText { text-align: left; vertical-align: top; white-space: pre-wrap; }'}
      {/* レベル */}
      {'.levelTable { display: table; width: 100%; height: 50%; }'}
      {/* レベル中身 */}
      {'.levelItem { display: table-cell; table-layout: fixed; width: 50%; height: 100%; ' +
        'border-width: 0px 0px 0px 1px; text-align: left; vertical-align: middle; padding: 5px 0; }'}
    </style>
  );
};

/**
 * 上段用(ADL/IADL)の各項目のJSXの描画.
 * @method renderTopSection
 * @param {Boolean} isBottom 最終行(bottomCellが入るため).
 * @param {String} itemName 項目タイトル名.
 * @param {HomeVisit.Multiple} multiple 連想配列.
 * @return {JSX.Element[]} JSXの配列.
 */
interface RenderTopSectionProps {
  multiple: HomeVisit.Multiple;
}

const RenderTopSection: FC<RenderTopSectionProps> = ({ multiple }) => {
  // multipleを分離.
  // レベルの内容の配列(自立=0、見守り=1、一部介助=2、全介助=3).
  let levels: number[] = [];
  // 課題の有無.
  let isProblem = false;

  // 環境(実施場所、補助具など)(falseなら下段なので非表示).
  let env: boolean | string = false;

  if (multiple) {
    levels = multiple ? multiple.level : [];
    isProblem = multiple ? multiple.is_problem : false;
    env = multiple.env === undefined ? false : multiple.env;
  }

  // number[] -> boolean[]
  const levelsBools: boolean[] = Enumerable.range(0, 4)
    .select((i: number) => {
      return levels.indexOf(i) > -1;
    })
    .toArray();

  const check = (isCheck: boolean): string => {
    return isCheck ? '■' : '□';
  };

  const problem: string = isProblem ? '有' : '無';

  return (
    <React.Fragment>
      <td>
        <div className="levelTable">
          <div className="levelItem" data-test="level-item">
            {check(levelsBools[0])} 自立
          </div>
          <div className="levelItem" data-test="level-item">
            {check(levelsBools[1])} 見守り
          </div>
        </div>
        <div className="levelTable">
          <div className="levelItem" data-test="level-item">
            {check(levelsBools[2])} 一部介助
          </div>
          <div className="levelItem" data-test="level-item">
            {check(levelsBools[3])} 全介助
          </div>
        </div>
      </td>
      <td data-test="problem">{problem}</td>
      <td className="env" data-test="env">
        {env}
      </td>
    </React.Fragment>
  );
};

/**
 * 下段用(起居動作)各項目のJSXの描画.
 * @method renderBottomSection
 * @param {String} itemName 項目タイトル名.
 * @param {HomeVisit.Multiple} multiple 連想配列.
 * @return {JSX.Element[]} JSXの配列.
 */
interface RenderBottomSectionProps {
  multiple: HomeVisit.Multiple;
}

const RenderBottomSection: FC<RenderBottomSectionProps> = ({ multiple }) => {
  // multipleを分離.
  // レベルの内容の配列(自立=0、見守り=1、一部介助=2、全介助=3).
  let levels: number[] = [];
  // 課題の有無.
  let isProblem = false;

  if (multiple) {
    levels = multiple ? multiple.level : [];
    isProblem = multiple ? multiple.is_problem : false;
  }

  // number[] -> boolean[]
  const levelsBools: boolean[] = Enumerable.range(0, 4)
    .select((i: number) => {
      return levels.indexOf(i) > -1;
    })
    .toArray();

  const check = (isCheck: boolean): string => {
    return isCheck ? '■' : '□';
  };

  const problem: string = isProblem ? '有' : '無';

  return (
    <React.Fragment>
      <td>
        <div className="levelTable">
          <div className="levelItem" data-test="kikyo-level-item">
            {check(levelsBools[0])} 自立
          </div>
          <div className="levelItem" data-test="kikyo-level-item">
            {check(levelsBools[1])} 見守り
          </div>
        </div>
        <div className="levelTable">
          <div className="levelItem" data-test="kikyo-level-item">
            {check(levelsBools[2])} 一部介助
          </div>
          <div className="levelItem" data-test="kikyo-level-item">
            {check(levelsBools[3])} 全介助
          </div>
        </div>
      </td>
      <td data-test="kikyo-problem">{problem}</td>
    </React.Fragment>
  );
};

interface BaseBrowserProps {
  user: User;
  homeVisit: ResponseData;
}

const BaseBrowser: FC<BaseBrowserProps> = ({ user, homeVisit }) => {
  let visitDate;
  let careLevel;

  // 利用者氏名.
  if (user) {
    // 要介護度.
    careLevel = CARE_LEVELS[user.user_care_level];
  }

  if (homeVisit) {
    // 訪問日.
    visitDate = homeVisit.visited_day ? convertHomeVisitDate(homeVisit.visited_day) : '';
  }

  return (
    <React.Fragment>
      <div className="tableContainer">
        <div className="tableTitle">居宅訪問チェックシート</div>
        {/* <!-- 利用者情報 --> */}
        <table>
          <thead>
            <tr className="thRow">
              <th className="cell1">利用者氏名</th>
              <td className="cell1" data-test="name">
                {user.user_name}
              </td>
              <th className="cell2">生年</th>
              <td data-test="birth-year">{user.user_birth_year}年</td>
              <th className="cell2">性別</th>
              <td className="cell2" data-test="gender">
                {user.user_gender === 0 ? Male : Female}
              </td>
            </tr>
          </thead>
        </table>
        {/* <!-- 2行目 --> */}
        <table className="topBorderNone">
          <thead>
            <tr className="thRow topBorderNone">
              <th className="cell1 topBorderNone">訪問日</th>
              <td className="cell5 topBorderNone" data-test="visit-date">
                {visitDate}
              </td>
              <th className="cell2 topBorderNone">要介護度</th>
              <td className="cell2 topBorderNone" data-test="care-level">
                {careLevel}
              </td>
            </tr>
          </thead>
        </table>
        {/* <!-- 3行目 --> */}
        <table className="lastTable topBorderNone">
          <thead>
            <tr className="thRow topBorderNone">
              <th className="cell1 topBorderNone">訪問スタッフ</th>
              <td className="cell1 topBorderNone" data-test="staff">
                {homeVisit.staff}
              </td>
              <th className="cell2 topBorderNone">職種</th>
              <td className="topBorderNone" data-test="profession">
                {homeVisit.profession}
              </td>
              <th className="cell2 topBorderNone">被聞き取り者</th>
              <td className="cell2 topBorderNone" data-test="interviewee">
                {homeVisit.interviewee}
              </td>
            </tr>
          </thead>
        </table>
        {/* <!-- 内容タイトル --> */}
        <table>
          <thead>
            <tr className="thRow">
              <th className="sectionCell1">{/* <!-- 空白 --> */}</th>
              <th className="sectionCell1">項目</th>
              <th className="sectionCell2">レベル</th>
              <th className="sectionCell3">課題</th>
              <th className="sectionCell2">環境(実施場所、補助具など)</th>
              <th className="sectionCell2">状況・生活課題</th>
            </tr>
          </thead>
          {/* <!-- ADL --> */}
          {/* <!-- 各項目 --> */}
          <tbody>
            <tr className="tdRow">
              <td rowSpan={6}>ADL</td>
              <td>食事</td>
              <RenderTopSection multiple={homeVisit.adl_feed} />
              <td rowSpan={6} className="detailText" data-test="detail-text">
                {/* <!-- 状況・生活課題 --> */}
                {homeVisit.adl_detail}
              </td>
            </tr>
            <tr className="tdRow">
              <td>排尿</td>
              <RenderTopSection multiple={homeVisit.adl_ur} />
            </tr>
            <tr className="tdRow">
              <td>入浴</td>
              <RenderTopSection multiple={homeVisit.adl_bath} />
            </tr>
            <tr className="tdRow">
              <td>更衣</td>
              <RenderTopSection multiple={homeVisit.adl_dress} />
            </tr>
            <tr className="tdRow">
              <td>整容</td>
              <RenderTopSection multiple={homeVisit.adl_cosme} />
            </tr>
            <tr className="tdRow">
              <td>移乗</td>
              <RenderTopSection multiple={homeVisit.adl_transfer} />
            </tr>
          </tbody>
          {/* <!-- IADL --> */}
          <tbody>
            <tr className="tdRow">
              <td rowSpan={6}>IADL</td>
              <td>屋内移動</td>
              <RenderTopSection multiple={homeVisit.iadl_indoor} />
              <td rowSpan={6} className="detailText" data-test="detail-text">
                {/* <!-- 状況・生活課題 --> */}
                {homeVisit.iadl_detail}
              </td>
            </tr>
            <tr className="tdRow">
              <td>屋外移動</td>
              <RenderTopSection multiple={homeVisit.iadl_outdoor} />
            </tr>
            <tr className="tdRow">
              <td>階段昇降</td>
              <RenderTopSection multiple={homeVisit.iadl_lift} />
            </tr>
            <tr className="tdRow">
              <td>調理</td>
              <RenderTopSection multiple={homeVisit.iadl_cook} />
            </tr>
            <tr className="tdRow">
              <td>洗濯</td>
              <RenderTopSection multiple={homeVisit.iadl_wash} />
            </tr>
            <tr className="tdRow">
              <td>掃除</td>
              <RenderTopSection multiple={homeVisit.iadl_clean} />
            </tr>
          </tbody>
        </table>
        {/* <!-- 起居動作・タイトル --> */}
        <table className="topBorderNone">
          <thead>
            <tr className="thRow topBorderNone">
              <th className="sectionCell1 topBorderNone">{/* <!-- 空白 --> */}</th>
              <th className="sectionCell1 topBorderNone">項目</th>
              <th className="sectionCell2 topBorderNone">レベル</th>
              <th className="sectionCell3 topBorderNone">課題</th>
              <th className="sectionCell4 topBorderNone">状況・生活課題</th>
            </tr>
          </thead>
          {/* <!-- 起居動作 --> */}
          <tbody>
            <tr className="tdRow">
              <td rowSpan={4}>起居動作</td>
              <td>起き上がり</td>
              <RenderBottomSection multiple={homeVisit.behaivior_arise} />
              <td rowSpan={4} className="detailText" data-test="detail-text">
                {/* <!-- 状況・生活課題 --> */}
                {homeVisit.behaivior_detail}
              </td>
            </tr>
            <tr className="tdRow">
              <td>座位</td>
              <RenderBottomSection multiple={homeVisit.behaivior_sitting} />
            </tr>
            <tr className="tdRow">
              <td>立ち上がり</td>
              <RenderBottomSection multiple={homeVisit.behaivior_standup} />
            </tr>
            <tr className="tdRow">
              <td>立位</td>
              <RenderBottomSection multiple={homeVisit.behaivior_upright} />
            </tr>
          </tbody>
        </table>
      </div>
      <RenderCSS />
    </React.Fragment>
  );
};

export default BaseBrowser;
