// Chrome用.
import React, { FC } from 'react';
import BaseBrowser from './BaseBrowser';
import { User } from '../../../constants/UserListField';
import { ResponseData } from '../../../constants/LifeCheck';

const RenderCSS = () => {
  return <style type="text/css">{'table, th, td { border: 2px solid #111 }'}</style>;
};

interface ChromeProps {
  user: User;
  lifeCheck: ResponseData;
}

// Chrome.
const Chrome: FC<ChromeProps> = ({ user, lifeCheck }) => {
  return (
    <>
      <BaseBrowser user={user} lifeCheck={lifeCheck} />
      <RenderCSS />
    </>
  );
};

export default Chrome;
