// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-ignore
// @ts-ignore
import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import GeneralPrimaryGhostButton from '../Atoms/GeneralPrimaryGhostButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// import _ from 'lodash';
// import { MENU_TYPE } from '../../constants/Menu';
import * as UserListField from '../../constants/UserListField';
import * as MoffRaku from '../../constants/MoffRaku';

const useStyles = makeStyles((theme) => ({
  table: {
    display: 'table',
    width: '100%',
    margin: '0 0 80px 0',
    overflow: 'hidden',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    borderRadius: '10px',
    boxShadow: '0 2px 3px 1px #ccc',
    border: '1px',
  },
  tableHeader: {
    padding: '16px 18px',
    fontSize: '14px',
    color: '#fff',
    backgroundColor: '#7c644e',
    textAlign: 'left',
    overflowWrap: 'break-word',
  },
  tableRow: {
    [theme.breakpoints.up('sm')]: {
      '& th, & td': {
        maxWidth: '150px',
        padding: '20px 20px',
        textAlign: 'left',
        fontWeight: 'normal',
        lineHeight: '1.3em',
        overflowWrap: 'break-word',
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& th, & td': {
        padding: '20px 7px',
        textAlign: 'left',
        fontWeight: 'normal',
        fontSize: '0.8em',
        overflowWrap: 'break-word',
        verticalAlign: 'middle',
        lineHeight: '1.3em',
      },
      '& th': {
        maxWidth: '105px',
      },
      '& td': {
        maxWidth: '15px',
        '&:first-of-type': {
          maxWidth: '70px',
        },
        '&:nth-of-type(2)': {
          maxWidth: '20px',
          textAlign: 'center',
        },
        '&:nth-of-type(3)': {
          maxWidth: '10px',
          textAlign: 'center',
        },
      },
    },
  },
  report: {
    paddingBottom: '50px',
    background: '#fff',
    borderRadius: '10px',
    boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
  },
  hideable: {
    padding: '25px 30px 25px 20px',
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#7c644e',
    borderRadius: '10px 10px 0 0',
  },
}));

const dispCreatedAt = (createdAt: string) => {
  const dispStr = createdAt.substr(0, 10);
  return dispStr;
};

const createNewPlan = (userId: string) => {
  // window.location.href = `${urlBase}/${userId}`;
  window.location.href = `/moff_raku_form/${userId}`;
};

const handleFieldClicked = (userId: string, planId: string) => {
  // 詳細画面への導線.
  window.location.href = `/moff_raku_edit/${userId}/${planId}`;
  // dispatchIdentifierToGTM('user_record');
};

interface MoffRakuListProps {
  userId: string;
  user: UserListField.User;
  plans: MoffRaku.PlanFormat[];
}

const MoffRakuList: FC<MoffRakuListProps> = ({ userId, user, plans }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
  };
  return (
    <Box component="article" pb="50px" className={classes.report}>
      <Box className={classes.hideable}>{user.user_name} 様 の【個別機能訓練計画書】一覧</Box>
      <Box component="section">
        <Box mx={{ xs: 2, sm: 5 }} my={5}>
          <Grid container spacing={matches.xs ? 2 : 3}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                <GeneralPrimaryGhostButton
                  onClick={() => createNewPlan(userId)}
                  innerText={'新しく作成する'}
                  setCss={'width: 120px; height: 40px;'}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              作成した計画書を見るには、下記の一覧から、作成日・更新日の行を選択してください。
            </Grid>
          </Grid>
        </Box>

        <Box>
          <table className={classes.table}>
            <thead>
              <tr>
                <th className={classes.tableHeader} scope="col">
                  作成日
                </th>
                <th className={classes.tableHeader} scope="col">
                  更新日
                </th>
              </tr>
            </thead>
            <tbody>
              {typeof plans === 'undefined' ? (
                <tr>
                  <th scope="row">Error.</th>
                </tr>
              ) : null}
              {plans.map((plan) => {
                return (
                  <tr
                    style={{ cursor: 'pointer', fontSize: '20px' }}
                    key={plan.plan_id}
                    onClick={() => handleFieldClicked(plan.user_id, plan.plan_id)}
                    className={classes.tableRow}
                  >
                    <th className="user" hidden={true}></th>
                    <th scope="row">{dispCreatedAt(plan.created_at)}</th>
                    <th scope="row">{plan.updated_at}</th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

export default MoffRakuList;
