// 個別機能訓練に関する記録・詳細(Show).
import React, { FC, useState, useEffect } from 'react';
import * as KirokuConst from '../../constants/Kiroku';
import { SCREEN_TYPE } from '../../constants/Training';
import { User } from '../../constants/UserListField';
import { convertTime, convertDay } from '../../utils/dateUtil';
import { Global } from '../../utils/global';
import { dispatchIdentifierToGTM } from '../../utils/commonUtil';

import KirokuCommon from '../Molecules/KirokuCommon';
import DialogAlert from '../Molecules/DialogAlert';

import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import GeneralPrimaryCheckBox from '../Atoms/GeneralPrimaryCheckBox';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteIcon from '@material-ui/icons/Delete';

import { Box, DialogContentText, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  sec: {
    marginTop: 0,
  },
  noDataWord: {
    display: 'inline-block',
    margin: '0 0 0 25px',
  },
  tblStyle00: {
    [theme.breakpoints.down('xs')]: {
      width: '300px',
      margin: '0 auto',
      fontSize: '0.7em',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 50px)',
      margin: '0 25px',
      fontSize: '1em',
    },
    '@media print': {
      width: '100%',
      fontSize: '1em',
    },
    /* Edgeの印刷時に線が出る対策 */
    '@supports (-ms-ime-align: auto)': {
      border: 'solid 2px #999',
    },
    maxWidth: '939px',
    border: 'solid 1px #999',
    '& thead': {
      '& th': {
        padding: '3px 5px',
        fontWeight: 'normal',
        textAlign: 'center',
        background: '#e8e4df',
        lineHeight: '1.1em',
        verticalAlign: 'middle',
        border: 'solid 1px #999',
      },
      '& th:nth-of-type(1)': {
        width: '40px',
        '@media print': {
          display: 'none',
        },
        textAlign: 'center',
        padding: '6px 5px 0px 5px',
      },
      '& th:nth-of-type(7)': {
        width: '40px',
        '@media print': {
          display: 'none',
        },
      },
    },
    '& tbody': {
      '& td': {
        padding: '3px',
        verticalAlign: 'middle',
        textAlign: 'left',
        border: 'solid 1px #999',
      },
      '& td:nth-of-type(1)': {
        textAlign: 'center',
        '@media print': {
          display: 'none',
        },
      },
      '& td:nth-of-type(2)': {
        textAlign: 'center',
      },
      '& td:nth-of-type(3)': {
        textAlign: 'center',
      },
      '& td:nth-of-type(4)': {
        fontSize: '90%',
        textAlign: 'center',
        minWidth: '40px',
      },
      '& td:nth-of-type(5)': {
        fontSize: '90%',
        textAlign: 'center',
      },
      '& td:nth-of-type(6)': {
        fontSize: '90%',
        textAlign: 'center',
        minWidth: '40px',
      },
      '& td:nth-of-type(7)': {
        minWidth: '50px',
        '@media print': {
          display: 'none',
        },
      },
    },
  },
  isPrintRow: {
    '@media print': {
      display: 'table-row',
    },
  },
  isNotPrintRow: {
    '@media print': {
      display: 'none',
    },
  },
  selectedDeleteButton: {
    color: theme.palette.grey[600],
  },
  notSelectedDeleteButton: {
    color: theme.palette.grey[400],
  },
  alertMessage: {
    color: theme.palette.error.main,
    fontWeight: 600,
  },
}));

interface KirokuShowProps {
  filteredTraining: KirokuConst.Training[];
  deleteRecord: (user_id: string, datetimeArr: string[]) => Promise<void>;
  setScreenState: (screenType: SCREEN_TYPE) => void;
  date: moment.Moment;
  cachedUser: User;
  minusMonthly: () => void;
  plusMonthly: () => void;
}

// 詳細本体.
const KirokuShow: FC<KirokuShowProps> = ({
  filteredTraining,
  deleteRecord,
  setScreenState,
  date,
  cachedUser,
  minusMonthly,
  plusMonthly,
}) => {
  const classes = useStyles();
  const [printRecords, setPrintRecords] = useState<KirokuConst.CheckData[]>([] as KirokuConst.CheckData[]);
  const [deleteRecords, setDeleteRecords] = useState<KirokuConst.DeleteRecord[]>([] as KirokuConst.DeleteRecord[]);
  const [allDeleteRecords, setAllDeleteRecords] = useState({ selected: false });
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // stateの準備.
    const initialPrintRecords: KirokuConst.CheckData[] = filteredTraining.map(
      (training: KirokuConst.Training, index: number) => {
        // ついでに日付をコンバート.
        let theday = convertTime(training.datetime).day;
        theday = convertDay(theday);
        training.day = theday;

        return {
          id: index,
          selected: true,
        };
      },
    );
    setPrintRecords(initialPrintRecords);

    const initialDeleteRecords = filteredTraining.map((training: KirokuConst.Training, index: number) => ({
      id: index,
      selected: false,
      datetime: training.datetime,
    }));
    setDeleteRecords(initialDeleteRecords);
  }, [filteredTraining]);

  // チェックボックスの状況変更.
  const changeSelection = (id: number) => {
    const nextState = printRecords.map((checkData: KirokuConst.CheckData) => {
      return {
        id: checkData.id,
        selected: checkData.id === id ? !checkData.selected : checkData.selected,
      };
    });
    setPrintRecords(nextState);
  };

  // 削除対象の状況変更.
  const changeDeleteRecords = (id: number) => {
    const nextState = deleteRecords.map((deleteRecord: KirokuConst.DeleteRecord) => {
      return {
        ...deleteRecord,
        selected: deleteRecord.id === id ? !deleteRecord.selected : deleteRecord.selected,
      };
    });
    setDeleteRecords(nextState);
  };

  // 削除対象の状況変更.
  const changeAllDeleteRecords = () => {
    const nextState = deleteRecords.map((deleteRecord: KirokuConst.DeleteRecord) => {
      return {
        ...deleteRecord,
        selected: !allDeleteRecords.selected,
      };
    });
    setDeleteRecords(nextState);
    setAllDeleteRecords({ selected: !allDeleteRecords.selected });
  };

  let dataNullFlg = 0;
  if (filteredTraining) {
    dataNullFlg = filteredTraining.length;
  }

  return (
    <>
      <KirokuCommon date={date} cachedUser={cachedUser} minusMonthly={minusMonthly} plusMonthly={plusMonthly} />
      <section className={classes.sec}>
        {dataNullFlg === 0 ? (
          <>
            <Box>
              <p className={classes.noDataWord}>この月はデータがありません。</p>
              <Box display="flex" justifyContent="center" mt="50px">
                <GeneralPrimaryButton
                  txt="編集"
                  setCss={'width: 158px; height: 50px;'}
                  onClick={() => {
                    setScreenState(SCREEN_TYPE.KirokuEdit);
                    dispatchIdentifierToGTM('detail_page_edit_kiroku');
                  }}
                />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <table className={classes.tblStyle00}>
                <thead>
                  <tr>
                    {Global.isApp ? (
                      <th scope="col" />
                    ) : (
                      <th scope="col">
                        印刷
                        <br />
                        対象
                      </th>
                    )}
                    <th scope="col">実施日時</th>
                    <th scope="col">実施時間</th>
                    <th scope="col">訓練内容</th>
                    <th scope="col">
                      機能訓練
                      <br />
                      指導員
                    </th>
                    <th scope="col">特記事項</th>
                    <th scope="col">
                      削除
                      <br />
                      対象
                      <br />
                      選択
                      <br />
                      <Box mt="5px">
                        <GeneralPrimaryButton
                          txt="全て"
                          setCss={'width: 58px; height: 30px; font-size: 0.8em !important;'}
                          onClick={() => changeAllDeleteRecords()}
                          isDisable={!allDeleteRecords.selected}
                        />
                      </Box>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {filteredTraining.map((training: KirokuConst.Training, index: number) => {
                    return (
                      <tr
                        key={index}
                        className={printRecords[index]?.selected ? classes.isPrintRow : classes.isNotPrintRow}
                      >
                        {Global.isApp ? (
                          <td />
                        ) : (
                          <td>
                            <GeneralPrimaryCheckBox
                              onChange={() => changeSelection(index)}
                              checked={printRecords[index]?.selected ?? true}
                            />
                          </td>
                        )}
                        <td>{training.day}</td>
                        <td>{training.duration}</td>
                        <td>{training.subject}</td>
                        <td>{training.instructor}</td>
                        <td>{training.remarks}</td>
                        <td>
                          <Box display="flex" justifyContent="center">
                            <IconButton
                              size="medium"
                              onClick={() => changeDeleteRecords(index)}
                              data-test="delete-button"
                            >
                              {deleteRecords[index]?.selected ? (
                                <DeleteIcon fontSize="large" className={classes.selectedDeleteButton} />
                              ) : (
                                <DeleteOutlineIcon fontSize="large" className={classes.notSelectedDeleteButton} />
                              )}
                            </IconButton>
                          </Box>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {deleteRecords.some((deleteRecord) => deleteRecord.selected === true) && (
                <Box display="flex" justifyContent="flex-end" mt="10px" mx="25px">
                  <GeneralPrimaryButton
                    txt="一括削除"
                    setCss={'width: 158px; height: 50px;'}
                    onClick={() => setOpen(true)}
                  />
                  <DialogAlert
                    dialogOpen={open}
                    setDialogOpen={handleClose}
                    dialogTitle="選択されているデータを全て削除します。"
                    dialogContent={
                      <DialogContentText className={classes.alertMessage}>
                        削除したデータは２度と復元できません。
                      </DialogContentText>
                    }
                    leftButton={
                      <GeneralPrimaryButton
                        onClick={handleClose}
                        txt="キャンセル"
                        setCss="width: 150px; font-size: 0.9em !important; padding: 5px 15px; margin: 5px;"
                        isDisable={true}
                      />
                    }
                    rightButton={
                      <GeneralPrimaryButton
                        onClick={() => {
                          handleClose();
                          const datetimeArr = deleteRecords
                            .filter((deleteRecord) => deleteRecord.selected === true)
                            .map((datetime) => datetime.datetime);
                          deleteRecord(cachedUser.user_id, datetimeArr);
                        }}
                        txt="一括削除実行"
                        setCss="width: 150px; font-size: 0.9em !important; padding: 5px 15px; margin: 5px;"
                      />
                    }
                  />
                </Box>
              )}
              <Box display="flex" justifyContent="center" mt="50px">
                <GeneralPrimaryButton
                  txt="編集"
                  setCss={'width: 158px; height: 50px;'}
                  onClick={() => {
                    setScreenState(SCREEN_TYPE.KirokuEdit);
                    dispatchIdentifierToGTM('detail_page_edit_kiroku');
                  }}
                />
              </Box>
            </Box>
          </>
        )}
      </section>
    </>
  );
};

export default KirokuShow;
