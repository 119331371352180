/* 興味関心チェック・印刷 */
import React, { FC, useState, useEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import store from '../store/configureStore';

import * as InterestCheckActions from '../actions/InterestCheck';
import * as HeaderActions from '../actions/Header';
import * as FooterActions from '../actions/Footer';
import * as Header from '../constants/Header';
import { User } from '../constants/UserListField';
import { ResponseData } from '../constants/InterestCheck';

import PageLoader from '../components/Atoms/PageLoader';
import BrowserHandler from '../components/Molecules/InterestCheckPrintBrowsers/BrowserHandler';
import PageTransition from '../components/Molecules/PageTransition';

import { dispatchIdentifierToGTM } from '../utils/commonUtil';
import { CHECK_SHEET_TYPES } from '../constants/Report';
import { Box } from '@material-ui/core';

type AllState = ReturnType<typeof store.getState>;

const mapStateToProps = (state: AllState) => ({
  loaded: state.moffAPI.Loaded,
  user: state.moffAPI.result.user,
  interestCheck: state.moffAPI.result.interestCheck,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInterestCheckRecord: (userId: string, uniqueId: string, sequence: number) =>
        InterestCheckActions.getInterestCheckRecordBySequence(userId, uniqueId, sequence),
      display: (param: string) => FooterActions.display(param),
      setPage: (page: string) => HeaderActions.setPage(page),
    },
    dispatch,
  );

const RenderCSS: FC = () => {
  return (
    <>
      <style type="text/css">
        {/* 表の調整 */}
        {'body,div,table,thead,tbody,tfoot,tr,th,td,p { font-size:small }'}

        {/* 上の影響を吸収 */}
        {'h1, .prev { font-size:120%; line-height: 1.75;}'}

        {/* Chromeでのtrの背景表示のため */}
        {'body { -webkit-print-color-adjust: exact; }'}
        {'a.comment-indicator:hover + comment { background:#ffd; ' +
          'position:absolute; display:block; border:1px solid black; padding:0.5em; }'}
        {'a.comment-indicator { background:red; display:inline-block; ' +
          'border:1px solid black; width:0.5em; height:0.5em; }'}
        {'comment { display:none; } '}
      </style>
    </>
  );
};

interface InterestCheckPrintContainerProps
  extends RouteComponentProps<{ total: string; userId: string; uniqueId: string }> {
  loaded: boolean;
  getInterestCheckRecord: (userId: string, uniqueId: string, sequence: number) => void;
  setPage: (page: string) => void;
  display: (param: string) => void;
  user: User;
  interestCheck: ResponseData;
}

const InterestCheckPrintContainer: FC<InterestCheckPrintContainerProps> = ({
  loaded,
  getInterestCheckRecord,
  setPage,
  display,
  match,
  user,
  interestCheck,
}) => {
  // URLのパラメータ.
  const total = Number(match.params.total);
  const userId = match.params.userId;
  const uniqueId = match.params.uniqueId;

  // 記録番号.
  const [currnetPage, setCurrentPage] = useState(total);

  // ヘッダ情報の設定。興味関心のデータを取得。
  useEffect(() => {
    (async () => {
      document.title = '興味関心チェックシート';
      setPage(Header.INTEREST_CHECK_PRINT);
      display('hidden');
      await getInterestCheckRecord(userId, uniqueId, currnetPage);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pushLeft = async () => {
    const currnetSequence = currnetPage - 1;
    setCurrentPage(currnetSequence);
    await getInterestCheckRecord(userId, uniqueId, currnetSequence);
    dispatchIdentifierToGTM('detail_page_change_history_interest_check');
  };

  const pushRight = async () => {
    const currnetSequence = currnetPage + 1;
    setCurrentPage(currnetSequence);
    await getInterestCheckRecord(userId, uniqueId, currnetSequence);
    dispatchIdentifierToGTM('detail_page_change_history_interest_check');
  };

  /**
   * 印刷ボタンの描画.
   * @method renderPrint
   * @return {JSX.Element} JSX.
   */
  return (
    <>
      {loaded ? null : <PageLoader />}
      <Box id="interestcheck" style={{ backgroundColor: 'white' }}>
        <Box>
          <BrowserHandler user={user} interestCheck={interestCheck} loaded={loaded} />
        </Box>
        <Box pb="30px">
          <PageTransition
            currnetSequence={currnetPage}
            total={total}
            pushLeft={pushLeft}
            pushRight={pushRight}
            sheetType={CHECK_SHEET_TYPES.interestCheck}
          />
        </Box>
        <RenderCSS />
      </Box>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(InterestCheckPrintContainer);
