/* 利用者設定画面上部の曜日・かなリスト */
import React, { FC, useEffect, useState, useLayoutEffect } from 'react';
import * as UserListField from '../constants/UserListField';
import * as DateKanaList from '../constants/DateKanaList';
import DateList from '../components/Molecules/DateList';
import KanaList from '../components/Molecules/KanaList';
import SearchInput from '../components/Molecules/SearchInput';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

const DateKanaListContainer: FC = () => {
  // 頭文字フィルター用.
  // デフォルトはその曜日なのでそれでinexを取得する.
  const [kanaSelected, setKanaSelected] = useState<DateKanaList.KANA>(DateKanaList.KANA.KANA_ALL);
  const [weekSelected, setWeekSelected] = useState<string>(UserListField.WEEK_CHARS[new Date().getDay()]);
  const [searchText, setSearchText] = useState('');

  // フィルター処理.
  const filter = () => {
    // userのオブジェクトの入ったhiddenのClass.
    let usersObject: HTMLCollectionOf<Element> | any = document.getElementsByClassName('user');

    // search枠.
    if (searchText) {
      // フィルターする(HTMLCollectionは配列に一回変換する必要あり).
      usersObject = [].slice.call(usersObject).filter(function (element: HTMLElement) {
        const parent: HTMLElement | null = element.parentElement;
        if (parent) {
          // 一旦全員消す(次の頭文字フィルターに委ねるため).
          parent.hidden = true;
        }

        const html: string = element.innerHTML;
        const row = JSON.parse(html);
        return Object.keys(row).some(function (key: string) {
          return String(row[key]).indexOf(searchText) > -1;
        });
      });
    }

    // ここから曜日フィルター.
    let index = 0;
    for (const key in usersObject) {
      if (Object.prototype.hasOwnProperty.call(usersObject, key)) {
        // 1つのエレメント.
        const element: Element = usersObject[key];

        // stringfyで入ってるのでいったん変換.
        // 運動曜日: 月曜開始で順に入った配列（1:該当, 0:非該当）.
        const userVisitDay: number[] = (JSON.parse(element.innerHTML) as UserListField.User).user_visit_day;

        const parent: HTMLElement | null = element.parentElement;
        // 選択されたWeekSelectedがWeekChars[]の中では何番目の値なのか？
        const weekIndex = UserListField.START_MO_WEEK_CHARS.indexOf(weekSelected);

        // stringfyで入ってるのでいったん変換.
        const kanaInitial: string = (JSON.parse(element.innerHTML) as UserListField.User).user_name_kana;

        // カナ名頭文字.
        const firstKana: string = kanaInitial ? kanaInitial.substring(0, 1) : '';

        // 両方「すべて」またはかなと曜日を満たす.
        if (
          (weekSelected === UserListField.ALL && kanaSelected.word === '') ||
          (weekSelected === UserListField.ALL && kanaSelected.word.search(firstKana) > -1) ||
          (kanaSelected.word === '' && userVisitDay[weekIndex] === 1) ||
          (userVisitDay && userVisitDay[weekIndex] === 1 && kanaSelected.word.search(firstKana) > -1)
        ) {
          if (parent) {
            // 居る人は表示する.
            parent.hidden = false;

            // 偶数は白/奇数グレー(cssがhidden要素をスキップしてくれないので上書き).
            const isEven: boolean = index % 2 === 0;
            [].forEach.call(
              parent.childNodes,
              (child: Node) => ((child as HTMLElement).style.background = isEven ? '#fff' : '#e8e4df'),
            );

            index++;
          }
        } else {
          if (parent) {
            // 該当しない人は消す.
            parent.hidden = true;
          }
        }
      }
    }
  };

  // 五十音.
  const searchHeadChar = (kana: DateKanaList.KANA) => {
    setKanaSelected(kana);
    dispatchIdentifierToGTM('kana_filter');
  };

  const searchHeadWeek = (week: string) => {
    // 背景変更.
    setWeekSelected(week);
    dispatchIdentifierToGTM('week_day_filter');
  };

  const onFocusSearchInput = () => {
    dispatchIdentifierToGTM('user_search');
  };

  useEffect(() => {
    // フィルター.
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // フィルター処理.
    filter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanaSelected, weekSelected, searchText]);

  return (
    <React.Fragment>
      <SearchInput onChange={setSearchText} onFocus={onFocusSearchInput} placeholder="利用者検索" />
      <KanaList kanaSelected={kanaSelected} searchHeadChar={searchHeadChar} />
      <DateList weekSelected={weekSelected} searchHeadWeek={searchHeadWeek} />
    </React.Fragment>
  );
};

export default DateKanaListContainer;
