// 興味関心チェック or 居宅訪問の定数.
export const SERVICE_ERR_MSG = 'この利用者は、該当サービスの利用がございません。';

// multiple(連想配列型).
export interface Multiple {
  level: number[];
  is_problem: boolean;
  // ADL, IADLのみ.
  env?: string;
}

// 返り値の型.
export interface ResponseData {
  // Unique ID
  unique_id: string;
  // ADL-食事
  adl_feed: Multiple;
  // ADL-排尿.
  adl_ur: Multiple;
  // ADL-入浴
  adl_bath: Multiple;
  // ADL-更衣
  adl_dress: Multiple;
  // ADL-整容
  adl_cosme: Multiple;
  // ADL-移乗
  adl_transfer: Multiple;
  // ADL-状況・生活課題
  adl_detail: string;
  // IADL-屋内移動
  iadl_indoor: Multiple;
  // IADL-屋外移動
  iadl_outdoor: Multiple;
  // IADL-階段昇降
  iadl_lift: Multiple;
  // IADL-調理
  iadl_cook: Multiple;
  // IADL-洗濯
  iadl_wash: Multiple;
  // IADL-掃除
  iadl_clean: Multiple;
  // IADL-状況・生活課題
  iadl_detail: string;
  // 起居動作-起き上がり
  behaivior_arise: Multiple;
  // 起居動作-座位
  behaivior_sitting: Multiple;
  // 起居動作-立ち上がり
  behaivior_standup: Multiple;
  // 起居動作-立位
  behaivior_upright: Multiple;
  // 起居動作-状況・生活課題
  behaivior_detail: string;
  // 訪問日
  visited_day: string;
  // 訪問スタッフ名
  staff: string;
  // 被聞き取り者
  interviewee: string;
  // 訪問スタッフの職種
  profession: string;
  // データ作成時間
  created_at: string;
  // データ更新時間
  updated_at: string;
  // 記録番号
  sequence: number;
}

export const DUMMY_HOME_VISIT_RESULT = {
  adl_bath: {
    is_problem: false,
    level: [0],
  },
  adl_cosme: {
    is_problem: false,
    level: [0],
  },
  adl_detail: '',
  adl_dress: {
    is_problem: false,
    level: [0],
  },
  adl_feed: {
    env: '',
    is_problem: false,
    level: [0],
  },
  adl_transfer: {
    env: '',
    is_problem: false,
    level: [0],
  },
  adl_ur: {
    is_problem: false,
    level: [0],
  },
  behaivior_arise: {
    is_problem: false,
    level: [0],
  },
  behaivior_detail: '',
  behaivior_sitting: {
    is_problem: false,
    level: [0],
  },
  behaivior_standup: {
    is_problem: false,
    level: [0],
  },
  behaivior_upright: {
    is_problem: false,
    level: [0],
  },
  created_at: '',
  iadl_clean: {
    is_problem: false,
    level: [],
  },
  iadl_cook: {
    is_problem: false,
    level: [0],
  },
  iadl_detail: '',
  iadl_indoor: {
    is_problem: false,
    level: [0],
  },
  iadl_lift: {
    is_problem: false,
    level: [0],
  },
  iadl_outdoor: {
    is_problem: false,
    level: [0],
  },
  iadl_wash: {
    is_problem: false,
    level: [0],
  },
  interviewee: '',
  profession: '',
  sequence: 1,
  staff: '',
  unique_id: '',
  updated_at: '',
  visited_day: '',
};
