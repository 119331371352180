export interface Popup {
  pcPrint: boolean;
  literallyReport: boolean;
  moffCheck: boolean;
  ADLLocomo: boolean;
  kiroku: boolean;
  press: boolean;
  userSetting: boolean;
}
export const OPEN_PCPRINT = 'OPEN_PCPRINT';
export const OPEN_LITERALLYREPORT = 'OPEN_LITERALLYREPORT';
export const OPEN_MOFFCHECK = 'OPEN_MOFFCHECK';
export const OPEN_ADLLOCOMO = 'OPEN_ADLLOCOMO';
export const OPEN_KIROKU = 'OPEN_KIROKU';
export const OPEN_PRESS = 'OPEN_PRESS';
export const OPEN_USER_SETTING = 'OPEN_USER_SETTING';
export const CLOSE_POPUP = 'CLOSE_POPUP';

export const POPUP_FUNCTION_NAMES = {
  pcPrint: {
    title: 'pcPrint',
    src: 'https://www.youtube.com/embed/CHmegYrGL80?rel=0',
  },
  adlLocomo: {
    title: 'ADLLocomo',
    src: 'https://www.youtube.com/embed/HiCkuHaFI5U?rel=0',
  },
  kiroku: {
    title: 'kiroku',
    src: 'https://www.youtube.com/embed/o8qRT-orj6Y?rel=0',
  },
  press: {
    title: 'press',
    src: 'https://www.youtube.com/embed/TkKKTDGT7ZI?rel=0',
  },
  moffCheck: {
    title: 'moffCheck',
    src: 'https://www.youtube.com/embed/C65PQm-eDkM?rel=0',
  },
  literallyReport: {
    title: 'literallyReport',
    src: 'https://www.youtube.com/embed/nxkjiXIMtPk?rel=0',
  },
  userSetting: {
    title: 'userSetting',
    src: 'https://www.youtube.com/embed/TepV2LYInco?rel=0',
  },
} as const;

export type POPUP_FUNCTION_NAMES = typeof POPUP_FUNCTION_NAMES[keyof typeof POPUP_FUNCTION_NAMES];
