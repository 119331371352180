// ユーザー一覧の表示(Show).
import React, { FC } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Box, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import * as UserListField from '../../constants/UserListField';
import { DATE_DISPLAY_TYPE } from '../../constants/Training';
import { MOFF_CHECK_COMPARE_LOGIC, MOFF_CHECK_V1_VALUE_LIST } from '../../constants/MoffCheck';
import { Memo } from '../../constants/Report';
import { FREE_CHOICE_SELECT_MONTH_RANGE } from '../../constants/Report';

import { osJudgement } from '../../utils/commonUtil';

import DateSelector from '../Molecules/DateSelector';
import UserList from '../Molecules/UserListShow';

import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import GeneralPrimarySelectBox from '../Atoms/GeneralPrimarySelectBox';
import ToggleButton from '../Atoms/ToggleButton';
import MultiSelectBox from '../Atoms/MultiSelectBox';

const useStyles = makeStyles((theme) => ({
  mainBody: {
    background: '#fff',
    borderRadius: '10px',
    '-moz-box-shadow': '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
    '-webkit-box-shadow': '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
    boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
    '@media print': {
      boxShadow: 'none',
    },
  },
  headerTitle: {
    padding: '25px 30px 25px 20px',
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#7c644e',
    borderRadius: '10px 10px 0 0',
    '@media print': {
      display: 'none',
    },
  },
  ttlStyle01: {
    padding: '0 45px 0 5px',
    margin: '0 0 20px 0',
    fontSize: '25px',
    borderBottom: 'solid 1px #dcdcdc',
  },
  dateSelecterArea: {
    width: '100%',
    maxWidth: '350px',
    margin: '0 auto 25px',
    [theme.breakpoints.up('md')]: {
      margin: (props: { margin: string }) => props.margin,
    },
  },
  freeChoiceSelectArea: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  monthStockedButton: {
    backgroundColor: '#fff',
    boxShadow: '0px 0px 6px 1px #a9a9a9',
    borderRadius: '10px',
    width: '125px',
    height: '50px',
    margin: '0 10px 0',
    padding: '0 5px',
    display: 'flex',
    testAlign: 'center',
    lineHeight: '50px',
    fontSize: '1.2em',
    cursor: 'pointer',
    '& p, & button': {
      display: 'block',
      margin: '0 auto',
    },
    '& button': {
      color: '#999',
      backgroundColor: '#fff',
    },
    '&:hover': {
      opacity: 0.5,
    },
  },
}));

export interface MoffCheckAllUsersPrintProps {
  location: any;
  refUsersList: any;
  minusMonthly: () => void;
  plusMonthly: () => void;
  allUsersPrintButtonClickHandler: () => void;
  date: moment.Moment;
  loaded: boolean;
  cachedUsers: UserListField.User[];
  compareMonth: string;
  selectBoxChanged: (event: any) => void;
  toggle: boolean;
  switchToggle: (toggle: boolean) => void;
  selectItems: string[];
  addOutputItems: (event: React.ChangeEvent<{ value: unknown }>) => void;
  selectMonths: string[];
  addOutputMonths: (event: React.ChangeEvent<{ value: unknown }>) => void;
  deleteMonthSelect: (month: string) => void;
  memos: Memo[];
  saveAllMemoValue: (checkDataArr: UserListField.CheckData[]) => void;
}

const MoffCheckAllUsersPrint: FC<MoffCheckAllUsersPrintProps> = ({
  location,
  refUsersList,
  minusMonthly,
  plusMonthly,
  allUsersPrintButtonClickHandler,
  date,
  loaded,
  cachedUsers,
  compareMonth,
  selectBoxChanged,
  toggle,
  switchToggle,
  selectItems,
  addOutputItems,
  selectMonths,
  addOutputMonths,
  memos,
  saveAllMemoValue,
}) => {
  const props = {
    margin: compareMonth === MOFF_CHECK_COMPARE_LOGIC.thirdOption ? '0 0 25px' : '0 auto 25px',
  };
  const classes = useStyles(props);
  const isApp = false;
  const isDisable = compareMonth === MOFF_CHECK_COMPARE_LOGIC.thirdOption && selectMonths.length === 0;
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
  };

  return (
    <>
      <Box component="article" position="relative" pb="75px" className={classes.mainBody}>
        <Box className={classes.headerTitle}>
          <p>一括出力</p>
        </Box>
        <Box component="section" m="10px 0 0 0" p="30px 25px 0">
          <Box display="flex" flexWrap="wrap" justifyContent="space-between">
            <Box mb={3}>
              {isApp ? null : osJudgement() === 'PC' ||
                osJudgement() === 'ipad' ||
                osJudgement() === 'iphone' ||
                osJudgement() === 'android' ||
                osJudgement() === 'androidTablet' ? (
                <GeneralPrimaryButton
                  txt="一括PDF出力"
                  setCss={'width: 200px; height: 40px; margin: 0 0 0 auto;'}
                  isDisable={isDisable ? true : false}
                  onClick={isDisable ? () => null : allUsersPrintButtonClickHandler}
                />
              ) : (
                <GeneralPrimaryButton
                  txt="一括PDF出力"
                  setCss={'width: 200px; height: 40px; margin: 0 0 0 auto;'}
                  onClick={() => null}
                  isDisable={true}
                />
              )}
            </Box>
            <Box mb={5}>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent={matches.xs ? 'flex-start' : 'space-between'}
                displayPrint="none"
              >
                <Box alignItems="center" lineHeight="40px" mx={1}>
                  <b>出力形式選択:</b>
                </Box>
                <Box>
                  <ToggleButton
                    isToggleOn={toggle}
                    toggleDisplayMode={switchToggle}
                    leftText="評価レポート"
                    rightText="一覧表"
                    customCss={'width: 140px; height: 40px;'}
                  />
                </Box>
              </Box>
              <Box
                mt={{ xs: 2, sm: 4 }}
                display="flex"
                flexWrap="wrap"
                justifyContent={matches.xs ? 'start' : 'space-between'}
                displayPrint="none"
              >
                <Box alignItems="center" lineHeight="40px" mx={1}>
                  <b>比較方法選択:</b>
                </Box>
                <Box width="280px">
                  <GeneralPrimarySelectBox
                    selectedValue={compareMonth}
                    size="small"
                    style={{
                      height: 50,
                    }}
                    selectBoxChanged={selectBoxChanged}
                  >
                    <MenuItem value={MOFF_CHECK_COMPARE_LOGIC.default}>3ヶ月間比較</MenuItem>
                    <MenuItem value={MOFF_CHECK_COMPARE_LOGIC.secondOption}>トレーニング開始月＋2ヶ月間比較</MenuItem>
                    <MenuItem value={MOFF_CHECK_COMPARE_LOGIC.thirdOption}>比較月自由選択</MenuItem>
                  </GeneralPrimarySelectBox>
                </Box>
              </Box>
              {!toggle ? (
                <Box
                  mt={{ xs: 2, sm: 4 }}
                  display="flex"
                  flexWrap="wrap"
                  justifyContent={matches.xs ? 'start' : 'space-between'}
                  displayPrint="none"
                >
                  <Box width="100px" alignItems="center" lineHeight="20px" mx={1}>
                    <b>表への追加項目の選択:</b>
                  </Box>
                  <Box width="280px">
                    <MultiSelectBox
                      itemList={MOFF_CHECK_V1_VALUE_LIST}
                      selectedItems={selectItems}
                      checkBoxOnClick={addOutputItems}
                      style={{
                        width: 280,
                        height: 50,
                      }}
                      helperText="2項目まで選択可能です"
                    />
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
          <h2 className={classes.ttlStyle01}>モフトレチェックの一括PDF出力</h2>
          <Box className={classes.freeChoiceSelectArea}>
            <Box className={classes.dateSelecterArea}>
              <DateSelector
                date={date}
                className={''}
                dateDisplayType={DATE_DISPLAY_TYPE.Month}
                minusDate={minusMonthly}
                plusDate={plusMonthly}
              />
            </Box>
            {(() => {
              if (compareMonth === MOFF_CHECK_COMPARE_LOGIC.thirdOption) {
                return (
                  <Box mb={3} display="flex" flexWrap="wrap" justifyContent="center">
                    <Box mb={{ xs: 2, sm: 2, md: 0 }}>
                      <MultiSelectBox
                        itemList={FREE_CHOICE_SELECT_MONTH_RANGE}
                        selectedItems={selectMonths}
                        checkBoxOnClick={addOutputMonths}
                        helperText="比較月を3項目まで選択"
                        style={{
                          width: 350,
                        }}
                      />
                    </Box>
                  </Box>
                );
              } else {
                return null;
              }
            })()}
          </Box>
          {loaded && !_.isEmpty(cachedUsers) ? (
            <UserList
              users={cachedUsers}
              invokedPath={location.pathname}
              currentMonth={date.format('YYYY-MM')}
              isMemoColumn={toggle}
              textFormPlaceholder={'ここにメモを記入'}
              ref={(instance) => {
                refUsersList.current = instance;
              }}
              memos={memos}
              saveAllMemoValue={saveAllMemoValue}
            />
          ) : (
            // ダミー.
            <Box>
              <p>読み込み中</p>
            </Box>
          )}
          <Box component="section" m="10px 0 0 0" p="30px 25px 0">
            <Box display="flex" flexWrap="wrap" justifyContent="space-between">
              <Box mb={3}>
                {isApp ? null : osJudgement() === 'PC' ||
                  osJudgement() === 'ipad' ||
                  osJudgement() === 'iphone' ||
                  osJudgement() === 'android' ||
                  osJudgement() === 'androidTablet' ? (
                  <GeneralPrimaryButton
                    txt="一括PDF出力"
                    setCss={'width: 200px; height: 40px; margin: 0 0 0 auto;'}
                    isDisable={isDisable ? true : false}
                    onClick={isDisable ? () => null : allUsersPrintButtonClickHandler}
                  />
                ) : (
                  <GeneralPrimaryButton
                    txt="一括PDF出力"
                    setCss={'width: 200px; height: 40px; margin: 0 0 0 auto;'}
                    onClick={() => null}
                    isDisable={true}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MoffCheckAllUsersPrint;
