import * as UserListField from '../constants/UserListField';
import * as ReportConst from '../constants/Report';

export const extractionCheckUsers = (checkDataArr: UserListField.CheckData[]) => {
  const checkUserArr = checkDataArr.filter((checkData) => checkData.selected === true);
  if (checkUserArr.length === 0) {
    alert('ユーザーが選択されていません');
    return false;
  }

  return checkUserArr;
};

export const extractionChangedMemo = (
  checkDataArr: UserListField.CheckData[],
  thisMonthMemoArr: ReportConst.Memo[],
) => {
  const memoArr = checkDataArr
    .map((checkData: UserListField.CheckData) => {
      const isThisMonthMemo = thisMonthMemoArr.find((thisMonthMemo) => {
        return thisMonthMemo.user_id === checkData.userId;
      });
      const thisMonthMemo = isThisMonthMemo ? isThisMonthMemo.comment : '';
      if (checkData.memo !== thisMonthMemo) {
        return { user_id: checkData.userId, memo: { comment: checkData.memo } };
      }
      return null;
    })
    .filter((memo): memo is ReportConst.MemoForAPI => {
      return memo !== null;
    });
  if (memoArr.length === 0) {
    alert('メモの変更がありません');
    return false;
  }

  return memoArr;
};
