// モフトレチェックモジュール.
import _ from 'lodash';
import moment from 'moment';
import {
  MOFF_CHECK_COMPARE_LOGIC,
  UnionWalkingAbilityType,
  MoffCheckAPIResponseType,
  MOFF_CHECK_ALL_MEASUREMENT_LIST,
  MOFF_CHECK_API_PREFIX_LIST,
  ROMTable,
  UnionMoffCheckTrainingType,
} from '../constants/MoffCheck';
import { Male, Female } from '../constants/UserListField';
import { isValidDate } from './dateUtil';

/**
 * 男性、女性表示.
 *
 * @param {number} gender
 * @returns {string}
 * @memberof MoffCheck
 */
export const getGender = (gender: number): string => {
  return `${gender === 0 ? Male : Female}性`;
};

/**
 * 誕生日表示用文字列生成.
 *
 * @param {User} user
 * @returns {string}
 * @memberof MoffCheck
 */
export const makeBirthday = (user: any): string => {
  const year = user.user_birth_year;
  const month = user.user_birth_month;
  const date = user.user_birth_day;

  // 数字ではないデータが入っているのを防止.
  if (!isValidDate(year, month, date)) {
    return `${year}. . 生`;
  }

  return `${year}.${month}.${date}生`;
};

export const jpNotationMonth = (yearMonth: string) => {
  const splitedMonth = yearMonth.split('.');
  return `${splitedMonth[0]}年${splitedMonth[1]}月`;
};

export const measurementUnit = (title: string): string => {
  const unitObj = {
    tug: '秒',
    balance: '秒',
    cs30: '回',
    ss5: '秒',
    rom_101_right: '°',
    rom_101_left: '°',
    rom_602_right: '°',
    rom_602_left: '°',
    grip_right: 'kg',
    grip_left: 'kg',
  };
  const unit = unitObj[title];
  return unit ? unit : '';
};

/**
 * モフトレチェック比較自由選択で使用。バラバラに取得した月ごとトレーニングデータを一つのデータにまとめる
 *
 * @export
 * @param {MoffCheckAPIResponseType<UnionWalkingAbilityType[]>[][]} apiResponseData
 */
export const convertApiResponsedata = (apiResponseData: MoffCheckAPIResponseType<UnionWalkingAbilityType[]>[][]) => {
  return _.map(
    _.zip(...apiResponseData),
    (selectedTrainingData: MoffCheckAPIResponseType<UnionWalkingAbilityType[]>[]) => {
      return _.reduce(selectedTrainingData, (prevObj, currentObj) => {
        return {
          count: prevObj.count + currentObj.count,
          Items: [...prevObj.Items, ...currentObj.Items],
        };
      });
    },
  );
};

export const measurementUnitJp = (measurementPrefix: MOFF_CHECK_ALL_MEASUREMENT_LIST) => {
  let unit = '';
  switch (measurementPrefix) {
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.cs30:
      unit = '回';
      break;
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.gripLeft:
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.gripRight:
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.weight:
      unit = 'kg';
      break;
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.romKneeLeft:
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.romKneeRight:
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.romSholderLeft:
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.romSholderRight:
      unit = '°';
      break;
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.tug:
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.balanceLeft:
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.balanceRight:
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.ss5:
      unit = '秒';
      break;
    case MOFF_CHECK_ALL_MEASUREMENT_LIST.height:
      unit = 'cm';
      break;
    default:
      unit = '';
  }
  return unit;
};

export const judgeDisplayDiff = (
  firstColunm: number | null,
  secondColunm: number | null,
  resultComparison: { diff: number; sign: string; evaluation: string } | null,
) => {
  return !_.isNull(firstColunm) && !_.isNull(secondColunm) && resultComparison ? true : false;
};

export const displayMeasurementResult = (
  result: number | string | null,
  measurementUnit: string,
  noResultText: string,
) => {
  return result?.toString().concat(measurementUnit) ?? noResultText;
};

export const convertResponseToMonthArray = (responseData: UnionMoffCheckTrainingType[]) =>
  _(responseData)
    .map((obj) => {
      let convertDate = null;
      if (obj) {
        convertDate = 'started_at' in obj ? obj.started_at : 'recorded_at' in obj ? obj.recorded_at : null;
      }
      return convertDate ? moment(convertDate).format('YYYY-MM') : null;
    })
    .compact()
    .uniq()
    .value();

export const convertResponseToLatestDate = (
  responseData: {
    type: MOFF_CHECK_API_PREFIX_LIST;
    data: UnionMoffCheckTrainingType | ROMTable[] | null;
  }[],
) => {
  // ROMのみレスポンスの形式が違うため、apiResponseDataから取り出し処理を変更する
  const rom = _.find(responseData, (data) => data.type.name === MOFF_CHECK_API_PREFIX_LIST.rom.name);
  const rest = _.omitBy(responseData, (data) => data.type.name === MOFF_CHECK_API_PREFIX_LIST.rom.name);

  // 取得した、TUG・Balance（右・左足）・cs30・ss5・romの最新日の配列からさらに最新日を抽出
  const latestDate = _([..._.map(rest, (data) => data.data ?? null), ...((rom?.data as ROMTable[]) ?? [null])])
    .compact()
    .map((obj: UnionMoffCheckTrainingType) => {
      let convertDate = null;
      if (obj) {
        convertDate = 'started_at' in obj ? obj.started_at : 'recorded_at' in obj ? obj.recorded_at : null;
      }
      return convertDate ? moment(convertDate).format('YYYY-MM-DD') : null;
    })
    .max();

  return latestDate;
};

export const convertResponseToOldestMonth = (
  responseOldestData: {
    data: MoffCheckAPIResponseType<UnionMoffCheckTrainingType[]>;
  }[],
) => {
  const oldestMonthTrainingDataArr = _.map(
    responseOldestData,
    (training: { data: MoffCheckAPIResponseType<UnionMoffCheckTrainingType[]> }) => {
      return training.data.Items;
    },
  );
  // 念の為、一番古いトレーニング日を抽出
  const oldestMonthTrainingData = _.union(...oldestMonthTrainingDataArr).sort(
    (a: UnionMoffCheckTrainingType, b: UnionMoffCheckTrainingType) => {
      const aDate = 'started_at' in a ? a.started_at : 'recorded_at' in a ? a.recorded_at : null;
      const bDate = 'started_at' in b ? b.started_at : 'recorded_at' in b ? b.recorded_at : null;
      return moment(aDate).diff(bDate);
    },
  )[0];
  let oldestDate = 'empty_data';
  if (oldestMonthTrainingData) {
    oldestDate =
      'started_at' in oldestMonthTrainingData
        ? moment(oldestMonthTrainingData.started_at).format('YYYY-MM')
        : 'recorded_at' in oldestMonthTrainingData
        ? moment(oldestMonthTrainingData.recorded_at).format('YYYY-MM')
        : 'empty_data';
  }

  return oldestDate;
};

export const generateMoffCheckMonthsPayload = (
  compareMonth: MOFF_CHECK_COMPARE_LOGIC,
  singleSelectMonth: string,
  multiSelectMonths: string[],
) =>
  ([MOFF_CHECK_COMPARE_LOGIC.default, MOFF_CHECK_COMPARE_LOGIC.secondOption] as MOFF_CHECK_COMPARE_LOGIC[]).includes(
    compareMonth,
  )
    ? [singleSelectMonth]
    : multiSelectMonths
        .sort((a: string, b: string) => moment(b).diff(a))
        .map((month) => moment(month).format('YYYY-MM'));
