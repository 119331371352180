/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  input: {
    backgroundColor: '#fff',
    width: (props: { width?: string | number }) => props?.width ?? undefined,
    height: (props: { height?: string | number }) => props?.height ?? undefined,
  },
}));
interface GeneralPrimarySelectBoxProps {
  /** 選択されている値 */
  selectedValue: string | number;
  /** disableかどうか */
  isDisable?: boolean;
  /** formの高さ */
  size?: 'small' | 'medium';
  /** テスト用 key_id */
  dataTest?: string;
  /** 外から注入するCSSスタイル */
  style?: {
    width?: string | number;
    height?: string | number;
  };
  /** 選択肢を変更した時のイベントハンドラ */
  selectBoxChanged: (event: any) => void;
}

const GeneralPrimarySelectBox: FC<GeneralPrimarySelectBoxProps> = ({
  children,
  selectedValue,
  selectBoxChanged,
  isDisable = false,
  size = 'medium',
  style,
  dataTest = 'general-primary-select-box',
}) => {
  const classes = useStyles(style);
  return (
    <TextField
      select
      fullWidth
      disabled={isDisable}
      value={selectedValue}
      onChange={selectBoxChanged}
      variant="outlined"
      size={size}
      InputProps={{
        className: classes.input,
      }}
      data-test={dataTest}
    >
      {children}
    </TextField>
  );
};

export default GeneralPrimarySelectBox;
