const initialState = {
  display: true,
};

export default function footer(state: any = initialState, action: any) {
  switch (action.type) {
    case 'hidden':
      return Object.assign({}, state, {
        display: false,
      });
    default:
      return state;
  }
}
