import React from 'react';
import {
  PRESS_SPRING_MONTH_RANGE,
  PRESS_SPRING_BACKBROUND_COLOR,
  PRESS_SUMMER_MONTH_RANGE,
  PRESS_SUMMER_BACKBROUND_COLOR,
  PRESS_AUTUMN_MONTH_RANGE,
  PRESS_AUTUMN_BACKBROUND_COLOR,
  PRESS_WINTER_MONTH_RANGE,
  PRESS_WINTER_BACKBROUND_COLOR,
} from '../constants/Press';

import * as images from '../img/index';

export const getSeasonClassName = (selectedMonth: number): string => {
  let className = '';

  // season
  if (PRESS_SPRING_MONTH_RANGE.includes(selectedMonth)) {
    className = PRESS_SPRING_BACKBROUND_COLOR;
  } else if (PRESS_SUMMER_MONTH_RANGE.includes(selectedMonth)) {
    className = PRESS_SUMMER_BACKBROUND_COLOR;
  } else if (PRESS_AUTUMN_MONTH_RANGE.includes(selectedMonth)) {
    className = PRESS_AUTUMN_BACKBROUND_COLOR;
  } else if (PRESS_WINTER_MONTH_RANGE.includes(selectedMonth)) {
    className = PRESS_WINTER_BACKBROUND_COLOR;
  }

  return `${className}`;
};

export const getBadge = (): JSX.Element => {
  const randomNum = Math.floor(Math.random() * 3);
  let ptrnAlphabet = 'A';
  let alt = '';
  switch (randomNum) {
    case 1:
      ptrnAlphabet = 'A';
      alt = 'すごい！';
      break;
    case 2:
      ptrnAlphabet = 'B';
      alt = 'そのちょうし！';
      break;
    case 3:
      ptrnAlphabet = 'C';
      alt = 'やったね！';
      break;
    default:
      break;
  }

  return <img src={images[`badge_ptrn${ptrnAlphabet}`]} alt={`${alt}`} />;
};

export const memoComplete = (event: any, setMemoText: (value: string) => void) => {
  if (event.type === 'click' && (event.target as HTMLTextAreaElement).value === undefined) {
    setMemoText('');
  } else {
    setMemoText((event.target as HTMLTextAreaElement).value);
  }
};
