// Edge用.
import React, { FC } from 'react';
import BaseBrowser from './BaseBrowser';
import { User } from '../../../constants/UserListField';
import { ResponseData } from '../../../constants/LifeCheck';

// Edge.
const RenderCSS = () => {
  return (
    <style type="text/css">
      {/* 縦印刷, 日付・title名は消す */}
      {'@media print{@page {size: A4; margin: 0;' +
        'margin-top: 2mm; margin-left: 1cm; margin-right: 1cm; margin-bottom: 0mm; } ' +
        '#wrapper { margin-top: 0;} header { display: none; } ' +
        'body { padding: 8;} ' +
        '#homevisit { padding-left: 0; padding-right: 0; } ' +
        '#sec01 { display: none; }}'}
      {'table, th, td { border: 2px solid #111 } .detailText { width: 182px; }'}
    </style>
  );
};

interface EdgeProps {
  user: User;
  lifeCheck: ResponseData;
}

// Chrome.
const Edge: FC<EdgeProps> = ({ user, lifeCheck }) => {
  return (
    <>
      <BaseBrowser user={user} lifeCheck={lifeCheck} />
      <RenderCSS />
    </>
  );
};

export default Edge;
