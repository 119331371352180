export const MOFF_CHECK_PDF_CREATE_START = 'PDF_CREATE/MOFF_CHECK_START';
export const MOFF_CHECK_PDF_CREATE_SUCCEED = 'PDF_CREATE/MOFF_CHECK_SUCCEED';
export const MOFF_CHECK_PDF_CREATE_FAIL = 'PDF_CREATE/MOFF_CHECK_FAIL';
export const KIROKU_PDF_CREATE_START = 'PDF_CREATE/KIROKU_START';
export const KIROKU_PDF_CREATE_SUCCEED = 'PDF_CREATE/KIROKU_SUCCEED';
export const KIROKU_PDF_CREATE_FAIL = 'PDF_CREATE/KIROKU_FAIL';
export const LITERALLY_PDF_CREATE_START = 'PDF_CREATE/LITERALLY_START';
export const LITERALLY_PDF_CREATE_SUCCEED = 'PDF_CREATE/LITERALLY_SUCCEED';
export const LITERALLY_PDF_CREATE_FAIL = 'PDF_CREATE/LITERALLY_FAIL';
export const PRESS_PDF_CREATE_START = 'PDF_CREATE/PRESS_START';
export const PRESS_PDF_CREATE_SUCCEED = 'PDF_CREATE/PRESS_SUCCEED';
export const PRESS_PDF_CREATE_FAIL = 'PDF_CREATE/PRESS_FAIL';

export const UNKNOWN_ERROR = 'PDF_CREATE/UNKNOWN_ERROR';
export const STATUS_CHECK_FIRST_INTERVAL = 2000;

export const REPORT = {
  MoffCheckV1_AllMeasurement: {
    fileName: 'MoffCheckV1_AllMeasurement',
    retryLimit: 17,
    retryInterval: 3000,
  },
  MoffCheckV2_AllMeasurement: {
    fileName: 'MoffCheckV2_AllMeasurement',
    retryLimit: {
      all: 17,
      individual: 7,
    },
    retryInterval: 3000,
  },
  Kiroku_All: {
    fileName: 'Kiroku_All',
    retryLimit: 25,
    retryInterval: 2000,
  },
  Literally_All: {
    fileName: 'Literally_All',
    retryLimit: 20,
    retryInterval: 3000,
  },
  Press_ALL: {
    fileName: 'Press_All',
    retryLimit: 17,
    retryInterval: 3000,
  },
};
