import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store/configureStore';

import * as HeaderActions from '../actions/Header';
import * as Header from '../constants/Header';
import ForgotPasswordCode from '../components/Organisms/ForgotPasswordCode';

type AllState = ReturnType<typeof store.getState>;

const ForgotPasswordCodeContainer: FC = () => {
  const dispatch = useDispatch();
  const password = useSelector((state: AllState) => state.password);

  useEffect(() => {
    (async () => {
      document.title = '管理';
      dispatch(HeaderActions.setPage(Header.SETTING_FORGOT_PASSWORD));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ForgotPasswordCode password={password} />;
};

export default ForgotPasswordCodeContainer;
