// 実施記録フォームから受け取った「新規」データの型
export interface ParamsFormat {
  user_care_level: number | null;
  user_birth_year: number | null;
  user_birth_month: number | null;
  user_birth_day: number | null;
  user_name_kana: string | null;
  user_name: string | null;
  user_care_id: string | null;
  user_gender: number | null;
  user_visit_day: number[] | null;
  user_insurer_no: string | null;
}

export interface CheckerUser {
  careId: string;
  userName: string;
}

export const START_DATE = 1900;

// ユーザにデータがないときのデフォルトの月.
export const DEFAULT_MONTH = 1;

export const DUMMY_USER = {
  user_name_kana: '',
  user_care_id: '',
  functional_latest_date: '',
  created_at: '',
  user_gender: 0,
  user_birth_day: 1,
  user_visit_day: [0, 0, 0, 0, 0, 0, 0],
  user_birth_year: 1900,
  updated_at: '',
  user_id: '',
  institution_sub: '',
  user_name: '',
  user_care_level: 0,
  user_birth_month: 1,
  user_insurer_no: '',
};
