// モフトレ通信
// 円グラフの各色
export const GraphBackgroundColor = [
  'rgba(220,113,100,1.0)',
  'rgba(100,147,225,1.0)',
  'rgba( 99,207,147,1.0)',
  'rgba(237,205,105,1.0)',
  'rgba(221,142, 87,1.0)',
  'rgba( 211, 211, 211,1.0)',
];

export const PRESS_SPRING_MONTH_RANGE = [3, 4, 5];
export const PRESS_SPRING_BACKBROUND_COLOR = '#66B268';

export const PRESS_SUMMER_MONTH_RANGE = [6, 7, 8];
export const PRESS_SUMMER_BACKBROUND_COLOR = '#63BDC6';

export const PRESS_AUTUMN_MONTH_RANGE = [9, 10, 11];
export const PRESS_AUTUMN_BACKBROUND_COLOR = '#ECBE3A';

export const PRESS_WINTER_MONTH_RANGE = [12, 1, 2];
export const PRESS_WINTER_BACKBROUND_COLOR = '#D66363';
