import React, { FC } from 'react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

export type Direction = 'up' | 'down' | 'keep';
interface ArrowIconProps {
  /** 矢印の方向。up（上） or down（下）or keep（横）*/
  direction: Direction;
}

const ArrowIcon: FC<ArrowIconProps> = ({ direction = 'up' }) => (
  <>
    {direction === 'up' ? (
      <ArrowUpwardIcon data-test="up" />
    ) : direction === 'down' ? (
      <ArrowDownwardIcon data-test="down" />
    ) : (
      <ArrowForwardIcon data-test="keep" />
    )}
  </>
);
export default ArrowIcon;
