import * as Password from '../constants/Password';
import * as PasswordAPI from '../api/Password';

const validateAction = (actionType: string, errorMessage: string, callBack: Function | undefined) => {
  if (typeof callBack !== 'undefined') {
    callBack(false, errorMessage);
  }

  return {
    type: actionType,
    error: errorMessage,
    result: false,
  };
};

/**
 * パスワード変更アクション、Amplifyを使用したcognito認証
 *
 * @param {string} oldPassword
 * @param {string} newPassword
 * @param {string} confirmation
 * @param {Function|undefined} callBack
 * @returns
 */
export const changePassword = (
  oldPassword: string,
  newPassword: string,
  confirmation: string,
  callBack: Function | undefined,
) => {
  if (newPassword === '' || oldPassword === '' || confirmation === '') {
    return validateAction(
      Password.ERROR_CHANGE_PASSWORD,
      "You should type 8 character at least. It's not permited blank in any area",
      callBack,
    );
  } else if (newPassword === oldPassword) {
    return validateAction(Password.ERROR_CHANGE_PASSWORD, 'You typed the same password as your old one', callBack);
  } else if (newPassword !== confirmation) {
    return validateAction(
      Password.ERROR_CHANGE_PASSWORD,
      'You typed inconsistent password in confirmation box',
      callBack,
    );
  }

  return PasswordAPI.changePasswordAPI(oldPassword, newPassword);
};

/**
 * パスワードを忘れた場合のアクション、Amplifyを使用したcognito認証
 *
 * @param {string} username
 * @param {Function|undefined} callBack
 * @returns
 */
export const forgotPassword = (username: string, callBack: Function | undefined) => {
  if (username === '') {
    return validateAction(Password.ERROR_FORGOT_PASSWORD, 'You should input your username', callBack);
  }

  return PasswordAPI.forgotPasswordAPI(username);
};

/**
 * パスワード確認のアクション、Amplifyを使用したcognito認証
 *
 * @param {string} username
 * @param {string} confirmationCode
 * @param {string} newPassword
 * @param {string} newPasswordCheck
 * @param {Function|undefined} callBack
 * @returns
 */
export const confirmPasswordCode = (
  username: string,
  confirmationCode: string,
  newPassword: string,
  newPasswordCheck: string,
  callBack: Function | undefined,
) => {
  if (username === '' || confirmationCode === '' || newPassword === '' || newPasswordCheck === '') {
    return validateAction(Password.ERROR_CONFIRM_PASSWORD, 'You should input your information', callBack);
  } else if (newPassword !== newPasswordCheck) {
    return validateAction(Password.ERROR_CONFIRM_PASSWORD, 'You typed the same password as your old one', callBack);
  }

  return PasswordAPI.confirmPasswordAPI(username, confirmationCode, newPassword);
};

/**
 * 初期化する.
 *
 * @returns
 */
export const resetStatus = () => {
  return {
    type: Password.PASSWORD_INITIALIZE,
  };
};
