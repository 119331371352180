import { AxiosInstance, AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

import * as CsvCreateConstants from '../constants/csvCreate';
import { createAxiosInstance } from '../constants/AWS';

import { mapReturnObject } from '../utils/commonUtil';
import { apiRequestFunc, errorFunc } from '../utils/apiUtil';

export class CsvCreate {
  /** インスタンス */
  private static _instance: CsvCreate;
  private axiosInstance!: AxiosInstance;
  // .envの設定
  private API_BASE_URL = String(process.env.REACT_APP_CREATE_CSV_API_URL);

  /** インスタンスの取得 */
  public static get instance(): CsvCreate | null {
    // _inctanceが存在しない場合に、new CsvCreate()を実行する。
    if (!this._instance) {
      this._instance = new CsvCreate();
      // moffAPIはjwtTokenで認証を行うため、初回instance生成時はapiKey・jwtToken共にnullで設定
      // ユーザーセッション取得の際（ページロード時）に、jwtTokenを含むAxiosInstanceで書き換える
      // （jwtToken取得を非同期で行うためreduxのフローにのせる）
      const axiosInstance = createAxiosInstance(this._instance.API_BASE_URL, null, null);

      if (axiosInstance) {
        this._instance.axiosInstance = axiosInstance;
      } else {
        return null;
      }
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  // jwtTokenを含むAxiosInstanceに書き換える関数
  public async setAxiosInstanceWithJwtToken(): Promise<void> {
    const jwtToken: string = await Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => user.getSignInUserSession()?.getIdToken()?.getJwtToken() ?? '')
      .catch(() => '');
    this.axiosInstance = await createAxiosInstance(this.API_BASE_URL, null, jwtToken);
  }

  public createCsv(
    requestParams: CsvCreateConstants.RequestParams,
    outputItems: CsvCreateConstants.CSV_OUTPUT_ITEMS[],
  ) {
    const uniqueLogicFunc = async () => {
      const responseData = await Promise.all(
        outputItems.map(async (item) =>
          this.axiosInstance
            .post(`/${item.url}`, requestParams)
            .then((response: AxiosResponse) => response?.data)
            .catch((error) => {
              if (error.response.status === 404) return null;
              throw error;
            }),
        ),
      );
      return mapReturnObject(outputItems, (item, i) => ({
        [item.key]: {
          downloadPath: responseData[i]?.[`${item.downloadKey}`] ?? null,
        },
      }));
    };
    return apiRequestFunc(
      CsvCreateConstants.CSV_CREATE_START,
      CsvCreateConstants.CSV_CREATE_SUCCEED,
      CsvCreateConstants.CSV_CREATE_FAIL,
      uniqueLogicFunc,
    );
  }
}

export const createCsvCreateInstance = (): [boolean, CsvCreate | null, any | null] => {
  // Moffインスタンスが存在しない場合、エラーアクションを返す
  return CsvCreate.instance === null
    ? [true, null, errorFunc(CsvCreateConstants.UNKNOWN_ERROR, '不明なエラーが発生しました.')]
    : [false, CsvCreate.instance, null];
};
