import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import _ from 'lodash';

import {
  MOFF_CHECK_V1_REPORT_START,
  MOFF_CHECK_V1_REPORT_SUCCEED,
  MOFF_CHECK_V1_REPORT_FAIL,
  MOFF_CHECK_V2_REPORT_START,
  MOFF_CHECK_V2_REPORT_SUCCEED,
  MOFF_CHECK_V2_REPORT_FAIL,
  REPORT_COMMENT_SAVE_START,
  REPORT_COMMENT_SAVE_SUCCEED,
  REPORT_COMMENT_SAVE_FAIL,
  REPORT_COMMENTS_GET_START,
  REPORT_COMMENTS_GET_SUCCEED,
  REPORT_COMMENTS_GET_FAIL,
  UNKNOWN_ERROR,
} from '../constants/Report';
import { createAxiosInstance } from '../constants/AWS';
import { MoffCheckV1SelectKeys, REQUEST_RETRY_MAX_COUNT } from '../constants/MoffCheck';
import { MOFF_REPORT_MASTER } from '../constants/Report';
import * as ReportConst from '../constants/Report';

import { apiRequestFunc, errorFunc } from '../utils/apiUtil';
import { waitTimeMs } from '../utils/commonUtil';
import qs from 'qs';

export class Report {
  /** インスタンス */
  private static _instance: Report;
  private axiosInstance!: AxiosInstance;
  // .envの設定
  private API_BASE_URL = String(process.env.REACT_APP_REPORT_API_URL);

  /** インスタンスの取得 */
  public static get instance(): Report | null {
    // _inctanceが存在しない場合に、new PdfCreate()を実行する。
    if (!this._instance) {
      this._instance = new Report();
      // moffAPIはjwtTokenで認証を行うため、初回instance生成時はapiKey・jwtToken共にnullで設定
      // ユーザーセッション取得の際（ページロード時）に、jwtTokenを含むAxiosInstanceで書き換える
      // （jwtToken取得を非同期で行うためreduxのフローにのせる）
      const axiosInstance = createAxiosInstance(this._instance.API_BASE_URL, null, null);

      if (axiosInstance) {
        this._instance.axiosInstance = axiosInstance;
      } else {
        return null;
      }
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  // jwtTokenを含むAxiosInstanceに書き換える関数
  public async setAxiosInstanceWithJwtToken(): Promise<void> {
    const jwtToken: string = await Auth.currentAuthenticatedUser()
      .then((user: any) => user.signInUserSession.idToken.jwtToken)
      .catch(() => '');
    this.axiosInstance = await createAxiosInstance(this.API_BASE_URL, null, `Bearer ${jwtToken}`);
  }

  // 評価レポートのトレーニング結果を取得するAPI【3ヶ月比較】
  public getMoffCheckV1MeasurementThreeMonthAPI(
    userId: string,
    selectMonth: string,
    items: MoffCheckV1SelectKeys[],
    isWalkingAbility: boolean,
  ) {
    const uniqueLogicFunc = async () => {
      const resoponseData = await this.axiosInstance
        .get(`/check_v1/three_months`, {
          params: {
            first: selectMonth,
            user_ids: [userId],
            items: items,
            is_walking_ability: isWalkingAbility,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        })
        .then((response: AxiosResponse) => response)
        .catch((err: AxiosError) => err.response);
      const reportData = resoponseData?.status === 200 ? resoponseData.data : null;
      return {
        moffCheckV1: reportData,
      };
    };
    return apiRequestFunc(
      MOFF_CHECK_V1_REPORT_START,
      MOFF_CHECK_V1_REPORT_SUCCEED,
      MOFF_CHECK_V1_REPORT_FAIL,
      uniqueLogicFunc,
    );
  }

  // 評価レポートのトレーニング結果を取得するAPI【開始月比較】
  public getMoffCheckV1MeasurementOldestOldestAPI(
    userId: string,
    selectMonth: string,
    items: MoffCheckV1SelectKeys[],
    isWalkingAbility: boolean,
  ) {
    const uniqueLogicFunc = async () => {
      const resoponseData = await this.axiosInstance
        .get(`/check_v1/oldest`, {
          params: {
            first: moment(selectMonth).format('YYYY.MM'),
            user_ids: [userId],
            items: items,
            is_walking_ability: isWalkingAbility,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        })
        .then((response: AxiosResponse) => response)
        .catch((err: AxiosError) => err.response);
      const reportData = resoponseData?.status === 200 ? resoponseData.data : null;
      return {
        moffCheckV1: reportData,
      };
    };
    return apiRequestFunc(
      MOFF_CHECK_V1_REPORT_START,
      MOFF_CHECK_V1_REPORT_SUCCEED,
      MOFF_CHECK_V1_REPORT_FAIL,
      uniqueLogicFunc,
    );
  }

  // 評価レポートのトレーニング結果を取得するAPI【比較月自由選択】
  public getMoffCheckV1MeasurementFreeChoiceAPI(
    userId: string,
    selectMonths: string[],
    items: MoffCheckV1SelectKeys[],
    isWalkingAbility: boolean,
  ) {
    const uniqueLogicFunc = async () => {
      const selectMonthsParams = selectMonths
        .sort((a: string, b: string) => moment(b).diff(a))
        .map((month) => moment(month).format('YYYY-MM'));
      const resoponseData = await this.axiosInstance
        .get(`/check_v1/arbitrary_months`, {
          params: {
            first: selectMonthsParams[0],
            second: selectMonthsParams[1] || undefined,
            third: selectMonthsParams[2] || undefined,
            user_ids: [userId],
            items: items,
            is_walking_ability: isWalkingAbility,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        })
        .then((response: AxiosResponse) => response)
        .catch((err: AxiosError) => err.response);
      const reportData = resoponseData?.status === 200 ? resoponseData.data : null;
      return {
        moffCheckV1: reportData,
      };
    };
    return apiRequestFunc(
      MOFF_CHECK_V1_REPORT_START,
      MOFF_CHECK_V1_REPORT_SUCCEED,
      MOFF_CHECK_V1_REPORT_FAIL,
      uniqueLogicFunc,
    );
  }

  // tug・balance・cs30・ss5・romのトレーニング結果を取得するAPI【3ヶ月比較】
  public getMoffCheckAllMeasurementThreeMonthAPI(userId: string, selectMonth: string) {
    const uniqueLogicFunc = async () => {
      let resoponseData;
      for (let i = 1; i < REQUEST_RETRY_MAX_COUNT; i++) {
        resoponseData = await this.axiosInstance
          .get(`/check_v2/three_months`, {
            params: {
              first: selectMonth,
              user_ids: [userId],
            },
          })
          .then((response: AxiosResponse) => response);
        if (_.isEmpty(resoponseData.data.incomplete_users)) {
          break;
        } else {
          await waitTimeMs(1000);
        }
      }
      const reportData =
        resoponseData?.status === 200
          ? _.zipObject(['title', 'data'], [resoponseData.data.title, { ...resoponseData }.data.data.shift() ?? {}])
          : {};
      const comment = await this.getCommentAPI(userId, MOFF_REPORT_MASTER.MoffCheckAllMeasurementReport, selectMonth);
      return {
        moffCheckV2: reportData,
        comment: comment,
      };
    };
    return apiRequestFunc(
      MOFF_CHECK_V2_REPORT_START,
      MOFF_CHECK_V2_REPORT_SUCCEED,
      MOFF_CHECK_V2_REPORT_FAIL,
      uniqueLogicFunc,
    );
  }

  // tug・balance・cs30・ss5・romのトレーニング結果を取得するAPI【開始月比較】
  public getMoffCheckAllMeasurementOldestOldestAPI(userId: string, selectMonth: string) {
    const uniqueLogicFunc = async () => {
      let resoponseData;
      for (let i = 1; i < REQUEST_RETRY_MAX_COUNT; i++) {
        resoponseData = await this.axiosInstance
          .get(`/check_v2/oldest`, {
            params: {
              first: moment(selectMonth).format('YYYY.MM'),
              user_ids: [userId],
            },
          })
          .then((response: AxiosResponse) => response);
        if (_.isEmpty(resoponseData.data.incomplete_users)) {
          break;
        } else {
          await waitTimeMs(1000);
        }
      }
      const reportData =
        resoponseData?.status === 200
          ? _.zipObject(['title', 'data'], [resoponseData.data.title, { ...resoponseData }.data.data.shift() ?? {}])
          : {};
      const comment = await this.getCommentAPI(userId, MOFF_REPORT_MASTER.MoffCheckAllMeasurementReport, selectMonth);
      return {
        moffCheckV2: reportData,
        comment: comment,
      };
    };
    return apiRequestFunc(
      MOFF_CHECK_V2_REPORT_START,
      MOFF_CHECK_V2_REPORT_SUCCEED,
      MOFF_CHECK_V2_REPORT_FAIL,
      uniqueLogicFunc,
    );
  }

  // tug・balance・cs30・ss5・romのトレーニング結果を取得するAPI【比較月自由選択】
  public getMoffCheckAllMeasurementFreeChoiceAPI(userId: string, selectMonths: string[]) {
    const uniqueLogicFunc = async () => {
      const selectMonthsParams = selectMonths
        .sort((a: string, b: string) => moment(b).diff(a))
        .map((month) => moment(month).format('YYYY-MM'));
      let resoponseData;
      for (let i = 1; i < REQUEST_RETRY_MAX_COUNT; i++) {
        resoponseData = await this.axiosInstance
          .get(`/check_v2/arbitrary_months`, {
            params: {
              first: selectMonthsParams[0],
              second: selectMonthsParams[1] || undefined,
              third: selectMonthsParams[2] || undefined,
              user_ids: [userId],
            },
          })
          .then((response: AxiosResponse) => response);
        if (_.isEmpty(resoponseData.data.incomplete_users)) {
          break;
        } else {
          await waitTimeMs(1000);
        }
      }
      const reportData =
        resoponseData?.status === 200
          ? _.zipObject(['title', 'data'], [resoponseData.data.title, { ...resoponseData }.data.data.shift() ?? {}])
          : {};
      const comment = await this.getCommentAPI(
        userId,
        MOFF_REPORT_MASTER.MoffCheckAllMeasurementReport,
        selectMonthsParams[0],
      );
      return {
        moffCheckV2: reportData,
        comment: comment,
      };
    };
    return apiRequestFunc(
      MOFF_CHECK_V2_REPORT_START,
      MOFF_CHECK_V2_REPORT_SUCCEED,
      MOFF_CHECK_V2_REPORT_FAIL,
      uniqueLogicFunc,
    );
  }

  public getPressAPI(userId: string, selectMonth: string) {
    const uniqueLogicFunc = async () => {
      const resoponseData = await this.axiosInstance
        .get('/press', {
          params: {
            year_month: selectMonth,
            user_ids: [userId],
          },
        })
        .then((response: AxiosResponse) => response)
        .catch((err: AxiosError) => err.response);
      const reportData = resoponseData?.status === 200 ? resoponseData.data : null;
      return {
        press: reportData,
      };
    };
    return apiRequestFunc(
      ReportConst.PRESS_REPORT_START,
      ReportConst.PRESS_REPORT_SUCCEED,
      ReportConst.PRESS_REPORT_FAIL,
      uniqueLogicFunc,
    );
  }

  public saveCommentAPI(userId: string, reportId: number, yearMonth: string, comment: string) {
    const uniqueLogicFunc = async () => {
      await this.axiosInstance
        .put(`/comments/user/${userId}/${reportId}/${yearMonth}`, {
          comment: comment,
        })
        .then(() => {
          alert('データを保存しました');
        })
        .catch(() => {
          alert('データの保存に失敗しました');
        });
    };
    return apiRequestFunc(
      REPORT_COMMENT_SAVE_START,
      REPORT_COMMENT_SAVE_SUCCEED,
      REPORT_COMMENT_SAVE_FAIL,
      uniqueLogicFunc,
    );
  }

  public saveAllUsersCommentAPI(reportId: number, yearMonth: string, memoArr: ReportConst.MemoForAPI[]) {
    const uniqueLogicFunc = async () => {
      try {
        await Promise.all(
          memoArr.map((el) => {
            return this.axiosInstance.put(`/comments/user/${el.user_id}/${reportId}/${yearMonth}`, {
              comment: el.memo.comment,
            });
          }),
        );
        alert('データを保存しました');
      } catch (err) {
        alert('データの保存に失敗しました');
      }
    };
    return apiRequestFunc(
      REPORT_COMMENT_SAVE_START,
      REPORT_COMMENT_SAVE_SUCCEED,
      REPORT_COMMENT_SAVE_FAIL,
      uniqueLogicFunc,
    );
  }

  public async getCommentAPI(userId: string, reportId: number, yearMonth: string) {
    return this.axiosInstance
      .get(`/comments/user/${userId}/${reportId}/${yearMonth}`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status === 404) {
          return '';
        }
        throw error.response.data.message;
      });
  }

  public getCommentsAPI(reportId: number, yearMonth: string) {
    const uniqueLogicFunc = async () => {
      const comments = await this.axiosInstance
        .get(`/comments/users/${reportId}/${yearMonth}`)
        .then((response) => response.data);
      return {
        comments: comments,
      };
    };
    return apiRequestFunc(
      REPORT_COMMENTS_GET_START,
      REPORT_COMMENTS_GET_SUCCEED,
      REPORT_COMMENTS_GET_FAIL,
      uniqueLogicFunc,
    );
  }
}

export const createReportInstance: any = () => {
  // Moffインスタンスが存在しない場合、エラーアクションを返す
  return Report.instance === null
    ? [true, null, errorFunc(UNKNOWN_ERROR, '不明なエラーが発生しました.')]
    : [false, Report.instance, null];
};
