import * as Login from '../../constants/Login';

const initialState = {
  // FIXME: ダミーです.
  hoge: true,
};

export default function institution(state: any = initialState, action: any) {
  switch (action.type) {
    case Login.LOADING:
      return Object.assign({}, state, {
        // FIXME: ダミーです.
        hoge: false,
      });
    default:
      return state;
  }
}
