// HDS-R(旧名:MCI)用API.

import { AxiosInstance } from 'axios';
import { createAxiosInstance } from '../constants/AWS';

/**
 * HDS-RのAPIクラス.
 *
 * @export
 * @class HDSRAPI
 */
export class HDSRAPI {
  /** インスタンス */
  private static _instance: HDSRAPI;
  private axiosInstance!: AxiosInstance;

  /** インスタンスの取得 */
  public static get instance(): HDSRAPI | null {
    // _inctanceが存在しない場合に、new MoffAPI()を実行する。
    if (!this._instance) {
      this._instance = new HDSRAPI();

      // .envの設定
      const API_BASE_URL = String(process.env.REACT_APP_HDSR_API_URL);
      const API_KEY = String(process.env.REACT_APP_HDSR_API_KEY);
      if (API_BASE_URL === 'undefined') {
        console.log('.envにREACT_APP_HDSR_API_URLの設定がありません.');
        return null;
      }
      if (API_KEY === 'undefined') {
        console.log('.envにRREACT_APP_HDSR_API_KEYの設定がありません.');
        return null;
      }
      const axiosInstance = createAxiosInstance(API_BASE_URL, API_KEY);

      if (axiosInstance) {
        this._instance.axiosInstance = axiosInstance;
      } else {
        return null;
      }
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  // HDSR全ての記録取得関数 [APIリクエストから呼び出し]
  public async getHdsrAllRecordsFunc(uniqueId: string) {
    return this.axiosInstance.get(`/mci/users/${uniqueId}/scores`);
  }
}
