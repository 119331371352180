import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles({
  button: {
    background: 'linear-gradient(0deg, rgba(135, 122, 111, 1) 0%, rgba(189, 178, 163, 1) 100%)',
    cursor: 'pointer',
    '@media print': {
      display: 'none',
    },
  },
  arrow: {
    borderColor: (props: { borderColor: string; transform: string }) => props.borderColor,
    transform: (props: { borderColor: string; transform: string }) => props.transform,
  },
});

export type Direction = 'left' | 'right';

interface ArrowRoundButtonProps {
  /** ボタンの方向。left（左）もしくはright（右）*/
  arrowDirection: Direction;
  /** クリックした時のイベントハンドラ */
  onClick?: (event: any) => void;
}

const ArrowRoundButton: FC<ArrowRoundButtonProps> = ({ arrowDirection, onClick }) => {
  const classes = useStyles({
    borderColor: arrowDirection === 'left' ? 'transparent transparent #fff #fff' : '#fff #fff transparent transparent',
    transform: `${arrowDirection === 'left' ? 'translate(-30%, -50%)' : 'translate(-60%, -50%)'} rotate(45deg)`,
  });

  return (
    <Box
      position="relative"
      minWidth="50px"
      height="50px"
      borderRadius="50%"
      border="3px solid #e8e5e1"
      className={classes.button}
      onClick={onClick}
      data-test={`arrow-round-button-${arrowDirection}`}
    >
      <Box
        position="absolute"
        top="50%"
        left="50%"
        width="15px"
        height="15px"
        border="3px solid"
        className={classes.arrow}
      />
    </Box>
  );
};

export default ArrowRoundButton;
