/* 利用者設定・登録 */
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, MenuItem } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Enumerable from 'linq';
import { CARE_LEVELS } from '../../constants/Training';
import GenderRow from '../Molecules/GenderRow';
import DateOfBirthRow from '../Molecules/DateOfBirthRow';
import SelectDateList from '../Molecules/SelectDateList';
import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import GeneralPrimarySelectBox from '../Atoms/GeneralPrimarySelectBox';
import GeneralPrimaryInput from '../Atoms/GeneralPrimaryInput';
import VideoButton from '../Atoms/VideoButton';

const useStyles = makeStyles(() => ({
  report: {
    paddingBottom: '50px',
    background: '#fff',
    borderRadius: '10px',
    boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
  },
  hideable: {
    padding: '25px 30px 25px 20px',
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#7c644e',
    borderRadius: '10px 10px 0 0',
  },
}));

/**
 * 利用者登録コンポーネントのstate.
 *
 * @interface UserSettingRegisterState
 */
interface UserSettingFormProps {
  tableTitle: string;
  submitButtonText: string;
  deleteButtonText?: string;
  lastDayOfMonth: number;
  userName: string;
  readName: string;
  userCareId: string;
  birthYear: number;
  birthMonth: number;
  birthDay: number;
  careLevel: number;
  gender: number;
  dateList: number[];
  insurerNo: string;
  setUserName: (userName: string) => void;
  setReadName: (readName: string) => void;
  setUserCareId: (userCareId: string) => void;
  setBirthYear: (birthYear: number) => void;
  setBirthMonth: (birthMonth: number) => void;
  setBirthDay: (birthDay: number) => void;
  setCareLevel: (careLevel: number) => void;
  setGender: (gender: number) => void;
  setInsurerNo: (insurerNo: string) => void;
  funcDateList: (index: number, value: number) => void;
  submit: () => void;
  deleteFunc?: () => void;
  openPopupUserSetting: () => void;
}

const UserSettingForm: FC<UserSettingFormProps> = ({
  tableTitle,
  submitButtonText,
  deleteButtonText,
  lastDayOfMonth,
  userName,
  readName,
  userCareId,
  birthYear,
  birthMonth,
  birthDay,
  careLevel,
  gender,
  insurerNo,
  dateList,
  setUserName,
  setReadName,
  setUserCareId,
  setBirthYear,
  setBirthMonth,
  setBirthDay,
  setCareLevel,
  setGender,
  setInsurerNo,
  funcDateList,
  submit,
  deleteFunc,
  openPopupUserSetting,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
  };
  return (
    <Box component="article" pb="50px" className={classes.report}>
      <Box className={classes.hideable}>{tableTitle}</Box>
      <Box component="section">
        <Box mx={{ xs: 2, sm: 5 }} my={5}>
          <Grid container spacing={matches.xs ? 2 : 3}>
            <Box style={{ display: 'none' }} data-test="profile">
              <p>{userCareId ? userCareId : null}</p>
              <p>{userName ? userName : null}</p>
              <p>{readName ? readName : null}</p>
              <p>{birthYear ? birthYear : null}</p>
              <p>{birthMonth ? birthMonth : null}</p>
              <p>{birthDay ? birthDay : null}</p>
              <p>{careLevel !== undefined ? careLevel : null}</p>
              <p>{gender !== undefined ? (gender === 0 ? '男' : '女') : null}</p>
              <p>{insurerNo ? insurerNo : null}</p>
            </Box>
            <Grid item xs={12}>
              <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                <VideoButton text="利用者設定の説明を動画で見る" onClick={openPopupUserSetting} />
              </Box>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  名前
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <GeneralPrimaryInput
                  value={userName}
                  onChange={(event) => setUserName(event.target.value)}
                  placeholder={'漢字で名前を入力して下さい...'}
                  dataTest="user-name"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  なまえ(よみ)
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <GeneralPrimaryInput
                  value={readName}
                  onChange={(event) => setReadName(event.target.value)}
                  placeholder={'ひらがなで名前を入力してください...'}
                  dataTest="user-name-kana"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  被保険者番号（介護）
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <GeneralPrimaryInput
                  value={userCareId}
                  onChange={(event) => setUserCareId(event.target.value)}
                  dataTest="user-care-id"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  生年月日
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <DateOfBirthRow
                  birthYear={birthYear}
                  setBirthYear={setBirthYear}
                  birthMonth={birthMonth}
                  setBirthMonth={setBirthMonth}
                  lastDayOfMonth={lastDayOfMonth}
                  birthDay={birthDay}
                  setBirthDay={setBirthDay}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  性別
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <GenderRow gender={gender} setGender={setGender} />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  介護度
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box width={{ xs: '100%', sm: '200px' }}>
                  <GeneralPrimarySelectBox
                    selectedValue={careLevel}
                    selectBoxChanged={(event) => setCareLevel(Number(event.target.value))}
                    dataTest="care-level"
                  >
                    {Enumerable.from(CARE_LEVELS)
                      .select<JSX.Element>((careLevelStr: { key: string; value: any }, index: number) => {
                        // valueは0開始の数字.
                        return (
                          <MenuItem key={`careLevel${index}`} value={index}>
                            {careLevelStr.value}
                          </MenuItem>
                        );
                      })
                      .toArray()}
                  </GeneralPrimarySelectBox>
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  運動曜日
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <SelectDateList dateList={dateList} funcDateList={funcDateList} />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={3}>
                <Box height="100%" minHeight="35px" display="flex" alignItems="center" fontWeight="bold">
                  保険者番号
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <GeneralPrimaryInput
                  value={insurerNo}
                  onChange={(event) => setInsurerNo(event.target.value)}
                  dataTest="user-insurer-no"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Grid container justify="center" spacing={6}>
        <Grid item xs={12} sm={4}>
          <GeneralPrimaryButton
            txt={submitButtonText}
            setCss={`width: 240px; height: 50px; margin: 0 auto ${matches.xs ? '0' : '30px'};`}
            onClick={submit}
          />
        </Grid>
        {deleteButtonText ? (
          <Grid item xs={12} sm={4}>
            <GeneralPrimaryButton
              txt={deleteButtonText}
              setCss={'width: 240px; height: 50px; margin: 0 auto 30px;'}
              isDisable={true}
              onClick={deleteFunc}
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default UserSettingForm;
