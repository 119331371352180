import React, { FC, useRef, memo, useLayoutEffect, MutableRefObject } from 'react';
import Chart from 'chart.js';
import ChartJsAnnotation from 'chartjs-plugin-annotation';
import { calculateMaxCountInArray, numberRoundUp } from '../../utils/commonUtil';

const insertDataCanvasGraph = (
  canvasElement: HTMLCanvasElement,
  width: number,
  height: number,
  yAxis1Max: number,
  yAxis2Max: number,
  criteriaValue: number,
  dailyIndex: string[],
  historyCounts: number[],
  historyAngles: number[],
) => {
  const context1 = canvasElement.getContext('2d');
  if (!context1) return;
  context1.canvas.width = width;
  context1.canvas.height = height;

  const maxCount = calculateMaxCountInArray(historyCounts);
  const maxCountRoundUp = numberRoundUp(maxCount, 10);
  const lineData: Chart.ChartData = {
    labels: dailyIndex, // X軸のラベル
    datasets: [
      {
        label: '回数', // 項目名
        yAxisID: 'y-axis-1', // 軸
        fill: true,
        lineTension: 0,
        backgroundColor: 'rgba(210,230,230,0.5)',
        borderColor: 'rgba(50,150,150,1)',
        data: historyCounts,
      },
      {
        label: '角度', // 項目名
        yAxisID: 'y-axis-2', // 軸
        fill: true,
        lineTension: 0,
        backgroundColor: 'rgba(230,200,190,0.5)',
        borderColor: 'rgba(160,100,40,1)',
        data: historyAngles,
      },
    ] as Chart.ChartDataSets[],
  };

  return new Chart(context1, {
    type: 'line',
    plugins: [ChartJsAnnotation],
    data: lineData,
    options: {
      animation: {
        duration: 0,
      },
      hover: {
        animationDuration: 0,
      },
      elements: {
        line: {
          tension: 0, // disables bezier curves
        },
      },
      events: ['click'],
      responsive: false,
      scales: {
        yAxes: [
          {
            id: 'y-axis-1',
            type: 'linear',
            position: 'left',
            ticks: {
              // スケール
              max: yAxis1Max < maxCount ? maxCountRoundUp : yAxis1Max,
              min: 0,
              stepSize: 10,
            } as Chart.LinearTickOptions,
            scaleLabel: {
              display: true,
              labelString: '回数',
              fontSize: 10,
            },
          },
          {
            id: 'y-axis-2',
            type: 'linear',
            position: 'right',
            ticks: {
              max: yAxis2Max,
              min: 0,
              stepSize: 10,
            } as Chart.LinearTickOptions,
            scaleLabel: {
              display: true,
              labelString: '角度',
              fontSize: 10,
            },
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
      },
      annotation: {
        annotations: [
          {
            type: 'line',
            drawTime: 'afterDatasetsDraw',
            id: 'criteria',
            mode: 'horizontal',
            scaleID: 'y-axis-2',
            value: criteriaValue,
            borderColor: 'orange',
            borderWidth: 2,
            borderDash: [2, 2],
            borderDashOffset: 1,
            label: {
              backgroundColor: 'orange',
              borderwidth: 2,
              fontSize: 10,
              position: 'left',
              enabled: true,
              content: '目安角度',
            } as ChartJsAnnotation.LineAnnotationLabel,
          },
        ],
      },
    },
  });
};

/**
 * Canvasの縦横サイズを0にしてメモリ解放する.
 *
 * @export
 * @param {((HTMLCanvasElement | null)[])} [refCharts=[]]
 * @returns
 */
const memoryReleaseForCanvas = (refChart: MutableRefObject<(any | null)[]>) => {
  // 縦横サイズを0にしてcanvasを開放する.
  const chart = refChart.current['chart'];
  if (chart !== null && typeof chart !== 'undefined') {
    // Get canvas context
    const context1 = chart.getContext('2d');
    if (context1 === null) {
      return;
    }

    context1.canvas.width = 0;
    context1.canvas.height = 0;
    delete context1.canvas;
  }
};

interface TrainingGraphProps {
  /** グラフの横幅 */
  width: number;
  /** グラフの縦幅 */
  height: number;
  /** グラフの回数の最大値初期値 */
  yAxis1Max: number;
  /** グラフの角度の最大値初期値 */
  yAxis2Max: number;
  /** 基準角度 */
  criteriaValue: number;
  /** トレーニングを行った日（回数データのある日） */
  trainingDays: string[];
  /** 回数データ */
  counts: number[];
  /** 角度データ */
  angles: number[];
}

const TrainingGraph: FC<TrainingGraphProps> = ({
  width,
  height,
  yAxis1Max,
  yAxis2Max,
  criteriaValue,
  trainingDays,
  counts,
  angles,
}) => {
  const refChart = useRef<(HTMLCanvasElement | null)[]>([]);
  useLayoutEffect(() => {
    const graph = insertDataCanvasGraph(
      refChart.current['chart'],
      width,
      height,
      yAxis1Max,
      yAxis2Max,
      criteriaValue,
      trainingDays,
      counts,
      angles,
    );
    return () => {
      graph?.destroy();
      memoryReleaseForCanvas(refChart);
    };
    // eslint-disable-next-line
  }, [trainingDays, counts, angles]);

  return (
    <canvas
      ref={(item) => {
        refChart.current['chart'] = item;
      }}
      width={width}
      height={height}
      id="canvas"
    />
  );
};

export default memo(TrainingGraph);
