// ブラウザのJSXの親クラス.
import React, { FC } from 'react';
import { User, Male, Female } from '../../../constants/UserListField';
import { ResponseData } from '../../../constants/LifeCheck';
import { Multiple, LIFE_CHECK_LIST_PATTERN, LIFE_CHECK_SCORE_PATTERN } from '../../../constants/LifeCheck';
import { convertLifeCheckDate } from '../../../utils/dateUtil';
import { CARE_LEVELS } from '../../../constants/Training';
import Enumerable from 'linq';
import _ from 'lodash';

const RenderCSS = () => {
  return (
    <style type="text/css">
      {/* 縦印刷, 日付・title名は消す */}
      {'@media print{@page {size: A4; margin: 0; ' +
        'margin-top: 4mm; margin-left: 1cm; margin-right: 1cm; margin-bottom: 0mm; } ' +
        '#wrapper { margin-top: 0; } header { display: none; } ' +
        'body { padding: 8; } ' +
        '#homevisit { padding-left: 0; padding-right: 0; } ' +
        '#sec01 { display: none; }}'}
      {'table, th, td { border: 1px solid #111; vertical-align: middle; text-align: center }'}
      {'table { width: 774px; }'}
      {'th { background-color: #F2F2F2; font-weight: normal; }'}
      {'.thRow { height: 26px; }'}
      {'.tdRow { height: 47px; }'}
      {'.tableContainer { width: 774px; margin: 0 auto; line-height: 1; }'}
      {'.tableTitle { padding: 10px 0; font-weight: bold; text-align: center; }'}
      {'.lastTable { margin-bottom: 19px; }'}
      {'.env { text-align: left; }'}
      {'.cell1 { width: 160px; }'}
      {'.cell2 { width: 91px; }'}
      {'.cell3 { width: 136px; }'}
      {'.cell4 { width: 68px; }'}
      {'.cell5 { width: 430px; }'}
      {'.cell6 { width: 91px; }'}
      {'.sectionCell1 { width: 84px; }'}
      {'.sectionCell2 { width: 212px; }'}
      {'.sectionCell3 { width: 34px; }'}
      {'.sectionCell4 { width: 354px; }'}
      {'.sectionCell5 { width: 177px; }'}
      {'.topBorderNone { border-top: none; }'}
      {'.detailText { text-align: left; vertical-align: top; white-space: pre-wrap; }'}
      {/* レベル */}
      {'.levelTable { display: table; width: 100%; }'}
      {/* レベル中身 */}
      {'.levelItem { display: table-cell; table-layout: fixed; width: 50%; height: 100%; ' +
        'border-width: 0px 0px 0px 1px; text-align: left; vertical-align: middle; padding: 5px 0; }'}
    </style>
  );
};

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

/**
 * 各行項目のJSXの描画.
 * @param {Multiple} multiple 連想配列.
 * @return {JSX.Element[]} JSXの配列.
 */
interface RowProps {
  multiple: Multiple;
  listPattern: Partial<LIFE_CHECK_LIST_PATTERN>;
  scorePattern?: Record<keyof UnionToIntersection<LIFE_CHECK_SCORE_PATTERN>, number>;
}

const Row: FC<RowProps> = ({ multiple, listPattern, scorePattern = undefined }) => {
  // multipleを分離.
  let levels: number[] = [];
  // 課題の有無.
  let isProblem = false;
  // 環境(実施場所、補助具など)(falseなら下段なので非表示).
  let env: boolean | string = false;

  if (multiple) {
    levels = multiple ? multiple.level : [];
    isProblem = multiple ? multiple.is_problem : false;
    env = multiple.env === undefined ? false : multiple.env;
  }

  // number[] -> boolean[]
  const levelsBools: boolean[] = Enumerable.range(0, 4)
    .select((i: number) => {
      return levels.indexOf(i) > -1;
    })
    .toArray();

  const check = (isCheck: boolean): string => {
    return isCheck ? '■' : '□';
  };

  const problem: string = isProblem ? '有' : '無';

  return (
    <>
      <td>
        <div className="levelTable">
          {listPattern.independence && (
            <div className="levelItem" data-test="level-item">
              {`${check(levelsBools[0])} ${listPattern.independence}${
                _.isNil(scorePattern?.independence) ? '' : `(${scorePattern?.independence})`
              }`}
            </div>
          )}
          {listPattern === LIFE_CHECK_LIST_PATTERN.adl && listPattern.partialAssistance && (
            <div className="levelItem" data-test="level-item">
              {`${check(levelsBools[1])} ${listPattern.partialAssistance}${
                _.isNil(scorePattern?.partialAssistance) ? '' : `(${scorePattern?.partialAssistance})`
              }`}
            </div>
          )}
          {listPattern === LIFE_CHECK_LIST_PATTERN.transfers && listPattern.supervised && (
            <div className="levelItem" data-test="level-item">
              {`${check(levelsBools[1])} ${listPattern.supervised}${
                _.isNil(scorePattern?.supervised) ? '' : `(${scorePattern?.supervised})`
              }`}
            </div>
          )}
          {listPattern === LIFE_CHECK_LIST_PATTERN.mobility && listPattern.walker && (
            <div className="levelItem" data-test="level-item">
              {`${check(levelsBools[1])} ${listPattern.walker}${
                _.isNil(scorePattern?.walker) ? '' : `(${scorePattern?.walker})`
              }`}
            </div>
          )}
          {(listPattern === LIFE_CHECK_LIST_PATTERN.iadl || listPattern === LIFE_CHECK_LIST_PATTERN.behavior) &&
            listPattern.watchOver && (
              <div className="levelItem" data-test="level-item">
                {`${check(levelsBools[1])} ${listPattern.watchOver}`}
              </div>
            )}
        </div>
        {listPattern.rowLength === 3 && (
          <div className="levelTable">
            {listPattern === LIFE_CHECK_LIST_PATTERN.transfers && listPattern.canSitNotTransfer && (
              <div className="levelItem" data-test="level-item">
                {`${check(levelsBools[2])} ${listPattern.canSitNotTransfer}${
                  _.isNil(scorePattern?.canSitNotTransfer) ? '' : `(${scorePattern?.canSitNotTransfer})`
                }`}
              </div>
            )}
            {listPattern === LIFE_CHECK_LIST_PATTERN.mobility && listPattern.wheelchair && (
              <div className="levelItem" data-test="level-item">
                {`${check(levelsBools[2])} ${listPattern.wheelchair}${
                  _.isNil(scorePattern?.wheelchair) ? '' : `(${scorePattern?.wheelchair})`
                }`}
              </div>
            )}
          </div>
        )}
        <div className="levelTable">
          {(listPattern === LIFE_CHECK_LIST_PATTERN.iadl || listPattern === LIFE_CHECK_LIST_PATTERN.behavior) &&
            listPattern.partialAssistance && (
              <div className="levelItem" data-test="level-item">
                {`${check(levelsBools[2])} ${listPattern.partialAssistance}`}
              </div>
            )}
          {listPattern === LIFE_CHECK_LIST_PATTERN.adl && listPattern.totalAssistance && (
            <div className="levelItem" data-test="level-item">
              {`${check(levelsBools[2])} ${listPattern.totalAssistance}${
                _.isNil(scorePattern?.totalAssistance) ? '' : `(${scorePattern?.totalAssistance})`
              }`}
            </div>
          )}
          {listPattern !== LIFE_CHECK_LIST_PATTERN.adl && listPattern.totalAssistance && (
            <div className="levelItem" data-test="level-item">
              {`${check(levelsBools[3])} ${listPattern.totalAssistance}${
                _.isNil(scorePattern?.totalAssistance) ? '' : `(${scorePattern?.totalAssistance})`
              }`}
            </div>
          )}
        </div>
      </td>
      <td data-test="problem">{problem}</td>
      {listPattern.key !== LIFE_CHECK_LIST_PATTERN.behavior.key && (
        <td className="env" data-test="env">
          {env}
        </td>
      )}
    </>
  );
};

interface BaseBrowserProps {
  user: User;
  lifeCheck: ResponseData;
}

const BaseBrowser: FC<BaseBrowserProps> = ({ user, lifeCheck }) => {
  let visitDate;
  let careLevel;
  // 利用者氏名.
  if (user) {
    // 要介護度.
    careLevel = CARE_LEVELS[user.user_care_level];
  }

  if (lifeCheck) {
    // 訪問日.
    visitDate =
      lifeCheck?.visited_time_start && lifeCheck?.visited_time_end
        ? convertLifeCheckDate(lifeCheck.visited_time_start, lifeCheck.visited_time_end)
        : '';
  }

  return (
    <>
      <div className="tableContainer">
        <div className="tableTitle">生活機能チェックシート</div>
        {/* <!-- 利用者情報 --> */}
        <table>
          <thead>
            <tr className="thRow">
              <th className="cell1">利用者氏名</th>
              <td className="cell1" data-test="name">
                {user.user_name}
              </td>
              <th className="cell2">生年</th>
              <td data-test="birth-year">{user.user_birth_year}年</td>
              <th className="cell2">性別</th>
              <td className="cell2" data-test="gender">
                {user.user_gender === 0 ? Male : Female}
              </td>
            </tr>
          </thead>
        </table>
        {/* <!-- 2行目 --> */}
        <table className="topBorderNone">
          <thead>
            <tr className="thRow topBorderNone">
              <th className="cell1 topBorderNone">訪問日</th>
              <td className="cell5 topBorderNone" data-test="visit-date">
                {visitDate}
              </td>
              <th className="cell2 topBorderNone">要介護度</th>
              <td className="cell2 topBorderNone" data-test="care-level">
                {careLevel}
              </td>
            </tr>
          </thead>
        </table>
        {/* <!-- 3行目 --> */}
        <table className="lastTable topBorderNone">
          <thead>
            <tr className="thRow topBorderNone">
              <th className="cell1 topBorderNone">訪問スタッフ</th>
              <td className="cell1 topBorderNone" data-test="staff">
                {lifeCheck.staff}
              </td>
              <th className="cell2 topBorderNone">職種</th>
              <td className="topBorderNone" data-test="profession">
                {lifeCheck.profession}
              </td>
              <th className="cell2 topBorderNone">被聞き取り者</th>
              <td className="cell2 topBorderNone" data-test="interviewee">
                {lifeCheck.interviewee}
              </td>
            </tr>
          </thead>
        </table>
        {/* <!-- 内容タイトル --> */}
        <table className="table">
          <thead>
            <tr className="thRow">
              <th className="sectionCell1">{/* <!-- 空白 --> */}</th>
              <th className="sectionCell1">項目</th>
              <th className="sectionCell2">レベル</th>
              <th className="sectionCell3">課題</th>
              <th className="sectionCell5">環境(実施場所、補助具など)</th>
              <th className="sectionCell5">状況・生活課題</th>
            </tr>
          </thead>
          {/* <!-- ADL --> */}
          {/* <!-- 各項目 --> */}
          <tbody>
            <tr className="tdRow">
              <td rowSpan={10}>ADL</td>
              <td>食事</td>
              <Row
                multiple={lifeCheck.adl_feeding}
                listPattern={LIFE_CHECK_LIST_PATTERN.adl}
                scorePattern={LIFE_CHECK_SCORE_PATTERN.feeding}
              />
              <td rowSpan={10} className="detailText" data-test="detail-text">
                {/* <!-- 状況・生活課題 --> */}
                {lifeCheck.adl_detail}
              </td>
            </tr>
            <tr className="tdRow">
              <td>椅子とベッド間の移乗</td>
              <Row
                multiple={lifeCheck.adl_transfers}
                listPattern={LIFE_CHECK_LIST_PATTERN.transfers}
                scorePattern={LIFE_CHECK_SCORE_PATTERN.transfers}
              />
            </tr>
            <tr className="tdRow">
              <td>整容</td>
              <Row
                multiple={lifeCheck.adl_grooming}
                listPattern={LIFE_CHECK_LIST_PATTERN.adl}
                scorePattern={LIFE_CHECK_SCORE_PATTERN.grooming}
              />
            </tr>
            <tr className="tdRow">
              <td>トイレ動作</td>
              <Row
                multiple={lifeCheck.adl_toilet_use}
                listPattern={LIFE_CHECK_LIST_PATTERN.adl}
                scorePattern={LIFE_CHECK_SCORE_PATTERN.toilet_use}
              />
            </tr>
            <tr className="tdRow">
              <td>入浴</td>
              <Row
                multiple={lifeCheck.adl_bathing}
                listPattern={LIFE_CHECK_LIST_PATTERN.adl}
                scorePattern={LIFE_CHECK_SCORE_PATTERN.bathing}
              />
            </tr>
            <tr className="tdRow">
              <td>平地歩行</td>
              <Row
                multiple={lifeCheck.adl_mobility}
                listPattern={LIFE_CHECK_LIST_PATTERN.mobility}
                scorePattern={LIFE_CHECK_SCORE_PATTERN.mobility}
              />
            </tr>
            <tr className="tdRow">
              <td>階段昇降</td>
              <Row
                multiple={lifeCheck.adl_stairs}
                listPattern={LIFE_CHECK_LIST_PATTERN.adl}
                scorePattern={LIFE_CHECK_SCORE_PATTERN.stairs}
              />
            </tr>
            <tr className="tdRow">
              <td>更衣</td>
              <Row
                multiple={lifeCheck.adl_dressing}
                listPattern={LIFE_CHECK_LIST_PATTERN.adl}
                scorePattern={LIFE_CHECK_SCORE_PATTERN.dressing}
              />
            </tr>
            <tr className="tdRow">
              <td>
                排便
                <br />
                コントロール
              </td>
              <Row
                multiple={lifeCheck.adl_bowels}
                listPattern={LIFE_CHECK_LIST_PATTERN.adl}
                scorePattern={LIFE_CHECK_SCORE_PATTERN.bowels}
              />
            </tr>
            <tr className="tdRow">
              <td>
                排尿
                <br />
                コントロール
              </td>
              <Row
                multiple={lifeCheck.adl_bladder}
                listPattern={LIFE_CHECK_LIST_PATTERN.adl}
                scorePattern={LIFE_CHECK_SCORE_PATTERN.bladder}
              />
            </tr>
          </tbody>
          {/* <!-- IADL --> */}
          <tbody>
            <tr className="tdRow">
              <td rowSpan={3}>IADL</td>
              <td>調理</td>
              <Row multiple={lifeCheck.iadl_cooking} listPattern={LIFE_CHECK_LIST_PATTERN.iadl} />
              <td rowSpan={3} className="detailText" data-test="detail-text">
                {/* <!-- 状況・生活課題 --> */}
                {lifeCheck.iadl_detail}
              </td>
            </tr>
            <tr className="tdRow">
              <td>洗濯</td>
              <Row multiple={lifeCheck.iadl_washing} listPattern={LIFE_CHECK_LIST_PATTERN.iadl} />
            </tr>
            <tr className="tdRow">
              <td>掃除</td>
              <Row multiple={lifeCheck.iadl_cleaning} listPattern={LIFE_CHECK_LIST_PATTERN.iadl} />
            </tr>
          </tbody>
        </table>
        {/* <!-- 起居動作・タイトル --> */}
        <table className="topBorderNone">
          <thead>
            <tr className="thRow topBorderNone">
              <th className="sectionCell1 topBorderNone">{/* <!-- 空白 --> */}</th>
              <th className="sectionCell1 topBorderNone">項目</th>
              <th className="sectionCell2 topBorderNone">レベル</th>
              <th className="sectionCell3 topBorderNone">課題</th>
              <th className="sectionCell4 topBorderNone">状況・生活課題</th>
            </tr>
          </thead>
          {/* <!-- 起居動作 --> */}
          <tbody>
            <tr className="tdRow">
              <td rowSpan={5}>起居動作</td>
              <td>寝返り</td>
              <Row multiple={lifeCheck.behavior_turn_over} listPattern={LIFE_CHECK_LIST_PATTERN.behavior} />
              <td rowSpan={5} className="detailText" data-test="detail-text">
                {/* <!-- 状況・生活課題 --> */}
                {lifeCheck.behavior_detail}
              </td>
            </tr>
            <tr className="tdRow">
              <td>起き上がり</td>
              <Row multiple={lifeCheck.behavior_raise_up} listPattern={LIFE_CHECK_LIST_PATTERN.behavior} />
            </tr>
            <tr className="tdRow">
              <td>座位</td>
              <Row multiple={lifeCheck.behavior_sitting} listPattern={LIFE_CHECK_LIST_PATTERN.behavior} />
            </tr>
            <tr className="tdRow">
              <td>立ち上がり</td>
              <Row multiple={lifeCheck.behavior_stand_up} listPattern={LIFE_CHECK_LIST_PATTERN.behavior} />
            </tr>
            <tr className="tdRow">
              <td>立位</td>
              <Row multiple={lifeCheck.behavior_upright} listPattern={LIFE_CHECK_LIST_PATTERN.behavior} />
            </tr>
          </tbody>
        </table>
      </div>
      <RenderCSS />
    </>
  );
};

export default BaseBrowser;
