// ユーザー詳細.
import React, { FC, memo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { User, Male, Female } from '../../constants/UserListField';

const useStyles = makeStyles(() => ({
  cardHeader: {
    color: '#fff',
    backgroundColor: '#7c644e',
    borderRadius: '10px 10px 0 0',
    '&:after': {
      display: 'block',
      clear: 'both',
      content: '',
    },
    '@media print': {
      color: '#000',
      display: (props: { display: string }) => props.display,
    },
    '& p': {
      float: 'left',
      '&:first-child': {
        marginRight: '100px',
        width: '20%',
      },
      '&:nth-child(2)': {
        minWidth: '20%',
      },
      '&:nth-child(3)': {
        width: '20%',
      },
      '&:nth-child(4)': {
        width: '5%',
      },
      '&:nth-child(5)': {
        float: 'right',
      },
    },
  },
}));

// 利用者の詳細表示用コンポーネント
const UserDetail: FC<{ user: User; isDisablePrint?: boolean }> = ({ user, isDisablePrint = false }) => {
  const classes = useStyles({
    display: isDisablePrint ? 'none' : 'block',
  });

  if (typeof user === 'undefined') {
    return (
      <Box
        p="25px 30px 65px 20px"
        fontSize={{ xs: '18px', sm: '18px', md: '22px' }}
        fontWeight="bold"
        className={classes.cardHeader}
      >
        <p>Error</p>
        <p />
        <p />
        <p />
      </Box>
    );
  }

  return (
    <Box
      p="25px 30px 65px 20px"
      fontSize={{ xs: '18px', sm: '18px', md: '22px' }}
      fontWeight="bold"
      className={classes.cardHeader}
      data-test="card-header"
    >
      <p>{user.user_care_id}</p>
      <p>{user.user_name}</p>
      <p>{user.user_birth_year}</p>
      <p>{user.user_gender === 0 ? Male : Female}</p>
      {/* <p>{institution.institution_name}</p> */}
    </Box>
  );
};

export default memo(UserDetail, (prevProps, nextProps) => {
  if (prevProps.user === nextProps.user) {
    return true;
  } else {
    return false;
  }
});
