import { RouteProps } from 'react-router-dom';
import * as RequestParam from '../constants/RequestParam';

export function load(message: RouteProps['location']) {
  return {
    type: RequestParam.LOADED,
    message: message,
  };
}

export type loadAction = ReturnType<typeof load>;
