import React, { FC } from 'react';
import * as _ from 'lodash';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import * as DateKanaList from '../../constants/DateKanaList';
import GeneralPrimarySelectButton from '../Atoms/GeneralPrimarySelectButton';

const useStyles = makeStyles({
  inputField: {
    width: '100%',
    backgroundColor: '#fff',
  },
  menuItem: {
    height: '50px',
  },
  kanaList: {
    marginBottom: '20px',
    overflow: 'hidden',
  },
  li: {
    float: 'left',
    maxWidth: '9.09091%',
    textAlign: 'center',
  },
});

interface KanaListProps {
  kanaSelected: DateKanaList.KANA;
  searchHeadChar: (kana: DateKanaList.KANA) => void;
}

const KanaList: FC<KanaListProps> = ({ kanaSelected, searchHeadChar }) => {
  const theme = useTheme();
  const matches = {
    sm: useMediaQuery(theme.breakpoints.down('sm')),
  };
  const classes = useStyles();

  return matches.sm ? (
    <Box my={2}>
      <FormControl variant="outlined" className={classes.inputField}>
        <InputLabel id="demo-simple-select-outlined-label">かなフィルター</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={JSON.stringify(kanaSelected)}
          defaultValue={JSON.stringify(DateKanaList.KANA.KANA_ALL)}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            searchHeadChar(JSON.parse(event.target.value as string) as DateKanaList.KANA);
          }}
          label="かなフィルター"
        >
          {_.map(DateKanaList.KANA, (kana, index) => (
            <MenuItem className={classes.menuItem} value={JSON.stringify(kana)} key={index}>
              {kana.initialWord}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  ) : (
    <Box>
      <ul className={classes.kanaList}>
        <li className={classes.li} data-test={DateKanaList.KANA.KANA_A.name}>
          <GeneralPrimarySelectButton
            txt="あ"
            onClick={() => searchHeadChar(DateKanaList.KANA.KANA_A)}
            selected={kanaSelected === DateKanaList.KANA.KANA_A}
          />
        </li>
        <li className={classes.li} data-test={DateKanaList.KANA.KANA_KA.name}>
          <GeneralPrimarySelectButton
            txt="か"
            onClick={() => searchHeadChar(DateKanaList.KANA.KANA_KA)}
            selected={kanaSelected === DateKanaList.KANA.KANA_KA}
          />
        </li>
        <li className={classes.li} data-test={DateKanaList.KANA.KANA_SA.name}>
          <GeneralPrimarySelectButton
            txt="さ"
            onClick={() => searchHeadChar(DateKanaList.KANA.KANA_SA)}
            selected={kanaSelected === DateKanaList.KANA.KANA_SA}
          />
        </li>
        <li className={classes.li} data-test={DateKanaList.KANA.KANA_TA.name}>
          <GeneralPrimarySelectButton
            txt="た"
            onClick={() => searchHeadChar(DateKanaList.KANA.KANA_TA)}
            selected={kanaSelected === DateKanaList.KANA.KANA_TA}
          />
        </li>
        <li className={classes.li} data-test={DateKanaList.KANA.KANA_NA.name}>
          <GeneralPrimarySelectButton
            txt="な"
            onClick={() => searchHeadChar(DateKanaList.KANA.KANA_NA)}
            selected={kanaSelected === DateKanaList.KANA.KANA_NA}
          />
        </li>
        <li className={classes.li} data-test={DateKanaList.KANA.KANA_HA.name}>
          <GeneralPrimarySelectButton
            txt="は"
            onClick={() => searchHeadChar(DateKanaList.KANA.KANA_HA)}
            selected={kanaSelected === DateKanaList.KANA.KANA_HA}
          />
        </li>
        <li className={classes.li} data-test={DateKanaList.KANA.KANA_MA.name}>
          <GeneralPrimarySelectButton
            txt="ま"
            onClick={() => searchHeadChar(DateKanaList.KANA.KANA_MA)}
            selected={kanaSelected === DateKanaList.KANA.KANA_MA}
          />
        </li>
        <li className={classes.li} data-test={DateKanaList.KANA.KANA_YA.name}>
          <GeneralPrimarySelectButton
            txt="や"
            onClick={() => searchHeadChar(DateKanaList.KANA.KANA_YA)}
            selected={kanaSelected === DateKanaList.KANA.KANA_YA}
          />
        </li>
        <li className={classes.li} data-test={DateKanaList.KANA.KANA_RA.name}>
          <GeneralPrimarySelectButton
            txt="ら"
            onClick={() => searchHeadChar(DateKanaList.KANA.KANA_RA)}
            selected={kanaSelected === DateKanaList.KANA.KANA_RA}
          />
        </li>
        <li className={classes.li} data-test={DateKanaList.KANA.KANA_WA.name}>
          <GeneralPrimarySelectButton
            txt="わ"
            onClick={() => searchHeadChar(DateKanaList.KANA.KANA_WA)}
            selected={kanaSelected === DateKanaList.KANA.KANA_WA}
          />
        </li>
        <li className={classes.li} data-test={DateKanaList.KANA.KANA_ALL.name}>
          <GeneralPrimarySelectButton
            txt="全て"
            onClick={() => searchHeadChar(DateKanaList.KANA.KANA_ALL)}
            selected={kanaSelected === DateKanaList.KANA.KANA_ALL}
          />
        </li>
      </ul>
    </Box>
  );
};

export default KanaList;
