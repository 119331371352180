/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';

const Icon = css`
  width: 18px;
  max-height: 19px; //IE11対策
  margin-left: 5px;
  transform: translateY(3px);
`;

/*
 * TODO react-scripts@2.0.0以上で、react@16.3.0以上だとsvgをcomponentとして扱える
 * https://create-react-app.dev/docs/adding-images-fonts-and-files/#adding-svgs
 * (現状svg-loaderでエラー出るので、とりあえず直接記入)
 */
const TableIcon: FC = () => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="table"
      className="svg-inline--fa fa-table fa-w-16"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      css={Icon}
    >
      <path
        fill="currentColor"
        d="M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49
           48-48V80c0-26.51-21.49-48-48-48zM224 416H64v-96h160v96zm0-160H64v-96h160v96zm224
           160H288v-96h160v96zm0-160H288v-96h160v96z"
      />
    </svg>
  );
};

export default TableIcon;
