import _ from 'lodash';
import moment from 'moment';
import { MoffCheckV2ReportActionPayload, MoffCheckV1ReportActionPayload, CheckV1User } from './MoffCheck';

export const MOFF_CHECK_V1_REPORT_START = 'REPORT/MOFF_CHECK_V1_REPORT_START';
export const MOFF_CHECK_V2_REPORT_START = 'REPORT/MOFF_CHECK_V2_REPORT_START';
export const MOFF_CHECK_V2_REPORT_SUCCEED = 'REPORT/MOFF_CHECK_V2_REPORT_SUCCEED';
export const MOFF_CHECK_V1_REPORT_SUCCEED = 'REPORT/MOFF_CHECK_V1_SUCCEED';
export const MOFF_CHECK_V1_REPORT_FAIL = 'REPORT/MOFF_CHECK_V1_REPORT_FAIL';
export const MOFF_CHECK_V2_REPORT_FAIL = 'REPORT/MOFF_CHECK_V2_REPORT_FAIL';
export const REPORT_COMMENT_SAVE_START = 'REPORT/COMMENT_SAVE_START';
export const REPORT_COMMENT_SAVE_SUCCEED = 'REPORT/COMMENT_SAVE_SUCCEED';
export const REPORT_COMMENT_SAVE_FAIL = 'REPORT/COMMENT_SAVE_FAIL';
export const REPORT_COMMENTS_GET_START = 'REPORT/COMMENTS_GET_START';
export const REPORT_COMMENTS_GET_SUCCEED = 'REPORT/COMMENTS_GET_SUCCEED';
export const REPORT_COMMENTS_GET_FAIL = 'REPORT/COMMENTS_GET_FAIL';
export const PRESS_REPORT_START = 'REPORT/PRESS_REPORT_START';
export const PRESS_REPORT_SUCCEED = 'REPORT/PRESS_SUCCEED';
export const PRESS_REPORT_FAIL = 'REPORT/PRESS_REPORT_FAIL';

export const UNKNOWN_ERROR = 'REPORT/UNKNOWN_ERROR';

export const MOFF_REPORT_MASTER = {
  MoffCheckAllMeasurementReport: 1,
} as const;

export type MOFF_CHECK_ALL_MEASUREMENT_LIST = typeof MOFF_REPORT_MASTER[keyof typeof MOFF_REPORT_MASTER];

export interface PressReportResponse {
  institution: {
    institution_sub: string;
    institution_name: string;
  };
  data: {
    user: CheckV1User;
    trainings: {
      name: string;
      count: number;
    }[];
    calendar: {
      [key: number]: boolean;
    };
  }[];
}

export interface Memo {
  user_id: string;
  year_month: string;
  comment: string;
  institution_sub: string;
  created_at: string;
  updated_at: string;
  report_id?: number;
  report_id_year_month?: string;
}

export interface Memos {
  total: number;
  data: Memo[];
}

export interface ReportActionPayload {
  moffCheckV1: MoffCheckV1ReportActionPayload;
  moffCheckV2: MoffCheckV2ReportActionPayload;
  press: PressReportResponse;
  comment: Memo;
  comments: Memos;
}

export interface MemoForAPI {
  user_id: string;
  memo: {
    comment: string | undefined;
  };
}

export const SELECT_REPORT_YEAR_TERM = 5;

export const FREE_CHOICE_SELECT_MONTH_RANGE = _.map(new Array(SELECT_REPORT_YEAR_TERM * 12), (_, index) => {
  return moment().subtract(index, 'months').format('YYYY-MM');
});

export const CHECK_SHEET_TYPES = {
  interestCheck: {
    name: 'interestCheck',
    gtmKey: 'interest_check',
  },
  homeVisit: {
    name: 'homeVisit',
    gtmKey: 'home_visit',
  },
  lifeCheck: {
    name: 'lifeCheck',
    gtmKey: 'life_check',
  },
} as const;

export type CHECK_SHEET_TYPES = typeof CHECK_SHEET_TYPES[keyof typeof CHECK_SHEET_TYPES];
