/** @jsx jsx */
// graph toggle button
import { FC } from 'react';
import { jsx, css } from '@emotion/core';

const toggleBtnBox = css`
  display: flex;

  @media print {
    display: none;
  }
`;

const toggleBtn = (isSelected: boolean, side: string, customCss: string, isDisable: boolean) => {
  return css`
    font-weight: bold;
    font-size: 16px;
    width: 100px;
    text-align: center;
    box-shadow: ${isSelected ? '0 0 2px 1px rgba(34,36,38,.15)' : '0 0 2px 1px rgba(34,36,38,.15) inset'};
    cursor: ${isSelected ? 'default' : 'pointer'};
    background-color: ${!isDisable && isSelected
      ? '#ec7e4d'
      : !isDisable && !isSelected
      ? 'white'
      : isDisable && isSelected
      ? '#bdbdbd'
      : '#e0e0e0'};
    color: ${!isDisable && isSelected
      ? 'white'
      : !isDisable && !isSelected
      ? '#ffb492'
      : isDisable && isSelected
      ? '#757575'
      : '#9e9e9e'};
    border-radius: ${side === 'left' ? '20px 0 0 20px;' : ''};
    border-radius: ${side === 'right' ? '0 0 0 0;' : ''};
    border-radius: ${side === 'third' ? '0 0 0 0;' : ''};
    border-radius: ${side === 'fourth' ? '0 20px 20px 0;' : ''};
    ${customCss}
  `;
};

interface ToggleButtonFour {
  /** トグルがOnかどうか */
  isToggleLifeCheckOn: boolean;
  isToggleInterestCheckOn: boolean;
  isToggleFunctionalTrainingPlanOn: boolean;
  isToggleAdlOn: boolean;
  /** 左のテキスト */
  leftText: string;
  /** 右のテキスト */
  rightText: string;
  /** 3番目のテキスト */
  thirdText?: string;
  /** 4番目のテキスト */
  fourthText?: string;
  /** 左のアイコン */
  leftIcon?: JSX.Element;
  /** 右のアイコン */
  rightIcon?: JSX.Element;
  /** 3番目のアイコン */
  thirdIcon?: JSX.Element;
  /** 4番目のアイコン */
  fourthIcon?: JSX.Element;
  /** disableかどうか */
  isDisable?: boolean;
  /** 外部から渡すスタイル */
  customCss: string;
  /** トグルOn/Off切り替え */
  toggleDisplayModeLifeCheck: (isToggleLifeCheckOn: boolean) => void;
  toggleDisplayModeInterestCheck: (isToggleInterestCheckOn: boolean) => void;
  toggleDisplayModeFunctionalTrainingPlan: (isToggleFunctionalTrainingPlanOn: boolean) => void;
  toggleDisplayModeAdl: (isToggleAdlOn: boolean) => void;
}

const ToggleButtonFour: FC<ToggleButtonFour> = ({
  isToggleLifeCheckOn,
  isToggleInterestCheckOn,
  isToggleFunctionalTrainingPlanOn,
  isToggleAdlOn,
  toggleDisplayModeInterestCheck,
  toggleDisplayModeLifeCheck,
  toggleDisplayModeFunctionalTrainingPlan,
  toggleDisplayModeAdl,
  leftText,
  rightText,
  thirdText,
  fourthText,
  leftIcon,
  rightIcon,
  thirdIcon,
  fourthIcon,
  customCss,
  isDisable = false,
}) => {
  console.log(isToggleFunctionalTrainingPlanOn);
  return (
    <div css={toggleBtnBox}>
      <button
        onClick={isToggleLifeCheckOn ? undefined : () => toggleDisplayModeLifeCheck(!isToggleLifeCheckOn)}
        css={
          isToggleLifeCheckOn
            ? toggleBtn(true, 'left', customCss, isDisable)
            : toggleBtn(false, 'left', customCss, isDisable)
        }
        data-test="toggle-table"
      >
        {leftText}
        {leftIcon ?? leftIcon}
      </button>

      <button
        onClick={isToggleInterestCheckOn ? undefined : () => toggleDisplayModeInterestCheck(!isToggleInterestCheckOn)}
        css={
          isToggleInterestCheckOn
            ? toggleBtn(true, 'right', customCss, isDisable)
            : toggleBtn(false, 'right', customCss, isDisable)
        }
        data-test="toggle-graph"
      >
        {rightText}
        {rightIcon ?? rightIcon}
      </button>

      <button
        onClick={
          isToggleFunctionalTrainingPlanOn
            ? undefined
            : () => toggleDisplayModeFunctionalTrainingPlan(!isToggleFunctionalTrainingPlanOn)
        }
        css={
          isToggleFunctionalTrainingPlanOn
            ? toggleBtn(true, 'third', customCss, isDisable)
            : toggleBtn(false, 'third', customCss, isDisable)
        }
        data-test="toggle-graph"
      >
        {thirdText}
        {thirdIcon ?? thirdIcon}
      </button>

      <button
        onClick={isToggleAdlOn ? undefined : () => toggleDisplayModeAdl(!isToggleAdlOn)}
        css={
          isToggleAdlOn
            ? toggleBtn(true, 'fourth', customCss, isDisable)
            : toggleBtn(false, 'fourth', customCss, isDisable)
        }
        data-test="toggle-graph"
      >
        {fourthText}
        {fourthIcon ?? fourthIcon}
      </button>
    </div>
  );
};

export default ToggleButtonFour;
