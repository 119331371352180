// ユーザー一覧の表示(Show).
import React, { FC } from 'react';
import _ from 'lodash';

import * as UserListField from '../../constants/UserListField';
import { DATE_DISPLAY_TYPE } from '../../constants/Training';
import { SERVICE_CODE, ADL_CODE } from '../../constants/csvCreate';

import GeneralPrimaryButton from '../Atoms/GeneralPrimaryButton';
import GeneralPrimarySelectBox from '../Atoms/GeneralPrimarySelectBox';
import DateSelector from '../Molecules/DateSelector';
import UserList from '../Molecules/UserListShow';

import ToggleButtonFour from '../Atoms/ToggleButtonFour';
import MultiSelectBox from '../Atoms/MultiSelectBox';

import { makeStyles } from '@material-ui/core/styles';
import { Box, MenuItem } from '@material-ui/core';
import { CSV_OUTPUT_ITEMS } from '../../constants/csvCreate';
import { osJudgement } from '../../utils/commonUtil';

const useStyles = makeStyles(() => ({
  mainBody: {
    background: '#fff',
    borderRadius: '10px',
    '-moz-box-shadow': '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
    '-webkit-box-shadow': '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
    boxShadow: '0 1px 2px 1px rgba(0, 0, 0, 0.3)',
    '@media print': {
      boxShadow: 'none',
    },
  },
  headerTitle: {
    padding: '25px 30px 25px 20px',
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#fff',
    backgroundColor: '#7c644e',
    borderRadius: '10px 10px 0 0',
    '@media print': {
      display: 'none',
    },
  },
  ttlStyle01: {
    padding: '0 45px 0 5px',
    margin: '0 0 20px 0',
    fontSize: '25px',
    borderBottom: 'solid 1px #dcdcdc',
  },
  footerArea: {
    transform: 'translate(-50%, -50%)',
    '-webkit-transform': 'translate(-50%, -50%)',
    '-ms-transform': 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));
export interface LifeCSVOutputProps {
  location: any;
  refUsersList: any;
  minusMonthly: () => void;
  plusMonthly: () => void;
  allUsersPrintButtonClickHandler: () => void;
  date: moment.Moment;
  loaded: boolean;
  cachedUsers: UserListField.User[];
  toggleLifeCheck: boolean;
  toggleInterestCheck: boolean;
  toggleFunctionalTrainingPlan: boolean;
  toggleAdl: boolean;
  serviceCode: SERVICE_CODE;
  adlCode: ADL_CODE;
  switchToggleLifeCheck: (toggle: boolean) => void;
  switchToggleInterestCheck: (toggle: boolean) => void;
  switchToggleFunctionalTrainingPlan: (toggle: boolean) => void;
  switchToggleAdl: (toggle: boolean) => void;
  outputItemNames: CSV_OUTPUT_ITEMS['name'][];
  handleCheckBoxClick: (event: React.ChangeEvent<{ value: unknown }>) => void;
  selectBoxChanged: (value: React.ChangeEvent<{ value: unknown }>) => void;
  selectADLBoxChanged: (value: React.ChangeEvent<{ value: unknown }>) => void;
}

const LifeCSVOutput: FC<LifeCSVOutputProps> = ({
  location,
  refUsersList,
  minusMonthly,
  plusMonthly,
  allUsersPrintButtonClickHandler,
  date,
  loaded,
  cachedUsers,
  toggleInterestCheck,
  toggleLifeCheck,
  toggleFunctionalTrainingPlan,
  toggleAdl,
  serviceCode,
  adlCode,
  switchToggleInterestCheck,
  switchToggleLifeCheck,
  switchToggleFunctionalTrainingPlan,
  switchToggleAdl,
  outputItemNames,
  handleCheckBoxClick,
  selectBoxChanged,
  selectADLBoxChanged,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box
        component="article"
        position="relative"
        pb={{ xs: '230px', sm: '200px', md: '200px', lg: '150px' }}
        className={classes.mainBody}
      >
        <Box
          width="100%"
          height={{ xs: '230px', sm: '150px', md: '150px', lg: '150px' }}
          position="fixed"
          bottom={{ xs: '-120px', sm: '-80px', md: '-75px', lg: '-75px' }}
          left="50%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          // flexWrap={{ xs: 'wrap', sm: 'no-wrap' }}
          zIndex={2}
          className={classes.footerArea}
          border={{ xs: 'solid 1px #000', sm: 'solid 1px #000', md: 'solid 1px #000' }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexWrap={{ xs: 'wrap', sm: 'no-wrap' }}
            width={{ xs: '980px', sm: '980px', md: '980px' }}
          >
            <Box mb={{ xs: '15px', sm: 0 }} display="flex" alignItems="center">
              <Box
                width={{ xs: '140px', sm: '140px', md: '140px' }}
                alignItems="center"
                lineHeight="20px"
                mx={1}
                color="white"
                textAlign="right"
              >
                <b>出力項目選択:</b>
              </Box>
              <MultiSelectBox
                itemList={Object.keys(CSV_OUTPUT_ITEMS).map((key) => CSV_OUTPUT_ITEMS[key].name)}
                selectedItems={outputItemNames}
                checkBoxOnClick={handleCheckBoxClick}
                style={{
                  width: 250,
                  height: 70,
                  helperTextColor: '#fff',
                }}
                helperText={osJudgement() === 'PC' ? '' : '※ PC以外の端末では単数選択のみ'}
              />
            </Box>
            <Box mr={{ xs: 0, sm: '15px' }} ml="20px" display="flex" alignItems="center">
              <Box
                width={{ xs: '140px', sm: '140px', md: '140px' }}
                alignItems="center"
                lineHeight="20px"
                mx={1}
                color="white"
                textAlign="right"
              >
                <b>サービス区分選択:</b>
              </Box>
              <Box
                mr={{ sx: 0, sm: '60px' }}
                display="flex"
                alignItems="center"
                width={{ xs: '250px', sm: '250px', md: '250px' }}
              >
                <GeneralPrimarySelectBox
                  selectedValue={serviceCode.id}
                  size="medium"
                  style={{ width: 250, height: 50 }}
                  selectBoxChanged={selectBoxChanged}
                >
                  {Object.keys(SERVICE_CODE).map((key) => (
                    <MenuItem key={key} value={SERVICE_CODE[key].id}>
                      {`${SERVICE_CODE[key].id}: ${SERVICE_CODE[key].name}`}
                    </MenuItem>
                  ))}
                </GeneralPrimarySelectBox>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexWrap={{ xs: 'wrap', sm: 'no-wrap' }}
            flexDirection="row"
            width={{ xs: '980px', sm: '980px', md: '980px' }}
            mt="10px"
          >
            <Box mr={{ xs: 0, sm: '15px' }} display="flex" alignItems="center" width="100%">
              <Box
                width={{ xs: '140px', sm: '140px', md: '140px' }}
                alignItems="center"
                lineHeight="20px"
                mx={1}
                color="white"
                textAlign="right"
              >
                <b>ADL維持対象選択:</b>
              </Box>
              <Box mr={{ sx: 0, sm: '60px' }} display="flex" alignItems="center">
                <GeneralPrimarySelectBox
                  selectedValue={adlCode.id}
                  size="medium"
                  style={{ width: 250, height: 50 }}
                  selectBoxChanged={selectADLBoxChanged}
                >
                  {Object.keys(ADL_CODE).map((key) => (
                    <MenuItem key={key} value={ADL_CODE[key].id}>
                      {/*{`${ADL_CODE[key].id}: ${ADL_CODE[key].name}`}*/}
                      {`${ADL_CODE[key].name}`}
                    </MenuItem>
                  ))}
                </GeneralPrimarySelectBox>
              </Box>
              <Box mr={{ xs: 0, sm: '15px' }} ml="20px" display="flex" alignItems="center" width="250px">
                <GeneralPrimaryButton
                  txt="LIFE CSV出力"
                  setCss={'width: 250px; height: 50px;'}
                  onClick={allUsersPrintButtonClickHandler}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.headerTitle}>
          <p>CSV出力</p>
        </Box>
        <Box component="section" m="30px 0 0 0" p="30px 25px 0">
          <h2 className={classes.ttlStyle01}>LIFE CSV出力対象者リスト</h2>
          <p>出力項目とサービス区分を選択し、LIFE CSV出力ボタンをクリックしてください</p>
          <p>・「生活機能」「興味関心」は記録月、「個別機能訓練計画」は作成月のデータがある利用者名が表示されます</p>
          <p>・同月に複数回、記録・作成がある場合は、月内の最新の記録が出力されます</p>
          <br />
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Box mb={5}>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                displayPrint="none"
              >
                <Box alignItems="center" lineHeight="40px" mx={1}>
                  <b>表示項目選択:</b>
                </Box>
                <Box>
                  <ToggleButtonFour
                    isToggleLifeCheckOn={toggleLifeCheck}
                    isToggleInterestCheckOn={toggleInterestCheck}
                    isToggleFunctionalTrainingPlanOn={toggleFunctionalTrainingPlan}
                    isToggleAdlOn={toggleAdl}
                    toggleDisplayModeLifeCheck={switchToggleLifeCheck}
                    toggleDisplayModeInterestCheck={switchToggleInterestCheck}
                    toggleDisplayModeFunctionalTrainingPlan={switchToggleFunctionalTrainingPlan}
                    toggleDisplayModeAdl={switchToggleAdl}
                    leftText="生活機能"
                    rightText="興味関心"
                    thirdText="個別機能訓練計画"
                    fourthText="ADL維持等(BI)"
                    customCss={'width: 140px; height: 40px;'}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexWrap={{ xs: 'wrap', sm: 'no-wrap' }}
            data-test="monthly-header"
          >
            <Box m={{ xs: '0 0 10px', sm: '0 50px 25px' }} width={{ xs: '300px', sm: '350px' }}>
              <DateSelector
                date={date}
                className={''}
                dateDisplayType={DATE_DISPLAY_TYPE.Month}
                minusDate={minusMonthly}
                plusDate={plusMonthly}
              />
            </Box>
            <Box
              m={{ xs: '0 0 20px', sm: '0 0 25px' }}
              width="300px"
              fontSize="30px"
              textAlign="center"
              data-test="output-users-count"
            >
              <Box component="span" display="inline-block" m="0 0.2em 0 0.3em" height="16px" fontSize="16px">
                {'出力対象者　'}
              </Box>
              {cachedUsers ? cachedUsers.length : 0}
              <Box component="span" display="inline-block" m="0 0.2em 0 0.3em" height="16px" fontSize="16px">
                {'　名'}
              </Box>
            </Box>
          </Box>
          {loaded && !_.isEmpty(cachedUsers) ? (
            <UserList
              users={cachedUsers}
              invokedPath={location.pathname}
              currentMonth={date.format('YYYY-MM')}
              isCheckBox={false}
              isMemoColumn={false}
              isReportCheckColumn={false}
              ref={(instance) => {
                refUsersList.current = instance;
              }}
            />
          ) : (
            <Box data-test="not-exist-comment">
              <p>該当月は出力対象者が存在しません。</p>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default LifeCSVOutput;
