export enum MoffAPIActionType {
  LOADING = 'MoffAPI/LOADING',
  SUCCESS = 'MoffAPI/SUCCESS',
  ERROR = 'MoffAPI/ERROR',
  INITIALIZE = 'MoffAPI/INITIALIZE',
  DUPLICATE_REPEAT_SORT = 'MoffAPI/DUPLICATE_REPEAT_SORT',
  PRESS_DUPLICATE_REPEAT_SORT = 'MoffAPI/PRESS_DUPLICATE_REPEAT_SORT',
  ADD_DISPLAY_DATE = 'MoffAPI/ADD_DISPLAY_DATE',
  SORT_BY_KEY = 'MoffAPI/SORT_BY_KEY',
}

// ロコモ or ADL.
// ロコモ予防トレーニング.
export const LOCOMO_TYPE = 1;
// 日常生活動作トレーニング.
export const ADL_TYPE = 2;
