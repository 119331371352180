// ユーザー詳細.
import React, { FC, useState, useEffect, useRef, Fragment, useLayoutEffect } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';

import store from '../store/configureStore';
import { CreatePdfState } from 'store/reducers/pdfCreate';

import * as MoffAPIActions from '../actions/MoffAPI';
import * as HeaderActions from '../actions/Header';
import * as pdfCreateActions from '../actions/pdfCreate';

import * as Header from '../constants/Header';
import * as UserListField from '../constants/UserListField';
import * as ReportConst from '../constants/Report';

import { objectQuickSort, duplicateRepeatSort, dispatchIdentifierToGTM } from '../utils/commonUtil';
import { extractionCheckUsers, extractionChangedMemo } from '../utils/PressAllUsersPrintModule';

import PopupContainer from './Popup';

import PageLoader from '../components/Atoms/PageLoader';
import PressAllUsersPrint from '../components/Organisms/PressAllUsersPrint';

type AllState = ReturnType<typeof store.getState>;

const mapStateToProps = (state: AllState) => ({
  loaded: state.moffAPI.Loaded,
  memos: state.moffAPI.result.memos,
  users: state.moffAPI.result.users,
  result: state.moffAPI.result,
  pdfCreate: state.pdfCreate,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getUsersAndPressMemos: (yearMonth: string) => MoffAPIActions.getUsersAndPressMemos(yearMonth),
      pressDuplicateRepeatSort: () => MoffAPIActions.pressDuplicateRepeatSort(),
      getMoffUsersDetailData: (users: UserListField.User[], yearMonth: string, waitTimeMs: number) =>
        MoffAPIActions.getMoffUsersDetailData(users, yearMonth, waitTimeMs),
      putAllPressMemo: (memoArr: ReportConst.MemoForAPI[], yearMonth: string) =>
        MoffAPIActions.putAllPressMemo(memoArr, yearMonth),
      createPressPDF: (yearMonth: string, userIds: string[], comments: { user_id: string; text: string }[]) =>
        pdfCreateActions.createPressPDF(yearMonth, userIds, comments),
      initialize: () => MoffAPIActions.initialize(),
      setPage: (page: string) => HeaderActions.setPage(page),
    },
    dispatch,
  );

interface PressAllUsersPrintContainerProps extends RouteComponentProps {
  loaded: boolean;
  memos: ReportConst.Memo[];
  users: UserListField.User[];
  result: any;
  pdfCreate: CreatePdfState;
  getUsersAndPressMemos: (yearMonth: string) => Promise<void>;
  pressDuplicateRepeatSort: () => void;
  getMoffUsersDetailData: (users: UserListField.User[], yearMonth: string, waitTimeMs: number) => void;
  putAllPressMemo: (memoArr: ReportConst.MemoForAPI[], yearMonth: string) => Promise<void>;
  createPressPDF: (
    yearMonth: string,
    userIds: string[],
    comments: { user_id: string; text: string }[],
  ) => Promise<void>;
  initialize: () => void;
  setPage: (page: string) => void;
}

const PressAllUsersPrintContainer: FC<PressAllUsersPrintContainerProps> = ({
  location,
  loaded,
  memos,
  users,
  pdfCreate,
  getUsersAndPressMemos,
  pressDuplicateRepeatSort,
  putAllPressMemo,
  createPressPDF,
  setPage,
}) => {
  const [date, setDate] = useState(moment());
  const [cachedMemos, setChachedMemos] = useState<ReportConst.Memo[]>([] as ReportConst.Memo[]);
  const [cachedUsers, setCachedUsers] = useState<UserListField.User[]>([] as UserListField.User[]);
  const [gettingLastMonthMemos, setGettingLastMonthMemos] = useState(false);
  const [cachedCheckData, setCachedCheckData] = useState<UserListField.CheckData[]>([] as UserListField.CheckData[]);
  const [thisMonthMemoArr, setThisMonthMemoArr] = useState<ReportConst.Memo[]>([] as ReportConst.Memo[]);

  const refUsersList = useRef<any>();

  // 画面読み込み時の処理。ヘッダ情報の設定。モフトレ通信一括出力利用者リスト用の利用者・メモのデータを取得
  useEffect(() => {
    (async () => {
      document.title = 'モフトレ通信一括PDF出力';
      setPage(Header.PRESS_ALL_USER_PRINT);
      await getUsersAndPressMemos(date.format('YYYY-MM'));
      pressDuplicateRepeatSort();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // モフトレ通信一括出力利用者リスト用データ取得後、利用者のデータをローカルステートにキャッシュさせておく
  useEffect(() => {
    if (_.isEmpty(cachedUsers) && users) {
      setCachedUsers(duplicateRepeatSort(UserListField.USERS_SORT_KEYS, users, objectQuickSort));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, users]);

  // モフトレ通信一括出力利用者リスト用データ取得後、メモのデータをローカルステートにキャッシュさせておく
  useLayoutEffect(() => {
    // キャッシュさせておく.
    if (memos) {
      let memosArr = memos;

      if (gettingLastMonthMemos) {
        cachedCheckData.forEach((checkData) => {
          if (checkData.selected === false) {
            const checkDataMemo: ReportConst.Memo = {
              user_id: checkData.userId,
              year_month: date.format('YYYY-MM'),
              comment: checkData.memo ?? '',
              institution_sub: 'kari',
              created_at: 'kari',
              updated_at: 'kari',
            };
            memosArr = memosArr.filter((memo) => {
              return memo.user_id !== checkData.userId;
            });
            memosArr.push(checkDataMemo);
          }
        });
        setGettingLastMonthMemos(false);
        // dateの値が変更されているため戻す
        setDate(date.add(1, 'months'));
      } else {
        // 選択されている月のメモを保存
        setThisMonthMemoArr(memosArr);
      }

      setChachedMemos(memosArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memos]);

  // 月選択画面 矢印のアクション
  const minusMonthly = async () => {
    const lastMonth = date.subtract(1, 'months');
    setDate(lastMonth);
    await getUsersAndPressMemos(lastMonth.format('YYYY-MM'));
    dispatchIdentifierToGTM('all_users_change_month_press');
  };

  const plusMonthly = async () => {
    const nextMonth = date.add(1, 'months');
    setDate(nextMonth);
    await getUsersAndPressMemos(nextMonth.format('YYYY-MM'));
    dispatchIdentifierToGTM('all_users_change_month_press');
  };

  // nextに切り替える際、この中のコードをAPIリクエストに変更
  const allUsersPrintButtonClickHandler = async () => {
    // チェックボックスがonのユーザーのみを取得
    const checkedUsers: UserListField.User[] = refUsersList.current.getCheckedUsers();
    if (checkedUsers.length === 0) {
      alert('ユーザーが選択されていません');
      return;
    }
    const userIds = _.map(checkedUsers, (user) => user.user_id);
    const comments = _.map(checkedUsers, (user) => ({ user_id: user.user_id, text: user.memo }));
    await createPressPDF(date.format('YYYY-MM'), userIds, comments);

    dispatchIdentifierToGTM('output_all_users_report_press');
  };

  const getLastMonthMemos = async (checkDataArr: UserListField.CheckData[]) => {
    const checkUserArr = extractionCheckUsers(checkDataArr);
    if (!checkUserArr) {
      return;
    }
    if (
      window.confirm('チェックボックスにチェックを入れている全利用者の、前月のメモ内容が反映します。実行しますか？')
    ) {
      setGettingLastMonthMemos(true);
      // 入力されていたメモを保存
      setCachedCheckData(checkDataArr);
      const lastMonth = date.subtract(1, 'months');
      await getUsersAndPressMemos(lastMonth.format('YYYY-MM'))
        .then(() => {
          alert(
            '前月のメモの反映が完了しました。 \n※ 反映されたメモは保存されていません \n※ メモを確定させるためには「メモを保存」をクリックしてください',
          );
        })
        .catch(() => alert('前月のメモ内容反映に失敗しました'));
    }
    dispatchIdentifierToGTM('all_users_copy_last_month_memo_press');
  };

  // メモの内容を保存
  const saveAllMemoValue = async (checkDataArr: UserListField.CheckData[]) => {
    // 変更があったメモのみ取得
    const memoArr = extractionChangedMemo(checkDataArr, thisMonthMemoArr);
    if (memoArr) {
      if (window.confirm('メモを保存してよろしいですか？')) {
        await putAllPressMemo(memoArr, date.format('YYYY-MM') ?? '')
          .then(() => {
            alert('データを保存しました');
            getUsersAndPressMemos(date.format('YYYY-MM'));
          })
          .catch(() => alert('データの保存に失敗しました'));
      }
    } else {
      return;
    }
    dispatchIdentifierToGTM('all_users_save_memo_press');
  };

  const reportOutputButtonOnClick = (user: UserListField.User, currentMonth?: string) => {
    window.open(`/press/${user.user_id}/${currentMonth ?? ''}`, '_blank', 'noreferrer noopener');
    dispatchIdentifierToGTM('all_users_report_check_press');
  };

  /**
   * CSSの描画.
   */
  const RenderCSS: FC = () => {
    return (
      <style type="text/css">
        {/* 縦印刷, 日付・title名ヘッダの余白 */}
        {'@media print{@page {size: portrait; margin-top: 43px;}}'}
        {'@media print{#wrapper { padding:0; margin: 0; } header { display: none; }}'}
        {'@media print{.contentIn { padding-top:0!important; padding-bottom:0!important; }}'}
        {'@media print{.report { padding-bottom:0!important; }}'}
        {/* Edge対策 */}
        {'@media print{tr { page-break-inside: avoid; }}'}
      </style>
    );
  };

  return (
    <Fragment>
      <RenderCSS />
      <PopupContainer />
      {!loaded || pdfCreate.Loading ? <PageLoader /> : null}
      <PressAllUsersPrint
        location={location}
        refUsersList={refUsersList}
        minusMonthly={minusMonthly}
        plusMonthly={plusMonthly}
        allUsersPrintButtonClickHandler={allUsersPrintButtonClickHandler}
        getLastMonthMemos={getLastMonthMemos}
        saveAllMemoValue={saveAllMemoValue}
        reportOutputButtonOnClick={reportOutputButtonOnClick}
        date={date}
        loaded={loaded}
        cachedUsers={cachedUsers}
        cachedMemos={cachedMemos}
      />
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PressAllUsersPrintContainer);
