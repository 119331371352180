import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select, { SelectProps } from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { FormHelperText, Theme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 125,
  },
  formLabel: {
    margin: '0 10px 0',
    lineHeight: 0.38,
    fontSize: 14.5,
  },
  outlinedInput: {
    width: (props: { width?: string | number }) => props?.width ?? 350,
    height: (props: { height?: string | number }) => props?.height ?? 50,
    backgroundColor: '#fff',
  },
  helperText: {
    color: (props: { helperTextColor?: string }) => props?.helperTextColor ?? theme.palette.primary.main,
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
    },
  },
};

interface MultiSelectBoxProps {
  /** フォームのヘルパーテキスト */
  helperText?: string;
  /** フォームの項目名 */
  itemList: string[];
  /** フォームの値（配列） */
  selectedItems: string[];
  /** 外から注入するCSSスタイル */
  style?: {
    width?: string | number;
    height?: string | number;
    helperTextColor?: string;
  };
  /** 選択肢が追加された時のイベントハンドラ */
  checkBoxOnClick: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const MultiSelectBox: FC<MultiSelectBoxProps> = ({ helperText, itemList, selectedItems, style, checkBoxOnClick }) => {
  const classes = useStyles(style);

  return (
    <FormControl className={classes.formControl}>
      <Select
        labelId="demo-mutiple-checkbox-label"
        multiple
        value={selectedItems}
        onChange={checkBoxOnClick}
        input={<OutlinedInput className={classes.outlinedInput} />}
        renderValue={(selected: SelectProps['value']) => (
          <Box display="flex" flexWrap="wrap">
            {(selected as string[]).map((value) => (
              <Box key={value} mr="5px">
                <Chip
                  label={
                    <Typography
                      style={{
                        whiteSpace: 'normal',
                        width: '70px',
                        lineHeight: '1em',
                        fontSize: '1em',
                        textAlign: 'center',
                      }}
                    >
                      {value}
                    </Typography>
                  }
                  color="secondary"
                />
              </Box>
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        variant="outlined"
        data-test="multi-select-box"
      >
        {itemList.map((item) => (
          <MenuItem key={item} value={item}>
            <Checkbox checked={selectedItems.indexOf(item) > -1} />
            <ListItemText primary={item} data-test="multi-select-box-item-text" />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default MultiSelectBox;
