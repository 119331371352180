// モフトレチェック.
import * as MoffCheck from '../../constants/MoffCheck';

const initialState = {
  Loading: false,
  Loaded: false,
};

export function moffCheck(state: any = initialState, action: any) {
  switch (action.type) {
    case MoffCheck.SUCCESS:
      return Object.assign({}, state, {
        Loading: false,
        Loaded: true,
        result: action.result,
        is_error: false,
      });
    case MoffCheck.ERROR:
      return Object.assign({}, state, {
        Loading: false,
        Loaded: true,
        result: action.result,
        is_error: true,
      });
    case MoffCheck.START_LOAD:
      return Object.assign({}, state, {
        Loading: true,
        Loaded: false,
        is_error: false,
      });
    case MoffCheck.INITIALIZE:
      return Object.assign({}, state, {
        Loading: false,
        Loaded: false,
        is_error: false,
      });
    default:
      return state;
  }
}

// TUGと片足立ちのActionカウント用.
const countInitialState = {
  LoadedCount: 0,
};

export function moffCheckCount(state: any = countInitialState, action: any) {
  switch (action.type) {
    case MoffCheck.START_LOAD:
      return Object.assign({}, state, {
        type: MoffCheck.START_LOAD,
        // 0リセット.
        LoadedCount: 0,
      });
    case MoffCheck.FINISH:
      return Object.assign({}, state, {
        type: MoffCheck.FINISH,
        LoadedCount: state.LoadedCount + 1,
      });
    default:
      return state;
  }
}
