// Mobile用.
import React, { FC } from 'react';
import { User } from '../../../constants/UserListField';
import { ResponseData } from '../../../constants/InterestCheck';
import BaseBrowser from './BaseBrowser';
import DummyBaseBrowser from './DummyBaseBrowser';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { detect } from 'detect-browser';

const useStyles = makeStyles((theme) => ({
  checkSheet: {
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(0.46)',
      height: '350px',
      transformOrigin: (props: { transformOrigin: string }) => props.transformOrigin,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      transform: 'scale(0.80)',
      height: '600px',
      transformOrigin: (props: { transformOrigin: string }) => props.transformOrigin,
    },
    '@media print': {
      transform: 'scale(1)',
      height: '750px',
    },
    width: '700px',
  },
}));

interface MobileProps {
  user: User;
  interestCheck: ResponseData;
  loaded: boolean;
}

// 興味関心チェックシート.
const Mobile: FC<MobileProps> = ({ user, interestCheck, loaded }) => {
  const browser = detect();
  const styleProps = {
    transformOrigin: browser?.name === 'ie' ? '25% top' : 'center top',
  };
  const classes = useStyles(styleProps);

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box className={classes.checkSheet}>
          {loaded ? <BaseBrowser user={user} interestCheck={interestCheck} /> : <DummyBaseBrowser />}
        </Box>
      </Box>
      <style key="interest_check_print_css" type="text/css">
        {'body,div,table,thead,tbody,tfoot,tr,th,td,p { font-size: small; padding:0 }'}
        {'body { -webkit-print-color-adjust: exact; }'}

        {/* 通常は小さめ */}
        {'.interest_check_table { width: 700px; table-layout: fixed; }'}

        {/* 印刷時 */}
        {'@media print{ body {margin: 0;} @page { size: 21.0cm 29.7cm;' +
          ' margin: 0; } ' +
          ' .interest_check_table { table-layout: fixed; margin-left: 0; }' +
          ' #interestcheck { padding: 0; }' +
          // 非表示系.
          ' .kiroku_edit_tpl { display:none; }' +
          ' #blank { display:none; }' +
          ' header { display:none; }' +
          ' }'}

        {'.smallFont { font-size: xx-small; }'}

        {'.tableTitle { text-align: left; vertical-align: middle; }'}

        {'.hearing { text-align: right; vertical-align: middle; }'}

        {/* 名前 */}
        {'.nameTd {' +
          ' border-top: 2px solid black; border-bottom: 2px solid black;' +
          ' border-left: 2px solid black; border-right: 0px solid black;' +
          ' text-align: left; vertical-align: middle;}'}

        {/* 生年 */}
        {'.birthTd {' +
          ' border-top: 2px solid black; border-bottom: 2px solid black; border-left:' +
          ' 1px solid black; border-right: 0px solid black;' +
          ' text-align: left; vertical-align: middle;}'}
        {/* TODO: あとで歳を戻すためコメントアウト */}
        {/* ' width: 42.0%; }'} */}

        {/* 年齢 */}
        {'.ageTd {' +
          ' border-top: 2px solid black; border-bottom: 2px solid black; border-left: 1px solid black;' +
          ' border-right: 0px solid black;' +
          ' text-align: center; vertical-align: middle;' +
          ' width: 12%; }'}

        {/* 性別 */}
        {'.genderTd {' +
          ' border-top: 2px solid black; border-bottom: 2px solid black; border-left: 1px solid black;' +
          ' border-right: 2px solid black;' +
          ' text-align: center; vertical-align: middle; }'}

        {/* 最初の行 */}
        {'.topLeftTd {' +
          ' border-top: 2px solid black; border-bottom: 1px solid black; border-left: 2px solid black;' +
          ' border-right: 1px solid black;' +
          ' text-align: left; vertical-align: middle;' +
          ' width: 26%; }'}

        {'.topMiddleTd {' +
          ' border-top: 2px solid black; border-bottom: 1px solid black; border-left: 1px solid black;' +
          ' border-right: 0px solid black;' +
          ' text-align: center; vertical-align: middle;' +
          ' width: 8%; }'}

        {'.topRightTd {' +
          ' border-top: 2px solid black; border-bottom: 1px solid black; border-left: 1px solid black;' +
          ' border-right: 2px solid black;' +
          ' text-align: center; vertical-align: middle;' +
          ' width: 8%; }'}

        {/* 間系 */}
        {'.leftTd {' +
          ' border-top: 1px solid black; border-bottom: 0px solid black; border-left: 2px solid black;' +
          ' border-right: 1px solid black;' +
          ' text-align: left; vertical-align: middle; }'}

        {'.middleTd {' +
          ' border-top: 1px solid black; border-bottom: 0px solid black; border-left: 1px solid black;' +
          ' text-align: center; vertical-align: middle; }'}

        {'.rightTd {' +
          ' border-top: 1px solid black; border-bottom: 0px solid black; border-left: 1px solid black;' +
          ' border-right: 2px solid black;' +
          ' text-align: center; vertical-align: middle; }'}

        {/* 最後系 */}
        {'.bottomLeftTd {' +
          ' border-top: 1px solid black; border-bottom: 2px solid black; border-left: 2px solid black;' +
          ' border-right: 1px solid black;' +
          ' text-align: left; vertical-align: middle; }'}

        {'.bottomMiddleTd {' +
          ' border-top: 1px solid black; border-bottom: 2px solid black; border-left: 1px solid black;' +
          ' text-align: center; vertical-align: middle; }'}

        {'.bottomRightTd {' +
          ' border-top: 1px solid black; border-bottom: 2px solid black; border-left: 1px solid black;' +
          ' border-right: 2px solid black;' +
          ' text-align: center; vertical-align: middle; }'}
      </style>
    </>
  );
};

export default Mobile;
