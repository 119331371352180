/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC } from 'react';

const baseCss = (customCss: string, isDisable: boolean) => css`
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid ${isDisable ? 'lightgrey' : '#000'};
  outline: none;
  padding: 0;
  appearance: none;
  display: block;
  line-height: 40px;
  font-size: 0.9em;
  font-weight: bold;
  color: ${isDisable ? 'lightgrey' : '#000'};
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  ${customCss}
`;

interface GeneralPrimaryOutlineButtonProps {
  /** ボタンテキスト */
  text: string | JSX.Element;
  /** 外部から渡すスタイル */
  customCss: string;
  /** disableかどうか */
  isDisable?: boolean;
  /** クリックした時のイベントハンドラ */
  onClick?: () => void;
}

const GeneralPrimaryOutlineButton: FC<GeneralPrimaryOutlineButtonProps> = ({
  text,
  customCss,
  isDisable = false,
  onClick,
}) => {
  return (
    <button onClick={onClick} css={baseCss(customCss, isDisable)}>
      {text}
    </button>
  );
};

export default GeneralPrimaryOutlineButton;
