// Mobile用.
import React, { FC } from 'react';
import BaseBrowser from './BaseBrowser';
import { User } from '../../../constants/UserListField';
import { ResponseData } from '../../../constants/HomeVisit';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { detect } from 'detect-browser';

const useStyles = makeStyles((theme) => ({
  checkSheet: {
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(0.42)',
      height: '430px',
      transformOrigin: (props: { transformOrigin: string }) => props.transformOrigin,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      transform: 'scale(0.70)',
      height: '750px',
      transformOrigin: (props: { transformOrigin: string }) => props.transformOrigin,
    },
    '@media print': {
      transform: 'scale(0.9)',
      height: '950px',
    },
    transformOrigin: 'center top',
    width: '774px',
  },
}));

const RenderCSS = () => {
  return (
    <style type="text/css">
      {/* 縦印刷, 日付・title名は消す */}
      {'@media print{@page {size: A4; margin: 0; ' +
        'margin-top: 4mm; margin-left: 1cm; margin-right: 1cm; margin-bottom: 0mm; } ' +
        '#wrapper { margin-top: 0; } header { display: none; } ' +
        'body { padding: 8; } ' +
        '#homevisit { padding-left: 0; padding-right: 0; } ' +
        '#sec01 { display: none; }}'}
    </style>
  );
};

interface MobileProps {
  user: User;
  homeVisit: ResponseData;
}

// Mobile.
const Mobile: FC<MobileProps> = ({ user, homeVisit }) => {
  const browser = detect();
  const styleProps = {
    transformOrigin: browser?.name === 'ie' ? '25% top' : 'center top',
  };
  const classes = useStyles(styleProps);

  return (
    <Box display="flex" justifyContent="center">
      <Box className={classes.checkSheet}>
        <BaseBrowser user={user} homeVisit={homeVisit} />
        <RenderCSS />
      </Box>
    </Box>
  );
};

export default Mobile;
