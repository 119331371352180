import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import * as Header from '../constants/Header';
import { SCREEN_TYPE } from '../constants/Training';

import UserDetail from './UserDetail';
import TrainingHeader from './TrainingHeader';
import PopupContainer from './Popup';
import KirokuShowContainer from './KirokuShow';
import KirokuEditContainer from './KirokuEdit';

/**
 * CSSの描画.
 * A4横向き固定、ページはみ出る対策.
 * TODO: 現状維持で入れてあるが、本当に必要かどうか調査する必要あり
 */
const RenderCSS: FC = () => {
  return (
    <>
      <style type="text/css">
        {/* 縦印刷, 日付・title名ヘッダの余白 */}
        {'@media print{@page {size: portrait; margin-top: 43px;}}'}
        {'@media print{#wrapper { padding:0; margin: 0; } header { display: none; }}'}
        {'@media print{.contentIn { padding-top:0!important; padding-bottom:0!important; }}'}
        {'@media print{.report { padding-bottom:0!important; }}'}
        {/* Edge対策 */}
        {'@media print{tr { page-break-inside: avoid; }}'}
      </style>
    </>
  );
};

const KirokuContainer: FC<RouteComponentProps<{ userId: string }>> = ({ match }) => {
  const userId = match.params.userId;

  const className = 'report kiroku_tpl';

  const [screenState, setScreenState] = useState<SCREEN_TYPE>(SCREEN_TYPE.KirokuShow);
  const [date, setDate] = useState(moment());

  return (
    <>
      <RenderCSS />
      <PopupContainer />
      <article className={className}>
        <UserDetail
          userId={userId}
          isDisablePrint={true}
          headerType={Header.KIROKU}
          pageTitle="個別機能訓練に関する記録"
        />
        <TrainingHeader screenType={SCREEN_TYPE.KirokuShow} />
        {(() => {
          switch (screenState) {
            case SCREEN_TYPE.KirokuShow:
              return (
                <KirokuShowContainer userId={userId} date={date} setDate={setDate} setScreenState={setScreenState} />
              );
            case SCREEN_TYPE.KirokuEdit:
              return (
                <KirokuEditContainer userId={userId} date={date} setDate={setDate} setScreenState={setScreenState} />
              );
            default:
              return null;
          }
        })()}
      </article>
    </>
  );
};

export default KirokuContainer;
