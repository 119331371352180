/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC, Fragment, memo } from 'react';
import { zeroPadding } from '../../utils/dateUtil';

import { WEEK_NAMES } from '../../constants/Calendar';

import * as images from '../../img/index';
import { PressReportResponse } from '../../constants/Report';

/* カレンダーのスタイル */
const calenderTable = css`
  position: absolute;
  top: 12mm;
  left: 7mm;
  width: 76mm;
  thead {
    display: none;
  }
  td {
    width: 9mm;
    height: 8mm;
    img {
      width: 34px;
      height: 34px;
    }
  }
`;

const calenderDayPin = (done: boolean, num: string) => css`
  &:before {
    content: url(${done ? images[`day_${num}_done`] : images[`day_${num}`]});
  }
`;

interface PressCalendarProps {
  calendarData: PressReportResponse['data'][0]['calendar'];
  year: string;
  month: string;
}

// カレンダー部分.
const PressCalendar: FC<PressCalendarProps> = memo(({ calendarData, year, month }) => {
  const weekLines = [...Array(6)].map((_, i) => i);
  const weekLine = [...Array(7)].map((_, i) => i);
  // 1日の曜日
  const firstDay = new Date(Number(year), Number(month) - 1, 1).getDay();
  // 晦日の日にち
  const lastDate = new Date(Number(year), Number(month), 0).getDate();
  // 日にちのカウント
  let dayIdx = 0;

  return (
    <Fragment>
      <table css={calenderTable}>
        <thead>
          <tr>
            {WEEK_NAMES.map((week: string, i: number) => {
              return <th key={`week_${i}`}>{week}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {weekLines.map((w, index: number) => {
            const key = `calender${index}`;
            return (
              <tr key={key}>
                {weekLine.map((d, weekIndex: number) => {
                  const keyWeek = `calennderWeek${index}-${weekIndex}`;
                  // ないのはスペース.
                  if ((w === 0 && d < firstDay) || lastDate <= dayIdx) {
                    return <td key={keyWeek} />;
                  }
                  dayIdx++;
                  const num: string = zeroPadding(dayIdx);
                  return <td css={calenderDayPin(calendarData[dayIdx], num)} key={keyWeek} />;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Fragment>
  );
});

PressCalendar.displayName = 'pressCalendar';
export default PressCalendar;
