import { RouteProps } from 'react-router-dom';
import * as RequestParam from '../../constants/RequestParam';
import { loadAction } from 'actions/RequestParam';

export type LoadParamState = RouteProps['location'];

const initialState = {
  hash: '',
  pathname: '',
  search: '',
  state: {},
};

export default function loadParam(state: LoadParamState = initialState, action: loadAction): LoadParamState {
  switch (action.type) {
    case RequestParam.LOADED:
      return action.message;
    default:
      return state;
  }
}
