import { headerAction } from 'actions/Header';
import * as Header from '../../constants/Header';

export interface HeaderState {
  // タイトル
  title: string;
  // 左上のバック
  back_type: Header.HEADER_BACK_PAGE_TYPE;
  // 各機能ごとのprevious pageのurl
  back_url: string;
  // 右上の検索枠の有無
  is_search: boolean;
  // 右上の利用者設定の新規登録ボタンの有無
  is_register_btn: boolean;
  // 右上の利用者設定ボタンの有無
  is_user_setting_btn: boolean;
  // 右上のログアウトの有無
  is_logout: boolean;
  // モフトレ通信など特殊なのはヘッダなし.
  no_header: boolean;
  // 一括PDFボタン
  is_all_print_btn: boolean;
  // LifeCSV出力ボタン
  is_life_btn: boolean;
}

const initialState = {
  title: 'モフトレ・レポート',
  back_type: Header.HEADER_BACK_PAGE_TYPE.none,
  back_url: '',
  is_search: false,
  is_register_btn: false,
  is_user_setting_btn: false,
  is_logout: false,
  no_header: false,
  is_all_print_btn: false,
  is_life_btn: false,
};

// プロパティ名になる.
export default function header(state: HeaderState = initialState, action: headerAction) {
  switch (action.type) {
    case Header.LOGIN:
      return Object.assign({}, state, {
        title: 'モフトレ・レポート',
        back_type: Header.HEADER_BACK_PAGE_TYPE.none,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.MENU:
      return Object.assign({}, state, {
        title: 'レポートメニュー',
        back_type: Header.HEADER_BACK_PAGE_TYPE.none,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: true,
        is_life_btn: true,
        is_logout: true,
      });
    case Header.USER_SELECT_MOFF_TRAINING:
      return Object.assign({}, state, {
        title: '利用者一覧(トレーニング実施記録（表・グラフ）)',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backMenu,
        is_search: true,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.MOFF_TRAINING:
      return Object.assign({}, state, {
        title: 'トレーニング実施記録（表・グラフ）',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        back_url: '/user_select_moff_training',
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.USER_SELECT_KIROKU:
      return Object.assign({}, state, {
        title: '個別機能訓練に関する記録',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backMenu,
        is_search: true,
        is_all_print_btn: true,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.KIROKU_ALL_USER_PRINT:
      return Object.assign({}, state, {
        title: '一括PDF出力（個別機能訓練）',
        // スラッシュ必須.
        back_url: '/user_select_kiroku',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.KIROKU_ALL_USER_SAVE:
      return Object.assign({}, state, {
        title: '一括入力（個別機能訓練）',
        // スラッシュ必須.
        back_url: '/user_select_kiroku',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.KIROKU:
      return Object.assign({}, state, {
        title: '個別機能訓練に関する記録',
        // スラッシュ必須.
        back_url: '/user_select_kiroku',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.USER_SELECT_PRESS:
      return Object.assign({}, state, {
        title: '利用者一覧(モフトレ通信)',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backMenu,
        is_search: true,
        is_all_print_btn: true,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.PRESS_SELECT_MONTH:
      return Object.assign({}, state, {
        title: 'モフトレ通信',
        // スラッシュ必須.
        back_url: '/user_select_press',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.PRESS_ALL_USER_PRINT:
      return Object.assign({}, state, {
        title: '一括PDF出力（モフトレ通信）',
        // スラッシュ必須.
        back_url: '/user_select_press',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.PRESS:
      return Object.assign({}, state, {
        title: 'モフトレ通信',
        back_type: Header.HEADER_BACK_PAGE_TYPE.none,
        is_search: false,
        is_register_btn: false,
        is_logout: false,
        is_user_setting_btn: false,
        no_header: true,
      });
    case Header.USER_SETTING:
      return Object.assign({}, state, {
        title: '利用者設定',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backMenu,
        is_search: true,
        is_register_btn: true,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.REGISTER_USER_SETTING:
      return Object.assign({}, state, {
        title: '利用者登録',
        // スラッシュ必須.
        back_url: '/user_setting',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.USER_SELECT_MOFF_CHECK:
      return Object.assign({}, state, {
        title: '利用者一覧(モフトレチェック)',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backMenu,
        is_search: true,
        is_all_print_btn: true,
        is_logout: false,
      });
    case Header.EDIT_USER_SETTING:
      return Object.assign({}, state, {
        title: '利用者編集',
        // スラッシュ必須.
        back_url: '/user_setting',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.MOFF_CHECK_SELECT_MONTH:
      return Object.assign({}, state, {
        title: 'モフトレチェック',
        // スラッシュ必須.
        back_url: '/user_select_moff_check',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_logout: false,
      });
    case Header.MOFF_CHECK_ALL_USER_PRINT:
      return Object.assign({}, state, {
        title: '一括PDF出力（モフトレチェック）',
        // スラッシュ必須.
        back_url: '/user_select_moff_check',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.USER_INTEREST_CHECK:
      return Object.assign({}, state, {
        title: '利用者一覧(興味関心チェック)',
        // スラッシュ必須.
        back_url: '/user_setting',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backMenu,
        is_search: true,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.INTEREST_CHECK_PRINT:
      return Object.assign({}, state, {
        title: '興味関心チェックシート',
        // スラッシュ必須.
        back_url: '/user_interest_check',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.HOME_VISIT:
      return Object.assign({}, state, {
        title: '利用者一覧(居宅訪問)',
        back_url: '/user_setting',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backMenu,
        is_search: true,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.HOME_VISIT_PRINT:
      return Object.assign({}, state, {
        title: '居宅訪問チェックシート',
        // スラッシュ必須.
        back_url: '/home_visit',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.USER_SELECT_LIFE_CHECK:
      return Object.assign({}, state, {
        title: '利用者一覧(生活機能チェック)',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backMenu,
        is_search: true,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.LIFE_CHECK_PRINT:
      return Object.assign({}, state, {
        title: '生活機能チェックシート',
        back_url: '/user_select_life_check',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.USER_SELECT_LITERALLY_REPORT:
      return Object.assign({}, state, {
        title: '利用者一覧(機能訓練レポート)',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backMenu,
        is_search: true,
        is_all_print_btn: true,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.LITERALLY_REPORT_SELECT_MONTH:
      return Object.assign({}, state, {
        title: '機能訓練レポート',
        // スラッシュ必須.
        back_url: '/user_select_literally_report',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_logout: false,
      });
    case Header.LITERALLY_REPORT_ALL_USER_PRINT:
      return Object.assign({}, state, {
        title: '一括PDF出力（機能訓練レポート）',
        // スラッシュ必須.
        back_url: '/user_select_literally_report',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.LIFE_CSV_OUTPUT:
      return Object.assign({}, state, {
        title: 'LIFE CSV出力',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backMenu,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.SETTING:
    case Header.SETTING_FORGOT_PASSWORD_CODE:
      return Object.assign({}, state, {
        title: '管理',
        back_type: Header.HEADER_BACK_PAGE_TYPE.none,
        is_search: false,
        is_logout: true,
      });
    case Header.SETTING_CHANGE_PASSWORD:
    case Header.SETTING_FORGOT_PASSWORD:
      return Object.assign({}, state, {
        title: '管理',
        back_url: '/settings.html',
        back_type: Header.HEADER_BACK_PAGE_TYPE.setting,
        is_search: false,
        is_logout: true,
      });
    case Header.MOFF_RAKU_USERS:
      return Object.assign({}, state, {
        title: '利用者基本情報入力(モフらく計画書)',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backMenu,
        is_search: true,
        is_all_print_btn: true,
        is_logout: false,
      });
    case Header.USER_SELECT_MOFF_RAKU:
      return Object.assign({}, state, {
        title: '利用者一覧(モフらく計画書)',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backMenu,
        is_search: true,
        is_all_print_btn: true,
        is_logout: false,
      });
    case Header.MOFF_RAKU_LIST:
      return Object.assign({}, state, {
        title: '計画書一覧(モフらく計画書)',
        // スラッシュ必須.
        back_url: '/user_select_moff_raku',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    case Header.MOFF_RAKU_FORM:
      return Object.assign({}, state, {
        title: '計画書作成(モフらく計画書)',
        // スラッシュ必須.
        back_url: '/user_select_moff_raku',
        back_type: Header.HEADER_BACK_PAGE_TYPE.backUserList,
        is_search: false,
        is_register_btn: false,
        is_user_setting_btn: false,
        is_logout: false,
      });
    default:
      return state;
  }
}
