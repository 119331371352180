// sign-in時のアクションタイプ
export const LOADING_SIGN_IN = 'LOADING_SIGN_IN';
export const SUCCESS_SIGN_IN = 'SUCCESS_SIGN_IN';
export const ERROR_SIGN_IN = 'ERROR_SIGN_IN';

// session時のアクションタイプ
export const LOADING_SESSION = 'LOADING_SESSION';
export const SUCCESS_SESSION = 'SUCCESS_SESSION';
export const ERROR_SESSION = 'ERROR_SESSION';

// sign-out時のアクションタイプ
export const LOADING_SIGN_OUT = 'LOADING_SIGN_OUT';
export const SUCCESS_SIGN_OUT = 'SUCCESS_SIGN_OUT';
export const ERROR_SIGN_OUT = 'ERROR_SIGN_OUT';

// auth初期化時のアクションタイプ
export const AUTH_INITIALIZE = 'AUTH_INITIALIZE';
