import React, { createContext, Dispatch, ReactNode, useContext } from 'react';

export const moffCheckContext = createContext({});
const { Provider } = moffCheckContext;

// export const useMoffCheckProvider = () => {
//   const [isToggleOn, setIsToggleOn] = useState<boolean>(false);
//   return {
//     isToggleOn,
//     setIsToggleOn,
//   };
// };

export const MoffCheckProvider = (props: {
  children: ReactNode;
  isToggleOn: boolean;
  setIsToggleOn: Dispatch<boolean>;
}): JSX.Element => {
  // const moffCheck = useMoffCheckProvider();
  return (
    <Provider value={{ isToggleOn: props.isToggleOn, setIsToggleOn: props.setIsToggleOn }}>{props.children}</Provider>
  );
};
export const useMoffCheck = () => {
  return useContext(moffCheckContext) as useMoffCheckProvider;
};

type useMoffCheckProvider = { isToggleOn: boolean; setIsToggleOn: Dispatch<boolean> };
// & ReturnType<typeof useMoffCheckProvider>
