/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FC, Fragment } from 'react';

const checkBoxOriginalinput = css`
  display: none;
`;

const checkMark = css`
  &:before {
    color: #7c644e;
    content: '\\02714';
  }
`;

const checkBox = (isCheckMarkDisplayed: boolean) => css`
  all: initial;
  display: inline-block;
  line-height: 1em;
  width: 22px;
  height: 22px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #969696;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(34, 36, 38, 0.15);
  font-size: 20px;
  ${isCheckMarkDisplayed ? checkMark : null}
`;

interface GeneralPrimaryCheckBoxProps {
  /** チェックボックスのID */
  checkBoxId?: number;
  /** チェックボックスの状態 */
  checked: boolean;
  /** チェックボックスが不確定の状態 */
  indeterminate?: boolean;
  /** クリックした時のイベントハンドラ */
  onChange: (event?: any, data?: any) => void;
}

const GeneralPrimaryCheckBox: FC<GeneralPrimaryCheckBoxProps> = ({
  checkBoxId = 0,
  checked,
  indeterminate = false,
  onChange,
}) => {
  const isCheckMarkDisplayed = !indeterminate && checked;
  return (
    <Fragment>
      <label>
        <input
          id={checkBoxId.toString()}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          ref={(el) => el && (el.indeterminate = indeterminate)}
          css={checkBoxOriginalinput}
        />
        <span css={checkBox(isCheckMarkDisplayed)} data-test="general-primary-check-box" />
      </label>
    </Fragment>
  );
};

export default GeneralPrimaryCheckBox;
