import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { getDisplayTrainingMenus, omitUnIntroducedFromTrainingMenu, sortByTrainingId } from '../utils/locomoAdlModule';
import { INITIAL_TRAINING_NAMES, TOGGLE_POSITION, TrainingInfo, TRAINING_REPORT_INFO } from '../constants/Training';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

export const trainingUIContext = createContext({});
const { Provider } = trainingUIContext;

export const useTrainingUIProvider = (initialUserId: string) => {
  const [userId, setUserId] = useState<string>(initialUserId);

  // 1度目のキャッシュ用. 左右ボタン押下の時に毎回APIアクセスする必要のないマスター的なデータ.
  const [cachedTrainingMenus, setCachedTrainingMenus] = useState<TrainingInfo[]>([]);
  const [allTrainings, setAllTrainings] = useState<TrainingInfo[]>([]);
  const [displayedTrainings, setDisplayedTrainings] = useState<string[]>([...INITIAL_TRAINING_NAMES]);
  const [isToggleOn, setIsToggleOn] = useState<boolean>(false);
  const [togglePosition, setTogglePosition] = useState<TOGGLE_POSITION>(TOGGLE_POSITION.Left);
  const [startTogglePosition, setStartTogglePosition] = useState<TOGGLE_POSITION>(TOGGLE_POSITION.Left);
  const [endTogglePosition, setEndTogglePosition] = useState<TOGGLE_POSITION>(TOGGLE_POSITION.Left);
  const [longTermtogglePosition, setLongTermtogglePosition] = useState<TOGGLE_POSITION>(TOGGLE_POSITION.Left);

  useEffect(() => {
    setUserId(initialUserId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cachedTrainingMenus) {
      const allTrainings = getDisplayTrainingMenus(cachedTrainingMenus);
      const allTrainingsOmitUnIntroduce = omitUnIntroducedFromTrainingMenu(allTrainings);
      setAllTrainings(allTrainingsOmitUnIntroduce);
    }
  }, [cachedTrainingMenus]);

  const addDisplayTraining = (trainingInfo: TrainingInfo) => {
    if (displayedTrainings.length < 9) {
      const nextTrainings = [...displayedTrainings, trainingInfo.name];
      const sortedTrainings = sortByTrainingId(cachedTrainingMenus, nextTrainings);
      setDisplayedTrainings(sortedTrainings);

      // GTM
      let trainingName: TRAINING_REPORT_INFO['name'];
      let trainingKey: TRAINING_REPORT_INFO['key'];
      if (displayedTrainings.length < nextTrainings.length) {
        trainingName = _.differenceWith(
          nextTrainings,
          displayedTrainings,
          _.isEqual,
        )[0] as TRAINING_REPORT_INFO['name'];
        trainingKey = _.filter(TRAINING_REPORT_INFO, (info: TRAINING_REPORT_INFO) => info.name === trainingName)?.[0]
          ?.key;
        dispatchIdentifierToGTM(`training_customize_add_${trainingKey}`);
      }
    }
  };

  const removeDisplayTraining = (trainingInfo: TrainingInfo) => {
    if (_.some(displayedTrainings, (displayedTraining: string) => displayedTraining === trainingInfo.name)) {
      setDisplayedTrainings(
        _.filter(displayedTrainings, (displayedTraining: string) => displayedTraining !== trainingInfo.name),
      );

      // GTM
      const trainingKey = _.filter(
        TRAINING_REPORT_INFO,
        (info: TRAINING_REPORT_INFO) => info.name === trainingInfo.name,
      )?.[0]?.key;
      dispatchIdentifierToGTM(`training_customize_remove_${trainingKey}`);
    }
  };

  const changeTogglePosition = (className: string) => (state: TOGGLE_POSITION) => {
    switch (className) {
      case 'startReport': {
        setStartTogglePosition(state);
        break;
      }
      case 'endReport': {
        setEndTogglePosition(state);
        break;
      }
      default: {
        break;
      }
    }
  };

  return {
    userId,
    cachedTrainingMenus,
    displayedTrainings,
    allTrainings,
    isToggleOn,
    togglePosition,
    startTogglePosition,
    endTogglePosition,
    longTermtogglePosition,
    setCachedTrainingMenus,
    setDisplayedTrainings,
    addDisplayTraining,
    removeDisplayTraining,
    setIsToggleOn,
    setTogglePosition,
    changeTogglePosition,
    setLongTermtogglePosition,
  };
};

export const TrainingUIProvider = (props: { children: ReactNode; initialUserId: string }): JSX.Element => {
  const training = useTrainingUIProvider(props.initialUserId);
  return <Provider value={training}>{props.children}</Provider>;
};
export const useTrainingUI = () => {
  return useContext(trainingUIContext) as useTrainingUIProvider;
};

type useTrainingUIProvider = ReturnType<typeof useTrainingUIProvider>;
