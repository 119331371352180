import React, { FC, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  maintenance: {
    display: 'flex',
    '& article': {
      display: 'block',
      width: '650px',
      margin: '24px auto',
      overflow: 'hidden',
      textAlign: 'left',
      backgroundColor: '#fff',
      borderRadius: '6px',
      '& h1': {
        padding: '8px',
        backgroundImage: 'linear-gradient(0deg, #ec9f35 0%, #edac36 100%)',
        backgroundRepeat: 'no-repeat',
      },
      '& p': {
        padding: '8px',
      },
    },
  },
}));

const Maintainance: FC = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'メンテナンス';
  }, []);

  return (
    <Box className={classes.maintenance}>
      <article>
        <h1>メンテナンス中です</h1>
        <Box>
          <p>申し訳ありません。只今メンテナンス中です。</p>
        </Box>
      </article>
    </Box>
  );
};

export default Maintainance;
