// 環境チェックAPI.
import { AxiosInstance, AxiosResponse } from 'axios';
import * as _ from 'lodash';
import moment from 'moment';

import { MoffAPIActionType } from '../constants/MoffAPI';
import { ResponseData } from '../constants/AppUser';
import { MoffAPI } from './MoffAPI';
import { createAxiosInstance } from '../constants/AWS';
import * as LifeCheck from '../constants/LifeCheck';
import { errorFunc, apiRequestFunc } from '../utils/apiUtil';
import { waitTimeMs, isEmpty } from '../utils/commonUtil';
import { getAppUserData } from './AppUser';
import { convertFormatTimeToDate } from '../utils/dateUtil';
import { DisplayPayload } from '../constants/UserListField';
import { DATA_CHECK_INTERVAL } from '../constants/csvCreate';

export class LifeCheckAPI {
  /** インスタンス */
  private static _instance: LifeCheckAPI;
  private axiosInstance!: AxiosInstance;

  /** インスタンスの取得 */
  public static get instance(): LifeCheckAPI | null {
    // _inctanceが存在しない場合に、new User()を実行する。
    if (!this._instance) {
      this._instance = new LifeCheckAPI();

      // .envの設定
      const API_BASE_URL = String(process.env.REACT_APP_LIFE_CHECK_API_URL);
      const API_KEY = String(process.env.REACT_APP_LIFE_CHECK_API_KEY);
      if (typeof API_BASE_URL === 'undefined') {
        console.log('.envにREACT_APP_LIFE_CHECK_API_URLの設定がありません.');
        return null;
      }
      if (typeof API_KEY === 'undefined') {
        console.log('.envにREACT_APP_LIFE_CHECK_API_KEYの設定がありません.');
        return null;
      }
      const axiosInstance = createAxiosInstance(API_BASE_URL, API_KEY);

      if (axiosInstance) {
        this._instance.axiosInstance = axiosInstance;
      } else {
        return null;
      }
    }

    // 生成済みのインスタンスを返す
    return this._instance;
  }

  // 件数取得.
  public async getRecordCountAPI(uniqueId: string) {
    const url = `/lifecheck/records/${uniqueId}`;
    return this.axiosInstance.get(url);
  }

  public async getLifecheckFunc(uniqueId: string, startDate: string, endDate: string) {
    return this.axiosInstance.get(`/lifecheck/records/${uniqueId}/from/${startDate}/to/${endDate}`);
  }

  /**
   * 指定の1件を取得.
   *
   * @param {string} userId
   * @param {string} uniqueId
   * @param {number} sequence
   * @returns {*}
   * @memberof LifeCheck
   */
  public getRecordBySequenceAPI(userId: string, uniqueId: string, sequence: number) {
    const moff = MoffAPI.instance;
    if (moff === null) {
      return errorFunc(MoffAPIActionType.ERROR, '不明なエラーが発生しました.');
    }

    const uniqueLogicFunc: any = async () => {
      const userDataMoff = await moff.getUserFunc(userId).then((response) => response.data);
      // ここの sequenceには、前はtotalが入っていたが、sequenceの最大値を取得するように変更した 2024/03/08
      const url = `/lifecheck/records/${uniqueId}/sequence/${sequence}`;
      // データ取得で 該当sequence番号のデータを削除すると 404 が返ってくるので、空のデータを返すように修正 2024/03/08
      try {
        const lifeCheckData = await this.axiosInstance.get(url).then((response) => response.data);
        return _.zipObject(['user', 'lifeCheck'], [userDataMoff, lifeCheckData]);
      } catch (e) {
        console.log(e);
        return _.zipObject(['user', 'lifeCheck'], [userDataMoff, {}]);
      }
    };

    return apiRequestFunc(
      MoffAPIActionType.LOADING,
      MoffAPIActionType.SUCCESS,
      MoffAPIActionType.ERROR,
      uniqueLogicFunc,
    );
  }

  /**
   * 指定月の全利用者データを取得.
   *
   * @param {ResponseData[]} uniqueIds
   * @param {string} month
   * @returns {*}
   * @memberof LifeCheck
   */
  public getRecordByMonthAPI(uniqueIds: ResponseData[], month: string) {
    const uniqueLogicFunc = async () => {
      const fromDate = moment(month).startOf('month').format('YYYY-MM-DD');
      const toDate = moment(month).endOf('month').format('YYYY-MM-DD');
      const data = await Promise.all(
        uniqueIds.map(async (uniqueId: ResponseData, i) => {
          await waitTimeMs(i * DATA_CHECK_INTERVAL);
          const url = `/lifecheck/records/${uniqueId.unique_id}/from/${fromDate}/to/${toDate}`;
          const data = await this.axiosInstance.get(url).then((response) => response.data);
          return {
            userId: uniqueId.app_user_id,
            data,
          };
        }),
      );
      const monthUsersRecords = data.filter((v) => !isEmpty(v.data));
      return {
        monthUsersRecords,
      };
    };

    return apiRequestFunc(LifeCheck.LOADING, LifeCheck.SUCCESS, LifeCheck.ERROR, uniqueLogicFunc);
  }

  /**
   * APPユーザの登録が存在するか確認.
   * あれば最新のレコードから訪問日を取得
   *
   * @param {string} userId
   * @param {(payload: DisplayPayload) => void} addDisplayDate
   * @returns {*}
   * @memberof AppUserAPI
   */
  public getLifeCheckLastDateAPI(
    userId: string,
    addDisplayDate: (payload: DisplayPayload) => void,
    intervalTimeMs = 500,
  ) {
    const uniqueLogicFunc = async () => {
      await waitTimeMs(intervalTimeMs);
      const appUser = await getAppUserData(userId, LifeCheck.FINISH);
      const emptyDisplayDate = { user_id: userId, displayDate: '' };

      if (!appUser) {
        addDisplayDate(emptyDisplayDate);
        return emptyDisplayDate;
      } else {
        const getLatestRecordURL = `/lifecheck/records/${appUser.data.unique_id}/latest`;
        return this.axiosInstance
          .get(getLatestRecordURL)
          .then((recordResponse: AxiosResponse<LifeCheck.ResponseData>) => {
            // 最新日
            const updateDate = convertFormatTimeToDate(recordResponse.data?.visited_time_start) ?? '';
            const updatedDisplayDate = { ...emptyDisplayDate, displayDate: updateDate };
            addDisplayDate(updatedDisplayDate);
            return updatedDisplayDate;
          })
          .catch(() => {
            addDisplayDate(emptyDisplayDate);
            return emptyDisplayDate;
          });
      }
    };
    return apiRequestFunc(LifeCheck.LOADING, LifeCheck.FINISH, LifeCheck.FINISH, uniqueLogicFunc);
  }
}

export const createLifeCheckAPIInstance: any = () => {
  // Moffインスタンスが存在しない場合、エラーアクションを返す
  return LifeCheckAPI.instance === null
    ? [true, null, errorFunc(MoffAPIActionType.ERROR, '不明なエラーが発生しました.')]
    : [false, LifeCheckAPI.instance, null];
};
