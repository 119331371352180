import React, { FC } from 'react';
import { osJudgement } from '../../utils/commonUtil';

import VideoButton from '../Atoms/VideoButton';
import { Box } from '@material-ui/core';

interface FooterProps {
  isPcPrintDisplay: boolean;
  openPcPrint: () => void;
}

const Footer: FC<FooterProps> = ({ isPcPrintDisplay, openPcPrint }) => {
  return (
    <>
      {(() => {
        if (osJudgement() !== 'PC' && isPcPrintDisplay) {
          return (
            <Box
              position="fixed"
              bottom={{ xs: '30px', sm: '30px', md: '60px' }}
              left={{ xs: '30px', sm: '30px', md: '60px' }}
            >
              <VideoButton onClick={openPcPrint} text={'印刷方法を確認'} />
            </Box>
          );
        }
        return;
      })()}
    </>
  );
};

export default Footer;
