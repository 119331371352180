/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import store from '../store/configureStore';

import * as PasswordActions from '../actions/Password';
import GeneralPrimaryButton from '../components/Atoms/GeneralPrimaryButton';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

const input = css`
  width: 350px;
  margin: 25px 15px;
  padding: 12px 8px;
  border: 0;
  border: solid 1px #8c8c8c;
  border-radius: 4px;
  font-size: 20px;
  outline: 0;
  &:focus {
    border: solid 1px #ea6e37;
  }
`;

const th = css`
  font-size: 14px;
  text-align: right;
`;

type AllState = ReturnType<typeof store.getState>;
const selector = formValueSelector('changePasswordForm');

const ChangePasswordForm: React.FC = () => {
  const dispatch = useDispatch();
  const state = useSelector((state: AllState) => state);

  const oldPassword = selector(state, 'oldPassword');
  const newPassword = selector(state, 'newPassword');
  const confirmation = selector(state, 'confirmation');

  const changePasswordForm = {
    oldPassword,
    newPassword,
    confirmation,
  };

  const handleSubmit = async () => {
    if (typeof changePasswordForm === 'undefined') {
      return;
    }

    // undefinedになってた時に最低空文字にしてcognite側で判断させる
    const oldPassword = changePasswordForm.oldPassword || '';
    const newPassword = changePasswordForm.newPassword || '';
    const confirmation = changePasswordForm.confirmation || '';

    await dispatch(
      PasswordActions.changePassword(oldPassword, newPassword, confirmation, (res: any, message: string) => {
        if (res === false) {
          alert(message);
        }
      }),
    );
    dispatchIdentifierToGTM('change_password');
  };

  return (
    <div>
      <div>
        <h2>パスワードを変更する</h2>
        <form>
          <table>
            <tbody>
              <tr>
                <th css={th}>現在のパスワード</th>
                <td>
                  <Field
                    css={input}
                    name="oldPassword"
                    type="password"
                    component="input"
                    placeholder="現在のパスワード"
                    required={true}
                    autoFocus={true}
                  />
                </td>
              </tr>
              <tr>
                <th css={th}>変更後のパスワード</th>
                <td>
                  <Field
                    css={input}
                    name="newPassword"
                    type="password"
                    component="input"
                    placeholder="変更後のパスワード"
                    required={true}
                  />
                </td>
              </tr>
              <tr>
                <th css={th}>(確認用)変更後のパスワード</th>
                <td>
                  <Field
                    css={input}
                    name="confirmation"
                    type="password"
                    component="input"
                    placeholder="(確認用)変更後のパスワード"
                    required={true}
                  />
                </td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <td>
                  <GeneralPrimaryButton
                    txt="パスワード変更"
                    setCss="width: 240px; height: 60px; margin: 25px auto;"
                    onClick={() => handleSubmit()}
                    type="button"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'changePasswordForm',
})(ChangePasswordForm as any);
