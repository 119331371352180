import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store/configureStore';

import * as HeaderActions from '../actions/Header';
import * as MoffAPIActions from '../actions/MoffAPI';
import * as Header from '../constants/Header';
import PopupContainer from './Popup';
import UserSetting from '../components/Organisms/UserSetting';

type AllState = ReturnType<typeof store.getState>;

const UserSettingContainer: FC = () => {
  const dispatch = useDispatch();
  const moffAPI = useSelector((state: AllState) => state.moffAPI);

  useEffect(() => {
    (async () => {
      document.title = '利用者設定';
      dispatch(HeaderActions.setPage(Header.USER_SETTING));
      await dispatch(MoffAPIActions.getUsers());
      dispatch(MoffAPIActions.duplicateRepeatSort());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PopupContainer />
      <UserSetting moffAPI={moffAPI} />
    </>
  );
};

export default UserSettingContainer;
