import * as Popup from '../../constants/Popup';

const initialState: Popup.Popup = {
  pcPrint: false,
  literallyReport: false,
  moffCheck: false,
  ADLLocomo: false,
  kiroku: false,
  press: false,
  userSetting: false,
};

export default function popup(state: Popup.Popup = initialState, action: any) {
  switch (action.type) {
    case Popup.OPEN_PCPRINT:
      return Object.assign({}, state, {
        pcPrint: true,
      });
    case Popup.OPEN_LITERALLYREPORT:
      return Object.assign({}, state, {
        literallyReport: true,
      });
    case Popup.OPEN_MOFFCHECK:
      return Object.assign({}, state, {
        moffCheck: true,
      });
    case Popup.OPEN_ADLLOCOMO:
      return Object.assign({}, state, {
        ADLLocomo: true,
      });
    case Popup.OPEN_KIROKU:
      return Object.assign({}, state, {
        kiroku: true,
      });
    case Popup.OPEN_PRESS:
      return Object.assign({}, state, {
        press: true,
      });
    case Popup.OPEN_USER_SETTING:
      return Object.assign({}, state, {
        userSetting: true,
      });
    case Popup.CLOSE_POPUP:
      return Object.assign({}, state, {
        pcPrint: false,
        literallyReport: false,
        moffCheck: false,
        ADLLocomo: false,
        kiroku: false,
        press: false,
        userSetting: false,
      });
    default:
      return state;
  }
}
