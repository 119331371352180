/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Fragment, FC, memo } from 'react';

const spin = keyframes`
  0% {
    opacity: 0.2;
    transform: rotate(0deg);
  } /* 0%の時は20％の透明度 */
  50% {
    opacity: 1;
    transform: rotate(180deg);
  } /* 50%の時は透明度なし */
  100% {
    opacity: 0.2;
    transform: rotate(360deg);
  } /* 100%の時に20％の透明度に戻る */
`;

const loader = css`
  z-index: 1000;
  border: 8px solid #fff; /* 円に○の白枠をつける */
  border-right-color: transparent; /* 円の右にC状の空きをつける */
  border-radius: 50%; /* CSS3で円を書く */
  animation: ${spin} 1s linear infinite; /* 1秒毎にくるくる回転するアニメーション */
`;

const loader_back = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  filter: alpha(opacity=50);
  background-repeat: repeat;
  background-position: top center;
  -moz-background-size: cover;
  background-size: cover;
  -moz-opacity: 0.6;
  opacity: 0.6;
`;

const PageLoader: FC = () => {
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
  };

  return (
    <Fragment>
      <Box display="flex" justifyContent="center" alignItems="center" css={loader_back} data-test="page-loader">
        <Box width={matches.xs ? '100px' : '200px'} height={matches.xs ? '100px' : '200px'} css={loader} />
      </Box>
    </Fragment>
  );
};

export default memo(PageLoader);
