// ブラウザを切り替えるコンポーネント.
import { detect } from 'detect-browser';
import { User } from '../../../constants/UserListField';
import { ResponseData } from '../../../constants/LifeCheck';
import React, { FC } from 'react';
import Chrome from './Chrome';
import InternetExplorer from './InternetExplorer';
import Edge from './Edge';
import Safari from './Safari';
import Default from './Default';
import Mobile from './Mobile';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// 生活機能チェックシート.
interface BrowserHandlerProps {
  user: User;
  lifeCheck: ResponseData;
}

const BrowserHandler: FC<BrowserHandlerProps> = ({ user, lifeCheck }) => {
  const theme = useTheme();
  const matches = {
    xs: useMediaQuery(theme.breakpoints.down('xs')),
    sm: useMediaQuery(theme.breakpoints.down('sm')),
  };
  const isMediaPrint = window.matchMedia('print');

  if (!isMediaPrint.matches && (matches.xs || matches.sm)) {
    return <Mobile user={user} lifeCheck={lifeCheck} />;
  }

  const browser = detect();
  if (browser) {
    const name: string = browser.name;
    switch (name) {
      case 'ie':
        return <InternetExplorer user={user} lifeCheck={lifeCheck} />;
      case 'chrome':
        return <Chrome user={user} lifeCheck={lifeCheck} />;
      case 'safari':
        return <Safari user={user} lifeCheck={lifeCheck} />;
      case 'edge':
        return <Edge user={user} lifeCheck={lifeCheck} />;
      default:
        return <Default user={user} lifeCheck={lifeCheck} />;
    }
  }

  // 安全策.
  return <Default user={user} lifeCheck={lifeCheck} />;
};

export default BrowserHandler;
