import { useEffect, useState, useRef } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import * as MoffAPIActions from '../actions/MoffAPI';
import { MonthlyTrainingData } from '../constants/Training';
import { dispatchIdentifierToGTM, mergeObject, cloneDeep } from '../utils/commonUtil';
import { validateLongterm, getLongtermHistory } from '../utils/dateUtil';
import { convertToAttachmentPosition, omitUnIntroducedFromMonthlyTrainings } from '../utils/locomoAdlModule';
import { useTrainingUI } from './use-training-ui';

export const useTrainingFetchLongTerm = () => {
  const store = useStore();
  const dispatch = useDispatch();
  type AllState = ReturnType<typeof store.getState>;
  const moffApiState = useSelector((state: AllState) => state.moffAPI);
  const { userId, longTermtogglePosition } = useTrainingUI();

  const [date] = useState(moment());
  const [longTermStartMonth, setLongTermStartMonth] = useState(moment().subtract(2, 'months').format('YYYY-MM'));
  const [longTermEndMonth, setLongTermEndMonth] = useState(moment().format('YYYY-MM'));
  const [longTermErrorMessage, setlongTermErrorMessage] = useState('');

  // inputタグのref.
  const refLongTermStartDate = useRef<HTMLInputElement | null>();
  const refLongTermEndDate = useRef<HTMLInputElement | null>();

  const [longTermData, setLongTermData] = useState<MonthlyTrainingData[]>([]);

  // 画面読み込み時の処理。選択範囲のトレーニングデータを取得
  useEffect(() => {
    const longtermHistory: string[] = getLongtermHistory(longTermStartMonth, longTermEndMonth);
    dispatch(MoffAPIActions.getLongTermData(userId, longtermHistory, longTermtogglePosition));
    // 長期変化.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longTermEndMonth, longTermStartMonth, userId]);

  // 「両側」「左」「右」をトグルで切り替えた時の処理
  useEffect(() => {
    (async () => {
      const attachmentPosition = convertToAttachmentPosition(longTermtogglePosition);
      const longtermHistory: string[] = getLongtermHistory(longTermStartMonth, longTermEndMonth);
      dispatch(MoffAPIActions.getLongTermData(userId, longtermHistory, longTermtogglePosition));
      if (attachmentPosition?.name) {
        dispatchIdentifierToGTM(`longterm_graph_${attachmentPosition.name}_moff_training`);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longTermtogglePosition]);

  // トレーニングデータ読み込み完了後、ロコモ・ADLのデータを合成しローカルステートに格納する
  useEffect(() => {
    const copyLocomoLongtermData = cloneDeep(moffApiState.result.locomoLongTermData);
    const copyAdlLongtermData = cloneDeep(moffApiState.result.adlLongTermData);
    const locomoDataLength = copyLocomoLongtermData?.length;
    const adlDataLength = copyAdlLongtermData?.length;
    const longTermData =
      locomoDataLength !== adlDataLength
        ? []
        : Array.from(Array(locomoDataLength), (_, i: number) => {
            const fetchedAllMonthlyData = mergeObject<MonthlyTrainingData>(
              copyLocomoLongtermData?.[i],
              copyAdlLongtermData?.[i],
            );
            return omitUnIntroducedFromMonthlyTrainings(fetchedAllMonthlyData);
          });
    setLongTermData(longTermData);
  }, [moffApiState.result.locomoLongTermData, moffApiState.result.adlLongTermData]);

  const longTermFormValidation = async () => {
    let isValid = false;
    const startDate = refLongTermStartDate.current ? refLongTermStartDate.current.value.toString() : '';
    const endDate = refLongTermEndDate.current ? refLongTermEndDate.current.value.toString() : '';

    // ここにも反映しないとinputの中身が変わらない.
    setLongTermStartMonth(startDate);
    setLongTermEndMonth(endDate);

    // フォーマット: 2017-01
    // ※年と月の片方さえなければ空文字で判定される.
    // OK: start:2018-12 end:2018-12
    // BAD: start:2018-12 end:2018-11 or 1年以上.
    isValid = validateLongterm(startDate, endDate);

    if (isValid) {
      setlongTermErrorMessage('');
      // 通信.
      const longtermHistory: string[] = getLongtermHistory(startDate, endDate);
      await dispatch(MoffAPIActions.getLongTermData(userId, longtermHistory, longTermtogglePosition));
    } else {
      setlongTermErrorMessage('期間は1年以内で、正しく指定してください。');
    }
  };

  return {
    moffApiState,
    longTermData,
    refLongTermStartDate,
    refLongTermEndDate,
    date,
    longTermStartMonth,
    longTermEndMonth,
    longTermErrorMessage,
    longTermFormValidation,
  };
};

type useTrainingFetchLongTerm = ReturnType<typeof useTrainingFetchLongTerm>;
