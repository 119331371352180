import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import store from '../store/configureStore';

import { parseQueryString, waitResponseFromBrowser } from '../utils/commonUtil';
import * as RequestParamActions from '../actions/RequestParam';
import * as AuthActions from '../actions/Auth';
import * as PopupActions from '../actions/Popup';

import Header from '../components/Organisms/Header';
import PopupContainer from './Popup';
import { dispatchIdentifierToGTM } from '../utils/commonUtil';

type AllState = ReturnType<typeof store.getState>;

const HeaderContainer: React.FC = () => {
  const dispatch = useDispatch();
  const header = useSelector((state: AllState) => state.header);
  const request_param = useSelector((state: AllState) => state.request_param);
  const location = useLocation();
  const planType = useSelector((state: AllState) => state.auth.planType);

  useEffect(() => {
    const query = parseQueryString(location.search);
    dispatch(RequestParamActions.load(Object.assign({}, location, { search: query })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignOut = async () => {
    dispatch(AuthActions.initialize());
    await waitResponseFromBrowser();
    await dispatch(AuthActions.signOut());
    dispatchIdentifierToGTM('logout');
  };

  const openUserSetting = (): void => {
    dispatch(PopupActions.OpenUserSetting());
    dispatchIdentifierToGTM('menu_youtube_user_setting');
  };

  if (header) {
    if (!header.no_header) {
      return (
        <>
          <Header
            header={header}
            request_param={request_param}
            handleSignOut={handleSignOut}
            openPopupUserSetting={openUserSetting}
            planType={planType}
          />
          <PopupContainer />
        </>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default HeaderContainer;
