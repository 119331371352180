// 生活機能チェックreducer.
import { Reducer } from 'redux';
import * as LifeCheck from '../../constants/LifeCheck';
import { LifeCheckAction } from '../../actions/LifeCheck';

export interface LifeCheckState {
  Loading: boolean;
  LoadedCount: number;
  result: LifeCheck.MonthlyUsersData;
}

export const initialState = {
  Loading: false,
  LoadedCount: 0,
  result: {
    monthUsersRecords: [],
  },
};

const lifeCheck: Reducer<LifeCheckState, LifeCheckAction> = (state = initialState, action: LifeCheckAction) => {
  switch (action.type) {
    case LifeCheck.LOADING:
      return {
        ...state,
        type: LifeCheck.LOADING,
        Loading: true,
        LoadedCount: 0,
      };
    case LifeCheck.FINISH:
      return {
        ...state,
        type: LifeCheck.FINISH,
        Loading: false,
        LoadedCount: state.LoadedCount + 1,
      };
    case LifeCheck.SUCCESS:
      return {
        ...state,
        type: LifeCheck.SUCCESS,
        Loading: false,
        result: action.result,
      };
    case LifeCheck.ERROR:
      return {
        ...state,
        type: LifeCheck.ERROR,
        Loading: false,
        error: action?.error,
      };
    default:
      return state;
  }
};

export default lifeCheck;
