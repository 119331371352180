import React from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';

import { Box, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { returnFalse } from '../../utils/commonUtil';

const useStyles = makeStyles({
  inputField: {
    width: '100%',
    backgroundColor: '#fff',
  },
  dt: {
    marginRight: '20px',
  },
  dd: {
    position: 'absolute',
    top: 0,
    right: '20px',
  },
  input: {
    width: '200px',
    height: '32px',
    padding: '7px 30px 5px 5px',
    lineHeight: '32px',
    color: '#fff',
    backgroundColor: 'hsla(0, 0%, 100%, 0.2)',
    border: 'none',
    borderRadius: '4px',
    fontSize: '18px',
    outline: 'none',
    '&::placeholder': {
      fontSize: '18px',
      color: '#fff',
    },
    '&::-webkit-search-cancel-button': {
      '-webkit-appearance': 'none',
    },
    '&:-ms-input-placeholder': {
      /* IEでレイアウトが崩れるためpaddingをクリア */
      padding: '0 0 0 5px',
      fontSize: '18px',
      /* Internet Explorer 10-11 */
      color: 'white',
      opacity: 0.8,
    },
    '-webkit-appearance': 'none',
  },
  button: {
    display: 'block',
    width: '32px',
    height: '32px',
    padding: 0,
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    outline: 'none',
  },
  span: {
    display: 'block',
    height: '100%',
    background:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAABYCAYAAABxlTA0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAhlJREFUeNrs2w9ugyAchuGx9F6jJ6ueDD0Z0wQNY4J/ECTwfknTpbPaPuJPQCu01l8kXb4hABhgAjDAABOAAQaYAAwwARhggAnAAANMAAYYYAIwwARggAEmABeYV86NCSF2l9Fad+bPz8a/h+kxzs/TuoarnyPn7WIi68YCwNPnUNOTPLG6Gbi/Ap31frx5Y7kevhar/0aZ16RnWeUuX/R3fhLYwVJbqB4g6b4XYAc4BsiBPr2O6oEdGBlZ4k4jtwAc1XJ3kGXTwFZpUDefrNeSUxJw9m6aBfCO6ct64A6tO+d3zjqSswYRw924Jn1gkNLEUPnHPI+J1r/sNFkKsMj8K6Nk5cEtEyIwbKx2qLwAiyOTEpUA1zib1jv1vr0SkaMFh8oQJYISEXeWL+XwrRF4dLpryfrZrQKv/dTYSR5PPon72Zf2ei1zEV2JcxFPAN82VemZTeuaBnZnvu5APntU5PzOjww0ph7U26rH6irycunImnsop/Y+VYM9Le/KdTX3YikT7juH91pDQ0iBq9CHkZsC3jhJ6Q1AX7rAOiTAnpqqw1G+nsJR5KovGZ09iZkT2OErIOY9dj3/N+nT5K1TN5+4g8jMB8fvyBnzbb2kEg3N2wQuCbnq+4NLQK6yBh+oyYIWnLYl19mCWwy/0QAYYAIwwAATgAEGmAAMMAEYYIAJwAADTAAGGGAIAAaYAAwwwATgEvMrwABCIuf9Z6FgFgAAAABJRU5ErkJggg==) no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '44px auto',
  },
  searchDesktop: {
    position: 'fixed',
    top: '9px',
    right: '0px',
    zIndex: 101,
  },
});
interface SearchInputProps {
  onChange: (text: string) => void;
  onFocus: () => void;
  placeholder: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ onChange, onFocus, placeholder }) => {
  const theme = useTheme();
  const matches = {
    sm: useMediaQuery(theme.breakpoints.down('sm')),
  };
  const classes = useStyles();

  return matches.sm ? (
    <TextField
      label="利用者検索"
      onChange={(event) => onChange(event.target.value)}
      variant="outlined"
      className={classes.inputField}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <Box className={classes.searchDesktop}>
      <dl>
        <dt className={classes.dt}>
          <input
            type="search"
            onChange={(event) => onChange(event.target.value)}
            onFocus={onFocus}
            className={classes.input}
            placeholder={placeholder}
          />
        </dt>
        <dd className={classes.dd}>
          <button onClick={returnFalse} className={classes.button}>
            <span className={classes.span} />
          </button>
        </dd>
      </dl>
    </Box>
  );
};

export default SearchInput;
