import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import store from '../store/configureStore';

import * as HeaderActions from '../actions/Header';
import * as MoffAPIActions from '../actions/MoffAPI';
import * as Header from '../constants/Header';
import UserSelectMoffCheck from '../components/Organisms/UserSelectMoffCheck';

type AllState = ReturnType<typeof store.getState>;

const UserSelectMoffCheckContainer: FC = () => {
  const dispatch = useDispatch();
  const moffAPI = useSelector((state: AllState) => state.moffAPI);

  const moffCheckCount = useSelector((state: AllState) => state.moffCheckCount);

  useEffect(() => {
    (async () => {
      document.title = 'モフトレチェック';
      dispatch(HeaderActions.setPage(Header.USER_SELECT_MOFF_CHECK));
      await dispatch(MoffAPIActions.getUsers());
      dispatch(MoffAPIActions.duplicateRepeatSort());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <UserSelectMoffCheck moffAPI={moffAPI} moffCheckCount={moffCheckCount} />;
};

export default UserSelectMoffCheckContainer;
